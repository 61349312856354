import { Media } from "@/styles";
import Cleave from "cleave.js/react";
import { ms } from "date-fns/locale";
import { size, transform } from "lodash";
import { relative } from "path";
import styled, { css } from "styled-components";


export const InputContainer = styled.div<{ focused: boolean, isBig?: boolean }>`
position: relative;
 width: 100%;
  padding: ${({isBig}) => isBig ? "20px 16px" : "36px 16px 12px 16px"};
  border-radius: 12px;
  border: ${({ focused }) => focused ? "1px solid var(--gradient-2, #37DCF2)" : "1px solid var(--dark-3, #1E2239)"};
background: var(--dark-4, #151530);
`;

export const TransparentInputContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 12px;
`;

export const TransparentInputLabelWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const TransparentInputWrapper = styled.div<{focused: boolean}>`
position: relative;
display: flex;
justify-content: flex-start;
align-items: center;
gap: 12px;
padding: 28px 24px;
width: 100%;
border-radius: 16px;
border: 1px solid ${({focused}) => focused ? "#2C5173" : "#1E2239"};
transition: all 150ms ease-in-out;

${Media.mobile} {
  padding: 24px 16px;
}
`;

export const AdditionalButtonWrapper = styled.div`
position: absolute;
right: 20px;
top: 16px;

${Media.mobile} {
  top: 20px;
}
`;

const inputTextStyles = css`
  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  line-height: normal;
  letter-spacing: -0.03em;
`;

export const CleaveStyled = styled(Cleave)<{ focused: boolean, value: string }>`
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 180px;
  padding-right: 8px;
  margin-top: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  ${inputTextStyles}
  color: ${({focused, value}) => focused || value !== "0" ? "#FFF" : "#7D7D97"};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &::-moz-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &:-ms-input-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &:-moz-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
`

export const CoinLogo = styled.img`
width: 40px;
height: 40px;
`;

export const InputLabel = styled.label<{ focused: boolean, value: boolean }>`
  position: absolute;
  left: 0;
  top: -3px;
  color: #7D7D97;
/* Text 1/Medium */
font-family: Inter;
font-style: normal;
font-weight: 500;
line-height: normal;
  background-color: transparent;
  z-index: 10;
  transform: ${({ focused, value }) => focused || value ? "translateY(-100%)" : "none"};
  font-size: ${({ focused, value }) => focused || value ? "14px" : "18px"};
  transition: transform 150ms ease-in-out, font-size 150ms ease-out;
`;

export const TransparentInputLabel = styled.label`
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const AdditionalLabel = styled.p<{labelWeight?: number}>`
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 16px;
color: #7D7D97;
/* Text 1/Medium */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: ${({labelWeight}) => labelWeight || "500"};
line-height: normal;
`;

export const Input = styled.input<{awardedColor?: boolean, isBig?: boolean}>`
  position: relative;
  padding: 6px 0px 5px 0;
  width: 100%;
  background: var(--dark-4, #151530);
  outline: none;
  border: none;
  font-family: Inter;
font-size: ${({isBig}) => isBig ? "24px" : "18px"};
font-style: normal;
font-weight: 500;
color: #FFF;

${({awardedColor}) => awardedColor && `
background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`}
`;
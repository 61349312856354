import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import React from "react";
import styled from "styled-components";
import veShellCoin from "@/assets/icons/shell-white-coin.svg";
import shellIcon from '@/assets/coins/SHELL.svg'
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";
import { Media } from "@/styles";
import { formatDisplay } from "@/utils/formatDisplay";

export const MyVeShellBalance = () => {
    const {myStaked, handleOpenStakeModal, handleInitUnstake, myAvailableBalance, withdrawableAmount } = useRewardsContext();

    const canStake = parseFloat(withdrawableAmount) == 0

    return (
        <MyVeShellBalanceContainer hasFixedHeight>
            <TitleBlanceContainer>
                <MyVeShellBalanceLabel>My veSHELL Balance</MyVeShellBalanceLabel>
                <MyVeShellValueWrapper>
                    <ShellIcon src={veShellCoin} alt="veShell icon" />
                    <VeShellBalance>{formatDisplay(myStaked.toString())}</VeShellBalance>
                </MyVeShellValueWrapper>
            </TitleBlanceContainer>
            <ButtonsAndInfoContainer>
                <VeShellButtonsWrapper>
                    <CustomSecondaryBtn disabled={!canStake || parseFloat(myAvailableBalance) == 0} onClick={handleOpenStakeModal}>Stake</CustomSecondaryBtn>
                    <CustomSecondaryBtn disabled={canStake} onClick={handleInitUnstake}>Unstake</CustomSecondaryBtn>
                </VeShellButtonsWrapper>
                <HrDiv />
                <MyVeShellInfoWrapper>
                    <SingleInfoRow>
                        <LeftSideWrapper>
                            <MyVeShellBalanceLabel>
                                {canStake ? 'Available to stake' : 'Available to unstake'}
                            </MyVeShellBalanceLabel>
                        </LeftSideWrapper>
                        <RightSideWrapper>
                            <SmallCoinImg src={shellIcon} alt="shell coin" />
                            <SmallValue>{formatDisplay(canStake ? myAvailableBalance : withdrawableAmount, 2)} SHELL</SmallValue>
                        </RightSideWrapper>
                    </SingleInfoRow>
                </MyVeShellInfoWrapper>
            </ButtonsAndInfoContainer>
        </MyVeShellBalanceContainer>
    )
};

export const MyVeShellBalanceContainer = styled.div<{hasFixedHeight?: boolean}>`
width: 100%;
border-radius: 16px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
display: flex;
// height: ${({hasFixedHeight}) => hasFixedHeight ? "323px" : "auto"};
padding: 24px;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;

${Media.tablet} {
    padding: 16px;
    gap: 16px;
  }
`;

const TitleBlanceContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 10px;
`;

export const ButtonsAndInfoContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 18px;
width: 100%;
`;

export const MyVeShellBalanceLabel = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const MyVeShellValueWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;
`;

export const ShellIcon = styled.img`
width: 24px;
height: 24px;
`;

export const VeShellBalance = styled.p`
color: var(--White, #FFF);

/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`;

const VeShellButtonsWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 16px;
width: 100%;
`;

export const CustomPrimaryBtn = styled(ButtonPrimary)`
width: 100%;
padding-top: 17px;
padding-bottom: 17px;
height: unset;
border-radius: 16px;

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border-color: #1E2239;
`}
`;

const CustomSecondaryBtn = styled(ButtonSecondary)`
width: 100%;
padding-top: 17px;
padding-bottom: 17px;
height: unset;
border-radius: 16px;
border-width: 2px;

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border-color: #1E2239;
`}
`;

export const HrDiv = styled.div`
height: 1px;
width: 100%;
background: #1E2239;
`;

const MyVeShellInfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 12px;
width: 100%;
`;

export const SmallCoinImg = styled.img`
width: 16px;
height: 16px;
`;

export const SmallValue = styled.p`
color: var(--White, #FFF);
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

const SingleInfoRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

const LeftSideWrapper = styled.div`
display: flex;
gap: 4px;
justify-content: flex-start;
align-items: center;
`;

export const RightSideWrapper = styled.div`
display: flex;
gap: 4px;
justify-content: flex-end;
align-items: center;
`;




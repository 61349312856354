import React, { useContext } from "react";
import styled from "styled-components";
import { Media, breakpoints } from "../../styles";
import { Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getFormattedChartDayDate } from "../../utils";
import { useWidthBreakpoint } from "../../hooks";
import { TableFilterDropdown } from "../Table/TableFilterDropdown";
import CustomAreaChart from "../../pages/Rewards/components/Points/components/CustomAreaChart";
import { tokenColors } from "@/constants/tokenColors";
import { stripNonAlphabeticCharacters } from "@/pages/Rewards/components/Points/PointsChart";
import { isShellToken } from "@/utils/tokens";
import { ChainContext } from "../Overlays/ChainProvider";

interface TokenWithPoints {
  name: string;
  subname: string;
  color: string;
  balance: number;
  value: number;
  multiplier: number;
  dailyPoints: number;
}

interface TokensPerData {
  date: number;
  tokens: TokenWithPoints[];
}

const tokenLookup: any = {};

export const AreaChartWithPredicted = ({
  header,
  allowDuplicatedCategory,
  tooltip,
  areas,
  withoutLegend,
  tokenName,
  tokenColor,
  tokens,
  prefixTick,
  suffixTick,
  xAxisFormat
}: any) => {

  const { tokenMap } = useContext(ChainContext)

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const chartData: any = [];

  if (tokens) {
    // Sort tokens by time that user started earning points
    const tokenNames = Object.keys(tokens)
      .filter((token) => token !== "compoundedPoints")
      .map((name) => {
        let historical = tokens[name]["historical"] || {};
        return {
          token: name,
          time:
            Object.keys(historical)[
              Object.values(historical).findIndex((value: any) => value > 0)
            ] ?? 0,
        };
      })
      .sort((a: any, b: any) => a.time - b.time)
      .map((value) => value.token);

    const dateTokenPoint: { [date: string]: TokenWithPoints[] } = {};

    tokenNames.forEach((token) => {
      let tokenData = tokens[token];
      let tokenInfo = tokenMap[token];

      tokenLookup[stripNonAlphabeticCharacters(token)] = {
        name: token,
        color: isShellToken(tokenInfo)
          ? tokenColors["shLP"]
          : tokenColors[token],
      };

      Object.keys(tokenData?.historical).forEach((date) => {
        if (!(date in dateTokenPoint)) {
          dateTokenPoint[date] = [];
        }

        const tokenPoint = {
          name: token,
          subname: isShellToken(tokenInfo) ? tokenInfo.oceanID : tokenInfo?.name,
          color: isShellToken(tokenInfo)
            ? tokenColors["shLP"]
            : tokenColors[token],
          balance: tokenData.balance,
          value: tokenData.value,
          multiplier: tokenData.multiplier,
          dailyPoints: tokenData.historical[date],
        };

        dateTokenPoint[date].push(tokenPoint);
      });
    });

    if (tokens.compoundedPoints) {
      Object.keys(tokens.compoundedPoints.historical).forEach((date) => {
        if (!(date in dateTokenPoint)) {
          dateTokenPoint[date] = [];
        }
        const tokenPoint = {
          name: "compoundedPoints",
          subname: "compoundedPoints",
          color: "#0ABCFD",
          balance: 0,
          value: 0,
          multiplier: 0,
          dailyPoints: tokens.compoundedPoints.historical[date],
        };

        dateTokenPoint[date].push(tokenPoint);

        tokenLookup.compoundedpoints = {
          name: "Compounded Points",
          color: "#0ABCFD",
        };
      });
    }

    const tokensPerDayData: TokensPerData[] = Object.keys(dateTokenPoint).map(
      (date) => {
        return {
          date: parseInt(date),
          tokens: dateTokenPoint[date],
        };
      }
    );

    for (let i in tokensPerDayData) {
      let obj = tokensPerDayData[i];
      let newobj: any = {
        date: obj.date,
      };
      for (let j in obj.tokens) {
        let m = obj.tokens[j];
        newobj[m.name ? stripNonAlphabeticCharacters(m.name) : `token${j}`] =
          m.dailyPoints;
      }
      chartData.push(newobj);
    }
  }

  return (
    <PointsChartWrapper>
      {header}
      <StyledResponsiveContainer width="100%" height={261}>
        <StyledAreaChart
          margin={{
            top: isTablet ? 0 : 8,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient
              id={tokenName?.toLowerCase() || "data"}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              key={tokenName?.toLowerCase() || "data"}
              data-defs-test={tokenName?.toLowerCase() || "data"}
            >
              <stop offset="0%" stopColor={`${tokenColor}4D`} stopOpacity={1} />
              <stop
                offset="100%"
                stopColor={`${tokenColor}4D`}
                stopOpacity={0.2}
              />
            </linearGradient>
            <linearGradient
              id={"compoundedPoints"}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              key={"compounded"}
              data-defs-test={"compounded"}
            >
              <stop offset="0%" stopColor={`#0ABCFD4D`} stopOpacity={1} />
              <stop offset="100%" stopColor={`#0ABCFD4D`} stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            padding={{ left: 0, right: isTablet ? 0 : 4 }}
            interval="preserveStartEnd"
            color={"#7d7d97"}
            fill={"#7d7d97"}
            scale={"utc"}
            tickFormatter={(date) => xAxisFormat ? xAxisFormat(date) : getFormattedChartDayDate(date)}
            allowDuplicatedCategory={
              allowDuplicatedCategory === undefined
                ? true
                : allowDuplicatedCategory
            }
          />
          <YAxis
            orientation="right"
            tickFormatter={(tick) => `${prefixTick || ''}${tick}${suffixTick || ''}`}
            axisLine={false}
            tickLine={false}
            color="#7d7d97"
            fill={"#7d7d97"}
            padding={{ top: isTablet ? 0 : 4 }}
          />
          <Tooltip cursor={{ stroke: '#171B33', strokeWidth: 2 }} content={tooltip} />
          {areas || (
            <Area
              data={chartData}
              dataKey={tokenName?.toLowerCase()}
              key={tokenName?.toLowerCase()}
              type="monotone"
              stroke={tokenColor}
              fillOpacity={1}
              fill={`url(#${tokenName?.toLowerCase()})`}
              data-area-test={tokenName?.toLowerCase()}
              activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
              stackId="1"
            />
          )}
        </StyledAreaChart>
      </StyledResponsiveContainer>
      {withoutLegend ? null : (
        <Legend>
          <LegendItem>
            <Line color={tokenColor} />
            Price
          </LegendItem>
          <LegendItem>
            <DashLine color={tokenColor} />
            Future Price
          </LegendItem>
        </Legend>
      )}
    </PointsChartWrapper>
  );
};

const PointsChartWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 12px 20px 24px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;
  overflow: hidden;
  .xAxis {
    .recharts-cartesian-axis-tick {
      &:last-child {
        text {
          transform: translateX(-14px);
        }
      }
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: #7d7d97;
    }
  }

  ${Media.tablet} {
    padding: 12px;
    padding-right: 0;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 16px;
`;

const StyledAreaChart = styled(CustomAreaChart)`
  .recharts-xAxis {
    transform: translateY(8px);
  }

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  ${Media.tablet} {
    .recharts-xAxis {
      transform: translateY(0px);
    }
  }
`;

const Legend = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;

const Line = styled.div<{color: string}>`
  width: 24px;
  height: 0px;
  border: 2px solid ${({color}) => color};
`;

const DashLine = styled.div<{color: string}>`
  width: 24px;
  height: 0px;
  border: 2px dashed ${({color}) => color};
`;

const Button = styled.div`
  background: #171b33;
  border-radius: 10px;
`;

import React from "react";

export const HistoryIcon = () => {
  return (
    <svg height="24px" version="1.1" viewBox="0 0 20 21" width="24px" xmlns="http://www.w3.org/2000/svg">
        <title/>
        <desc/>
        <defs>
            <linearGradient
            id="paint0_linear_3881_28027"
            x1="2.175"
            y1="2"
            x2="22.1533"
            y2="2.15431"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#37DCF2" />
            <stop offset="1" stopColor="#07C0FB" />
            </linearGradient>
        </defs>
        <g fill="url(#paint0_linear_3881_28027)" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
            <g id="Core" opacity="0.9" transform="translate(-464.000000, -254.000000)">
                <g id="history" transform="translate(464.000000, 254.500000)">
                    <path d="M10.5,0 C7,0 3.9,1.9 2.3,4.8 L0,2.5 L0,9 L6.5,9 L3.7,6.2 C5,3.7 7.5,2 10.5,2 C14.6,2 18,5.4 18,9.5 C18,13.6 14.6,17 10.5,17 C7.2,17 4.5,14.9 3.4,12 L1.3,12 C2.4,16 6.1,19 10.5,19 C15.8,19 20,14.7 20,9.5 C20,4.3 15.7,0 10.5,0 L10.5,0 Z M9,5 L9,10.1 L13.7,12.9 L14.5,11.6 L10.5,9.2 L10.5,5 L9,5 L9,5 Z" id="Shape"/>
                </g>
            </g>
        </g>
    </svg>
  );
};

import React, { createContext, useContext, useState, useEffect } from "react";
import { queryLeaderboard } from "../Leaderboard/queryLeaderboard";
import { updateMetrics } from "@/store/metricsSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { EXPLORER_API, HISTORY_API, POOL_API } from "@/constants/urls";
import { formatDisplay, formatDisplayShorthand } from "@/utils/formatDisplay";
import { isExternalDefiToken, isShellV2Token } from "@/utils/tokens";
import { addTransaction, addTransactionCount, clearUserTransactions } from "@/store/transactionsSlice";
import { reduceString } from "@/utils/reduceString";
import { tokenColors } from "@/constants/tokenColors";
import { addPool } from "@/store/poolsSlice";
import { buildTransactions } from "../Transactions/TransactionHistory";
import { getTokenID } from "@/utils/LiquidityGraph";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface ProviderProps {
  children: React.ReactNode;
}

interface LeaderboardContext {
  leaderboard: any[];
  leaderboardLoading: boolean;
}

interface MetricsContext {
  metrics: any;
  metricsLoading: boolean;
}

interface PoolsContext {
  featuredPools: any[];
  featuredPoolsLoading: boolean;
}

interface TransactionsContext {
  transactions: any[];
  transactionsLoading: boolean;
}

const LeaderboardContext = createContext<LeaderboardContext>({
  leaderboard: [],
  leaderboardLoading: true,
});

const MetricsContext = createContext<MetricsContext>({
  metrics: {},
  metricsLoading: true,
});

const PoolsContext = createContext<PoolsContext>({
  featuredPools: [],
  featuredPoolsLoading: true,
});

const TransactionsContext = createContext<TransactionsContext>({
  transactions: [],
  transactionsLoading: true,
});

export const LeaderboardProvider = ({ children }: ProviderProps) => {
  const [leaderboard, setLeaderboard] = useState<any[]>([]);
  const [leaderboardLoading, setLeaderboardLoading] = useState(true);

  const dispatch = useAppDispatch();
  const cachedLeaderboards = useAppSelector((state) => state.leaderboards.leaderboards);

  const queryLeaderboardData = async () => {
    setLeaderboardLoading(true);
    const data = await queryLeaderboard(dispatch, cachedLeaderboards);
    setLeaderboard(data);
    setLeaderboardLoading(false);
  };

  useEffect(() => {
    queryLeaderboardData();
  }, []);

  const providerValue = React.useMemo(
    () => ({
      leaderboard,
      leaderboardLoading,
    }),
    [leaderboardLoading]
  );

  return <LeaderboardContext.Provider value={providerValue}>{children}</LeaderboardContext.Provider>;
};

export const TransactionsProvider = ({ children }: ProviderProps) => {
  const [transactions, setTransactionsData] = useState<any[]>([]);
  const [transactionsLoading, setTransactionsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const { connectedChain } = useContext(ChainContext);

  const queryTransactions = async () => {
    const response = await fetch(`${HISTORY_API(connectedChain)}v3/address/all/0`);
    const apiData = await response.json();
    const transactionData = apiData.transactions.data;
    const transactionHashes = Object.keys(transactionData);

    dispatch(addTransactionCount({ chain: connectedChain.name, address: "all", count: apiData.transactions.count }));
    dispatch(clearUserTransactions({ chain: connectedChain.name, user: "global" }));
    const txs = buildTransactions(transactionData, transactionHashes);
    dispatch(addTransaction({ chain: connectedChain.name, address: "all", skip: 0, data: txs }));
    return txs;
  };

  useEffect(() => {
    setTransactionsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const txData = await queryTransactions().catch(() => []);
      if (signal.aborted) return;
      setTransactionsData(txData.slice(0, 4));
      setTransactionsLoading(false);
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [connectedChain]);

  const providerValue = React.useMemo(
    () => ({
      transactions,
      transactionsLoading,
    }),
    [transactionsLoading]
  );

  return <TransactionsContext.Provider value={providerValue}>{children}</TransactionsContext.Provider>;
};

export const PoolsProvider = ({ children }: ProviderProps) => {
  const chainPools: { [id: string]: string[] } = {
    "Arbitrum One": ["SHELL+ETH", "2CRV", "Stablepool"],
    Base: ["weETH-WETH-BPT", "cbETH-WETH-BPT", "rETH-WETH-BPT"],
  };
  const { connectedChain, tokenMap } = useContext(ChainContext);

  const buildDefaultTokens = () => {
    return chainPools[connectedChain.name].map((tokenID) => {
      const lpToken = tokenMap[tokenID];
      return {
        icon: lpToken.icon,
        title: lpToken.name,
        description: "LP " + reduceString(lpToken.oceanID ?? lpToken.address, 6, 4),
        color: tokenColors[lpToken.tokens[0]],
        protocol: lpToken.tokenType,
        version: lpToken.protocolVersion,
        info: [
          {
            title: "APY",
            value: `0%`,
            color: "#7ADEB9",
          },
          {
            title: "Liquidity",
            value: `$0`,
          },
          {
            title: "24h Vol",
            value: `$0`,
          },
        ],
      };
    });
  };

  const [featuredPoolsLoading, setFeaturedPoolsLoading] = useState(false);
  const [featuredPools, setFeaturedPools] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const poolStats = useAppSelector((state) => state.pools.pools[connectedChain.name]);

  const queryPools = async () => {
    const lpTokens = chainPools[connectedChain.name].map((pool) => tokenMap[pool]);

    const fetchPoolInfo = async (pool: any) => {
      //   setFeaturedPoolsLoading(true);
      const poolName = getTokenID(pool);
      const version = isExternalDefiToken(pool) && isShellV2Token(pool) ? "v2" : "v3";
      if (poolStats[poolName]) return poolStats[poolName];
      return fetch(POOL_API(connectedChain) + version + "/pools/" + poolName.replace(/\//g, "-"))
        .then((response) => response.json())
        .then((data) => {
          dispatch(addPool({ name: poolName, data: data, chain: connectedChain.name }));
          return data;
        })
        .catch(() => {
          return {
            apy: 1,
            totalValueLocked: 0,
            "24HrVolume": 0,
          };
        });
    };

    const results = await Promise.all(lpTokens.map((lpToken) => fetchPoolInfo(lpToken)));

    return results.map((poolInfo: any, index: number) => {
      const lpToken = lpTokens[index];
      return {
        icon: lpToken.icon,
        title: lpToken.name,
        description: "LP " + reduceString(lpToken.oceanID ?? lpToken.address, 6, 4),
        color: tokenColors[lpToken.tokens[0]],
        protocol: lpToken.tokenType,
        version: lpToken.protocolVersion,
        info: [
          {
            title: "APY",
            value: `${poolInfo.apy > 1 ? formatDisplay(((poolInfo.apy - 1) * 100).toString(), 2) : "--"}%`,
            color: "#7ADEB9",
          },
          {
            title: "Liquidity",
            value: `$${formatDisplayShorthand(poolInfo.totalValueLocked)}`,
          },
          {
            title: "24h Vol",
            value: `$${formatDisplayShorthand(poolInfo["24HrVolume"])}`,
          },
        ],
      };
    });
  };

  useEffect(() => {
    setFeaturedPools(buildDefaultTokens());
    setFeaturedPoolsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const poolData = await queryPools().catch(() => []);
      if (signal.aborted) return;
      setFeaturedPools(poolData);
      setFeaturedPoolsLoading(false);
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [connectedChain]);

  const providerValue = React.useMemo(
    () => ({
      featuredPools,
      featuredPoolsLoading,
    }),
    [featuredPoolsLoading, featuredPools]
  );

  return <PoolsContext.Provider value={providerValue}>{children}</PoolsContext.Provider>;
};

export const MetricsProvider = ({ children }: ProviderProps) => {
  const { connectedChain } = useContext(ChainContext);

  const defaultMetrics = {
    volume: {
      value: 0,
      dailyChange: 0,
    },
    users: {
      value: 0,
      dailyChange: 0,
    },
    volumeChart: [],
  };

  const [metrics, setMetrics] = useState(defaultMetrics);
  const [metricsLoading, setMetricsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const cachedMetrics = useAppSelector((state) => state.metrics.data[connectedChain.name]);

  const queryMetrics = async () => {
    if (Object.keys(cachedMetrics).length > 0) return cachedMetrics;
    const data = await fetch(EXPLORER_API(connectedChain)).then((response) => response.json());
    dispatch(updateMetrics({ chain: connectedChain.name, data: data }));
    return data;
  };

  useEffect(() => {
    setMetricsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const metricsData = await queryMetrics().catch(() => defaultMetrics);
      if (signal.aborted) return;
      setMetrics(metricsData);
      setMetricsLoading(false);
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [connectedChain]);

  const providerValue = React.useMemo(
    () => ({
      metrics,
      metricsLoading,
    }),
    [metricsLoading]
  );

  return <MetricsContext.Provider value={providerValue}>{children}</MetricsContext.Provider>;
};

export const useLeaderboard = () => useContext(LeaderboardContext);
export const useMetrics = () => useContext(MetricsContext);
export const usePools = () => useContext(PoolsContext);
export const useTransactions = () => useContext(TransactionsContext);

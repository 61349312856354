import React, { Fragment, useState, useLayoutEffect, useMemo, useCallback } from "react";
import { Column, useExpanded, useFilters, useSortBy, useTable } from "react-table";
import { SortIcon } from "../../Icons/SortIcon";
import { Table, TableRow, TableSubRow } from "../StyledTable";
import { ExpandButton } from "@/components/Table/ExpandButton";
import { TokenCell } from "./TableCells/TokenCell";
import { TransactionTableSubRow } from "@/components/Table/TransactionTable/TransactionTableSubRow";
import { TimestampCell } from "./TableCells/TimestampCell";
import { TransactionHashCell } from "./TableCells/TransactionHashCell";
import { LoadingRows } from "@/components/Table/GenericTable";
import { useTransactions } from "@/pages/Explore/ExploreContext";

interface InputOutput {
  tokens: string[];
  amounts: string[];
}

export interface Transaction {
  transactionHash: string;
  input: InputOutput;
  output: InputOutput;
  arrowType: string;
  timestamp: number;
  clickLink: string;
  tokenIDs: string[];
  expanded: boolean;
}

interface TransactionTableProps {
  data?: Transaction[];
  isLoading?: boolean;
  txHistory?: boolean;
}

export const TransactionTable = ({ data, isLoading, txHistory }: TransactionTableProps) => {
  const [expanded, setExpanded] = useState<{ [id: string]: boolean }>({});
  const { transactions, transactionsLoading } = useTransactions();
  data = txHistory ? data : transactions || [];
  isLoading = txHistory ? isLoading : transactionsLoading;

  const columns = useMemo(
    () => [
      {
        Header: "Timestamp",
        accessor: "timestamp",
        Cell: TimestampCell,
      },
      {
        Header: "Sent",
        accessor: "input",
        Cell: TokenCell,
      },
      {
        Header: "Received",
        accessor: "output",
        Cell: TokenCell,
      },
      {
        Header: "Transaction Hash",
        accessor: "transactionHash",
        Cell: TransactionHashCell,
      },
      {
        Header: "",
        id: "expander",
        Cell: ({ row }: any) => <ExpandButton row={row} />,
        disableSortBy: true,
        width: 50,
      },
    ],
    []
  );

  const renderRowSubComponent = useCallback(({ row }: { row: any }) => <TransactionTableSubRow row={row} />, []);
  const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);
  const tableInstance = useTable(
    {
      columns: columns as Column<Transaction>[],
      data: data || [],
      defaultColumn,
    },
    useFilters,
    useSortBy,
    useExpanded
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns, toggleRowExpanded } = tableInstance;

  useLayoutEffect(() => {
    rows.forEach((row) => toggleRowExpanded([row.id], expanded[row.id] == true));
  }, [expanded]);

  useLayoutEffect(() => {
    const newExpanded = { ...expanded };
    rows.forEach((row) => (newExpanded[row.id] = false));
    setExpanded(newExpanded);
  }, [data]);

  const onTableRowClick = (id: string) => {
    const newExpanded = { ...expanded };
    newExpanded[id] = !expanded[id];
    setExpanded(newExpanded);
    toggleRowExpanded([id]);
  };

  return (
    <>
      {isLoading ? (
        <LoadingRows isLoading={true} padding="50px 0 22px" rows={4} />
      ) : (
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {column.canSort && column.Header == "Timestamp" && rows.length > 1 && (
                      <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                    )}
                    {column.canFilter && <div>{column.render("Filter")}</div>}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const txData: any = row.original;
              return (
                <Fragment key={row.id}>
                  <TableRow {...row.getRowProps()} onClick={() => onTableRowClick(row.id)} className={row.isExpanded ? "expanded" : "hoverable"}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </TableRow>
                  {row.isExpanded && (
                    <TableSubRow>
                      <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                    </TableSubRow>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

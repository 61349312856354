import { ETH_ADDRESS, STREAM_ADDRESS } from "../constants/addresses";

import ethIcon from "../assets/coins/ETH.svg";
import weEthIcon from '../assets/coins/weETH.svg'
import weEthsIcon from '../assets/coins/weETHs.svg'
import renzoEthIcon from '../assets/coins/renzoETH.svg'
import rsEthIcon from '../assets/coins/rsETH.svg'
import aWethIcon from "../assets/coins/aWETH.png"
import awstEthIcon from '../assets/coins/awstETH.png'
import aWbtcIcon from '../assets/coins/aWBTC.png'
import aWeEthIcon from '../assets/coins/aweETH.png'
import ptWeEthsIcon from '../assets/coins/pt-weeths.svg'
import ytWeEthsIcon from '../assets/coins/yt-weeths.svg'
import ptWeEthIcon from '../assets/coins/pt-weeth.svg'
import ytWeEthIcon from '../assets/coins/yt-weeth.svg'
import ezEthWethBPTIcon from '../assets/coins/ezETH-WETH-BPT.png'
import rsEthWethBPTIcon from '../assets/coins/rsETH-WETH-BPT.png'

import { ProtocolTypeOption, generateColorHex } from "@/components/DexBadge/DexBadge";
import { tokenColors } from "@/constants/tokenColors";
import { Token, ShellToken, NFTCollection, LBPToken, ExternalToken, ExternalDefiToken } from "../utils/tokens";
import { openseaKey } from "@/providers/WagmiProvider";
import { NFT_RARITY, WRAPPED_NFT_MAP } from "@/utils/nftHelpers";
import { calculateWrappedTokenId } from "@/utils/ocean/utils";
import { createTokenLogo, tokenLogos } from "@/constants/tokenLogos";

export const oceanAddress = "0x10597C960343213b947674E103C15B41c9f94f22";

export const tokens: Token[] = [
  {
    name: "Ether",
    symbol: "ETH",
    address: ETH_ADDRESS,
    wrapped: false,
    icon: ethIcon,
  },
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  },
  {
    name: "Dai",
    symbol: "DAI",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
  },
  {
    name: "Tether",
    symbol: "USDT",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    wrapped: false,
    icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9.png",
  },
  {
    name: "Lido Staked ETH",
    symbol: "wstETH",
    address: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
    wrapped: false,
    icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0x5979d7b546e38e414f7e9822514be443a4800529.png",
  },
  {
    name: "Wrapped Bitcoin",
    symbol: "WBTC",
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  },
  {
    name: "Super Symbiotic LRT",
    symbol: "weETHs",
    address: '0x917ceE801a67f933F2e6b33fC0cD1ED2d5909D88',
    wrapped: false,
    icon: weEthsIcon
  },
  {
    name: "Ether.fi Staked ETH",
    symbol: "weETH",
    address: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
    wrapped: false,
    icon: weEthIcon,
  },
  {
    name: "Renzo Restaked ETH",
    symbol: "ezETH",
    address: '0xbf5495Efe5DB9ce00f80364C8B423567e58d2110',
    wrapped: false,
    icon: renzoEthIcon
  },
  {
    name: "Kelp DAO Restaked ETH",
    symbol: "rsETH",
    address: "0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7",
    wrapped: false,
    icon: rsEthIcon,
  },
];

export const shellTokens: ShellToken[] = [];

export const nftCollections: NFTCollection[] = [];

export const lbpTokens: LBPToken[] = [];

export const externalTokens: ExternalToken[] = [];

export const externalDefiTokens: ExternalDefiToken[] = [
  {
    name: "ETH+WETH",
    symbol: "ETH+WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    wrapped: false,
    icon: "",
    pool: "0x1f8064368098c6cd9e595e6089efb519099abc77",
    query: "0x1f8064368098c6cd9e595e6089efb519099abc77",
    tokens: ["ETH", "WETH"],
    tokenType: undefined,
    fee: 0,
  },
  {
    name: "Uniswap",
    symbol: "UNI",
    address: "",
    wrapped: false,
    icon: "",
    pool: "0x22b8e34461366e0b01a4dade638a9599df8b3b91",
    query: "0xf671AD7AB9a0A23b9C1200734d5D30fc39D2Af50",
    tokens: tokens.filter((token) => !token.wrapped && token.address !== ETH_ADDRESS).map((token) => token.symbol),
    tokenType: "Uniswap",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum ETH",
    symbol: "aEthWETH",
    address: "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8",
    wrapped: false,
    icon: aWethIcon,
    pool: "0x286ad7ccc4f6f87a91f905837147d11705c6a910",
    query: "0x286ad7ccc4f6f87a91f905837147d11705c6a910",
    tokens: ["WETH"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum USDC",
    symbol: "aEthUSDC",
    address: "0x98C23E9d8f34FEFb1B7BD6a91B7FF122F4e16F5c",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5760.png",
    pool: "0x5be1836b4573f56a0cb3278b038fcec476fe6b37",
    query: "0x5be1836b4573f56a0cb3278b038fcec476fe6b37",
    tokens: ["USDC"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum DAI",
    symbol: "aEthDAI",
    address: "0x018008bfb33d285247A21d44E50697654f754e63",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5763.png",
    pool: "0x2cf268f48f1bfdc0034f2c8ccde8f3f625cd2ec6",
    query: "0x2cf268f48f1bfdc0034f2c8ccde8f3f625cd2ec6",
    tokens: ["DAI"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum USDT",
    symbol: "aEthUSDT",
    address: "0x23878914EFE38d27C4D67Ab83ed1b93A74D4086a",
    wrapped: false,
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5761.png',
    pool: "0xf2c3cbaea31c9e36262e474bd7c24e361a6a3094",
    query: "0xf2c3cbaea31c9e36262e474bd7c24e361a6a3094",
    tokens: ["USDT"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum wstETH",
    symbol: "aEthwstETH",
    address: "0x0B925eD163218f6662a35e0f0371Ac234f9E9371",
    wrapped: false,
    icon: awstEthIcon,
    pool: "0x33abf362424eaaac950449fe680ed7c5f562b50b",
    query: "0x33abf362424eaaac950449fe680ed7c5f562b50b",
    tokens: ["wstETH"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum WBTC",
    symbol: "aEthWBTC",
    address: "0x5Ee5bf7ae06D1Be5997A1A72006FE6C607eC6DE8",
    wrapped: false,
    icon: aWbtcIcon,
    pool: "0xde88e80589447a4df42d7cfc247865e2c24db2ce",
    query: "0xde88e80589447a4df42d7cfc247865e2c24db2ce",
    tokens: ["WBTC"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Ethereum weETH",
    symbol: "aEthweETH",
    address: "0xBdfa7b7893081B35Fb54027489e2Bc7A38275129",
    wrapped: false,
    icon: aWeEthIcon,
    pool: "0x537e2d22cc140c15ec03e99be399223750de7737",
    query: "0x537e2d22cc140c15ec03e99be399223750de7737",
    tokens: ["weETH"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "PT weETHs 29AUG2024",
    symbol: "PT-weETHs-29AUG2024",
    address: "0xda6530efafd63a42d7b9a0a5a60a03839cdb813a",
    wrapped: false,
    icon: ptWeEthsIcon,
    pool: "0x156be1fd089e63c1bcf2a56d63d34419323e30f6",
    query: "0x3478073842c914dbb7e2c1bd46314821db1e31fb",
    tokens: ["weETHs"],
    tokenType: "Pendle",
    protocolVersion: "V3",
    fee: 0,
    metadata: "0xcaa8abb72a75c623bece1f4d5c218f425d47a0d0", // Pendle Market
  },
  {
    name: "YT weETHs 29AUG2024",
    symbol: "YT-weETHs-29AUG2024",
    address: "0x28ce264d0938c1051687febdcefacc2242ba9e0e",
    wrapped: false,
    icon: ytWeEthsIcon,
    pool: "0x9bd63fffda868be55abf32becff4406e29a62ae6",
    query: "0x641edbf1642016efa5612c5dbe4f89497587b48c",
    tokens: ["weETHs"],
    tokenType: "Pendle",
    protocolVersion: "V3",
    fee: 0,
    metadata: "0xcaa8abb72a75c623bece1f4d5c218f425d47a0d0", // Pendle Market
  },
  {
    name: "PT weETH 26SEP2024",
    symbol: "PT-weETH-26SEP2024",
    address: "0x1c085195437738d73d75dc64bc5a3e098b7f93b1",
    wrapped: false,
    icon: ptWeEthIcon,
    pool: "0x6dec8df4bf73e697faec2bccbb22d833af890a9c",
    query: "0x78a31fd34fadfae3ac0c819bb3bc2cba80703cb1",
    tokens: ["weETH"],
    tokenType: "Pendle",
    protocolVersion: "V3",
    fee: 0,
    metadata: "0xc8edd52d0502aa8b4d5c77361d4b3d300e8fc81c", // Pendle Market
  },
  {
    name: "YT weETH 26SEP2024",
    symbol: "YT-weETH-26SEP2024",
    address: "0xa54df645a042d24121a737daa89a57ebf8e0b71c",
    wrapped: false,
    icon: ytWeEthIcon,
    pool: "0xb9fc0c358f9d430e807d59d17d39dd8f8e5d75d5",
    query: "0x6535a4612c66b570e7a2de568e01dd1762589b01",
    tokens: ["weETH"],
    tokenType: "Pendle",
    protocolVersion: "V3",
    fee: 0,
    metadata: "0xc8edd52d0502aa8b4d5c77361d4b3d300e8fc81c", // Pendle Market
  },
  {
    name: "ezETH-WETH Stable Pool",
    symbol: "ezETH-WETH-BPT",
    address: "0x596192bb6e41802428ac943d2f1476c1af25cc0e",
    wrapped: false,
    icon: ezEthWethBPTIcon,
    pool: "0xb7d5632d9654686e5f00a1beef329638e6088d1e",
    query: "0x772ebe9a2895f7746f6f184f54f267859edb21fd",
    tokens: ["ezETH", "WETH"],
    tokenType: "Balancer",
    protocolVersion: "V2",
    fee: 0.04,
    metadata: "0x596192bb6e41802428ac943d2f1476c1af25cc0e000000000000000000000659", // Balancer Pool ID
  },
  {
    name: "rsETH-WETH Stable Pool",
    symbol: "rsETH-WETH-BPT",
    address: "0x58aadfb1afac0ad7fca1148f3cde6aedf5236b6d",
    wrapped: false,
    icon: rsEthWethBPTIcon,
    pool: "0xad2b30ecbd960e5fc8846fdd30f9261cacfb6cdc",
    query: "0x7537c5a65d8db2635c9eb8f713d315a7935497a2",
    tokens: ["rsETH", "WETH"],
    tokenType: "Balancer",
    protocolVersion: "V2",
    fee: 0.01,
    metadata: "0x58aadfb1afac0ad7fca1148f3cde6aedf5236b6d00000000000000000000067f", // Balancer Pool ID
  }
];

export const tokenMap: {
  [id: string]: Token | ShellToken | NFTCollection | LBPToken;
} = {};

tokens.forEach((token) => {
  if (token.symbol !== "shLP") {
    tokenMap[token.symbol] = token;
    // tokenLogos[token.symbol] = createTokenLogo(token, 83);
  }
});

shellTokens.forEach((token) => {
  tokenMap[token.name] = token;
  //   tokenLogos[token.name] = createTokenLogo(token, 50);
});

externalTokens.forEach((token) => {
  tokenMap[token.symbol] = token;
  if (!tokenColors[token.symbol]) tokenColors[token.symbol] = generateColorHex(token.tokenType!);
  //   tokenLogos[token.symbol] = createTokenLogo(token, 50);
});

externalDefiTokens.forEach((token) => {
  tokenMap[token.symbol] = token;
  tokenColors[token.symbol] = generateColorHex(token.tokenType!);
  //   tokenLogos[token.symbol] = createTokenLogo(token, 50);
});

nftCollections.forEach((collection) => {
  tokenMap[collection.symbol] = collection;

  //   tokenLogos[collection.symbol] = createTokenLogo(collection, 50);
  if (!collection.wrapped) {
    const options = { method: "GET", headers: { accept: "application/json", "X-API-KEY": openseaKey } };

    const rarityTable =
      collection.is1155 || collection.address == STREAM_ADDRESS ? [] : require(`../constants/nftRarities/${collection.symbol.toLowerCase()}-rarity.json`);
    NFT_RARITY[collection.address] = {};

    if (collection.is1155) {
      const idMap: any = {};
      for (let i = 0; i < collection.totalSupply!; i++) {
        idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol];
      }
      WRAPPED_NFT_MAP["sh" + collection.symbol] = idMap;
    } else {
      fetch(`https://api.opensea.io/api/v2/collections/${collection.slug}`, options)
        .then((response) => response.json())
        .then((response) => {
          const wrappedCollection: NFTCollection = tokenMap["sh" + collection.symbol] as NFTCollection;
          if (!collection.description) {
            collection.description = response.description;
            wrappedCollection.description = response.description;
          }
          if (!collection.totalSupply) {
            collection.totalSupply = response["total_supply"];
            wrappedCollection.totalSupply = response["total_supply"];
          }
          const idMap: any = {};
          for (let i = 0; i <= collection.totalSupply!; i++) {
            idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol];
            NFT_RARITY[collection.address][i.toString()] = rarityTable[i] ?? null;
          }
          WRAPPED_NFT_MAP["sh" + collection.symbol] = idMap;
        })
        .catch((err) => console.error(err));
    }
  }
});

lbpTokens.forEach((lbpToken) => {
  tokenMap[lbpToken.symbol] = lbpToken;
  tokenMap[lbpToken.poolName] = {
    name: lbpToken.poolName,
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: lbpToken.poolIcon,
    oceanID: lbpToken.poolOceanID,
    pool: lbpToken.poolAddress,
    tokens: [lbpToken.symbol, lbpToken.pairToken],
    fee: 0,
    tokenType: "Shell",
    protocolVersion: "V3",
  };
  //   tokenLogos[lbpToken.symbol] = createTokenLogo(lbpToken, 83);
  //   tokenLogos[lbpToken.poolName] = createTokenLogo(tokenMap[lbpToken.poolName], 50);
});

import React from "react";
import styled from "styled-components";

export type ProtocolTypeOption =
  | "Shell"
  | "Uniswap"
  | "Balancer"
  | "Curve"
  | "Sushiswap"
  | "Compound"
  | "Aave"
  | "Pendle"
  | "Beefy"
  | "Aerodrome"
  | "STIP"
  | "Velodrome"
  | undefined;

type DexBadgeProps = {
  protocol: ProtocolTypeOption;
  protocolVersion: string;
};

export const generateColorHex = (protocol: string) => {
  switch (protocol) {
    case "Shell":
      return "#00BDFF";
    case "Balancer":
      return "#A937EC";
    case "Curve":
      return "#1763FD";
    case "Uniswap":
      return "#FF2BCE";
    case "Aave":
      return "#8D70AA";
    case "SushiSwap":
      return "#3B81F6";
    case "Compound":
      return "#00D395";
    case "Pendle":
      return "#28437C";
    case "Beefy":
      return "#FFFFFF";
    case "Aerodrome":
      return "#EBE8E2";
    case "Velodrome":
      return "#EBE8E2";
    default:
      return "";
  }
};

export const DexBadge = ({ protocol, protocolVersion }: DexBadgeProps) => {
  return (
    <DexBadgeWrapper color={generateColorHex(protocol!)}>
      <DexBadgeProtocol color={generateColorHex(protocol!)}>{protocol}</DexBadgeProtocol>
      <DexBadgeProtocolVersion color={generateColorHex(protocol!)}>{protocolVersion}</DexBadgeProtocolVersion>
    </DexBadgeWrapper>
  );
};

const DexBadgeWrapper = styled.div<{ color: string }>`
  border-radius: 10px;
  border: ${({ color }) => `1px solid ${color}66`};
  border: ${({ color }) => `1px solid ${color}1A`};
  padding: 3px 6px;
  display: flex;
  width: fit-content;
`;

const DexBadgeProtocol = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

const DexBadgeProtocolVersion = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const VestingABI = [
  {
    inputs: [
      { internalType: "contract IERC20", name: "_shell", type: "address" },
      { internalType: "contract IERC20", name: "_crab", type: "address" },
      {
        internalType: "contract ISablierV2LockupLinear",
        name: "_lockupLinear",
        type: "address",
      },
      { internalType: "uint40", name: "_vestingStartTime", type: "uint40" },
      { internalType: "address", name: "_admin", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "UnAuthorized", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "streamId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Claimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256[]",
        name: "streamIds",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    inputs: [],
    name: "admin",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "claimStream",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "lockupLinear",
    outputs: [
      {
        internalType: "contract ISablierV2LockupLinear",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "shell",
    outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingStartTime",
    outputs: [{ internalType: "uint40", name: "", type: "uint40" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256[]", name: "_streamIds", type: "uint256[]" },
    ],
    name: "withdrawFromStream",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

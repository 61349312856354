import { Chain } from "@/placeholders/chains"
import { HISTORY_API } from "../../constants/urls"

const forwardTxTypes = new Set(['ERC20Wrap', 'ERC721Wrap', 'ERC1155Wrap', 'EtherWrap', 'ComputeOutputAmount'])

const compareValues = (valueOne : number, valueTwo : number) => {
    return valueOne - valueTwo > 1e-17
}

export const queryInteraction = async (chain: Chain, transactionHash: string, input: any, output: any, arrowType: string, data? : any) => {

    const apiData = data ?? await fetch(HISTORY_API(chain) + 'tx/' + transactionHash).then((response) => response.json())
        
    let interactions = apiData.interactions.map((interaction: any) => {
        interaction['traversed'] = false
        return interaction
    })
    
    const inputTokens = input.tokens
    const outputTokens = output.tokens

    let transactionPathAmounts : any[] = []
    let transactionPathTokens : any[] = []

    let nonIntersectedTokenArray : any[] = new Array(Math.max(inputTokens.length, outputTokens.length)).fill([]);
    let intersectedTokens : any[] = []

    let prePaths, postPaths
    let prePathLength, postPathLength


    if(arrowType == 'split'){

        let sharedPath = []
        let firstPath = []
        let secondPath = []

        if(forwardTxTypes.has(interactions[0].type)){ // Forward tx

            for(let i = 0; i < interactions.length; i++){

                let secondIndex : number
                for(secondIndex = interactions.length - 1; secondIndex >= i; secondIndex--){
                    if(interactions[secondIndex].inputToken == interactions[i].inputToken) 
                        break
                }

                if(i !== secondIndex){

                    sharedPath = interactions.slice(0, i)
                    firstPath = interactions.slice(i, secondIndex)
                    secondPath = interactions.slice(secondIndex)

                    break
                } else if(i == interactions.length - 1){
                    for(let j = 0; j < interactions.length - 1; j++){
                        const lastOutputAmount = parseFloat(interactions[j].outputAmount)
                        const nextInputAmount = parseFloat(interactions[j+1].inputAmount)
                        if(interactions[j].outputToken == interactions[j+1].inputToken && compareValues(lastOutputAmount, nextInputAmount)){
                            sharedPath = JSON.parse(JSON.stringify(interactions.slice(0, j)))
                            firstPath = JSON.parse(JSON.stringify(interactions.slice(j)))
                            firstPath[0].outputAmount = nextInputAmount.toString()
                            secondPath.push({...interactions[j]})
                            secondPath[0].outputAmount = (lastOutputAmount - nextInputAmount).toString()
                            secondPath[0].inputAmount = '0'
                        }
                    }
                }
            } 

        } else { // Backward tx

            for(let i = interactions.length - 1; i >= 0; i--){

                let firstIndex : number
                for(firstIndex = 0; firstIndex < i; firstIndex++){
                    if(interactions[firstIndex].inputToken == interactions[i].inputToken) 
                        break
                }

                if(i !== firstIndex){

                    sharedPath = interactions.slice(i + 1).reverse()
                    firstPath.push(interactions[firstIndex])
                    secondPath.push(interactions[i])

                    let firstCurrent = firstPath[0].outputToken
                    let secondCurrent = secondPath[0].outputToken

                    let checkFirst = true

                    for(let j = firstIndex - 1; j >= 0; j--){
                        if(checkFirst){
                            if(interactions[j].inputToken == firstCurrent){
                                firstPath.push(interactions[j])
                                firstCurrent = interactions[j].outputToken
                            } else if(interactions[j].inputToken == secondCurrent){
                                secondPath.push(interactions[j])
                                secondCurrent = interactions[j].outputToken
                            }
                            checkFirst = false
                        } else {
                            if(interactions[j].inputToken == secondCurrent){
                                secondPath.push(interactions[j])
                                secondCurrent = interactions[j].outputToken
                            } else if(interactions[j].inputToken == firstCurrent){
                                firstPath.push(interactions[j])
                                firstCurrent = interactions[j].outputToken
                            }
                            checkFirst = true
                        }

                    }

                    break
                } else if(i == 0){
                    for(let j = interactions.length - 1; j > 0; j--){
                        const lastOutputAmount = parseFloat(interactions[j].outputAmount)
                        const nextInputAmount = parseFloat(interactions[j-1].inputAmount)

                        if(interactions[j].outputToken == interactions[j-1].inputToken && compareValues(lastOutputAmount, nextInputAmount) ){
                            sharedPath = JSON.parse(JSON.stringify(interactions.slice(j+1).reverse()))
                            firstPath = JSON.parse(JSON.stringify(interactions.slice(0, j+1).reverse()))
                            firstPath[0].outputAmount = nextInputAmount.toString()
                            secondPath.push({...interactions[j]})
                            secondPath[0].outputAmount = (lastOutputAmount - nextInputAmount).toString()
                            secondPath[0].inputAmount = '0'
                            if(interactions[j-1].nftID?.length > 0){
                                firstPath[0].nftID = interactions[j-1].nftID
                                if(typeof(interactions[j-1].nftID[0]) == 'number'){
                                    secondPath[0].nftID = interactions[j].nftID.filter((nft : number) => interactions[j-1].nftID.indexOf(nft) == -1)
                                } else {
                                    secondPath[0].nftID = [{...firstPath[0].nftID[0], balance: secondPath[0].outputAmount.toString()}];
                                }
                            }
                        }
                    }
                }

            }

        }

        if(firstPath.length > 0 && firstPath[firstPath.length - 1].outputToken !== outputTokens[0]){ // Swap paths if necessary
            const temp = firstPath.slice()
            firstPath = secondPath
            secondPath = temp
        }

        const firstPathTokens : any = []
        const secondPathTokens : any = []

        const firstPathAmounts : any = []
        const secondPathAmounts : any = []

        // Combine shared, first, and second paths

        sharedPath.forEach((step : any, index : number) => { 
            firstPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})
            secondPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})

            let halfAmount = parseFloat(step.inputAmount) / 2

            firstPathAmounts.push(halfAmount)
            secondPathAmounts.push(halfAmount)

            if(index == sharedPath.length - 1){
                let halfAmount = parseFloat(step.outputAmount) / 2
                if(firstPath.length == 0){
                    firstPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                    firstPathAmounts.push(halfAmount)
                }
                if(secondPath.length == 0){
                    secondPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                    secondPathAmounts.push(halfAmount)
                }
            }
        })

        firstPath.forEach((step : any, index : number) => { 
            firstPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})
            firstPathAmounts.push(step.inputAmount)

            if(index == firstPath.length - 1){
                firstPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                firstPathAmounts.push(step.outputAmount)
            }
        })

        secondPath.forEach((step : any, index : number) => {
            secondPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})
            secondPathAmounts.push(step.inputAmount)

            if(index == secondPath.length - 1){
                secondPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                secondPathAmounts.push(step.outputAmount)
            }
        })

        transactionPathTokens = [firstPathTokens, secondPathTokens]
        transactionPathAmounts = [firstPathAmounts, secondPathAmounts]

        // Build arrays used for displaying the data

        let indexes = transactionPathTokens.map((_) => 0)
        
        while(indexes.filter((index, i) => index >= transactionPathTokens[i].length).length == 0){
            const startTokens = transactionPathTokens.map((pathToken, i) => pathToken[indexes[i]])
            const startAmounts = transactionPathAmounts.map((pathAmount, i) => parseFloat(pathAmount[indexes[i]]))

            if(startTokens.filter((token) => token.tokenID == startTokens[0].tokenID).length == startTokens.length) { // Found same token in all paths
                if(indexes.filter((index, i) => index == transactionPathTokens[i].length - 1).length > 0){ // One or more indices are at the end of its array
                    for(let i = 0; i < indexes.length; i++){
                        const tokenArray = [...nonIntersectedTokenArray[i]]
                        tokenArray.push({token: startTokens[i].tokenID, amount: startAmounts[i], nftID: startTokens[i].nftID})
                        nonIntersectedTokenArray[i] = tokenArray
                    }
                } else {
                    intersectedTokens.push({token: startTokens[0].tokenID, amount: startAmounts.reduce((partialSum, a) => partialSum + a, 0), nftID: startTokens[0].nftID})
                }
            } else {
                for(let i = 0; i < indexes.length; i++){
                    const tokenArray = [...nonIntersectedTokenArray[i]]
                    tokenArray.push({token: startTokens[i].tokenID, amount: startAmounts[i], nftID: startTokens[i].nftID})
                    nonIntersectedTokenArray[i] = tokenArray
                }
            }

            for (let i = 0; i < indexes.length; i++) indexes[i]++
        }

        transactionPathTokens.forEach((pathTokens, i) => {
            while(indexes[i] < pathTokens.length){
                const tokenArray = [...nonIntersectedTokenArray[i]]
                tokenArray.push({token: pathTokens[indexes[i]].tokenID, amount: transactionPathAmounts[i][indexes[i]], nftID: pathTokens[indexes[i]].nftID})
                nonIntersectedTokenArray[i] = tokenArray
                indexes[i]++;
            }
        })

        if(nonIntersectedTokenArray.length > 0) {
            let lengthDifference = nonIntersectedTokenArray[0].length - nonIntersectedTokenArray[1].length

            for(let i = 0; i < Math.abs(lengthDifference); i++){
                nonIntersectedTokenArray[lengthDifference < 0 ? 0 : 1].push({token: '', amount: ''})
            }
        }

        prePaths = [intersectedTokens]
        postPaths = nonIntersectedTokenArray

        prePathLength = intersectedTokens.length
        postPathLength = Math.max(nonIntersectedTokenArray[0].length, nonIntersectedTokenArray[1].length)

    } else if(arrowType == 'merge'){


        let sharedPath: any[] = []
        let firstPath: any[] = []
        let secondPath: any[] = []

        if(forwardTxTypes.has(interactions[0].type)){ // Forward swap

            for(let i = interactions.length - 1; i >= 0; i--){

                let firstIndex : number
                for(firstIndex = 0; firstIndex < i; firstIndex++){
                    if(interactions[firstIndex].outputToken == interactions[i].outputToken) 
                        break
                }

                if(i !== firstIndex){

                    sharedPath = interactions.slice(i + 1)
                    firstPath.push(interactions[firstIndex])
                    secondPath.push(interactions[i])

                    let firstCurrent = firstPath[0].inputToken
                    let secondCurrent = secondPath[0].inputToken

                    let checkFirst = true

                    for(let j = firstIndex - 1; j >= 0; j--){
                        if(checkFirst){
                            if(interactions[j].outputToken == firstCurrent){
                                firstPath.unshift(interactions[j])
                                firstCurrent = interactions[j].inputToken
                            } else if(interactions[j].outputToken == secondCurrent){
                                secondPath.unshift(interactions[j])
                                secondCurrent = interactions[j].inputToken
                            }
                            checkFirst = false
                        } else {
                            if(interactions[j].outputToken == secondCurrent){
                                secondPath.unshift(interactions[j])
                                secondCurrent = interactions[j].inputToken
                            } else if(interactions[j].outputToken == firstCurrent){
                                firstPath.unshift(interactions[j])
                                firstCurrent = interactions[j].inputToken
                            }
                            checkFirst = true
                        }

                    }
                    break
                } else if(i == 0){
                    for(let j = 1; j < interactions.length; j++){
                        const lastOutputAmount = parseFloat(interactions[j-1].outputAmount)
                        const nextInputAmount = parseFloat(interactions[j].inputAmount)
                        if(interactions[j-1].outputToken == interactions[j].inputToken && compareValues(nextInputAmount, lastOutputAmount)){
                            sharedPath = JSON.parse(JSON.stringify(interactions.slice(j+1)))
                            firstPath = JSON.parse(JSON.stringify(interactions.slice(0, j+1)))
                            firstPath[firstPath.length - 1].inputAmount = lastOutputAmount.toString()
                            secondPath.push({...interactions[j]})
                            secondPath[0].inputAmount = (nextInputAmount - lastOutputAmount).toString()
                            secondPath[0].outputAmount = '0'
                            if(interactions[j].nftID?.length > 0){
                                firstPath[firstPath.length - 1].nftID = interactions[j-1].nftID
                                if(typeof(interactions[j-1].nftID[0]) == 'number'){
                                    secondPath[0].nftID = interactions[j].nftID.filter((nft : number) => interactions[j-1].nftID.indexOf(nft) == -1)
                                } else {
                                    secondPath[0].nftID = [{...firstPath[0].nftID[0], balance: secondPath[0].inputAmount.toString()}];
                                }
                            }
                        }
                    }
                }
            }

        } else {

            for(let i = 0; i < interactions.length; i++){

                let secondIndex : number
                for(secondIndex = interactions.length - 1; secondIndex >= i; secondIndex--){
                    if(interactions[secondIndex].outputToken == interactions[i].outputToken) 
                        break
                }

                if(i !== secondIndex){

                    sharedPath = interactions.slice(0, i).reverse()
                    firstPath = interactions.slice(i, secondIndex).reverse()
                    secondPath = interactions.slice(secondIndex).reverse()

                    break

                } else if(i == interactions.length - 1){
                    for(let j = interactions.length - 1; j > 0; j--){
                        const lastOutputAmount = parseFloat(interactions[j].outputAmount)
                        const nextInputAmount = parseFloat(interactions[j-1].inputAmount)
                        if(interactions[j].outputToken == interactions[j-1].inputToken && compareValues(nextInputAmount, lastOutputAmount)){
                            sharedPath = JSON.parse(JSON.stringify(interactions.slice(0, j-1).reverse()))
                            firstPath = JSON.parse(JSON.stringify(interactions.slice(j-1).reverse()))
                            firstPath[firstPath.length - 1].inputAmount = lastOutputAmount.toString()
                            secondPath.push({...interactions[j-1]})
                            secondPath[0].inputAmount = (nextInputAmount - lastOutputAmount).toString()
                            secondPath[0].outputAmount = '0'
                        }
                    }
                }

            }
        }

        if(firstPath.length > 0 && firstPath[0].inputToken !== inputTokens[0]){ // Swap paths if necessary
            const temp = firstPath.slice()
            firstPath = secondPath
            secondPath = temp
        }

        const firstPathTokens : any = []
        const secondPathTokens : any = []

        const firstPathAmounts : any = []
        const secondPathAmounts : any = []

        // Combine shared, first, and second paths

        firstPath.forEach((step : any, index : number) => {
            firstPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})
            firstPathAmounts.push(step.inputAmount)

            if(index == firstPath.length - 1 && sharedPath.length == 0){
                firstPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                firstPathAmounts.push(step.outputAmount)
            }
        })

        secondPath.forEach((step : any, index : number) => { 
            secondPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})
            secondPathAmounts.push(step.inputAmount)

            if(index == secondPath.length - 1 && sharedPath.length == 0){
                secondPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                secondPathAmounts.push(step.outputAmount)
            }
        })

        sharedPath.forEach((step : any, index : number) => {
            firstPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})
            secondPathTokens.push({tokenID: step.inputToken, nftID: step.nftID})

            let halfAmount = parseFloat(step.inputAmount) / 2

            firstPathAmounts.push(halfAmount)
            secondPathAmounts.push(halfAmount)

            if(index == sharedPath.length - 1){
                let halfAmount = parseFloat(step.outputAmount) / 2
                firstPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                firstPathAmounts.push(halfAmount)
                secondPathTokens.push({tokenID: step.outputToken, nftID: step.nftID})
                secondPathAmounts.push(halfAmount)
            }
        })

        transactionPathTokens = [firstPathTokens, secondPathTokens]
        transactionPathAmounts = [firstPathAmounts, secondPathAmounts]

        // Build arrays used for displaying the data

        let indexes = transactionPathTokens.map((pathTokens) => pathTokens.length - 1)

        while(indexes.indexOf(-1) == -1){

            const endTokens = transactionPathTokens.map((pathToken, i) => pathToken[indexes[i]])
            const endAmounts = transactionPathAmounts.map((pathAmount, i) => parseFloat(pathAmount[indexes[i]]))

            if(endTokens.filter((token) => token.tokenID == endTokens[0].tokenID).length == endTokens.length){
                if(indexes.indexOf(0) >= 0){
                    for(let i = 0; i < indexes.length; i++){
                        const tokenArray = [...nonIntersectedTokenArray[i]]
                        tokenArray.unshift({token: endTokens[i].tokenID, amount: endAmounts[i], nftID: endTokens[i].nftID})
                        nonIntersectedTokenArray[i] = tokenArray
                    }
                } else {
                    intersectedTokens.unshift({token: endTokens[0].tokenID, amount: endAmounts.reduce((partialSum, a) => partialSum + a, 0), nftID: endTokens[0].nftID})
                }
            } else {

                for(let i = 0; i < indexes.length; i++){
                    const tokenArray = [...nonIntersectedTokenArray[i]]
                    tokenArray.unshift({token: endTokens[i].tokenID, amount: endAmounts[i], nftID: endTokens[i].nftID})
                    nonIntersectedTokenArray[i] = tokenArray
                }
            }

            for(let i = 0 ; i < indexes.length; i++) indexes[i]--
        }

        transactionPathTokens.forEach((pathTokens, i) => {
            while(indexes[i] >= 0){
                const tokenArray = [...nonIntersectedTokenArray[i]]
                tokenArray.unshift({token: pathTokens[indexes[i]].tokenID, amount: transactionPathAmounts[i][indexes[i]], nftID: pathTokens[indexes[i]].nftID})
                nonIntersectedTokenArray[i] = tokenArray
                indexes[i]--;
            }
        })
        
        if(nonIntersectedTokenArray.length > 0) {
            let lengthDifference = nonIntersectedTokenArray[0].length - nonIntersectedTokenArray[1].length

            for(let i = 0; i < Math.abs(lengthDifference); i++){
                nonIntersectedTokenArray[lengthDifference < 0 ? 0 : 1].unshift({token: '', amount: ''})
            }
        }

        prePaths = nonIntersectedTokenArray
        postPaths = [intersectedTokens]

        prePathLength = Math.max(nonIntersectedTokenArray[0].length, nonIntersectedTokenArray[1].length)
        postPathLength = intersectedTokens.length

    } else {

        const path = forwardTxTypes.has(interactions[0].type) ? interactions : interactions.slice().reverse()

        transactionPathTokens.push(path.map((step : any) => {
            return {tokenID: step.inputToken, nftID: step.nftID}
        }).concat({tokenID: path[path.length - 1].outputToken, nftID: path[path.length - 1].nftID}))

        transactionPathAmounts.push(path.map((step : any) => step.inputAmount).concat(path[path.length - 1].outputAmount))

        intersectedTokens = transactionPathTokens[0].map((token : any, i : number) => { return {token: token.tokenID, amount: transactionPathAmounts[0][i], nftID: token.nftID}})

        prePaths = [intersectedTokens]
        postPaths = []

        prePathLength = intersectedTokens.length
        postPathLength = 0
    }

    return {
        hash: transactionHash, 
        preArrowPaths: prePaths, 
        postArrowPaths: postPaths,
        preArrowPathLength: prePathLength,
        postArrowPathLength: postPathLength,
        block: apiData.block,
        fee: apiData.fee,
        srcChain: apiData.srcChain ?? '',
        destChain: apiData.destChain ?? ''
    }
}
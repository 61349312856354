import React, { useState } from "react";
import styled from "styled-components";
import { Media } from "@/styles";
import { TransactionTableSubRow } from "@/components/Table/TransactionTable/TransactionTableSubRow";
import { TimestampCell } from "./TableCells/TimestampCell";
import { TokenCell } from "./TableCells/TokenCell";
import { TransactionHashCell } from "./TableCells/TransactionHashCell";

interface TransactionTableRowMobileProps {
  transaction: any;
}

export const TransactionTale = ({ transaction }: TransactionTableRowMobileProps) => {
  const [expanded, setExpanded] = useState<boolean>(transaction.expanded);

  return (
    <DataContainer>
      <TimestampCell value={transaction.timestamp} />
      <TransactionHashCell value={transaction.transactionHash} />
      <TokenContainer>
        Sent
        <TokenCell value={transaction.input} key={transaction.transactionHash} />
      </TokenContainer>
      <TokenContainer>
        Received
        <TokenCell value={transaction.output} key={transaction.transactionHash} />
      </TokenContainer>
      {expanded ? (
        <SubRowContainer>
          <TransactionTableSubRow row={{ original: transaction }} />
        </SubRowContainer>
      ) : null}
      <ButtonContainer>
        <Divider />
        <Button onClick={() => setExpanded(!expanded)} expanded={expanded}>
          {expanded ? "Less" : "More"} details
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 8L10 13L15 8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </Button>
      </ButtonContainer>
    </DataContainer>
  );
};

const SubRowContainer = styled.div`
  grid-column: 1 / 2 span;
  background-color: #0a0e27;
  margin: -16px;
  margin-top: 16px;
  //padding: 16px;

  ${Media.tablet} {
    padding-inline: 0;
  }
`;

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  gap: 16px;
  padding: 12px 16px 16px 16px;
  background: #151530;
  border: 1px solid #1e2239;
  border-radius: 16px;
`;

const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;

const Divider = styled.div`
  height: 1px;
  background: #1e2239;
  margin: 24px 0 16px;

  ${Media.tablet} {
    margin: 0 -16px 16px;
  }
`;

const Button = styled.div<{ expanded: boolean }>`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;

  svg {
    transform: ${({ expanded }) =>
      expanded ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const ButtonContainer = styled.div`
  grid-column: 1 / 2 span;
`;
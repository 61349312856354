import { StreamABI } from "@/constants/ABI/StreamABI";
import { VestingABI } from "@/constants/ABI/VestingABI";
import { STREAM_ADDRESS, VESTING_ADDRESS } from "@/constants/addresses";
import { defaultChain } from "@/placeholders/chains";
import { addNFTBalance } from "@/store/balancesSlice";
import { useAppDispatch } from "@/store/hooks";
import { getNFTs, multicall } from "@/utils/nftHelpers";
import { reduceString } from "@/utils/reduceString";
import { ContractCallContext, ContractCallResults } from "ethereum-multicall";
import { BigNumber } from "ethers/lib/ethers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { formatEther } from "viem";
import { useAccount, useNetwork } from "wagmi";

export type VestingStream = {
  id: number;
  address: string;
  claimable: string;
  imgSrc: string;
  vesting: string;
  vested: string;
  claimed: string;
};

export const useVestingHandler = () => {
  const navigate = useNavigate();

  const [vestingStreams, setVestingStreams] = useState<VestingStream[]>([]);
  const [streamSort, setStreamSort] = useState<any>({});
  const [vestingLoading, setVestingLoading] = useState(false);

  const [refresh, setRefresh] = useState(false)

  const [vestingStartTime, setVestingStartTime] = useState(0);
  const vestingEndTime = getTimestampTwoYearsLater(vestingStartTime);

  const { address: walletAddress } = useAccount();
  const { chain: activeChain } = useNetwork();

  const dispatch = useAppDispatch()
  const validChain = activeChain?.name == defaultChain.name

  const handleRedirectToVesting = () => {
    navigate("/rewards/vesting");
    setRefresh(!refresh)
};

  function getTimestampTwoYearsLater(timestampInSeconds: number) {
    const milliseconds = timestampInSeconds * 1000;
    const date = new Date(milliseconds);

    date.setFullYear(date.getFullYear() + 2);

    return date.getTime() / 1000;
  }


  const handleSortVestingStreams = (type: 'claimable' | 'vesting') => {
    const arr = [...vestingStreams];
    if (streamSort.order == 'asc') {
      arr.sort((a, b) => parseFloat(a[type]) - parseFloat(b[type]))
      setStreamSort({type: type, order: 'desc'})
    } else if(streamSort.order == 'desc') {
      arr.sort((a, b) => a.id - b.id)
      setStreamSort({type: '', order: ''})
    } else {
      arr.sort((a, b) => parseFloat(b[type]) - parseFloat(a[type]))
      setStreamSort({type: type, order: 'asc'})
    }
    setVestingStreams(arr);
  };

  const fetchStreams = async () => {

    if(!validChain) return []
         
    const nfts = (await getNFTs(walletAddress, STREAM_ADDRESS));
    const streamIds = nfts.map((token) => token.tokenId);
    const images = nfts.map((token) => token.image.originalUrl);

    const contractCallContext: ContractCallContext[] = [
      {
        reference: "Vesting",
        contractAddress: VESTING_ADDRESS,
        abi: VestingABI as any,
        calls: [
          {
            reference: "vestingStartTime",
            methodName: "vestingStartTime",
            methodParameters: [],
          },
          {
            reference: "admin",
            methodName: "admin",
            methodParameters: [],
          },
        ],
      },
      {
        reference: "Claimable",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "withdrawableAmountOf",
            methodName: "withdrawableAmountOf",
            methodParameters: [id],
          };
        }),
      },
      {
        reference: "Deposited",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "getDepositedAmount",
            methodName: "getDepositedAmount",
            methodParameters: [id],
          };
        }),
      },
      {
        reference: "Vested",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "streamedAmountOf",
            methodName: "streamedAmountOf",
            methodParameters: [id],
          };
        }),
      },
      {
        reference: "Claimed",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "getWithdrawnAmount",
            methodName: "getWithdrawnAmount",
            methodParameters: [id],
          };
        }),
      },
    ];

    const results: ContractCallResults = await multicall.call(
      contractCallContext
    );

    setVestingStartTime(
      BigNumber.from(
        results.results["Vesting"].callsReturnContext[0].returnValues[0]
      ).toNumber()
    );

    // dispatch(updateEndTime({
    //     data: getTimeUntilEnd(BigNumber.from(
    //         results.results["Vesting"].callsReturnContext[0].returnValues[0]
    //         ).toNumber()
    //     )
    // }))

    const admin = results.results['Vesting'].callsReturnContext[1].returnValues[0].toLowerCase()

    const streams: VestingStream[] = []

    nfts.forEach((nft, index) => {
        if(nft.raw.metadata.attributes[1].value.toLowerCase() == admin){
            streams.push({
              id: streamIds[index],
              imgSrc: images[index],
              address: reduceString(STREAM_ADDRESS, 6, 4),
              claimable: formatEther(
                BigNumber.from(
                  results.results["Claimable"].callsReturnContext[index]
                    .returnValues[0]
                ).toBigInt()
              ),
              vesting: formatEther(
                BigNumber.from(
                  results.results["Deposited"].callsReturnContext[index]
                    .returnValues[0]
                ).toBigInt() -
                  BigNumber.from(
                    results.results["Vested"].callsReturnContext[index]
                      .returnValues[0]
                  ).toBigInt()
              ),
              vested: formatEther(
                BigNumber.from(
                  results.results["Vested"].callsReturnContext[index].returnValues[0]
                ).toBigInt()
              ),
              claimed: formatEther(
                BigNumber.from(
                  results.results["Claimed"].callsReturnContext[index].returnValues[0]
                ).toBigInt()
              )
            })
        }
    })

    dispatch(
        addNFTBalance({
            collection: 'STREAM',
            items: streams.map(stream => stream.id),
        })
    );

    return streams
  };

  useEffect(() => {
    let _isMounted = true;
    if (_isMounted) {
      setVestingLoading(true);
    }
    fetchStreams()
      .then((streams) => {
        if (_isMounted) {
          setVestingStreams(streams);
          setVestingLoading(false);
        }
      })
      .catch((_) => {
        if (_isMounted) {
          setVestingLoading(false);
        }
      });

    return () => {
      _isMounted = false;
    };
  }, [walletAddress, refresh, validChain]);

  return {
    vestingStreams,
    handleSortVestingStreams,
    streamSort,
    vestingStartTime,
    vestingEndTime,
    vestingLoading,
    handleRedirectToVesting,
  };
};

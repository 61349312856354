import React, { useEffect, useState, ReactNode, useContext } from "react";
import { Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { tokenColors } from "../../constants/tokenColors";
import {
  isShellToken,
  isNFTCollection,
  isLBPToken,
} from "../../utils/tokens";
import {
  getFormattedChartDate,
  getFormattedChartDayDate,
  getFormattedChartFullDate,
} from "@/utils";
import { formatDisplayShorthand } from "../../utils/formatDisplay";
import { useWidthBreakpoint } from "../../hooks";
import { breakpoints, Media } from "../../styles";
import CustomAreaChart from "../Rewards/components/Points/components/CustomAreaChart";
import {
  InputData,
  assetFilters,
  dateFilters,
  filterColors,
} from "../../types/ChartTypes";
import { addMonths, addDays, addWeeks } from "date-fns";
import { FilterSeasonDropdown } from "../Rewards/components/Points/components/FilterSeasonDropdown";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TVLChartProps {
  title: string;
  poolTVL: boolean;
  lockedAssets: InputData[];
}

interface TransformedLockedAssetsData {
  startDate: number;
  endDate: number;
  totalTvl: number;
  [key: string]: number | string | string[] | undefined;
}

export const addPeriod = (
  date: Date,
  period: "day" | "week" | "month" | "all"
): Date => {
  switch (period) {
    case "day":
      return addDays(date, 1);
    case "week":
      return addWeeks(date, 1);
    case "month":
      return addMonths(date, 1);
    case "all":
      return addMonths(date, 1);
    default:
      return date;
  }
};

export const TVLChart = (props: TVLChartProps) => {
  const { title, poolTVL, lockedAssets } = props;
  const [transformedData, setTransformedData] = useState<
    TransformedLockedAssetsData[]
  >([]);
  const [sortedKeys, setSortedKeys] = useState<{ [assetID: string]: string[] }>(
    {}
  );
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const [selectedAssetFilter, setSelectedAssetsFilter] = useState<string>(
    poolTVL ? "Pool" : "All"
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>("All");
  const { tokenMap } = useContext(ChainContext)

  const isAssetType = (token: string, selectedAssetValue: string): boolean => {
    let tokenInfo = tokenMap[token];

    if (tokenInfo == undefined) return false;

    const isNFT = isNFTCollection(tokenInfo) && !tokenInfo.wrapped;
    const isLPToken = isShellToken(tokenInfo) && !isNFT;
    const isLBP = isLBPToken(tokenInfo);
    const isToken = !isLPToken && !isNFT;

    if(selectedAssetValue == 'Pool'){
        return isToken || isNFT;
    } else if (selectedAssetValue === "Token") {
      return isToken;
    } else if (selectedAssetValue === "NFT") {
      return isNFT;
    } else if (selectedAssetValue === "LBP") {
      return isLBP;
    } else {
      return false;
    }
  };

  const initDataPoint = (entry: InputData, selectedAssetValue: string): any => {
    const dataPoint: any = {
      startDate: Math.floor(entry.date),
      endDate: Math.floor(entry.date),
      totalTvl: Object.values(entry.tokens).reduce(
        (acc, tokenValue) => acc + tokenValue,
        0
      ),
    };
    if (selectedAssetValue === "All") {
      dataPoint["NFTs"] = 0;
      dataPoint["Tokens"] = 0;
    }
    for (const token of Object.keys(tokenMap)) {
      if (
        selectedAssetValue === "All" ||
        isAssetType(token, selectedAssetValue)
      ) {
        dataPoint[token] = 0;
      }
    }
    return dataPoint;
  };

  const getLineColor = (assetID: string) => {
    if (selectedAssetFilter == "All") {
      return filterColors[assetID];
    } else {
      if (isShellToken(tokenMap[assetID])) {
        return tokenColors["shLP"];
      } else {
        return tokenColors[assetID];
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    let startDate: Date;

    const selectedDate = dateFilters.find(
      (filter) => filter.label === selectedDateFilter
    )!;

    if (selectedDate.getStart) {
      startDate = selectedDate.getStart(currentDate);
    } else {
      startDate = new Date(
        Math.min(...lockedAssets.map((entry: any) => entry.date * 1000))
      );
    }

    const newTransformedData = lockedAssets
      .filter((entry: any, index: number) => {
        const entryDate = new Date(entry.date * 1000);
        if (
          entryDate.getTime() >= startDate.getTime() ||
          index == lockedAssets.length - 1
        ) {
          startDate = addPeriod(startDate, selectedDate.period);
          return true;
        } else {
          return false;
        }
      })
      .map((entry) => {
        const dataPoint = initDataPoint(entry, selectedAssetFilter);

        for (const token in entry.tokens) {
          const tokenValue = entry.tokens[token];
          if (selectedAssetFilter === "All") {
            // If "All" is selected, aggregate by asset type
            if (isAssetType(token, "NFT")) {
              dataPoint["NFTs"] =
                ((dataPoint["NFTs"] as number) || 0) + tokenValue;
            } else if (isAssetType(token, "Token")) {
              dataPoint["Tokens"] =
                ((dataPoint["Tokens"] as number) || 0) + tokenValue;
            }
          } else if (isAssetType(token, selectedAssetFilter)) {
            // If not "All", aggregate by token
            dataPoint[token] = ((dataPoint[token] as number) || 0) + tokenValue;
          }
        }
        return dataPoint;
      });

    if (!sortedKeys[selectedAssetFilter]) {
      const tokenStartDates: { [key: string]: number } = {};
      newTransformedData.forEach((data: any, index: any) => {
        Object.entries(data).forEach(([key, value]) => {
          if (
            key !== "startDate" &&
            key !== "endDate" &&
            key !== "totalTvl" &&
            typeof value === "number"
          ) {
            if (tokenStartDates[key] == undefined && value > 0) {
              tokenStartDates[key] = index;
            }
          }
        });
      });

      setSortedKeys((prevSortedKeys) => ({
        ...prevSortedKeys,
        [selectedAssetFilter]: Object.keys(tokenStartDates).sort(
          (a, b) => tokenStartDates[a] - tokenStartDates[b]
        ),
      }));
    }
    
    setTransformedData(newTransformedData);
  }, [selectedDateFilter, selectedAssetFilter]);

  return (
    <PointsChartWrapper>
      <ChartHeader>
        {title}
        <FiltersContainer>
          {!poolTVL && (
            <FilterSeasonDropdown
              onChange={(filter: any) => setSelectedAssetsFilter(filter.value)}
              list={assetFilters}
              currentFilter={
                assetFilters.find(
                  (filter) => filter.value === selectedAssetFilter
                )!
              }
            />
          )}
          <DateFiltersContainer>
            {dateFilters.map((item, index) => (
              <DateFilterButton
                key={index}
                onClick={() => setSelectedDateFilter(item.label)}
                active={item.label === selectedDateFilter}
              >
                {item.label}
              </DateFilterButton>
            ))}
          </DateFiltersContainer>
        </FiltersContainer>
      </ChartHeader>
      {transformedData.length > 0 ? (
        <StyledResponsiveContainer width="100%" height={248}>
          <StyledAreaChart
            data={transformedData}
            margin={{
              top: isTablet ? 4 : 8,
              right: 0,
              left: isTablet ? 0 : 20,
              bottom: 0,
            }}
          >
            <defs>
              {(() => {
                const bottomMostKey = sortedKeys[selectedAssetFilter]
                  ? sortedKeys[selectedAssetFilter][0]
                  : "";
                const lineColor = getLineColor(bottomMostKey);
                return (
                  <linearGradient
                    id={bottomMostKey}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop stopColor={lineColor} />
                    <stop offset="1" stopColor={lineColor} stopOpacity="0" />
                  </linearGradient>
                );
              })()}
            </defs>
            <XAxis
              dataKey="startDate"
              scale="time"
              type="number"
              domain={["dataMin", "dataMax"]}
              color={"#7D7D97"}
              axisLine={false}
              tickLine={false}
              padding={{ left: 0, right: isTablet ? 0 : 12 }}
              interval={
                isTablet || selectedDateFilter === "All"
                  ? "preserveStartEnd"
                  : 0
              }
              minTickGap={1}
              tickFormatter={(date) =>
                selectedDateFilter == "All"
                  ? getFormattedChartDate(date)
                  : getFormattedChartDayDate(date)
              }
              tickMargin={isTablet ? 10 : 0}
            />
            <YAxis
              orientation="right"
              color={"#7D7D97"}
              axisLine={false}
              tickLine={false}
              //domain={[0, "dataMax"]}
              padding={{ top: isTablet ? 0 : 4, bottom: 0 }}
              width={42}
              tickFormatter={(tick) => `$${formatDisplayShorthand(tick, 0, 1e3)}`}
            />
            <Tooltip
              cursor={{ stroke: "#171B33", strokeWidth: 2 }}
              content={<CustomTooltip />}
            />
            {(sortedKeys[selectedAssetFilter] || []).map((key, index) => {
              if (
                key !== "startDate" &&
                key !== "endDate" &&
                key !== "sortedKeys" &&
                key !== "totalTvl"
              ) {
                const lineColor = getLineColor(key);
                return (
                  <Area
                    key={key + index}
                    type="monotone"
                    dataKey={key}
                    stroke={lineColor}
                    fillOpacity={0.3}
                    fill={index === 0 ? `url(#${key})` : lineColor}
                    activeDot={{ stroke: lineColor, fill: "#0A0E27" }}
                    stackId="1"
                    strokeWidth={2}
                  />
                );
              }
              return null;
            })}
          </StyledAreaChart>
        </StyledResponsiveContainer>
      ) : (
        <PointsChartPlaceholder>No TVL data available.</PointsChartPlaceholder>
      )}
    </PointsChartWrapper>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<Object>;
}

export const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (
    active &&
    payload &&
    payload.some(
      (item: any) =>
        item.name && item.payload[item.name] && item.payload[item.name] > 0
    )
  ) {
    return (
      <TooltipContainer>
        <TooltipDate>
          {getFormattedChartFullDate((payload[0] as any).payload.startDate)}
        </TooltipDate>
        {payload.reverse().map((item: any, index: number, array) => {
          const tokenValue = item.payload[item.name] || 0;
          const totalTvl = item.payload.totalTvl || 0;
          const percentage = ((tokenValue / totalTvl) * 100).toFixed(2);

          if (tokenValue <= 0) {
            return null;
          }

          return (
            <TooltipRow key={index}>
              <TooltipRowText>
                <TooltipRowLabel color={item.color} capitalized={false}>
                  {item.name}
                </TooltipRowLabel>
              </TooltipRowText>
              {item.name && tokenValue > 0 && (
                <TooltipRowText>
                  <TooltipRowLabel>
                    ${formatDisplayShorthand(tokenValue, 0, 1e3)}
                  </TooltipRowLabel>
                  <TooltipRowLabel color="#7D7D97" fontWeight={400}>
                    ({percentage}%)
                  </TooltipRowLabel>
                </TooltipRowText>
              )}
            </TooltipRow>
          );
        })}
      </TooltipContainer>
    );
  }
  return <></>;
};

const PointsChartWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;

  .recharts-cartesian-axis-tick {
    text {
      fill: #7d7d97;
    }
  }

  ${Media.tablet} {
    padding: 8px;
  }
`;

const PointsChartPlaceholder = styled.div`
  width: 100%;
  height: 280px;
  text-align: center;
  vertical-align: middle;
  line-height: 280px;

  a {
    text-decoration: underline;
    color: #00bdff;

    :visited {
      color: #00bdff;
    }
  }

  ${Media.mobile} {
    line-height: 24px;
    margin-top: 50%;
    font-size: 12px;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 32px;
  z-index: 9999;
  ${Media.tablet}{
    margin-top: 16px;
  }
`;

const StyledAreaChart = styled(CustomAreaChart)`
  .recharts-xAxis {
    transform: translateY(8px);
  }

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  ${Media.tablet} {
    .recharts-xAxis {
      transform: translateY(0px);
    }
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  padding: 10px 12px;
  border: 1px solid #1e2239;
  border-radius: 14px;
  background-color: #171b33;
`;

const TooltipDate = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #7d7d97;
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  gap: 8px;
`;

const TooltipRowText = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
`;

const TooltipRowLabel = styled.span<{
  color?: string;
  capitalized?: boolean;
  fontWeight?: number;
}>`
  font-size: inherit;
  line-height: inherit;
  font-weight: ${({ fontWeight }) => fontWeight ?? "inherit"};
  color: ${({ color }) => color ?? "inherit"};
  text-transform: ${({ capitalized }) => (capitalized ? "capitalize" : "none")};
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  ${Media.tablet} {
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 16px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;

  ${Media.tablet} {
    align-items: center;
    gap: 8px;
  }
`;

const DateFiltersContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const DateFilterButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 32px;
  background: #1e2239;
  border: 1px solid #292941;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ active }) => (active ? "#FFFFFF" : "#7D7D97")};

  &:hover {
    color: #ffffff;
  }

  ${Media.tablet} {
    width: 42px;
    height: 28px;
  }
`;

import { BigNumberish } from "@ethersproject/bignumber";
import { HashZero as NullMetadata } from '@ethersproject/constants'
import { wrapERC20, unwrapERC20, computeInputAmount, computeOutputAmount, wrapEther, unwrapEther, wrapERC721, unwrapERC721, wrapERC1155, unwrapERC1155 } from './ocean/interactions';
import { Interaction } from "./ocean/types";
import { ETH_ADDRESS, OLD_OCEAN_ADDRESS } from '../constants/addresses'
import { isNFTCollection, isShellV2Token } from "./tokens";
import { Edge, getTokenID, LiquidityGraph } from "./LiquidityGraph";
import { calculateWrappedTokenId } from "./ocean/utils";
import { hexZeroPad } from "ethers/lib/utils";

export type InteractionCallback = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => Interaction

export type InteractionNetwork = {[tokenID : string]: {[neighbor : string]: [{primitive: string, callback: InteractionCallback}]}}

export const buildInteractionNetwork = (graph : Record<string, Edge[]>, tokenMap: {[id: string]: any}) => {
    const interactionNetwork : InteractionNetwork = {}
    for(let token of Object.values(tokenMap)){
        const neighbors : any = {}
        const currentToken = getTokenID(token)
        if(!graph[currentToken]) continue
        graph[currentToken].forEach((neighbor) => {
            const neighborID = getTokenID(neighbor.token)
            if (!neighbors[neighborID]) {
                neighbors[neighborID] = [];
            }
            if(isNFTCollection(token)){
                if(token.is1155){
                    if(neighbor.action == 'Wrap'){
                        neighbors[neighbor.token.symbol].push({
                          primitive: "",
                          callback: (
                            amount: BigNumberish,
                            direction: boolean,
                            slippage?: string,
                            nftID?: string
                          ) => wrapERC1155(token.address, nftID!, amount),
                        });
                    } else if(neighbor.action == 'Unwrap'){
                        neighbors[neighbor.token.symbol].push({
                          primitive: "",
                          callback: (
                            amount: BigNumberish,
                            direction: boolean,
                            slippage?: string,
                            nftID?: string
                          ) => unwrapERC1155(token.address, nftID!, amount),
                        });
                    } else {
                        neighbors[neighbor.token.symbol].push({
                          primitive: token.fractionalizer!,
                          callback: (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                token.fractionalizer!, 
                                calculateWrappedTokenId(token.address, nftID!), 
                                neighbor.token.oceanID!, 
                                amount, 
                                hexZeroPad(nftID!, 32) ?? NullMetadata
                            )
                          }
                        });
                    }
                } else {
                    if(neighbor.action == 'Wrap'){
                        neighbors[neighbor.token.symbol].push({
                          primitive: "",
                          callback: (
                            amount: BigNumberish,
                            direction: boolean,
                            slippage?: string,
                            nftID?: string
                          ) => wrapERC721(token.address, nftID!),
                        });
                    } else if(neighbor.action == 'Unwrap'){
                        neighbors[neighbor.token.symbol].push({
                          primitive: "",
                          callback: (
                            amount: BigNumberish,
                            direction: boolean,
                            slippage?: string,
                            nftID?: string
                          ) => unwrapERC721(token.address, nftID!),
                        });
                    } else {
                        neighbors[neighbor.token.symbol].push({
                          primitive: token.fractionalizer!,
                          callback: (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                token.fractionalizer!, 
                                calculateWrappedTokenId(token.address, nftID!), 
                                neighbor.token.oceanID!, 
                                amount, 
                                hexZeroPad(nftID!, 32) ?? NullMetadata
                            )
                          }
                        });
                    }
                }
            } else if (isShellV2Token(token)) {
                if(neighbor.action == 'Wrap'){
                    neighbors[neighbor.token.symbol].push({
                      primitive: "",
                      callback: (
                        amount: BigNumberish,
                        direction: boolean,
                        slippage?: string,
                        nftID?: string
                      ) => wrapERC1155(OLD_OCEAN_ADDRESS, token.oceanID!, amount),
                    });
                } else if(neighbor.action == 'Unwrap'){
                    neighbors[neighbor.token.symbol].push({
                      primitive: "",
                      callback: (
                        amount: BigNumberish,
                        direction: boolean,
                        slippage?: string,
                        nftID?: string
                      ) => unwrapERC1155(OLD_OCEAN_ADDRESS, neighbor.token.oceanID!, amount),
                    });
                } else {
                    neighbors[getTokenID(neighbor.token)].push({
                      // @ts-ignore
                      primitive: tokenMap[neighbor.pool].pool,
                      callback: (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                        const compute = direction ? computeOutputAmount : computeInputAmount;
                        return compute(
                            // @ts-ignore
                            tokenMap[neighbor.pool].pool, 
                            token.oceanID!, 
                            neighbor.token.oceanID!, 
                            amount, 
                            slippage ?? NullMetadata
                        )
                      }
                    });
                }
            } else {
                if(neighbor.action == 'Wrap'){
                    neighbors[neighbor.token.symbol].push({
                      primitive: "",
                      callback: (
                        amount: BigNumberish,
                        direction: boolean,
                        slippage?: string,
                        nftID?: string
                      ) => token.address == ETH_ADDRESS ? wrapEther(amount) : wrapERC20(token.address, amount),
                    });
                } else if(neighbor.action == 'Unwrap'){
                    neighbors[neighbor.token.symbol].push({
                      primitive: "",
                      callback: (
                        amount: BigNumberish,
                        direction: boolean,
                        slippage?: string,
                        nftID?: string
                      ) => token.address == ETH_ADDRESS ? unwrapEther(amount) : unwrapERC20(token.address, amount),
                    });
                } else {
                    if(isNFTCollection(neighbor.token)){
                        neighbors[getTokenID(neighbor.token)].push({
                          primitive: neighbor.token.fractionalizer!,
                          callback: (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                neighbor.token.fractionalizer, 
                                token.oceanID!, 
                                calculateWrappedTokenId(neighbor.token.address, nftID!), 
                                amount, 
                                hexZeroPad(nftID!, 32) ?? NullMetadata
                            )
                          }
                        });
                    } else {
                        neighbors[getTokenID(neighbor.token)].push({
                          // @ts-ignore
                          primitive: getPoolToken(neighbor.pool, tokenMap).pool,
                          callback: (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                getPoolToken(neighbor.pool, tokenMap).pool, 
                                token.oceanID!, 
                                neighbor.token.oceanID!, 
                                amount, 
                                slippage ?? NullMetadata
                            )
                          }
                        });
                    }
                    
                }
            }
        })
        interactionNetwork[currentToken] = neighbors
    }
    return interactionNetwork
}

export const findPath = (
    start: string,
    end: string,
    fromInputs: boolean,
    path: Edge[],
    liquidityGraph: LiquidityGraph,
    tokenMap: {[id: string] : any}
): any => {

    const interactionNetwork = buildInteractionNetwork(liquidityGraph.adjustGraph([tokenMap[start], tokenMap[end]]), tokenMap)

    const interactions: InteractionCallback[] = []
    const tokens: any[] = []

    if(fromInputs){
        for (let j = 0; j < path.length - 1; j++) {
            const action = path[j+1].action

            const current = getTokenID(path[j].token)
            const next = getTokenID(path[j + 1].token)

            let primitive = ''

            if(action != 'Wrap' && action != 'Unwrap'){
                if(action == 'Fractionalize'){
                    // @ts-ignore
                    primitive = path[j].token.fractionalizer
                } else {
                    // @ts-ignore
                    primitive = getPoolToken(path[j+1].pool, tokenMap).pool
                }
            }

            interactions.push(interactionNetwork[current][next].find(edge => edge.primitive == primitive)?.callback!)
            tokens.push(tokenMap[current])
        }
    } else {
        for (let j = path.length - 1; j >= 1; j--) {
            const action = path[j].action
    
            const current = getTokenID(path[j].token)
            const next = getTokenID(path[j - 1].token)

            let primitive = ''

            if(action != 'Wrap' && action != 'Unwrap'){
                if(action == 'Unfractionalize'){
                    // @ts-ignore
                    primitive = path[j].token.fractionalizer
                } else {
                    // @ts-ignores
                    primitive = tokenMap[path[j].pool].pool
                }
            }

            interactions.push(interactionNetwork[next][current].find(edge => edge.primitive == primitive)?.callback!)
            tokens.push(tokenMap[current])
        }
    }

    return {interactions: interactions, tokens: tokens};
}

export const getPoolToken: any = (tokenID: string, tokenMap: any) => {
    return tokenID.includes('UNI') ? tokenMap['UNI'] : tokenID.includes('AERO') ? tokenMap['Aero'] : tokenID.includes('VELO') ? tokenMap['Velo'] : tokenMap[tokenID]
}
import React, { useMemo } from "react";
import * as S from "./ConfirmationModals.styled";
import warningIcon from "@/assets/icons/warning-icon-modal.svg";
import { useRewardsContext } from "../../RewardsContext";
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";

export const AlertModalBody = ({isClaimVariant}: any) => {
    const { amountToStake, handleConfirmStakeAlert, handleChangeLockPeriod, handleClaimAndStakeChangeLockPeriod, currentLockPeriod, handleConfirmClaimAndStakeAlert, getUnlockTime, savedLockPeriod } = useRewardsContext();
    const { endTime } = {...savedLockPeriod}
    const durationText = useMemo(() => {
        if(currentLockPeriod.label == '~2Y'){
            return '~2 years'
        } else if(currentLockPeriod.label == '~1Y'){
            return '~1 year'
        } else if(currentLockPeriod.label == '~6M'){
            return '~6 months'
        } else if(currentLockPeriod.label == '~3M'){
            return '~3 months'
        } else if(currentLockPeriod.label == '~1M'){
            return '~1 month'
        } else {
            return '~1 week'
        }
    }, [currentLockPeriod])

    return (
        <S.ConfirmationBodyContainer>
            <S.ErrorIcon src={warningIcon} alt="warning icon" />
            <S.SuccessTextWrapper>
                <S.SuccessTitle>
                    Are you sure you want to stake
                    <span> {formatDisplay(amountToStake.toString(), 2)} SHELL </span>
                    for {durationText}?
                </S.SuccessTitle>
                <S.SuccessDescription>
                    Your SHELL tokens will be locked and earning yield until {timestampToDate((endTime ? endTime : getUnlockTime(currentLockPeriod.label)).toString())}.
                </S.SuccessDescription>
            </S.SuccessTextWrapper>
            <S.ButtonsWrapper>
                <S.CustomPrimaryBtn onClick={isClaimVariant ? handleConfirmClaimAndStakeAlert : handleConfirmStakeAlert}>Yes, stake SHELL</S.CustomPrimaryBtn>
                <S.CustomSecondaryBtn onClick={isClaimVariant ? handleClaimAndStakeChangeLockPeriod : handleChangeLockPeriod}>Change lock period</S.CustomSecondaryBtn>
            </S.ButtonsWrapper>
        </S.ConfirmationBodyContainer>
    )
}
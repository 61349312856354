export const OLD_OCEAN_ADDRESS = '0xC32eB36f886F638fffD836DF44C124074cFe3584'
export const OCEAN_ADDRESS = "0x96B4f4E401cCD70Ec850C1CF8b405Ad58FD5fB7a";

export const SHELL_ADDRESS = '0xe47ba52f326806559c1deC7ddd997F6957d0317D'

export const TOUCAN_ADDRESS = '0x642FfAb2752Df3BCE97083709F36080fb1482c80'
export const SMOL_ADDRESS = '0xA7f1462e0EcdeEbDeE4FaF6681148Ca96Db78777'

export const WRAPPED_ETHER_ID = "0x97a93559a75ccc959fc63520f07017eed6d512f74e4214a7680ec0eefb5db5b4";
export const ETH_ADDRESS = "0x0000000000000000000000000000004574686572";

export const OLD_BOOTY_ADDRESS = "0x619F1f68a9a3cF939327801012E12f95B0312bB9"
export const BOOTY_ADDRESS = "0x4FcFf952894e0fD287322e58784ED082fDB6f931"

export const CRAB_ADDRESS = '0xcf17664006851A27d5Bd93d497f30853AC558792';

export const VESTING_ADDRESS = '0x53b86E1c9cBfdC8F784082C1B4A5cf845d62f887';
export const STREAM_ADDRESS = '0xFDD9d122B451F549f48c4942c6fa6646D849e8C1';
export const STAKING_ADDRESS = '0x68313f1a6a60d05dd767811643406339e8e8d034'

import React, { useContext } from "react";
import styled from "styled-components";
import { Box } from "@/components/Layout/Box";
import { EarnShellPointsBox } from "./EarnShellPointsBox";
import provideLiquidity from "@/assets/points/provide-liquidity.png";
import stakeTokens from "@/assets/points/stake-shell.png";
import wrapNFTs from "@/assets/points/wrap-nfts.png";
import { useNavigate } from "react-router";
import { Media } from "@/styles";
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const EarnShellPointsBoxes = () => {
  const navigate = useNavigate();

  const { tokenMap } = useContext(ChainContext)

  const boxesMap = [
    {
        image: stakeTokens,
        headerText: "Stake SHELL",
        descriptionText: "Stake your SHELL to earn more SHELL",
        buttonText: "Stake Tokens",
        to: '/rewards/staking',
        onClickTokenState: {}
    },
    {
      image: provideLiquidity,
      headerText: "Provide Liquidity",
      descriptionText: "Earn yield from swap fees and potential SHELL rewards",
      buttonText: "Deposit Tokens",
      to: '/trade',
      onClickTokenState: {initInput: [tokenMap['SHELL'], tokenMap['ETH']], initOutput: [tokenMap['SHELL+ETH']]}
    },
    // {
    //   image: wrapNFTs,
    //   headerText: "Wrap NFTs",
    //   descriptionText: "Wrap your Toucans into the Ocean",
    //   buttonText: "Wrap Toucans",
    //   onClickTokenState: {initInput: [tokenMap['TOUCAN']], initOutput: [tokenMap['shTOUCAN']]}
    // },
  ];
  return (
    <View>
      <Header>Start earning SHELL</Header>
      <Description>Earn SHELL using the following ways</Description>
      <BoxesContainer>
        {boxesMap.map((boxProps, index) => (
          <EarnShellPointsBox key={index} {...boxProps} />
        ))}
      </BoxesContainer>
    </View>
  );
};

const View = styled(Box)`
  border: 1px solid #171b33;
  padding: 24px;
  margin-top: 24px;

  ${Media.tablet} {
    padding: 16px 12px;
  }
`;

const Header = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.72px;
`;

const Description = styled.div`
  margin-top: 12px;
  color: var(--grey-3, #7d7d97);
  font-size: 16px;
  font-weight: 400;
`;

const BoxesContainer = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 24px;

  ${Media.tablet} {
    flex-direction: column;
    gap: 8px;
  }
`;

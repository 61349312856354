import React from "react";
import styled from "styled-components";
import { Toaster } from "react-hot-toast";
import * as S from "../AirDrop.styled";
import { SeasonBox } from "./SeasonBox";
import { useRewardsContext } from "../../../RewardsContext";
import { CustomModal } from "@/components/CustomModal/CustomModal";
import { ClaimTokenModalBody } from "../../modals/ClaimTokenModalBody";
import crabIcon from '@/assets/seasons/CRAB.svg'

export const ClaimCrab = () => {
  const { 
    isCrabClaimModalOpen,
    handleCloseCrabClaimModal,
    userSeasons,
    unclaimedCrab,
    crabClaimSuccess,
    crabClaimTxSubmitted
  } = useRewardsContext();

  const crabToken = {
    name: 'Crab',
    symbol: 'CRAB',
    icon: crabIcon
  }

  const defaultUserSeasons = [
    {
      name: "Season Zero",
      status: "",
      value: 0
    },
    {
      name: "Season One",
      status: "",
      value: 0
    },
    {
      name: "Season Two",
      status: "",
      value: 0
    },
    {
      name: "Season Three",
      status: "",
      value: 0
    }
  ];

  const displayedSeasons = userSeasons.length > 0 ? userSeasons : defaultUserSeasons;

  return (
    <>
      <Toaster />
      <SeasonsOutline>
        <SeasonsWrapper>
          <S.SeasonsSummaryWrapper>
          {displayedSeasons.map((season) => (
            <SeasonBox
              key={season.name}
              seasonName={season.name}
              crabsValue={season.value}
              status={season.status}
            />
          ))}
          </S.SeasonsSummaryWrapper>
        </SeasonsWrapper>
      </SeasonsOutline>
      <CustomModal
        isOpen={isCrabClaimModalOpen}
        onClose={() => handleCloseCrabClaimModal()}
        mobileStyle={{ minHeight: "50%", maxHeight: "100%" }}
        withBackdropBlur
        withOutsideClick
      >
        <ClaimTokenModalBody 
          token={crabToken} 
          unclaimedAmount={unclaimedCrab} 
          handleCloseClaimModal={handleCloseCrabClaimModal} 
          claimSuccess={crabClaimSuccess} 
          claimTxSubmitted={crabClaimTxSubmitted} 
        />
      </CustomModal>
    </>
  );
};

export const SeasonsOutline = styled.section`
  border: 1px solid var(--dark-3, #1E2239);
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 12px;

  width: 100%;
  min-height: 0;
`;

export const SeasonsWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  min-height: 0;
`;


import React from "react";
import styled from "styled-components";
import { useRewardsContext } from "../../../RewardsContext";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";

interface OtherAirdropClaimButtonProps {
  row: any;
}

export const OtherAirdropClaimButton = ({ row }: OtherAirdropClaimButtonProps) => {
  const { handleOtherAirdropClaim } = useRewardsContext();
  const isClaimed = row.claimed;
  const reward = row.reward;
  const isDisabled = isClaimed || reward === 0;

  return (
    <CustomSecondaryButton
      onClick={() => handleOtherAirdropClaim(row)}
      disabled={isDisabled}
    >
      {isClaimed ? "Claimed" : "Claim"}
    </CustomSecondaryButton>
  );
};

const CustomSecondaryButton = styled(ButtonSecondary)`
  padding: 17px 24px;
  height: unset;
  width: auto;
  border-width: 2px;
  border-radius: 16px;

  ${({ disabled }) =>
    disabled &&
    `
        color: var(--grey-4, #464659);
        pointer-events: none;
        border-color: #1E2239;
        background: #151530;
    `}

  ${Media.tablet} {
    padding: 12px 16px;

    border-radius: 12px;
    font-size: 14px;
  }
`;

import { Box } from "@/components/Layout/Box";
import { Media } from "@/styles";
import styled from "styled-components";
import rewardsCoinsBg from "@/assets/reward-coins-bg.png";

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 300px 0 300px 0;
`;

export const View = styled(Box)`
  padding: 24px 24px 40px;
  background-image: url(${rewardsCoinsBg});
background-position: right 3px;
background-repeat: no-repeat;
background-blend-mode: luminosity;

  ${Media.tablet} {
    padding: 16px;
    background-image: none;
  }
`;

export const HeadWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 38px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
margin-top: 8px;
`;

export const TitleWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
`;

export const Title = styled.h1`
color: #FFF;
/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`;

export const Description = styled.p`
color: var(--grey-3, #7D7D97);
/* Text 4/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 16.32px */
`;

export const NavWrapper = styled.ul`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 32px;
margin-right: -25px;
margin-left: -24px;
width: calc(100% + 40px);
border-bottom: 1px solid #171B33;

${Media.tablet} {
    gap: 24px;
  }

  ${Media.mobile} {
    gap: 20px;
  }

  ${Media.smallMobile} {
    gap: 12px;
  }
`;

export const NavItem = styled.li<{ isActiveTab: boolean }>`
list-style: none;
padding: 0 0 16px 0;
text-align: center;
position: relative;

&:hover {
  a {
    color: #fff;
  }
}

&::after {
  content: "";
  transition: all .35s ease-in-out;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  transform: translateY(25%);
  opacity: ${({isActiveTab}) => isActiveTab ? "1" : "0"};
  background-color: ${({isActiveTab}) => isActiveTab ? "#37DCF2" : "none"};
}
/* Text 2/Regular */
a {
  color: ${({ isActiveTab }) => isActiveTab ? "#FFF" : "#7D7D97"};
  font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 16px;
}

&:nth-child(1) {
  margin-left: 30px;
}
`;
import React from "react";
import { Toaster } from "react-hot-toast";
import { useRewardsContext } from "../../../RewardsContext";
import { CustomModal } from "@/components/CustomModal/CustomModal";
import { ClaimOtherModalBody } from "../../modals/ClaimOtherModalBody";

export const ClaimOther = () => {
  const { handleOtherAirdropClose, isOtherAirDropClaimModalOpen } = useRewardsContext();

  return (
    <>
      <Toaster />
      <CustomModal
        isOpen={isOtherAirDropClaimModalOpen}
        onClose={handleOtherAirdropClose}
        mobileStyle={{ minHeight: "50%", maxHeight: "100%" }}
        withBackdropBlur
        withOutsideClick
      >
        <ClaimOtherModalBody />
      </CustomModal>
    </>
  );
};


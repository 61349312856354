import { createSlice } from '@reduxjs/toolkit'

interface VestingState {
    endTime: string,
}

const initialState : VestingState = {endTime: ''}

const vestingSlice = createSlice({
  name: 'vesting',
  initialState,
  reducers: {
    updateEndTime: (state, action) => {
        return {
            ...state,
            endTime: action.payload.data
        }
    },
    clearVesting: () => {
        return initialState
    },
  }
})

export const { updateEndTime, clearVesting } = vestingSlice.actions

export default vestingSlice.reducer
import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import { useWidthBreakpoint } from "@/hooks";
import { breakpoints, Media } from "@/styles";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Steper } from "./Steper";
import { scrollbar } from "@/styles/scrollbar";

type Step = {
    title: React.ReactNode;
    description: React.ReactNode;
    image: string;
}

interface SectionWithSwiperProps {
    steps: Step[];
    finalText: string;
    finalAction?: () => void;
}

export const SectionWithSwiper = ({ steps, finalText, finalAction }: SectionWithSwiperProps) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const isMobile = useWidthBreakpoint(breakpoints.mobile);
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };

    useEffect(() => {
        setCurrentStep(0);
    }, [])

    return (
        <StepContent>
            <StepImageWrapper>
                <StepImage src={steps[currentStep].image} alt="step image" />
            </StepImageWrapper>
            <ContentWrapper>
                <StepTitle>{steps[currentStep].title}</StepTitle>
                <StepDescription>{steps[currentStep].description}</StepDescription>
            </ContentWrapper>
            <ActionContainer flexEnd={steps.length === 1}>
                {steps.length > 1 ? (
                    <Steper totalSteps={steps.length} currentStep={currentStep} setCurrentStep={setCurrentStep} />
                ) : null}
                <ActionButtonsContainer>
                    {currentStep !== 0 ? (
                        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
                    ) : null}
                    {
                        currentStep !== steps.length - 1 ? (
                            <PrimaryButton onClick={handleNext}>Next</PrimaryButton>
                        ) : null
                    }
                    {currentStep === steps.length - 1 ? (
                        <PrimaryButton onClick={finalAction}>{finalText}</PrimaryButton>
                    ) : null}
                </ActionButtonsContainer>
            </ActionContainer>
        </StepContent>
    )
};

const StepContent = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
width: 100%;
min-height: 616px;
max-height: 616px;

${Media.mobile} {
    min-height: calc(100dvh - 142px);
    max-height: calc(100dvh - 142px);
}
`;

const StepImageWrapper = styled.div`
  flex: 1;
  min-height: 341px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media.mobile} {
    min-height: unset;
    flex: unset;
  }
`;

const StepImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 341px;
  object-fit: cover; 
  object-position: top;

  ${Media.mobile} {
    height: auto;
    object-fit: contain;
    object-position: top;
  }
`;

const StepTitle = styled.h3``;

const StepDescription = styled.p`
color: #7D7D97;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 19.04px */
`;

const ActionContainer = styled.div<{flexEnd: boolean}>`
display: flex;
justify-content: ${({flexEnd}) => flexEnd ? "flex-end" : "space-between"};
align-items: center;
padding: 24px;
border-top: 1px solid #171B33;
min-height: 96px;
max-height: 96px;

${Media.mobile} {
    padding: 12px 24px 24px 24px;
    position: fixed;
    bottom: 0;
    background: #0A0E27;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
    min-height: unset;
    max-height: unset;
}
`;

const ActionButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 16px;

${Media.mobile} {
    width: 100%;
};
`;

const ContentWrapper = styled.div`
padding: 0 24px;
display: flex;
flex-direction: column;
gap: 16px;
width: 100%;
min-height: 120px;
overflow-y: auto;
${scrollbar()}

${Media.mobile} {
    padding-bottom: calc(24px + 133px);
}
`;

const SecondaryButton = styled(ButtonSecondary)`
width: 140px;
border-radius: 12px;
height: unset;
padding: 14.5px 20px;
text-wrap: nowrap;

${Media.mobile} {
    width: 100%;
}
`;

const PrimaryButton = styled(ButtonPrimary)`
width: 140px;
border-radius: 12px;
height: unset;
padding: 14.5px 20px;
text-wrap: nowrap;

${Media.mobile} {
    width: 100%;
    border: 1px solid transparent;
}
`;
import React from "react";
import styled from "styled-components";
import arrowDown from "@/assets/icons/small-arrow-down.svg";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";
import { VestingStreamItem } from "./VestingStreamItem";
import { Media } from "@/styles";
import { SortIcon } from "@/components/Icons/SortIcon";

export const VestingStreams = () => {
    const { vestingStreams, handleSortVestingStreams, streamSort } = useRewardsContext();
    return (
        <VestingStreamsContainer>
            <TitleDescriptionWrapper>
                <Title>Shellstreams</Title>
                <Description>
                    NFTs that represent your SHELL vesting streams. Unclaimed SHELL remains inside a given Shellstream until you claim it.
                </Description>
            </TitleDescriptionWrapper>
            <VestingStreamsListWrapper>
                <VestingLabelsWrapper>
                    <VestingLabel style={{width: '36%'}}>
                        Stream
                    </VestingLabel>
                    <SorterWrapper onClick={() => handleSortVestingStreams('vesting')} style={{width: '20%'}}>
                        <VestingLabel>
                            Vesting
                        </VestingLabel>
                        <SortIcon isSortedDesc={streamSort.type != 'vesting' || (streamSort.type == 'vesting' && streamSort.order != 'asc')} isSorted={streamSort.type == 'vesting'}/>
                    </SorterWrapper>
                    <SorterWrapper onClick={() => handleSortVestingStreams('claimable')} style={{width: '20%'}}>
                        <VestingLabel>
                            Claimable
                        </VestingLabel>
                        <SortIcon isSortedDesc={streamSort.type != 'claimable' || (streamSort.type == 'claimable' && streamSort.order != 'asc')} isSorted={streamSort.type == 'claimable'}/>
                    </SorterWrapper>
                </VestingLabelsWrapper>
                <VestingStreamsList>
                    {vestingStreams.map((vestingStream, i) => (
                        <VestingStreamItem key={`${vestingStream.id}-${i}`} {...vestingStream} />
                    ))}
                </VestingStreamsList>
            </VestingStreamsListWrapper>
        </VestingStreamsContainer>
    )
};

const VestingStreamsContainer = styled.div`
display: flex;
flex-direction: column;
gap: 22px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
`;

const TitleDescriptionWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
`;

const Title = styled.h2`
color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const Description = styled.p`
text-align: left;
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const VestingStreamsListWrapper = styled.div`
border-top: 1px solid #171B33;
display: flex;
flex-direction: column;
width: 100%;
justify-content: flex-start;
align-items: flex-start;
gap: 10px;

${Media.tablet} {
    border-top: none;
}
`;

const VestingLabelsWrapper = styled.div`
display: flex;
// justify-content: space-between;
align-items: center;
margin-top: 12px;
width: 100%;
padding-left: 18px;

${Media.tablet} {
    gap: unset;
    justify-content: space-between;
    width: 100%;
    display: none;
  }
`;

const SorterWrapper = styled.div`
display: flex;
align-items: center;
gap: 6px;
cursor: pointer;

${Media.tablet} {
    margin-right: 0;
  }
`;

// const SortIcon = styled.img<{ transformToDown: boolean, sorted: boolean }>`
// width: 16px;
// height: 16px;
// transform: ${({ transformToDown }) => transformToDown ? "rotate(180deg)" : "none"};
// color: ${({ sorted }) => (sorted ? "#00BDFF" : "#FFFFFF")};
// transition: transform 0.35s ease-in-out;
// `;

const VestingLabel = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

const VestingStreamsList = styled.ul`
display: flex;
flex-direction: column;
gap: 5px;
width: 100%;
align-items: flex-start;
justify-content: flex-start;

${Media.tablet} {
    gap: 10px;
  }
`;

const PlaceholderText = styled.span`
  width: 100%;
  padding: 24px;
  text-align: center;
`



import React from "react";
import styled from "styled-components";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import { ArrowIcon } from "@/components/Icons/ArrowIcon";
import { GaugeClaimButton } from "./GaugeClaimButton";

interface StakeButtonProps {
  row: any;
  isActive: boolean;
  chosenAction: string;
  onClick: (action: string) => void;
  isSTIPTale?: boolean;
}

export const StakeButtons = ({ row, isActive, chosenAction, onClick, isSTIPTale }: StakeButtonProps) => {
  if (isSTIPTale) {
    if (isActive) {
      return (
        <Column>
          <Row chosenAction={chosenAction} isSTIPTale={isSTIPTale}>
              <CustomSecondaryButton onClick={() => onClick("Stake")} selected={chosenAction == "Stake"}>
                Stake
              </CustomSecondaryButton>
              <CustomSecondaryButton onClick={() => onClick("Unstake")} disabled={row.staked == 0} selected={chosenAction == "Unstake"}>
                Unstake
              </CustomSecondaryButton>
            <GaugeClaimButton row={row} isActive={isActive} />
          </Row>
        </Column>
      );
    } else {
      return (
        <Column>
          <Row chosenAction={chosenAction} isSTIPTale={isSTIPTale}>
              <CustomSecondaryButton onClick={() => onClick("Stake")} disabled={true} style={{ opacity: "0" }}>
                {chosenAction == "Stake" ? "Back" : "Stake"}
              </CustomSecondaryButton>
              <CustomSecondaryButton onClick={() => onClick("Unstake")} disabled={row.staked == 0} selected={chosenAction == "Unstake"}>
                Unstake
              </CustomSecondaryButton>
            <GaugeClaimButton row={row} isActive={true} />
          </Row>
        </Column>
      );
    }
  } else if (isActive) {
    return (
      <Column>
        <Row chosenAction={chosenAction}>
          {(chosenAction == "" || chosenAction == "Stake") && (
            <CustomSecondaryButton onClick={() => onClick("Stake")}>{chosenAction == "Stake" ? "Back" : "Stake"}</CustomSecondaryButton>
          )}
          {(chosenAction == "" || chosenAction == "Unstake") && (
            <CustomSecondaryButton onClick={() => onClick("Unstake")} disabled={row.staked == 0}>
              {chosenAction == "Unstake" ? "Back" : "Unstake"}
            </CustomSecondaryButton>
          )}
          {chosenAction.length > 0 && (
            <Arrow>
              <ArrowIcon bridge />
            </Arrow>
          )}
        </Row>
        {chosenAction == "" && <GaugeClaimButton row={row} isActive />}
      </Column>
    );
  } else {
    return (
      <Row chosenAction={chosenAction}>
        {(chosenAction == "" || chosenAction == "Unstake") && (
          <CustomSecondaryButton onClick={() => onClick("Unstake")} disabled={row.staked == 0}>
            {chosenAction == "Unstake" ? "Back" : "Unstake"}
          </CustomSecondaryButton>
        )}
        {chosenAction == "" && <GaugeClaimButton row={row} isActive={false} />}
        {chosenAction.length > 0 && (
          <Arrow>
            <ArrowIcon bridge />
          </Arrow>
        )}
      </Row>
    );
  }
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;
`;

const Row = styled.div<{ chosenAction: string; isSTIPTale?: boolean }>`
  display: flex;
  justify-content: ${({ chosenAction }) => (chosenAction === "" ? "space-between" : "end")};

  ${({ isSTIPTale }) =>
    isSTIPTale &&
    `

      flex-direction: row;
      align-items: stretch;
      flex-wrap: nowrap;
      gap: 8px;
    
  `}
`;

const CustomSecondaryButton = styled(ButtonSecondary)<{ selected?: boolean }>`
  padding: 17px 24px;
  height: unset;
  width: auto;
  border-width: 2px;
  border-radius: 16px;

  ${({ disabled }) =>
    disabled &&
    `
        color: var(--grey-4, #464659);
        pointer-events: none;
        border-color: #1E2239;
        background: #151530;
    `}

  ${Media.tablet} {
    padding: 12px 16px;
    border-radius: 12px;
    font-size: 14px;
  }

  &:hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    border: solid 2px #000e47;
    color: #000e47;
  }

  ${({ selected }) =>
    selected &&
    `
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    border: solid 2px #000e47;
    color: #000e47;
    `}
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 0;
  color: white;

  & > svg {
    width: 28px;
    height: 28px;
    color: #00bdff;
    transform: rotate(-90deg); /* Rotate the svg 90 degrees counter clockwise */
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Media } from '@/styles';

const tooltipBg = "#151530";
const tooltipTextColor = "#fff";
const tooltipMargin = "10px";
const tooltipArrowSize = "8px";
const tooltipBorder = "#171B33";

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const TooltipTip = styled.div<{ isActive: boolean }>`
  position: absolute;
  border-radius: 12px;
  left: 250%;
  transform: translateX(-50%);
  border: 1px solid ${tooltipBorder};
  box-shadow: 0px 8px 32px 0px rgba(10, 14, 39, 0.80);
  padding: 16px;
  color: ${tooltipTextColor};
  background: ${tooltipBg};
  z-index: 100;
  width: 250px;
  opacity: ${({ isActive }) => isActive ? "1" : "0"};
  visibility: ${({ isActive }) => isActive ? "visible" : "hidden"};
  transition: all .35s ease-in-out;
`;

export const TooltipTop = styled(TooltipTip)`
  top: calc(${tooltipMargin} * -1);
  transform: translateX(-50%) translateY(-100%);
  &::before {
    top: 100%;
    border-top-color: ${tooltipBg};
  }

  ${Media.tablet} {
    top: auto;
  }
`;

export const TooltipRight = styled(TooltipTip)`
  left: calc(100% + ${tooltipMargin});
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: calc(${tooltipArrowSize} * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${tooltipBg};
  }
`;

export const TooltipBottom = styled(TooltipTip)`
  bottom: calc(${tooltipMargin} * -1);
  &::before {
    bottom: 100%;
    border-bottom-color: ${tooltipBg};
  }
`;

export const TooltipLeft = styled(TooltipTip)`
  left: auto;
  right: calc(100% + ${tooltipMargin});
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: auto;
    right: calc(${tooltipArrowSize} * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${tooltipBg};
  }
`;

export const TooltipContent = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
gap: 8px;
flex-direction: column;
`;

export const TooltipTitle = styled.p`
color: var(--White, #FFF);
/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const TooltipSubtext = styled.p`
color: var(--grey-3, #7D7D97);
/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const TooltipImage = styled.div`
  position: relative;
  width: 14px;
  height: 14px;

&:hover {
    cursor: pointer;
    path {
        stroke: #07C0FB;
    }
}
`;
import React from "react";

export const DiscordIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5585 4.44187C15.4502 3.9252 14.2502 3.5502 13.0002 3.33353C12.9892 3.33318 12.9783 3.33524 12.9682 3.33956C12.9582 3.34388 12.9491 3.35036 12.9418 3.35853C12.7918 3.63353 12.6168 3.99187 12.5002 4.26687C11.1743 4.06687 9.82601 4.06687 8.50017 4.26687C8.38351 3.98353 8.20851 3.63353 8.05018 3.35853C8.04184 3.34187 8.01684 3.33353 7.99184 3.33353C6.74184 3.5502 5.55018 3.9252 4.43351 4.44187C4.42518 4.44187 4.41684 4.4502 4.40851 4.45853C2.14185 7.8502 1.51685 11.1502 1.82518 14.4169C1.82518 14.4335 1.83351 14.4502 1.85018 14.4585C3.35018 15.5585 4.79184 16.2252 6.21684 16.6669C6.24184 16.6752 6.26684 16.6669 6.27518 16.6502C6.60851 16.1919 6.90851 15.7085 7.16684 15.2002C7.18351 15.1669 7.16684 15.1335 7.13351 15.1252C6.65851 14.9419 6.20851 14.7252 5.76684 14.4752C5.73351 14.4585 5.73351 14.4085 5.75851 14.3835C5.85018 14.3169 5.94184 14.2419 6.03351 14.1752C6.05018 14.1585 6.07518 14.1585 6.09184 14.1669C8.95851 15.4752 12.0502 15.4752 14.8835 14.1669C14.9002 14.1585 14.9252 14.1585 14.9418 14.1752C15.0335 14.2502 15.1252 14.3169 15.2168 14.3919C15.2502 14.4169 15.2502 14.4669 15.2085 14.4835C14.7752 14.7419 14.3168 14.9502 13.8418 15.1335C13.8085 15.1419 13.8002 15.1835 13.8085 15.2085C14.0752 15.7169 14.3752 16.2002 14.7002 16.6585C14.7252 16.6669 14.7502 16.6752 14.7752 16.6669C16.2085 16.2252 17.6502 15.5585 19.1502 14.4585C19.1668 14.4502 19.1752 14.4335 19.1752 14.4169C19.5418 10.6419 18.5668 7.36687 16.5918 4.45853C16.5835 4.4502 16.5752 4.44187 16.5585 4.44187ZM7.60018 12.4252C6.74184 12.4252 6.02518 11.6335 6.02518 10.6585C6.02518 9.68353 6.72518 8.89187 7.60018 8.89187C8.48351 8.89187 9.18351 9.69187 9.17517 10.6585C9.17517 11.6335 8.47517 12.4252 7.60018 12.4252ZM13.4085 12.4252C12.5502 12.4252 11.8335 11.6335 11.8335 10.6585C11.8335 9.68353 12.5335 8.89187 13.4085 8.89187C14.2918 8.89187 14.9918 9.69187 14.9835 10.6585C14.9835 11.6335 14.2918 12.4252 13.4085 12.4252Z"
        fill="url(#paint0_linear_3765_24737)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3765_24737"
          x1="1.90483"
          y1="3.3335"
          x2="19.3846"
          y2="3.51065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

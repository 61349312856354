import React from 'react'
import styled from 'styled-components'
import { formatDisplayShorthand } from '../../../../../../utils/formatDisplay'
import shellIcon from "@/assets/coins/SHELL.svg";

export const TotalRewardsCell = ({ value }: any) => 
    <CellRow>
        <span>{formatDisplayShorthand(value)}</span>
        <img src={shellIcon}/>
    </CellRow>

const CellRow = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 12px;

  & > img {
    width: 16px;
    height: 16px;
    color: #00BDFF;
    margin-left: 8px;
  }
`

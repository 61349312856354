import { ETH_ADDRESS, STREAM_ADDRESS } from "../constants/addresses";

import ethIcon from "../assets/coins/ETH.svg";

import { ProtocolTypeOption, generateColorHex } from "@/components/DexBadge/DexBadge";
import { tokenColors } from "@/constants/tokenColors";
import { Token, ShellToken, NFTCollection, LBPToken, ExternalToken, ExternalDefiToken } from "../utils/tokens";
import { openseaKey } from "@/providers/WagmiProvider";
import { NFT_RARITY, WRAPPED_NFT_MAP } from "@/utils/nftHelpers";
import { calculateWrappedTokenId } from "@/utils/ocean/utils";
import { createTokenLogo, tokenLogos } from "@/constants/tokenLogos";

export const oceanAddress = "0xF51A074D3038aa1181F64622Cb1FB48B14B7af1f";

export const tokens: Token[] = [
  {
    name: "Ether",
    symbol: "ETH",
    address: ETH_ADDRESS,
    wrapped: false,
    icon: ethIcon,
  },
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0x4200000000000000000000000000000000000006",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  {
    name: "Dai",
    symbol: "DAI",
    address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    address: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  },
  {
    name: "Bridged USDC",
    symbol: "USDC.e",
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  {
    name: "Tether",
    symbol: "USDT",
    address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    wrapped: false,
    icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9.png",
  },
  {
    name: "Lido Staked ETH",
    symbol: "wstETH",
    address: "0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb",
    wrapped: false,
    icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0x5979d7b546e38e414f7e9822514be443a4800529.png",
  },
  {
    name: "Wrapped Bitcoin",
    symbol: "WBTC",
    address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  },
  {
    name: "Optimism",
    symbol: "OP",
    address: '0x4200000000000000000000000000000000000042',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/optimism/assets/0x4200000000000000000000000000000000000042/logo.png'
  },
  {
    name: "Velodrome",
    symbol: "VELO",
    address: "0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db",
    wrapped: false,
    icon: "https://asset-metadata-service-production.s3.amazonaws.com/asset_icons/f61c887898ac0902737bba0f4d5d460bd1b64247c34e6bf7b506052d39b1adf9.png",
  },
];

export const shellTokens: ShellToken[] = [];

export const nftCollections: NFTCollection[] = [];

export const lbpTokens: LBPToken[] = [];

export const externalTokens: ExternalToken[] = [];

export const externalDefiTokens: ExternalDefiToken[] = [
  {
    name: "ETH+WETH",
    symbol: "ETH+WETH",
    address: "0x4200000000000000000000000000000000000006",
    wrapped: false,
    icon: "",
    pool: "0x6dec8df4bf73e697faec2bccbb22d833af890a9c",
    query: "0x6dec8df4bf73e697faec2bccbb22d833af890a9c",
    tokens: ["ETH", "WETH"],
    tokenType: undefined,
    fee: 0,
  },
  {
    name: "Aave Optimism USDC.e",
    symbol: "aOptUSDC",
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5760.png",
    pool: "0xd8ba67ffa38325389c6cbc93e19113a8d5fb99ff",
    query: "0xd8ba67ffa38325389c6cbc93e19113a8d5fb99ff",
    tokens: ["USDC.e"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Optimism USDC",
    symbol: "aOptUSDCn",
    address: "0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5760.png",
    pool: "0x5d2fab79a78213ead922e036e3a3e4320390330a",
    query: "0x5d2fab79a78213ead922e036e3a3e4320390330a",
    tokens: ["USDC"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Optimism USDT",
    symbol: "aOptUSDT",
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5761.png",
    pool: "0x81a3050e6a9c0ae47615de239d7d4e8e0a2aa7c0",
    query: "0x81a3050e6a9c0ae47615de239d7d4e8e0a2aa7c0",
    tokens: ["USDT"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Optimism DAI",
    symbol: "aOptDAI",
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5763.png",
    pool: "0x4cf044399b8c625ba76d8a739bda3f4b1af3a565",
    query: "0x4cf044399b8c625ba76d8a739bda3f4b1af3a565",
    tokens: ["DAI"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Uniswap",
    symbol: "UNI",
    address: "",
    wrapped: false,
    icon: "",
    pool: "0x1f8064368098c6cd9e595e6089efb519099abc77",
    query: "0x73daf5640292f1fd8f11aaf072008bf71e0f7d20",
    tokens: tokens.filter((token) => !token.wrapped && token.address !== ETH_ADDRESS).map((token) => token.symbol),
    tokenType: "Uniswap",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Velodrome",
    symbol: "Velo",
    address: "",
    wrapped: false,
    icon: "",
    pool: "0x3fefeeaa1b9d7df271294864788d4e78cc69adb7",
    query: "0x12b641da4ed8d98d04eb8d1a7eea234177110645",
    tokens: tokens.filter((token) => !token.wrapped && token.address !== ETH_ADDRESS).map((token) => token.symbol),
    tokenType: "Velodrome",
    protocolVersion: "V2",
    fee: 0,
  },
];

export const tokenMap: {
  [id: string]: Token | ShellToken | NFTCollection | LBPToken;
} = {};

tokens.forEach((token) => {
  if (token.symbol !== "shLP") {
    tokenMap[token.symbol] = token;
    // tokenLogos[token.symbol] = createTokenLogo(token, 83);
  }
});

shellTokens.forEach((token) => {
  tokenMap[token.name] = token;
//   tokenLogos[token.name] = createTokenLogo(token, 50);
});

externalTokens.forEach((token) => {
  tokenMap[token.symbol] = token;
  if (!tokenColors[token.symbol]) tokenColors[token.symbol] = generateColorHex(token.tokenType!);
//   tokenLogos[token.symbol] = createTokenLogo(token, 50);
});

externalDefiTokens.forEach((token) => {
  tokenMap[token.symbol] = token;
  tokenColors[token.symbol] = generateColorHex(token.tokenType!);
//   tokenLogos[token.symbol] = createTokenLogo(token, 50);
});

nftCollections.forEach((collection) => {
  tokenMap[collection.symbol] = collection;

//   tokenLogos[collection.symbol] = createTokenLogo(collection, 50);
  if (!collection.wrapped) {
    const options = { method: "GET", headers: { accept: "application/json", "X-API-KEY": openseaKey } };

    const rarityTable =
      collection.is1155 || collection.address == STREAM_ADDRESS ? [] : require(`../constants/nftRarities/${collection.symbol.toLowerCase()}-rarity.json`);
    NFT_RARITY[collection.address] = {};

    if (collection.is1155) {
      const idMap: any = {};
      for (let i = 0; i < collection.totalSupply!; i++) {
        idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol];
      }
      WRAPPED_NFT_MAP["sh" + collection.symbol] = idMap;
    } else {
      fetch(`https://api.opensea.io/api/v2/collections/${collection.slug}`, options)
        .then((response) => response.json())
        .then((response) => {
          const wrappedCollection: NFTCollection = tokenMap["sh" + collection.symbol] as NFTCollection;
          if (!collection.description) {
            collection.description = response.description;
            wrappedCollection.description = response.description;
          }
          if (!collection.totalSupply) {
            collection.totalSupply = response["total_supply"];
            wrappedCollection.totalSupply = response["total_supply"];
          }
          const idMap: any = {};
          for (let i = 0; i <= collection.totalSupply!; i++) {
            idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol];
            NFT_RARITY[collection.address][i.toString()] = rarityTable[i] ?? null;
          }
          WRAPPED_NFT_MAP["sh" + collection.symbol] = idMap;
        })
        .catch((err) => console.error(err));
    }
  }
});

lbpTokens.forEach((lbpToken) => {
  tokenMap[lbpToken.symbol] = lbpToken;
  tokenMap[lbpToken.poolName] = {
    name: lbpToken.poolName,
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: lbpToken.poolIcon,
    oceanID: lbpToken.poolOceanID,
    pool: lbpToken.poolAddress,
    tokens: [lbpToken.symbol, lbpToken.pairToken],
    fee: 0,
    tokenType: "Shell",
    protocolVersion: "V3",
  };
//   tokenLogos[lbpToken.symbol] = createTokenLogo(lbpToken, 83);
//   tokenLogos[lbpToken.poolName] = createTokenLogo(tokenMap[lbpToken.poolName], 50);
});

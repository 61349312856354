import React, { useState, useEffect, useRef, useMemo, ReactNode, useContext } from "react";
import styled, { css } from "styled-components";
import { useAccount, erc20ABI, useNetwork, useProvider } from "wagmi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addBalance, addNFTBalance } from "../../store/balancesSlice";
import Cleave from "cleave.js/react";
import { BalanceInfo } from "./BalanceInfo";
import { SelectTokenModal } from "./SelectTokensModal/SelectTokenModal";
import plusIcon from "../../assets/icons/plus.svg";
import minusIcon from "../../assets/icons/minus.svg";
import { formatUnits } from "@ethersproject/units";
import { Token, ShellToken, isShellToken, NFT, isNFTCollection, NFTCollection, NFT1155, isLBPToken, isLPToken, isExternalDefiToken, isShellV2Token, isPlaceholderToken, ExternalDefiToken, LBPToken, placeholderToken, isMonoAdapter } from "../../utils/tokens";
import { ShellTokenInfo } from "./DeFiToken/ShellTokenInfo";
import { OceanABI } from "../../constants/ABI/OceanABI";
import { OCEAN_ADDRESS, ETH_ADDRESS, OLD_OCEAN_ADDRESS, STREAM_ADDRESS } from "../../constants/addresses";
import { BigNumber, Contract, providers } from "ethers";
import { DISPLAY_DECIMALS } from "../../utils/formatDisplay";
import { PoolQuery } from "../../utils/PoolQuery";
import { StraightLoader } from "../../components/Loaders";
import { formatDisplay } from "../../utils/formatDisplay";
import { getTokenID } from "../../utils/LiquidityGraph";
import { breakpoints, Media } from "../../styles";
import { NFTsTokensInfo } from "./NFTsTokensInfo/NFTsTokensInfo";
import { WRAPPED_NFT_MAP, extract1155Data, getNFTs } from "../../utils/nftHelpers";
import { removeLeadingZeros } from "../../utils/ocean/utils";
import { useWidthBreakpoint } from "../../hooks";
import { LBPTokenInfo } from "./DeFiToken/LBPTokenInfo";
import { Link, useLocation } from "react-router-dom";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { VestingStreamInfo } from "./NFTsTokensInfo/VestingStreamInfo";
import { LendingTokenInfo } from "./DeFiToken/LendingTokenInfo";
import { PendleTokenInfo } from "./DeFiToken/PendleTokenInfo";
import { BeefyTokenInfo } from "./DeFiToken/BeefyTokenInfo";
import { alchemyId } from "@/providers/WagmiProvider";
import { allChains, defaultChain } from "@/placeholders/chains";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface InputPanelProps {
  selectedToken: Token | ShellToken | NFTCollection;
  value: string;
  onChange: (token: Token, value: string) => void;
  label: string;
  CrossChainSelector?: any;
  onTokenSelect: (token: Token | ShellToken | NFTCollection) => void;
  isInputToken: boolean;
  onAddButtonClick: () => void;
  onRemoveButtonClick: () => void;
  inputsAmount: number;
  shape?: "top" | "bottom";
  anotherSelected: boolean;
  otherTokens: string[];
  disabledTokens: string[];
  reloadBalances: boolean;
  error: boolean;
  warning: boolean;
  loading: boolean;
  otherNFTs: {[collection : string] : (NFT | NFT1155)[]};
  nftsOnOtherSide: boolean;
  setWarningVisible: (warning: boolean) => void; // Show warning text blurb
  setUSDValues: (values: any) => void;
  priceImpact: number;
  selectedNFTs: any[];
  allSelectedNFTs: {[collection : string] : NFT[]};
  updateSelectedNFTs: any;
  nftSweepInputPrice?: null | string;
  onNFTSweepSelect?: (collection: string, items: NFT[]) => void;
  propLock: string;
  setPropLock: any;
  isWidget: boolean;
  selectionLocked?: boolean
  inputLocked?: boolean
  crossChain?: string;
  crossChainToggled?: boolean;
  setCrossChain?: React.Dispatch<React.SetStateAction<string>>;
  bridgeFee?: number;
  selectedProtocols: string[],
  handleSelectProtocol: (protocolName: string) => void
}

export const InputPanel = React.memo(({
  value,
  onChange,
  label,
  selectedToken,
  onTokenSelect,
  isInputToken,
  onAddButtonClick,
  onRemoveButtonClick,
  inputsAmount,
  shape,
  anotherSelected,
  otherTokens,
  disabledTokens,
  reloadBalances,
  error,
  warning,
  loading,
  otherNFTs,
  nftsOnOtherSide,
  setWarningVisible,
  setUSDValues,
  priceImpact,
  selectedNFTs,
  allSelectedNFTs,
  updateSelectedNFTs,
  nftSweepInputPrice,
  onNFTSweepSelect,
  propLock,
  setPropLock,
  isWidget,
  selectionLocked,
  inputLocked,
  crossChain,
  crossChainToggled,
  CrossChainSelector,
  setCrossChain,
  bridgeFee,
  selectedProtocols,
  handleSelectProtocol
}: InputPanelProps) => {

  const { connectedChain, poolQuery, loadedPrices, stipTokens, poolQueryTo } = useContext(ChainContext)

  const location = useLocation()

  const chain = crossChainToggled && crossChain ? allChains[crossChain] : connectedChain

  const { tokens, shellTokens, nftCollections, lbpTokens, externalTokens, externalDefiTokens, tokenMap } = require(`../../placeholders/${chain.name.split(' ')[0].toLowerCase()}Tokens.ts`)

  const is1155 = isNFTCollection(selectedToken) && selectedToken.is1155
        
  const [inputtedValue, setInputtedValue] = useState(selectedNFTs && selectedNFTs.length && !is1155 ? selectedNFTs.length.toString() : value);
  const [shake, setShake] = useState(false);
  const [warningBorder, setWarningBorder] = useState(false);
  const [balance, setBalance] = useState("0");
  const [tokenPrice, setTokenPrice] = useState(0);
  const [valueUSD, setValueUSD] = useState(0);
  const [ethPrice, setEthPrice] = useState(0)

  const [isVisible, setIsVisible] = useState(false);

  const { address: walletAddress, isConnected } = useAccount();

  const provider = new providers.JsonRpcProvider(`https://${chain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`)
  
  const userBalances: any = useAppSelector((state) => state.balances.balances[chain.name]);
  const userNFTBalances: Record<string, any[]> = useAppSelector((state) => state.balances.nftBalances);
  const prices: any = useAppSelector(state => state.prices.prices[chain.name])
  const dispatch = useAppDispatch();

  const filteredLBPTokens = useMemo(() => lbpTokens.filter((lbpToken: LBPToken) => !extract1155Data(lbpToken.symbol)), [lbpTokens])

  const tokenID = getTokenID(selectedToken)
  const tokenAddress = selectedToken.wrapped || isShellV2Token(selectedToken) ? selectedToken.oceanID ?? '' : selectedToken.address

  const oldOcean = new Contract(OLD_OCEAN_ADDRESS, OceanABI, provider);
  const ocean = new Contract(OCEAN_ADDRESS, OceanABI, provider);

  const isTablet = useWidthBreakpoint(breakpoints.tablet)
  const isVestingStream = selectedToken.address == STREAM_ADDRESS

  const fetchBalance = async () => {
    
    if (selectedToken.wrapped) {
      return ocean.balanceOf(walletAddress, selectedToken.oceanID).then((result: any) => {
          return formatUnits(result);
        })
        .catch(() => {
          return "0";
        });
    } else if (selectedToken.address == ETH_ADDRESS) {
        
      return provider?.getBalance(walletAddress ?? "0xNull").then((result: any) => {
          return formatUnits(result);
        })
        .catch(() => {
          return "0";
        });
    } else if(isShellV2Token(selectedToken)){
        return oldOcean.balanceOf(walletAddress, selectedToken.oceanID).then((result: any) => {
            return formatUnits(result);
          })
          .catch(() => {
            return "0";
          });
    } else {
      const tokenAddress = selectedToken.address
      const tokenContract = new Contract(tokenAddress, erc20ABI, provider);
      return Promise.all([
            tokenContract.balanceOf(walletAddress),
            tokenContract.decimals(),
        ]).then(([result, decimals]) => {
            return formatUnits(result, decimals);
        }).catch(() => {
            return "0";
        });
    }
  };

  const fetchNFTBalance = async () => {

    if(selectedToken.wrapped){
        const userOceanNFTs = (await getNFTs(walletAddress ?? '0xNull', OCEAN_ADDRESS))

        const wrappedIDs = WRAPPED_NFT_MAP[selectedToken.symbol]

        const userWrappedNFTs : any[] = []
        userOceanNFTs.forEach((userOceanNFT : any) => {
            const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
            if(wrappedIDs[oceanID]){
                userWrappedNFTs.push(
                    is1155 ? 
                    {id: parseInt(wrappedIDs[oceanID][0]), balance: parseInt(userOceanNFT.balance)} :
                    parseInt(wrappedIDs[oceanID][0])
                )
            }
        })

        return userWrappedNFTs

    } else {
        const userNFTs = (await getNFTs(walletAddress ?? '0xNull', selectedToken.address)).map((nft) => 
            is1155 ? {id: parseInt(nft.tokenId), balance: parseInt(nft.balance)} : parseInt(nft.tokenId)
        );
        return userNFTs
    }
  }

  const getPrices = async (token: any) => {
    if (loadedPrices) {
      const query = isWidget || (crossChainToggled && isInputToken) || !crossChainToggled ? poolQuery : poolQueryTo!
      return await query.getUSDPrice(token, {...prices});
    } else {
      return 0;
    }
  };

  const checkForLBP = () => {
    if(isNFTCollection(selectedToken)){
        if(selectedToken.is1155){
            const tokenData = tokenMap['fr' + selectedToken.symbol + '-' + selectedToken.id1155]
            return isLBPToken(tokenData) && tokenData.status !== 'Ended'
        }
    } else {
        return isLBPToken(selectedToken) && selectedToken.status !== 'Ended'
    }
  }
    
  const filteredTokens = useMemo(() => {
    if(crossChainToggled && isInputToken){
        return Object.keys(allChains[crossChain!].tokens!).map((tokenID) => {
            const token = {...tokenMap[tokenID]}
            if(token.address != ETH_ADDRESS)
                token.address = allChains[crossChain!].tokens![tokenID]
            return token
        })
    }
    const allTokens: any[] = tokens
      .concat(shellTokens)
      .concat(externalDefiTokens.filter((lpToken: ExternalDefiToken) => lpToken.tokenType && !isMonoAdapter(lpToken)))
      .concat(externalTokens).concat(chain.name == defaultChain.name ? stipTokens : []);
    filteredLBPTokens.forEach((lbpToken: LBPToken) => {
        if(lbpToken.status !== 'Ended')
            allTokens.push(lbpToken)
    })
    return allTokens.filter((token) => !otherTokens.includes(getTokenID(token)))
  }, [crossChain, crossChainToggled, stipTokens.length, chain])

  const bridgeFeeValue = useMemo(() => {
    if(bridgeFee){
        return ethPrice * bridgeFee
    } else {
        return 0
    }
  }, [bridgeFee, ethPrice])

  const initialRender = useRef(true);
  useEffect(() => {
    // Prevent unnecessary fetch on initial render
    if (initialRender.current) {
      initialRender.current = false;
    } else if (isConnected && !isPlaceholderToken(selectedToken)) {
      if(isNFTCollection(selectedToken)){
        fetchNFTBalance().then((nfts : any[]) => {
            if(is1155){
                const item : any = { ...selectedNFTs[0] };
                const itemBalance = nfts.filter((nft) => nft.id == item.id).map((nft) => nft.balance)
                item.balance = itemBalance.length > 0 ? itemBalance[0] : 0
                updateSelectedNFTs(selectedToken.symbol, [item])
            } else {
                setBalance(nfts.length.toString())
                updateSelectedNFTs(selectedToken.symbol, [])
            }
            dispatch(addNFTBalance({collection: selectedToken.symbol, items: nfts}))
        })
      } else {
        fetchBalance().then((newBalance: string) => {
            setBalance(newBalance);
            dispatch(addBalance({ chain: chain.name, address: tokenAddress, amount: newBalance }));
        });
      }
    }
  }, [reloadBalances]);

  useEffect(() => {
    // Fetch balances from contract if not already in Redux state and update store
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      if (isConnected && !isPlaceholderToken(selectedToken)) {
        if (userBalances[tokenAddress] && !crossChainToggled) {
          setBalance(userBalances[tokenAddress]);
        } else if (userNFTBalances[selectedToken.symbol]) {
          if (isNFTCollection(selectedToken) && !selectedToken.is1155) {
            setBalance(userNFTBalances[selectedToken.symbol].length.toString());
          }
        } else {
          if (isNFTCollection(selectedToken)) {
            const nfts = await fetchNFTBalance();
            if (signal.aborted) return;

            if (nfts.length > 0) {
              if (selectedToken.is1155) {
                const item: any = { ...selectedNFTs[0] };
                const itemBalance = nfts.filter((nft) => nft.id == item.id).map((nft) => nft.balance);
                item.balance = itemBalance.length > 0 ? itemBalance[0] : 0;
                updateSelectedNFTs(selectedToken.symbol, [item]);
              } else {
                setBalance(nfts.length.toString());
              }
              dispatch(
                addNFTBalance({
                  collection: selectedToken.symbol,
                  items: nfts,
                })
              );
            } else {
              setBalance("0");
            }
          } else {
            const newBalance = await fetchBalance();
            if (signal.aborted) return;

            if (parseFloat(newBalance) > 0) {
              // Prevents stale zero balance after claiming tokens
              setBalance(newBalance);
              dispatch(
                addBalance({
                  chain: chain.name,
                  address: tokenAddress,
                  amount: newBalance,
                })
              );
            } else {
              setBalance("0");
            }
          }
        }
      } else {
        setBalance('0')
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [walletAddress, isConnected, selectedToken, crossChain, crossChainToggled, connectedChain]);

  useEffect(() => {

    if(!isPlaceholderToken(selectedToken)){
        getPrices(selectedToken).then((price: number) =>
            setTokenPrice(price)
        );
    }
    
    getPrices(tokenMap['ETH']).then((price: number) => 
      setEthPrice(price)
    )

  }, [selectedToken, loadedPrices, prices]);

  useEffect(() => {
    if (!warning) {
      setWarningBorder(false);
      setWarningVisible(false);
    }
    if (inputtedValue && !is1155) handleInput(inputtedValue);
  }, [warning]);

  useEffect(() => {
    if(selectedNFTs){
        if(is1155){
            setBalance(selectedNFTs[0].balance)
        } else {
            handleInput(selectedNFTs.length > 0 ? selectedNFTs.length.toString() : '')
        }
    }
  }, [selectedNFTs])

  useEffect(() => {
    if(is1155){
        const pairID = selectedToken.wrapped ? selectedToken.symbol.substring(2) : 'sh' + selectedToken.symbol
        if(otherNFTs[pairID]?.length == 1 && !selectedNFTs){
            const pairItem : any = { ...otherNFTs[pairID][0] };
            const pairBalance = userNFTBalances[selectedToken.symbol].filter((nft) => nft.id == pairItem.id).map((nft) => nft.balance)
            const wrapPrefix = 'Wrapped '
            pairItem.name = selectedToken.wrapped ? wrapPrefix + pairItem.name : pairItem.name.replace(wrapPrefix, "")
            pairItem.symbol = selectedToken.symbol
            pairItem.balance = pairBalance.length > 0 ? pairBalance[0] : 0
            
            updateSelectedNFTs(selectedToken.symbol, [pairItem])
        }
    }
    
  }, [otherNFTs])

  useEffect(() => {

    let tokenValue: number

    if(isVestingStream && selectedNFTs){
        const totalVestingAmount = selectedNFTs.map((stream: any) => parseFloat(stream.vesting)).reduce((partialSum, a) => partialSum + a, 0)
        tokenValue = totalVestingAmount * (prices['strSHELL'])
    } else {
        tokenValue = parseFloat(value == "" ? "0" : value) * tokenPrice;
    }

    setValueUSD(tokenValue);
    setUSDValues((prev: any) => ({
      ...prev,
      [tokenID]: tokenValue + bridgeFeeValue,
    }));
    
  }, [value, tokenPrice, bridgeFeeValue]);

  useEffect(() => {
    if(!isVisible) onTokenSelect(tokenMap[getTokenID(selectedToken)] == undefined ? placeholderToken : tokenMap[getTokenID(selectedToken)])
  }, [tokenMap])

  const handleInput = (value: string) => {
    setShake(false);
    setInputtedValue(value);

    if (warning) {
      if (value) {
        setWarningBorder(true);
        setWarningVisible(true);
        setShake(true);
        setTimeout(() => setShake(false), 500);
      } else {
        setWarningVisible(false);
        setWarningBorder(false);
      }
    } else {
      setWarningBorder(false);
      setWarningVisible(false);
      onChange(selectedToken, value);
    }
  };

  const onBalanceClick = (percentage: number) => {

    const truncateDecimals = (balance : number) => {
        const multiplier = Math.pow(10, DISPLAY_DECIMALS),
            adjustedNum = balance * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
        return (truncatedNum / multiplier).toString();
    }

    const balanceNum = parseFloat(balance);
    if (percentage == 1) {
      if (
        selectedToken.symbol == "ETH" &&
        selectedToken.address == ETH_ADDRESS
      ) {
        // Some ETH must be saved for gas fees
        const minETH = 0.001 + (isInputToken && crossChainToggled ? Math.max(bridgeFee ?? 0, 0.001) : 0)
        if (balanceNum > minETH) {
          handleInput(truncateDecimals(balanceNum - minETH));
        } else {
          setWarningBorder(true);
          setShake(true);
          setTimeout(() => {
            setShake(false);
            setWarningBorder(false);
          }, 500);
        }
      } else {
        handleInput(truncateDecimals(balanceNum));
      }
    } else {
      handleInput(truncateDecimals(balanceNum * percentage));
    }
  };

  const onPropLockChange = () => {
    if(propLock == 'visible'){
        setPropLock('locked')
        handleInput(inputtedValue)
    } else if(propLock == 'locked'){
        setPropLock('visible')
    }
  };

  const buildTokenInfoPanel: any = (selectedToken: any) => {
    if (isLPToken(selectedToken) && !location.pathname.includes("statistics")) {
      return <ShellTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
    } else if (checkForLBP() && !location.pathname.includes("lbp")) {
      return <LBPTokenInfo selectedToken={selectedToken} isShrunk={isTablet} chain={chain}/>;
    } else if (isExternalDefiToken(selectedToken)) {
      if (selectedToken.tokenType == "Aave") {
        return <LendingTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
      } else if (selectedToken.tokenType == "Pendle") {
        return <PendleTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
      } else if (selectedToken.tokenType == "Beefy") {
        return <BeefyTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
      } else if (selectedToken.tokenType == "STIP") {

        const baseToken = tokenMap[selectedToken.tokens[0]]

        if (isLPToken(baseToken)) {
          return <ShellTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
        } else if (isExternalDefiToken(baseToken)) {
          if (baseToken.tokenType == "Aave") {
            return <LendingTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
          } else if (baseToken.tokenType == "Pendle") {
            return <PendleTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget} chain={chain}/>;
          } else if (baseToken.tokenType == "Beefy") {
            return <BeefyTokenInfo selectedToken={selectedToken} isShrunk={isTablet || isWidget}chain={chain}/>;
          }
        }
        // return buildTokenInfoPanel(tokenMap[selectedToken.tokens[0]]);
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <View
        shape={shape}
        errorBorder={error}
        warningBorder={warningBorder}
        shake={shake}
      >
        {inputsAmount > 1 ? (
          <RemoveButton
            data-testid={"remove-btn-" + label}
            onClick={onRemoveButtonClick}
            shellTokenSelected={
             (isShellToken(selectedToken) || isNFTCollection(selectedToken)) && !isWidget
            }
            isShrunk={isTablet || isWidget}
          />
        ) : (
            !anotherSelected && !(isNFTCollection(selectedToken) && nftsOnOtherSide) && (
            <AddButton
              data-testid={"add-btn-" + label}
              onClick={onAddButtonClick}
              shellTokenSelected={
                isShellToken(selectedToken) || isNFTCollection(selectedToken)
              }
              isShrunk={isTablet || isWidget}
            />
          )
        )}
        <ViewRow lpToken={isLPToken(selectedToken)} isShrunk={isTablet || isWidget}>
          <Label className={isTablet || isWidget ? 'shrunk' : ''}>
            {crossChainToggled ? (
              <LabelWrapper>
              {label}
              {CrossChainSelector && CrossChainSelector()}
            </LabelWrapper>
            ) : label}
            <CleaveStyled
              data-testid={`token-input-${selectedToken.symbol.toLowerCase().replace(/\s+/g, '')}-${isInputToken ? 'from' : 'to'}`}
              placeholder="0.0"
              value={selectedNFTs && selectedNFTs.length && !is1155 ? selectedNFTs.length : value} // Don't fill in value if there would be a warning on input
              options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
                numeralDecimalScale: DISPLAY_DECIMALS,
              }}
              onChange={(event : any) => handleInput(event.target.value)}
              wrapped={selectedToken?.wrapped?.toString()}
              onKeyDown={(event : any) =>{ 
                    ["-"].includes(event.key) && event.preventDefault()
                    if (isTablet && [",", "٫"].includes(event.key)) {
                        event.preventDefault();
                        event.target.value += '.';
                    }
                    if(is1155 && ["."].includes(event.key)){
                        event.preventDefault()
                    }
                }
              }
              inputMode="decimal"
              disabled={
                inputLocked ||
                (propLock == 'locked' && !isNFTCollection(selectedToken)) || 
                ((isNFTCollection(selectedToken) || nftsOnOtherSide) && !is1155)
              }
              className={isTablet || isWidget ? 'shrunk' : ''}
            />
            {loading || (value && !valueUSD) ? (
              <SkeletonBox isLoading width="64px" height='16px' borderRadius="4px"/>
            ) : (
              valueUSD > 0 &&
              (!isNFTCollection(selectedToken) || isVestingStream) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: isInputToken ? "column" : "row",
                  }}
                >
                  <USDValue>{`$${formatDisplay(
                    valueUSD.toString(),
                    2
                  )}`}</USDValue>
                  {bridgeFeeValue > 0 && <USDValue style={{ paddingLeft: "0px", marginLeft: "-6px" }}>
                    {bridgeFee
                      ? `+$${formatDisplay(
                          bridgeFeeValue.toString(),
                          2
                        )} (${bridgeFee} ETH Bridge Fee)`
                      : " "}
                  </USDValue>}
                  {!isInputToken &&
                    priceImpact !== Infinity &&
                    Math.abs(priceImpact) >= 0.0001 && (
                      <PriceImpact value={priceImpact}>
                        {`(${priceImpact > 0 ? "+" : ""}${formatDisplay(
                          priceImpact.toString(),
                          4
                        )}%)`}
                      </PriceImpact>
                    )}
                </div>
              )
            )}
          </Label>
          <Row className={isTablet || isWidget ? 'shrunk' : ''}>
            {
            !isPlaceholderToken(selectedToken) && 
            <BalanceInfo
                tokenID={tokenID}
                balance={balance}
                showMax={isInputToken && !nftsOnOtherSide}
                balanceClick={onBalanceClick}
                isNFTCollection={isNFTCollection(selectedToken)}
                isShrunk={isTablet || isWidget}
            />
            }
            <SelectTokenModal
              selectedToken={selectedToken}
              onTokenSelect={onTokenSelect}
              filteredTokens={filteredTokens}
              filteredCollections={nftCollections.filter((token: NFTCollection) => !otherTokens.includes(getTokenID(token)))}
              disabledTokens={disabledTokens}
              isInputToken={isInputToken}
              selectedNFTs={selectedNFTs}
              updateSelectedNFTs={updateSelectedNFTs}
              otherNFTs={otherNFTs}
              isShrunk={isTablet || isWidget}
              selectionLocked={selectionLocked}
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              crossChain={crossChain ?? ''}
              setCrossChain={setCrossChain}
              selectedProtocols={selectedProtocols}
              handleSelectProtocol={handleSelectProtocol}
            />
          </Row>
        </ViewRow>
        {buildTokenInfoPanel(selectedToken)}
        {
         isNFTCollection(selectedToken) && (!checkForLBP() || isWidget) && 
         (isInputToken || disabledTokens.filter((token) => isNFTCollection(tokenMap[token])).length == 0 || selectedToken.is1155) && 
         !isVestingStream &&
          <NFTsTokensInfo 
            allSelectedNFTs={allSelectedNFTs}
            nftTokens={selectedNFTs} 
            updateSelectedNFTs={updateSelectedNFTs} 
            selectedCollection={selectedToken} 
            isInputToken={isInputToken} 
            priceImpact={isInputToken ? 0 : priceImpact}
            nftSweepInputPrice={nftSweepInputPrice}
            onNFTSweepSelect={onNFTSweepSelect}
            propLock={propLock}
            onPropLockChange={onPropLockChange}
            usdValue1155={selectedToken.is1155 ? valueUSD : undefined}
            isShrunk={isTablet || isWidget}
            prices={prices}
          />
        }
        {
          isNFTCollection(selectedToken) && isVestingStream && (
           <VestingStreamInfo 
           selectedStreams={selectedNFTs}
           onNFTSweepSelect={onNFTSweepSelect}
           setIsVisible={setIsVisible}
           updateSelectedNFTs={updateSelectedNFTs} 
           isInputToken={isInputToken}
           isShrunk={isTablet || isWidget}
           />
          )
        }

      </View>
    </>
  );
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}
);

const Button = styled.button<{ shellTokenSelected: boolean, isShrunk: boolean }>`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: #151530;
  border: 2px solid #2c5173;
  box-shadow: 0px 6px 7px #04081c;
  border-radius: 50%;

  ${({ shellTokenSelected, isShrunk }) =>
    shellTokenSelected &&
    css`
      margin-top: ${isShrunk ? '-64' : '-16'}px;
  `};
`;

const AddButton = styled(Button)`
  background: #151530 url(${plusIcon}) center no-repeat;
`;

const RemoveButton = styled(Button)`
  background: #151530 url(${minusIcon}) center no-repeat;
`;

const View = styled.div<
  Pick<InputPanelProps, "shape"> & {
    selectedToken?: ShellToken;
    errorBorder: boolean;
    warningBorder: boolean;
    shake?: boolean;
  }
>`
  position: relative;
  border-radius: 16px;
  z-index: 1;
  max-width: 100%;

  &:hover {
    filter: drop-shadow(0px 0px 2px #2c5173);
  }

  ${Button} {
    display: block;
  }

  & + & {
    margin-top: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #151530;
    border: 1px solid #1e2239;
    border-radius: 16px;
    z-index: -1;
  }

  ${({ shape }) =>
    shape === "top" &&
    css`
      &:before {
        clip-path: polygon(
          0 0,
          calc(50% - 43px) 0%,
          calc(50% - 43px) calc(0% + 15px),
          calc(50% + 41px) calc(0% + 15px),
          calc(50% + 41px) 0,
          100% 0,
          100% 100%,
          0 100%
        );
      }
      &:after {
        top: 0px;
        transform: translateX(-50%) rotate(180deg);
      }
    `};

  ${({ shape }) =>
    shape === "bottom" &&
    css`
      &:before {
        clip-path: polygon(
          calc(50% - 43px) 100%,
          calc(50% - 43px) calc(100% - 15px),
          calc(50% + 41px) calc(100% - 15px),
          calc(50% + 41px) 100%,
          100% 100%,
          100% 0,
          0 0,
          0 100%
        );
      }
      &:after {
        bottom: -2px;
        transform: translateX(-50%);
      }
    `};

  ${({ errorBorder }) =>
    errorBorder &&
    css`
      filter: drop-shadow(0px 0px 2px #cc0000) !important;
    `};

  ${({ warningBorder }) =>
    warningBorder &&
    css`
      filter: drop-shadow(0px 0px 2px #daa520) !important;
    `};

  ${({ shake }) =>
    shake &&
    css`
      animation: shake 0.25s infinite;
    `};

  @keyframes shake {
    0% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(0deg);
    }
    30% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    40% {
      transform: translate(1px, 0px) rotate(0deg);
    }
    50% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    60% {
      transform: translate(-3px, 0px) rotate(0deg);
    }
    70% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    80% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    90% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    100% {
      transform: translate(1px, 0px) rotate(0deg);
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    width: 86px;
    height: 16px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 86 16"><path d="M0,0v16c5.2,0,10.3-1.4,14.7-4.1l9.1-5.5l0,0C35.8-0.9,51-0.7,62.9,6.6l8.1,5c4.5,2.8,9.7,4.3,15,4.3V0H0z" fill="rgb(21, 21, 48)"/><path d="M86,15v1c-5.5,0-10.9-1.5-15.6-4.4l-8.1-5C50.7-0.6,35.9-0.7,24.2,6.3l-9.1,5.5c-4.6,2.7-9.9,4.2-15.2,4.2v-1  c5.2,0,10.3-1.4,14.7-4.1l9.1-5.5l0,0C35.8-1.9,51-1.7,62.9,5.6l8.1,5C75.5,13.5,80.7,15,86,15z" fill="rgb(30, 34, 57)"/></svg>');
  }
`;

const ViewRow = styled.div<{ lpToken: boolean; isShrunk: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 24px 18px 24px 40px;
  overflow: hidden;

  ${({ lpToken }) =>
    lpToken &&
    css`
      padding-bottom: 12px;
    `};

  ${({ isShrunk }) =>
    isShrunk &&
    css`
      padding: 20px 12px 16px 24px;
    `};
`;


const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a9d1e2;
  
  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 12px;
      line-height: 14px;
    `};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  row-gap: 10px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      column-gap: 8px;
    `};

`;

const StyledStraightLoader = styled(StraightLoader)`
  margin-left: 10px;
`;

export const USDValue = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  padding-left: 3px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 12px;
      line-height: 14px;
    `};
`;

const PriceImpact = styled(USDValue)<{ value: number }>`
  font-weight: 1;

  ${({ value }) =>
    value > 0.001 &&
    css`
      color: #7adeb9;
    `};
  ${({ value }) =>
    value <= -5 &&
    css`
      color: #ff5349;
    `};
  ${({ value }) =>
    value > -5 &&
    value <= -1 &&
    css`
      color: #daa520;
    `};
`;

const inputTextStyles = css`
  font-weight: 500;
  font-size: 46px;
  line-height: 56px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const CleaveStyled = styled(Cleave)<{ wrapped?: string }>`
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 180px;
  padding-right: 8px;
  margin-top: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  ${inputTextStyles}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &::-moz-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &:-ms-input-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &:-moz-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }

  ${({ wrapped }) =>
    wrapped === "true" &&
    css`
      color: #37dcf2;

      &::-webkit-input-placeholder {
        color: #37dcf2;
      }
      &::-moz-placeholder {
        color: #37dcf2;
      }
      &:-ms-input-placeholder {
        color: #37dcf2;
      }
      &:-moz-placeholder {
        color: #37dcf2;
      }
    `};

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 30px;
      line-height: 36px;
      margin-top: 8px;
      min-width: 100px;
      max-width: 144px;
    `};
`;

export const InputPanelsWrapper = styled.div<{ position?: "top" | "bottom" }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ position }) =>
    position === "top"
      ? css`
          ${View} {
            &:not(:last-child) {
              &:after {
                display: none;
              }
              &:before {
                clip-path: unset;
              }
            }
          }
        `
      : css`
          ${View} {
            &:not(:first-child) {
              &:after {
                display: none;
              }
              &:before {
                clip-path: unset;
              }
            }
          }
        `};
`;

const LabelWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;
`;

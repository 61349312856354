import React from "react";
import styled from "styled-components";
import { Box } from "@/components/Layout/Box";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import { Link } from "react-router-dom";

export const EarnShellPointsBox = ({
  image,
  headerText,
  descriptionText,
  buttonText,
  to,
  onClickTokenState
}: {
  image: string;
  headerText: string;
  descriptionText: string;
  buttonText: string;
  to: string;
  onClickTokenState: any;
}) => {

  return (
    <View>
      <Image src={image}></Image>
      <Header>{headerText}</Header>
      <Description>{descriptionText}</Description>
      <EarnShellPointsButton to={to} state={onClickTokenState} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
        {buttonText}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3983_174)">
            <path
              d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_3983_174">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </EarnShellPointsButton>
    </View>
  );
};

const View = styled(Box)`
  width: 100%;
  background: var(--dark-4, #151530);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Image = styled.img`
  margin-top: 20px;
  margin-inline: auto;

  ${Media.tablet} {
    margin-top: 0px; 
  }
`;

const Header = styled.div`
  margin-top: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  ${Media.tablet} {
    margin-top: 16px;
  }
`;

const Description = styled.div`
  margin-top: 8px;
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  ${Media.tablet} {
    font-size: 14px;

  }
`;

const EarnShellPointsButton = styled(Link)`

  color: #ffffff;
  border: 1px solid #37dcf2;
  margin-top: 16px;
  width: 100%;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;

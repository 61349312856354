import React, { ReactNode, useEffect, useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { Composition } from '../../../../../components/Composition/Composition';
import { POOL_API } from '../../../../../constants/urls';
import { isExternalToken, isShellToken, isShellV2Token } from '../../../../../utils/tokens';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { addPool } from '../../../../../store/poolsSlice';
import { formatDisplay, formatDisplayShorthand } from '../../../../../utils/formatDisplay';
import { getTokenID } from '../../../../../utils/LiquidityGraph';
import { TokenProps } from '../PointsTable';
import { TransferToken } from './TransferToken';
import { extract1155Data } from '../../../../../utils/nftHelpers';
import { StatsSkeleton, TokenRowSkeleton, CompositionSkeleton } from './PoolSubRow';
import { useAccount } from 'wagmi';
import { Media } from '@/styles';
import { LinkButton } from "@/components/Buttons/LinkButton";
import { getSlugForStatisticsPage } from '@/pages/Pools/PoolsTable';
import { defaultChain } from '@/placeholders/chains';
import { ChainContext } from '@/components/Overlays/ChainProvider';

interface PointsTaleDetailsProps {
  token: TokenProps;
}

export const PointsTaleDetails = ({ token }: PointsTaleDetailsProps) => {
  const [loading, setLoading] = useState(true)
  const [analytics, setAnalytics] = useState<any>({})
  const [userBalance, setUserBalance] = useState(0);

  const poolData = useAppSelector(state => state.pools.pools[defaultChain.name])
  const userBalances = useAppSelector(state => state.balances.balances[defaultChain.name])
  const userCurrentPoints = useAppSelector(state => state.points.currentSeason)

  const dispatch = useAppDispatch()
  const { address: walletAddress } = useAccount();
  const { tokenMap, poolQuery } = useContext(ChainContext)

  const tokenData: any = tokenMap[token.name]

  const getPoolAnalytics = async (pool: any, signal: AbortSignal) => {
    const poolName = getTokenID(pool)
    const version = isExternalToken(pool) && isShellV2Token(pool) ? 'v2' : 'v3'

    const cachedData = poolData[poolName];
    if (cachedData) {
      return cachedData;
    }
    
    const response = await fetch(POOL_API(defaultChain) + version + '/pools/' + poolName.replace(/\//g, "-"), { signal })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    dispatch(addPool({ name: poolName, data, chain: defaultChain.name }));

    return data;
  };

  const compositionList = useMemo(() => {
    return Object.keys(analytics?.breakdown || {}).map((childToken) => {
      return { symbol: childToken, percentage: `${analytics.breakdown[childToken]}%` };
    });
  }, [analytics?.breakdown]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchPoolInfo = async (poolName: any) => {
      try {
        const analytics = await getPoolAnalytics(tokenMap[poolName], signal);
        if (isMounted) {
          setAnalytics(analytics);
        }
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchPoolInfo(token.name);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [token.name]);

  useEffect(() => {
    if(token.name === "SHELL" && walletAddress) {
      const shellBalance = userCurrentPoints[walletAddress].shellBalance;
      setUserBalance(shellBalance);
    } else {
      const userBalance = userBalances[tokenData.oceanID];
      const userBalanceFormatted = formatDisplay(userBalance, token.balance.decimals);
      setUserBalance(userBalanceFormatted.includes("<") ? 0 : parseFloat(userBalanceFormatted));
    }
  }, [userBalances[tokenData.oceanID], tokenData.oceanID]);
  
  return (
    <>
      {isShellToken(tokenData) ? (
        <>
          <MainRow>
            <TokenBalances>
              {loading
                ? <TokenRowSkeleton length={2} isMobile={true} />
                : analytics &&
                analytics.breakdown && Object.keys(analytics).length > 0 &&
                Object.keys(analytics.breakdown).map((childToken) => {
                  const data1155 = extract1155Data(childToken);
                  const displayName = (data1155 ? data1155.item : tokenMap[childToken]).name;
                  const displayIcon = (data1155 ? data1155.item : tokenMap[childToken]).icon;
                  return (
                    <TokenRow key={childToken}>
                      <TokenTitle>
                        <img src={displayIcon} alt="logo" />
                        <TokenName>{displayName}</TokenName>
                      </TokenTitle>
                      <TokenValue>
                        {formatDisplay((analytics.balances[childToken] / (data1155 ? 100 : 1)).toString(), 4)}
                      </TokenValue>
                    </TokenRow>
                  )
                })}
            </TokenBalances>
            <CompositionWrapper>
              {loading ? (
                <CompositionSkeleton length={2} />
              ) : Object.keys(analytics).length > 0 && (
                <Composition list={compositionList} />
              )}
            </CompositionWrapper>
          </MainRow>
          <StatsRow>
            {loading ? (
              <>
                <StatsSkeleton length={4} />
              </>
            ) : Object.keys(analytics).length > 0 && (
              <>
                <StatsItem
                  label="APY"
                  value={`${analytics?.apy > 1 ? formatDisplay(((analytics.apy - 1) * 100).toString(), 2) : "--"}%`}
                  color={'#7ADEB9'}
                />
                <StatsItem
                  label="Fee Tier"
                  value={`${tokenData.fee}%`}
                  color={'#00BDFF'}
                />
                <StatsItem
                  label="TVL"
                  value={`$${formatDisplayShorthand(analytics?.totalValueLocked ?? 0)}`}
                />
                <StatsItem
                  label="24h Vol"
                  value={`$${formatDisplayShorthand(analytics["24HrVolume"] ?? 0)}`}
                />
              </>
            )}
          </StatsRow>
        </>
      ) : null}
  
      <ButtonColumn>
        <ButtonRow>
          {isShellToken(tokenData) || (isExternalToken(tokenData) && isShellV2Token(tokenData)) ? (
            <>
              <ViewPoolStatsButton
                to={getSlugForStatisticsPage(tokenData)}
                outline={true}
                arrow={true}
                label="Pool Stats"
              />
              <ActionButton
                to="/trade"
                outline={false}
                arrow={true}
                label="Add LP"
                textColor="#0B1639"
                state={poolQuery.generateBuySellTokens(tokenData, true)}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
              />
            </>
          ) : (
            <>
              <ActionButton 
                to="/trade"
                outline={false}
                arrow={false} 
                textColor="#0B1639"
                state={poolQuery.generateBuySellTokens(tokenData, true)} 
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                label='Buy'
              />
              <ActionButton 
                to="/trade"
                outline={true}
                arrow={false} 
                state={poolQuery.generateBuySellTokens(tokenData, false)} 
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                label='Sell'
              />
            </>
          )}
          <TransferToken
            token={tokenData}
            balance={userBalance}
            wallet={token.wallet}
          />
        </ButtonRow>
      </ButtonColumn>
    </>
  ) 
}

interface DataItemProps {
  label: string
  value: ReactNode,
  color?: string
}

const StatsItem = ({ label, value, color }: DataItemProps) => (
  <StatsBlock>
    <Label>{label}:</Label>
    <Value color={color} style={{ marginRight: '12px' }}>{value}</Value>
  </StatsBlock>
)

const LoaderContainer = styled.div`
  display: flex; 
  height: 48px; 
  justify-content: center;
  align-items: center;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;

const TokenBalances = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const TokenRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 16px;
  width: 100%;
  max-width: 100%;
`;

const TokenTitle = styled.div`
  display: flex;
  align-items: center;

  & img {
    height: 24px;
    max-height: 24px;
    width: 24px;
    max-width: 24px;
    object-fit: contain;
    margin-right: 8px;
    border-radius: 4px;
  }
`;

const TokenName = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #A9D1E2;
`;

const TokenValue = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 100;
  color: #FFFFFF;
`

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  width: 100%;
  max-width: 100%;
  margin-top: 16px;
  margin-bottom: 22px;
`;

const StatsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: #7D7D97;
`;

const Value = styled.div<{color? : string}>`
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${({ color }) => color ?? '#FFFFFF'};
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  & > a {
    flex-grow: 1;
  }

  & > button {
    //flex-grow: 1;
  }
`;

const ActionButton = styled(LinkButton)`
  height: 48px;
  box-sizing: border-box;
`;

const ViewPoolStatsButton = styled(LinkButton)`
  flex-grow: 1;
  height: 48px;
  box-sizing: border-box;

  & > a {
    flex-grow: 1;
  }

  ${Media.tablet} {
    width: 100%;
  }

  ${Media.mobile} {
    height: 40px;
  }
`;

const CompositionWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;


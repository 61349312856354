import React from 'react';
import styled from 'styled-components';
import { MenuItem, ShellGuideModalTabs } from '../ShellGuideModalBody';
import chevronDown from "@/assets/icons/small-arrow-down.svg";
import closeButton from "@/assets/icons/close-button.png";
import { ModalNavItem } from './ModalNavItem';
import protocolsImage from "@/assets/icons/shellGuide/protocols.png";

interface SelectionTopicMobileLayerProps {
    topics: MenuItem[];
    handleSelectTopic: (topic: ShellGuideModalTabs) => void;
    handleSetToFirstTopic: () => void;
    selectedTopic: MenuItem;
}


export const SelectionTopicMobileLayer = ({ topics, handleSelectTopic, handleSetToFirstTopic, selectedTopic }: SelectionTopicMobileLayerProps) => {
    const [isOpenLayerWithSelection, setIsOpenLayerWithSelection] = React.useState(false);

    const handleCloseLayer = () => {
        setIsOpenLayerWithSelection(false);
    };

    const handleCloseLayerAndSelectFirstTopic = () => {
        handleSetToFirstTopic();
        handleCloseLayer();
    };

    const handleCloseLayerAndSelectAnyTopic = (topic: ShellGuideModalTabs) => {
        handleSelectTopic(topic);
        handleCloseLayer();
    };

    return (
        <>
            <TriggerButton onClick={() => setIsOpenLayerWithSelection(!isOpenLayerWithSelection)}>
                <SmallLabel>
                    <SmallLabelIcon>{selectedTopic.activeIcon}</SmallLabelIcon>
                    <SmallLabelText>{selectedTopic.title}</SmallLabelText>
                </SmallLabel>
                <Arrow src={chevronDown} alt="arrow" />
            </TriggerButton>
            <LayerBackground isOpen={isOpenLayerWithSelection}>
                <LayerModal isOpen={isOpenLayerWithSelection}>
                    <LayerTitleContainer>
                        <LayerTitle>
                            Select Guide
                        </LayerTitle>
                        <CloseBtnWrapper>
                            <CloseBtn src={closeButton} onClick={handleCloseLayer} />
                        </CloseBtnWrapper>
                    </LayerTitleContainer>
                    <LayerNavContainer>
                        <NavContainer>
                            {topics.map((topic, index) => (
                                <ModalNavItem
                                    key={index}
                                    title={topic.title}
                                    icon={selectedTopic.title === topic.title ? topic.activeIcon : topic.icon}
                                    click={() => handleCloseLayerAndSelectAnyTopic(topic.title)}
                                    active={selectedTopic.title === topic.title}
                                />
                            ))}
                        </NavContainer>
                        <SupportContainer>
                            <SupportText>
                                Shell already supports
                            </SupportText>
                            <ComingContainer>
                                <ProtocolsImage src={protocolsImage} alt="protocols" />
                                <ComingText>More coming soon!</ComingText>
                            </ComingContainer>
                        </SupportContainer>
                    </LayerNavContainer>
                </LayerModal>
            </LayerBackground>
        </>
    )
};

const TriggerButton = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const SmallLabel = styled.div`
padding: 4px 14px 4px 6px;
display: flex;
justify-content: flex-start;
align-items: center;
gap: 4px;
border-radius: 8px;
border: 1px solid #1E2239;
background: var(--dark-1, #171B33);
position: relative;
cursor: pointer;
transition: all 0.2s ease-in-out;

&::before {
    position: absolute;
content: "";
width: 3px;
height: 28.5px;
top: 50%;
transform: translateY(-50%);
background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
right: 1px;
border-top-right-radius: 45px;
border-bottom-right-radius: 45px;
}
`;

const SmallLabelIcon = styled.div`
transition: all 0.2s ease-in-out;
svg {
    transition: all 0.2s ease-in-out;
width: 20px;
height: 20px;
}
`;

const SmallLabelText = styled.p`
color: var(--White, #FFF);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;



const Arrow = styled.img`
width: 20px;
height: 20px;
`;

const LayerBackground = styled.div<{ isOpen: boolean }>`
position: fixed;
top: 0;
left: 0;
z-index: 9999999;
width: 100vw;
display: ${({ isOpen }) => isOpen ? "block" : "none"};
height: 100dvh;
background: rgba(26, 33, 73, 0.80);
backdrop-filter: blur(17px);
`;

const LayerModal = styled.div<{isOpen: boolean}>`
position: fixed;
bottom: 0;
min-height: 60dvh;
background: var(--dark-1, #0A0E27);
display: flex;
flex-direction: column;
gap: 0;
border-top-left-radius: 16px;
border-top-right-radius: 16px;
tranform: ${({isOpen}) => isOpen ? "translateY(0)" : "translateY(100%)"};
transition: all 0.3s ease-in-out;
transition-delay: 0.1s;
`;

const CloseBtnWrapper = styled.div`
border-radius: 6px;
background: var(--dark-2, #171B33);
padding: 4px;
display: flex;
justify-content: center;
align-items: center;
width: 24px;
    height: 24px;
`;


const CloseBtn = styled.img`
border-radius: 6px;
top: 10px;
right: 26px;
  cursor: pointer;
  color: white;
  width: 32px;
height: 32px;
`;

const NavContainer = styled.ul`
display: flex;
flex-direction: column;
gap: 0;
`;

const LayerTitleContainer = styled.div`
padding: 16px;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid var(--dark-2, #171B33);
`;

const LayerTitle = styled.p`
color: var(--White, #FFF);
text-align: center;

/* Text 1/Medium */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const LayerNavContainer = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
width: 100vw;
padding: 8px 16px 24px 8px;
`;

const SupportContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 12px;
margin-left: 12px;
`;

const SupportText = styled.p`
color: #7D7D97;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const ComingText = styled.p`
color: #A2BCD4;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const ComingContainer = styled.div`
display: flex;
gap: 7px;
justify-content: flex-start;
align-items: center;
`;

const ProtocolsImage = styled.img`
height: 24px;
`;



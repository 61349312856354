import React, { useEffect, useState } from 'react'
import styled, { css } from "styled-components"
import { PointsIcon } from "@/components/Icons/PointsIcon"
import { CoinsIcon } from '@/components/Icons/CoinsIcon'
import { CompoundingIcon } from '@/components/Icons/CompoundingIcon'
import CountUp from 'react-countup'
import { formatDisplay, formatDisplayShorthand } from '@/utils/formatDisplay'
import { DropdownIconUp, DropdownIconDown } from '@/components/Icons/DropdownIcon'
import { breakpoints, Media } from '@/styles'
import { useWidthBreakpoint } from '@/hooks'
import { FilterSeasonDropdown, SeasonFilter } from './components/FilterSeasonDropdown'
import { CURRENT_SEASON } from '@/types/ChartTypes'
import { itemsMap } from '@/pages/Booty/Items'
import shellIcon from '@/assets/coins/SHELL.svg'
import { CustomTooltip } from '@/components/CustomTooltip/CustomTooltip'
import { SkeletonBox } from '@/components/Loaders/SkeletonBox'
import { useAccount } from 'wagmi'
import { Label } from 'recharts'

export const PointsDetails = (props: {
  compoundedPoints: number;
  totalShellPoints: number;
  forecastedPointsGain: number;
  boost: any;
  totalValue: number;
  seasonFilter: SeasonFilter;
  shellBalance: string;
  isOwnWallet: boolean;
  seasonFilters: SeasonFilter[];
  isLoading: boolean;
  setSeasonFilter: (filter: any) => void;
  userEpochs?: any
}) => {
  const {
    compoundedPoints,
    totalShellPoints,
    forecastedPointsGain,
    boost,
    totalValue,
    seasonFilter,
    shellBalance,
    isOwnWallet,
    seasonFilters,
    isLoading,
    setSeasonFilter,
    userEpochs
  } = props;

  const [pointCounter, setPointCounter] = useState<any>({ start: 0, end: totalShellPoints, duration: 1 })
  const [boostDetailsVisible, setBoostDetailsVisible] = useState(false)

  const seasonEpochs : {[id: string]: Set<string>} = {
    'Season One': new Set([]),
    'Season Two': new Set([]),
    'Season Three': new Set([]),
    'Season Four': new Set(['0']),
    'Season Five': new Set(['1'])
  }

  const alreadyClaimed = userEpochs ? userEpochs.map((epoch: any) => {
    if(seasonEpochs[seasonFilter.label].has(epoch.name) && epoch.status == "Claimed"){
        return epoch.value
    } else {
        return 0
    }
  }).reduce((partialSum: number, a: number) => partialSum + a, 0) : 0;

  const formatShellPoints = (points: number) => formatDisplay(Math.round(points).toString(), 0)

  const headerText = seasonFilter.label == CURRENT_SEASON ? 'SHELL Rewards' : 'Shell Points'
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const { isConnected } = useAccount();
  useEffect(() => {
    setPointCounter({
        start: 0,
        end: isConnected ? totalShellPoints : 0,
        duration: 1,
    });
  }
  , [isConnected, totalShellPoints])

  return (
    <PointsDetailsWrapper>
      <PointsHeader>
        <TitleTooltipContainer>
          <HeaderSubtitle>{isOwnWallet ? 'My' : ''} {headerText}</HeaderSubtitle>
          <CustomTooltip
            position={isTablet ? "bottom" : "right"}
            title="SHELL Rewards"
            // subtext="Displayed balance is your earned SHELL rewards, claimable after the reward period ends."
            subtext={
                <div style={{display: "flex", flexDirection: 'column', alignItems: 'baseline', rowGap: '8px'}}>
                  <p>Displayed value is your total earned SHELL rewards, claimable periodically throughout the season.</p> 
                  {alreadyClaimed > 0 && <p>{`Already claimed: ${formatDisplay(alreadyClaimed.toString(), 2)} SHELL`}</p>}
                </div>
            }
            delay={50}
          />
        </TitleTooltipContainer>
        <ChartHeader>
            <SeasonFiltersContainer>
              <FilterSeasonDropdown
                onChange={(filter) => setSeasonFilter(filter)}
                list={seasonFilters}
                currentFilter={seasonFilter}
              />
            </SeasonFiltersContainer>
          </ChartHeader>
      </PointsHeader>
      <Column>
        <ValueColumn>
          {seasonFilter.label == CURRENT_SEASON ? (
            <img src={shellIcon} />
          ) : (
            <PointsIcon />
          )}
          { isLoading ? (
            <SkeletonBox
              isLoading={true}
              width="156px"
              height="32px"
              borderRadius="8px"
            />
          ) : (
            <Value num={totalShellPoints}>
              <CountUp
                start={pointCounter.start}
                end={pointCounter.end}
                duration={pointCounter.duration}
                formattingFn={formatShellPoints}
                onEnd={() => {
                  if (pointCounter.start == 0) {
                    setPointCounter({
                      start: totalShellPoints,
                      end:
                        totalShellPoints +
                        (seasonFilter.label != CURRENT_SEASON ||
                        forecastedPointsGain < 1
                          ? 0
                          : forecastedPointsGain),
                      duration: 60 * 60 * 24,
                    });
                  } else {
                    window.location.reload();
                  }
                }}
              />
            </Value>
          )}
        </ValueColumn>
      </Column>
      {seasonFilter.label == CURRENT_SEASON ? (
        <Statistics>
          <StatisticsItem>
            <TitleTooltipContainer>
              <StatisticsTitle>Next 24h Gain</StatisticsTitle>
              <CustomTooltip
                position={isTablet ? "top" : "right"}
                title="Next 24h Gain"
                subtext="Updates daily at midnight UTC. Display may not show latest gains immediately."
                delay={50}
              />
            </TitleTooltipContainer>
            <StatisticsImage>
              {/* <PointsIcon /> */}
              <img src={shellIcon} />
            </StatisticsImage>
            <StatisticsValue>
              { isLoading ? (
                <SkeletonBox
                  isLoading={true}
                  width="72px"
                  height="24px"
                  borderRadius="8px"
                />
              ) : (
                formatDisplayShorthand(forecastedPointsGain)
              )}
            </StatisticsValue>
          </StatisticsItem>
          <StatisticsItem>
            <StatisticsTitle>SHELL</StatisticsTitle>
            <StatisticsImage>
              <img src={shellIcon} />
            </StatisticsImage>
            { isLoading ? (
              <SkeletonBox
                isLoading={true}
                width="72px"
                height="24px"
                borderRadius="8px"
              />
            ) : (
              <StatisticsValue>{shellBalance}</StatisticsValue>
            )}
          </StatisticsItem>
          {/* <StatisticsItem>
                <StatisticsTitle>Compounded Points</StatisticsTitle>
                <StatisticsImage>
                    <CompoundingIcon/>
                </StatisticsImage>
                <StatisticsValue>
                    {formatDisplayShorthand(compoundedPoints)}
                </StatisticsValue>
            </StatisticsItem> */}
          <StatisticsItem>
            <TitleTooltipContainer>
              <StatisticsTitle>Active Boost</StatisticsTitle>
              <CustomTooltip
                position={isTablet ? "top" : "right"}
                title="Notice"
                subtext="Boosts are not applied to the following tokens: veSHELL"
                delay={50}
              />
            </TitleTooltipContainer>
            <StatisticsImage>
              <img src={`../../assets/icons/boost.svg`} />
            </StatisticsImage>
            <StatisticsValue>
              { isLoading ? (
                <SkeletonBox
                  isLoading={true}
                  width="72px"
                  height="24px"
                  borderRadius="8px"
                />
              ) : (
                `${formatDisplay((boost.currentBoost ?? 1).toString(), 3)}x`
              )}
              {boost.currentBoost > 1 && (
                <ExpandButton
                  onClick={() => setBoostDetailsVisible(!boostDetailsVisible)}
                >
                  {boostDetailsVisible ? (
                    <DropdownIconUp />
                  ) : (
                    <DropdownIconDown />
                  )}
                </ExpandButton>
              )}
              <TooltipBox visible={boostDetailsVisible}>
                <Column style={{ rowGap: "4px" }}>
                  {boost.activatedArtifacts &&
                    Object.keys(boost.activatedArtifacts).map(
                      (artifactIndex: any) => (
                        <Artifact
                          key={artifactIndex}
                          src={`../../assets/booty/${
                            itemsMap[parseInt(artifactIndex)].image
                          }.jpg`}
                          style={{ width: "32px", height: "32px", margin: 0 }}
                          chad={
                            parseFloat(
                              boost.activatedArtifacts[artifactIndex]
                                .chadTimeRemaining
                            ) > 0
                          }
                        />
                      )
                    )}
                </Column>
                <Column style={{ width: "fit-content", rowGap: "4px" }}>
                  {boost.activatedArtifacts &&
                    Object.keys(boost.activatedArtifacts).map(
                      (artifactIndex: any) => (
                        <div
                          key={artifactIndex}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            height: "32px",
                          }}
                        >
                          <img
                            style={{ width: "16px", marginRight: "4px" }}
                            src={`../../assets/icons/boost.svg`}
                          />
                          <BoostValue style={{ fontSize: "14px" }}>{`${
                            itemsMap[parseInt(artifactIndex)].effect?.value
                          }%`}</BoostValue>
                        </div>
                      )
                    )}
                </Column>
                <Column style={{ width: "fit-content", rowGap: "4px" }}>
                  {boost.activatedArtifacts &&
                    Object.keys(boost.activatedArtifacts).map(
                      (artifactIndex: any) => {
                        const days = parseInt(
                          boost.activatedArtifacts[artifactIndex].timeRemaining
                        );
                        const hours = Math.floor(
                          (boost.activatedArtifacts[artifactIndex]
                            .timeRemaining -
                            days) *
                            24
                        );
                        return (
                          <div
                            key={artifactIndex}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              height: "32px",
                            }}
                          >
                            <img
                              style={{ width: "16px", marginRight: "4px" }}
                              src={`../../assets/icons/time.svg`}
                            />
                            <BoostValue>{`${days == 0 ? "" : days + "d"} ${
                              hours == 0
                                ? days == 0
                                  ? "<1h"
                                  : ""
                                : hours + "h"
                            }`}</BoostValue>
                          </div>
                        );
                      }
                    )}
                </Column>
              </TooltipBox>
            </StatisticsValue>
          </StatisticsItem>
          <StatisticsItem>
            <StatisticsTitle>Wallet Value (USD)</StatisticsTitle>
            <StatisticsImage>
              <CoinsIcon />
            </StatisticsImage>
            <StatisticsValue>
              { isLoading ? (
                <SkeletonBox
                  isLoading={true}
                  width="72px"
                  height="24px"
                  borderRadius="8px"
                />
              ) : (
                `$${formatDisplayShorthand(totalValue)}`
              )}
            </StatisticsValue>
          </StatisticsItem>
        </Statistics>
      ) : (
        seasonFilter.label == "Season One" && (
          <StatisticsItem>
            <StatisticsTitle>Compounded Points</StatisticsTitle>
            <StatisticsImage>
              <CompoundingIcon />
            </StatisticsImage>
            <StatisticsValue>
              {formatDisplayShorthand(compoundedPoints)}
            </StatisticsValue>
          </StatisticsItem>
        )
      )}
    </PointsDetailsWrapper>
  );
};

const PointsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  row-gap: 12px;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
`;

const PointsDetailsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding: 24px 12px;

  ${Media.tablet} {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    column-gap: unset;
    padding: 24px 8px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  & > svg {
    width: 18px;
    height: 18px;
    color: #00bdff;
    margin: 0 8px;
  }

  & > img {
    width: 18px;
    height: 18px;
    color: #00bdff;
    margin: 0 8px;
  }
`;

const Subtitle = styled.h4`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #7d7d97;

  ${Media.tablet} {
    font-size: 12px;
    line-height: 14px;
  }
`;

const HeaderSubtitle = styled(Subtitle)`
  ${Media.tablet} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const ValueColumn = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  column-gap: 12px;
  margin-top: 12px;

  & > svg {
    color: #00bdff;
  }

  & > img {
    width: 28px;
    height: 28px;
  }
`;

const Value = styled.span<{ num: number }>`
  font-size: 46px;
  line-height: 56px;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: #ffffff;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ num }) =>
    num >= 100000000 &&
    css`
      font-size: 42px;
    `};
`;

const BoostValue = styled.p`
  font-size: 13px;
  color: #ffffff;
  font-weight: 100;
`;

const Statistics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  column-gap: 24px;
  row-gap: 24px;
  width: fit-content;
  max-width: 100%;
  margin: 16px 0 8px;
  height: fit-content;

  ${Media.smallDesktop} {
    justify-items: start;
  }
`;

const StatisticsItem = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  column-gap: 8px;
  row-gap: 4px;

  ${Media.tablet} {
    display: grid;
    grid-template-columns: 20px auto;
    grid-template-areas:
      "title title"
      "image value";
  }
`;

const TitleTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
`;

const StatisticsTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #7d7d97;

  ${Media.tablet} {
    grid-area: title;
    font-size: 12px;
    line-height: 18px;
  }
`;

const StatisticsImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px !important;
  max-width: 20px;
  height: 20px !important;
  max-height: 20px;
  color: #00bdff;
  overflow: hidden;

  & > img,
  & > svg {
    max-width: 100%;
    max-height: 100%;
    color: inherit;
  }

  ${Media.tablet} {
    grid-area: image;
  }
`;

const ClaimButton = styled.button`
  width: 72px;
  height: 32px;

  margin-left: 8px;

  &:hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    border: solid 1.5px #000e47;
    color: #000e47;
  }

  border: solid 1.5px #00bdff;
  border-radius: 8px;
  color: #00bdff;
`;

const ExpandButton = styled.button`
  align-items: center;
  width: 20px;
  height: 20px;

  &:hover {
    opacity: 0.7;
  }
`;

const TooltipBox = styled.div<{ visible: boolean }>`
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  margin-top: 12px;
  margin-left: -112px;
  column-gap: 4px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 174px;
  padding: 4px 4px;
  border-radius: 8px;
  box-shadow: 0 0px 6px #00bdff;
  background-color: #1e2239;

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
    `};

  ${Media.tablet} {
    margin-left: -26px;
  }
`;

const StatisticsValue = styled.span`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #ffffff;

  ${Media.tablet} {
    grid-area: value;

    font-size: 16px;
    line-height: 20px;
  }
`;

const Artifact = styled.img<{ chad?: boolean }>`
  width: 36px;
  border-radius: 6px;
  border: 1px solid #1e2239;

  ${({ chad }) =>
    chad &&
    css`
      box-shadow: 0 0px 4px #0add08;
    `};
`;

const ForecastLoader = styled.img`
  width: 28px;
  margin-bottom: 4px;
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  ${Media.tablet} {
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    padding: 0px;
  }
`;

const SeasonFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

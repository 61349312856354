import { useAirDropHandler } from "./hooks/useAirDropHandler";
import { useRewardsHandler } from "./hooks/useRewardsHandler"
import { useSTIPHandler } from "./hooks/useSTIPHandler";
import { useStakingHandler } from "./hooks/useStakingHandler";
import { useVestingHandler } from "./hooks/useVestingHandler";
import { parseEther } from "ethers/lib/utils";

export const useRewardsContextData = () => {

    const {
        claimAllStreams,
        claimStream,
        setIsClaimModalOpen,
        isClaimModalOpen,
        isClaimSuccess,
        closeClaimModal,
        myRewards,
        setIsClaimSuccess,
        claimTxSubmitted,
        unclaimedShell,
        handleClaimShell,
        shellClaimTxSubmitted,
        shellClaimSuccess,
        isShellClaimModalOpen,
        setIsShellClaimModalOpen,
        handleCloseShellClaimModal,
        userEpochs
    } = useRewardsHandler();

    const {
        setModalDelayTimerId,
        handleInitStake,
        handleOpenClaimStakeModal,
        isIncreaseLockPeriodModalOpen,
        isStakeModalOpen,
        isUnstakeModalOpen,
        isClaimStakeModalOpen,
        setIsClaimStakeModalOpen,
        handleCloseClaimStakeModal,
        currentLockPeriod,
        savedLockPeriod,
        myStaked,
        handleSelectLockPeriod,
        isOpenStakeConfirmationModal,
        handleCloseStakeConfirmationModal,
        isStakeConfirmed,
        handleRedirectToStaking,
        handleConfirmStake,
        handleConfirmStakeAlert,
        isOpenAlertModal,
        setIsOpenAlertModal,
        isStakeAlertConfirmed,
        setIsStakeAlertConfirmed,
        handleCloseAlertModal,
        handleChangeLockPeriod,
        handleOpenIncreaseLockPeriodModal,
        handleCloseIncreaseLockPeriodModal,
        handleInitIncreaseLockPeriodAfterStake,
        handleIncreaseLockPeriod,
        isOpenChangeLockPeriodConfirmationModal,
        handleCloseChangeLockPeriodConfirmationModal,
        isIncreaseLockPeriodSuccess,
        handleOpenStakeModal,
        handleCloseStakeModal,
        amountToStake,
        setAmountToStake,
        onChangeStakeAmount,
        myAvailableBalance,
        handleStakeMax,
        handleOpenUnstakeModal,
        handleCloseUnstakeModal,
        withdrawableAmount,
        isOpenUnstakeConfirmationModal,
        handleCloseUnstakeConfirmationModal,
        handleInitUnstake,
        isUnstakeConfirmed,
        stakingInfo,
        getUnlockTime,
        projectedVeShell,
        projectedEndTime,
        stakeApprovalState,
        stakeApprovedAmount,
        stakeBalanceError,
        approveStake,
        stakingLoading,
        streamToClaim,
        stakeTxSubmitted
    } = useStakingHandler();

    const {
        handleInitClaim: airDropClaim,
        userSeasons,
        airDropBalance,
        unclaimedCrab,
        isClaimModalOpen :isAirDropClaimModalOpen,
        handleCloseClaimModal :handleCloseAirDropClaimModal,
        setIsClaimConfirmationModalOpen,
        handleCloseClaimConfirmationModal :handleCloseAirDropClaimConfirmationModal,
        isClaimConfirmationModalOpen :isAirDropConfirmationModalOpen,
        isAirDropClaimConfirmed,
        setIsAirDropClaimConfirmed,
        handleSortAirDropRewards,
        handleSortAirDropToken,
        otherAirdrops,
        isCrabClaimModalOpen,
        handleOpenCrabClaimModal,
        handleCloseCrabClaimModal,
        crabBalance,
        crabApprovalState,
        setCrabApprovalState,
        crabApprovedAmount,
        approveCrab,
        handleClaimCrab,
        airdropLoading,
        crabClaimTxSubmitted,
        crabClaimSuccess,
        claimedStreamId,
        airdropTxSubmitted,
        airdropShellAmount,
        handleClaimAirdrop,
        // Other Airdrops
        handleOtherAirdropClaim,
        handleOtherAirdropClose,
        isOtherAirDropClaimModalOpen,
        otherClaimTxSubmitted,
        otherClaimSuccess,
        currentAirdrop,
    } = useAirDropHandler();


    const {
        vestingStartTime,
        vestingEndTime,
        vestingStreams,
        handleSortVestingStreams,
        streamSort,
        vestingLoading,
        handleRedirectToVesting,
    } = useVestingHandler();

    const {
        activeStakePools,
        pastStakePools,
        stipLoading,
        isGaugeClaimModalOpen,
        gaugeClaimTxSubmitted,
        isGaugeClaimSuccess,
        gaugeClaimAmount,
        claimFromGauge,
        closeGaugeClaimModal,
        stipRewardToken
    } = useSTIPHandler()

    const getTimeUntil = (endTime: number) => {
    
        const diffInSecs = endTime - new Date().getTime() / 1000;

        if(diffInSecs <= 0) return '0Y 0M 0D'
    
        // Calculate seconds in a day, month, and year
        const secsInDay = 24 * 60 * 60;
        const secsInMonth = secsInDay * 30.436875; // Average days in a month
        const secsInYear = secsInDay * 365.2425; // Average days in a year
    
        // Calculate the difference in years
        const years = Math.floor(diffInSecs / secsInYear);
        let remainingSecs = diffInSecs - years * secsInYear;
    
        // Calculate the difference in months
        const months = Math.floor(remainingSecs / secsInMonth);
        remainingSecs -= months * secsInMonth;
    
        // Calculate the difference in days
        const days = Math.floor(remainingSecs / secsInDay);

        if(diffInSecs > 0 && years == 0 && months == 0 && days == 0){
            return `<${years}Y ${months}M 1D`
        }
    
        const formattedDuration = `${years}Y ${months}M ${days}D`;
        return formattedDuration;
    }

    const handleConfirmClaimAndStakeAlert = () => {
        setIsStakeAlertConfirmed(true)
        claimAndStake()
    }

    const handleClaimAndStakeChangeLockPeriod = () => {
        setIsOpenAlertModal(false);
        setIsClaimStakeModalOpen(true);
    };

    const handleClaimAndStake = () => {

        if (!myStaked && !isStakeAlertConfirmed) {
            setIsClaimStakeModalOpen(false);
            // setIsStakeModalOpen(false);
            setIsOpenAlertModal(true);
            return;
        }
        setIsClaimStakeModalOpen(false);
        // setIsStakeModalOpen(false);
        const modalDelayTimer = setTimeout(() => {
            claimAndStake();
        }, 230);
        setModalDelayTimerId(modalDelayTimer);

    }

    const claimAndStake = () => {
      setIsOpenAlertModal(false);
      const claimPromise =
        streamToClaim < 0
          ? handleClaimShell()
          : streamToClaim == 0
          ? claimAllStreams(
              vestingStreams.map((stream) => stream.id),
              vestingStreams.map((stream) => parseEther(stream.claimable)),
              vestingStreams
                .map((stream) => parseFloat(stream.claimable))
                .reduce((partialSum, a) => partialSum + a, 0)
            )
          : claimStream(streamToClaim, amountToStake);

      claimPromise
        .then((claimedAmount: number) => {
          setIsClaimModalOpen(false);
          setAmountToStake(claimedAmount);
          handleConfirmStake(
            streamToClaim < 0 ? setIsShellClaimModalOpen : setIsClaimModalOpen
          ).then(() => setIsClaimSuccess(false));
        })
        .catch(() => {
          setIsClaimStakeModalOpen(true);
        });
    };

    return {
        myRewards,
        isClaimModalOpen,
        isClaimSuccess,
        claimAllStreams,
        claimStream,
        handleInitStake,
        closeClaimModal,
        isIncreaseLockPeriodModalOpen,
        isStakeModalOpen,
        isUnstakeModalOpen,
        isClaimStakeModalOpen,
        handleOpenClaimStakeModal,
        handleCloseClaimStakeModal,
        handleClaimAndStake,
        currentLockPeriod,
        savedLockPeriod,
        claimTxSubmitted,
        unclaimedShell,
        handleClaimShell,
        shellClaimTxSubmitted,
        shellClaimSuccess,
        isShellClaimModalOpen,
        handleCloseShellClaimModal,
        userEpochs,
        airdropLoading,
        myStaked,
        handleSelectLockPeriod,
        isOpenStakeConfirmationModal,
        handleCloseStakeConfirmationModal,
        isStakeConfirmed,
        handleRedirectToStaking,
        handleConfirmStakeAlert,
        isOpenAlertModal,
        handleCloseAlertModal,
        handleChangeLockPeriod,
        handleClaimAndStakeChangeLockPeriod,
        handleOpenIncreaseLockPeriodModal,
        handleCloseIncreaseLockPeriodModal,
        handleInitIncreaseLockPeriodAfterStake,
        handleIncreaseLockPeriod,
        isOpenChangeLockPeriodConfirmationModal,
        handleCloseChangeLockPeriodConfirmationModal,
        isIncreaseLockPeriodSuccess,
        userSeasons,
        airDropBalance,
        unclaimedCrab,
        isAirDropClaimModalOpen,
        handleCloseAirDropClaimModal,
        setIsClaimConfirmationModalOpen,
        airDropClaim,
        handleOpenStakeModal,
        handleCloseStakeModal,
        amountToStake,
        setAmountToStake,
        onChangeStakeAmount,
        myAvailableBalance,
        handleStakeMax,
        handleOpenUnstakeModal,
        handleCloseUnstakeModal,
        withdrawableAmount,
        isOpenUnstakeConfirmationModal,
        handleCloseUnstakeConfirmationModal,
        handleInitUnstake,
        isUnstakeConfirmed,
        stakingInfo,
        getUnlockTime,
        projectedVeShell,
        projectedEndTime,
        stakeApprovalState,
        stakeApprovedAmount,
        stakeBalanceError,
        approveStake,
        stakingLoading,
        isAirDropConfirmationModalOpen,
        handleCloseAirDropClaimConfirmationModal,
        isAirDropClaimConfirmed,
        setIsAirDropClaimConfirmed,
        handleRedirectToVesting,
        vestingStreams,
        vestingLoading,
        vestingStartTime,
        vestingEndTime,
        handleSortVestingStreams,
        streamSort,
        handleSortAirDropRewards,
        handleSortAirDropToken,
        otherAirdrops,
        isCrabClaimModalOpen,
        handleOpenCrabClaimModal,
        handleCloseCrabClaimModal,
        crabBalance,
        crabApprovalState,
        setCrabApprovalState,
        crabApprovedAmount,
        approveCrab,
        handleClaimCrab,
        crabClaimTxSubmitted,
        crabClaimSuccess,
        claimedStreamId,
        airdropTxSubmitted,
        airdropShellAmount,
        handleClaimAirdrop,
        getTimeUntil,
        claimAndStake,
        streamToClaim,
        handleConfirmClaimAndStakeAlert,
        stakeTxSubmitted,
        // Other Airdrops
        handleOtherAirdropClaim,
        handleOtherAirdropClose,
        isOtherAirDropClaimModalOpen,
        otherClaimTxSubmitted,
        otherClaimSuccess,
        currentAirdrop,
        activeStakePools,
        pastStakePools,
        stipLoading,
        isGaugeClaimModalOpen,
        gaugeClaimTxSubmitted,
        isGaugeClaimSuccess,
        gaugeClaimAmount,
        claimFromGauge,
        closeGaugeClaimModal,
        stipRewardToken
    }
}
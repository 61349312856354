import { CustomModal } from "@/components/CustomModal/CustomModal";
import React, { useContext } from 'react';
import { useRewardsContext } from "../../RewardsContext";
import { AlertModalBody } from "../modals/AlertModalBody";
import { ChangeLockPeriodConfirmationModalBody } from "../modals/ChangeLockPeriodConfirmationModalBody";
import { ClaimConfirmationModalBody } from "../modals/ClaimConfirmationModalBody";
import { IncreaseLockPeriodModalBody } from "../modals/IncreaseLockPeriodModalBody";
import { StakeConfirmationModalBody } from "../modals/StakeConfirmationModalBody";
import { StakeModalBody } from "../modals/StakeModalBody";
import { VestingBox } from "./components/VestingBox";
import { VestingDetails } from "./components/VestingDetails";
import { VestingProgress } from "./components/VestingProgress";
import { VestingStreams } from "./components/VestingStreams";
import { VestingTimeline } from "./components/VestingTimeline";
import * as S from "./Vesting.styled";
import GeoRestrictedOverlay from "@/components/Overlays/GeoRestrictedOverlay";
import { GeoContext } from "@/components/Overlays/GeoProvider";

export const Vesting = () => {
  const {
    vestingLoading,
    isClaimModalOpen,
    closeClaimModal,
    isClaimStakeModalOpen,
    handleCloseClaimStakeModal,
    isOpenStakeConfirmationModal,
    handleCloseStakeConfirmationModal,
    isOpenAlertModal,
    handleCloseAlertModal,
    handleCloseIncreaseLockPeriodModal,
    isIncreaseLockPeriodModalOpen,
    isOpenChangeLockPeriodConfirmationModal,
    handleCloseChangeLockPeriodConfirmationModal,
    vestingStreams,
  } = useRewardsContext();

  const { isGeoLoading, isUS } = useContext(GeoContext);

  return (
    <>
      <GeoRestrictedOverlay isUS={isUS}>
        <S.VestingContainer>

            <>
              <VestingBox />
              {vestingStreams.length > 0 && (
                <>
                  <S.DetailsAndProgressWrapper>
                    <VestingProgress />
                    <VestingDetails />
                  </S.DetailsAndProgressWrapper>
                  <VestingTimeline />
                  <VestingStreams />
                </>
              )}
            </>

        </S.VestingContainer>
      </GeoRestrictedOverlay>
      <CustomModal
        isOpen={isClaimModalOpen}
        onClose={() => closeClaimModal()}
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        withOutsideClick
        withBackdropBlur
      >
        <ClaimConfirmationModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isClaimStakeModalOpen}
        onClose={() => handleCloseClaimStakeModal()}
        withOutsideClick
        withBackdropBlur
        width="654px"
      >
        <StakeModalBody isClaimVariant />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenStakeConfirmationModal}
        onClose={() => handleCloseStakeConfirmationModal()}
        withOutsideClick
        withBackdropBlur
      >
        <StakeConfirmationModalBody isClaimVariant />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenAlertModal}
        onClose={() => handleCloseAlertModal()}
        withOutsideClick
        withBackdropBlur
      >
        <AlertModalBody isClaimVariant />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isIncreaseLockPeriodModalOpen}
        onClose={() => handleCloseIncreaseLockPeriodModal()}
        withOutsideClick
        withBackdropBlur
        width="654px"
      >
        <IncreaseLockPeriodModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenChangeLockPeriodConfirmationModal}
        onClose={() => handleCloseChangeLockPeriodConfirmationModal()}
        withOutsideClick
        withBackdropBlur
      >
        <ChangeLockPeriodConfirmationModalBody />
      </CustomModal>
    </>
  );
};
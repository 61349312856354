import React, { useState } from "react";
import styled from "styled-components";
import { Media } from "../../styles";
import smallShellLogo from "@/assets/coins/shell-small.svg";
import { formatDisplay } from "@/utils/formatDisplay";

interface ItemProps {
  dataTestId?: string;
  icon: string;
  title: string;
  subtitle?: string;
  aboveTitle?: string;
  selected?: boolean;
  allocation?: string;
  tokensToClaim?: string;
  isVestingVariant?: boolean;
  onClick: () => void;
}

export const CollectionGridItem = ({
  dataTestId,
  icon,
  title,
  subtitle,
  aboveTitle,
  allocation,
  tokensToClaim,
  isVestingVariant,
  selected,
  onClick,
}: ItemProps) => {
  const [hovered, setHovered] = useState(false);
  return isVestingVariant ? (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick} isVestingStream onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <ImageContainer>
        <Logo src={icon} alt="Logo" isVestingStream />
        <ImageMask selected={selected || hovered} />
        <AllocationInfoContainer selected={selected || hovered}>
        <AllocationInfoWrapper>
          <AllocationInfoLabel>Claimable:</AllocationInfoLabel>
          <AllocationInfoValue>
            {formatDisplay(tokensToClaim, 2)}
            <img src={smallShellLogo} alt="small shell icon" />
            </AllocationInfoValue>
        </AllocationInfoWrapper>
        <AllocationInfoWrapper>
          <AllocationInfoLabel>Vesting:</AllocationInfoLabel>
          <AllocationInfoValue>
            {formatDisplay(allocation, 2)}
            <img src={smallShellLogo} alt="small shell icon" />
            </AllocationInfoValue>
        </AllocationInfoWrapper>
        </AllocationInfoContainer>
      </ImageContainer>
      <AboveTitle>{aboveTitle}</AboveTitle>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Button>
  ) : (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick}>
      <ImageContainer>
        <Logo src={icon} alt="Logo" />
      </ImageContainer>
      <AboveTitle>{aboveTitle}</AboveTitle>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Button>
  );
};

const Button = styled.button<{ selected: boolean | undefined, isVestingStream?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(#171B33, #171B33) padding-box, linear-gradient(to right, #37dcf2, #07c0fb) border-box"
      : "#171B33"};
  border: 1px solid transparent;
  border-radius: 16px;

  &:hover {
    outline: ${({ isVestingStream }) => isVestingStream ? "1px solid #2C5173" : "2px solid #2C5173"};
  }
  &:focus {
    box-shadow: 0px 19px 45px #04081c;
    border-color: transparent;
    outline: ${(props) => (props.selected ? "none" : "2px solid #2C5173")};
  }

  ${Media.mobile} {
    // max-width: 182px;
  }
`;

const Logo = styled.img<{ isVestingStream?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ isVestingStream }) => isVestingStream ? "10px" : "12px"};
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 0px;
    padding-top: 100%;
  }
`;

const ImageMask = styled.div<{ selected?: boolean }>`
width: 100%;
  height: 148.25px;
  border-radius: 10px;
background: #0A0E274D;
position: absolute;
top: 0;
left: 0;
opacity: ${({selected}) => selected ? "1" : "0"};
transition: all 0.25s ease-in-out;
`;

const AllocationInfoContainer = styled.div<{selected: boolean | undefined}>`
position: absolute;
display: flex;
flex-direction: column;
gap: 8px;
align-items: flex-start;
justify-content: center;
border-radius: 8px;
background: rgba(23, 27, 51, 0.80);
backdrop-filter: blur(10px);
bottom: 4px;
width: calc(100% - 8px);
left: 4px;
padding: 10px;
opacity: ${({selected}) => selected ? "1" : "0"};
transition: all 0.25s ease-in-out;
`;

const AllocationInfoWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 2px;
align-items: flex-start;
justify-content: center;
`;

const AllocationInfoLabel = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 4/Regular */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 16.32px */
`;

const AllocationInfoValue = styled.p`
color: var(--White, #FFF);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
display: flex;
justify-content: flex-start;
gap: 6px;
align-items: center;

img {
  width: 16px;
  height: 16px;
}
`;

const AboveTitle = styled.p`
  margin-top: 12px;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  text-align: left;
  ${Media.mobile} {
    font-size: 12px;
  }
`;

const Title = styled.p`
  margin-top: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const SubTitle = styled.p`
  margin-top: 2px;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;

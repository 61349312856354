import { useWidthBreakpoint } from "@/hooks";
import { Media, breakpoints } from "@/styles";
import React, { Fragment, useMemo, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { PointsTokenPreviewCell } from "../../Points/components/TableCells/PointsTokenPreviewCell";
import { SortIcon } from "@/components/Icons/SortIcon";
import { useTable, useFilters, useSortBy } from "react-table";
import { STIPTale } from "./STIPTale";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { LoadingRows } from "@/components/Table/GenericTable";
import { useRewardsContext } from "../../../RewardsContext";
import { StakeButtons } from "./StakeButtons";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { View as TradeView } from "../../../../Trade/TradeScreen";
import { TradeWidget } from "@/pages/Trade/TradeWidget";
import { USDValue } from "@/pages/Trade/InputPanel";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";

export const STIPTable = (props: any) => {
  const { tokenMap } = useContext(ChainContext);

  const { tokens, isActive } = props;
  const { stipLoading, stipRewardToken } = useRewardsContext();

  const [selected, setSelected] = useState<{ [id: string]: string }>({});

  const onTableRowClick = (id: string, action: string) => {
    const newSelected = { ...selected };
    Object.keys(newSelected).forEach((key) => {
      if (key !== id) {
        newSelected[key] = "";
      }
    });
    if (newSelected[id] == action) {
      newSelected[id] = "";
    } else {
      newSelected[id] = action;
    }
    setSelected(newSelected);
  };

  const showTradeWidget = useMemo(() => {
    return Object.values(selected).find((e) => e == "Stake" || e == "Unstake") !== undefined;
  }, [selected]);

  const selectedToken = useMemo(() => {
    return Object.keys(selected).find((key) => selected[key] == "Stake" || selected[key] == "Unstake") ?? "";
  }, [selected]);

  const activeIndex = useMemo(() => {
    return Object.values(selected).findIndex((e) => e == "Stake" || e == "Unstake") ?? 0;
  }, [selected]);

  const columns = useMemo(
    () => [
      {
        accessor: "token",
        Cell: ({ row }: any) => <PointsTokenPreviewCell row={row} />,
        disableSortBy: true,
      },
      {
        Header: isActive ? "APY" : "",
        accessor: "apy",
        Cell: ({ value, row }: any) => {
          if (showTradeWidget || !isActive) return <></>;
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {value && value > 0 ? (
                <APY>{`${formatDisplayShorthand(value * 100, 2)}%`}</APY>
              ) : (
                <SkeletonBox isLoading width="96px" height="16px" borderRadius="4px" />
              )}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Balances",
        accessor: "staked",
        Cell: ({ value, row }: any) => {
          if (showTradeWidget) return <></>;
          return (
            <Column>
              <BalanceWrapper>
                <Balance pending={false}>{formatDisplayShorthand(value, 2)}</Balance>
                <TokenIcon src={tokenMap[row.original.name].icon} />
                <Text>staked</Text>
                {row.original.stakedPrice ? (
                  <USDValue>{`($${formatDisplayShorthand(value * row.original.stakedPrice, 2)})`}</USDValue>
                ) : (
                  <SkeletonBox isLoading width="48px" height="16px" borderRadius="4px" style={{ marginLeft: "3px" }} />
                )}
              </BalanceWrapper>
              <BalanceWrapper>
                <Balance pending={false}>{formatDisplayShorthand(row.original.reward, 2)}</Balance>
                <TokenIcon src={stipRewardToken.icon} />
                <Text>reward</Text>
                {row.original.rewardPrice ? (
                  <USDValue>{`($${formatDisplayShorthand(row.original.reward * (row.original.rewardPrice ?? 0), 2)})`}</USDValue>
                ) : (
                  <SkeletonBox isLoading width="48px" height="16px" borderRadius="4px" style={{ marginLeft: "3px" }} />
                )}
              </BalanceWrapper>
            </Column>
          );
        },
        disableSortBy: true,
      },
      {
        id: "Stake",
        accessor: "stake",
        Cell: ({ value, row }: any) => {
          if (showTradeWidget && (selected[row.original.name] ?? "") == "") return <></>;
          return (
            <StakeButtons
              isActive={isActive}
              row={row.original}
              chosenAction={selected[row.original.name] ?? ""}
              onClick={(action: string) => {
                onTableRowClick(row.original.name, action);
              }}
            />
          );
        },
        disableSortBy: true,
      },
    ],
    [showTradeWidget, selected]
  );

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

  const data = tokens;

  const tableInstance = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <TradeContainer showTradeWidget={showTradeWidget}>
      {tokens.length > 0 &&
        !stipLoading &&
        (isTablet ? (
          <TalesWrapper>
            {rows.map((row: any, index: number) => (
              <STIPTale key={row.id + "-STIPTale"} row={row.original} isActive={isActive} />
            ))}
          </TalesWrapper>
        ) : (
          <Table {...getTableProps()} showTradeWidget={showTradeWidget}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {
                        <>
                          {column.render("Header")}
                          {column.canSort && <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />}
                          {column.canFilter && <div>{column.render("Filter")}</div>}
                        </>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const tokenData: any = row.original;

                return (
                  <Fragment key={row.id}>
                    <TableRow {...row.getRowProps()} style={{ opacity: showTradeWidget && (selected[tokenData.name] ?? "") == "" ? "50%" : "100%" }}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </TableRow>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        ))}
      {stipLoading ? (
        <LoadingRows isLoading={true} rows={3} padding="154px 0 22px" style={{ marginTop: "12px" }} />
      ) : !tokens.length ? (
        <LoadingRows isLoading={true} rows={3} padding="154px 0 22px" style={{ marginTop: "12px" }} />
      ) : null}
      {showTradeWidget && (
        <StyledTradeScreen topAlign={activeIndex < Math.ceil(tokens.length / 2)}>
          <TradeWidget
            initInput={[selected[selectedToken] == "Unstake" ? tokenMap[selectedToken + "-STIP"] : tokenMap["ETH"]]}
            initOutput={[selected[selectedToken] == "Stake" ? tokenMap[selectedToken + "-STIP"] : tokenMap["ETH"]]}
            lockedTokens={new Set([selectedToken + "-STIP"])}
            refreshPage={true}
            disableSwap
          />
        </StyledTradeScreen>
      )}
    </TradeContainer>
  );
};

const TradeContainer = styled.div<{ showTradeWidget: boolean }>`

  ${({ showTradeWidget }) =>
    showTradeWidget &&
    css`
      position: relative;
      display: grid;
      grid-template-columns: calc(100% - 394px) 375px;
      gap: 16px;
      ${Media.tablet} {
        grid-template-columns: calc(100vw - 48px);
      }
    `};

  ${({ showTradeWidget }) =>
    !showTradeWidget &&
    css`
      width: 100%;
      max-width: 100%;
    `};
`;

const TalesWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
`;

const Table = styled.table<{ showTradeWidget: boolean }>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;


  th {
    padding-bottom: 2px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 0px;
    color: #7d7d97;

    & > svg {
      margin-left: 8px;
    }

    &:first-child {
      width: 4%;
      padding-left: 12px;
    }

    &:nth-child(2) {
      text-align: center;
      width: 8%;
    }

    &:nth-child(3) {
      padding-left: 28px;
      width: 5%;
    }

    &:nth-child(4) {
      width: 0%;
    }

    ${({ showTradeWidget }) =>
      showTradeWidget &&
      `
      display: none;
    &:nth-child(4) {
        width: 10%;
    }
    `}

    &:last-child {
      pointer-events: none;
      user-select: none;
      opacity: 0;
      width: 1%;
    }
  }

  td {
    border-top: 1px solid #1e2239;
    border-bottom: 1px solid #1e2239;
    background: #151530;
  }

  td:first-child {
    border-left: 1px solid #1e2239;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-right: 1px solid #1e2239;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

const TableRow = styled.tr`
  position: relative;
  z-index: 1;

  &.expanded:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  td {
    padding: 26px 0 22px;
    vertical-align: middle;
    text-align: center;
  }

  td:first-child {
    padding-left: 24px;
    text-align: left;
  }

  td:last-child {
    padding-right: 24px;
    text-align: right;
  }

  &.expanded td {
    border-bottom: none;
  }

  &.expanded td:first-child {
    border-bottom-left-radius: 0;
  }

  &.expanded td:last-child {
    border-bottom-right-radius: 0;
  }

  &.hoverable:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.hoverable:hover td:first-child {
    padding-left: 23px;
  }
  &.hoverable:hover td:last-child {
    padding-right: 23px;
  }

  &.expanded:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.expanded:hover td:first-child {
    padding-left: 23px;
  }
  &.expanded:hover td:last-child {
    padding-right: 23px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Balance = styled.p<{ pending: boolean }>`
  color: ${({ pending }) => (pending ? "#00BDFF" : "#FFF")};
  /* Text 2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TokenIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const Text = styled.p`
  /* Text 2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledTradeScreen = styled.div<{ topAlign: boolean }>`
  margin-top: 10px;
  margin-bottom: 12px;
  border: 1px solid #171b33;
  border-radius: 16px;
  padding: 8px;
  height: fit-content;
  ${TradeView} {
    padding: 0;
  }
  align-self: ${({ topAlign }) => (topAlign ? "start" : "end")};
`;

const APY = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #7adeb9;

  -webkit-animation-name: glow;
  -moz-animation-name: glow;
  animation-name: glow;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  @keyframes glow {
    0% {
      text-shadow: 0 0 6px #00bdff;
    }

    50% {
      text-shadow: 0 0 14px #00bdff;
    }

    100% {
      text-shadow: 0 0 6px #00bdff;
    }
  }
`;

import { useContext, useEffect, useMemo, useState } from "react";
import collabCoin from "@/assets/coins/collab-coin.svg";
import { reduceString } from "@/utils/reduceString";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { isShellToken } from "@/utils/tokens";
import { defaultChain } from "@/placeholders/chains";
import { alchemyId } from "@/providers/WagmiProvider";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import { useAccount, useSigner } from "wagmi";
import { SHELL_ADDRESS } from "@/constants/addresses";
import { formatEther } from "ethers/lib/utils.js";
import { Contract } from "ethers";
import { useAppSelector } from "@/store/hooks";
import { AddressZero } from "@ethersproject/constants";
import { POOL_API } from "@/constants/urls";
import { getTokenID } from "@/utils/LiquidityGraph";

export type StakePoolType = {
  name: string;
  subname: string;
  staked: number;
  reward: number;
  icon: string;
};

export const GaugeABI = [
  {
    stateMutability: "view",
    type: "function",
    name: "claimable_reward",
    inputs: [
      { name: "_user", type: "address" },
      { name: "_reward_token", type: "address" },
    ],
    outputs: [{ name: "", type: "uint256" }],
  },
  { stateMutability: "view", type: "function", name: "balanceOf", inputs: [{ name: "arg0", type: "address" }], outputs: [{ name: "", type: "uint256" }] },
  { stateMutability: "nonpayable", type: "function", name: "claim_rewards", inputs: [], outputs: [] },
  {
    stateMutability: "view",
    type: "function",
    name: "reward_data",
    inputs: [{ name: "arg0", type: "address" }],
    outputs: [
      {
        name: "",
        type: "tuple",
        components: [
          { name: "distributor", type: "address" },
          { name: "period_finish", type: "uint256" },
          { name: "rate", type: "uint256" },
          { name: "last_update", type: "uint256" },
          { name: "integral", type: "uint256" },
        ],
      },
    ],
  },
];

export const useSTIPHandler = () => {
  const { tokenMap, poolQuery, loadedPrices, stipTokens } = useContext(ChainContext);

  const rpcURL = `https://${defaultChain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`;

  const { address: walletAddress } = useAccount();
  const { data: signer } = useSigner();

  const multicall = new Multicall({ nodeUrl: rpcURL, tryAggregate: true, multicallCustomContractAddress: "0xcA11bde05977b3631167028862bE2a173976CA11" });
  const prices = useAppSelector((state) => state.prices.prices[defaultChain.name]);

  const [stipLoading, setStipLoading] = useState(true);
  const [activeStakePools, setActiveStakePools] = useState<StakePoolType[] | null>([]);
  const [pastStakePools, setPastStakePools] = useState<StakePoolType[] | null>([]);

  const [isGaugeClaimModalOpen, setIsGaugeClaimModalOpen] = useState(false);
  const [gaugeClaimTxSubmitted, setGaugeClaimTxSubmitted] = useState(false);
  const [isGaugeClaimSuccess, setIsGaugeClaimSuccess] = useState(false);
  const [gaugeClaimAmount, setGaugeClaimAmount] = useState<number>(0);

  const stipRewardToken = tokenMap["ARB"]; //TODO: change to ARB

  const claimFromGauge = (gaugeAddress: string, amount: number) => {
    const gauge = new Contract(gaugeAddress, GaugeABI, signer!);
    setGaugeClaimAmount(amount);
    setIsGaugeClaimModalOpen(true);
    gauge
      .claim_rewards()
      .then((response: any) => {
        setGaugeClaimTxSubmitted(true);
        response.wait().then((result: any) => {
          if (result.status == 1) {
            setIsGaugeClaimSuccess(true);
            setGaugeClaimTxSubmitted(false);
          } else {
            setIsGaugeClaimSuccess(false);
          }
        });
      })
      .catch(() => {
        setIsGaugeClaimModalOpen(false);
      });
  };

  const closeGaugeClaimModal = () => {
    setIsGaugeClaimModalOpen(false);
    if (isGaugeClaimSuccess) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      window.location.reload();
    }
  };

  const buildPools = async (pools: any[]) => {
    if (!pools.length) return [];

    const contractCallContext: ContractCallContext[] = pools.map((pool) => {
      return {
        reference: pool.symbol,
        contractAddress: pool.address,
        abi: GaugeABI as any,
        calls: [
          {
            reference: "Balance",
            methodName: "balanceOf",
            methodParameters: [walletAddress ?? AddressZero],
          },
          {
            reference: "Claimable",
            methodName: "claimable_reward",
            methodParameters: [walletAddress ?? AddressZero, stipRewardToken.address],
          },
        ],
      };
    });

    const poolResults = await multicall.call(contractCallContext);

    return pools.map((pool, index) => {
      return {
        name: tokenMap[pool.symbol].tokens[0],
        subname: reduceString(isShellToken(pool) ? pool.oceanID : pool.address, 6, 4),
        gauge: pool.address,
        staked: parseFloat(formatEther(poolResults.results[pool.symbol].callsReturnContext[0].returnValues[0])),
        reward: parseFloat(formatEther(poolResults.results[pool.symbol].callsReturnContext[1].returnValues[0])),
        icon: pool.icon,
      };
    });
  };

  const getPoolDetails = async (pools: any[], isActive: boolean) => {
    const pricePromises = pools.map((pool) => poolQuery.getUSDPrice(pool, prices));
    pricePromises.push(poolQuery.getUSDPrice(stipRewardToken, prices));

    const apyPromises = pools.map((pool) =>
      fetch(POOL_API(defaultChain) + "v3/pools/" + getTokenID(pool).replace(/\//g, "-"))
        .then((response) => response.json())
        .catch(() => {})
    );

    const [priceResults, metricResults] = await Promise.all([Promise.all(pricePromises), Promise.all(apyPromises)]);

    return (isActive ? activeStakePools : pastStakePools)!.map((pool, index) => {
      return {
        ...pool,
        stakedPrice: priceResults[index],
        rewardPrice: priceResults.at(-1)!,
        apy: (metricResults[index]?.rewardAPY - 1) ?? 0,
      };
    });
  };

  useEffect(() => {
    setStipLoading(true);

    const stipPools = stipTokens.filter((token) => !token.wrapped && token.tokenType == "STIP");

    Promise.all([buildPools(stipPools.filter((pool) => pool.metadata == "active")), buildPools(stipPools.filter((pool) => pool.metadata != "active"))]).then(
      ([activeResult, pastResult]) => {
        setActiveStakePools(activeResult);
        setPastStakePools(pastResult);
        setStipLoading(false);
      }
    );
  }, [walletAddress, stipTokens.length]);

  useEffect(() => {
    if (loadedPrices) {
      const stipPools = Object.values(tokenMap).filter((token) => !token.wrapped && token.tokenType == "STIP");

      if (activeStakePools?.length) {
        getPoolDetails(
          stipPools.filter((pool) => pool.metadata == "active"),
          true
        ).then((result) => {
          setActiveStakePools(result);
        });
      }

      if (pastStakePools?.length) {
        getPoolDetails(
          stipPools.filter((pool) => pool.metadata != "active"),
          false
        ).then((result) => {
          setPastStakePools(result);
        });
      }
    }
  }, [activeStakePools?.length, pastStakePools?.length, loadedPrices]);

  return {
    activeStakePools,
    pastStakePools,
    stipLoading,
    isGaugeClaimModalOpen,
    gaugeClaimTxSubmitted,
    isGaugeClaimSuccess,
    gaugeClaimAmount,
    claimFromGauge,
    closeGaugeClaimModal,
    stipRewardToken,
  };
};

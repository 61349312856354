import React, { useMemo } from "react";
interface PaginationOptions {
  pageSize?: number;
  pageNumber?: number;
}

export function usePaginate<T>(objects: T[], opts?: PaginationOptions) {
  const { pageSize = 10, pageNumber = 1 } = opts || {};
  const [currentPage, setCurrentPage] = React.useState(pageNumber);
  const startIdx = useMemo(
    () => (currentPage - 1) * pageSize,
    [currentPage, pageSize]
  );
  const endIdx = useMemo(() => startIdx + pageSize, [startIdx, pageSize]);
  const pageObjects = useMemo(() => {
    return objects.slice(startIdx, endIdx);
  }, [objects, startIdx, endIdx]);

  const handleSetCurrentPage = (p: number) => {
    setCurrentPage(p);
  };

  const handleSetNextPage = () => {
    if (isLastPage) return;
    setCurrentPage((p) => p + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleSetPrevPage = () => {
    if (isFirstPage) return;
    setCurrentPage((p) => p - 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const pagesLength = useMemo(
    () => Math.ceil(objects.length / pageSize),
    [objects.length, pageSize]
  );

  const isFirstPage = useMemo(() => currentPage === 1, [currentPage]);

  const isLastPage = useMemo(
    () => pagesLength === currentPage,
    [currentPage, pageSize]
  );

  return {
    pageObjects,
    currentPage: useMemo(() => currentPage, [currentPage]),
    pagesLength,
    startIdx,
    endIdx,
    isFirstPage,
    isLastPage,
    pageSize,
    objectsLength: objects.length,
    handleSetCurrentPage,
    handleSetNextPage,
    handleSetPrevPage,
  };
}

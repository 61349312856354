import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ButtonSecondary } from "../../../components/Buttons/Button";
import { CopyButton } from "../../../components/Buttons/CopyButton";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { formatDisplay, formatDisplayShorthand } from "../../../utils/formatDisplay";
import { reduceString } from "../../../utils/reduceString";
import { Composition } from "../../../components/Composition/Composition";
import { Media } from "../../../styles";
import { Link, useLocation } from "react-router-dom";
import { StatusBullet } from "@/pages/LBP/StatusBullet";
import { addMetadata } from "@/store/lbpsSlice";
import { extract1155Data } from "@/utils/nftHelpers";
import { CompositionSkeleton, StatsSkeleton } from "@/pages/Rewards/components/Points/components/PoolSubRow";
import { Contract, providers } from "ethers/lib/ethers";
import { AaveDataProviderABI } from "@/constants/ABI/AaveDataProvider";
import { erc20ABI, useProvider } from "wagmi";
import { formatUnits, parseEther, parseUnits } from "ethers/lib/utils.js";
import { DexBadge } from "@/components/DexBadge/DexBadge";
import { alchemyId, base } from "@/providers/WagmiProvider";
import { Chain } from "@/placeholders/chains";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { USDValue } from "../InputPanel";
import { STIPFooter, rewardTokenID } from "./STIPFooter";
import { POOL_API } from "@/constants/urls";
import { isExternalToken, isShellV2Token } from "@/utils/tokens";

interface LBPTokenInfoProps {
  selectedToken: any;
  isShrunk: boolean;
  chain: Chain;
}

export const lbpTime = (startTime: number, endTime: number) => {
  const elapsedSeconds = endTime - startTime;
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const elapsedHours = Math.floor(elapsedMinutes / 60);
  const elapsedDays = Math.floor(elapsedHours / 24);

  const formattedElapsedDays = elapsedDays.toString() + "d"; //formatTimeUnits(elapsedDays, 'day');
  const formattedElapsedHours = (elapsedHours % 24).toString() + "h"; //formatTimeUnits(elapsedHours % 24, 'hour');
  const formattedElapsedMinutes = (elapsedMinutes % 60).toString() + "m"; //formatTimeUnits(elapsedMinutes % 60, 'min');
  const formattedElapsedSeconds = (elapsedSeconds % 60).toString() + "sec"; //formatTimeUnits(elapsedSeconds % 60, 'sec');

  if (elapsedMinutes == 0 && elapsedHours == 0 && elapsedDays == 0) {
    return formattedElapsedSeconds;
  } else if (elapsedHours == 0 && elapsedDays == 0) {
    return formattedElapsedMinutes;
  } else if (elapsedDays == 0) {
    return `${formattedElapsedHours} ${elapsedMinutes % 60 > 0 ? formattedElapsedMinutes : formattedElapsedSeconds}`;
  } else {
    return `${formattedElapsedDays} ${elapsedHours % 24 > 0 ? formattedElapsedHours : formattedElapsedMinutes}`;
  }
};

export const LendingTokenInfo = ({ selectedToken, isShrunk, chain }: LBPTokenInfoProps) => {
  const metadata = useAppSelector((state) => state.lbps.metadata);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { tokenMap } = require(`../../../placeholders/${chain.name.split(' ')[0].toLowerCase()}Tokens.ts`)
  const { poolQuery, poolQueryTo, connectedChain } = useContext(ChainContext);
  const query = chain.name == connectedChain.name ? poolQuery : poolQueryTo!

  const prices = useAppSelector((state) => state.prices.prices[chain.name]);

  const [loading, setLoading] = useState(true);
  const [lendingData, setLendingData] = useState<any>({});

  const [stipLoading, setStipLoading] = useState(true);
  const [stipData, setStipData] = useState<any>({});

  const rpcURL = `https://${chain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`;
  const provider = new providers.JsonRpcProvider(rpcURL);

  const displayToken = selectedToken.tokenType == "STIP" ? tokenMap[selectedToken.tokens[0]] : selectedToken;

  const stipRewardToken = tokenMap[rewardTokenID];

  const underlying = tokenMap[displayToken.tokens[0]];

  const dataProviders: { [id: string]: string } = {
    "Arbitrum One": "0x69FA688f1Dc47d4B5d8029D5a35FB7a548310654",
    Base: "0x2d8A3C5677189723C4cB8873CfC9C8976FDF38Ac",
    Optimism: "0x69FA688f1Dc47d4B5d8029D5a35FB7a548310654",
    Ethereum: "0x7B4EB56E7CD4b454BA8ff71E4518426369a138a3",
  };

  const fetchLendingInfo = async () => {
    if (underlying == undefined) return {};

    const tokenContract = new Contract(underlying.address, erc20ABI, provider);
    const aaveDataProvider = new Contract(dataProviders[chain.name], AaveDataProviderABI, provider);

    const baseTokenPrice = await query.getUSDPrice(displayToken, { ...prices });

    const [data, decimals] = await Promise.all([aaveDataProvider.getReserveData(underlying.address), tokenContract.decimals()]);

    const utilization = Math.floor((data.totalVariableDebt / data.totalAToken) * 100);

    const totalSupply = parseFloat(formatUnits(data.totalAToken, decimals));
    const totalBorrowed = parseFloat(formatUnits(data.totalVariableDebt, decimals));

    return {
      supplyAPY: formatUnits(data.liquidityRate, 25),
      totalSupply: { amount: totalSupply, value: totalSupply * baseTokenPrice },
      totalBorrowed: { amount: totalBorrowed, value: totalBorrowed * baseTokenPrice },
      breakdown: {
        Borrowed: utilization,
        Available: 100 - utilization,
      },
    };
  };

  const fetchStipData = async (pool: any) => {
    const rewardData = await fetch(POOL_API(chain) + "v3/pools/" + pool.symbol.replace(/\//g, "-"))
      .then((response) => response.json())
      .catch(() => {
        return { totalSupply: 0, totalReward: 0, rewardAPY: 0 };
      });

    const baseTokenPrice = await query.getUSDPrice(displayToken, { ...prices });
    const rewardTokenPrice = await query.getUSDPrice(stipRewardToken, { ...prices });

    const totalStaked = rewardData.totalSupply;
    const dailyReward = rewardData.totalReward;

    return {
      totalStaked: { amount: totalStaked, value: totalStaked * baseTokenPrice },
      rewardPerDay: { amount: dailyReward, value: dailyReward * rewardTokenPrice },
      rewardAPY: (rewardData.rewardAPY - 1) * 100,
    };
  };

  useEffect(() => {
    // Fetch balances from contract if not already in Redux state and update store
    setLoading(true);

    fetchLendingInfo().then((data: any) => {
      if (data) {
        setLendingData(data);
        setLoading(false);
      }
    });

    if (selectedToken.tokenType == "STIP") {
      setStipLoading(true);
      fetchStipData(selectedToken).then((data: any) => {
        if (data) {
          setStipData(data);
          setStipLoading(false);
        }
      });
    }
  }, [selectedToken, chain]);

  if (underlying == undefined) return <></>;

  if (location.pathname.includes("/rewards/stip") && selectedToken.tokenType == "STIP" && selectedToken.metadata == "active")
    return <STIPFooter token={displayToken} rewardToken={stipRewardToken} data={stipData} loading={stipLoading} isShrunk={isShrunk} />;

  return (
    <>
      <Wrapper className={isShrunk ? "shrunk" : ""}>
        <TokenDiv>
          <Image className={isShrunk ? "shrunk" : ""} src={selectedToken.icon} alt={`${selectedToken.name} logo`} />
          <TokenSummary className={isShrunk ? "shrunk" : ""}>
            <TokenName>{selectedToken.symbol}</TokenName>
            <TokenAddress className={isShrunk ? "shrunk" : ""}>
              {reduceString(selectedToken.address, 6, 4)} <CopyButton text={selectedToken.address} className="copyShellToken" />
            </TokenAddress>
          </TokenSummary>
        </TokenDiv>
        <Stats className={isShrunk ? "shrunk" : ""}>
          <Portions className={isShrunk ? "shrunk" : ""}>
            {loading ? (
              <CompositionSkeleton length={2} />
            ) : (
              Object.keys(lendingData).length > 0 && (
                <Composition
                  list={Object.keys(lendingData.breakdown).map((childToken) => {
                    return {
                      symbol: childToken,
                      percentage: `${lendingData.breakdown[childToken]}%`,
                      metadata: childToken == "Borrowed" ? displayToken.symbol : undefined,
                    };
                  })}
                />
              )
            )}
          </Portions>
          <Info className={isShrunk ? "shrunk" : ""}>
            <StatsRow className={isShrunk ? "shrunk" : ""}>
              {loading ? (
                <>
                  <StatsSkeleton length={4} boxWidth={isShrunk ? 100 : 122} noMargin={true} />
                </>
              ) : (
                <>
                  <InfoItem className={isShrunk ? "shrunk" : ""} style={{ display: "flex" }}>
                    <Label>Lending Pool:</Label> <DexBadge protocol={displayToken.tokenType} protocolVersion={displayToken.protocolVersion ?? ""} />
                  </InfoItem>
                  <InfoItem className={isShrunk ? "shrunk" : ""}>
                    <Label>Total Supplied:</Label> <Value>{formatDisplayShorthand(lendingData.totalSupply.amount)}</Value> <img src={underlying.icon} />{" "}
                    <USDValue className={isShrunk ? "shrunk" : ""}>{`($${formatDisplayShorthand(lendingData.totalSupply.value, 2)})`}</USDValue>
                  </InfoItem>
                  <InfoItem className={isShrunk ? "shrunk" : ""}>
                    <Label>Supply APY:</Label> <Value color={"#7ADEB9"}>{`${formatDisplay(lendingData.supplyAPY, 2)}%`}</Value>
                  </InfoItem>
                  <InfoItem className={isShrunk ? "shrunk" : ""}>
                    <Label>Total Borrowed:</Label> <Value>{formatDisplayShorthand(lendingData.totalBorrowed.amount)}</Value> <img src={underlying.icon} />{" "}
                    <USDValue className={isShrunk ? "shrunk" : ""}>{`($${formatDisplayShorthand(lendingData.totalBorrowed.value, 2)})`}</USDValue>
                  </InfoItem>
                </>
              )}
            </StatsRow>
            {/* <StyledLink to={{ pathname: `/` }} disabled={true}>
                        <ButtonS className={isShrunk ? 'shrunk' : ''}>
                           {isShrunk ? 'Details' : 'View Details'}
                            <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <g clipPath="url(#clip0_3983_174)">
                                <path
                                d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
                                fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_3983_174">
                                <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                            </svg>
                        </ButtonS>
                    </StyledLink>  */}
          </Info>
        </Stats>
      </Wrapper>
      {selectedToken.tokenType == "STIP" && selectedToken.metadata == "active" && (
        <STIPFooter token={displayToken} rewardToken={stipRewardToken} data={stipData} loading={stipLoading} isShrunk={isShrunk} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 30px;

  width: 100%;
  padding: 24px 28px 24px 40px;
  padding-top: 24px;
  border-top: 1px solid #1e2239;
  background-image: linear-gradient(180deg, #0f0f29, transparent);

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      flex-direction: column;
      justify-content: unset;
      row-gap: 20px;
      padding: 18px 12px;
    `};
`;

const TokenDiv = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 300px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      width: 50px;
      height: 50px;
    `};
`;

const TokenSummary = styled.div`
  margin-left: 20px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      margin-left: 15px;
    `};
`;

const TokenName = styled.span`
  font-size: 20px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: -0.03em;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TokenAddress = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #a9d1e2;

  .copyShellToken {
    color: #a9d1e2;
    margin-left: 4px;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      font-size: 14px;
      line-height: 16px;
    `};
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 496px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      width: 100%;
      max-width: 100%;
      align-items: unset;
    `};
`;

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  align-items: center;
  row-gap: 6px;
  column-gap: 12px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      column-gap: 24px;
    `};
`;

const LoadWrapper = styled.div`
  margin: auto;

  > img {
    width: 48px;
    margin-left: 10px;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      margin-bottom: 16px;
      > img {
        width: 36px;
        margin-left: 0px;
      }
    `};
`;

const Portions = styled.div`
  display: flex;
  width: 100%;
  max-width: 428px;
  margin: 12px 0;
  overflow: hidden;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      max-width: 100%;
      margin: 0 0 18px;
    `};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2px;
  justify-content: space-between;
  gap: 12px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      gap: 0;
    `};
`;

const InfoItem = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  gap: 4px;

  > img {
    width: 20px;
    height: 20px;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      & + & {
        margin-left: unset;
      }
      ${Media.mobile} {
        font-size: 12px;
      }
    `};
`;

const Label = styled.span`
  color: #7d7d97;
`;

const Value = styled.span<{ value?: number; color?: string }>`
  font-weight: 500;
  color: ${({ color }) => color ?? "#FFFFFF"};
`;

const StyledLink = styled(Link)<{ disabled: boolean }>`
  width: fit-content;
  margin-left: auto;

  ${({ disabled }) =>
    disabled &&
    `

  & ${ButtonS} {
  color: var(--grey-4, #464659);
  border-color: #1E2239;
  background: #151530;
  }
  pointer-events: none;
  `}
`;

const ButtonS = styled(ButtonSecondary)`
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      width: 100%;
      grid-column: 1 / 2 span;
      padding-inline: 12px;
    `};
`;

import React, { useContext } from "react";
import styled from "styled-components";
import { Media } from "@/styles/breakpoints";
import { reduceString } from "@/utils/reduceString";
import { defaultChain } from "@/placeholders/chains";
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const TransactionHashCell = ({ value }: any) => {

  const { connectedChain } = useContext(ChainContext)

  const transactionHash = value;
  const transactionUrl = connectedChain.explorerUrl + "tx/" + transactionHash;
  return (
    <TransactionHash href={transactionUrl} target="_blank">
      <Hash>{reduceString(transactionHash, 6, 4)}</Hash>
      <ArbiscanLogo>
        <Image src={connectedChain.icon!} />
      </ArbiscanLogo>
    </TransactionHash>
  );
};

const TransactionHash = styled.a`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;

  &:hover {
    color: #00bdff;
    img {
      filter: grayscale(0%);
    }
  }

  img {
    filter: grayscale(100%);
  }

  ${Media.mobile}{
    font-size: 13px;
    img {
      filter: grayscale(0%);
    }
  }
`;

const Hash = styled.div`
  width: 110px;
  line-height: 22px;
`;

const ArbiscanLogo = styled.div`
//   margin-left: 6px;
  column-gap: 8px;
`;

const Image = styled.img`
  width: 24px;
  height: 22px;
`;

import React from "react";
import styled, { css } from "styled-components";
import { Media } from "../../styles";
import { BoxButton, CopyBoxButton } from "../../components/Buttons/Button";
import { CopyIcon } from "../../components/Icons/CopyIcon";
import { DocumentationIcon } from "../../components/Icons/DocumentationIcon";
import { TwitterIcon } from "../../components/Icons/TwitterIcon";
import { WebsiteIcon } from "../../components/Icons/WebsiteIcon";
import { DiscordIcon } from "../../components/Icons/DiscordIcon";
import arbitrumLogo from "../../assets/icons/arbitrum-logo.svg";
import { extract1155Data } from "@/utils/nftHelpers";
import { defaultChain } from "@/placeholders/chains";

export const About = ({token, lbpData}: any) => {

  const data1155 = extract1155Data(token.symbol)
  const displayName = data1155 ? data1155.item.name : token.name
  const displaySymbol = data1155 ? data1155.symbol : token.symbol

  return (
    <AboutContainer>
      <Box>
        <Title>About {displayName}</Title>
        <AboutDescription>
          {lbpData.description}
        </AboutDescription>
        <ButtonsContainer>
          <a href={lbpData.socialLinks.homepage} target="_blank">
            <BoxSocialButtonStyled>
              <WebsiteIcon />
            </BoxSocialButtonStyled>
          </a>
          <a href={lbpData.socialLinks.twitter} target="_blank">
            <BoxSocialButtonStyled>
              <TwitterIcon />
            </BoxSocialButtonStyled>
          </a>
          <a href={lbpData.socialLinks.discord} target="_blank">
            <BoxSocialButtonStyled>
              <DiscordIcon />
            </BoxSocialButtonStyled>
          </a>
        </ButtonsContainer>
        <Divider />
        <Title>Token Contract Address</Title>
        <CopyBoxButtonStyled
          icon={arbitrumLogo}
          text={token.address}
          cIcon={<CopyIcon />}
        >
          {token.address}
        </CopyBoxButtonStyled>
        <Divider />
        <Title>Links</Title>
        <ButtonsContainer style={{marginTop: '0'}} mobileColumn={true}>
          <a href={`${defaultChain.explorerUrl}address/${token.address}`} target="_blank">
            <BoxButtonStyled>
              <Icon src={arbitrumLogo} />
              {displaySymbol} Token
            </BoxButtonStyled>
          </a>
          <a href={`${defaultChain.explorerUrl}address/${token.poolAddress}`} target="_blank">
            <BoxButtonStyled>
              <Icon src={arbitrumLogo} />
              LBP Address
            </BoxButtonStyled>
          </a>
          {/* <BoxButtonStyled>
            <DocumentationIcon />
            LBP Documentation
          </BoxButtonStyled> */}
        </ButtonsContainer>
      </Box>
    </AboutContainer>
  );
};

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;

  ${Media.tablet} {
    padding: 16px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #171b33;
  margin-block: 24px;
`;

const FlexContainer = styled.div<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || "unset"};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

const Container = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
`;

const Amount = styled.div`
  margin-left: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7d7d97;
`;

const PercentageContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

const Title = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const AboutDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.45em;
  color: #7d7d97;
`;

const ButtonsContainer = styled.div<{ mobileColumn?: boolean; wrap?: boolean }>`
  display: flex;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  gap: 8px;
  margin-top: 16px;

  ${({ mobileColumn }) =>
    mobileColumn &&
    css`
      ${Media.tablet} {
        flex-direction: column;
      }
    `}
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const BoxButtonStyled = styled(BoxButton)`
  flex-grow: 1;
`;

const BoxSocialButtonStyled = styled(BoxButton)`
  width: fit-content;
  padding: 10px;
  // flex-grow: 1;
`;

const CopyBoxButtonStyled = styled(CopyBoxButton)`
  padding-inline: 16px;
`;

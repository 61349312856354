import React, { useContext } from "react";
import * as S from "./STIP.styled";
import shellIcon from "@/assets/coins/SHELL.svg";
import crabIcon from "@/assets/seasons/CRAB.svg";
import leftArrow from "@/assets/icons/arrow-left.svg";
import { useRewardsContext } from "../../RewardsContext";
import { CustomModal } from "@/components/CustomModal/CustomModal";
import { ClaimAirDropModalBody } from "../modals/ClaimAirDropModalBody";
import { ClaimAirDropConfirmation } from "../modals/ClaimAirDropConfirmation";
import { ClaimCrab } from "./components/ClaimCrab";
import { STIPTable } from "./components/STIPTable";
import GeoRestrictedOverlay from "@/components/Overlays/GeoRestrictedOverlay";
import { formatDisplay } from "@/utils/formatDisplay";
import { useAccount } from "wagmi";
import { useWidthBreakpoint } from "@/hooks";
import { Media, breakpoints } from "@/styles";
import { ClaimOther } from "./components/ClaimOther";
import { GeoContext } from "@/components/Overlays/GeoProvider";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { LoadingRows } from "@/components/Table/GenericTable";
import styled from "styled-components";
import { GaugeClaimConfirmationModalBody } from "../modals/GaugeClaimConfirmationModalBody";

export const STIP = () => {
  const { activeStakePools, pastStakePools, isGaugeClaimModalOpen, closeGaugeClaimModal } = useRewardsContext();
  const { isConnected } = useAccount();

  const isMobile = useWidthBreakpoint(breakpoints.mobile);

  return (
    <>
      <S.STIPContainer isConnected={true}>
        {/* <S.OtherSTIPsContainer>
          <S.Title>Active Pools</S.Title>
          <STIPTable tokens={activeStakePools} isActive />
        </S.OtherSTIPsContainer> */}
        <S.OtherSTIPsContainer>
          <S.Title>Past Pools</S.Title>
          <STIPTable tokens={pastStakePools} isActive={false} />
        </S.OtherSTIPsContainer>
      </S.STIPContainer>
      <CustomModal
        isOpen={isGaugeClaimModalOpen}
        onClose={() => closeGaugeClaimModal()}
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        withOutsideClick
        withBackdropBlur
      >
        <GaugeClaimConfirmationModalBody />
      </CustomModal>
    </>
  );
};

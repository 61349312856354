import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { NFT } from "../../../utils/tokens";
import "swiper/css";
import { NFTsSwiperSelect } from "../NFTsSwiper/NFTsSwiperSelect";
import { useAppSelector } from "../../../store/hooks";
import { formatDisplay } from "../../../utils/formatDisplay";
import { StraightLoader } from "../../../components/Loaders";
import { build1155Displays } from "../../../utils/nftHelpers";
import { ToggleSkinny } from "../../../components/ToggleSwitch/ToggleSkinny";
import LockIcon from '../../../assets/booty/lock.svg'

interface INFTsTokensInfoProps {
  allSelectedNFTs: {[collection : string] : NFT[]};
  nftTokens: any[];
  updateSelectedNFTs?: any;
  selectedCollection: any;
  isInputToken: boolean;
  priceImpact: number;
  nftSweepInputPrice?: null | string;
  onNFTSweepSelect?: (collection: string, items: NFT[]) => void;
  propLock: string
  onPropLockChange: any
  usdValue1155?: number;
  isShrunk: boolean;
  prices: Record<string, any>
}

export const NFTsTokensInfo = ({
  allSelectedNFTs,
  nftTokens,
  updateSelectedNFTs,
  selectedCollection,
  isInputToken,
  priceImpact,
  nftSweepInputPrice,
  onNFTSweepSelect,
  propLock,
  onPropLockChange,
  usdValue1155,
  isShrunk,
  prices
}: INFTsTokensInfoProps) => {
  const [usdAmount, setUSDAmount] = useState(0);
  const [selected1155, setSelected1155] = useState<any>()

  useEffect(() => {
    if(usdValue1155 !== undefined)
        setUSDAmount(usdValue1155)
    else if(nftTokens && nftTokens.length)
        setUSDAmount(nftTokens.length * (prices[nftTokens[0].symbol] ?? 0));
    else
        setUSDAmount(0)
  }, [nftTokens, prices, usdValue1155]);

  useEffect(() => {
    if(selectedCollection.is1155 && nftTokens?.length == 1){
        setSelected1155(build1155Displays(selectedCollection, nftTokens)[0])
    } else {
        setSelected1155(null)
    }
  }, [selectedCollection, nftTokens])

  return (
    <Wrapper className={isShrunk ? 'shrunk' : ''}>
      {
        selected1155 ? 
        <TokenDiv style={{flexDirection: 'row'}} className={isShrunk ? 'shrunk' : ''}>
            <Image className={isShrunk ? 'shrunk' : ''} src={selected1155.image} alt={`${selected1155.name} logo`} />
            <TokenSummary className={isShrunk ? 'shrunk' : ''}>
                <TokenName className={isShrunk ? 'shrunk' : ''}>{selected1155.name}</TokenName>
                <TokenDesc className={isShrunk ? 'shrunk' : ''}>{selected1155.desc}</TokenDesc>
            </TokenSummary>
        </TokenDiv>
        :
        <TokenDiv>
            <Title className={isShrunk ? 'shrunk' : ''}>NFTs to {isInputToken ? ' Send' : ' Receive'}</Title>
            <NFTsSwiperSelect 
                allSelectedNFTs={allSelectedNFTs}
                nftSweepInputPrice={nftSweepInputPrice}
                onNFTSweepSelect={onNFTSweepSelect} nftTokens={nftTokens} 
                updateSelectedNFTs={updateSelectedNFTs} 
                selectedCollection={selectedCollection} 
                isInputToken={isInputToken}
            />  
        </TokenDiv>
      }
      <Stats className={isShrunk ? 'shrunk' : ''}>
        <TransactionTitle>Total NFT Value</TransactionTitle>
        {isNaN(usdAmount) ? 
        <StraightLoader/> :
        <AmountContainer>
            <TransactionAmount>
                {formatDisplay((usdAmount / prices['ETH']).toString(), 3)} ETH
            </TransactionAmount>
            <div style={{display: 'flex', justifyContent: 'end', columnGap: '4px'}}>
                <TransactionAmount style={{fontWeight: '1', fontSize: '14px', color: '#7d7d97'}}>
                    ${formatDisplay(usdAmount.toString(), 2)}
                </TransactionAmount>
                {usdAmount > 0 && priceImpact !== Infinity && Math.abs(priceImpact) >= 0.0001 && (
                    <PriceImpact value={priceImpact}>
                    {`(${priceImpact > 0 ? "+" : ""}${formatDisplay(
                        priceImpact.toString(),
                        4
                    )}%)`}
                    </PriceImpact>
                )}          
            </div>
        </AmountContainer>
        }
        {propLock &&
            <PropLock className={isShrunk ? 'shrunk' : ''} is1155={selected1155}>
                <TransactionTitle style={{lineHeight: '20px'}}>Prop. {isInputToken ? 'Deposit' : 'Withdraw'}</TransactionTitle>
                <img style={{height: '20px', margin: '0 4px 8px 6px'}} src={LockIcon}/>
                <ToggleSkinny 
                    checked={propLock == 'locked'}
                    onChange={onPropLockChange}
                ></ToggleSkinny>
            </PropLock>
        }
      </Stats>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  align-items: center;

  width: 100%;
  padding: 24px 28px 24px 40px;
  padding-top: 24px;
  border-top: 1px solid #1e2239;
  background-image: linear-gradient(180deg, #0f0f29, transparent);

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;
      row-gap: 20px;
      padding: 20px 12px;
    `};
`;

const TokenDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 0;
  
  ${props =>
    props.className?.includes('shrunk') &&
    css`
      gap: 12px;
    `};
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 12px;
      line-height: 15px;
    `};
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 200px;
  gap: 6px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      width: 100%;
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: unset;
    `};
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TransactionTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;

const TransactionAmount = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #00BDFF;
  text-align: right;
`;

const PriceImpact = styled(TransactionAmount)<{ value: number }>`
  font-weight: 1;
  font-size: 12px;

  ${({ value }) =>
    value > 0.001 &&
    css`
      color: #7adeb9;
    `};
  ${({ value }) =>
    value <= -5 &&
    css`
      color: #ff5349;
    `};
  ${({ value }) =>
    value > -5 &&
    value <= -1 &&
    css`
      color: #daa520;
    `};
`;

const Image = styled.img`
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 8px;
  object-fit: cover;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      width: 50px;
      height: 50px;
    `};
`

const TokenSummary = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      margin-left: 0;
    `};
`

const TokenName = styled.span`
  font-size: 20px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: -0.03em;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 16px;
    `};
`

const TokenDesc = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 200;
  letter-spacing: -0.03em;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #7d7d97;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 14px;
    `};
`

const PropLock = styled.div<{is1155: boolean}>`
  display: flex;
  position: absolute;
  margin-top: 80px;
  align-items: center;

  ${({ is1155 }) => is1155 && css`
    margin-top: 66px;
  `};

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      margin-top: 30px;
    `};
`
import React from "react";
import styled from "styled-components";
import { Media } from "../../../styles";

interface IDescriptionBoxProps {
  title: string;
  description: string;
}

export const DescriptionBox = ({
  title,
  description,
}: IDescriptionBoxProps) => {
  return (
    <DescriptionBoxContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </DescriptionBoxContainer>
  );
};

const DescriptionBoxContainer = styled.div`
  // height: -webkit-fill-available;
  height: auto;
  width: calc(50% - 8px);
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #151530;
  border: 1px solid #1e2239;
  border-radius: 16px;
  flex-grow: 1;
  align-items: stretch;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #7d7d97;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
`;

import React from "react";
import styled from "styled-components";
import { Media } from "@/styles/breakpoints";
import { timestampToDate } from "@/utils/formatDisplay";

export const TimestampCell = ({ value }: any) => (
  <FlexContainer>
    <StyledTimestampCell>{timestampToDate(value)}</StyledTimestampCell>
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTimestampCell = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  padding-left: 38px;
  ${Media.tablet}{
    font-size: 13px;
    padding-left: 0;
  }
`;

import React, { useRef, useState } from "react";
import styled from "styled-components";
import chevronDown from "@/assets/icons/chevron-down.svg";
import blueCheck from "@/assets/icons/blue-check.svg";
import { useClickOutside } from "@/hooks/useClickOutside";

type DropdownProps = {
  allOptions: string[];
  selectedOptions: string[];
  onOptionChange: (allOptions: string) => void;
};

export const CustomDropdown: React.FC<DropdownProps> = ({ allOptions, selectedOptions, onOptionChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleOptionChange = (option: string) => {
    onOptionChange(option);
    setIsOpen(false);
  };
  useClickOutside(ref, () => setIsOpen(false));

  let displayLabel = "All";
  if (selectedOptions.length === 1 && selectedOptions[0] === 'all') {
    displayLabel = "All";
  } else if (selectedOptions.length === 1) {
    displayLabel = selectedOptions[0];
  } else if (selectedOptions.length > 1 && selectedOptions.length < allOptions.length) {
    displayLabel = "Multiple";
  }
  
  return (
    <DropdownStyled ref={ref}>
      <SelectInput onClick={() => setIsOpen(!isOpen)}>
        {displayLabel}
        <ChevronImg alt="chevron" src={chevronDown} isOpen={isOpen} />
      </SelectInput>
      {isOpen && (
        <OptionList>
          <Option selected={selectedOptions.includes("all")} onClick={() => handleOptionChange("all")}> All </Option>
          {allOptions.map((option) => (
            <Option
              key={option}
              onClick={() => handleOptionChange(option)}
              selected={selectedOptions.includes(option)}
            >
              {option}
            </Option>
          ))}
        </OptionList>
      )}
    </DropdownStyled>
  );
};

const DropdownStyled = styled.div`
min-width: 116px;
  position: relative;
  cursor: pointer;
  `;

const SelectInput = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
background: var(--dark-2, #171B33);
outline: none;
border: none;
white-space: nowrap;
color: #FFF;
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */;
  `;

  const ChevronImg = styled.img<{isOpen: boolean}>`
  transform: ${({isOpen}) => isOpen ? "rotate(180deg)" : "none"};
  transition: all 0.15s ease-in-out;
  `;

const OptionList = styled.ul`
position: absolute;
top: 44px;
left: 0;
z-index: 1;

border-radius: 8px;
background: var(--dark-2, #171B33);
box-shadow: 0px 8px 24px 0px rgba(11, 13, 34, 0.60);
padding: 8px 6px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
display: flex;
width: 100%;
`;

const Option = styled.li<{selected?: boolean}>`
padding: 8px 6px;
width: 100%;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: space-between;

&:hover {
  background: #1E2239;
  cursor: pointer;
}

&::after {
  content: ${({selected}) => selected ? `url(${blueCheck})` : ""};
}
`;

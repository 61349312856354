import { 
    ETH_ADDRESS, 
    SHELL_ADDRESS,
    TOUCAN_ADDRESS,
    SMOL_ADDRESS,
    STREAM_ADDRESS,
} from "../constants/addresses";

import ethIcon from "../assets/coins/ETH.svg";
import shellIcon from "../assets/coins/SHELL.svg"
import daiUsdcIcon from "../assets/coins/dai-usdc.svg";
import usdtUsdcIcon from "../assets/coins/usdt-usdc.svg";
import stablepoolIcon from "../assets/coins/stablepool.svg";
import ethUsdIcon from "../assets/coins/eth-usd.svg";
import wbtcUsdIcon from "../assets/coins/wbtc-usd.svg";
import wstethEthIcon from "../assets/coins/wsteth-eth.svg";
import arbEthIcon from "../assets/coins/arb-eth.svg";
import magicEthIcon from "../assets/coins/magic-eth.svg";
import stgEthIcon from "../assets/coins/stg-eth.svg";
import toucanIcon from "../assets/coins/TOUCAN.svg";
import shToucanIcon from "../assets/coins/shTOUCAN.svg";
import strShellIcon from "../assets/coins/strSHELL.png"
import toucoinIcon from "../assets/coins/TOUCOIN.svg";
import toucoinEthIcon from "../assets/coins/toucoin-eth.svg"
import smolIcon from "../assets/coins/SMOL.png"
import shSmolIcon from "../assets/coins/shSMOL.png"
import smolcoinIcon from "../assets/coins/SMOLCOIN.png"
import smolpoolIcon from "../assets/coins/smolpool.png"
import countdownCratePoolIcon from "../assets/coins/countdown-crate-eth.svg"
import collabEthIcon from "../assets/coins/collab-eth.svg"
import lbpIcon from "../assets/coins/lbp.svg"
import shellEthIcon from "../assets/coins/shell-eth.svg";
import strShellShellIcon from "../assets/coins/strshell-shell.svg"
import veShellCoin from "@/assets/icons/shell-white-coin.svg";

import accountantIcon from '../assets/booty/accountant-head.png'
import shAccountantIcon from '../assets/coins/shACCT.svg'
import { calculateWrappedTokenId } from "../utils/ocean/utils";
import placeholderIcon from "../assets/coins/logo-placeholder.svg"
import { itemsMap } from "@/pages/Booty/Items";
import shellVestingStream from "@/assets/icons/stream.svg";

import magicIcon from '../assets/coins/MAGIC.svg'
import stgIcon from '../assets/coins/STG.png'
import fraxIcon from '../assets/coins/FRAX.svg'
import renzoEthIcon from '../assets/coins/renzoETH.svg'

import crv2PoolIcon from '../assets/coins/2CRV.png'
import crvMim3PoolIcon from '../assets/coins/MIM3CRV.png'
import crvUSDUsdcIcon from '../assets/coins/crvUSD-USDC.png'
import crvUSDUsdtIcon from '../assets/coins/crvUSD-USDT.png'
import crvUSDFraxIcon from '../assets/coins/crvUSD-FRAX.png'
import crvUSDMimIcon from '../assets/coins/crvUSD-MIM.png'
import wstethWethBPTIcon from '../assets/coins/wstETH-WETH-BPT.png'
import rethWethBPTIcon from '../assets/coins/rETH-WETH-BPT.png'
import ezEthWstethBPTIcon from '../assets/coins/ezETH-wstETH-BPT.png'
import aWethIcon from '../assets/coins/aWETH.png'
import aWbtcIcon from '../assets/coins/aWBTC.png'
import aFraxIcon from '../assets/coins/aFRAX.png'
import awstEthIcon from '../assets/coins/awstETH.png'
import aArbIcon from '../assets/coins/aARB.png'
import arEthIcon from '../assets/coins/arETH.png'
import moo2CRVIcon from '../assets/coins/moo2CRV.png'
import mooAbrcdbrMIM2CRVIcon from '../assets/coins/mooAbrcdbrMIM2CRV.png'
import mooCrvUsdUsdcIcon from '../assets/coins/moocrvUSD-USDC.png'
import mooCrvUsdUsdtIcon from '../assets/coins/moocrvUSD-USDT.png'
import mooCrvUsdFraxIcon from '../assets/coins/moocrvUSD-FRAX.png'
import mooCrvUsdMimIcon from '../assets/coins/moocrvUSD-MIM.png'
import mooezEthWstethIcon from '../assets/coins/mooezETH-wstETH-BPT.png'
import moorEthWethIcon from '../assets/coins/moorETH-WETH-BPT.png'
import mooWstethEthIcon from '../assets/coins/moowstETH-WETH-BPT.png'
import ptUsdcIcon from '../assets/coins/pt-usdc.svg'
import ytUsdcIcon from '../assets/coins/yt-usdc.svg'
import ptRethIcon from '../assets/coins/pt-reth.svg'
import ytRethIcon from '../assets/coins/yt-reth.svg'
import ptEzEthIcon from '../assets/coins/pt-ezeth.svg'
import ytEzEthIcon from '../assets/coins/yt-ezeth.svg'
import ptUsdeIcon from '../assets/coins/pt-usde.svg'
import ytUsdeIcon from '../assets/coins/yt-usde.svg'

import { generateColorHex } from "@/components/DexBadge/DexBadge";
import { tokenColors } from "@/constants/tokenColors";
import { openseaKey } from "@/providers/WagmiProvider";
import { NFT_RARITY, WRAPPED_NFT_MAP } from "@/utils/nftHelpers";
import { tokenLogos, createTokenLogo } from "@/constants/tokenLogos";
import { VeToken, Token, ShellToken, NFTCollection, LBPToken, ExternalToken, ExternalDefiToken } from "../utils/tokens";

export const oceanAddress = '0x96B4f4E401cCD70Ec850C1CF8b405Ad58FD5fB7a'

export const tokens: Token[] = [
  {
    name: "Ether",
    symbol: "ETH",
    address: ETH_ADDRESS,
    wrapped: false,
    icon: ethIcon
  },
  {
    name: 'Wrapped Ether',
    symbol: 'WETH',
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
  },
  {
    name: "Dai",
    symbol: "DAI",
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png'
  },
  {
    name: "Bridged USDC",
    symbol: "USDC.e",
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  {
    name: "Tether",
    symbol: "USDT",
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    wrapped: false,
    icon: 'https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9.png'
  },
  {
    name: "Wrapped Bitcoin",
    symbol: "WBTC",
    address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  },
  {
    name: 'Staked Ether',
    symbol: 'wstETH',
    address: '0x5979D7b546E38E414F7E9822514be443A4800529',
    wrapped: false,
    icon: 'https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0x5979d7b546e38e414f7e9822514be443a4800529.png'
  },
  {
    name: 'Arbitrum',
    symbol: 'ARB',
    address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    wrapped: false,
    icon: 'https://arbitrum.foundation/logo.png'
  },
  {
    name: 'Magic',
    symbol: 'MAGIC',
    address: '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
    wrapped: false,
    icon: magicIcon
  },
  {
    name: 'Stargate',
    symbol: 'STG',
    address: '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
    wrapped: false,
    icon: stgIcon
  },
  {
    name: 'CollabLand',
    symbol: 'COLLAB',
    address: '0xf18C263EC50CC211EF3F172228549B6618F10613',
    wrapped: false,
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23649.png'
  },
  {
    name: 'Shell',
    symbol: 'SHELL',
    address: SHELL_ADDRESS,
    wrapped: false,
    icon: shellIcon
  },
  {
    name: 'Rocket Pool ETH',
    symbol: 'rETH',
    address: '0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/balancer/tokenlists/main/src/assets/images/tokens/0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8.png'
  },
  {
    name: 'Magic Internet Money',
    symbol: 'MIM',
    address: '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
    wrapped: false,
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/162.png'
  },
  {
    name: "Curve.fi USD",
    symbol: "crvUSD",
    address: '0x498Bf2B1e120FeD3ad3D42EA2165E9b73f99C1e5',
    wrapped: false,
    icon: 'https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0x498bf2b1e120fed3ad3d42ea2165e9b73f99c1e5.png'
  },
  {
    name: "Frax",
    symbol: "FRAX",
    address: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
    wrapped: false,
    icon: fraxIcon
  },
  {
    name: "Fractional Vesting SHELL",
    symbol: "strSHELL",
    address: STREAM_ADDRESS,
    wrapped: true,
    oceanID: '0xbfc0fac8afec93cd55aefbb38e4bc9c36ea33af24b96b30a653d3d097359393e',
    icon: strShellIcon,
  },
  {
    name: "Renzo Restaked ETH",
    symbol: "ezETH",
    address: '0x2416092f143378750bb29b79eD961ab195CcEea5',
    wrapped: false,
    icon: renzoEthIcon
  },
  {
    name: 'Ethena USD',
    symbol: 'USDe',
    address: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34',
    wrapped: false,
    icon: 'https://ethena.fi/shared/usde.svg'
  }
]

export const shellTokens: ShellToken[] = [
  {
    name: "SHELL+ETH",
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: shellEthIcon,
    oceanID: '0x679c61f9b15e386cac1317a2029d2c2d7f0e4bf83ff5e8ed9c0cf596366a7d1a',
    pool: '0xC32A9fC5665aFFCe85CF043472F718029577F7E0',
    tokens: ["SHELL", "ETH"],
    fee: 0.25,
    tokenType: 'Shell',
    protocolVersion: 'V3'
  },
  {
    name: 'strSHELL+SHELL',
    symbol: 'shLP',
    address: '0',
    oceanID: '0xb3da487eb43be007fc8943b2fcc97cc047f54e8ceaaedec0ea03210418a1bea8',
    wrapped: true,
    icon: strShellShellIcon,
    pool: '0xB2AFF1094FacF36477A0212e872C1059865B137E',
    tokens: ['strSHELL', 'SHELL'],
    tokenType: "Shell",
    protocolVersion: 'V3',
    fee: 0.25
  },
];

export const nftCollections: NFTCollection[] = [
  {
    name: "Government Toucans",
    symbol: "TOUCAN",
    address: TOUCAN_ADDRESS,
    wrapped: false,
    icon: toucanIcon,
    ipfsHash: '',
    fractionalizer: '0xC06D111d5b05f3f610BA41FDfAb9a633fD36737E',
    slug: 'government-toucans'
  },
  {
    name: "Wrapped Toucans",
    symbol: "shTOUCAN",
    address: TOUCAN_ADDRESS,
    wrapped: true,
    icon: shToucanIcon,
    ipfsHash: '',
    fractionalizer: '0xC06D111d5b05f3f610BA41FDfAb9a633fD36737E',
  },
  {
    name: "Smol Brains",
    symbol: "SMOL",
    address: SMOL_ADDRESS,
    wrapped: false,
    icon: smolIcon,
    ipfsHash: '',
    fractionalizer: '0xB91780CEEb235d4CCccEd250F298713B360b7f6d',
    slug: 'smol-brains'
  },
  {
    name: "Wrapped Smol Brains",
    symbol: "shSMOL",
    address: SMOL_ADDRESS,
    wrapped: true,
    icon: shSmolIcon,
    ipfsHash: '',
    fractionalizer: '0xB91780CEEb235d4CCccEd250F298713B360b7f6d',
  },
  {
    name: "Shellstreams",
    symbol: "STREAM",
    address: STREAM_ADDRESS,
    wrapped: false,
    icon: shellVestingStream,
    ipfsHash: '',
    fractionalizer: '0x4eB9AD5d252515AC1D6076bB39E43699E491f73A',
    isVestingStream: true,
    description: `Shellstreams are tradeable NFTs representing SHELL vesting streams, with SHELL tokens being unlocked linearly on every block. Unlocked SHELL remains in a stream until is claimed.`,
    slug: 'sablier-lockup-linear-a4bf69c-on-chain-42161'
},
  {
    name: "Wrapped Shellstreams",
    symbol: "shSTREAM",
    address: STREAM_ADDRESS,
    wrapped: true,
    icon: '',
    ipfsHash: '',
    fractionalizer: '0x4eB9AD5d252515AC1D6076bB39E43699E491f73A',
    isVestingStream: true,
  }
//   {
//     name: "Accountant's Trove",
//     symbol: "ACCT",
//     address: BOOTY_ADDRESS,
//     wrapped: false,
//     icon: accountantIcon,
//     ipfsHash: '',
//     fractionalizer: '',
//     is1155: true,
//     maxSupply: itemsMap.length
//   },
//   {
//     name: "Wrapped Trove",
//     symbol: "shACCT",
//     address: BOOTY_ADDRESS,
//     wrapped: true,
//     icon: shAccountantIcon,
//     ipfsHash: '',
//     fractionalizer: '',
//     is1155: true,
//     maxSupply: itemsMap.length
//   }
];


export const lbpTokens: LBPToken[] = [
//   {
//     name: "Shell",
//     symbol: "SHELL",
//     description: 'SHELL Liquidity Bootstraping Pool',
//     address: '0',
//     wrapped: false,
//     icon: lbpIcon,
//     pairToken: 'USDC.e'
//   },
//   {
//     name: 'CollabLand',
//     symbol: 'COLLAB',
//     description: 'Collab',
//     address: COLLAB_ADDRESS,
//     wrapped: false,
//     icon: collabIcon,
//     pairToken: 'ETH',
//     poolName: 'COLLAB+ETH',
//     poolAddress: COLLAB_ETH_ADDRESS,
//     poolOceanID: COLLAB_ETH_LP_TOKEN_ID,
//     poolIcon: collabEthIcon
//   },
    // {
    //   name: 'Sunken Crate',
    //   symbol: 'frACCT-0',
    //   description: 'Sunken Crate',
    //   address: BOOTY_ADDRESS,
    //   wrapped: true,
    //   icon: '',
    //   pairToken: 'ETH',
    //   poolName: 'Sunken Crate Pool',
    //   poolAddress: '0x4E064dC73F1a14833B75c41172457cd09F67C4ae',
    //   poolOceanID: '0xdf9599ecff738d0b92d1829e18d0805d8265d7966002487f21f77a7b844b1572',
    //   poolIcon: collabEthIcon
    // },
];

export const externalTokens: ExternalToken[] = [
  {
    name: "Fractional Toucans",
    symbol: "TOUCOIN",
    address: TOUCAN_ADDRESS,
    wrapped: false,
    oceanID: "0x22f353625821fc1deecf5e926787edee7672e0f4c537ba94b3f2b2b64b43d9be",
    icon: toucoinIcon,
    tokenType: "Shell",
    protocolVersion: "V2",
  },
  {
    name: "Fractional Smol Brains",
    symbol: "SMOLCOIN",
    address: SMOL_ADDRESS,
    wrapped: false,
    oceanID: "0x44e43f758fe59a8f0d5e19c28d27f04cdf380bb2c5c0d6394f2f242694be0a0f",
    icon: smolcoinIcon,
    tokenType: "Shell",
    protocolVersion: "V2",
  }
];

export const externalDefiTokens: ExternalDefiToken[] = [
    {
      name: 'ETH+WETH',
      symbol: 'ETH+WETH',
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      wrapped: false,
      icon: '',
      pool: '0x65f257A45ba85ff99DC594cab2fBB1E6CBd7797E',
      query: '0x65f257A45ba85ff99DC594cab2fBB1E6CBd7797E',
      tokens: ['ETH', 'WETH'],
      tokenType: undefined,
      fee: 0
    },
    {
      name: 'Uniswap',
      symbol: 'UNI',
      address: '',
      wrapped: false,
      icon: '',
      pool: '0xC98675DA140B7493448B986019933483400536CE',
      query: '0xA465A87112f100E329342351Cac338CF94498eeA',
      tokens: tokens.filter((token) => !token.wrapped && token.address !== ETH_ADDRESS).map((token) => token.symbol),
      tokenType: 'Uniswap',
      protocolVersion: 'V3',
      fee: 0
    },
    {
      name: '2pool',
      symbol: '2CRV',
      address: '0x7f90122bf0700f9e7e1f688fe926940e8839f353',
      wrapped: false,
      icon: crv2PoolIcon,
      pool: '0x02b4ab3B517371D0BD71D325dbE7dFc0320742e4',
      query: '0xcfa2bd1d1fe7670B5617675Af70F27BFE96bF2ca',
      tokens: ['USDC.e', 'USDT'],
      tokenType: "Curve",
      fee: 0.01
    },
    {
        name: 'MIM Metapool',
        symbol: 'MIM3CRV',
        address: '0x30dF229cefa463e991e29D42DB0bae2e122B2AC7',
        wrapped: false,
        icon: crvMim3PoolIcon,
        pool: '0xC77030692f296BC53b7995F85e6D54cb679E1115',
        query: '0xeE4E7d43908d0ff5BAd4bB730f76255Ab2b7665e',
        tokens: ['MIM', '2CRV'],
        tokenType: "Curve",
        fee: 0.04
      },
    {
      name: 'crvUSD/USDC',
      symbol: 'crvUSD/USDC',
      address: '0xec090cf6DD891D2d014beA6edAda6e05E025D93d',
      wrapped: false,
      icon: crvUSDUsdcIcon,
      pool: '0xe428F7C5F9DC057fdcAD4c5Ff11301C10F78f6ED',
      query: '0x05326679fEd5b40d967A2a4bC13175ca7ad55e54',
      tokens: ['crvUSD', 'USDC'],
      tokenType: "Curve",
      fee: 0.01
    },
    {
      name: 'crvUSD/USDT',
      symbol: 'crvUSD/USDT',
      address: '0x73af1150f265419ef8a5db41908b700c32d49135',
      wrapped: false,
      icon: crvUSDUsdtIcon,
      pool: '0x5c4826cBa1CF3Daa1A06a11d46070FC258D2645e',
      query: '0x3c5fB423DaD052f26fB1741056351B122839Ac1e',
      tokens: ['crvUSD', 'USDT'],
      tokenType: "Curve",
      fee: 0.01
    },
    {
        name: 'crvUSD/FRAX',
        symbol: 'crvUSD/FRAX',
        address: '0x2FE7AE43591E534C256A1594D326e5779E302Ff4',
        wrapped: false,
        icon: crvUSDFraxIcon,
        pool: '0x7cdF1b25C74fc6562A75C86127a9ba4A650BFB9A',
        query: '0xA4D3Cb79d64185113b28ffd3982D7909453E17fc',
        tokens: ['crvUSD', 'FRAX'],
        tokenType: "Curve",
        fee: 0.01
    },
    {
        name: 'crvUSD/USDC.e',
        symbol: 'crvUSD/USDC.e',
        address: '0x3aDf984c937FA6846E5a24E0A68521Bdaf767cE1',
        wrapped: false,
        icon: crvUSDUsdcIcon,
        pool: '0x67F868B76a47fDc02540e9D977BE95924A164bD5',
        query: '0xEcC3bE1e10B654C8c9ca0a786E698b7b117Fdc72',
        tokens: ['crvUSD', 'USDC.e'],
        tokenType: "Curve",
        fee: 0.01
    },
    {
        name: 'crvUSD/MIM',
        symbol: 'crvUSD/MIM',
        address: '0x4070C044ABc8d9d22447DFE4B032405970878d06',
        wrapped: false,
        icon: crvUSDMimIcon,
        pool: '0x0fd3eAaC40225621390C7df6b8339af7FCe31b12',
        query: '0x77E0009b708EF34c81753DA5f3b5c7025a8478B8',
        tokens: ['crvUSD', 'MIM'],
        tokenType: "Curve",
        fee: 0.01
    },
    {
        name: 'wstETH-WETH Stable Pool',
        symbol: 'wstETH-WETH-BPT',
        address: '0x9791d590788598535278552EEcD4b211bFc790CB',
        wrapped: false,
        icon: wstethWethBPTIcon,
        pool: '0xA8Cb454449143912159e066760c1cf3b92415B4A',
        query: '0x31c7c774498072bd56b2641EF4b450c1B88e3978',
        tokens: ['wstETH', 'WETH'],
        tokenType: "Balancer",
        protocolVersion: 'V2',
        fee: 0.03,
        metadata: '0x9791d590788598535278552eecd4b211bfc790cb000000000000000000000498' // Balancer Pool ID
    },
    {
        name: 'rETH-WETH Stable Pool',
        symbol: 'rETH-WETH-BPT',
        address: '0xadE4A71BB62bEc25154CFc7e6ff49A513B491E81', 
        wrapped: false,
        icon: rethWethBPTIcon,
        pool: '0x85482FEC354E3959c7b7fB6d99b658DB9B53A39c', 
        query: '0x1B2FFd1daaF1E9F023D53be3839903CC0598041f', 
        tokens: ['rETH', 'WETH'],
        tokenType: "Balancer",
        protocolVersion: 'V2',
        fee: 0.02,
        metadata: '0xade4a71bb62bec25154cfc7e6ff49a513b491e81000000000000000000000497' // Balancer Pool ID
    },
    {
        name: 'ezETH-wstETH Stable Pool',
        symbol: 'ezETH-wstETH-BPT',
        address: '0xB61371Ab661B1ACec81C699854D2f911070C059E', 
        wrapped: false,
        icon: ezEthWstethBPTIcon,
        pool: '0x9F2B52085e0d17e3d1b299052AC99df5ba206fd8', 
        query: '0x2fcFF9E1602D8d8D00FA2d353eE9c7bb7e9d0e1F', 
        tokens: ['ezETH', 'wstETH'],
        tokenType: "Balancer",
        protocolVersion: 'V2',
        fee: 0.04,
        metadata: '0xb61371ab661b1acec81c699854d2f911070c059e000000000000000000000516' // Balancer Pool ID
    },
    {
      name: 'Aave Arbitrum WETH',
      symbol: 'aArbWETH',
      address: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
      wrapped: false,
      icon: aWethIcon,
      pool: '0xdEf2a2572Fd3cfc6E862DDF856Ce5B51b1231e85',
      query: '0xdEf2a2572Fd3cfc6E862DDF856Ce5B51b1231e85',
      tokens: ['WETH'],
      tokenType: 'Aave',
      protocolVersion: 'V3',
      fee: 0
    },
    {
      name: 'Aave Arbitrum DAI',
      symbol: 'aArbDAI',
      address: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
      wrapped: false,
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5763.png',
      pool: '0x9Cc9CE741b99392f592CEE49fFc0A954f835120c',
      query: '0x9Cc9CE741b99392f592CEE49fFc0A954f835120c',
      tokens: ['DAI'],
      tokenType: 'Aave',
      protocolVersion: 'V3',
      fee: 0
    },
    {
      name: 'Aave Arbitrum USDC',
      symbol: 'aArbUSDC',
      address: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
      wrapped: false,
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5760.png',
      pool: '0xE03Bc2c0D75E3f759Be67E5AfBa8C9242A7BE8B1',
      query: '0xE03Bc2c0D75E3f759Be67E5AfBa8C9242A7BE8B1',
      tokens: ['USDC.e'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum USDCn',
      symbol: 'aArbUSDCn',
      address: '0x724dc807b04555b71ed48a6896b6F41593b8C637',
      wrapped: false,
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5760.png',
      pool: '0x6428BB8D0e12BD3f8524f433C5CDaFf8F2CBEB5f',
      query: '0x6428BB8D0e12BD3f8524f433C5CDaFf8F2CBEB5f',
      tokens: ['USDC'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum USDT',
      symbol: 'aArbUSDT',
      address: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
      wrapped: false,
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5761.png',
      pool: '0x218406574f04fB7586583B86E253135aCf56BAEf',
      query: '0x218406574f04fB7586583B86E253135aCf56BAEf',
      tokens: ['USDT'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum WBTC',
      symbol: 'aArbWBTC',
      address: '0x078f358208685046a11C85e8ad32895DED33A249',
      wrapped: false,
      icon: aWbtcIcon,
      pool: '0xa6485D25C44eb97D01369De7781e8614A4200C4E',
      query: '0xa6485D25C44eb97D01369De7781e8614A4200C4E',
      tokens: ['WBTC'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum wstETH',
      symbol: 'aArbwstETH',
      address: '0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf',
      wrapped: false,
      icon: awstEthIcon,
      pool: '0x51F29Cac3ca66c80Eb1e6f873c5DFB4116466d22',
      query: '0x51F29Cac3ca66c80Eb1e6f873c5DFB4116466d22',
      tokens: ['wstETH'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum ARB',
      symbol: 'aArbARB',
      address: '0x6533afac2E7BCCB20dca161449A13A32D391fb00',
      wrapped: false,
      icon: aArbIcon,
      pool: '0xc44f80F509C73B463e3097a8aD308cdDE9AA511B',
      query: '0xc44f80F509C73B463e3097a8aD308cdDE9AA511B',
      tokens: ['ARB'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum rETH',
      symbol: 'aArbrETH',
      address: '0x8Eb270e296023E9D92081fdF967dDd7878724424',
      wrapped: false,
      icon: arEthIcon,
      pool: '0xA31fC8119dC3DF0ad89E8Da57e5B3B5941Cfb49B',
      query: '0xA31fC8119dC3DF0ad89E8Da57e5B3B5941Cfb49B',
      tokens: ['rETH'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
      name: 'Aave Arbitrum FRAX',
      symbol: 'aArbFRAX',
      address: '0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5',
      wrapped: false,
      icon: aFraxIcon,
      pool: '0xeE7102828895A02F63633871F7Ac796286fe670C',
      query: '0xeE7102828895A02F63633871F7Ac796286fe670C',
      tokens: ['FRAX'],
      tokenType: 'Aave',
      protocolVersion: 'V3',      
      fee: 0
    },
    {
        name: 'DAI+USDC',
        symbol: 'DAI+USDC',
        address: '0x96C7dC9d473e621a1e3968Cb862803EAEDe21888',
        oceanID: '0xbebb2eb60c5f0f6b2851070df30eb6b21f828c3b8242fd438caed9ca074a4436',
        wrapped: false,
        icon: daiUsdcIcon,
        pool: '0x267f7c46B2d4A2e5CCAFB4C11059Aec50b2F0474',
        query: '0x537E2d22CC140C15Ec03e99BE399223750DE7737',
        tokens: ['DAI', 'USDC.e'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.05
    },
    {
        name: 'USDT+USDC',
        symbol: 'USDT+USDC',
        address: '0x0cb736ea2AD425221c368407CAAFDD323b7bDc83',
        oceanID: '0xaed7b01708bac910285a0331e9d703cc070c2c3ab641b4aec46feffa10f0d46b',
        wrapped: false,
        icon: usdtUsdcIcon,
        pool: '0xdDFe449860ed14DF4f1826cBD0E55f15BF5f7d1E',
        query: '0xd5A7eDB913eae552e341b3bA0704CB2F6971BF7b',
        tokens: ['USDT', 'USDC.e'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.05
    },
    {
        name: 'Stablepool',
        symbol: 'Stablepool',
        address: '0x4f9d367636d5d2056f848803C11872Fdbc2afc47',
        oceanID: '0x6fa5e1f835f60b6883a60a12ec43e1534c10acef7b090b1b6a36b82876f54de9',
        wrapped: false,
        icon: stablepoolIcon,
        pool: '0x2e930a8678d6dcc2D0d78046A8a1fE22CF20dF6A',
        query: '0xB827ce2b24518eAa13663AFF541632c8CB3E8C71',
        tokens: ['USDT+USDC', 'DAI+USDC'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.025
    },
    {
        name: 'ETH+USD',
        symbol: 'ETH+USD',
        address: '0xa2dB39e781A5Eee0EAA625dace6F097c17dfF7Ea',
        oceanID: '0x3d39f7c71b4be4b2b39924b0aefbe172194c1a8b11ff4e31a74896edead80b02',
        wrapped: false,
        icon: ethUsdIcon,
        pool: '0x97064fE13D64061496d881eb3C8058E7fe19374F',
        query: '0x618b21Dd90b52970f4F3aE107F83fd9900E30E96',
        tokens: ['ETH', 'Stablepool'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'WBTC+USD',
        symbol: 'WBTC+USD',
        address: '0x3402D87DF0817b2A96b210b8873d33dD979C8D19',
        oceanID: '0x0e53d22e36f0a0403bacf365d574a97253953118fb462cb766f6a1a34791d493',
        wrapped: false,
        icon: wbtcUsdIcon,
        pool: '0x2C80f9f889F7613070390205f31a35C7899C8209',
        query: '0xE55f3d89043154563cEc9b283fA57752442Bc56a',
        tokens: ['WBTC', 'Stablepool'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'wstETH+ETH',
        symbol: 'wstETH+ETH',
        address: '0x2EaB95A938d1fAbb1b62132bDB0C5A2405a57887',
        oceanID: '0x39d7132d7d4337aea5f75f468e05f6efd8182efd66eb6ddc49a8c900548411db',
        wrapped: false,
        icon: wstethEthIcon,
        pool: '0xDBa3E2671a0522c25D6d257CDc5C1087Faa0ae72',
        query: '0x56db613Ccbe5469A467dC43EEe923947bD124a29',
        tokens: ['wstETH', 'ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.025
    },
    {
        name: 'ARB+ETH',
        symbol: 'ARB+ETH',
        address: '0xA16F40437213020A167c230E4667ff8f13640f75',
        oceanID: '0x977a1e4c16998d4b94d59494909c9503f5bc50642326bc8fa61f2e22761e7a3a',
        wrapped: false,
        icon: arbEthIcon,
        pool: '0x53efca0d14Bf8c43f735cAb69a724FdBE53B670e',
        query: '0x243e83365063BAA4fEDa9D71c872dbaBe035280b',
        tokens: ['ARB', 'ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'MAGIC+ETH',
        symbol: 'MAGIC+ETH',
        address: '0x0699645f2fd448398272aE07F82EEe8d0388DE1C',
        oceanID: '0xf00fbe5928cf77453911087439475eba4c4ddedd3f3664589eaa91c00e803377',
        wrapped: false,
        icon: magicEthIcon,
        pool: '0xc8DaF7859c4696cc5904ac8451304f486479df9d',
        query: '0xcd27114b23A7B913BC778994aE2996897A6d950B',
        tokens: ['MAGIC', 'ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'STG+ETH',
        symbol: 'STG+ETH',
        address: '0xe043EB17Cc12C3fD4f5EaC765D0f1b965975F470',
        oceanID: '0x395b21a145e98bc33138d4bc87a25e7ca0839b05bd16c1ed06476c5ceb5c0bfe',
        wrapped: false,
        icon: stgEthIcon,
        pool: '0xCe18979EB9399b92Ebe8F6d1c206534c9eB3aF49',
        query: '0x9B41d5991b20e2f5eB3DbAE6d4C4f65f54981ccB',
        tokens: ['STG', 'ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'TOUCOIN+ETH',
        symbol: 'TOUCOIN+ETH',
        address: '0x81F6F6664E8Ece1E81bc9097084373c1dDDb8Daa',
        oceanID: '0x9b499052e6ba1808bdaf05e612d98955f2aeea34bffd35318ba56c92dd8ead74',
        wrapped: false,
        icon: toucoinEthIcon,
        pool: '0x7e892662F8df340904237E1CE503C4554544b6Ff',
        query: '0xA70615e0aA71F83253d37D19282E6BAc7F42cE07',
        tokens: ['TOUCOIN', 'ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'Smolpool',
        symbol: 'Smolpool',
        address: '0x6896177Ee52659f22A87b180e8FCB2c850A7427e',
        oceanID: '0x6aa326cb31e0abc00882250a8489836657da968ec4226710f9e146139eded137',
        wrapped: false,
        icon: smolpoolIcon,
        pool: '0xd68B95706b52F47a96626311694bA1DE577f2983',
        query: '0xDfD421298F09555e32611ac00d1dd3B22A583ebf',
        tokens: ['SMOLCOIN', 'MAGIC+ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'COLLAB+ETH',
        symbol: 'COLLAB+ETH',
        address: '0x6D0F58fdD73a34cb012B0bA10695440CBF3f7476',
        oceanID: '0x1a92dda5f59172bcf9e4338910a498864eaa25e835689ae7a9fe42d559a6a637',
        wrapped: false,
        icon: collabEthIcon,
        pool: '0x2803300A714bcBBE96EA849911Ae95cA2490aCEc',
        query: '0xAD990ee1D7F6daC3EdE5e111a20043da52c26A19',
        tokens: ['COLLAB', 'ETH'],
        tokenType: "Shell",
        protocolVersion: 'V2',
        fee: 0.25
    },
    {
        name: 'PT aUSDC 27JUN2024',
        symbol: 'PT-aUSDC-27JUN2024',
        address: '0xb72b988CAF33f3d8A6d816974fE8cAA199E5E86c',
        wrapped: false,
        icon: ptUsdcIcon,
        pool: '0x2535Ef127dC6B5D478B805dB58277d626D64f255',
        query: '0xB5f3263726fA47FA94965D643C8c56ae6BBF21ed',
        tokens: ['aArbUSDCn'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0xBa4A858d664Ddb052158168DB04AFA3cFF5CFCC8' // Pendle Market
    },
    {
        name: 'YT aUSDC 27JUN2024',
        symbol: 'YT-aUSDC-27JUN2024',
        address: '0xA1c32EF8d3c4c30cB596bAb8647e11daF0FA5C94',
        wrapped: false,
        icon: ytUsdcIcon,
        pool: '0x9A45bBd515121A5dd330891428165ffF0EbE6533',
        query: '0xC90f709c2D3E551745c50FB93603f934b33c26Bc',
        tokens: ['aArbUSDCn'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0xBa4A858d664Ddb052158168DB04AFA3cFF5CFCC8' // Pendle Market
    },
    {
        name: 'PT rETH 26JUN2025',
        symbol: 'PT-rETH-26JUN2025',
        address: '0x685155D3BD593508Fe32Be39729810A591ED9c87',
        wrapped: false,
        icon: ptRethIcon,
        pool: '0x90988abA8be06f4993Df20Dc95a50C71a12B74b8',
        query: '0xde674de5e3Db0358836b191fa6dcae3340bA6865',
        tokens: ['rETH'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0x14FbC760eFaF36781cB0eb3Cb255aD976117B9Bd' // Pendle Market
    },
    {
        name: 'YT rETH 26JUN2025',
        symbol: 'YT-rETH-26JUN2025',
        address: '0xe822AE44EB2466B4E263b1cbC94b4833dDEf9700',
        wrapped: false,
        icon: ytRethIcon,
        pool: '0x4873144dd892Bba5b196f5ca14C091B35fABE14f',
        query: '0x6B05EbA9741F15625332b1f5e1BBa10fD65e93e0',
        tokens: ['rETH'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0x14FbC760eFaF36781cB0eb3Cb255aD976117B9Bd' // Pendle Market
    },
    {
        name: 'PT ezETH 27JUN2024',
        symbol: 'PT-ezETH-27JUN2024',
        address: '0x8ea5040d423410f1fdc363379af88e1db5ea1c34',
        wrapped: false,
        icon: ptEzEthIcon,
        pool: '0x75e2b2B5290C47DB74596AD634b019a0fa658eE9',
        query: '0xad272D984DA84a42544D1aA55876dDC1d81520bD',
        tokens: ['ezETH'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0x5e03c94fc5fb2e21882000a96df0b63d2c4312e2' // Pendle Market
    },
    {
        name: 'YT ezETH 27JUN2024',
        symbol: 'YT-ezETH-27JUN2024',
        address: '0x05735b65686635f5c87aa9d2dae494fb2e838f38',
        wrapped: false,
        icon: ytEzEthIcon,
        pool: '0x7184F6bbe5e0D57Ec8122560413668e1C7428CB0',
        query: '0x277e7b9e882b1D6F2904e6f0c0cBaA4B595Eb207',
        tokens: ['ezETH'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0x5e03c94fc5fb2e21882000a96df0b63d2c4312e2' // Pendle Market
    },
    {
        name: 'PT USDe 29AUG2024',
        symbol: 'PT-USDe-29AUG2024',
        address: '0xad853EB4fB3Fe4a66CdFCD7b75922a0494955292',
        wrapped: false,
        icon: ptUsdeIcon,
        pool: '0xA8f37e857D0de88d3f13239e9229bcDaC8786053',
        query: '0x299927b13a64a6A4748d2758BBE0A797fcc18851',
        tokens: ['USDe'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0x2dfaf9a5e4f293bceede49f2dba29aacdd88e0c4' // Pendle Market
    },
    {
        name: 'YT USDe 29AUG2024',
        symbol: 'YT-USDe-29AUG2024',
        address: '0xf478f7feaeab0dfdc91e02cdd6c63c66e30a7335',
        wrapped: false,
        icon: ytUsdeIcon,
        pool: '0x0D8c8346e32dcd4083CF7434B5a5fa3d639F5fA5',
        query: '0x23d7D99116FC00601B26b1735CECC1973d5749EF',
        tokens: ['USDe'],
        tokenType: "Pendle",
        protocolVersion: 'V3',
        fee: 0,
        metadata: '0x2dfaf9a5e4f293bceede49f2dba29aacdd88e0c4' // Pendle Market
    },
    {
      name: 'Moo Curve 2pool',
      symbol: 'mooCurve2Pool',
      address: '0xEc7c0205a6f426c2Cb1667d783B5B4fD2f875434',
      wrapped: false,
      icon: moo2CRVIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['2CRV'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'curve-arb-2pool'
    },
    {
      name: 'Moo Curve crvUSD-USDC.e',
      symbol: 'mooCurveCrvUSD-USDC.e',
      address: '0x36295709Ebb6df19f6D78127F8D2e5580AE7336f',
      wrapped: false,
      icon: mooCrvUsdUsdcIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['crvUSD/USDC.e'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'curve-arb-crvusd-usdc.e'
    },
    {
      name: 'Moo Curve crvUSD-USDC',
      symbol: 'mooCurveCrvUSD-USDC',
      address: '0x174897AD878c614D7Bc563Eaf23A4a0D75D58d2C',
      wrapped: false,
      icon: mooCrvUsdUsdcIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['crvUSD/USDC'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'curve-arb-crvusd-usdc'
    },
    {
      name: 'Moo Curve crvUSD-USDT',
      symbol: 'mooCurveCrvUSD-USDT',
      address: '0x25071C7Cf437F756a4AF9260aDCe5a639e143F93',
      wrapped: false,
      icon: mooCrvUsdUsdtIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['crvUSD/USDT'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'curve-arb-crvusd-usdt'
    },
    {
      name: 'Moo Curve crvUSD-MIM',
      symbol: 'mooCurveCrvUSD-MIM',
      address: '0x5abaA1fe55c8c3fC55F3f7C814Bc19e423022764',
      wrapped: false,
      icon: mooCrvUsdMimIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['crvUSD/MIM'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'curve-arb-crvusd-mim'
    },
    {
      name: 'Moo Abrcdbr MIM-2CRV',
      symbol: 'mooAbrcdbrMIM-2CRV',
      address: '0xDa2307A45D298e855415675bF388e2bd64351D5b',
      wrapped: false,
      icon: mooAbrcdbrMIM2CRVIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['MIM3CRV'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'spell-mim-crv'
    },
    {
      name: 'Moo Balancer Arb wstETH-ETH',
      symbol: 'mooBalancerArbwstETH-ETH',
      address: '0x85B10228cd93A6e5E354Ff0f2c60875E8E62F65A',
      wrapped: false,
      icon: mooWstethEthIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['wstETH-WETH-BPT'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'balancer-wsteth-weth-v3'
    },
    {
      name: 'Moo Balancer Arb ETH-rETH',
      symbol: 'mooBalancerArbETH-rETH',
      address: '0xCF297A94d527A34081Db2A20Eb1c87248cD1ea81',
      wrapped: false,
      icon: moorEthWethIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['rETH-WETH-BPT'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'balancer-weth-reth'
    },
    {
      name: 'Moo Aura Arb ezETH-wstETH',
      symbol: 'mooAuraArbezETH-wstETH',
      address: '0xEFAd727469e7e4e410376986AB0af8B6F9559fDc',
      wrapped: false,
      icon: mooezEthWstethIcon,
      pool: '0xe9fCBe88c96a56F0A32477406bdc27f80F4eF8D2',
      query: '0xCAC5358500D0550C197f94763d8879208B060A19',
      tokens: ['ezETH-wstETH-BPT'],
      tokenType: 'Beefy',
      protocolVersion: '',      
      fee: 0,
      metadata: 'aura-arb-ezeth-wsteth'
    }
]

export const veSHELL = {
    name: 'Voting Power',
    symbol: 'veSHELL',
    address: "0x000",
    wrapped: false, 
    icon: veShellCoin
}

export const tokenMap: { [id: string]: Token | ShellToken | NFTCollection | LBPToken } = {};

tokens.forEach((token) => {
  if (token.symbol !== "shLP") {
    tokenMap[token.symbol] = token;
    // tokenLogos[token.symbol] = createTokenLogo(token, 83)
  }
});

shellTokens.forEach((token) => {
  tokenMap[token.name] = token;
//   tokenLogos[token.name] = createTokenLogo(token, 50)
});

externalTokens.forEach((token) => {
    tokenMap[token.symbol] = token
    if(!tokenColors[token.symbol]) tokenColors[token.symbol] = generateColorHex(token.tokenType!)
    // tokenLogos[token.symbol] = createTokenLogo(token, 50)
})

externalDefiTokens.forEach((token) => {
    tokenMap[token.symbol] = token;
    tokenColors[token.symbol] = generateColorHex(token.tokenType!)
    // tokenLogos[token.symbol] = createTokenLogo(token, 50)
});

nftCollections.forEach((collection) => {

    tokenMap[collection.symbol] = collection

    // tokenLogos[collection.symbol] = createTokenLogo(collection, 50)

    if(!collection.wrapped){
        const options = {method: 'GET', headers: {accept: 'application/json', 'X-API-KEY': openseaKey}};

        const rarityTable = collection.is1155 || collection.address == STREAM_ADDRESS ? [] : require(`../constants/nftRarities/${collection.symbol.toLowerCase()}-rarity.json`)
        NFT_RARITY[collection.address] = {}

        if(collection.is1155){

            const idMap : any = {}
            for(let i = 0; i < collection.totalSupply!; i++){
                idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol]
            }
            WRAPPED_NFT_MAP['sh' + collection.symbol] = idMap

        } else {
            fetch(`https://api.opensea.io/api/v2/collections/${collection.slug}`, options)
            .then(response => response.json())
            .then(response => {
                const wrappedCollection: NFTCollection = tokenMap['sh' + collection.symbol] as NFTCollection
                if(!collection.description){
                    collection.description = response.description
                    wrappedCollection.description = response.description
                }
                if(!collection.totalSupply){
                    collection.totalSupply = response['total_supply']
                    wrappedCollection.totalSupply = response['total_supply']
                }    
                const idMap : any = {}
                for(let i = 0; i <= collection.totalSupply!; i++){
                    idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol]
                    NFT_RARITY[collection.address][i.toString()] = rarityTable[i] ?? null
                }
                WRAPPED_NFT_MAP['sh' + collection.symbol] = idMap
            })
            .catch(err => console.error(err));
        }
    }
})

lbpTokens.forEach((lbpToken) => {
    tokenMap[lbpToken.symbol] = lbpToken
    tokenMap[lbpToken.poolName] = {
        name: lbpToken.poolName,
        symbol: 'shLP',
        address: '0',
        wrapped: true,
        icon: lbpToken.poolIcon,
        oceanID: lbpToken.poolOceanID,
        pool: lbpToken.poolAddress,
        tokens: [lbpToken.symbol, lbpToken.pairToken],
        fee: 0,
        tokenType: 'Shell',
        protocolVersion: 'V3'
    }
    // tokenLogos[lbpToken.symbol] = createTokenLogo(lbpToken, 83)
    // tokenLogos[lbpToken.poolName] = createTokenLogo(tokenMap[lbpToken.poolName], 50)
})
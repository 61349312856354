import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import styled from "styled-components";
import { Box } from '../../../../components/Layout/Box';


export const STIPContainer = styled.div<{isConnected?: boolean}>`
display: flex;
flex-direction: column;
gap: 28px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
margin-top: 18px;
${({ isConnected }) => !isConnected && `margin-top: 24px;`} /* match margin top of other tabs */
`;

export const ShellSTIPContainer = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;

  ${Media.tablet} {
      justify-content: center;
      align-items: center;
  }
`;

export const OtherSTIPsContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
// gap: 16px;
`;

export const TitleText = styled.h1`
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.9px;
color: #FFF;

/* Title 5 */
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.9px;

span {
    background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
`;

export const Title = styled.h2`
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ActionsAndSeasonsWrapper =  styled.div`
display: flex;
justify-content: flex-start;
align-items: stretch;
gap: 20px;
width: 100%;

${Media.tablet} {
    flex-direction: column;
  }
`;

export const ActionsAndDescriptionWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 12px;
width: 100%;
`;

export const ActionsWrapper = styled.div`
padding: 24px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
gap: 24px;
border-radius: 16px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
width: 100%;
`;

export const ActionBoxTitleWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 12px;
`;

export const ActionBoxTitle = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const ActionBoxValueWrapper = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
gap: 8px;
align-items: center;
`;
export const Coin =  styled.img`
width: 28px;
height: 28px;
`;

export const Value = styled.p`
color: #FFF;
text-align: center;

/* Title 5 */
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.9px;

span {
    color: #7D7D97;
}
`;

export const Description = styled.p`
color: #7D7D97;

/* Text 4/Regular */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 16.32px */
`;

export const SeasonsWrapper =  styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 8px;
width: 100%;
`;

export const CustomPrimaryBtn = styled(ButtonPrimary)`
width: 100%;
border-radius: 12px;
padding-top: 16px;
padding-bottom: 16px;
height: unset;

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border: 2px solid #1E2239;
background: var(--dark-4, #151530);
`}
`;

export const CustomSecondaryBtn = styled(ButtonSecondary)`
width: 100%;
border-radius: 12px;
padding-top: 16px;
padding-bottom: 16px;
height: unset;
border-width: 2px;
`;

export const LoadingWrapper = styled(Box)`
display: flex;
justify-content: center;
vertical-align: center;
width: 100%;
padding: 300px 0 300px 0;
`;

export const SeasonsDescription = styled(Description)`
    text-align: center;
`;

export const SeasonsSummaryWrapper = styled(SeasonsWrapper)`
    gap: 12px;
    background: #0a0d27;
`;

export const STIPSeasonsSummaryWrapper = styled(ActionsAndSeasonsWrapper)`
    ${Media.tablet} {
      flex-direction: column-reverse;
    }

    > * {
      flex: 1;
      min-height: 0;
    }
`;

export const STIPClaimWrapper = styled(ActionsAndDescriptionWrapper)`
    flex-direction: row;
    align-items: stretch;
    flex-wrap: nowrap;
    min-height: 0;
`;

export const STIPClaimInner = styled(ActionsWrapper)`
    align-items: center;
    justify-content: flex-start;
`;

export const CongratsTitle = styled(TitleText)`
  font-size: clamp(18px, 4vw, 28px);
  display: flex;
  gap: 8px;
  align-items: flex-end;
  text-align: center;

  span {
    font-size: clamp(20px, 4.5vw, 30px);
  }

  ${Media.tablet} {
    width: 100%;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    gap: 4px;
  }
`;


export const STIPSectionTitle = styled.div`
    color: var(--grey-1,#F0F2F6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
    /* padding-bottom: 12px; */
`;

export const STIPBalanceLabel = styled(ActionBoxTitle)`
    font-size: clamp(12px, 2vw, 14px);
    width: 100%;
    white-space: nowrap;
    text-align: center;
`;

export const STIPValue = styled(Value)`
    display: flex;
    font-size: clamp(20px, 5vw, 22px);

    span {
        margin-left: 8px;
    }
`;

export const STIPColumn = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    gap: 18px;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

  ${Media.tablet} {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    justify-content: center;
    max-width: 100%;
  }
`;

export const STIPBalanceItem = styled.div`
    display: flex;
    flex-flow: column wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    place-content: center;
    text-align: left;
    gap: 2px;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

  ${Media.tablet} {
    align-items: center;
    text-align: center;
  }
`;

export const STIPCoinValueWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`;

export const STIPCrabToShellWrapper = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    /* padding-bottom: 12px; */

    ${Media.tablet} {
      flex-direction: row;
    }
`;

export const STIPCrabIcon = styled.img`
    width: 80px;
    height: 80px;

    ${Media.tablet} {
      width: 60px;
      height: 60px;
    }
`;
export const STIPShellIcon = styled(STIPCrabIcon)``;
export const STIPRightArrowIcon = styled.img`
    width: 30px;
    height: 30px;
    transform: rotate(180deg);

    ${Media.tablet} {
      width: 25px;
      height: 25px;
    }
`;

export const STIPButtonColumn = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;
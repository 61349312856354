import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "@/components/Layout/Box";
import { Content } from "@/components/Layout/Content";
import { Media, breakpoints } from "@/styles";
import { ButtonSecondary } from "@/components/Buttons/Button";
import poolsHeaderImage from "@/assets/pools/pools-header-image.png";
import { LBPToken, isExternalDefiToken, isLBPToken, isLPToken, isMonoAdapter } from "@/utils/tokens";
import { PoolsTable } from "./PoolsTable";
import { useWidthBreakpoint } from "@/hooks";
import { SearchInput } from "@/components/TokensModal/SearchInput";
import { useSearch } from "@/hooks/useSearch";
import { PoolsTableMobile } from "./PoolsTableMobile";
import { LBP_API, POOL_API } from "@/constants/urls";
import { reduceString } from "@/utils/reduceString";
import { tokenColors } from "@/constants/tokenColors";
import { updateAllPools } from "@/store/poolsSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { addMetadata } from "@/store/lbpsSlice";
import { ChainContext } from "@/components/Overlays/ChainProvider";

type ViewsType = "LP Tokens" | "LBP Tokens";

interface TabGroupProps {
  views: ViewsType[];
  activeView: string;
  setActiveView: (activeView: ViewsType) => void;
}

function TabGroup({ views, activeView, setActiveView }: TabGroupProps) {
  return (
    <ButtonGroup>
      {views.map((view) => (
        <Tab
          key={view}
          activeView={activeView === view}
          onClick={() => setActiveView(view)}
          data-testid={`tab-${view}`}
          disabled={view == 'LBP Tokens'}
        >
          {view}
        </Tab>
      ))}
    </ButtonGroup>
  );
}

export const PoolsScreen = () => {
  const views: ViewsType[] = ["LP Tokens", "LBP Tokens"];
  const [activeView, setActiveView] = useState<ViewsType>(views[0]);
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const [poolSearchValue, setPoolSearchValue] = useState<string>("");
  
  const searchableTokenProperties = ["name"];

  const [isLoading, setIsLoading] = useState(true);
  const [lpTokenData, setLPTokenData] = useState<any[]>([])
  const [lbpTokenData, setLBPTokenData] = useState<any[]>([])

  const searchPools = useSearch(
    activeView === "LP Tokens" ? lpTokenData : lbpTokenData,
    searchableTokenProperties,
    poolSearchValue
  );

  const pageSize = 10;
  //TODO: temp remove pagination
//   const { handleSetCurrentPage, ...pagination } = usePaginate<object>(
//     searchPools,
//     {
//       pageSize,
//     }
//   );

  const { tokenMap, shellTokens, externalDefiTokens, connectedChain } = useContext(ChainContext)
  const dispatch = useAppDispatch()
  const allPoolData = useAppSelector((state) => state.pools.all[connectedChain.name]);
  const metadata = useAppSelector((state) => state.lbps.metadata);

  const queryPools = async () => {
    let data

    if(allPoolData.length > 0){
        data = allPoolData
    } else {
        const results : any = await fetch(POOL_API(connectedChain) + 'all/pools').then((response) => response.json())
        const lpTokens: any[] = shellTokens
          .map((token) => token.name)
          .concat(
            externalDefiTokens
              .filter(
                (defiToken) =>
                  isLPToken(defiToken) &&
                  !isMonoAdapter(defiToken)
              )
              .map((token) => token.symbol)
          );
        data = lpTokens.map((poolName : any) => {
            const poolInfo = results[poolName]
            const lpToken = tokenMap[poolName]
            return {
                name: isExternalDefiToken(lpToken) ? lpToken.symbol : lpToken.name,
                subname: reduceString(lpToken.oceanID ?? lpToken.address, 6, 4),
                visible: true,
                liquidity: poolInfo.totalValueLocked,
                volume: poolInfo['24HrVolume'],
                apy: poolInfo.apy,
                rewardAPY: poolInfo.rewardAPY,
                type: "LP Token",
            }
        })

        dispatch(updateAllPools({data: data, chain: connectedChain.name}))
    }
    return data
  }

  const queryLBPs = async () => {

    const fetchLBPInfo = async (tokenID: string) => {
      if (metadata[tokenID]) return metadata[tokenID];
  
      return fetch(LBP_API(connectedChain) + tokenID)
        .then((response) => response.json())
        .then((data) => {
          dispatch(addMetadata({ name: tokenID, data: data }));
          return data;
        })
        .catch(() => {});
    };

    const lbps = Object.values(tokenMap)
      .filter((token): token is LBPToken => isLBPToken(token));

    const results = await Promise.all(lbps.map((lbpToken) => fetchLBPInfo(lbpToken.poolName)))

    return results.map((lbpInfo : any, index : number) => {
        const lbpToken = lbps[index]
        return {
            name: lbpToken.symbol,
            subname: lbpToken.name,
            visible: true,
            color: tokenColors[lbpToken.symbol],
            status: lbpToken.status,
            marketCap: lbpInfo.marketCap,
            volume: lbpInfo.totalVol,
            type: "Token",
        }
    }).reverse()

  }

  useEffect(() => {

    setIsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
        const [newLPTokenData, newLbpData] = await Promise.all([queryPools(), queryLBPs()]).catch(() => [[], []])
        if(signal.aborted) return

        setLPTokenData(newLPTokenData)
        setLBPTokenData(newLbpData)
        setIsLoading(false)

    }
    
    fetchData()
    
    return () => {
      controller.abort()
    };
  }, [connectedChain]);

  return (
    <Content>
        <View>
          <PoolsScreenHeader>
            <TitleContainer>
              <FlexContainer>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  Liquidity {activeView === "LP Tokens" ? "" : "Bootstrapping"}{" "}
                  Pools{" "}
                  {/* {isTablet && <TableButton style={{height: '32px', width: '32px', padding: '0', borderRadius: '8px'}}>+</TableButton>} */}
                </div>
                <span>
                  {activeView === "LP Tokens"
                    ? "Earn swap fees, earn SHELL and create your own Pool."
                    : "Get LBP Tokens, earn SHELL and be first owner of new tokens."}
                </span>
              </FlexContainer>
              {/* {!isTablet && 
              <TableButton>
                + Create {activeView === "LP Tokens" ? "Pool" : "LBP"}
              </TableButton>
              } */}
            </TitleContainer>
            <TabGroup
              views={views}
              activeView={activeView}
              setActiveView={setActiveView}
            />
            <HeaderImage src={poolsHeaderImage} />
          </PoolsScreenHeader>
          <TableContainer>
            {isTablet ? (
              <PoolsTableMobile
                activeView={activeView}
                data={searchPools}
                isLoading={isLoading}
              />
            ) : (
              <>
                <SearchInput
                  value={poolSearchValue}
                  onChange={(event) =>
                    setPoolSearchValue(event.target.value)
                  }
                  placeholder={`Type ${
                    activeView === "LP Tokens" ? "LP" : "LBP"
                  } Token name`}
                />
                <PoolsTable
                  activeView={activeView}
                  data={searchPools}
                  isLoading={isLoading}
                />
              </>
            )}
          </TableContainer>
          {/* <PaginationContainer>
            <Pagination {...pagination} />
          </PaginationContainer> */}
        </View>
    </Content>
  );
};

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 300px 0 300px 0;
`

const View = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 26px 0;

  ${Media.tablet} {
    padding-inline: 4px;
  }
`;

const PoolsScreenHeader = styled.div`
  padding-inline: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark-2, #171b33);

  .header-image {
    position: absolute;
    top: -26px;
    right: 0;
    z-index: 0;

    ${Media.tablet} {
      display: none;
    }
  }

  ${Media.tablet} {
    padding-inline: 8px;
  }
`;

const TableButton = styled(ButtonSecondary)`
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  backdrop-filter: blur(25px);
  z-index: 1;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.tablet} {
    width: 100%;
  }
`;

const HeaderImage = styled.img`
  position: absolute;
  top: -26px;
  right: 0;
  z-index: 0;
  height: 197px;

  ${Media.tablet} {
    display: none;
  }
`;

const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
  width: 202px;
  margin-top: 48px;
`;

const Tab = styled.button<{ activeView: boolean }>`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  padding-inline: 0;
  cursor: pointer;
  opacity: 0.6;
  background: transparent;
  color: white;
  border: 0;
  outline: 0;
  z-index: 0;
  ${({ activeView }) =>
    activeView &&
    `
    border-bottom: 2px solid #37DCF2;
    opacity: 1;
    `}
  ${Media.mobile} {
    width: unset;
    padding-bottom: 16px;
    padding-inline: 0;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

`;

const TitleContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;

  span {
    max-width: 298px;
    font-size: 16px;
    font-weight: 400;
    color: #7d7d97;
  }

  ${Media.tablet} {
    flex-direction: column;
    gap: 20px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PaginationContainer = styled.div`
  margin-top: 40px;
  &:empty {
    display: none;
  }

  ${Media.tablet} {
    margin-top: 32px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 20px;
  ${Media.tablet} {
    padding-inline: 8px;
  }
`;

const TableTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
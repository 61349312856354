import React, { useRef } from 'react'
import styled from 'styled-components';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useToggler } from '../../hooks/useToggler';
import { Dropdown, DropdownButton, DropdownContent, DropdownToggle } from '../Dropdown';
import { DropdownIconDown, DropdownIconUp } from '../Icons/DropdownIcon';

export interface TableFilter {
  id: string
  label: string
}

interface TableFilterDropdownProps {
  value: TableFilter
  onChange: (value: TableFilter) => void
  list: TableFilter[]
  className?: string
}

export const TableFilterDropdown = ({ value, onChange, list, className }: TableFilterDropdownProps) => {
  const { visible, toggle } = useToggler();
  const ref = useRef(null);
  useClickOutside(ref, () => toggle(false));

  return (
    <Dropdown ref={ref} className={visible ? "expanded" : ""}>
      <DropdownToggle onClick={() => toggle()} className={className}>
        {value.label}
        {visible
          ? <DropdownIconUp/>
          : <DropdownIconDown/>
        }
      </DropdownToggle>
      {visible && (
        <DropdownContent>
          {list.map((item) => (
            <li key={item.id}>
              <DropdownButton
                onClick={() => {
                    onChange(item)
                    toggle(false)
                }}
                className={item.id === value.id ? "active" : ""}
              >
                {item.label}
              </DropdownButton>
            </li>
          ))}
        </DropdownContent>
      )}
    </Dropdown>
  );
}


export const crates = [
    {name: 'Sunken Crate', image: 'sunken-crate', desc: `Common boosts, expanders, and wreckage from Bro Kwon's sunken yacht.`, price: 0.001, maxSupply: '20000', maxMint: 20, minToucan: 0, crateID: 0},
    {name: 'Rare Crate', image: 'rare-crate',  desc: 'A shimmering crate packed with rarer boosts, expanders, and artifacts.', price: 0.001, maxSupply: '10000', maxMint: 10, minToucan: 1, crateID: 1},
    {name: 'Mythic Crate', image: 'mythic-crate', desc: 'A legendary trove. All the best odds for rare and exclusive booty.', price: 0.001, maxSupply: '5000', maxMint: 5, minToucan: 3, crateID: 2},
    {name: 'Countdown Crate', image: 'countdown-crate', desc: `A sizzling chest of treasure from The Connector's lair.`, price: 0, maxSupply: '0', maxMint: 0, minToucan: 0, crateID: 21},
]

export const artifacts = [
    {name: 'Mini Booster', image: 'mini-booster', desc: 'A barnacle-crusted rocket booster.', effect: {type: 'Booster', value: 2, duration: 10}},
    {name: 'Main Booster', image: 'main-booster', desc: 'A sizable dual rocket booster.', effect: {type: 'Booster', value: 3, duration: 10}},
    {name: 'Hyperbooster', image: 'hyperbooster', desc: 'A rainbow-spewing plasma booster.', effect: {type: 'Booster', value: 5, duration: 30}},
    {name: 'Hand of the Accountant', image: 'accountant-hand', desc: 'A gauntlet of tremendous power.', effect: {type: 'Booster', value: 15, duration: 30}},
    {name: 'Smashed Expander', image: 'smashed-expander', desc: 'A fried and battered expander unit.', effect: {type: 'Expander', value: 50}},
    {name: 'Standard Expander', image: 'standard-expander', desc: 'A lowly, reliable expander unit.', effect: {type: 'Expander', value: 250}},
    {name: 'Chunky Expander', image: 'chunky-expander', desc: 'A hefty, girthy expander unit.', effect: {type: 'Expander', value: 1000}},
    {name: 'Expando-Chad', image: 'expando-chad', desc: 'A giga-powerful expander unit.', effect: {type: 'Expander', value: 5000}},
    {name: `Bro Kwon's Mastermind`, image: 'mastermind', desc: 'A machine of frightening intellect.', effect: {type: 'Expander', value: 50000}},
    {name: 'Yacht Chunk', image: 'yacht-chunk', desc: `A blasted piece of scrap from Bro Kwon's yacht.`, effect: null},
    {name: 'Sunken Drone', image: 'sunken-drone', desc: 'An Accountant drone exhumed from its watery grave.', effect: null},
    {name: 'Bro Kwon IOU', image: 'iou', desc: 'A worthless piece of paper signed by Bro Kwon himself.', effect: null},
    {name: 'Ancient Tablet', image: 'ancient-tablet', desc: 'A stone tablet with ominous energy and hints of dark toucan tidings.', effect: null},
    {name: 'Ponzu Sauce', image: 'ponzu-sauce', desc: `A bottle of sauce recovered from Bro Kwon's galley. Salty!`, effect: null},
    {name: '???', image: 'mystery', desc: 'A mysterious, glowing crystal inside a reinforced case.', effect: null},
    {name: 'Rabbit Booster', image: 'rabbit-booster', desc: 'A booster enhanced with rabbit energy.', effect: {type: 'Booster', value: 1, duration: 5}},
    {name: 'Lucky Chad', image: 'lucky-chad', desc: 'A festive Chad that grants a random boost.', effect: {type: 'Booster', value: 0, duration: 5}},
    {name: 'Converter', image: 'converter', desc: 'A clever machine built by Alice to turn expanders into boosters.', effect: null},
    {name: 'Bassbooster', image: 'bassbooster', desc: 'An electric bass tuned for a SHELL reward overdrive.', effect: {type: 'Booster', value: 2.5, duration: 20}},
    {name: 'Keybooster', image: 'keybooster', desc: 'A synthy keytar built to boost SHELL rewards.', effect: {type: 'Booster', value: 5, duration: 10}},
    {name: 'Sauced Bassbooster', image: 'sauced-bassbooster', desc: 'A supercharged electric bass to boost SHELL rewards.', effect: {type: 'Booster', value: 3.5, duration: 20}},
    {name: 'Sauced Keybooster', image: 'sauced-keybooster', desc: 'A keytar with the sauce to super-boost SHELL rewards.', effect: {type: 'Booster', value: 7, duration: 10}},
    {name: 'Megawub Booster', image: 'megawub-booster', desc: 'An earthshaking DJ deck ready to rock the club and SHELL rewards.', effect: {type: 'Booster', value: 15, duration: 10}},
]

export const itemsMap : any[] = new Array(crates.length + artifacts.length)

crates.forEach((crate) => {
    itemsMap[crate.crateID] = crate
})

let currentIndex = 0
Array.from(Array(crates.length + artifacts.length).keys()).forEach((id: number) => {
    if(!itemsMap[id]){
        itemsMap[id] = artifacts[currentIndex]
        currentIndex++;
    }
})

import styled, { css } from "styled-components";
import { Media } from "../../styles";

export const SwapInfo = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-between"};
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 36px auto 12px;
  text-align: center;

  ${Media.tablet} {
    justify-content: center;
    margin: 24px auto 0;
  }
`;

export const SwapInfoText = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 79%;

  .prefix {
    color: #A9D1E2;
  }

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 12px;
    `};
`;

export const SwapInfoDirection = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`;

export const SwapWrappedTokenInfo = styled.a`
  margin-left: auto;
  font-size: 14px;
  line-height: 16px;
  color: #a9d1e2;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: #ffffff;
  }
`;

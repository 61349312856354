import React from "react";
import styled from "styled-components";
import { Box } from "@/components/Layout/Box";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";

export const BoostBox = ({
  image,
  headerText,
  descriptionText,
  onClickButton,
}: {
  image: string;
  headerText: string;
  descriptionText: string;
  onClickButton: () => void;
}) => {
  return (
    <View onClick={onClickButton}>
      <Image src={image}></Image>
      <FlexContainer>
        <Header>{headerText}</Header>
        <Description>{descriptionText}</Description>
      </FlexContainer>
      <RedirectButton onClick={onClickButton}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 17L17 7"
            stroke="url(#paint0_linear_4695_28713)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 7H17V17"
            stroke="url(#paint1_linear_4695_28713)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4695_28713"
              x1="7.0875"
              y1="7"
              x2="17.0766"
              y2="7.07716"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#37DCF2" />
              <stop offset="1" stopColor="#07C0FB" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4695_28713"
              x1="7.0875"
              y1="7"
              x2="17.0766"
              y2="7.07716"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#37DCF2" />
              <stop offset="1" stopColor="#07C0FB" />
            </linearGradient>
          </defs>
        </svg>
      </RedirectButton>
    </View>
  );
};

const RedirectButton = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 14px;
  border: 1px solid var(--dark-3, #1e2239);
  background: var(--dark-4, #151530);
  cursor: pointer;

  ${Media.smallMobile} {
    height: 32px;
    width: 32px;
    border-radius: 8px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${Media.smallDesktop} {
    max-width: 230px;
  }
`;

const View = styled(Box)`
  border: 1px solid #171b33;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    background: #151530;
    border-color: #2c5173;

    svg {
      transform: translate(4px, -4px);
    }
  }
`;

const Image = styled.img`
  width: 100px;
  height: 100px;

  ${Media.tablet} {
    width: 80px;
    height: 80px;
  }
`;

const Header = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  ${Media.tablet} {
    font-size: 18px;
  }
  ${Media.smallMobile} {
    font-size: 16px;
  }
`;

const Description = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45em;
  ${Media.tablet} {
    font-size: 12px;
  }
`;

import React from "react";
import styled from "styled-components";
import shellIcon from '@/assets/coins/SHELL.svg'
import veShellIcon from '@/assets/icons/shell-white-coin.svg'
import { Media } from "@/styles";
import  { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";

type OverviewBoxProps = {
    label: string;
    value: string;
    withIcon?: boolean;
    icon?: string
    customLabelColor?: string;
}

export const OverviewBox = ({label, value, withIcon, icon, customLabelColor} :OverviewBoxProps) => {
    const { stakingLoading } = useRewardsContext();

    return (
        <BoxContainer>
            <BoxLabel>{label}</BoxLabel>
            <BoxValueWrapper>
                {withIcon && <BoxIcon src={icon == 'veSHELL' ? veShellIcon : shellIcon} alt="shell coin icon" />}
                {stakingLoading ? <SkeletonBox isLoading={true} width="124px" height="30px" borderRadius="8px"/> :
                <BoxValue customColor={customLabelColor}>{value}</BoxValue>}
            </BoxValueWrapper>
        </BoxContainer>
    )
}

const BoxContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 12px;
padding: 24px;
justify-content: flex-start;
align-items: flex-start;
border-radius: 16px;
border: 1px solid var(--dark-2, #171B33);
background: var(--dark-1, #0A0E27);

${Media.tablet} {
    padding: 16px;
}
`;

const BoxLabel = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

const BoxValueWrapper = styled.div`
display: flex;
gap: 8px;
justify-content: flex-start;
align-items: center;
width: 100%;
`;

const BoxValue = styled.p<{customColor?: string}>`
color: ${({customColor}) => customColor ? customColor : "#F0F2F6"};
text-align: center;
/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`;

const BoxIcon = styled.img`
width: 24px;
height: 24px;
`;
import { NFT, VestingStream } from "@/utils/tokens";
import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { NFTsSwiperSelect } from "../NFTsSwiper/NFTsSwiperSelect";
import shellCoin from "@/assets/coins/shell-small.svg";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { CustomTooltip } from "@/components/CustomTooltip/CustomTooltip";
import { useWidthBreakpoint } from "@/hooks";
import { breakpoints } from "@/styles";
import { ChainContext } from "@/components/Overlays/ChainProvider";

type VestingStreamInfoProps = {
    selectedStreams: VestingStream[];
    onNFTSweepSelect?: (collection: string, items: NFT[]) => void;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    updateSelectedNFTs?: any;
    isInputToken: boolean;
    isShrunk: boolean;
};

export const VestingStreamInfo = ({selectedStreams, onNFTSweepSelect, setIsVisible, updateSelectedNFTs, isInputToken, isShrunk} :VestingStreamInfoProps) => {

    const isTablet = useWidthBreakpoint(breakpoints.tablet)

    const { tokenMap } = useContext(ChainContext)

    const sumAllocation = (vestingStreams :VestingStream[]) => {
        const totalAllocation = vestingStreams.reduce((sum, stream) => {
            const allocationValue = parseFloat(stream.vesting);
            return sum + (isNaN(allocationValue) ? 0 : allocationValue);
          }, 0);

          return formatDisplayShorthand(totalAllocation, 2, 1e3)
                
    };

    const sumTokensToClaim = (vestingStreams :VestingStream[]) => {
        const totalTokensToClaim = vestingStreams.reduce((sum, stream) => {
            const tokensValue = parseFloat(stream.claimable);
            return sum + (isNaN(tokensValue) ? 0 : tokensValue);
          }, 0);
        
        return formatDisplayShorthand(totalTokensToClaim, 2, 1e3)
    }

    return (
        <Wrapper className={isShrunk ? 'shrunk' : ''}>
            <TokenDiv>
            <Title className={isShrunk ? 'shrunk' : ''}>NFTs to {isInputToken ? ' Send' : ' Receive'}</Title>
            <NFTsSwiperSelect 
                allSelectedNFTs={{"STREAM": selectedStreams}}
                nftSweepInputPrice={null}
                onNFTSweepSelect={onNFTSweepSelect}
                nftTokens={selectedStreams}
                updateSelectedNFTs={updateSelectedNFTs} 
                selectedCollection={tokenMap['STREAM']} 
                isInputToken={isInputToken}
                isVestingStreamVariant
                setIsVisible={setIsVisible}
            />  
        </TokenDiv>
        <StreamInfoDiv className={isShrunk ? 'shrunk' : ''}>
            <StreamInfoItem>
                <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <StreamInfoLabel>
                Total tokens to claim
                </StreamInfoLabel>
                {isInputToken && 
                <CustomTooltip
                  position={isTablet ? "bottom" : "left"}
                  title="Claimable SHELL"
                  subtext="This amount will automatically be claimed and sent to your wallet during the swap"
                  delay={50}
                />
                }
                </div>
                <StreamInfoValue>
                <img src={shellCoin} alt="shell logo" />
                {sumTokensToClaim(selectedStreams ?? [])} SHELL
                </StreamInfoValue>
            </StreamInfoItem>
            <StreamInfoItem>
                <StreamInfoLabel>
                Total tokens to vest
                </StreamInfoLabel>
                <StreamInfoValue>
                    <img src={shellCoin} alt="shell logo" />
                    {sumAllocation(selectedStreams ?? [])} SHELL
                </StreamInfoValue>
            </StreamInfoItem>
        </StreamInfoDiv>
        </Wrapper>
    )
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  align-items: center;

  width: 100%;
  padding: 24px 28px 24px 40px;
  padding-top: 24px;
  border-top: 1px solid #1e2239;
  background-image: linear-gradient(180deg, #0f0f29, transparent);

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;
      row-gap: 20px;
      padding: 20px 12px;
    `};
`;

const TokenDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 0;
  
  ${props =>
    props.className?.includes('shrunk') &&
    css`
      gap: 12px;
    `};
`;

const StreamInfoDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 24px;
margin-right: 55px;

${props =>
    props.className?.includes('shrunk') &&
    css`
      width: 100%;
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: unset;
    `};
`;

const StreamInfoItem = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 9px;
`;

const StreamInfoLabel = styled.div`
color: var(--grey-3, #7D7D97);
text-align: right;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

const StreamInfoValue = styled.div`
color: var(--White, #FFF);
text-align: right;
display: flex;
align-items: center;

/* Text 2/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;

span {
    color: var(--grey-3, #7D7D97);

/* Text 2/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;
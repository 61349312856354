import React, { ReactNode, useContext } from "react";
import styled from "styled-components";
import { PoolsTableRowMobile } from "./PoolsTableRowMobile";
import { formatDisplay, formatDisplayShorthand } from "@/utils/formatDisplay";
import { CustomTooltip } from "@/components/CustomTooltip/CustomTooltip";
import { LoadingRows } from "@/components/Table/GenericTable";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface LBPTableMobileProps {
  data: Array<object>;
  activeView: string;
  isLoading?: boolean;
}

export const PoolsTableMobile = ({ data, activeView, isLoading }: LBPTableMobileProps) => {
  const { tokenMap } = useContext(ChainContext)
  return (
    <LBPTableContainerStyled>
      {isLoading && <LoadingRows isLoading={true} rows={8} padding="100px 0 22px" />}
      {data
        ? data?.map(({ name, subname, liquidity, volume, apy, rewardAPY, status, marketCap }: any) => {
            const data: {
              icon: string;
              name: string;
              subname: string;
              info?: { title: string; value: string | ReactNode; color?: string }[];
            } = {
              icon: tokenMap[name]?.icon,
              name: name,
              subname: subname,
            };
            if (activeView === "LP Tokens")
              data.info = [
                {
                    title: "APY",
                    value: 
                    <APY color={apy > 0 ? "#7ADEB9" : "white"}>
                        {`${apy > 1 ? formatDisplay((((apy - 1) + rewardAPY) * 100).toString(), 2) : '--'}%`}
                        {apy > 1 && rewardAPY > 0 && 
                        <CustomTooltip
                          position={"right"}
                          title="APY Breakdown"
                          subtext={
                          <div style={{display: "flex", flexDirection: 'column', alignItems: 'baseline', paddingBottom: '42px'}}>
                              <p>{`Trading Fees: ${formatDisplay(((apy - 1) * 100).toString(), 2)}%`}</p> 
                              <p>{`SHELL Rewards: ${formatDisplay((rewardAPY * 100).toString(), 2)}%`}</p>
                          </div>
                          }
                          delay={50}
                        />
                        }
                    </APY>,
                    color: '#7ADEB9'
                },
                {
                  title: "Liquidity",
                  value: `$${formatDisplayShorthand(liquidity ?? 0)}`,
                },
                {
                  title: "24h Vol",
                  value: `$${formatDisplayShorthand(volume ?? 0)}`,
                },
              ];
            else 
              data.info = [
                {
                    title: "Status",
                    value: status,
                },
                {
                  title: "Market Cap",
                  value: `$${formatDisplayShorthand(marketCap ?? 0)}`,
                },
                {
                  title: "Volume",
                  value: `$${formatDisplayShorthand(volume ?? 0)}`,
                },
              ];
            return <PoolsTableRowMobile {...data} key={name + subname} />;
          })
        : null}
    </LBPTableContainerStyled>
  );
};

const LBPTableContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const APY = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ color }) => color};
  gap: 4px;
`;
import React, { useState } from 'react'
import styled, { css } from "styled-components"
import { formatDisplay } from '../../../../utils/formatDisplay'
import { Media } from '../../../../styles'
import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import smallShellLogo from "@/assets/icons/small-shell-logo.svg";
import { CustomModal } from '@/components/CustomModal/CustomModal'
import shellLogoWhite from "@/assets/icons/shell-logo-white.svg";
import { StakeModalBody } from '../modals/StakeModalBody'
import { useRewardsContext } from '../../RewardsContext'
import { StakeConfirmationModalBody } from '../modals/StakeConfirmationModalBody'
import { AlertModalBody } from '../modals/AlertModalBody'
import shellIcon from '@/assets/coins/SHELL.svg'
import { ClaimTokenModalBody } from '../modals/ClaimTokenModalBody';

export const ClaimHeader = () => {
  const {
    isClaimModalOpen, 
    closeClaimModal, 
    handleOpenClaimStakeModal, 
    isClaimStakeModalOpen,
    handleCloseClaimStakeModal,
    myRewards,
    isOpenStakeConfirmationModal,
    handleCloseStakeConfirmationModal,
    isOpenAlertModal,
    handleCloseAlertModal,
    handleCloseIncreaseLockPeriodModal,
    isIncreaseLockPeriodModalOpen,
    isOpenChangeLockPeriodConfirmationModal,
    handleCloseChangeLockPeriodConfirmationModal,
    unclaimedShell,
    handleClaimShell,
    isShellClaimModalOpen,
    handleCloseShellClaimModal,
    shellClaimSuccess,
    shellClaimTxSubmitted,
    savedLockPeriod
  } = useRewardsContext();

  if(unclaimedShell == 0) return <></>

  const shellToken = {
    symbol: 'SHELL',
    icon: shellIcon,
    name: 'Shell'
  }

  return (
    <>
      <PointsDetailsWrapper>
        <FirstRowWrapper>
          <GeneralInfoWrapper>
            <SmallSubtitle>Available to claim</SmallSubtitle>
            <ShellTokensValueWrapper>
              <img src={shellIcon}/>
              <ShellValue>{formatDisplay(unclaimedShell.toString(), 2)}</ShellValue>
            </ShellTokensValueWrapper>
          </GeneralInfoWrapper>
          <ActionWrapper>
            {(savedLockPeriod == undefined || savedLockPeriod.endTime > new Date().getTime() / 1000) && <CustomPrimaryBtn onClick={() => handleOpenClaimStakeModal(-1, unclaimedShell)}>Stake</CustomPrimaryBtn>}
            <CustomSecondaryBtn onClick={handleClaimShell}>Claim</CustomSecondaryBtn>
          </ActionWrapper>
        </FirstRowWrapper>
      </PointsDetailsWrapper>
      <CustomModal
        isOpen={isShellClaimModalOpen}
        onClose={() => handleCloseShellClaimModal()}
        mobileStyle={{ minHeight: "50%", maxHeight: "100%" }}
        withBackdropBlur
        withOutsideClick
      >
        <ClaimTokenModalBody 
          token={shellToken} 
          unclaimedAmount={unclaimedShell} 
          handleCloseClaimModal={handleCloseShellClaimModal} 
          claimSuccess={shellClaimSuccess} 
          claimTxSubmitted={shellClaimTxSubmitted} 
        />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isClaimStakeModalOpen}
        onClose={() => handleCloseClaimStakeModal()}
        withOutsideClick
        withBackdropBlur
        width="654px"
      >
        <StakeModalBody isClaimVariant />
      </CustomModal>
      <CustomModal
          mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
          isOpen={isOpenStakeConfirmationModal}
          onClose={() => handleCloseStakeConfirmationModal()}
          withOutsideClick
          withBackdropBlur
        >
          <StakeConfirmationModalBody isClaimVariant />
        </CustomModal>
        <CustomModal
          mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
          isOpen={isOpenAlertModal}
          onClose={() => handleCloseAlertModal()}
          withOutsideClick
          withBackdropBlur
        >
          <AlertModalBody isClaimVariant/>
        </CustomModal>
    </>
  )
}

const FirstRowWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

${Media.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }
`;

const GeneralInfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 12px;
`;

export const HrDiv = styled.div`
height: 1px;
width: 100%;
background: #171B33;
`;

const ActionWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 16px;

${Media.tablet} {
    width: 100%;
  }
`;

const CustomPrimaryBtn = styled(ButtonPrimary)`
width: 140px;
border-radius: 12px;
height: unset;
padding: 16px 20px;

${Media.tablet} {
    width: 100%;
  }
`;

const CustomSecondaryBtn = styled(ButtonSecondary)`
width: 140px;
border-radius: 12px;
height: unset;
padding: 16px 20px;
border-width: 2px;

${Media.tablet} {
    width: 100%;
  }
`;

const SmallSubtitle = styled.p`
  color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

const ShellTokensValueWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;

img {
  width: 24px;
  height: 24px;
}
`;

const ShellIcon = styled.div`
position: relative;
width: 28px;
height: 28px;

&::before{
  content: url(${smallShellLogo});
  height: 28px;
  width: 28px;
  position: absolute;
  top: 0;
}

&::after {
  content: url(${shellLogoWhite});
  height: 18px;
  width: 13px;
  position: absolute;
  top: 2px;
  left: 6px;
}
`;

const ShellValue = styled.p`
color: #FFF;

text-align: center;
/* Title 5 */
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.9px;
`;

const SecondRowWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;
width: 100%;
gap: 8px;

${Media.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }
`;

const InfoItem = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 8px;

${Media.tablet} {
    justify-content: space-between;
    width: 100%;
  }
`;

const InfoItemLabel = styled.p`
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 19.04px */
`;

const InfoItemValue = styled.p`
color: var(--White, #FFF);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 136%; /* 19.04px */
`;

const PointsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  row-gap: 12px;
  width: 100%;
  max-width: 100%;
`

const PointsDetailsWrapper = styled.div`
  display: flex;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
align-self: stretch;
border-radius: 16px;
border: 1px solid var(--dark-2, #171B33);
background: var(--dark-4, #151530);
backdrop-filter: blur(27px);
margin-top: 24px;
margin-bottom: 24px;

  ${Media.tablet} {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    column-gap: unset;
    padding: 16px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;

  & > svg {
    width: 18px;
    height: 18px;
    color: #00BDFF;
    margin: 0 8px;
}

& > img {
    width: 18px;
    height: 18px;
    color: #00BDFF;
    margin: 0 8px;
}
`

const Subtitle = styled.h4`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #7D7D97;

  ${Media.tablet} {
    font-size: 12px;
    line-height: 14px;
  }
`

const HeaderSubtitle = styled(Subtitle)`
  ${Media.tablet} {
    font-size: 14px;
    line-height: 16px;
  }
`

const ValueColumn = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  column-gap: 12px;
  margin-top: 12px;

  & > svg {
    color: #00BDFF;
  }
`

const Value = styled.span<{ num: number }>`
  font-size: 46px;
  line-height: 56px;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: #ffffff;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ num }) => num >= 100000000 && css`
    font-size: 42px;
  `};
`

const BoostValue = styled.p`
  font-size: 13px;
  color: #ffffff;
  font-weight: 100;
`

// const Statistics = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   column-gap: 24px;
//   row-gap: 24px;
//   width: fit-content;
//   max-width: 100%;
//   margin: 16px 0 8px;
//   height: fit-content;

//   & > :first-child {
//     justify-self: end;
//   }

//   & > :nth-child(3) {
//     justify-self: end;
//   }

//   ${Media.tablet} {

//     & > :first-child {
//         justify-self: start;
//     }

//     & > :nth-child(3) {
//       justify-self: start;
//     }
//   }
// `

const Statistics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  column-gap: 24px;
  row-gap: 24px;
  width: fit-content;
  max-width: 100%;
  margin: 16px 0 8px;
  height: fit-content;

  ${Media.smallDesktop} {
    justify-items: start;
  }
`

const StatisticsItem = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  column-gap: 8px;
  row-gap: 4px;
  overflow: hidden;

  ${Media.tablet} {
    display: grid;
    grid-template-columns: 20px auto;
    grid-template-areas: 
      'title title'
      'image value';
  }
`

const StatisticsTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #7D7D97;
  
  ${Media.tablet} {
    grid-area: title;
    font-size: 12px;
    line-height: 18px;
  }
`

const StatisticsImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  color: #00BDFF;
  overflow: hidden;

  & > img,
  & > svg {
    max-width: 100%;
    max-height: 100%;
    color: inherit;
  }

  ${Media.tablet} {
    grid-area: image;
  }
`

const ClaimButton = styled.button`
  width: 72px;
  height: 32px;

  margin-left: 8px;

  &:hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    border: solid 1.5px #000E47;
    color: #000E47;
  }

  border: solid 1.5px #00BDFF;
  border-radius: 8px;
  color: #00BDFF;
`;

const ExpandButton = styled.button`
  align-items: center;
  width: 20px;
  height: 20px;

  &:hover {
    opacity: 0.7;
  }
`;

const TooltipBox = styled.div<{ visible: boolean }>`
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  margin-top: 12px;
  margin-left: -112px;
  column-gap: 4px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 174px;
  padding: 4px 4px;
  border-radius: 8px;
  box-shadow: 0 0px 6px #00BDFF;
  background-color:  #1E2239;

  ${({ visible }) => visible && css`
    visibility: visible;
  `};

  ${Media.tablet}{
    margin-left: -26px;
  }

`;

const StatisticsValue = styled.span`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #FFFFFF;
  
  ${Media.tablet} {
    grid-area: value;

    font-size: 16px;
    line-height: 20px;
  }
`

const Artifact = styled.img<{ chad?: boolean }>`
    width: 36px;
    border-radius: 6px;
    border: 1px solid #1E2239;

    ${({ chad }) => chad && css`
        box-shadow: 0 0px 4px #0ADD08;
    `};
`

const ForecastLoader = styled.img`
  width: 28px;
  margin-bottom: 4px;
`;
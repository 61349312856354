import React from "react";
import styled from "styled-components";
import { BarChart } from "recharts";
import { Media } from "../../../../../styles";

const StyledBarChartWrapper = styled.div`
  tspan {
    font-size: 12px;
    line-height: 14px;
  }
`;

const CustomBarChart = (props: any) => {
  return (
    <StyledBarChartWrapper>
      <BarChart {...props} />
    </StyledBarChartWrapper>
  );
};

export default CustomBarChart;

import React, { useContext } from "react";
import styled from "styled-components";
import { Media, breakpoints } from "../../styles";
import {
  Area,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getFormattedChartDate } from "../../utils";
import { useWidthBreakpoint } from "../../hooks";
import { TableFilterDropdown } from "../Table/TableFilterDropdown";
import CustomAreaChart from "../../pages/Rewards/components/Points/components/CustomAreaChart";
import { tokenColors } from "@/constants/tokenColors";
import { stripNonAlphabeticCharacters } from "@/pages/Rewards/components/Points/PointsChart";
import { isShellToken } from "@/utils/tokens";
import CustomBarChart from "@/pages/Rewards/components/Points/components/CustomBarChart";
import { ChainContext } from "../Overlays/ChainProvider";

interface TokenWithPoints {
  name: string;
  subname: string;
  color: string;
  balance: number;
  value: number;
  multiplier: number;
  dailyPoints: number;
}

interface TokensPerData {
  date: number;
  tokens: TokenWithPoints[];
}

const tokenLookup: any = {};

export const BarChart = ({
  header,
  allowDuplicatedCategory,
  tooltip,
  areas,
  withoutLegend,
  tokenName,
  tokenColor,
  tokens,
  prefixTick,
}: any) => {

  const { tokenMap } = useContext(ChainContext)

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const chartData: any = [];

  if (tokens) {
    // Sort tokens by time that user started earning points
    const tokenNames = Object.keys(tokens)
      .filter((token) => token !== "compoundedPoints")
      .map((name) => {
        let historical = tokens[name]["historical"] || {};
        return {
          token: name,
          time:
            Object.keys(historical)[
              Object.values(historical).findIndex((value: any) => value > 0)
            ] ?? 0,
        };
      })
      .sort((a: any, b: any) => a.time - b.time)
      .map((value) => value.token);

    const dateTokenPoint: { [date: string]: TokenWithPoints[] } = {};

    tokenNames.forEach((token) => {
      let tokenData = tokens[token];
      let tokenInfo = tokenMap[token];

      tokenLookup[stripNonAlphabeticCharacters(token)] = {
        name: token,
        color: isShellToken(tokenInfo)
          ? tokenColors["shLP"]
          : tokenColors[token],
      };

      Object.keys(tokenData?.historical).forEach((date) => {
        if (!(date in dateTokenPoint)) {
          dateTokenPoint[date] = [];
        }

        const tokenPoint = {
          name: token,
          subname: isShellToken(tokenInfo) ? tokenInfo.oceanID : tokenInfo?.name,
          color: isShellToken(tokenInfo)
            ? tokenColors["shLP"]
            : tokenColors[token],
          balance: tokenData.balance,
          value: tokenData.value,
          multiplier: tokenData.multiplier,
          dailyPoints: tokenData.historical[date],
        };

        dateTokenPoint[date].push(tokenPoint);
      });
    });

    if (tokens.compoundedPoints) {
      Object.keys(tokens.compoundedPoints.historical).forEach((date) => {
        if (!(date in dateTokenPoint)) {
          dateTokenPoint[date] = [];
        }
        const tokenPoint = {
          name: "compoundedPoints",
          subname: "compoundedPoints",
          color: "#0ABCFD",
          balance: 0,
          value: 0,
          multiplier: 0,
          dailyPoints: tokens.compoundedPoints.historical[date],
        };

        dateTokenPoint[date].push(tokenPoint);

        tokenLookup.compoundedpoints = {
          name: "Compounded Points",
          color: "#0ABCFD",
        };
      });
    }

    const tokensPerDayData: TokensPerData[] = Object.keys(dateTokenPoint).map(
      (date) => {
        return {
          date: parseInt(date),
          tokens: dateTokenPoint[date],
        };
      }
    );

    for (let i in tokensPerDayData) {
      let obj = tokensPerDayData[i];
      let newobj: any = {
        date: obj.date,
      };
      for (let j in obj.tokens) {
        let m = obj.tokens[j];
        newobj[m.name ? stripNonAlphabeticCharacters(m.name) : `token${j}`] =
          m.dailyPoints;
      }
      chartData.push(newobj);
    }
  }

  const data = [
    { price: 0.16, value: 0.40 },
    { price: 0.25, value: 0.50 },
    { price: 0.33, value: 0.57 },
    { price: 0.44, value: 0.67 },
    { price: 0.64, value: 0.80 },
    { price: 0.83, value: 0.91 },
    { price: 1.00, value: 1.00 },
    { price: 1.21, value: 1.10 },
    { price: 1.56, value: 1.25 },
    { price: 2.25, value: 1.50 },
    { price: 3.06, value: 1.75 },
    { price: 4.00, value: 2.00 },
    { price: 6.25, value: 2.50 }
  ]

  const minValue = Math.min(...data.map((entry) => entry.value));
  const maxValue = Math.max(...data.map((entry) => entry.value));

  const yDomain = [minValue - (0.0001 * minValue), maxValue];

  return (
    <PointsChartWrapper>
      {header}
      <StyledResponsiveContainer width="100%" height={261}>
        <StyledBarChart
          data={data}
          margin={{
            top: isTablet ? 0 : 8,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="price"
            axisLine={false}
            tickLine={false}
            padding={{ left: 0, right: isTablet ? 0 : 12 }}
            interval="preserveStartEnd"
            color={"#7d7d97"}
            fill={"#7d7d97"}
            scale={"utc"}
            // tickFormatter={(date) => getFormattedChartDate(date)}
            allowDuplicatedCategory={
              false
            }
          />
          <YAxis
            orientation="right"
            tickFormatter={(tick) => `${prefixTick || ""}${tick.toFixed(5)}`}
            axisLine={false}
            tickLine={false}
            domain={yDomain}
            color="#7d7d97"
            fill={"#7d7d97"}
            padding={{ top: isTablet ? 0 : 4 }}
          />
          {/* <Tooltip cursor={{ stroke: '#171B33', strokeWidth: 2 }} content={tooltip} /> */}
          {areas || (
            <Bar
              data={data}
              dataKey="value"
              fill={`${tokenColor}`}
              key={tokenName?.toLowerCase()}
              fillOpacity={1}
              radius={6}
              barSize={8}
              maxBarSize={8}
            />
          )}
        </StyledBarChart>
      </StyledResponsiveContainer>
      {withoutLegend ? null : (
        <Legend>
          <LegendItem>
            <Line />
            Price
          </LegendItem>
          <LegendItem>
            <DashLine />
            Future Price
          </LegendItem>
        </Legend>
      )}
    </PointsChartWrapper>
  );
};

const PointsChartWrapper = styled.section`
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 24px 12px 20px 24px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;
  //   overflow: hidden;
  //   .recharts-layer.recharts-bar {
  //     transform: translateX(-38px);
  //   }
  .xAxis {
    .recharts-cartesian-axis-tick {
      &:last-child {
        // text {
        //   transform: translateX(-14px);
        // }
      }
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: #7d7d97;
    }
  }

  ${Media.tablet} {
    padding: 12px;
    padding-right: 0;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 16px;
`;

const StyledBarChart = styled(CustomBarChart)`
  //   .recharts-xAxis {
  //     transform: translateY(8px);
  //   }

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  //   ${Media.tablet} {
  //     .recharts-xAxis {
  //       transform: translateY(0px);
  //     }
  //   }
`;

const Legend = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;

const Line = styled.div`
  width: 24px;
  height: 0px;
  border: 2px solid #00bdff;
`;

const DashLine = styled.div`
  width: 24px;
  height: 0px;
  border: 2px dashed #87c0dd;
`;

const Button = styled.div`
  background: #171b33;
  border-radius: 10px;
`;

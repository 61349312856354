import React, { Fragment, useLayoutEffect, useState } from "react";
import {
  Column,
  useExpanded,
  useFilters,
  useSortBy,
  useTable,
} from "react-table";
import classNames from "classnames";
import { Table, TableRow, TableSubRow } from "./StyledTable";
import { SortIcon } from "../Icons/SortIcon";
import styled from "styled-components";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { useLeaderboard } from "@/pages/Explore/ExploreContext";

export const LoadingRows = ({
  isLoading,
  padding,
  rows,
  style
}: {
  isLoading: boolean;
  padding?: string;
  rows?: number;
  style?: React.CSSProperties;
}) => {
  if (!isLoading) return null;
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", ...style }}>
      {Array.from({ length: rows || 3 }, (_, rowIndex) => (
        <SkeletonBox
          isLoading={true}
          borderRadius="20px"
          padding={padding || "40px 0px 22px"}
          style={{ marginBottom: "10px" }}
        />
      ))}
    </div>
  );
};

interface TableProps {
  id?: string;
  columns: Array<Column<object>>;
  data: Array<object>;
  renderRowSubComponent?: any;
  onRowClick?: (id: string) => void;
}

export const GenericTable = ({
  id,
  columns: Columns,
  data,
  renderRowSubComponent,
  onRowClick,
}: TableProps) => {
  const [expanded, setExpanded] = useState<{ [id: string]: boolean }>({});

  const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

  const tableInstance = useTable(
    {
      columns: Columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    useExpanded
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    toggleRowExpanded,
  } = tableInstance;

  useLayoutEffect(() => {
    rows.forEach((row) =>
      toggleRowExpanded([row.id], expanded[row.id] == true)
    );
  }, [expanded]);

  useLayoutEffect(() => {
    const newExpanded = { ...expanded };
    rows.forEach((row) => (newExpanded[row.id] = false));
    setExpanded(newExpanded);
  }, [data]);

  const onTableRowClick = (id: string) => {
    const newExpanded = { ...expanded };
    newExpanded[id] = !expanded[id];
    setExpanded(newExpanded);
  };

  const { leaderboardLoading } = useLeaderboard();

  return (
    <Fragment>
      <TableStyled {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.canSort && column.Header == "Timestamp" && (
                    <SortIcon
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                    />
                  )}
                  {column.canFilter && <div>{column.render("Filter")}</div>}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            const tableRowClassname = classNames({
              expanded: row.isExpanded,
              hoverable: !row.isExpanded,
              active: id && row.original.id == id,
            });
            return (
              <Fragment key={row.id}>
                <TableRow
                  {...row.getRowProps()}
                  onClick={() =>
                    !renderRowSubComponent && onRowClick
                      ? onRowClick(row.original.clickLink)
                      : onTableRowClick(row.id)
                  }
                  className={tableRowClassname}
                >
                  {row.cells.map((cell: any) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </TableRow>
                {row.isExpanded && (
                  <TableSubRow>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </TableSubRow>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </TableStyled>
      {leaderboardLoading && !data && <LoadingRows isLoading={leaderboardLoading} />}
    </Fragment>
  );
};

const TableStyled = styled(Table)`
  th {
    text-align: left;
    padding-left: 20px;
  }

  td {
    & > * {
      margin-left: 20px;
    }
  }

  th:first-child {
    min-width: 40px;
    width: 14%;
    padding-left: 20px;
  }

  th:nth-child(2) {
    min-width: 120px;
    width: 12%;
  }

  th:nth-child(3) {
    min-width: 160px;
    width: 12%;
  }

  th:nth-child(4) {
    min-width: 100px;
    width: 8%;
  }

  th:nth-child(5) {
    min-width: 100px;
    width: 13%;
    text-align: left;
    padding-right: 0;
  }

  th:nth-child(6) {
    min-width: 100px;
    width: 9%;
    text-align: left;
    padding-right: 0;
  }

  th:last-child {
    text-align: left;
    width: 4%;
  }

  td:last-child {
    padding-right: 20px;
    text-align: right;
    & > * {
      margin-left: 0;
    }
  }
`;

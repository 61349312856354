import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RightPointCaretIcon } from "@/components/Icons/RightPointCaretIcon";
import { Media } from '@/styles';

interface LinkButtonProps {
  outline: boolean | undefined;
  arrow?: boolean;
  to: string;
  label: string;
  textColor?: string;
  borderColor?: string;
  onClick?: () => void;
  state?: any;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  outline = false,
  arrow = false,
  to,
  label,
  textColor = "#ffffff",
  borderColor = "#37dcf2",
  onClick,
  state,
}) => {
  return (
    <StyledLinkButton
      to={to}
      outline={outline ? "true" : undefined}
      textcolor={textColor}
      bordercolor={borderColor}
      state={state}
      onClick={onClick}
    >
      {label}
      {arrow && (
        <RightPointCaretIcon color={textColor} />
      )}
    </StyledLinkButton>
  );
};

const StyledLinkButton = styled(Link).attrs<{ state?: any }>(({ state }) => ({
  state: state,
}))<{
  outline: string | undefined;
  textcolor: string;
  bordercolor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  padding: 12px 24px;

  color: ${({ textcolor }) => textcolor};
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background: ${({ outline, bordercolor }) => outline === "true" ? "transparent" : bordercolor}; 
  border: ${({ bordercolor }) => `2px solid ${bordercolor}`};
  
  ${Media.tablet} {
    padding: 10px 20px;
    font-size: 14px;
  }

  ${Media.mobile} {
    font-size: 4vw;
  }

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;

import React from 'react'

interface ArrowIconProps {
  bridge?: boolean;
}

export const ArrowIcon = ({ bridge }: ArrowIconProps) => {
  return bridge ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12.0001 19.1638L18.2072 12.9567L16.793 11.5425L12.0001 16.3354L7.20718 11.5425L5.79297 12.9567L12.0001 19.1638ZM12.0001 13.5139L18.2072 7.30679L16.793 5.89258L12.0001 10.6855L7.20718 5.89258L5.79297 7.30679L12.0001 13.5139Z" fill="url(#paint0_linear_8414_55519)" />
      <defs>
        <linearGradient id="paint0_linear_8414_55519" x1="5.90159" y1="5.89258" x2="15.3061" y2="9.00286" gradientUnits="userSpaceOnUse">
          <stop stop-color="#37DCF2" />
          <stop offset="1" stop-color="#07C0FB" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
    </svg>

  )
}
import React from "react";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import infoIcon from "@/assets/icons/info-icon.svg";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import * as S from "./ConfirmationModals.styled";
import { useRewardsContext } from "../../RewardsContext";
import crabIcon from '@/assets/seasons/CRAB.svg'
import { formatDisplay } from "@/utils/formatDisplay";

const InputCustomLabel = ({token} : any) => (
    <S.CustomInputLabelContainer>
        <S.CoinIcon src={token.icon} alt="crab coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>{token.symbol}</S.InputTokenName>
                <S.InputTokenSymbol>{token.name}</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

export const ClaimTokenModalBody = ({token, unclaimedAmount, claimSuccess, handleCloseClaimModal, claimTxSubmitted}: any) => {
   
    return (
        <S.ConfirmationBodyContainer hasMask={claimSuccess}>
            {claimSuccess ? (
                <>
                    <S.SuccessIcon src={successIcon} alt="success icon" />
                    <S.SuccessTextWrapper>
                        <S.SuccessTitle>Claim successful!</S.SuccessTitle>
                        <S.SuccessDescription>
                        Your 
                        <span> {formatDisplay(unclaimedAmount.toString(), 2)} </span>
                        {token.symbol} has been successfully claimed
                        </S.SuccessDescription>
                    </S.SuccessTextWrapper>
                    <Input label="Rewards" disabled value={formatDisplay(unclaimedAmount.toString())} additionalLabel={<InputCustomLabel token={token}/>} />
                    <S.CustomPrimaryBtn style={{marginTop: "16px"}} onClick={handleCloseClaimModal}>Close</S.CustomPrimaryBtn>
                </>
            ) : (
                <>
                    <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                    <S.LoaderLabelContainer>
                        <S.LoaderLabel>
                            Claiming your {token.symbol}...
                        </S.LoaderLabel>
                        <S.LoaderSublabel>
                            You're claiming {formatDisplay(unclaimedAmount.toString(), 2)} {token.symbol}
                        </S.LoaderSublabel>
                    </S.LoaderLabelContainer>
                    <Input label="Rewards" disabled value={formatDisplay(unclaimedAmount.toString())} additionalLabel={<InputCustomLabel token={token}/>} />
                    {!claimTxSubmitted && 
                    <S.InfoLabelContainer>
                        <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                        <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                    </S.InfoLabelContainer>
                    }
                </>
            )}
        </S.ConfirmationBodyContainer >
    )
};
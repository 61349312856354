import React from 'react'

export const PointsIcon = ({ color = 'currentColor' }) => {
  return (
    <svg width="28" height="28" x="0px" y="0px" viewBox="0 0 26.7 24" fill="none" color={color}>
      <path d="M0,8.7V1.3C0,0.6,0.6,0,1.3,0h24c0.7,0,1.3,0.6,1.3,1.3l0,0v7.3c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3c0,0,0,0,0,0v7.3  c0,0.7-0.6,1.3-1.3,1.3h-24C0.6,24,0,23.4,0,22.7v-7.3c1.8,0,3.3-1.5,3.3-3.3S1.8,8.7,0,8.7C0,8.7,0,8.7,0,8.7z M2.7,6.6  c3,1.5,4.2,5.1,2.7,8c-0.6,1.2-1.5,2.1-2.7,2.7v4H24v-4c-3-1.5-4.2-5.1-2.7-8c0.6-1.2,1.5-2.1,2.7-2.7V2.7H2.7V6.6z" fill={color} />
      <path d="M13.3,17.9c-4.3,0-4.9-2.4-4.9-4.7c0-2,3-7.6,4.9-7.6s4.9,5.5,4.9,7.6C18.2,15.5,17.6,17.9,13.3,17.9z M13.3,7.7  c-0.9,0.9-2.9,4-2.9,5.4c0,1.8,0.1,2.7,2.9,2.7c2.8,0,2.9-1,2.9-2.7C16.2,11.8,14.2,8.6,13.3,7.7z" fill={color} />
    </svg>
  )
}

import { formatDisplay } from '@/utils/formatDisplay';
import React from 'react'
import styled, { css } from 'styled-components'

export const MultiplierCell = ({ value }: any) => <APY glow={value > 0} color={value > 0 ? "#7ADEB9" : "white"}>{`${value > 0 ? formatDisplay((value * 100).toString(), 2) : '--'}%`}</APY>

const APY = styled.div<{ color: string, glow: boolean }>`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ color }) => color};

  ${({ glow }) => glow && css`
    -webkit-animation-name: glow;
    -moz-animation-name: glow;
    animation-name: glow;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    @keyframes glow {
        0% {
            text-shadow: 0 0 6px #00BDFF;
        }
        
        50% {
            text-shadow: 0 0 14px #00bdff;
        }

        100% {
            text-shadow: 0 0 6px #00bdff;
        }
    }
  `};
`;
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Media, breakpoints } from "../../styles";
import { FilterSeasonDropdown } from "../Rewards/components/Points/components/FilterSeasonDropdown";
import { protocolFilters, dateFilters, DateFilter, ProtocolFilter } from "../../types/ChartTypes";
import { useWidthBreakpoint } from "@/hooks";
import CustomBarChart from "@/pages/Rewards/components/Points/components/CustomBarChart";
import { ExternalDefiToken } from "@/utils/tokens";
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { generateColorHex } from "@/components/DexBadge/DexBadge";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { getFormattedChartDayDate, getFormattedChartDate, getFormattedChartFullDate } from "@/utils";
import { addPeriod } from "./TVLChart";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface VolumeChartProps {
  title: string;
  volumeData: PoolData[];
  loading?: boolean;
}

type PoolData = {
  date: number;
  pools: { [key: string]: number };
};

interface VolumeChartData {
  volumeChart: PoolData[];
}

interface DateInterval {
  start: number;
  end: number;
}

interface FlatDataPoint {
  date: number;
  [key: string]: number | undefined; 
}

export const createDateIntervals = (startDate: Date, selectedDateFilter: DateFilter, currentDate: Date): DateInterval[] => {
  const intervals: DateInterval[] = [];
  let nextDate = new Date(startDate.setHours(0, 0, 0, 0));

  currentDate.setHours(0, 0, 0, 0);

  while (nextDate < currentDate) {
    let intervalEnd = addPeriod(new Date(nextDate), selectedDateFilter.period);
    intervalEnd.setHours(0, 0, 0, 0);

    if (intervalEnd > currentDate) {
      intervalEnd = new Date(currentDate.setHours(0, 0, 0, 0));
    }

    const startTimestamp = Math.floor(nextDate.getTime() / 1000);
    const endTimestamp = Math.floor(intervalEnd.getTime() / 1000);

    intervals.push({ start: startTimestamp, end: endTimestamp });

    nextDate = new Date(intervalEnd);
  }

  return intervals;
};

export const VolumeChart = (props: VolumeChartProps) => {
  const { title, volumeData, loading } = props;
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const [selectedDateFilter, setSelectedDateFilter] = useState<string>("3m");
  const [protocolFilter, setProtocolFilter] = useState<ProtocolFilter>({ label: "All", value: "all" });
  const [chartData, setChartData] = useState<FlatDataPoint[]>([]);

  const { tokenMap } = useContext(ChainContext)

  const aggregateByDateAndProtocol = (volumeData: PoolData[], startDate: Date, selectedDateFilter: DateFilter, protocolFilter: ProtocolFilter): FlatDataPoint[] => {
    const intervals = createDateIntervals(startDate, selectedDateFilter, new Date());
    return intervals.map(interval => {
      const summary: Record<string, number> = {};
  
      volumeData.filter(data => data.date >= interval.start && data.date < interval.end).forEach(({ pools }) => {
        Object.entries(pools).forEach(([poolName, volume]) => {
          const poolInfo = tokenMap[poolName] as ExternalDefiToken | undefined;
          const protocolName = poolInfo?.tokenType;
          if (!protocolName || (protocolFilter.label !== "All" && protocolFilter.label !== protocolName)) return;
  
          const protocolKey = protocolFilter.label === "All" ? protocolName : `${protocolName}: ${poolName}`;
          summary[protocolKey] = (summary[protocolKey] || 0) + volume;
        });
      });
  
      const flatData: FlatDataPoint = {
        date: interval.start,
        ...Object.keys(summary).sort((a, b) => summary[b] - summary[a]).reduce((acc, key) => {
          acc[key] = summary[key];
          return acc;
        }, {} as Record<string, number>)
      };
  
      return flatData;
    });
  };
  
  const generateGradientsAndBars = () => {
    const bars: JSX.Element[] = [];
    const uniqueIdentifiers = new Set();
    chartData.forEach(dataPoint => {
      Object.keys(dataPoint).forEach(key => {
        if (key !== "date") {
          uniqueIdentifiers.add(key);
        }
      });
    });
  
    uniqueIdentifiers.forEach((protocolOrPool: any) => {
      const isPool = protocolOrPool.includes(":");
      const protocolName = isPool ? protocolOrPool.split(":")[0] : protocolOrPool;
      const baseColor = generateColorHex(protocolName);
      
      const index = Array.from(uniqueIdentifiers).indexOf(protocolOrPool); 
      const positionRatio = index / (uniqueIdentifiers.size - 1);
      const opacityIncrement = 0.8 * positionRatio; 
      const opacity = isPool ? 0.2 + opacityIncrement : 1;
        
      if (!bars.some(bar => bar.props.dataKey === protocolOrPool)) {
        bars.push(
          <Bar
            dataKey={protocolOrPool}
            fill={baseColor}
            stackId="a"
            fillOpacity={Math.min(opacity, 1)}
          />
        );
      }
    });
  
    return { bars };
  };

  const { bars } = generateGradientsAndBars();

  useEffect(() => {
    const currentDate = new Date();
    let startDate: Date;

    const selectedDate = dateFilters.find((filter) => filter.label === selectedDateFilter);
    if (selectedDate && selectedDate.getStart) {
      startDate = selectedDate.getStart(currentDate);
    } else {
      if (!volumeData) return;

      startDate = new Date(Math.min(...volumeData.map((entry) => entry.date * 1000)));
    }
    if (!volumeData) return;


    const res = aggregateByDateAndProtocol(volumeData, startDate, selectedDate ?? dateFilters[0], protocolFilter);
    setChartData(res);
  }, [selectedDateFilter, protocolFilter, volumeData]);
  
  return (
    <PointsChartWrapper>
      <ChartHeader>{title}</ChartHeader>
      <FiltersContainer>
        <FilterSeasonDropdown
          onChange={(filter) => setProtocolFilter(filter)}
          list={protocolFilters}
          currentFilter={protocolFilter}
        />
        <DateFiltersContainer>
          {dateFilters.map((item, index) => (
            <DateFilterButton
              key={index}
              onClick={() => setSelectedDateFilter(item.label)}
              active={item.label === selectedDateFilter}
            >
              {item.label}
            </DateFilterButton>
          ))}
        </DateFiltersContainer>
      </FiltersContainer>
      {loading ? <SkeletonBox isLoading={true} height={"261px"} width="100%" borderRadius="20px" style={{marginTop: "16px"}}/> : chartData.length > 0 ? 
        <StyledResponsiveContainer width="100%" height={261}>
          <StyledBarChart
            data={chartData}
            margin={{
              top: isTablet ? 0 : 8,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis
              dataKey="date"
              color={"#7D7D97"}
              axisLine={false}
              tickLine={false}
              padding={{ left: 0, right: isTablet ? 0 : 12 }}
              interval={
                isTablet || selectedDateFilter === "All"
                  ? "preserveStartEnd"
                  : 0
              }
              minTickGap={1}
              tickFormatter={(date) =>
                selectedDateFilter == "All"
                  ? getFormattedChartDate(date)
                  : getFormattedChartDayDate(date)
              }
              tickMargin={isTablet ? 10 : 0}
            />
            <YAxis
              orientation="right"
              color={"#7D7D97"}
              axisLine={false}
              tickLine={false}
              padding={{ top: isTablet ? 0 : 4, bottom: 0 }}
              tickFormatter={(tick) =>
                `$${formatDisplayShorthand(tick, 0, 1e3)}`
              }
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip protocolFilter={protocolFilter} selectedDateFilter={selectedDateFilter} />}
            />
            {bars}
          </StyledBarChart>
        </StyledResponsiveContainer>
       : <PointsChartPlaceholder>
          No volume data available.
        </PointsChartPlaceholder>
      }
    </PointsChartWrapper>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<any>;
  protocolFilter: ProtocolFilter;
  selectedDateFilter: string;
}

const CustomTooltip = ({ active, payload, protocolFilter, selectedDateFilter }: CustomTooltipProps) => {
  if (!active || !payload || payload.length === 0) return null;
  const formatFn = selectedDateFilter == 'All' ? getFormattedChartDate : getFormattedChartFullDate;
  const date = formatFn(payload[0].payload.date)

  const renderTooltipContent = () => {
    const sortedPayload = payload.sort((a, b) => b.value - a.value);

    if (protocolFilter.label === "All") {
      return (
        <TooltipContainer>
          {sortedPayload.map(({ name, value }) => (
            <TooltipRow key={name}>
              <TooltipRowText>
                <TooltipRowLabel color={generateColorHex(name)}>
                  {name}
                </TooltipRowLabel>: ${formatDisplayShorthand(value)}
              </TooltipRowText>
            </TooltipRow>
          ))}
        </TooltipContainer>
      );
    } else {
      const data = payload[0].payload;
      
      const sortedPools = Object.entries(data)
        .filter(([key]) => key.startsWith(`${protocolFilter.label}:`))
        .sort(([, valueA], [, valueB]) => (valueB as number) - (valueA as number));

      return (
        <TooltipContainer>
          {sortedPools.map(([key, value], index) => {
            const poolName = key.split(`${protocolFilter.label}:`)[1];
            return (
              <TooltipRow key={index}>
                <TooltipRowText>
                  <TooltipRowLabel color={generateColorHex(protocolFilter.label)}>
                    {poolName}
                  </TooltipRowLabel>: ${formatDisplayShorthand(value as number)}
                </TooltipRowText>
              </TooltipRow>
            );
          })}
        </TooltipContainer>
      );
    }
  };

  return (
    <TooltipContainer>
      <TooltipDate>{date}</TooltipDate>
      {renderTooltipContent()}
    </TooltipContainer>
  );
};

const PointsChartWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;

  .xAxis {
    .recharts-cartesian-axis-tick {
      &:last-child {
      }
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: #7d7d97;
    }
  }

  ${Media.tablet} {
    padding: 12px;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 16px;
`;

const StyledBarChart = styled(CustomBarChart)`
  tspan {
    font-size: 12px;
    line-height: 14px;
  }
`;

const ChartHeader = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;
  letter-spacing: -0.03em;

  ${Media.tablet} {
    font-size: 20px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  ${Media.tablet} {
    align-items: center;
    gap: 8px;
  }
`;

const DateFiltersContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const DateFilterButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 32px;
  background: #1e2239;
  border: 1px solid #292941;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ active }) => (active ? "#FFFFFF" : "#7D7D97")};

  &:hover {
    color: #ffffff;
  }

  ${Media.tablet} {
    width: 42px;
    height: 28px;
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  padding: 10px 12px;
  border-radius: 14px;
  background-color: #171b33;
`;

const TooltipDate = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #7d7d97;
`;

const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
`;

const TooltipRowIcon = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  color: #00bdff;

  & > svg {
    width: 12px;
    height: 12px;
    color: #00bdff;
  }
`;

const TooltipRowText = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
`;

const TooltipRowLabel = styled.span<{
  color?: string;
  capitalized?: boolean;
  opacity?: number;
}>`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${({ color }) => color ?? "inherit"};
  text-transform: ${({ capitalized }) => (capitalized ? "capitalize" : "none")};
`;

const PointsChartPlaceholder = styled.div`
  width: 100%;
  height: 280px;
  text-align: center;
  vertical-align: middle;
  line-height: 280px;

  a {
    text-decoration: underline;
    color: #00bdff;

    :visited {
      color: #00bdff;
    }
  }

  ${Media.mobile} {
    line-height: 24px;
    margin-top: 50%;
    font-size: 12px;
  }
`;

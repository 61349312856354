import { BigNumber } from 'ethers';
import React, { ReactNode, useEffect, useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import { StraightLoader } from '../../../../../components/Loaders';
import { OCEAN_ADDRESS } from '../../../../../constants/addresses';
import { NFT, NFT1155, isNFTCollection } from '../../../../../utils/tokens';
import { addNFTBalance } from '../../../../../store/balancesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { buildNFTDisplays, build1155Displays, getNFTs, WRAPPED_NFT_MAP } from '../../../../../utils/nftHelpers';
import { removeLeadingZeros } from '../../../../../utils/ocean/utils';
import { NFTsSwiper } from '../../../../Trade/NFTsSwiper/NFTsSwiper';
import { TokenProps } from '../PointsTable';
import { getTokenID } from '../../../../../utils/LiquidityGraph';
import { TransferToken } from './TransferToken';
import { LinkButton } from '@/components/Buttons/LinkButton';
import { ChainContext } from '@/components/Overlays/ChainProvider';

interface NFTTaleDetailProps {
  token: TokenProps;
}

export const NFTTaleDetails = ({ token }: NFTTaleDetailProps) => {
  const { address : walletAddress } = useAccount();
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const { tokenMap, poolQuery } = useContext(ChainContext)
    
  const [nfts, setNFTs] = useState<(NFT | NFT1155)[]>([]);
  const nft: any = tokenMap[token.name];

  useEffect(() => {
    let isMounted = true;
    const fetchNFTs = async () => {
      if (walletAddress) {
        
        const userOceanNFTs = (await getNFTs(walletAddress ?? '0xNull', OCEAN_ADDRESS))

        const collection = tokenMap[token.name]

        const wrappedIDs = WRAPPED_NFT_MAP[token.name]

        const userWrappedNFTs : any[] = []

        if(isNFTCollection(collection) && collection.is1155){
            userOceanNFTs.forEach((userOceanNFT : any) => {
                const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
                if(wrappedIDs[oceanID]) {
                    userWrappedNFTs.push({
                        id: parseInt(wrappedIDs[oceanID][0]),
                        balance: parseInt(userOceanNFT.balance)
                    })
                }    
            })

            const nonZeroBalanceIDs = new Set(userWrappedNFTs.map((nft) => nft.id))
            Array.from(Array(collection.totalSupply).keys()).forEach((id) => {
                if(!nonZeroBalanceIDs.has(id)){
                    userWrappedNFTs.push({
                        id: id,
                        balance: 0
                    })
                }
            })

            dispatch(
                addNFTBalance({
                    collection: collection.symbol,
                    items: userWrappedNFTs,
                })
            );

            setNFTs(build1155Displays(collection, userWrappedNFTs).filter(item => item.balance > 0))
        } else {
            userOceanNFTs.forEach((userOceanNFT : any) => {
                const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
                if(wrappedIDs[oceanID]) userWrappedNFTs.push(parseInt(wrappedIDs[oceanID][0]))
            })

            dispatch(
                addNFTBalance({
                    collection: collection.symbol,
                    items: userWrappedNFTs,
                })
            );
            // @ts-ignore
            setNFTs(await buildNFTDisplays(collection, userWrappedNFTs))
        }
      }
    };

    setLoading(true);

    fetchNFTs().then(() => {
      if (isMounted) setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [walletAddress, nfts.length]);

  return (
    loading ? (
      <LoaderContainer>
        <StraightLoader/>
      </LoaderContainer>
    ) : (
      <>
        <NFTsSwiper
            nftTokens={nfts}
            navitagionTransform={{ left: "0", right: "0" }}
        />
            <ButtonRow hasUserBalance={nfts.length > 0}>
              <ActionButton 
                to="/trade"
                outline={false}
                arrow={false} 
                textColor="#0B1639"
                state={poolQuery.generateBuySellTokens(nft, true)}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                label='Buy'
              />
              <ActionButton 
                to="/trade"
                outline={true}
                arrow={false} 
                state={poolQuery.generateBuySellTokens(nft, false)}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                label='Sell'
              />
              {nfts.length > 0 &&
                <TransferToken 
                    collection={nft} 
                    balance={nfts.length} 
                    wallet={token.wallet} 
                    nfts={nft.is1155 ? nfts : undefined} 
                    isNFT={true} 
                    is1155={nft.is1155}
                /> 
              }
            </ButtonRow>
      </>
    )
  )
}

interface DataItemProps {
  label: string
  value: ReactNode,
  color?: string
}

const StatsItem = ({ label, value, color }: DataItemProps) => (
  <StatsBlock>
    <Label>{label}:</Label>
    <Value color={color} style={{ marginRight: '12px' }}>{value}</Value>
  </StatsBlock>
)

const LoaderContainer = styled.div`
  display: flex; 
  height: 48px; 
  justify-content: center;
  align-items: center;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;

const TokenBalances = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const TokenRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 16px;
  width: 100%;
  max-width: 100%;
`;

const TokenTitle = styled.div`
  display: flex;
  align-items: center;

  & img {
    height: 24px;
    max-height: 24px;
    width: 24px;
    max-width: 24px;
    object-fit: contain;
    margin-right: 8px;
  }
`

const TokenName = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #A9D1E2;
`;

const TokenValue = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 100;
  color: #FFFFFF;
`

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  width: 100%;
  max-width: 100%;
  margin-top: 16px;
`;

const StatsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: #7D7D97;
`;

const Value = styled.div<{color? : string}>`
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${({ color }) => color ?? '#FFFFFF'};
`;

const ButtonRow = styled.div<{ hasUserBalance: boolean }>`
  display: flex;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  width: 100%;
  margin-top: ${(props) => props.hasUserBalance ? '22px' : '0'};

  button {
    margin-right: 0;
  }

  & > a {
    flex-grow: 1;
  }
`;

const TradeButton = styled(Link)`
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  border-radius: 16px;
  text-align: center;
`

const ActionButton = styled(LinkButton)`
  height: 48px;
  box-sizing: border-box;
`;

const CompositionWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

import { ButtonPrimary } from "@/components/Buttons/Button";
import React from "react";
import styled from "styled-components";
import { swapTokens, nfts, anything, crossChain, getLendingTokens, provideLiquidity } from "@/assets/icons/shellGuide/selectionModal";
import { SelectionBox } from "./SelectionBox";
import { Media } from "@/styles";
import { ShellGuideModalTabs } from "../ShellGuideModalBody";
import { scrollbar } from "@/styles/scrollbar"; 

export const selectOptions = [
    {
        id: "swap",
        title: "Swap Tokens",
        image: swapTokens,
    },
    {
        id: "liquidity",
        title: "Provide Liquidity",
        image: provideLiquidity,
    },
    {
        id: "lending",
        title: "Get Lending Tokens",
        image: getLendingTokens,
    },
    {
        id: "nfts",
        title: "Buy/Sell NFTs",
        image: nfts,
    },
    {
        id: "trade",
        title: "Anything to Anything",
        image: anything,
    },
    {
        id: "crosschain",
        title: "Swap Cross-chain",
        image: crossChain,
    }
];

interface SelectionSectionProps {
    redirectAction: any;
}

export const SelectionSection = ({redirectAction} :SelectionSectionProps) => {
    const [selectedBox, setSelectedBox] = React.useState<string | undefined>(undefined);
    return (
        <SectionWrapper>
            <Title>
                What would you like to do?
            </Title>
            <SelectionWrapper>
                <RowSelection>
                    {selectOptions.slice(0, 3).map((option) => (
                        <SelectionBox
                            key={option.id}
                            title={option.title}
                            image={option.image}
                            selected={selectedBox === option.id}
                            handleSelect={() => setSelectedBox(option.id)}
                        />
                    ))}
                </RowSelection>
                <RowSelection>
                    {selectOptions.slice(3).map((option) => (
                        <SelectionBox
                            key={option.id}
                            title={option.title}
                            image={option.image}
                            selected={selectedBox === option.id}
                            handleSelect={() => setSelectedBox(option.id)}
                        />
                    ))}
                </RowSelection>
            </SelectionWrapper>
            <Footer>
                <Description>
                    Select the transaction type, click continue, and Shell will automatically compose it for you.
                </Description>
                <CustomButton disabled={!selectedBox} onClick={() => redirectAction(selectedBox ?? "swap")}>
                    Continue
                </CustomButton>
            </Footer>
        </SectionWrapper>
    )
};

const SectionWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
margin-top: 24px;

${scrollbar()}

${Media.mobile} {
    overflow-y: auto;
    padding-bottom: calc(24px + 124px);
}
`;

const Title = styled.h2`
margin-left: 24px;
color: #FFF;

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;
`;

const SelectionWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 12px;
width: 100%;

${Media.mobile} {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: 0 12px 0 12px;
}
`;

const RowSelection = styled.div`
display: flex;
justify-content: center;
gap: 12px;

${Media.mobile} {
    flex-direction: column;
    align-items: center;
}
`;

const Footer = styled.div`
min-height: 96px;
padding: 24px;
display: flex;
justify-content: space-between;
align-items: center;
border-top: 1px solid #171B33;
box-sizing: border-box;

${Media.mobile} {
    position: fixed;
    bottom: 0;
    background: #0A0E27;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 16px 16px 24px 16px;
    align-items: flex-end;
}
`;

const Description = styled.p`
color: #7D7D97;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 16.32px */
width: 265px;

${Media.mobile} {
    width: 100%;
}
`;

const CustomButton = styled(ButtonPrimary)`
    border-radius: 12px;
height: unset;
padding: 14.5px 20px;
text-wrap: nowrap;

&&:disabled {
    border-radius: 12px;
background: var(--dark-2, #171B33);
}
    `;
import { Media } from "@/styles";
import React from "react";
import styled from "styled-components";


interface SelectionBoxProps {
    selected: boolean;
    title: string;
    image: string;
    handleSelect: () => void;
};

export const SelectionBox = ({selected, title, image, handleSelect} :SelectionBoxProps) => {

    return (
        <BoxWrapper onClick={handleSelect} selected={selected}>
            <BoxImage src={image} alt="box image" />
            <TitleWrapper>
                <BoxTitle>{title}</BoxTitle>
            </TitleWrapper>
        </BoxWrapper>
    )
};

const BoxWrapper = styled.div<{selected: boolean}>`
display: flex;
flex-direction: column;
gap: 0;
border: ${({selected}) => selected ? "1px solid transparent" : "1px solid var(--dark-2, #171B33)"};
outline: ${({selected}) => selected ? "2px solid var(--gradient-2, #37DCF2)" : "none"};
background: ${({selected}) => selected ? "#171B33" : "#0A0E27"};
width: 177px;
border-radius: 8px;
cursor: pointer;
transition: all 0.2s ease-in-out;
&:hover {
    background: #171B33;
}

${Media.mobile} {
    width: 100%;
    flex: 1;
}
`;

const BoxImage = styled.img`
width: 100%;
max-height: 157.5px;
min-height: 157.5px;

${Media.mobile} {
    min-height: unset;
    max-height: unset;
    min-width: calc(50vw - 18px);
    object-fit: contain;
}
`;

const TitleWrapper = styled.div`
padding: 13px 0 16px 16px;
white-space: nowrap;
`;

const BoxTitle = styled.p`
color: var(--White, #FFF);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;

${Media.mobile} {
    font-size: 12px;
}
`;


import styled, { CSSProperties, keyframes } from "styled-components";
import { Media } from "@/styles";

export const inAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const outAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const inModalAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

export const outModalAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const inMobileModalAnimation = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const outMobileModalAnimation = keyframes`
 0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;


export const Background = styled.div<{ withBackdropBlur?: boolean, isOpen: boolean, mobileStyle?: CSSProperties }>`
  backdrop-filter: ${({ withBackdropBlur }) => withBackdropBlur ? "blur(17px)" : "none"};
  position: fixed;
  background: rgba(26, 33, 73, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  animation: ${({ isOpen }) => (isOpen ? inAnimation : outAnimation)} 220ms ease-in;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;

${Media.mobile} {
    top: -20px;
    bottom: -20px;
    ${({ mobileStyle }) => mobileStyle && Object.entries(mobileStyle).map(([key, value]) => {
      const kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      return `${kebabKey}: ${value};`;
    }).join('')}
  } 

`;

export const Modal = styled.div<{ message?: boolean, isOpen: boolean, width?: string, mobileStyle?: CSSProperties, isFullHeight?: boolean, mobileFullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({width}) => width || "430px"};
  max-height: calc(100vh - 50px);
  border-radius: ${is => is.isFullHeight ? "0px" : "20px"};
  background-color: var(--dark-1, #0A0E27);

  /* ::-webkit-scrollbar {
    height: 6px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: black;
  }

  ::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 10px;

    &:hover {
      background-color: black;
    }
  } */

  &::-webkit-scrollbar {
        display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  animation: ${({ isOpen }) => (isOpen ? inModalAnimation : outModalAnimation)} 220ms ease-in;
  -webkit-animation-timing-function: linear; 
  animation-timing-function: linear;

  ${Media.tablet} {
    width: 80%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  ${Media.mobile} {
    width: 100%;
    transform: translate(-50%);
    max-height: calc(100% - 37px);
    top: unset;
    position: absolute;
    bottom: 0;
    animation: ${({ isOpen }) => (isOpen ? inMobileModalAnimation : outMobileModalAnimation)} 220ms ease-in;
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear;
    margin-bottom: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${({ mobileStyle }) => mobileStyle && Object.entries(mobileStyle).map(([key, value]) => {
      const kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      return `${kebabKey}: ${value};`;
    }).join('')}
  } 
`;


export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 32px 8px;

  ${Media.mobile} {
    padding: 0px 0px 38px 0px;
  }
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  gap: 15px;
  color: white;
  font-weight: 700;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const CloseBtn = styled.img`
border-radius: 6px;
top: 24px;
right: 26px;
  cursor: pointer;
  position: absolute;
  color: white;

  &:hover {
    opacity: 0.7;
  }

  ${Media.mobile} {
    top: 10px;
  }
`;
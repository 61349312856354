import React, { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useParams } from "react-router";
import { Box } from "../../components/Layout/Box";
import { Content } from "../../components/Layout/Content";
import { LogoLoader } from "../../components/Loaders";
import { Media, breakpoints } from "../../styles";
import { isExternalToken, isNFTCollection, isShellToken, isShellV2Token, maxTokenDecimals } from "@/utils/tokens";
import { BoxButton, ButtonSecondary, CopyBoxButton } from "@/components/Buttons/Button";
import { Composition } from "@/components/Composition/Composition";
import { extract1155Data } from "@/utils/nftHelpers";
import { HISTORY_API, NFT_PRICES_API, POOL_API, PRICES_API } from "@/constants/urls";
import { useAccount } from "wagmi";
import { tokenColors } from "@/constants/tokenColors";
import { ChartHeader } from "../LBP/ChartHeader";
import { BarChart } from "@/components/Charts/BarChart";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { addPool, addPoolChart } from "@/store/poolsSlice";
import { formatDisplay } from "@/utils/formatDisplay";
import { CopyButton } from "@/components/Buttons/CopyButton";
import { reduceString } from "@/utils/reduceString";
import { BackArrowIcon } from "@/components/Icons/BackArrowIcon";
import { useNavigationHelpers } from "@/hooks/navigation";
import { StatsChart } from "./StatsChart";
import { TVLChart } from "../Explore/TVLChart";
import { TradeWidget } from "../Trade/TradeWidget";
import { getTokenID } from "@/utils/LiquidityGraph";
import { View as TradeView } from "../Trade/TradeScreen";
import arbitrumLogo from "@/assets/icons/arbitrum-logo.svg";
import { addTransactionCount, addTransaction } from "@/store/transactionsSlice";
import { PoolHistory, skipInterval } from "../Pools/PoolHistory";
import { buildTransactions } from "../Transactions/TransactionHistory";
import { StatsTooltip } from "./StatsTooltip";
import { addPrice } from "@/store/pricesSlice";
import { unslug } from "@/types/ChartTypes";
import { CustomTooltip } from "@/components/CustomTooltip/CustomTooltip";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { defaultChain } from "@/placeholders/chains";

interface TabGroupProps {
  views: string[];
  activeView: string;
  setActiveView: (activeView: string) => void;
}

export function TabGroup({ views, activeView, setActiveView }: TabGroupProps) {
  return (
    <ButtonGroup>
      {views.map((view) => (
        <Tab
          key={view}
          activeView={activeView === view}
          onClick={() => setActiveView(view)}
          data-testid={`tab-${view}`}
        >
          {view}
        </Tab>
      ))}
    </ButtonGroup>
  );
}

export const StatisticsScreen = () => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const { tokenMap, nftCollections, connectedChain } = useContext(ChainContext)
  
  let { tokenName } = useParams();
  tokenName = tokenName?.includes("+") ? tokenName : unslug(tokenName!);
  const token: any = tokenMap[tokenName ?? ""];
  tokenName = tokenName?.replace(/[^a-zA-Z0-9 ]/g, '')
  
  const { handleGoBack } = useNavigationHelpers();
  const [loading, setLoading] = useState(true);
  const [historyLoading, setHistoryLoading] = useState(true);

  const getTexts = () => {
    let headerText = "";
    let buttonText = "";
    let chartHeader = "";
    if (isNFTCollection(token)) {
      headerText = `${capitalizeFirstLetter(
        token?.symbol?.toLowerCase()
      )} Statistics`;
      buttonText = "Wrap Toucans";
      chartHeader = `${capitalizeFirstLetter(
        token?.symbol?.toLowerCase()
      )}s Floor Price`;
    } else if (isShellToken(token)) {
      headerText = `Pool Statistics`;
      buttonText = "Provide Liquidity";
      chartHeader = `${token?.name} LP Token Price`;
    } else {
      headerText = `${token?.wrapped ? "Wrapped " : ""}Tether Statistics`;
      buttonText = "Wrap Token";
      chartHeader = `${token?.name} Price`;
    }

    return {
      headerText,
      buttonText,
      chartHeader,
    };
  };

  const walletInfoList = [
    {
      title: "12",
      description: isNFTCollection(token)
        ? "Wrapped Toucans"
        : isShellToken(token)
        ? "LP Tokens"
        : "Wrapped Tether",
    },
    {
      title: "10307 Points",
      description: "Earned Shell Points",
    },
    {
      title: "6x",
      description: "Active Boost",
    },
    {
      title: "$678.52",
      description: "USD Value",
    },
  ];

  const [tokens, setTokens] = useState<any>({});
  const { address: walletAddress } = useAccount();

  const views: string[] = ["Metrics", "Transaction History"];
  const [activeView, setActiveView] = useState<string>(views[0]);

  const [analytics, setAnalytics] = useState<any>({});
  const [tvlData, setTVLData] = useState<any>([]);
  const [priceChartData, setPriceChartData] = useState<any>([]);
  const [apyData, setApyData] = useState<any>([]);
  
  const poolStats = useAppSelector((state) => state.pools.pools[connectedChain.name]);
  const poolCharts = useAppSelector((state) => state.pools.charts[connectedChain.name]);
  const dispatch = useAppDispatch();

  const fetchPoolInfo = async (pool : any) => {
    const poolName = getTokenID(pool)
    const version = isExternalToken(pool) && isShellV2Token(pool) ? 'v2' : 'v3'

    if(poolStats[poolName])
        return poolStats[poolName]
    return fetch(POOL_API(connectedChain) + version + '/pools/' + poolName.replace(/\//g, "-"))
      .then((response) => response.json())
      .then((data) => {
        dispatch(addPool({ name: poolName, data: data, chain: connectedChain.name }));
        return data;
      })
      .catch(() => {});
  };

  const fetchPoolCharts = async (pool : any) => {
    const poolName = getTokenID(pool)
    const version = isExternalToken(pool) && isShellV2Token(pool) ? 'v2' : 'v3'

    if(poolCharts[poolName])
        return poolCharts[poolName]

    return fetch(POOL_API(connectedChain) + version + '/apa/' + poolName.replace(/\//g, "-"))
      .then((response) => response.json())
      .then((result) => {
        const data : any = {tvlData: [], priceData: [], apyData: []}
        result.chartData.forEach((entry : any) => {
            data.tvlData.push({date: entry.date, tokens: entry.tokens})
            data.priceData.push({date: entry.date, value: entry.lpTokenPrice})
            data.apyData.push({date: entry.date, value: formatDisplay(((entry.apy - 1) * 100).toString(), 2)})
        })
        dispatch(addPoolChart({ name: poolName, data: data, chain: connectedChain.name }));
        return data;
      })
      .catch(() => {});
  };

  const compositionList = useMemo(() => {
    return Object.keys(analytics?.breakdown || {}).map((childToken) => {
      return {
        symbol: childToken,
        percentage: `${analytics.breakdown[childToken]}%`,
      };
    });
  }, [analytics?.breakdown]);

  const queryTransactions = async () => {
    const apiData = await fetch(`${HISTORY_API(connectedChain)}pool/${token.name}/0`).then(
      (response) => response.json()
    );

    const transactionData: any = apiData.transactions.data;
    const transactionHashes: string[] = Object.keys(transactionData).slice(0, skipInterval);

    dispatch(addTransactionCount({ chain: connectedChain.name, address: token.name, count: apiData.transactions.count }));

    const txs = buildTransactions(transactionData, transactionHashes)

    dispatch(addTransaction({ chain: connectedChain.name, address: token.name, skip: 0, data: txs }));
  };

  const nftAddresses = new Set(nftCollections.map((collection) => collection.address))

  const fetchPrices = async (childTokens: string[]) => {

    const tokenAddresses = childTokens.map((token) => {
        const data1155 = extract1155Data(token)
        if(data1155){
            return tokenMap[data1155.symbol].address
        } else {
            return tokenMap[token].address
        }
    })

    const fetchPromises = [fetch(PRICES_API(connectedChain)).then((response) => response.json())];

    if (tokenAddresses.some(address => nftAddresses.has(address))) {
        fetchPromises.push(fetch(NFT_PRICES_API(connectedChain)).then((response) => response.json()));
    }

    const [priceData, nftPriceData] = await Promise.all(fetchPromises).catch(() => {
        console.error("Server not responding properly");
        return []
    })
    
    const prices : {[id: string] : number} = {}

    if(priceData){
        Object.keys(priceData).forEach((tokenID) => {
            const tokenPrice = priceData[tokenID]
            dispatch(addPrice({chain: connectedChain.name, name: tokenID, price: tokenPrice}))
            dispatch(addPrice({chain: connectedChain.name, name: 'sh'+ tokenID, price: tokenPrice}))
            prices[tokenID] = tokenPrice
            prices['sh' + tokenID] = tokenPrice
        })
        if(nftPriceData){
            Object.keys(nftPriceData).forEach((tokenID) => {
                const tokenPrice = nftPriceData[tokenID]
                dispatch(addPrice({chain: connectedChain.name, name: tokenID, price: tokenPrice}))
                dispatch(addPrice({chain: connectedChain.name, name: 'sh'+ tokenID, price: tokenPrice}))
                prices[tokenID] = tokenPrice
                prices['sh' + tokenID] = tokenPrice
            })
        }
    } else {
        console.error("Server not responding properly");
    }

    return prices
  }

  useEffect(() => {
    let _isMounted = true;
    if (_isMounted) {
      setLoading(true);
    }

    Promise.all([fetchPoolInfo(token), fetchPoolCharts(token), fetchPrices(token.tokens)])
      .then(([poolInfo, poolCharts, prices]) => {
        if (poolInfo) {
          setAnalytics(poolInfo);
        }
        if (poolCharts) {

          const currentTime = Math.floor(Date.now() / 1000);

          const currentTVLData : {[tokenID: string]: number} = {}
          Object.keys(poolInfo.balances).forEach((tokenID) => {
              currentTVLData[tokenID] = poolInfo.balances[tokenID] * prices[tokenID]
          })
          const currentPrice = poolInfo.LPTokenPrice

          const formatTokens = (tokens: any) => {
            const formatted : any = {}
            Object.keys(tokens).forEach((tokenID) => {
                const data1155 = extract1155Data(tokenID)
                if (data1155) {
                    formatted[data1155.symbol] = tokens[tokenID];
                } else {
                    formatted[tokenID] = tokens[tokenID]
                }
            })
            return formatted
          }

          const formattedTVLData = poolCharts.tvlData.map((data: any) => {
            return { ...data, tokens: formatTokens(data.tokens) }
          });

          const newTVLData = [
            ...formattedTVLData,
            { date: currentTime, tokens: formatTokens(currentTVLData) },
          ];
    
          setTVLData(newTVLData);
          setPriceChartData([...poolCharts.priceData, {date: currentTime, value: currentPrice}]);
          setApyData(poolCharts.apyData);
          setLoading(false);
        }
      })
      .catch((_) => {
        if (_isMounted) {
          setLoading(false);
        }
      });
    
    queryTransactions()
    
    return () => {
      _isMounted = false;
    };
  }, []);

  return (
    <Content>
      {loading ? (
        <LoadingWrapper>
          <LogoLoader />
        </LoadingWrapper>
      ) : (
        <View>
          {token ? (
            <>
              <TableHeader>
                <TableTitle onClick={() => handleGoBack()}>
                  <BackArrowIcon/>
                  {getTexts().headerText}
                </TableTitle>
              </TableHeader>
              <TradeContainer>
                <TokensWithDetailsContainer>
                    <TokensContainer>
                    <FlexContainer>
                        <TokenInfoContainer>
                        <Image src={token.icon} />
                        <FlexColumnContainer>
                            {token.name}
                            <TokenAddress>LP {reduceString(token.oceanID!, 6, 4)} <CopyButton text={token.oceanID!} className='copyShellToken'/></TokenAddress>
                            {/* <span>Government Toucans Collection</span> */}
                        </FlexColumnContainer>
                        </TokenInfoContainer>
                    </FlexContainer>
                    </TokensContainer>
                    <DetailsContainer>
                          <DetailsItem>
                              APY: 
                              <div>
                                {`${analytics.apy > 1 ? formatDisplay((((analytics.apy - 1) + analytics.rewardAPY) * 100).toString(), 2) : '--'}%`}
                              </div>
                              {analytics.apy > 1 && analytics.rewardAPY > 0 && 
                              <CustomTooltip
                                position={"right"}
                                title="APY Breakdown"
                                subtext={
                                <div className="CustomTooltipBreakdownText" style={{display: "flex", flexDirection: 'column', alignItems: 'baseline'}}>
                                  <p>{`Trading Fees: ${formatDisplay(((analytics.apy - 1) * 100).toString(), 2)}%`}</p> 
                                  <p>{`SHELL Rewards: ${formatDisplay((analytics.rewardAPY * 100).toString(), 2)}%`}</p>
                                </div>
                                }
                                delay={50}
                              />
                              }
                          </DetailsItem>
                          <DetailsItem>
                              Fee Tier: <span style={{color: '#00BDFF'}}>{`${token.fee}%`}</span>
                          </DetailsItem>
                          <DetailsItem>
                              TVL: <span>{`$${formatDisplay(analytics.totalValueLocked, 0)}`}</span>
                          </DetailsItem>
                          <DetailsItem>
                              24h Vol: <span>{`$${formatDisplay(analytics['24HrVolume'], 0)}`}</span>
                          </DetailsItem>
                        </DetailsContainer>
                    {isShellToken(token) &&
                    <ShellInfoBoxContainer>
                        <Container>
                        {Object.keys(analytics.breakdown).map((childToken) => {
                            const data1155 = extract1155Data(childToken)
                            const displayName = (data1155 ? data1155.item : tokenMap[childToken]).name
                            const displayIcon = (data1155 ? data1155.item : tokenMap[childToken]).icon
                            return (
                                <ShellInfoContainer maxWidth="296px" key={childToken}>
                                    <SmallImage src={displayIcon} /> {displayName}
                                    <Amount>{formatDisplay((analytics.balances[childToken] / (data1155 ? 100 : 1)).toString(), maxTokenDecimals(childToken))}</Amount>
                                </ShellInfoContainer>
                            )
                        })}
                        </Container>
                        <PercentageContainer>
                        <Composition
                            list={compositionList}
                        />
                        </PercentageContainer>
                    </ShellInfoBoxContainer>
                    }
                    <LinkContainer>
                    <ButtonsContainer style={{marginTop: '0'}} mobileColumn={true}>
                      <a href={`${defaultChain.explorerUrl}address/${token.pool}`} target="_blank">
                        <BoxButtonStyled>
                          <Icon src={arbitrumLogo} />
                          Pool Contract
                        </BoxButtonStyled>
                      </a>
                    </ButtonsContainer> 
                    </LinkContainer>
                </TokensWithDetailsContainer>
                <StyledTradeScreen>
                <TradeWidget //TODO: hide on mobile and create button to show
                  initInput={token.tokens.map((childToken: string) => tokenMap[childToken])} 
                  initOutput={[token]} 
                  lockedTokens={new Set([getTokenID(token)])}
                  refreshPage={false}
                />
                </StyledTradeScreen>
              </TradeContainer>
              {/* <WalletInfoContainer>
                Your wallet
                <WalletInfoListItem>
                  {walletInfoList.map(({ title, description }, index) => (
                    <WalletInfoItem key={index}>
                      {title}
                      <span>{description}</span>
                    </WalletInfoItem>
                  ))}
                </WalletInfoListItem>
              </WalletInfoContainer> */}
              <div>
              <TabGroup
                views={views}
                activeView={activeView}
                setActiveView={setActiveView}
              />
              {activeView === "Metrics" ? (
                <>
                  <ChartsWrapper>
                    <StatsChart title ={'APY'} token={token} data={apyData} type='APY'/>
                    <StatsChart title ={'LP Price'} token={token} data={priceChartData} type='price'/>
                  </ChartsWrapper>
                  <div style={{marginTop: '12px'}}>
                    <TVLChart title="Pool Tokens Locked"  poolTVL={true} lockedAssets={tvlData}/>
                  </div>
                  <BarChart //TODO: change display from none to flex
                    header={
                      <ChartFullHeader>
                        <ChartHeader
                          title="Liquidity Concetration"
                          amount="0.9998"
                          percent="-0.12%"
                        />
                      </ChartFullHeader>
                    }
                    tooltip={<StatsTooltip type={'price'}/>}
                    allowDuplicatedCategory={false}
                    tokenColor={tokenColors[tokenName ?? ""] || "#37DCF2"}
                    tokenName={tokenName ?? ""}
                    tokens={tokens}
                    withoutLegend={true}
                  />
                </>
              ) : <PoolHistory poolName={token.name}/>
              }
              </div>
              
              {/* {isNFTCollection(token) ? (
                <BuyToucansBox>
                  <BuyToucanImage src={buyToucan} />
                  <BuyToucanInfoContainer>
                    <BuyToucanInfoHeader>
                      Don't you have your Toucan yet? <br />
                      <span>Now buy it on Shell Protocol!</span>
                    </BuyToucanInfoHeader>
                    <BuyToucanInfoDescription>
                      Go to the Trade section, select what you want to buy a
                      Toucan for, and select your NFTs.
                    </BuyToucanInfoDescription>
                    <BuyToucanButton onClick={() => {}}>
                      Buy Toucans
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_3983_174)">
                          <path
                            d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3983_174">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </BuyToucanButton>
                  </BuyToucanInfoContainer>
                </BuyToucansBox>
              ) : null} */}
            </>
          ) : (
            "No token found"
          )}
        </View>
      )}
    </Content>
  );
};

const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: calc(100%);
    height: 1px;
    background: #292941;
  }
`;

const Tab = styled.button<{ activeView: boolean }>`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 16px;
  cursor: pointer;
  opacity: 0.6;
  background: transparent;
  color: white;
  border: 0;
  outline: 0;
  z-index: 0;
  ${({ activeView }) =>
    activeView &&
    `
    border-bottom: 1px solid #37DCF2;
    opacity: 1;
    `}
  ${Media.mobile} {
    width: unset;
    padding-bottom: 16px;
    padding-inline: 0;
  }
`;

const WalletInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 32px 20px;
  background: #151530;
  border: 1px solid #1e2239;
  border-radius: 16px;
  flex: 1;

  ${Media.tablet} {
    padding: 15px;
  }
`;

const WalletInfoListItem = styled.div`
  display: flex;
  gap: 8px;
  ${Media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const WalletInfoContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #7d7d97;
  }

  ${Media.tablet} {
    gap: 16px;
    font-size: 20px;
    line-height: 24px;

    span {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const BuyToucanInfoDescription = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #7d7d97;

  ${Media.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`;

const BuyToucanInfoHeader = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  span {
    background: linear-gradient(#37dcf2 100%, #07c0fb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  ${Media.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const BuyToucanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BuyToucanImage = styled.img`
  max-width: 320px;
  max-height: 210px;
  width: 100%;
  height: 100%;
  border-radius: 16px;

  ${Media.tablet} {
    max-width: 295px;
  }
`;

const BuyToucansBox = styled(Box)`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 24px;
  border: 1px solid #171b33;

  ${Media.tablet} {
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    button {
      max-width: unset;
    }
  }
`;

const SmallImage = styled.img`
  width: 32px;
  height: 32px;
`;

const Image = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 64px;

  ${Media.tablet} {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding-top: 300px;
  padding-bottom: 300px;
`;

const View = styled(Box)`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${Media.tablet} {
    padding: 16px 8px 12px;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;

  ${Media.tablet} {
    font-size: 20px;
    gap: 8px;
  }
`;

const TokensContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  background-color: #151530;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const TokenInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: left;

  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;

  span {
    color: var(--grey-3, #7d7d97);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  ${Media.tablet} {
    font-size: 18px;

    span {
      font-size: 14px;
    }
  }
`;

const TokenButton = styled(ButtonSecondary)<{ height?: string }>`
  width: fit-content;
  height: ${({ height }) => height || "48px"};
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.tablet} {
    max-width: 100%;
  }
`;

const TokensWithDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #1e2239;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: space-around;
  align-items: center;
  row-gap: 12px; 
  font-size: 16px;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #171b33;

  ${Media.tablet}{
    grid-template-columns: 1fr 1fr;
  }

`;

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 100;

  span {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #ffffff;
  }

  div:not([class*="CustomTooltip"]) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    border-radius: 6px;
    background-color: #1b2d3d;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #7adeb9;
  }

  ${Media.tablet} {
    span {
      gap: 8px;
    }
  }
`;

const ShellInfoContainer = styled.div<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || "unset"};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #A9D1E2;
`;

const Container = styled(ShellInfoContainer)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

const Amount = styled.div`
  margin-left: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

const PercentageContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

const ShellInfoBoxContainer = styled(ShellInfoContainer)<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 0;
  border-bottom: 1px solid #171b33;
  ${Media.tablet} {
    flex-direction: column;
  }
`;

const BuyToucanButton = styled(TokenButton)`
  margin-top: 8px;
  margin-left: unset;
  width: 100%;
  height: 60px;
  max-width: 250px;
`;

const BoostButton = styled(TokenButton)`
  ${Media.tablet} {
    height: 32px;
    border-radius: 12px;
  }
`;

const ChartFullHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  ${Media.tablet} {
    gap: 16px;
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  gap: 12px;
  ${Media.tablet} {
    flex-direction: column;
  }
`;

const StyledTradeScreen = styled.div`
  border: 1px solid #171b33;
  border-radius: 16px;
  padding: 8px;
  ${TradeView} {
    padding: 0;
  }
`;

const TradeContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: calc(100% - 394px) 375px;
  gap: 16px;
  ${Media.tablet} {
    grid-template-columns: calc(100vw - 48px);
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;

  ${Media.tablet} {
    padding: 16px;
  }
`;

const TokenAddress = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #A9D1E2;

  .copyShellToken {
    margin-left: 4px;
  }

  ${Media.tablet} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const LinkContainer = styled.div`
  padding: 24px;
`

const ButtonsContainer = styled.div<{ mobileColumn?: boolean; wrap?: boolean }>`
  display: flex;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  gap: 8px;
  margin-top: 16px;

  ${({ mobileColumn }) =>
    mobileColumn &&
    css`
      ${Media.tablet} {
        flex-direction: column;
      }
    `}
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const BoxButtonStyled = styled(BoxButton)`
  flex-grow: 1;
`;

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: 156px auto;
`

const ButtonRow = styled.div`
display: flex;
align-items: center;
justify-content: center;
column-gap: 16px;
margin-top: 8px;
`;

const PageRow = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

const DirectionButton = styled.button`
  width: 34px;
  height: 28px;
  font-weight: 100;
  font-size: 16px;
  line-height: 22px;
  border-radius: 20px;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #37dcf2;

  &:not(:disabled):hover {
      background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
      color: #0a0e27;
  }

  &:disabled {
      border: 1px solid #464659;
      color: #464659;
      cursor: auto;
  }

  ${Media.tablet} {
    width: 28px;
    border: none !important;
    &:hover {
      background: transparent !important;
      border: none !important;
      color: #464659;
    }
  }
`
import { ERC20ABI } from "@/constants/ABI/ERC20ABI";
import { StakingABI } from "@/constants/ABI/StakingABI";
import { VestingABI } from "@/constants/ABI/VestingABI";
import { SHELL_ADDRESS, STAKING_ADDRESS, VESTING_ADDRESS } from "@/constants/addresses";
import { multicall } from "@/utils/nftHelpers";
import { ContractCallContext, ContractCallResults } from "ethereum-multicall";
import { BigNumber, Contract } from "ethers";
import { formatEther, formatUnits, parseEther, parseUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { useAccount, useNetwork, useProvider, useSigner } from "wagmi";
import { MaxUint256 } from "@ethersproject/constants";
import { DISPLAY_DECIMALS, formatDisplay } from "@/utils/formatDisplay";
import { POINTS_API } from "@/constants/urls";
import { defaultChain } from "@/placeholders/chains";

interface DurationObject {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}

export type LockPeriodType = {
    label: string;
    period: DurationObject;
    APY: string;
    isDefault: boolean;
    amount: string,
    endTime: number
};

export const lockPeriods: LockPeriodType[] = [
    { label: "~1W", period: { days: 7 }, APY: '', isDefault: false, amount: '0', endTime: 0 },
    { label: "~1M", period: { days: 30 }, APY: '', isDefault: false, amount: '0', endTime: 0 },
    { label: "~3M", period: { days: 90 }, APY: '', isDefault: false, amount: '0', endTime: 0 },
    { label: "~6M", period: { days: 180 }, APY: '', isDefault: false, amount: '0', endTime: 0 },
    { label: "~1Y", period: { years: 1 }, APY: '', isDefault: false, amount: '0', endTime: 0 },
    { label: "~2Y", period: { years: 2 }, APY: '', isDefault: true, amount: '0', endTime: 0 }
];


export const useStakingHandler = () => {
    const navigate = useNavigate();

    const [modalDelayTimerId, setModalDelayTimerId] = useState<NodeJS.Timeout | null>(null);
    const [redirectTimerId, setRedirectTimerId] = useState<NodeJS.Timeout | null>(null);

    const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);
    const [isClaimStakeModalOpen, setIsClaimStakeModalOpen] = useState(false);
    const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false);
    const [isIncreaseLockPeriodModalOpen, setIsIncreaseLockPeriodModalOpen] = useState(false);
    const [isOpenStakeConfirmationModal, setIsOpenStakeConfirmationModal] = useState(false);
    const [isOpenUnstakeConfirmationModal, setIsOpenUnstakeConfirmationModal] = useState(false);
    const [isOpenChangeLockPeriodConfirmationModal, setIsOpenChangeLockPeriodConfirmationModal] = useState(false);
    const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

    const [currentLockPeriod, setCurrentLockPeriod] = useState<LockPeriodType>({ label: "~2Y", period: { years: 2 }, APY: '', isDefault: true, amount: '0', endTime: 0 });
    const [savedLockPeriod, setSavedLockPeriod] = useState<LockPeriodType | undefined>(undefined);

    const [isIncreaseLockPeriodSuccess, setIsIncreaseLockPeriodSuccess] = useState(false);
    const [isStakeConfirmed, setIsStakeConfirmed] = useState(false);
    const [isStakeAlertConfirmed, setIsStakeAlertConfirmed] = useState(false);
    const [isUnstakeConfirmed, setIsUnstakeConfirmed] = useState(false);

    const [myAvailableBalance, setMyAvailableBalance] = useState('0');
    const [myStaked, setMyStaked] = useState(0);
    const withdrawableAmount = useMemo(() => {
        return savedLockPeriod && savedLockPeriod.endTime < new Date().getTime() / 1000 ? savedLockPeriod.amount : '0'
    }, [savedLockPeriod])
    const [streamToClaim, setStreamToClaim] = useState(0)

    const [stakingLoading, setStakingLoading] = useState(false)
    const [stakingInfo, setStakingInfo] = useState<{[name: string]: string}>({});
    const { data: signer } = useSigner();
    const provider = useProvider();
    const { address: walletAddress } = useAccount();
    const { chain: activeChain } = useNetwork();
    const validChain = activeChain?.name == defaultChain.name

    const [amountToStake, setAmountToStake] = useState(0);

    const [stakeTxSubmitted, setStakeTxSubmitted] = useState(false)
    const [refresh, setRefresh] = useState(false)
    
    const staking = new Contract(STAKING_ADDRESS, StakingABI, signer || provider)

    const handleCloseUnstakeModal = () => {
        setIsUnstakeModalOpen(false);
    };

    const handleOpenUnstakeModal = () => {
        setIsUnstakeModalOpen(true);
    }

    const handleOpenClaimStakeModal = (streamID: number, amount: number) => {
        setIsClaimStakeModalOpen(true);
        setAmountToStake(amount);
        setStreamToClaim(streamID)
    };

    const handleCloseUnstakeConfirmationModal = () => {
        setIsOpenUnstakeConfirmationModal(false);
        if(isUnstakeConfirmed) handleRedirectToStaking()
    };

    const handleCloseClaimStakeModal = () => {
        setIsClaimStakeModalOpen(false)
    };

    const handleOpenStakeModal = () => {
        setAmountToStake(0);
        setIsStakeModalOpen(true);
    };

    const handleCloseStakeModal = () => setIsStakeModalOpen(false);

    const handleOpenConfirmationModal = (amount: string) => setIsOpenStakeConfirmationModal(true);

    const handleOpenIncreaseLockPeriodModal = () => {
        setIsIncreaseLockPeriodModalOpen(true);
        setCurrentLockPeriod(lockPeriods[0])
        setAmountToStake(Number(savedLockPeriod?.amount))
    };

    const handleCloseIncreaseLockPeriodModal = () => {
        setIsIncreaseLockPeriodModalOpen(false);
        setAmountToStake(0)
        if(isIncreaseLockPeriodSuccess) handleRedirectToStaking()
    };

    const handleStakeMax = () => {
        setAmountToStake(parseFloat(myAvailableBalance));
    };

    // const handleUnstakeMax = () => {
    //     if (myStaked) {
    //         setAmountToUnstake(myStaked);
    //     }
    // }

    const onChangeStakeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const amount = e.target.value.replaceAll(',', '')
        if(amount && parseFloat(amount) > 0){
            setAmountToStake(Number(amount));
        } else {
            setAmountToStake(0)
        }
    };

    // const onChangeUnstakeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setAmountToUnstake(Number(e.target.value));
    // }

    const handleCloseStakeConfirmationModal = () => {
        setIsOpenStakeConfirmationModal(false);
        if(isStakeConfirmed) handleRedirectToStaking()
        setIsStakeConfirmed(false)
    };

    const handleCloseChangeLockPeriodConfirmationModal = () => {
        setIsOpenChangeLockPeriodConfirmationModal(false);
        // if (modalDelayTimerId) clearTimeout(modalDelayTimerId);
        if(isIncreaseLockPeriodSuccess) handleRedirectToStaking()
    };

    const handleSelectLockPeriod = (lockPeriod: LockPeriodType) => {
        setCurrentLockPeriod(lockPeriod);
    };

    const truncateDecimals = (balance : number) => {
        const multiplier = Math.pow(10, DISPLAY_DECIMALS),
            adjustedNum = balance * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
        return (truncatedNum / multiplier).toString();
    }

    const handleConfirmStake = (
      showFallbackModal?: (show: boolean) => void
    ) => {
      return new Promise((resolve, reject) => {
        setIsOpenStakeConfirmationModal(true);

        if (savedLockPeriod) {
          staking
            .increase_amount(parseEther(truncateDecimals(amountToStake)))
            .then((response: any) => {
              setStakeTxSubmitted(true);
              response.wait().then((result: any) => {
                if (result.status == 1) {
                  //Success
                  setIsStakeConfirmed(true);
                  setStakeTxSubmitted(false);
                } else {
                  setIsStakeConfirmed(false);
                }
                resolve(0)
              });
            })
            .catch(() => {
              setIsStakeConfirmed(false);
              setIsOpenStakeConfirmationModal(false);
              if (showFallbackModal) {
                showFallbackModal(true);
              } else {
                setIsStakeModalOpen(true);
              }
              setIsStakeAlertConfirmed(false);
            });
        } else {
          staking
            .create_lock(
              parseEther(truncateDecimals(amountToStake)),
              getUnlockTime(currentLockPeriod.label)
            )
            .then((response: any) => {
              setStakeTxSubmitted(true);
              response.wait().then((result: any) => {
                if (result.status == 1) {
                  //Success
                  setIsStakeConfirmed(true);
                  setStakeTxSubmitted(false);
                } else {
                  setIsStakeConfirmed(false);
                }
                resolve(0)
              });
            })
            .catch(() => {
              setIsStakeConfirmed(false);
              setIsOpenStakeConfirmationModal(false);
              if (showFallbackModal) {
                showFallbackModal(true);
              } else {
                setIsStakeModalOpen(true);
              }
              setIsStakeAlertConfirmed(false);
            });
        }
      });
    };

    const handleInitStake = () => {
        //condition for show alert modal before stake action
        if (!myStaked && !isStakeAlertConfirmed) {
            setIsClaimStakeModalOpen(false);
            setIsStakeModalOpen(false);
            const modalDelayTimer = setTimeout(() => {
                setIsOpenAlertModal(true);
            }, 230);
            setModalDelayTimerId(modalDelayTimer);
            return;
        }
        setIsClaimStakeModalOpen(false);
        setIsStakeModalOpen(false);
        handleConfirmStake();
    };

    const handleConfirmUnstake = () => {
        setIsOpenUnstakeConfirmationModal(true);

        staking.withdraw().then((response: any) => {      
            setStakeTxSubmitted(true)            
            response.wait().then((result : any) => {
                setStakeTxSubmitted(true)            
                if(result.status == 1){ //Success
                    setIsUnstakeConfirmed(true);
                    setMyStaked(0)
                    setMyAvailableBalance((prev) => {
                        return prev + parseFloat(withdrawableAmount)
                    });
                    setStakeTxSubmitted(false)            
                } else {
                    setIsUnstakeConfirmed(false);
                }
            })
        }).catch(() => {
            setIsUnstakeConfirmed(false);
            setIsOpenUnstakeConfirmationModal(false);
        });
    };

    const handleInitUnstake = () => {
        setIsUnstakeModalOpen(false);
        const modalDelayTimer = setTimeout(() => {
            handleConfirmUnstake();
        }, 230);
        setModalDelayTimerId(modalDelayTimer);
    };

    const handleRedirectToStaking = () => {
        setIsOpenStakeConfirmationModal(false);
        setIsOpenChangeLockPeriodConfirmationModal(false);
        setAmountToStake(0);
        setIsStakeConfirmed(false)
        setIsStakeAlertConfirmed(false)
        setIsIncreaseLockPeriodSuccess(false)
        setIsUnstakeConfirmed(false)
        const timer = setTimeout(() => {
            navigate("/rewards/staking");
        }, 230)
        setRedirectTimerId(timer);
        setRefresh(!refresh)
    };

    const handleConfirmStakeAlert = () => {
        setIsOpenAlertModal(false);
        setIsStakeAlertConfirmed(true);
        handleConfirmStake();
    };

    const handleChangeLockPeriod = () => {
        setIsOpenAlertModal(false);
        setIsStakeConfirmed(false);
        setIsOpenStakeConfirmationModal(false);
        setIsStakeModalOpen(true);
        setIsStakeAlertConfirmed(false);
    };

    const handleCloseAlertModal = () => setIsOpenAlertModal(false);

    const handleInitIncreaseLockPeriodAfterStake = () => {
        setRefresh(!refresh)
        setIsStakeConfirmed(false)
        setIsStakeAlertConfirmed(false)
        setIsOpenStakeConfirmationModal(false);
        setCurrentLockPeriod(lockPeriods[0])
        setIsIncreaseLockPeriodModalOpen(true);
    };

    const handleConfirmIncreaseLockPeriod = () => {

        staking.increase_unlock_time(projectedEndTime).then((response: any) => {        
            setStakeTxSubmitted(true)            
            response.wait().then((result : any) => {
                if(result.status == 1){ //Success
                    setIsIncreaseLockPeriodSuccess(true);
                } else {
                    setIsOpenChangeLockPeriodConfirmationModal(false);
                    setIsIncreaseLockPeriodModalOpen(true);
                }
                setStakeTxSubmitted(false)            
            })
        }).catch(() => {
            setIsOpenChangeLockPeriodConfirmationModal(false);
            setIsIncreaseLockPeriodModalOpen(true);
        });
    };

    const handleIncreaseLockPeriod = () => {
        setIsIncreaseLockPeriodModalOpen(false);
        setIsOpenChangeLockPeriodConfirmationModal(true);
        
        const modalDelayTimer = setTimeout(() => {
            handleConfirmIncreaseLockPeriod();
        }, 230);
        setModalDelayTimerId(modalDelayTimer);
    };

    const getUnlockTime = (timePeriod: string, currentUnlockTime?: number) => {
        const week = 7 * 86400
        if(!currentUnlockTime) currentUnlockTime = new Date().getTime() / 1000
        let unlockTime
        if(timePeriod == '~2Y'){
            unlockTime = 2 * 365 * 86400
            return Math.floor((currentUnlockTime + unlockTime) / week) * week
        } else if(timePeriod == '~1Y'){
            unlockTime = 365 * 86400
        } else if(timePeriod == '~6M'){
            unlockTime = 180 * 86400
        } else if(timePeriod == '~3M'){
            unlockTime = 90 * 86400
        } else if(timePeriod == '~1M'){
            unlockTime = 30 * 86400
        } else {
            unlockTime = week
        }
        return Math.round((currentUnlockTime + unlockTime) / week) * week
    }

    const projectedEndTime = useMemo(() => {
      if (savedLockPeriod?.endTime) {
        const isIncreasingLockPeriod =
          isIncreaseLockPeriodModalOpen ||
          isOpenChangeLockPeriodConfirmationModal;
        return isIncreasingLockPeriod
          ? Math.min(
              getUnlockTime(currentLockPeriod.label, savedLockPeriod?.endTime),
              getUnlockTime("~2Y")
            )
          : savedLockPeriod.endTime;
      } else {
        return getUnlockTime(currentLockPeriod.label);
      }
    }, [
      currentLockPeriod,
      savedLockPeriod?.endTime,
      isIncreaseLockPeriodModalOpen,
      isOpenChangeLockPeriodConfirmationModal,
    ]);

    const projectedVeShell = useMemo(() => {
        const currentTime = new Date().getTime() / 1000
        let multiplier = (projectedEndTime - currentTime) / (getUnlockTime('~2Y') - currentTime)
        return amountToStake * multiplier
    }, [currentLockPeriod, savedLockPeriod?.endTime, amountToStake, isIncreaseLockPeriodModalOpen])

    const [stakeApprovalState, setStakeApprovalState] = useState('')
    const [stakeApprovedAmount, setStakeApprovedAmount] = useState('0')
    const [stakeBalanceError, setStakeBalanceError] = useState(false)

    const shell = new Contract(SHELL_ADDRESS, ERC20ABI, signer || provider)
    
    const approveStake = () => {
        setStakeApprovalState('Pending')
        shell.approve(STAKING_ADDRESS, MaxUint256).then((response: any) => {
            toast.promise(response.wait(), {
                loading: "Approving SHELL",
                success: () => {
                    setStakeApprovalState('');
                    return "Approved SHELL";
                },
                error: () => {
                    setStakeApprovalState('');
                    return (
                        "Error in SHELL approval"
                    );
                },
            });
        }).catch(() => {})
      }
  
      useEffect(() => {
          setStakeBalanceError(amountToStake > parseFloat(myAvailableBalance))
          if(parseUnits(stakeApprovedAmount).lt(parseUnits(amountToStake.toString()))){
              setStakeApprovalState('Needs')
          } else {
              setStakeApprovalState('')
          }
      }, [amountToStake])

    const fetchBalances = async () => {

        if (!validChain) {
          return {
            veShell: "0",
            shell: "0",
            currentLock: {
              amount: "0",
              end: 0,
            },
            approvedAmount: "0"
          };
        }

        const contractCallContext: ContractCallContext[] = [
            {
              reference: "veSHELL",
              contractAddress: STAKING_ADDRESS,
              abi: StakingABI as any,
              calls: [
                {
                  reference: "balanceOf",
                  methodName: "balanceOf",
                  methodParameters: [walletAddress],
                },
                {
                    reference: "locked",
                    methodName: "locked",
                    methodParameters: [walletAddress],
                }
              ],
            },
            {
                reference: "SHELL",
                contractAddress: SHELL_ADDRESS,
                abi: ERC20ABI as any,
                calls: [
                  {
                    reference: "balanceOf",
                    methodName: "balanceOf",
                    methodParameters: [walletAddress],
                  },
                  {
                    reference: "allowance",
                    methodName: "allowance",
                    methodParameters: [walletAddress, STAKING_ADDRESS],
                  }
                ],
            },
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext)

        return {
          veShell: formatEther(
            results.results["veSHELL"].callsReturnContext[0].returnValues[0]
          ),
          shell: formatEther(
            results.results["SHELL"].callsReturnContext[0].returnValues[0]
          ),
          currentLock: {
            amount: formatEther(
              results.results["veSHELL"].callsReturnContext[1].returnValues[0]
            ),
            end: BigNumber.from(
              results.results["veSHELL"].callsReturnContext[1].returnValues[1]
            ).toNumber(),
          },
          approvedAmount: formatEther(
            results.results["SHELL"].callsReturnContext[1].returnValues[0]
          ),
        };
    }

    const formatAPY = (stakedAmount: number, dailyReturn: number) => {
        // const apy = (((1 + dailyReturn) ** 365) - 1) * 100
        const totalReturn = dailyReturn * 365
        const apy = (totalReturn / stakedAmount) * 100
        if(apy >= 1e9){
            return '1,000,000,000+'
        } else {
            return formatDisplay(apy.toString(), 2)
        }
    }

    const getAPY = (lockPeriod: LockPeriodType, currentTime: number, totalVeShell: number, totalReward: number) => {

        let endTime
        if (savedLockPeriod?.endTime) {
            endTime = Math.min(
                getUnlockTime(lockPeriod.label, savedLockPeriod?.endTime),
                getUnlockTime("~2Y")
            )
          } else {
            endTime = getUnlockTime(lockPeriod.label);
          }
        
        let veShellUnit = (endTime - currentTime) / (getUnlockTime('~2Y') - currentTime)
        const dailyReturn = (veShellUnit / (totalVeShell + veShellUnit)) * totalReward

        return formatAPY(1, dailyReturn)
    }

    useEffect(() => {
        return () => {
            if (modalDelayTimerId) clearTimeout(modalDelayTimerId)
            if (redirectTimerId) clearTimeout(redirectTimerId)
        };
    }, [isStakeConfirmed]);

    useEffect(() => {
        let _isMounted = true;
        if (_isMounted) {
          setStakingLoading(true);
        }
        if(Object.keys(stakingInfo).length == 0) return
        fetchBalances()
          .then((result) => {
            if (_isMounted) {
              setMyStaked(parseFloat(result.veShell))
              setMyAvailableBalance(parseFloat(result.shell) > 1e-6 ? result.shell : '0')
              setStakeApprovedAmount(parseFloat(result.approvedAmount) > 1e-6 ? result.approvedAmount : '0')
              if(parseFloat(result.currentLock.amount) > 0){
                const dailyReturn =
                  ((parseFloat(result.veShell) /
                    parseFloat(stakingInfo.totalVeShell)) *
                    parseFloat(stakingInfo.totalReward));

                setSavedLockPeriod({
                  label: '~1W',
                  period: { days: 7 },
                  APY: formatAPY(parseFloat(result.currentLock.amount) ,dailyReturn),
                  isDefault: false,
                  amount: result.currentLock.amount,
                  endTime: result.currentLock.end,
                });
              } else {
                setSavedLockPeriod(undefined)
              }
              setStakingLoading(false);
            }
          })
          .catch((_) => {
            if (_isMounted) {
              setStakingLoading(false);
            }
          });

        return () => {
          _isMounted = false;
        };
      }, [walletAddress, refresh, stakingInfo, validChain]);

    useEffect(() => {

        const contractCallContext: ContractCallContext[] = [
        {
          reference: "Staked",
          contractAddress: SHELL_ADDRESS,
          abi: ERC20ABI as any,
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [STAKING_ADDRESS],
            },
          ],
        },
        {
          reference: "veShell",
          contractAddress: STAKING_ADDRESS,
          abi: StakingABI as any,
          calls: [
            {
              reference: "totalSupply",
              methodName: "totalSupply",
              methodParameters: [],
            },
          ],
        },
      ];

      const currentTime = new Date().getTime() / 1000;

      Promise.all([multicall.call(contractCallContext), fetch(POINTS_API(defaultChain) + 'currentShellReward').then((response) => response.json())])
        .then(([results, totalReward]) => {
          const totalVeShell = formatEther(
            BigNumber.from(
              results.results["veShell"].callsReturnContext[0].returnValues[0]
            ).toBigInt()
          );

          setStakingInfo({
            totalShellStaked: formatEther(
              BigNumber.from(
                results.results["Staked"].callsReturnContext[0].returnValues[0]
              ).toBigInt()
            ),
            totalVeShell: totalVeShell,
            totalReward: totalReward
          });

          for (const lockPeriod of lockPeriods) {
            const apy = getAPY(
              lockPeriod,
              currentTime,
              parseFloat(totalVeShell),
              totalReward
            );
            lockPeriod.APY = apy;
          }
        });
    }, [refresh]);

    return {
        setModalDelayTimerId,
        currentLockPeriod,
        savedLockPeriod,
        handleInitStake,
        isIncreaseLockPeriodModalOpen,
        isUnstakeModalOpen,
        isStakeModalOpen,
        setIsStakeModalOpen,
        myStaked,
        isClaimStakeModalOpen,
        setIsClaimStakeModalOpen,
        handleOpenClaimStakeModal,
        handleCloseClaimStakeModal,
        handleOpenConfirmationModal,
        handleCloseStakeConfirmationModal,
        // currentVotingPower,
        handleSelectLockPeriod,
        isOpenStakeConfirmationModal,
        isStakeConfirmed,
        handleRedirectToStaking,
        handleConfirmStake,
        handleConfirmStakeAlert,
        isOpenAlertModal,
        setIsOpenAlertModal,
        isStakeAlertConfirmed,
        setIsStakeAlertConfirmed,
        handleCloseAlertModal,
        handleChangeLockPeriod,
        handleOpenIncreaseLockPeriodModal,
        handleCloseIncreaseLockPeriodModal,
        handleInitIncreaseLockPeriodAfterStake,
        handleIncreaseLockPeriod,
        isOpenChangeLockPeriodConfirmationModal,
        handleCloseChangeLockPeriodConfirmationModal,
        isIncreaseLockPeriodSuccess,
        handleOpenStakeModal,
        handleCloseStakeModal,
        amountToStake,
        setAmountToStake,
        onChangeStakeAmount,
        myAvailableBalance,
        handleStakeMax,
        handleOpenUnstakeModal,
        handleCloseUnstakeModal,
        withdrawableAmount,
        isOpenUnstakeConfirmationModal,
        handleCloseUnstakeConfirmationModal,
        handleInitUnstake,
        isUnstakeConfirmed,
        stakingInfo,
        getUnlockTime,
        projectedVeShell,
        projectedEndTime,
        stakeApprovalState,
        stakeApprovedAmount,
        stakeBalanceError,
        approveStake,
        stakingLoading,
        streamToClaim,
        stakeTxSubmitted
    }
}
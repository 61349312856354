import React, { useContext, useState } from "react";
import styled from "styled-components";
import headerIcon from "../../assets/lbp/lbp-with-check-icon.png";
import { Media, breakpoints } from "../../styles";
import { LiveText, StatusBullet } from "./StatusBullet";
import { useNavigate } from "react-router";
import { useWidthBreakpoint } from "../../hooks";
import { BackArrowIcon } from "@/components/Icons/BackArrowIcon";
import { useNavigationHelpers } from "@/hooks/navigation";
import { extract1155Data } from "@/utils/nftHelpers";
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const Header = ({token}: any) => {
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const { handleGoBack } = useNavigationHelpers();

  const { tokenMap, lbpTokens  } = useContext(ChainContext)

  const data1155 = extract1155Data(token.symbol)
  const displaySymbol = data1155 ? data1155.symbol : token.symbol
  const displayIcon = data1155 ? data1155.item.icon : token.icon;

  return (
    <HeaderContainer>
      <FlexContainer>
        <div style={{cursor: 'pointer'}} onClick={() => handleGoBack()}>
          <BackArrowIcon/>
        </div>
        <Image src={displayIcon} />
        <TitleContainer>
          <Title>{token.name}</Title>
          <SecocndTitle>{!isTablet && '•'} {displaySymbol}</SecocndTitle>
        </TitleContainer>
        <StatusBullet status={token.status} />
      </FlexContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  ${Media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const Image = styled.img`
  width: 52px;
  height: 52px;
  margin-inline: 12px;
  border-radius: 8px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-right: 4px;
`;

const SecocndTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #7d7d97;
  margin-right: 20px;
`;

const Button = styled.div`
  padding: 17px 30px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
  cursor: pointer;

  ${Media.tablet} {
    width: 100%;
    margin-left: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  ${Media.tablet} {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  ${Media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

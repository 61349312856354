import { StreamABI } from "@/constants/ABI/StreamABI";
import { STREAM_ADDRESS } from "@/constants/addresses";
import { BigNumber, Contract } from "ethers/lib/ethers";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useSigner, useProvider } from 'wagmi';
import { ClaimData } from "./useAirDropHandler";
import { ShellRewardsABI } from "@/constants/ABI/ShellRewardsABI";
import { SHELL_REWARDS_API } from "@/constants/urls";
import { defaultChain } from "@/placeholders/chains";

type ClaimEpoch = {
    name: string;
    value: number;
    status: string;
};

export const useRewardsHandler = () => {
    const [isClaimSuccess, setIsClaimSuccess] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [actionTimeoutId, setActionTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [redirectTimerId, setRedirectTimerId] = useState<NodeJS.Timeout | null>(null);
    const [claimTxSubmitted, setClaimTxSubmitted] = useState(false);
    const [claimData, setClaimData] = useState<ClaimData>({index: [], amount: [], proof: []});
    const [unclaimedShell, setUnclaimedShell] = useState(0);
    const [userEpochs, setUserEpochs] = useState<ClaimEpoch[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [seasonClaimedAmount, setSeasonClaimedAmount] = useState({})

    const [isShellClaimModalOpen, setIsShellClaimModalOpen] = useState(false);
    const [shellClaimTxSubmitted, setShellClaimTxSubmitted] = useState(false)
    const [shellClaimSuccess, setShellClaimSuccess] = useState(false)

    const { address: walletAddress } = useAccount();
    const { data: signer } = useSigner();
    const provider = useProvider();
    const { chain: activeChain } = useNetwork();
    const validChain = activeChain?.name == defaultChain.name
    const streams = new Contract(STREAM_ADDRESS, StreamABI, signer || provider)
    const shellRewards = new Contract('0xbF2495345D2F0384312686a553D230373618d93f', ShellRewardsABI, signer || provider)

    const [myRewards, setMyRewards] = useState(0);

    const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);

    const handleCloseShellClaimModal = () => {
        setIsShellClaimModalOpen(false);
        if(shellClaimSuccess) setRefresh(!refresh)
    };

    const closeClaimModal = () => {
        setIsClaimModalOpen(false);
        if (isClaimSuccess) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            window.location.reload()
        }
    };

    const fetchClaimData = async () => {
      
      const epochs = 3;

      const claims = await shellRewards.viewRewardClaims(walletAddress);
      const data: any[] = Object.values(
        await fetch(SHELL_REWARDS_API + walletAddress).then((response) =>
          response.json()
        )
      );

      const newClaimData: ClaimData = { index: [], amount: [], proof: [] };
      const newClaimEpochs: ClaimEpoch[] = [];
      let unclaimedShellAmount = 0;

      for (let index = 0; index < epochs; index++) {
        const unclaimed = !claims[index] && data[index];
        const seasonClaimData = data[index];
        newClaimData.index.push(0);
        if (seasonClaimData && Object.keys(seasonClaimData).length > 0) {
          newClaimData.amount.push(seasonClaimData.amount);
          newClaimData.proof.push(
            unclaimed
              ? seasonClaimData.proof.map(
                  (proofElement: string) =>
                    `0x${proofElement.replace(/^0x/, "")}` as `0x${string}`
                )
              : []
          );
          newClaimEpochs.push({
            name: index.toString(),
            status: unclaimed ? "Pending" : "Claimed",
            value: parseFloat(formatUnits(seasonClaimData.amount)),
          });
          unclaimedShellAmount += unclaimed
            ? parseFloat(formatUnits(seasonClaimData.amount))
            : 0;
        } else {
          newClaimData.amount.push("0");
          newClaimData.proof.push([]);
          newClaimEpochs.push({
            name: index.toString(),
            status: "",
            value: 0,
          });
        }
      }

      return {
        claimData: newClaimData,
        userEpochs: newClaimEpochs,
        unclaimedShell: unclaimedShellAmount,
      };
    };

    const handleClaimShell = (): Promise<number> => {
      return new Promise((resolve, reject) => {
        setIsShellClaimModalOpen(true);
        shellRewards
          .claim(
            claimData.amount.map((amount) => BigNumber.from(amount)),
            claimData.proof
          )
          .then((response: any) => {
            setShellClaimTxSubmitted(true);
            response.wait().then((result: any) => {
              if (result.status == 1) {
                //Success
                let claimedAmount = 0;
                for (const event of result.events) {
                  if (event.event === "Transfer") {
                    claimedAmount += parseFloat(formatEther(event.args[2]));
                  }
                }
                setMyRewards(claimedAmount);
                resolve(claimedAmount);
                setShellClaimSuccess(true);
                setShellClaimTxSubmitted(false);
              } else {
                setShellClaimSuccess(false);
              }
            });
          })
          .catch(() => {
            setIsShellClaimModalOpen(false);
            resolve(0);
          });
      });
    };

    const claimAllStreams = (
      streamIDs: number[],
      amounts: BigNumber[],
      amount: number
    ): Promise<number> => {
      return new Promise((resolve, reject) => {
        setMyRewards(amount);
        setIsClaimModalOpen(true);
        streams
          .withdrawMultiple(streamIDs, walletAddress, amounts)
          .then((response: any) => {
            setClaimTxSubmitted(true)
            response.wait().then((result: any) => {
              if (result.status == 1) { //Success
                let claimedAmount = 0
                for (const event of result.events) {
                    if (event.event === "WithdrawFromLockupStream") {
                      claimedAmount += parseFloat(formatEther(event.args[3]));
                    }
                }
                setMyRewards(claimedAmount);
                resolve(claimedAmount);
                setIsClaimSuccess(true);
                setClaimTxSubmitted(false)
              } else {
                setIsClaimSuccess(false);
                resolve(0)
              }
            });
          })
          .catch(() => {
            setIsClaimModalOpen(false);
          });
      });
    };

    const claimStream = (streamID: number, amount: number): Promise<number> => {
        return new Promise((resolve, reject) => {
            setMyRewards(amount);
            setIsClaimModalOpen(true);
            streams.withdraw(streamID, walletAddress, parseEther(amount.toString())).then((response: any) => {
                setClaimTxSubmitted(true)
                response.wait().then((result: any) => {
                    if (result.status == 1) { // Success
                        result.events.forEach((event: any) => {
                            if (event.event == "WithdrawFromLockupStream") {
                                const claimedAmount = parseFloat(formatEther(event.args[3]));
                                setMyRewards(claimedAmount);
                                resolve(claimedAmount);
                            }
                        });
                        setIsClaimSuccess(true);
                        setClaimTxSubmitted(false)
                    } else {
                        setIsClaimSuccess(false);
                        resolve(0);
                    }
                })
            }).catch(() => {
                setIsClaimModalOpen(false);
            });
        });
    };

    useEffect(() => {
        return () => {
            if (timerId) {
                clearTimeout(timerId)
            }
            if (redirectTimerId) {
                clearTimeout(redirectTimerId)
            }
        };
    }, [isClaimSuccess]);

    useEffect(() => {    
       setUnclaimedShell(0)   
       fetchClaimData().then((claimResult) => {
          setClaimData(claimResult.claimData)
          setUserEpochs(claimResult.userEpochs)
          setUnclaimedShell(claimResult.unclaimedShell)
        })
        .catch((_) => {});
      }, [walletAddress, refresh, validChain]);

    useEffect(() => {
      return () => {
          if (redirectTimerId) clearTimeout(redirectTimerId);
      }
    }, [shellClaimSuccess]);

    return {
        claimAllStreams,
        claimStream,
        isClaimSuccess,
        setIsClaimModalOpen,
        isClaimModalOpen,
        closeClaimModal,
        myRewards,
        setIsClaimSuccess,
        claimTxSubmitted,
        unclaimedShell,
        handleClaimShell,
        shellClaimTxSubmitted,
        shellClaimSuccess,
        isShellClaimModalOpen,
        setIsShellClaimModalOpen,
        handleCloseShellClaimModal,
        userEpochs
    }
}
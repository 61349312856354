import React, { SetStateAction, useEffect } from "react";
import styled from "styled-components";
import shellGuideSmallIcon from "@/assets/icons/shell-guide-small.svg";
import closeButton from "@/assets/icons/close-button.png";
import { ModalNavItem } from "./components/ModalNavItem";
import {
  GetStartedIcon,
  GetStartedIconActive,
  NFTsIcon,
  NFTsIconActive,
  ProvideLiquidityIcon,
  ProvideLiquidityIconActive,
  SwapFungibleIcon,
  SwapFungibleIconActive,
  SwapLendingIcon,
  SwapLendingIconActive,
  TradeIcon,
  TradeIconActive,
  TryIcon,
  TryIconActive
} from "./components/icons";
import { StartedSection } from "./components/StartedSection";
import { SectionWithSwiper } from "./components/SectionWithSwiper";
import { SelectionSection } from "./components/SelectionSection";
import { swapFungible1, swapFungible2, swapFungible3 } from "@/assets/icons/shellGuide/swapFungible";
import { provideLiquidity1, provideLiquidity2, provideLiquidity3 } from "@/assets/icons/shellGuide/provideLiquidity";
import { lendingTokens1, lendingTokens2, lendingTokens3 } from "@/assets/icons/shellGuide/lendingTokens";
import { nfts1, nfts2, nfts3, nfts4 } from "@/assets/icons/shellGuide/nfts";
import { earn1, earn2 } from "@/assets/icons/shellGuide/earn";
import { stake1, stake2 } from "@/assets/icons/shellGuide/stake";
import { trade1 } from "@/assets/icons/shellGuide/trade";
import { shellStargate } from "@/assets/icons/shellGuide/selectionModal";
import protocolsImage from "@/assets/icons/shellGuide/protocols.png";
import { breakpoints, Media } from "@/styles";
import { useWidthBreakpoint } from "@/hooks";
import { SelectionTopicMobileLayer } from "./components/SelectionTopicMobileLayer";
import { useNavigate } from "react-router";

export enum ShellGuideModalTabs {
  GET_STARTED = "Get Started",
  SWAP_FUNGIBLE = "Swap Fungible Tokens",
  PROVIDE_LIQUIDITY = "Add Liquidity",
  SWAP_LENDING = "Lend Tokens",
  // NFTS = "Buy, sell and trade NFTs", // hide NFTs for now
  TRY = "Let's try Shell",
  LP = "Move Liquidity Between Two Pools",
  STAKE = "Stake to Earn ARB Reward",
  EARN = "Claim ARB Rewards",
}
interface ShellGuideModalBodyProps {
  onClose: (tab: string) => void;
  guideTab: string;
}

type ChildrenComponentProps = {
  handleSetActiveTabToOne?: () => void;
  handleNavigateToTradeScreen?: (action: ShellGuideModalTabs) => void;
};

export type MenuItem = {
  title: ShellGuideModalTabs;
  icon: JSX.Element;
  activeIcon: JSX.Element;
  childrenComponent: (props: ChildrenComponentProps) => JSX.Element;
};

const menuItems: MenuItem[] = [
  {
    title: ShellGuideModalTabs.GET_STARTED,
    icon: <GetStartedIcon />,
    activeIcon: <GetStartedIconActive />,
    childrenComponent: ({
      handleSetActiveTabToOne,
    }: ChildrenComponentProps) => (
      <StartedSection
        handleSetStarted={() =>
          handleSetActiveTabToOne && handleSetActiveTabToOne()
        }
      />
    ),
  },
  {
    title: ShellGuideModalTabs.SWAP_FUNGIBLE,
    icon: <SwapFungibleIcon />,
    activeIcon: <SwapFungibleIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => (
      <SectionWithSwiper
        steps={[
          {
            title: (
              <StepTitle>
                Select <span>Token</span>
              </StepTitle>
            ),
            description: (
              <>
                Click the <Bold>Select Token</Bold> button on the trade screen.
                A modal will pop up where you can choose your output token
                (which token you'd like to receive for your swap). Make sure you
                are on the <Bold>Token</Bold> sub-tab at the top. Your input
                token will default to ETH, but this can be changed as well.
              </>
            ),
            image: swapFungible1,
          },
          {
            title: <StepTitle>Input amount</StepTitle>,
            description: (
              <>
                Have you already chosen your input and output tokens? Great! Now
                it's time to enter the amount you want to swap into the input
                field at the top. The output field at the bottom will display
                how many tokens you will receive. Once you have decided how many
                tokens you'd like to swap, you can initiate the trade by
                clicking the <Bold>Swap Token</Bold> button at the bottom of
                your screen.
              </>
            ),
            image: swapFungible2,
          },
          {
            title: <StepTitle>Confirm Transaction</StepTitle>,
            description: `For the last step, a summary of your transaction will pop up on your screen. Once you confirm that the information matches what you selected, you will be asked to sign the transaction in your wallet, such as Metamask. Your transaction will be completed momentarily.`,
            image: swapFungible3,
          },
        ]}
        key={ShellGuideModalTabs.SWAP_FUNGIBLE}
        finalText="Swap tokens"
        finalAction={() =>
          props.handleNavigateToTradeScreen &&
          props.handleNavigateToTradeScreen(ShellGuideModalTabs.SWAP_FUNGIBLE)
        }
      />
    ),
  },
  {
    title: ShellGuideModalTabs.PROVIDE_LIQUIDITY,
    icon: <ProvideLiquidityIcon />,
    activeIcon: <ProvideLiquidityIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => (
      <SectionWithSwiper
        steps={[
          {
            title: (
              <StepTitle>
                Select <span>Liquidity</span> Pool
              </StepTitle>
            ),
            description: (
              <>
                Click the <Bold>Select Token</Bold> button on the trade screen.
                A modal will pop up where you can choose which pool you'd like
                to deposit into. Make sure you are on the <Bold>DeFi</Bold>{" "}
                sub-tab at the top. You can either find a pool by typing into
                the search bar at the top, or scrolling through the options. The
                input token will default to ETH, but you can choose any token
                you want - Shell will automatically route your transaction for
                you. Input multiple tokens by clicking on the <Bold>+</Bold> on
                the left side of the screen.
              </>
            ),
            image: provideLiquidity1,
          },
          {
            title: <StepTitle>Input amount</StepTitle>,
            description: (
              <>
                Have you already chosen your input and output tokens? Great! Now
                it's time to enter the amount you want to deposit into the pool.
                The output field at the bottom will display how many LP tokens
                you will receive. Once you have decided how many tokens you'd
                like to deposit, you can initiate the trade by clicking the{" "}
                <Bold>Swap Token</Bold> button at the bottom of your screen.
              </>
            ),
            image: provideLiquidity2,
          },
          {
            title: <StepTitle>Confirm Transaction</StepTitle>,
            description: `For the last step, a summary of your transaction will pop up on your screen. 
            Once you confirm that the information matches what you selected, you will be asked to sign the transaction in your wallet, such as Metamask. 
            Your transaction will be completed momentarily.`,
            image: provideLiquidity3,
          },
        ]}
        key={ShellGuideModalTabs.PROVIDE_LIQUIDITY}
        finalText="Trade LP"
        finalAction={() =>
          props.handleNavigateToTradeScreen &&
          props.handleNavigateToTradeScreen(
            ShellGuideModalTabs.PROVIDE_LIQUIDITY
          )
        }
      />
    ),
  },
  {
    title: ShellGuideModalTabs.SWAP_LENDING,
    icon: <SwapLendingIcon />,
    activeIcon: <SwapLendingIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => (
      <SectionWithSwiper
        steps={[
          {
            title: (
              <StepTitle>
                Select <span>Lending</span> Pool
              </StepTitle>
            ),
            description: (
              <>
                Click the <Bold>Select Token</Bold> button on the trade screen. 
                A modal will pop up where you can choose which pool you'd like to deposit into. 
                Make sure you are on the <Bold>DeFi</Bold> sub-tab at the top. 
                You can either find a pool by typing into the search bar at the top, or scrolling through the options. 
                The input token will default to ETH, but you can choose any token you want. 
                If the input token is different from the lending pool's base token, Shell will first do a swap on your behalf before depositing into the pool. 
              </>
            ),
            image: lendingTokens1,
          },
          {
            title: <StepTitle>Input Amount</StepTitle>,
            description: (
              <>
                Have you already chosen your input and output tokens? 
                Great! Now it's time to enter the amount you want to deposit into the pool. 
                The output field at the bottom will display how many lending tokens you will receive. 
                Once you have decided how many tokens you'd like to deposit, you can initiate the trade by clicking the <Bold>Swap Token</Bold> button at the bottom of your screen.
              </>
            ),
            image: lendingTokens2,
          },
          {
            title: <StepTitle>Confirm Transaction</StepTitle>,
            description: `For the last step, a summary of your transaction will pop up on your screen. Once you confirm that the information matches what you selected, you will be asked to sign the transaction in your wallet, such as Metamask. Your transaction will be completed momentarily.`,
            image: lendingTokens3,
          },
        ]}
        key={ShellGuideModalTabs.SWAP_LENDING}
        finalText="Lend tokens"
        finalAction={() =>
          props.handleNavigateToTradeScreen &&
          props.handleNavigateToTradeScreen(ShellGuideModalTabs.SWAP_LENDING)
        }
      />
    ),
  },
  // {
  //   title: ShellGuideModalTabs.NFTS,
  //   icon: <NFTsIcon />,
  //   activeIcon: <NFTsIconActive />,
  //   childrenComponent: (props: ChildrenComponentProps) => (
  //     <SectionWithSwiper
  //       steps={[
  //         {
  //           title: (
  //             <StepTitle>
  //               Decision - <span>Buy, Sell or Trade</span> NFTs
  //             </StepTitle>
  //           ),
  //           description: `Select the type of trade you are interested in using the layout changer on the “Trade” screen. Following the example above, this is how the selected transaction will look like.`,
  //           image: nfts1,
  //         },
  //         {
  //           title: (
  //             <StepTitle>
  //               Select <span>collection</span> and <span>NFTs</span> to Buy,
  //               Sell or Trade
  //             </StepTitle>
  //           ),
  //           description: `Open the modal by clicking on the appropriate element on the "Trade" screen, depending on whether you want to buy or sell NFTs. Go to the "NFTs" section, go into the collection you are interested in and select the NFTs that will be subject
  //               to the transaction.
  //               `,
  //           image: nfts2,
  //         },
  //         {
  //           title: (
  //             <StepTitle>
  //               <span>NFTs selected?</span> Go to confirmation
  //             </StepTitle>
  //           ),
  //           description: `Once you have selected NFTs and confirmed, the amount will be automatically calculated. If you want to change the token you are buying/selling NFTs for, simply open the modal and select a different one. When everything matches, confirm by clicking the "Swap Tokens" button at the bottom of the screen. 
  //               `,
  //           image: nfts3,
  //         },
  //         {
  //           title: <StepTitle>Confirm Transaction</StepTitle>,
  //           description: `Last step is summary of your transaction, you check that all the information matches and confirm the transaction in your wallet, such as MetaMask. Don't wait,  try buying or selling your NFTs without worrying about listing them on OpenSea!`,
  //           image: nfts4,
  //         },
  //       ]}
  //       key={ShellGuideModalTabs.NFTS}
  //       finalText="Trade NFTs"
  //       finalAction={() =>
  //         props.handleNavigateToTradeScreen &&
  //         props.handleNavigateToTradeScreen(ShellGuideModalTabs.NFTS)
  //       }
  //     />
  //   ),
  // },
  {
    title: ShellGuideModalTabs.LP,
    icon: <TradeIcon />,
    activeIcon: <TradeIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => (
      <SectionWithSwiper
        steps={[
          {
            title: (
              <StepTitle>
                DeFi at Your <span>Fingertips</span>
              </StepTitle>
            ),
            description: (
              <>
                Did you know that you can move your liquidity directly between two pools without first making a withdrawal? 
                Select the pool you'd like to deposit into by clicking the <Bold>Select Token</Bold> button. 
                Follow the steps for Adding Liquidity or Lending Tokens listed in the Shell Guide. 
                Next, select the pool you'd like to withdraw from by changing the input token. 
                Once you are ready, click <Bold>Swap Tokens</Bold> at the bottom and confirm the transaction.      
              </>
            ),
            image: trade1,
          },
        ]}
        key={ShellGuideModalTabs.LP}
        finalText="Let's try"
        finalAction={() =>
          props.handleNavigateToTradeScreen &&
          props.handleNavigateToTradeScreen(ShellGuideModalTabs.LP)
        }
      />
    ),
  },
  {
    title: ShellGuideModalTabs.STAKE,
    icon: <TradeIcon />,
    activeIcon: <TradeIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => (
      <SectionWithSwiper
        steps={[
          {
            title: (
              <StepTitle>
                Select <span>Pool</span>
              </StepTitle>
            ),
            description: (
              <>
                Click the <Bold>Select Token</Bold> button on the trade screen. 
                A modal will pop up where you can choose which pool to stake your tokens into. 
                Make sure you are on the <Bold>DeFi</Bold> sub-tab at the top. 
                You can find pools eligible for ARB rewards by typing <Bold>STIP</Bold> in the search bar, or by selecting the Arbitrum icon at the top of the modal. The input token will default to ETH, but you can choose any token you want - Shell will automatically route the transaction for you.              
              </>
            ),
            image: stake1,
          },
          {
            title: <StepTitle>Input Amount</StepTitle>,
            description: (
              <>
                Have you already chosen which pool you want to stake your tokens to? Great! 
                Now it's time to enter the amount you want to add into the pool. 
                The output field at the bottom will display how many staked tokens you will receive. 
                Once you have decided how many tokens you'd like to add, you can initiate the trade by clicking the <Bold>Swap Token</Bold> button at the bottom of your screen.              
              </>
            ),
            image: stake2,
          },
          {
            title: <StepTitle>Confirm Transaction</StepTitle>,
            description: `For the last step, a summary of your transaction will pop up on your screen. Once you confirm that the information matches what you selected, you will be asked to sign the transaction in your wallet, such as Metamask. Your transaction will be completed momentarily.`,
            image: swapFungible3,
          },
        ]}
        key={ShellGuideModalTabs.STAKE}
        finalText="Stake tokens"
        finalAction={() =>
          props.handleNavigateToTradeScreen &&
          props.handleNavigateToTradeScreen(ShellGuideModalTabs.STAKE)
        }
      />
    ),
  },
  {
    title: ShellGuideModalTabs.EARN,
    icon: <TradeIcon />,
    activeIcon: <TradeIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => (
      <SectionWithSwiper
        steps={[
          {
            title: (
              <StepTitle>
                Go to the <span>Rewards</span> Tab
              </StepTitle>
            ),
            description: (
              <>
                To claim your ARB rewards, go to the Rewards tab at the top of the dapp, and select the STIP sub-tab. 
                A list of staking pools will be displayed. 
                The amount of ARB rewards available for you to claim will be visible.   
              </>
            ),
            image: earn1,
          },
          {
            title: (
              <StepTitle>
                Claim <span>Rewards</span>
              </StepTitle>
            ),
            description: (
              <>
                Once you are on the STIP sub-tab of the Rewards tab, simply click <Bold>Claim</Bold> to claim your rewards for each pool. 
                You will be asked to confirm the transaction in your wallet. Once confirmed, the ARB tokens will be transferred to your wallet.
              </>
            ),
            image: earn2,
          },
        ]}
        key={ShellGuideModalTabs.EARN}
        finalText="View rewards"
        finalAction={() =>
          props.handleNavigateToTradeScreen &&
          props.handleNavigateToTradeScreen(ShellGuideModalTabs.EARN)
        }
      />
    ),
  },
  {
    title: ShellGuideModalTabs.TRY,
    icon: <TryIcon />,
    activeIcon: <TryIconActive />,
    childrenComponent: (props: ChildrenComponentProps) => <SelectionSection redirectAction={props.handleNavigateToTradeScreen} />,
  },
];

export const ShellGuideModalBody = ({
  onClose,
  guideTab,
}: ShellGuideModalBodyProps) => {
  const [activeTab, setActiveTab] = React.useState(guideTab);
  const isMobile = useWidthBreakpoint(breakpoints.mobile);
  const navigate = useNavigate();

  const handleSetActiveTab = (tab: ShellGuideModalTabs) => setActiveTab(tab);
  const handleSetActiveTabToOne = () =>
    setActiveTab(ShellGuideModalTabs.SWAP_FUNGIBLE);

  const onCloseAsync = () =>
    new Promise<void>((resolve) => {
      onClose(activeTab);
      setTimeout(() => {
        resolve();
      }, 1000);
    });

  const handleNavigateToTradeScreen = async (
    action: ShellGuideModalTabs | string
  ) => {
    await onCloseAsync();
    const actionMap: { [key: string]: string } = {
      [ShellGuideModalTabs.SWAP_FUNGIBLE]: "swap",
      [ShellGuideModalTabs.PROVIDE_LIQUIDITY]: "liquidity",
      [ShellGuideModalTabs.SWAP_LENDING]: "lending",
      // [ShellGuideModalTabs.NFTS]: "nfts",
      [ShellGuideModalTabs.LP]: "trade",
      [ShellGuideModalTabs.STAKE]: "stake",
      [ShellGuideModalTabs.EARN]: "earn",
    };

    if(action === ShellGuideModalTabs.EARN) { 
      navigate("/rewards/stip"); 
    } else {
      navigate("/trade", { state: { guideAction: actionMap[action] || action } });
    }
  };

  useEffect(() => {
    const preloadImages = [
      shellGuideSmallIcon,
      closeButton,
      swapFungible1,
      swapFungible2,
      swapFungible3,
      provideLiquidity1,
      provideLiquidity2,
      provideLiquidity3,
      lendingTokens1,
      lendingTokens2,
      lendingTokens3,
      nfts1,
      nfts2,
      nfts3,
      nfts4,
      trade1,
      earn1,
      earn2,
      stake1,
      stake2,
      shellStargate,
      protocolsImage,
    ];
    preloadImages.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  return (
    <ModalBodyContainer>
      <ModalHeaderContainer>
        <ModalBodyTitleContainer>
          <TitleIcon src={shellGuideSmallIcon} alt="Shell Guide Icon" />
          <TitleText>Shell Guide</TitleText>
        </ModalBodyTitleContainer>
        <CloseButton
          style={{ position: "relative" }}
          src={closeButton}
          onClick={() => onClose(activeTab)}
          alt="close button"
        />
      </ModalHeaderContainer>
      <ModalContentContainer>
        <LeftContainer>
          {isMobile ? (
            <SelectionTopicMobileLayer
              topics={menuItems}
              selectedTopic={
                menuItems.find((el) => el.title === activeTab) || menuItems[0]
              }
              handleSelectTopic={handleSetActiveTab}
              handleSetToFirstTopic={handleSetActiveTabToOne}
            />
          ) : (
            <>
              <NavContainer>
                {menuItems.map((item) => (
                  <ModalNavItem
                    key={item.title}
                    title={item.title}
                    icon={
                      activeTab === item.title ? item.activeIcon : item.icon
                    }
                    active={item.title === activeTab}
                    click={() => handleSetActiveTab(item.title)}
                  />
                ))}
              </NavContainer>
              <SupportContainer>
                <SupportText>Shell already supports</SupportText>
                <ComingContainer>
                  <ProtocolsImage src={protocolsImage} alt="protocols" />
                  <ComingText>More coming soon!</ComingText>
                </ComingContainer>
              </SupportContainer>
            </>
          )}
        </LeftContainer>
        <MainContainer>
          <MainContent>
            {menuItems
              .find((item) => item.title === activeTab)
              ?.childrenComponent?.({
                handleSetActiveTabToOne,
                handleNavigateToTradeScreen,
              })}
          </MainContent>
        </MainContainer>
      </ModalContentContainer>
    </ModalBodyContainer>
  );
};

const ModalBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 680px;

  ${Media.mobile} {
    height: 100dvh;
    overflow-y: hidden;
  }
`;

const ModalHeaderContainer = styled.div`
  padding: 15px 24px 15px 30px;
  max-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${Media.mobile} {
    padding: 16px 24px 16px 16px;
  }
`;

const ModalBodyTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
`;

const TitleIcon = styled.img`
  width: 13.33px;
  height: 13.33px;
`;

const TitleText = styled.span`
  color: var(--Pacifiic-Harbour, #a9d1e2);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const CloseButton = styled.img`
  border-radius: 6px;

  cursor: pointer;

  color: white;

  &:hover {
    opacity: 0.7;
  }
`;

const ModalContentContainer = styled.div`
  border-top: 1px solid var(--dark-2, #171b33);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 0;

  ${Media.mobile} {
    flex-direction: column;
    gap: 0;
    overflow-y: hidden;
  }
`;

const LeftContainer = styled.div`
  padding: 10px 12px 24px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 313px;
  min-height: 616px;
  max-height: 616px;

  ${Media.mobile} {
    padding: 16px 19px;
    min-height: 74px;
    max-height: 74px;
  }
`;

const NavContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin-left: 12px;
`;

const SupportText = styled.p`
  color: #7d7d97;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ComingText = styled.p`
  color: #a2bcd4;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ComingContainer = styled.div`
  display: flex;
  gap: 7px;
  justify-content: flex-start;
  align-items: center;
`;

const ProtocolsImage = styled.img`
  height: 24px;
`;

const MainContainer = styled.div`
  width: 70%;
  border-left: 1px solid var(--dark-2, #171b33);
  overflow-y: auto;

  ${Media.mobile} {
    width: 100%;
    border-left: none;
  }
`;

const MainContent = styled.div``;

const StepTitle = styled.h3`
  color: #fff;

  /* Title 7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;

  span {
    background: var(
      --gradient-2,
      linear-gradient(90deg, #37dcf2 0.87%, #07c0fb 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    /* Title 7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
  color: #fff;
`;

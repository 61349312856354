import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Media, breakpoints } from "../../styles";
import { SearchIcon } from "@/components/Icons/SearchIcon";
import { ethers } from "ethers";
import { useWidthBreakpoint } from "@/hooks";
import { reduceString } from "@/utils/reduceString";
import { scrollbar } from "@/styles/scrollbar";
import { useSearch } from "@/hooks/useSearch";
import { RankComponent } from "../Wallet/Wallet";
import { useLeaderboard } from "./ExploreContext";

export const ExploreSearch = () => {
  const navigate = useNavigate();
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState<{ address: string; rank: number }[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const { leaderboard } = useLeaderboard();

  const leaderboardMap = leaderboard.reduce((acc, entry) => {
    acc[entry.address] = entry.id;
    return acc;
  }, {});

  let searchableTokenProperties = ["address"];
  if (!searchValue.startsWith("0x") && !isNaN(Number(searchValue)))
    searchableTokenProperties = ["id"];
  const searchResults = useSearch(
    leaderboard,
    searchableTokenProperties,
    searchValue
  );

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const suggestionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.target?.value.trim();
    setSearchValue(value);

    if (value === "" || value === "0" || value === "0x") {
      setSearchSuggestions([]);
    } else {
      const suggestions = searchResults
        .map((item) => {
          const rank = leaderboardMap[item.address];
          return {
            address: item.address,
            rank: rank ? rank : -1,
          };
        })
        .slice(0, 5);
      setSearchSuggestions(suggestions);
    }
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    switch (event.key) {
      case "ArrowUp":
        setSelectedIndex((prevIndex) => {
          if (typeof prevIndex !== "number") {
            return null;
          }
          return Math.max(0, prevIndex - 1);
        });
        break;
      case "ArrowDown":
        setSelectedIndex((prevIndex) => {
          if (typeof prevIndex !== "number") {
            return 0;
          }
          return Math.min(searchSuggestions.length - 1, prevIndex + 1);
        });
        break;
      case "Enter":
        if (
          typeof selectedIndex === "number" &&
          selectedIndex >= 0 &&
          selectedIndex < searchSuggestions.length
        ) {
          const selectedSuggestion = searchSuggestions[selectedIndex].address;
          handleSearchEnter(selectedSuggestion);
        } else {
          handleSearchEnter();
        }
        break;
      default:
        break;
    }

    setTimeout(() => {
      if (dropdownRef.current && typeof selectedIndex === "number") {
        const dropdown = dropdownRef.current;
        const selectedItem = suggestionRefs.current[selectedIndex];

        if (!selectedItem) return;

        const style = window.getComputedStyle(selectedItem);
        const marginTop = parseInt(style.marginTop, 10) || 0;
        const marginBottom = parseInt(style.marginBottom, 10) || 0;

        const itemTop = selectedItem.offsetTop - marginTop;
        const itemBottom = itemTop + selectedItem.offsetHeight + marginBottom;

        if (itemBottom > dropdown.scrollTop + dropdown.offsetHeight) {
          dropdown.scrollTop = itemBottom - dropdown.offsetHeight;
        } else if (itemTop < dropdown.scrollTop) {
          dropdown.scrollTop = itemTop;
        } else if (selectedIndex === 0) {
          dropdown.scrollTop = 0;
        } else if (selectedIndex === searchSuggestions.length - 1) {
          dropdown.scrollTop = dropdown.scrollHeight - dropdown.offsetHeight;
        }
      }
    });
  };

  const handleSearchEnter = useCallback(
    (suggestionValue?: string) => {
      const valueToSearch = suggestionValue || searchValue;
      if (!valueToSearch.trim()) return;

      if (!valueToSearch.startsWith("0x")) {
        // Rank search
        const rank = parseInt(valueToSearch.slice(1));
        const entry = leaderboard.find((entry) => entry.id === rank);
        if (entry) {
          navigate(`/wallet/${entry?.address}`, { state: { rank: entry?.id } });
          return;
        }
      }

      if (ethers.utils.isAddress(valueToSearch)) {
        // Wallet search
        const entry = leaderboard.find(
          (entry) => entry.address === valueToSearch
        );
        navigate(`/wallet/${valueToSearch}`, { state: { rank: entry?.id } });
        return;
      }

      navigate(`/tx/${valueToSearch}`); // Default: Tx hash search
    },
    [searchValue, navigate]
  );

  useEffect(() => {
    if (searchValue && searchValue !== "0" && searchValue !== "0x") {
      const suggestions = searchResults
        .map((item) => ({
          address: item.address,
          rank: leaderboardMap[item.address] || -1,
        }))
        .slice(0, 5);
      setSearchSuggestions(suggestions);
    } else {
      setSearchSuggestions([]);
    }
  }, [searchValue, searchResults.length, leaderboardMap.length]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        setSearchSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SearchInputContainer ref={searchContainerRef}>
      <SearchInput
        value={searchValue}
        onChange={(event) => {
          handleSearchInputChange(event);
        }}
        onKeyDown={handleSearchKeyDown}
        placeholder="Search by txn hash / wallet address / rank #"
      />
      {searchSuggestions.length > 0 && (
        <SearchSuggestionsContainer itemCount={searchSuggestions.length}>
          <SearchSuggestionsDropdown
            itemCount={searchSuggestions.length}
            ref={dropdownRef}
          >
            {searchSuggestions.map((suggestion, index) => (
              <SuggestionItem
                ref={(el) => (suggestionRefs.current[index] = el)}
                key={suggestion.rank}
                onClick={() => {
                  setSearchValue(suggestion.address);
                  setSearchSuggestions([]);
                  handleSearchEnter(suggestion.address);
                }}
                style={
                  selectedIndex === index ? { backgroundColor: "#292941" } : {}
                }
              >
                {isTablet
                  ? reduceString(suggestion.address, 12, 10)
                  : suggestion.address}
                <RankComponent
                  rank={suggestion.rank}
                  height="0.5rem"
                  fontSize="12px"
                  padding="10px"
                />
              </SuggestionItem>
            ))}
          </SearchSuggestionsDropdown>
        </SearchSuggestionsContainer>
      )}
      <SearchIconContainer onClick={() => handleSearchEnter()}>
        <SearchIcon />
      </SearchIconContainer>
    </SearchInputContainer>
  );
};
const SearchIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 2px solid rgb(55, 220, 242);
`;

const SearchInputContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid #171b33;
  padding: 8px;
  padding-left: 16px;
  border-radius: 16px;
  gap: 10px;
  //margin-bottom: 16px;
  transition: all 0.3s;

  &:focus,
  &:hover,
  &:active,
  &:focus-within {
    border-color: rgb(55, 220, 242);
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: white;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: #7d7d97;
  }

  ${Media.tablet} {
    font-size: 12px;
  }
`;

const SearchSuggestionsContainer = styled.div<{ itemCount: number }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-right: 8px;
  z-index: 1000;
  background-color: #171b33;
  border-radius: 16px;
  margin-top: 10px;
  box-sizing: border-box;
  overflow: hidden;
  height: ${({ itemCount }) => (itemCount > 3 ? "212px" : "auto")};
  box-shadow: rgb(10, 14, 39) 0rem 3rem 12rem 6px;
`;

const SearchSuggestionsDropdown = styled.div<{ itemCount: number }>`
  padding-left: 0.5rem;
  padding-right: ${({ itemCount }) => (itemCount > 3 ? "0.5rem" : "0")};
  overflow-x: hidden;
  max-height: 100%;
  overflow-y: ${({ itemCount }) => (itemCount > 3 ? "auto" : "hidden")};
  ${scrollbar({
    border: "16px",
    width: "6px",
    marginTop: "12px",
    marginBottom: "12px",
    backgroundColor: "#171b33",
    scrollbarColor: "#2C5173",
  })};
`;

const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
  border-radius: 16px;
  margin: 12px 0;
  gap: 10px;

  &:hover {
    background-color: #292941;
  }

  ${Media.tablet} {
    padding: 12px;
    font-size: 12px;
  }
`;
import React from "react";
import styled from "styled-components";
import { DescriptionBox } from "./DescriptionBox";
import { Media } from "../../../styles";
import { lbpTime } from "@/pages/Trade/DeFiToken/LBPTokenInfo";
import { formatDisplay } from "@/utils/formatDisplay";

export const DescriptionBoxes = ({
  withoutMarketCap,
  withoutEnding,
  lbpData
}: {
  withoutMarketCap: boolean;
  withoutEnding: boolean
  lbpData: any
}) => {

  const getsEndsInTime = () => {
    const duration = lbpData.endTime - Math.floor(Date.now() / 1000)
    if(duration <= 0){
        window.location.reload()
        return '0m'
    } else {
        return lbpTime(Math.floor(Date.now() / 1000), lbpData.endTime)
    }
  }
  return (
    <DescriptionBoxesContainer>
      {!withoutEnding && 
        <DescriptionBox
            title='Ends In'
            description={getsEndsInTime()}
            key={'endsin'}
        />
      }
      {!withoutMarketCap && 
        <DescriptionBox
          title='Market Cap'
          description={`$${formatDisplay(lbpData.marketCap.toString(), 0)}`}
          key={'marketcap'}
        />
      }
      <DescriptionBox
          title='Liquidity'
          description={`$${formatDisplay(lbpData.liquidity.toString(), 0)}`}
          key={'liquidity'}
      />
      <DescriptionBox
          title='Total Volume'
          description={`$${formatDisplay(lbpData.totalVol.toString(), 0)}`}
          key={'totalVol'}
      />
    </DescriptionBoxesContainer>
  );
};

const DescriptionBoxesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${Media.tablet} {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: 123px 123px;
    align-items: stretch;
    height: auto;
    flex-wrap: wrap;
  }
`;

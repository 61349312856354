import React, { useState, useContext, useMemo } from "react";
import styled from "styled-components";
import { TokenLogo } from "../../../../../components/TokenLogo";
import { tokenLogos } from "../../../../../constants/tokenLogos";
import { Media } from "../../../../../styles";
import { DexBadge } from "@/components/DexBadge/DexBadge";
import { isExternalToken } from "@/utils/tokens";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { USDValue } from "@/pages/Trade/InputPanel";
import { StakeButtons } from "./StakeButtons";
import { TradeWidget } from "@/pages/Trade/TradeWidget";
import { View as TradeView } from "../../../../Trade/TradeScreen";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { rewardTokenID } from "@/pages/Trade/DeFiToken/STIPFooter";

interface STIPTaleProps {
  row: any;
  isActive: boolean;
}

export const STIPTale = ({ row, isActive }: STIPTaleProps) => {
  const { name, subname, color, reward, apy, staked, stakedPrice, rewardPrice } = row;
  const LogoIcon = tokenLogos[name] ? tokenLogos[name] : Logo;
  const { tokenMap } = useContext(ChainContext);

  let protocol, protocolVersion;
  if (isExternalToken(tokenMap[name])) {
    // @ts-ignore
    protocol = tokenMap[name].tokenType;
    // @ts-ignore
    protocolVersion = tokenMap[name].protocolVersion ?? "";
  }

  const [selected, setSelected] = useState<{ [id: string]: string }>({});

  const onTableRowClick = (id: string, action: string) => {
    const newSelected = { ...selected };

    if (selected[id]) {
      setSelected((prevState) => ({
        ...prevState,
        [id]: "",
      }));
      setTimeout(() => {
        Object.keys(newSelected).forEach((key) => {
          if (key !== id) {
            newSelected[key] = "";
          }
        });
        if (newSelected[id] == action) {
          newSelected[id] = "";
        } else {
          newSelected[id] = action;
        }
        setSelected(newSelected);
      }, 100);
    } else {
      Object.keys(newSelected).forEach((key) => {
        if (key !== id) {
          newSelected[key] = "";
        }
      });
      if (newSelected[id] == action) {
        newSelected[id] = "";
      } else {
        newSelected[id] = action;
      }
      setSelected(newSelected);
    }
  };

  const showTradeWidget = useMemo(() => {
    return Object.values(selected).find((e) => e == "Stake" || e == "Unstake") !== undefined;
  }, [selected]);

  const selectedToken = useMemo(() => {
    return Object.keys(selected).find((key) => selected[key] == "Stake" || selected[key] == "Unstake") ?? "";
  }, [selected]);

  const stipPoolInfo = [
    {
      title: "APY",
      value: `${formatDisplayShorthand(apy * 100, 2)}%`,
      color: "rgb(122, 222, 185)",
    },
    {
      title: "Staked",
      balance: formatDisplayShorthand(staked, 2),
      value: stakedPrice > 0 ? `($${formatDisplayShorthand(staked * stakedPrice, 2)})` : "",
      color: color,
    },
    {
      title: "Reward",
      balance: formatDisplayShorthand(reward, 2),
      value: rewardPrice > 0 ? `($${formatDisplayShorthand(reward * (rewardPrice ?? 0), 2)})` : "",
      color: color,
    },
  ];

  return (
    <Tale>
      <TaleHeader openDisabled={true}>
        <PoolsTableRowMobileHeader>
          <LogoIcon size={48} />
          <FlexContainer>
            <Title>{name} </Title>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Description>{subname}</Description>
              {protocol && <DexBadge protocol={protocol} protocolVersion={protocolVersion ?? ""} />}
            </div>
          </FlexContainer>
        </PoolsTableRowMobileHeader>

        {stipPoolInfo?.length ? (
          <InfoContainer>
            {stipPoolInfo?.map(({ title, value, color, balance }) => (
              <InfoBox key={title + value}>
                {title !== "Status" && <InfoTitle>{title}</InfoTitle>}
                <BalanceAndValueContainer>
                  {balance !== undefined && (
                    <>
                      <Balance pending={false}>{balance}</Balance>
                      <TokenIcon src={tokenMap[title == "Staked" ? name : rewardTokenID].icon} />
                    </>
                  )}
                  {title === "APY" ? (
                    parseFloat(value ?? "0") > 0 ? (
                      <InfoValue color={color}>{value}</InfoValue>
                    ) : (
                      <SkeletonBox isLoading width="48px" height="16px" borderRadius="4px" />
                    )
                  ) : value ? (
                    <USDValue color={color}>{value}</USDValue>
                  ) : (
                    <SkeletonBox isLoading width="48px" height="16px" borderRadius="4px" />
                  )}
                </BalanceAndValueContainer>
              </InfoBox>
            ))}
          </InfoContainer>
        ) : null}
        <TaleDetailsPreview>
          <StakeButtons
            isActive={isActive}
            row={row}
            chosenAction={selected[row.name] ?? ""}
            onClick={(action: string) => {
              onTableRowClick(row.name, action);
            }}
            isSTIPTale={true}
          />
        </TaleDetailsPreview>
        {showTradeWidget && (
          <StyledTradeScreen>
            <TradeWidget
              initInput={[selected[selectedToken] == "Unstake" ? tokenMap[selectedToken + "-STIP"] : tokenMap["ETH"]]}
              initOutput={[selected[selectedToken] == "Stake" ? tokenMap[selectedToken + "-STIP"] : tokenMap["ETH"]]}
              lockedTokens={new Set([selectedToken + "-STIP"])}
              refreshPage={true}
              disableSwap
            />
          </StyledTradeScreen>
        )}
      </TaleHeader>
    </Tale>
  );
};

const Blur = styled.div<{ color?: string }>`
  width: 578px;
  height: 578px;
  position: absolute;
  bottom: -578px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 366px;
  opacity: 0.2;
  background: ${({ color }) => color || "#72CCD7"};
  filter: blur(75px);
  z-index: 0;
  transition: all 0.3s ease;
`;

const Tale = styled.div`
  width: 100%;
  max-width: 100%;
  border: 1px solid #1e2239;
  border-radius: 16px;
  background-color: #151530;

  & + & {
    margin-top: 10px;
  }
`;

const Logo = styled(TokenLogo)`
  margin-right: 18px;
`;

const Balance = styled.p<{ pending: boolean }>`
  color: ${({ pending }) => (pending ? "#00BDFF" : "#FFF")};
  /* Text 2/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TaleHeader = styled.div<{ openDisabled: boolean }>`
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--dark-3, #1e2239);
  background: var(--dark-4, #151530);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: #2c5173;
    ${Blur} {
      border-radius: 578px;
      bottom: -480px;
    }
  }

  ${Media.tablet} {
    padding: 16px;
  }
`;

const TaleDetailsPreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
  & svg {
    margin-left: 8px;
  }
`;

const PoolsTableRowMobileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & img {
    width: 48px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--white, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
  max-width: 100%;
  word-break: break-word;
  white-space: normal;
`;

const Description = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoTitle = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoValue = styled.div<{ color?: string }>`
  color: ${({ color }) => color || "#fff"};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const BalanceAndValueContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
`;

const StyledTradeScreen = styled.div`
  border: 1px solid #171b33;
  border-radius: 16px;
  padding: 8px;
  ${TradeView} {
    padding: 0;
  }

  ${Media.mobile} {
    padding: 0;
    border-radius: 0;
    border: none;
  }
`;

const TokenIcon = styled.img`
  width: 18px;
  height: 18px;
`;

import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import styled, { keyframes } from "styled-components";
import shellLogoWhite from "@/assets/icons/shell-logo-white.svg";
import smallShellLogo from "@/assets/icons/small-shell-logo.svg";
import modalMask from "@/assets/masks/modal-mask.png";
import { Media } from "@/styles";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ConfirmationBodyContainer = styled.div<{ hasMask?: boolean }>`
padding: 56px 24px 24px 24px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
gap: 24px;
background-image: ${({ hasMask }) => hasMask ? `url(${modalMask})` : "none"};
background-position: top;
background-repeat: no-repeat;
background-size: 430px 177px;
transition: all .5s ease-in-out;

${Media.tablet} {
  padding:56px 20px 32px;
  background-size: 100% 177px;
  gap: 20px;
}
`;

export const BodyContainer = styled.div<{ hasMask?: boolean }>`
padding: 24px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 24px;
background-image: ${({ hasMask }) => hasMask ? `url(${modalMask})` : "none"};
background-position: top;
background-repeat: no-repeat;
background-size: 430px 177px;
transition: all .5s ease-in-out;

${Media.tablet} {
  padding: 16px 20px 32px;
  gap: 20px;
}
`;

export const ClaimAirDropBodyContainer = styled(BodyContainer)`
gap: 24px;

${Media.tablet} {
  gap: 20px;
}
`;

export const ClaimAirDropDetailsBox = styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
border-radius: 16px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
width: 100%;
`;

export const ClaimAirDropDetailsBoxInfo = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 16px;
`;

export const ClaimAirDropDetailsNFT = styled.img`
width: 56px;
height: 56px;
border-radius: 8px;
`;

export const ClaimAirDropDetailsInfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
`;

export const ClaimAirDropDetailsInfo = styled.p`
color: var(--White, #FFF);

/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`;

export const HrDiv = styled.div`
height: 1px;
width: 100%;
background: #171B33;
`;

export const ClaimAirDropDetailsDesc = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const ClaimAirDropDetails = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
gap: 12px;
`;

export const DetailsOneRow = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`;

export const DetailsLabel = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const DetailsValue = styled.p`
color: var(--White, #FFF);
text-align: right;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const SideWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 4px;
`;

export const TitleAndDescriptionContainer = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
justify-content: flex-start;
align-items: flex-start;
max-width: 92%;
`;

export const Description = styled.p`
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */

a {
  cursor: pointer;
  text-decoration: none;
  background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
`;

export const IncreaseLockPeriodInfoBox = styled.div`
display: flex;
/* width: 606px; */
width: 100%;
height: 112px;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 4px;
border-radius: 16px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
`;

export const LockInfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: start;
`;

export const LockPeriod = styled.p`
color: #FFF;
text-align: center;

/* Title 5 */
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.9px;
`;

export const SuccessIcon = styled.img``;

export const ErrorIcon = styled.img``;

export const CoinIcon = styled.img`
width: 32px;
height: 32px;
`;

export const SmallCoinIcon = styled.img`
width: 16px;
height: 16px;
`;

export const SuccessTextWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 12px;
justify-content: center;
align-items: center;
`;

export const BoxNFT = styled.div`
width: 100%;
padding: 20px 16px;
display: flex;
justify-content: flex-start;
gap: 16px;
align-items: center;
border-radius: 12px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
`;

export const StreamPreview = styled.div`
width: 100%;
padding: 20px 16px;
display: flex;
justify-content: space-between;
gap: 16px;
align-items: center;
border-radius: 12px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
`;

export const ImageNFT = styled.img`
width: 56px;
height: 56px;
border-radius: 8px;
`;

export const LabelNFT = styled.p`
color: var(--White, #FFF);

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;
`;


export const InputsWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 6px;
align-items: center;
justify-content: flex-start;
width: 100%;
`;

export const ButtonsWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 16px;
align-items: center;
justify-content: flex-start;
`;

export const CustomPrimaryBtn = styled(ButtonPrimary)`
width: 100%;
border-radius: 12px;
height: unset;
padding: 20px 30px;

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border-color: #1E2239;
background: #151530;
`}
`;

export const CustomSecondaryBtn = styled(ButtonSecondary)`
width: 100%;
border-radius: 12px;
height: unset;
padding: 20px 30px;
border-width: 2px;
`;

export const SuccessTitle = styled.h2`
color: var(--White, #FFF);
text-align: center;

/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;

span {
  background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 34px;
letter-spacing: -0.48px;
}
`;

export const SuccessDescription = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */

span {
    color: var(--Pacifiic-Harbour, #A9D1E2);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%;
}
`;

export const LockPeriodInfoText = styled(SuccessDescription)``;

export const ConfirmationLoader = styled.img`
animation: ${rotate360} 3s linear infinite;
width: 64px;
height: 64px;
`;

export const LoaderLabelContainer = styled.div`
display: flex;
gap: 12px;
flex-direction: column;
width: 100%;
justify-content: flex-start;
align-items: center;
`;

export const LoaderLabel = styled.p`
color: var(--White, #FFF);
text-align: center;
/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`;

export const LoaderSublabel = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const InfoLabelContainer = styled.div`
/* margin-top: 16px; */
padding: 12px;
display: flex;
justify-content: flex-start;
align-items: center;
width: 100%;
gap: 12px;
border-radius: 8px;
background: rgba(0, 189, 255, 0.05);
`;

export const InfoLabelIcon = styled.img`
width: 24px;
height: 24px;
`;

export const InfoLabel = styled.p`
color: var(--Shell-Sea, #00BDFF);
/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const CustomInputLabelContainer = styled.div`
display: flex;
gap: 8px;
justify-content: flex-start;
align-items: center;
`;

export const CustomInputLabelTokenName = styled.div`
display: flex;
flex-direction: column;
gap: 1px;
justify-content: center;
align-items: flex-start;
`;

export const InputTokenName = styled.p`
color: var(--White, #FFF);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const InputTokenSymbol = styled.p`
color: #7D7D97;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const ShellIcon = styled.div`
position: relative;
width: 28px;
height: 28px;

&::before{
  content: url(${smallShellLogo});
  height: 28px;
  width: 28px;
  position: absolute;
  top: 0;
}

&::after {
  content: url(${shellLogoWhite});
  height: 18px;
  width: 13px;
  position: absolute;
  top: 2px;
  left: 6px;
}
`;

export const ModalTitle = styled.h2`
color: var(--White, #FFF);

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;

${Media.mobile} {
  font-size: 18px;
}
`;

export const GeneralStakeInfoContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
align-items: flex-start;
`;

export const GeneralSingleInfoWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
width: 100%;
border-bottom: 1px solid #1E2239;

&:nth-last-child() {
    border-bottom: none;
}
`;

export const GeneralSingleLeftSide = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 4px;
`;

export const GeneralSingleRightSide = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 8px;

${Media.mobile} {
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}
`;

export const GeneralInfoLabel = styled.p`
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const GeneralInfoValueWrapper = styled.div`
display: flex;
justify-content: center;
gap: 4px;
align-items: center;
`;

export const ShellCoinImg = styled.img`
width: 16px;
height: 16.25px;
`;

export const ValueWrapper = styled.div`
display: flex;
gap: 8px;
justify-content: flex-start;
align-items: center;
`;

export const GeneralInfoGrayText = styled.p`
text-align: right;
color: var(--grey-3, #7D7D97);
text-align: center;
/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const GeneralInfoWhiteText = styled.p`
color: var(--White, #FFF);
text-align: center;
/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
text-align: right;
`;

export const ArrowImg = styled.img`
width: 20px;
height: 20px;
`;

export const MaxBtn = styled(ButtonSecondary)`
height: unset;
width: auto;
padding: 16px 24px;
border-radius: 12px;

${Media.mobile} {
  padding: 8px 20px;
  font-size: 14px;
}
`;

export const CustomPrimaryBtnStake = styled(ButtonPrimary)`
//margin-top: -8px;
width: 100%;
border-radius: 12px;
height: unset;
padding: 20px 30px;

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border-color: #1E2239;
background: #151530;
`}
`;

export const LockPeriodsWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 12px;
width: 100%;
margin: 8px 0;
`;

export const LockPeriodBoxesWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: stretch;
gap: 8px;
width: 100%;

${Media.mobile} {
  flex-wrap: wrap;
  flex-basis: calc(40%);
  align-items: center;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;

  :nth-child(odd) {
    margin-right: 8px;
  }

  :nth-child(1) {
    margin-bottom: 8px;
  }

  :nth-child(2) {
    margin-bottom: 8px;
  }

  :nth-child(even) {
    margin-right: 0;
  }
}
`;

export const LockPeriodTitleWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 4px;
`;

export const LockPeriodTitle = styled.p`
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const LockPeriodDescription = styled.p`
width: 100%;
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 16.8px */
`;

export const AvailableBalanceWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 4px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;
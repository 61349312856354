import React from "react";
import styled, { css } from "styled-components";

interface IPaginationProps {
  pagesLength: number;
  currentPage: number;
  pageSize: number;
  objectsLength: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  handleSetNextPage: () => void;
  handleSetPrevPage: () => void;
}

export const Pagination = ({
  pagesLength,
  currentPage,
  objectsLength,
  isFirstPage,
  isLastPage,
  pageSize,
  handleSetNextPage,
  handleSetPrevPage,
}: IPaginationProps) => {
  return objectsLength > pageSize ? (
    <PaginationContainer>
      <PaginationButton isDisabled={isFirstPage} onClick={handleSetPrevPage}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PaginationButton>
      <PaginationContent>
        {currentPage} of {pagesLength}
      </PaginationContent>
      <PaginationButton isDisabled={isLastPage} onClick={handleSetNextPage}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PaginationButton>
    </PaginationContainer>
  ) : null;
};

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const PaginationButton = styled.div<{ isDisabled: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #37dcf2;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

const PaginationContent = styled.div`
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #464659;
  border-radius: 8px;
  pointer-events: none;
`;

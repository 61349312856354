import React from 'react';
import styled from 'styled-components';
import { tokenColors } from '../../constants/tokenColors';
import { extract1155Data } from '../../utils/nftHelpers';

export interface Share {
  symbol: string,
  percentage: string
  metadata?: string;
}
interface CompositionItemProps {
  inactive?: boolean
  asset: Share
  hideLabel?: boolean
  as?: React.ElementType
  onClick?: (asset: Share) => void
}

export const CompositionItem = ({ inactive, asset, hideLabel, as, onClick }: CompositionItemProps) => {
  const { symbol, percentage, metadata } = asset

  const data1155 = extract1155Data(symbol)

  const displaySymbol = data1155 ? data1155.item.name : symbol
  const displayColor = data1155 ? tokenColors[data1155.symbol] : metadata ? tokenColors[metadata] : tokenColors[symbol] ?? '#FFFFFF'
  
  return (
    <Item key={displaySymbol} percentage={percentage} as={as || 'li'} onClick={() => onClick && onClick(asset)}>
      <Line color={displayColor} inactive={inactive} />
      <div style={{ height: '34px', textAlign: 'center', verticalAlign: 'top' }}>
        {!hideLabel && `${percentage} ${displaySymbol}`}
      </div>
    </Item>
  )
}

interface ItemProps {
  percentage: string
}

export const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: ${({ percentage }) => parseInt(percentage)};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;

  & + & {
    margin-left: 6px;
  }
`;

interface LineProps {
  color: string
}

const Line = styled.div<LineProps & Pick<CompositionItemProps, 'inactive'>>`
  height: 6px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 14px;
  background-color: ${({ color, inactive }) => inactive ? '#464659' : color};
`;

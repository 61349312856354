import React from "react";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import infoIcon from "@/assets/icons/info-icon.svg";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import * as S from "./ConfirmationModals.styled";
import { useRewardsContext } from "../../RewardsContext";
import { formatDisplay } from "@/utils/formatDisplay";
import { AirDropType } from "../../hooks/useAirDropHandler";

    const InputCustomLabel = ({ airdrop }: { airdrop: AirDropType }) => {
    return (
        <S.CustomInputLabelContainer>
        <S.CoinIcon src={airdrop.icon} alt="airdrop coin" />
        <S.CustomInputLabelTokenName>
            <S.InputTokenName>{airdrop.name}</S.InputTokenName>
            <S.InputTokenSymbol>{airdrop.subname}</S.InputTokenSymbol>
        </S.CustomInputLabelTokenName>
        </S.CustomInputLabelContainer>
    );
    };

    export const ClaimOtherModalBody = () => {
    const {
        handleOtherAirdropClose,
        otherClaimTxSubmitted,
        otherClaimSuccess,
        currentAirdrop 
    } = useRewardsContext();

        return (
            <S.ConfirmationBodyContainer hasMask={otherClaimSuccess}>
                {otherClaimSuccess ? (
                    <>
                        <S.SuccessIcon src={successIcon} alt="success icon" />
                        <S.SuccessTextWrapper>
                            <S.SuccessTitle>Claim successful!</S.SuccessTitle>
                            <S.SuccessDescription>
                            Your <span>{currentAirdrop ? formatDisplay(currentAirdrop.reward.toString(), 2) : ''}</span>
                            {currentAirdrop?.name} have been successfully claimed.
                            </S.SuccessDescription>
                        </S.SuccessTextWrapper>
                        <Input
                            label="Rewards"
                            disabled
                            value={currentAirdrop ? formatDisplay(currentAirdrop.reward.toString(), 2) : ''}
                            additionalLabel={currentAirdrop && <InputCustomLabel airdrop={currentAirdrop} />}
                        />
                        <S.CustomPrimaryBtn style={{marginTop: "16px"}} onClick={handleOtherAirdropClose}>Close</S.CustomPrimaryBtn>
                    </>
                ) : (
                    <>
                        <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                        <S.LoaderLabelContainer>
                            <S.LoaderLabel>
                                Claiming your {currentAirdrop?.name}...
                            </S.LoaderLabel>
                            <S.LoaderSublabel>
                                You're claiming {currentAirdrop ? formatDisplay(currentAirdrop.reward.toString(), 2) : ''} {currentAirdrop?.name}
                            </S.LoaderSublabel>
                        </S.LoaderLabelContainer>
                        {currentAirdrop && (
                            <Input
                                label="Rewards"
                                disabled
                                value={formatDisplay(currentAirdrop.reward.toString(), 2)}
                                additionalLabel={<InputCustomLabel airdrop={currentAirdrop} />}
                            />
                        )}
                        {!otherClaimTxSubmitted && 
                        <S.InfoLabelContainer>
                            <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                            <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                        </S.InfoLabelContainer>
                        }
                    </>
                )}
            </S.ConfirmationBodyContainer >
        )
    };

import React from "react";
import styled from "styled-components";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { formatDisplay, formatDisplayShorthand } from "@/utils/formatDisplay";

type ApyBadgeProps = {
  apy: number | undefined;
  isLoading?: boolean;
};

export const ApyBadge = ({ apy, isLoading }: ApyBadgeProps) => {
  if (isLoading) {
    return (
      <SkeletonBox
        isLoading={true}
        height="22px"
        width="77px"
        borderRadius="10px"
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            alignContent: "center",
            flexWrap: "nowrap",
        }}
      />
    );
  }

  if (typeof apy === "number") {
    if(apy <= 0) return <></>
    return (
      <ApyWrapper>
        <ApyText>{apy.toFixed(2)}% APY</ApyText>
      </ApyWrapper>
    );
  }

  return null;
};

type TvlBadgeProps = {
  tvl: number | undefined;
  isLoading?: boolean;
};

export const TvlBadge = ({ tvl, isLoading }: TvlBadgeProps) => {
  if (isLoading) {
    return (
      <SkeletonBox
        isLoading={true}
        height="22px"
        width="77px"
        borderRadius="10px"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          alignContent: "center",
          flexWrap: "nowrap",
        }}
      />
    );
  }


  if (typeof tvl === "number") {
    if(tvl <= 0) return <></>
    return (
      <TvlWrapper>
        <TvlText>${formatDisplayShorthand(tvl, 2, 1e3)} TVL</TvlText>
      </TvlWrapper>
    );
  }

  return null;
};

const ApyWrapper = styled.div`
  padding: 3px 6px;
  border-radius: 10px;
  border: 1px solid rgba(122, 222, 185, 0.1);
  display: flex;
  -webkit-box-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  width: max-content;
`;

const ApyText = styled.p`
  color: var(--Algea, #7adeb9);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

const TvlWrapper = styled.div`
  padding: 3px 6px;
  border-radius: 10px;
  border: 1px solid rgba(135, 192, 221, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  width: max-content;
`;

const TvlText = styled.p`
color: var(--Dolphin, #87C0DD);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
import { useSearch } from "@/hooks/useSearch";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Content } from "@/components/Layout/Content";
import { SearchInput } from "@/components/TokensModal/SearchInput";
import { Media } from "@/styles";
import { Box } from "@/components/Layout/Box";
import { LeaderboardTable } from "./LeaderboardTable/LeaderboardTable";
import { usersMap } from "./LeaderboardTable/usersMap";
import { SHELL_API_URL } from "@/constants/urls";
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";
import { useAccount } from "wagmi";
import { LogoLoader, Spinner } from "@/components/Loaders";
import { addLeaderboard } from "@/store/leaderboardsSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { BackArrowIcon } from "@/components/Icons/BackArrowIcon";
import { useNavigationHelpers } from "@/hooks/navigation";
import { CURRENT_SEASON, convertToSlug } from "@/types/ChartTypes";

export const LeaderboardScreen = () => {
  const [activeSeason, setActiveSeason] = useState<string>('Season Five');
  const [transactionSearchValue, setTransactionSearchValue] = useState<string>("");
  const [transactions, setTransactionData] = useState<any[]>([]);
  const [snapshotTime, setSnapshotTime] = useState('')

  const { handleGoBack } = useNavigationHelpers();

  const dispatch = useAppDispatch();
  const leaderboards = useAppSelector((state) => state.leaderboards.leaderboards);
  const dates = useAppSelector((state) => state.leaderboards.dates);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    let isMounted = true;          
    setIsLoading(true)

    const slug = convertToSlug(activeSeason)
    if(leaderboards[slug]){
        setTransactionData(leaderboards[slug])
        setSnapshotTime(timestampToDate(dates[slug]))
        setIsLoading(false)
    } else {
        fetch(SHELL_API_URL + 'points/leaderboard/' + slug).then((response) => response.json()).then((result) => {
            if(isMounted){

                const leaderboard = Object.keys(result.leaderboard).map((wallet, index) => {
                    return (
                        {
                            id: index + 1,
                            address: wallet,
                            points: formatDisplay(result.leaderboard[wallet], 0),
                            clickLink: wallet,
                        }
                    )
                })

                dispatch(addLeaderboard({ id: slug, value: leaderboard, date: result.time }));

                setTransactionData(leaderboard)
                setSnapshotTime(timestampToDate(result.time))
                setIsLoading(false)
            }
        })
    }
    return () => { isMounted = false };
  }, [activeSeason])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isAddress = transactionSearchValue.startsWith("0x");
  const isRank = !isNaN(Number(transactionSearchValue)) && Number(transactionSearchValue) > 0;
  const isSearchActive = transactionSearchValue.length > 0 && transactionSearchValue !== "0";
  const searchResults = useSearch(transactions,  isAddress ? ["address"] : isRank ? ["id"] : [], transactionSearchValue);
  const displayTransactions = isSearchActive ? searchResults : transactions;
  
  return (
    <Content>
      <View>
        <TableContainer>
          <TableHeader>
            <TableTitle onClick={() => handleGoBack("/points")}>
              <BackArrowIcon/>
              Leaderboard
            </TableTitle>
          </TableHeader>
          <ButtonsContainer>
            <SeasonButton
              onClick={() => setActiveSeason('Season Five')}
              active={activeSeason === 'Season Five'}
            >
              Season Five
            </SeasonButton>
            <SeasonButton
              onClick={() => setActiveSeason('Season Four')}
              active={activeSeason === 'Season Four'}
            >
              Season Four
            </SeasonButton>
            <SeasonButton
              onClick={() => setActiveSeason('Season Three')}
              active={activeSeason === 'Season Three'}
            >
              Season Three
            </SeasonButton>
            <SeasonButton
              onClick={() => setActiveSeason('Season Two')}
              active={activeSeason === 'Season Two'}
            >
              Season Two
            </SeasonButton>
            <SeasonButton
              onClick={() => setActiveSeason('Season One')}
              active={activeSeason === 'Season One'}
            >
              Season One
            </SeasonButton>
            {/* <SeasonButton
              onClick={() => setActiveSeason(0)}
              active={activeSeason === 0}
            >
              Season Zero
            </SeasonButton> */}
          </ButtonsContainer>
          
          {isLoading ? 
          <LoadingWrapper>
            <CircleContainer>
                <Spinner/>
            </CircleContainer>
          </LoadingWrapper>
          :
          <>
            <StyledSearchContainer>
                <SearchInput
                value={transactionSearchValue}
                onChange={(event) =>
                    setTransactionSearchValue(event.target.value)
                }
                placeholder="Type Rank # or wallet address"
                />
            </StyledSearchContainer>
            {displayTransactions.length === 0 ? 
              <NoResultsMessage>No matches found.</NoResultsMessage> : 
              <LeaderboardTable transactions={displayTransactions} isSearchActive={isSearchActive} key={transactionSearchValue} isShell={activeSeason == 'Season Four' || activeSeason == 'Season Five'} />
            }
            {snapshotTime && <Label>{`Last updated ${snapshotTime}`}</Label>}
          </>
          }
        </TableContainer>
      </View>
    </Content>
  );
};

const NoResultsMessage = styled.div`
  padding: 20px;
  text-align: center;
  clor: #7D7D97;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background-color: #0a0e27;
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;

  ${Media.tablet} {
    font-size: 20px;
    gap: 8px;
  }
`;

const View = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 40px;

  ${Media.tablet} {
    padding: 16px 8px 40px;
  }
`;

const StyledSearchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
`;

const SeasonButton = styled.div<{ active?: boolean }>`
  margin-top: 20px;
  padding: 16px 24px;
  color: #7d7d97;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 16px;
  border: 2px solid #292941;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    font-weight: 600;
    background: #171b33;
    border-color: #171b33;
  }
  ${({ active }) =>
    active &&
    css`
      color: #fff;
      font-weight: 600;
      background: #171b33;
      border-color: #171b33;
    `};
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 245px 0 245px 0;
`

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: auto;
`

const Label = styled.p`
  font-weight: 100;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  margin-top: 24px;
  color: #7D7D97;
  white-space: nowrap;
`;

import { createSlice } from '@reduxjs/toolkit'
import { allChains } from '@/placeholders/chains'

interface MetricsState {
    data: Record<string, any>,
}

const initialData: Record<string, any> = {}

Object.keys(allChains).forEach((chain) => {
    initialData[chain] = {}
})

const initialState: MetricsState = { data: initialData }

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    updateMetrics: (state, action) => {
        const { chain, data } = action.payload;
        if (!state.data[chain]) {
            state.data[chain] = {};
        }
        state.data[chain] = data;
    },
    clearMetrics: (state, action) => {
        const { chain } = action.payload;
        if (state.data[chain]) {
            state.data[chain] = {};
        }
    },
    clearAllMetrics: () => {
        return initialState;
    },
  }
})

export const { updateMetrics, clearMetrics, clearAllMetrics } = metricsSlice.actions

export default metricsSlice.reducer
import React from "react";
import styled from "styled-components";
import {
  Area,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { Media, breakpoints } from "@/styles";
import {
  getFormattedChartDayDate,
  getFormattedChartHalfDate,
} from "@/utils";
import { roundToFirstTwoNonZeroDecimals } from "@/pages/Statistics/StatsTooltip";
import { useWidthBreakpoint } from "@/hooks";
import CustomAreaChart from "@/pages/Rewards/components/Points/components/CustomAreaChart";

export const BeefyChart = ({ chartData }: any) => {
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const tokenColor = "#7adeb9";

  const todayData = chartData.at(-1);

  return (
    <ChartWrapper>
      <ResponsiveContainer width={472} height={100}>
        <StyledAreaChart
          margin={{
            top: isTablet ? 0 : 8,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient
              id={"data"}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              key={"data"}
              data-defs-test={"data"}
            >
              <stop offset="0%" stopColor={`${tokenColor}4D`} stopOpacity={1} />
              <stop
                offset="100%"
                stopColor={`${tokenColor}4D`}
                stopOpacity={0.2}
              />
            </linearGradient>
            <linearGradient
              id={"compoundedPoints"}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              key={"compounded"}
              data-defs-test={"compounded"}
            >
              <stop offset="0%" stopColor={`#0ABCFD4D`} stopOpacity={1} />
              <stop offset="100%" stopColor={`#0ABCFD4D`} stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            padding={{ left: 0, right: isTablet ? 0 : 4 }}
            interval="preserveStartEnd"
            color={"#7d7d97"}
            fill={"#7d7d97"}
            scale={"utc"}
            tickFormatter={(date) => getFormattedChartDayDate(date)}
            allowDuplicatedCategory={false}
          />
          <YAxis
            orientation="right"
            tickFormatter={(tick) => `${tick}%`}
            axisLine={false}
            tickLine={false}
            color="#7d7d97"
            fill={"#7d7d97"}
            padding={{ top: isTablet ? 0 : 4 }}
            yAxisId="apy"
          />
          <YAxis
            orientation="left"
            tickFormatter={(tick) => `$${formatDisplayShorthand(tick, 0, 1e3)}`}
            axisLine={false}
            tickLine={false}
            color="#7d7d97"
            fill={"#7d7d97"}
            padding={{ top: isTablet ? 0 : 4 }}
            yAxisId="tvl"
          />
          <Tooltip
            cursor={{ stroke: "#171B33", strokeWidth: 2 }}
            content={<CustomTooltip />}
          />
          <>
            <Area
              key="pastData"
              type="monotone"
              data={chartData}
              dataKey="apy"
              stroke={tokenColor}
              fillOpacity={0}
              fill={`transparent`}
              data-area-test={"pastData"}
              activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
              stackId="1"
              strokeWidth={2}
              yAxisId='apy'
            />
            <Area
              key="pastData2"
              type="monotone"
              data={chartData}
              dataKey="tvl"
              stroke={'#FFFFFF'}
              fillOpacity={0}
              fill={`transparent`}
              data-area-test={"pastData"}
              activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
              stackId="2"
              strokeWidth={2}
              yAxisId='tvl'
            />
            <ReferenceDot
              x={todayData.date}
              y={todayData.value}
              fill={tokenColor}
              stroke={tokenColor}
              r={3}
            />
          </>
        </StyledAreaChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<Object>;
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (
    active &&
    payload &&
    payload.some(
      (item: any, index) =>
        item.name && item.payload[item.name] && item.payload[item.name] > 0
    )
  ) {
    return (
      <TooltipContainer>
        <TooltipDate>
          {getFormattedChartHalfDate((payload[0] as any).payload.date)}
        </TooltipDate>
        {payload.map((item: any, index) => {
          if (
            item.name &&
            item.payload[item.name] &&
            item.payload[item.name] > 0
          ) {
            return (
              <TooltipRow>
                <TooltipRowText>
                  <TooltipRowLabel color={item.color} capitalized={false}>
                    {item.name.toUpperCase()}:
                  </TooltipRowLabel>
                  {item.name && item.payload[item.name] && (
                    <div style={{ display: "inline", marginLeft: "4px" }}>
                      {item.name == "apy"
                        ? `${roundToFirstTwoNonZeroDecimals(item.value)}%`
                        : `$${formatDisplayShorthand(item.value)}`}
                    </div>
                  )}
                </TooltipRowText>
              </TooltipRow>
            );
          }
        })}
      </TooltipContainer>
    );
  }
  return <></>;
};

const ChartWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .xAxis {
    .recharts-cartesian-axis-tick {
      &:last-child {
        text {
          transform: translateX(-14px);
        }
      }
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: #7d7d97;
    }
  }

  ${Media.tablet} {
    padding: 12px;
    padding-right: 0;
  }
`;

const StyledAreaChart = styled(CustomAreaChart)`
  .recharts-xAxis {
    transform: translateY(8px);
  }

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  ${Media.tablet} {
    .recharts-xAxis {
      transform: translateY(0px);
    }
  }
`;

const Legend = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;

const Line = styled.div<{ color: string }>`
  width: 24px;
  height: 0px;
  border: 2px solid ${({ color }) => color};
`;

const DashLine = styled.div<{ color: string }>`
  width: 24px;
  height: 0px;
  border: 2px dashed ${({ color }) => color};
`;

const Button = styled.div`
  background: #171b33;
  border-radius: 10px;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  padding: 10px 12px;
  border: 1px solid #1e2239;
  border-radius: 14px;
  background-color: #171b33;
`;

const TooltipDate = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #7d7d97;
`;

const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
`;

const TooltipRowIcon = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  color: #00bdff;

  & > svg {
    width: 12px;
    height: 12px;
    color: #00bdff;
  }
`;

const TooltipRowText = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
`;

const TooltipRowLabel = styled.span<{ color?: string; capitalized?: boolean }>`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${({ color }) => color ?? "inherit"};
  text-transform: ${({ capitalized }) => (capitalized ? "capitalize" : "none")};
`;

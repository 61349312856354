import React, { createContext, ReactNode, useContext } from "react";
import { useRewardsContextData } from "./useRewardsContextData";

type RewardsContextType = ReturnType<typeof useRewardsContextData>;

const RewardsContext = createContext<RewardsContextType | null>(null);

type RewardsContextProviderProps = {
    children: ReactNode;
};

export const useRewardsContext = () => {
    const context = useContext(RewardsContext);

    if (!context) {
        throw new Error("useRewardsContext hooks used outside a Rewards Context Provider");
    }

    return context;
}

export const RewardsContextProvider = ({
    children,
} :RewardsContextProviderProps) => {
    const contextItems = useRewardsContextData();

    return (
        <RewardsContext.Provider value={contextItems}>
            {children}
        </RewardsContext.Provider>
    )
};
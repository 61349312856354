import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "../../components/Layout/Box";
import { Content } from "../../components/Layout/Content";
import { Area, ReferenceDot } from "recharts";
import { isFuture, parseISO } from "date-fns";
import { AreaChartWithPredicted } from "../../components/Charts/AreaChartWithPredicted";
import { Header } from "./Header";
import { ChartHeader } from "./ChartHeader";
import { DescriptionBoxes } from "./DescriptionBoxes/DescriptionBoxes";
import { Media } from "../../styles";
import { About } from "./About";
import { Statistics } from "./Statistics";
import { ButtonSecondary } from "../../components/Buttons/Button";
import { useParams } from "react-router-dom";
import { tokenColors } from "@/constants/tokenColors";
import { isLBPToken } from "@/utils/tokens";
import { useLocation } from "react-router-dom";
import { View as TradeView } from "../Trade/TradeScreen";
import lbpStatic from "@/assets/lbp/lbp-static.png";
import { Gallery } from "./Gallery";
import { TradeWidget } from "../Trade/TradeWidget";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { addMetadata, addPriceChart } from "@/store/lbpsSlice";
import { LogoLoader } from "@/components/Loaders";
import { HISTORY_API, LBP_API } from "@/constants/urls";
import { addTransactionCount, addTransaction } from "@/store/transactionsSlice";
import { formatDisplay } from "@/utils/formatDisplay";
import { PoolHistory, skipInterval } from "../Pools/PoolHistory";
import { buildTransactions } from "../Transactions/TransactionHistory";
import { TabGroup } from "../Statistics/Statistics";
import { StatsTooltip, roundToFirstTwoNonZeroDecimals } from "../Statistics/StatsTooltip";
import { extract1155Data } from "@/utils/nftHelpers";
import { ChainContext } from "@/components/Overlays/ChainProvider";

const images = [
  "toucan1",
  "toucan2",
  "toucan3",
  "toucan4",
  "toucan5",
  "toucan6",
  "toucan7",
  "toucan8",
  "toucan9",
  "toucan10",
  "toucan11",
  "toucan12",
];

export const LBPScreen = () => {
  const location = useLocation();
  let tokenType;
  if (location.state) {
    tokenType = location.state.tokenType;
  }

  const { tokenMap, connectedChain } = useContext(ChainContext)

  const { tokenName } = useParams()
  const token = tokenMap[tokenName ?? ""];

  const data1155 = extract1155Data(token.symbol)

  if(!isLBPToken(token)) return <></>
  
  const [chartOrGalerry, setChartOrGalerry] = useState<string>("chart");
  const views: string[] = ["Details", "Transaction History"];
  const [activeView, setActiveView] = useState<string>(views[0]);

  const metadata = useAppSelector((state) => state.lbps.metadata);
  const priceChart = useAppSelector((state) => state.lbps.priceChart);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [lbpData, setLBPData] = useState<any>({});
  const [chartData, setChartData] = useState<any>([])

  const tokenColor = tokenColors[data1155 ? data1155.symbol : token.symbol]

  const pastData = chartData.filter(
    (item : any) =>
      !isFuture(parseISO(new Date(Number(item.date) * 1000).toISOString()))
  );
  const futureData = [
    pastData[pastData.length - 1],
    ...chartData.filter((item : any) =>
      isFuture(parseISO(new Date(Number(item.date) * 1000).toISOString()))
    ),
  ];
  
  const todayData = futureData[0];

  const fetchLBPInfo = async () => {
    
    if (metadata[token.symbol]) return metadata[token.symbol];
    return fetch(LBP_API(connectedChain) + token.poolName)
      .then((response) => response.json())
      .then((data) => {
        dispatch(addMetadata({ name: token.symbol, data: data }));
        return data;
      })
      .catch(() => {});
  };

  const fetchPriceChart = async () => {

    if (priceChart[token.symbol]) return priceChart[token.symbol];
    return fetch(`${HISTORY_API(connectedChain)}lbp/${token.poolName}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(addPriceChart({ name: token.symbol, data: data }));
        return data;
      })
      .catch(() => {});
  };

  const queryTransactions = async () => {
    const apiData = await fetch(`${HISTORY_API(connectedChain)}pool/${token.poolName}/0`).then(
      (response) => response.json()
    );

    const transactionData: any = apiData.transactions.data;
    const transactionHashes: string[] = Object.keys(transactionData).slice(0, skipInterval);

    dispatch(addTransactionCount({ chain: connectedChain.name, address: token.poolName, count: apiData.transactions.count }));

    const txs = buildTransactions(transactionData, transactionHashes)

    dispatch(addTransaction({ chain: connectedChain.name, address: token.poolName, skip: 0, data: txs }));
  };

  useEffect(() => {
    // Fetch balances from contract if not already in Redux state and update store
    setLoading(true);
    Promise.all([
      fetchLBPInfo(),
      fetchPriceChart(),
    ]).then(([lbpResult, chartResult]) => {
        if(chartResult){
            setChartData(chartResult)
        }
        if (lbpResult) {
          lbpResult = JSON.parse(JSON.stringify(lbpResult))
          lbpResult.startBalances[0] /= (data1155 ? 100 : 1)
          lbpResult.currentBalances[0] /= (data1155 ? 100 : 1)
          setLBPData(lbpResult);
          setCountdown(lbpResult.startTime - Math.floor(Date.now() / 1000));
          setLoading(false);
        }
    });
  }, []);

  useEffect(() => {
    queryTransactions()
  }, [])

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!loading && token.status == 'Upcoming') {
        setCountdown(() => {
          const currentTime = Math.floor(Date.now() / 1000);
          const diff = lbpData.startTime - currentTime; //TODO: fix
          if (diff < 1) {
            window.location.reload();
            return 0;
          } else {
            return diff;
          }
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [loading]);

  const formatTime = (time: number) => {
    if (time < 10) {
      return "0" + time.toString();
    } else {
      return time.toString();
    }
  };

  const timeChunks = [
    formatTime(Math.floor(countdown / 86400)), // Days
    formatTime(Math.floor((countdown % 86400) / 3600)), //Hours
    formatTime(Math.floor((countdown % 3600) / 60)), //Minutes
    formatTime(countdown % 60), //Seconds
  ];

  return (
    <Content>
      {loading ? (
      <LoadingWrapper>
        <LogoLoader />
      </LoadingWrapper>
      ) : (
      <View>
        <Header token={token} />
        {token.status !== "Upcoming" ? (
          <DescriptionBoxes
            withoutMarketCap={token.status === "Ended" || tokenType === "NFT"}
            withoutEnding={token.status === "Ended"}
            lbpData={lbpData}
          />
        ) : null}
        <GridContainer>
          {token.status === "Upcoming" ? (
            <UpcomingBox>
              <UpcomingBoxTitle>{data1155 ? token.name : token.symbol} LBP</UpcomingBoxTitle>
              <UpcomingBoxDescription>
                Starts in:
              </UpcomingBoxDescription>
              <UpcomingBoxTimeContainer>
                {timeChunks.map((chunk, index) => {
                    return (
                        <>
                            <UpcomingBoxTimeBoxes>
                            <UpcomingBoxTimeBox>
                                <span>{chunk[0]}</span>
                            </UpcomingBoxTimeBox>
                            <UpcomingBoxTimeBox>
                                <span>{chunk[1]}</span>
                            </UpcomingBoxTimeBox>
                            </UpcomingBoxTimeBoxes>
                            {index != timeChunks.length - 1 && <UpcomingBoxTimeColon>:</UpcomingBoxTimeColon>}
                        </>
                    )
                })}
              </UpcomingBoxTimeContainer>
              <BoxImage src={lbpStatic} />
            </UpcomingBox>
          ) : (
            <>
              {chartOrGalerry === "chart" ? (
                <AreaChartWithPredicted
                  header={
                    <ChartHeader
                      title={`${data1155 ? token.name : token.symbol} Price`}
                      amount={`$${roundToFirstTwoNonZeroDecimals(parseFloat(todayData.value))}`}
                    //   percent="+28.88%"
                      withButtons={false}
                      isActive={chartOrGalerry}
                      onClickButton={(value) => setChartOrGalerry(value)}
                    />
                  }
                  tooltip={<StatsTooltip type={'price'}/>}
                  allowDuplicatedCategory={false}
                  todayData={todayData}
                  tokenColor={tokenColor}
                  prefixTick="$"
                  areas={
                    <>
                      <Area
                        key="pastData"
                        type="monotone"
                        data={pastData}
                        dataKey="value"
                stroke={tokenColor}
                        fillOpacity={0}
                        fill={`transparent`}
                        data-area-test={"pastData"}
                activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
                        stackId="1"
                        strokeWidth={2}
                      />
                      <ReferenceDot
                        x={todayData.date}
                        y={todayData.value}
                        fill={tokenColor}
                        stroke={tokenColor}
                        r={3}
                      />
                      <Area
                        key="futureData"
                        type="monotone"
                        data={futureData}
                        dataKey="value"
                        stroke={tokenColor}
                        fillOpacity={0}
                        fill={`transparent`}
                        data-area-test="futureData"
                        activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
                        stackId="1"
                        strokeDasharray={"5 3"}
                        strokeWidth={2}
                      />
                      <Area
                        key="data"
                        type="monotone"
                        data={chartData}
                        dataKey="value"
                        stroke="transparent"
                        fillOpacity={1}
                        fill={`url(#data)`}
                        data-area-test="data"
                        stackId="1"
                      />
                    </>
                  }
                />
              ) : (
                <Gallery
                  images={images}
                  isActive={chartOrGalerry}
                  onClickButton={(value) => setChartOrGalerry(value)}
                />
              )}
            </>
          )}
          <StyledTradeScreen>
            <TradeWidget 
                initInput={[tokenMap[token.pairToken]]} 
                initOutput={[token]} 
                lockedTokens={new Set([data1155 ? data1155.symbol : token.symbol])}
                refreshPage={token.status !== "Ended"}
            />
          </StyledTradeScreen>
        </GridContainer>
        {token.status !== "Upcoming" && (
          <TabGroup
            views={views}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        )}
        {activeView === "Details" ? (
          <FlexContainer>
            <Statistics token={token} lbpData={lbpData} data1155={data1155}/>
            <About token={token} lbpData={lbpData} />
          </FlexContainer>
        ) : <PoolHistory poolName={token.poolName}/>
        }
      </View>
    )}
    </Content>
  );
};

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 300px 0 300px 0;
`

const View = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 40px;

  ${Media.tablet} {
    padding-inline: 8px;
  }
`;

const FlexContainer = styled.div<{ row?: boolean }>`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  // align-items: center;
  justify-content: center;
  gap: 20px;

  ${Media.tablet} {
    flex-direction: column;
  }
`;

const StyledTradeScreen = styled.div`
  border: 1px solid #171b33;
  border-radius: 16px;
  padding: 8px;
  ${TradeView} {
    padding: 0;
  }
`;

const GridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: calc(100% - 394px) 375px;
  gap: 16px;
  ${Media.tablet} {
    grid-template-columns: calc(100vw - 48px);
  }
`;

const UpcomingBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;
  min-height: 400px;
  border-radius: 16px;
  border: 1px solid var(--dark-2, #171b33);
`;

const UpcomingBoxTitle = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.9px;
`;
const UpcomingBoxDescription = styled.div`
  color: var(--grey-3, #7d7d97);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 136%; /* 21.76px */
`;
const UpcomingBoxTimeContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;
const UpcomingBoxTimeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid var(--dark-3, #1e2239);
  background: var(--dark-4, #151530);
  span {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.9px;
    background: var(
      --gradient-2,
      linear-gradient(135deg, #37dcf2 0%, #07c0fb 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${Media.mobile}{
        font-size: 12px;
    }
  }
`;

const BoxImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const UpcomingBoxTimeBoxes = styled.div`
  display: flex;
  gap: 4px;
`;

const UpcomingBoxTimeColon = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.9px;
  color: var(--white, #fff);
`;

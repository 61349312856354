import React, { ReactNode, useState } from "react";
import * as S from "./CustomTooltip.styled";

type CustomTooltipProps = {
  position: string;
  title: string;
  subtext: string | ReactNode;
  delay: number;
  children?: ReactNode;
};

export const CustomTooltip = ({ position, title, subtext, delay, children } :CustomTooltipProps) => {
  let timeout :ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };


    let TooltipComponent;

    switch (position) {
      case 'top':
        TooltipComponent = S.TooltipTop;
        break;
      case 'right':
        TooltipComponent = S.TooltipRight;
        break;
      case 'bottom':
        TooltipComponent = S.TooltipBottom;
        break;
      case 'left':
        TooltipComponent = S.TooltipLeft;
        break;
      default:
        TooltipComponent = S.TooltipTop;
    }

    return (
      <S.TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
        {children}
        <S.TooltipImage>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_8144_606)">
              <path
                d="M6.99972 12.8337C10.2214 12.8337 12.833 10.222 12.833 7.00033C12.833 3.77866 10.2214 1.16699 6.99972 1.16699C3.77805 1.16699 1.16638 3.77866 1.16638 7.00033C1.16638 10.222 3.77805 12.8337 6.99972 12.8337Z"
                stroke="#7D7D97"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 9.33333V7"
                stroke="#7D7D97"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 4.66699H7.00667"
                stroke="#7D7D97"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_8144_606">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </S.TooltipImage>
        <TooltipComponent isActive={active}>
          <S.TooltipContent>
            <S.TooltipTitle>{title}</S.TooltipTitle>
            <S.TooltipSubtext>{subtext}</S.TooltipSubtext>
          </S.TooltipContent>
        </TooltipComponent>
      </S.TooltipWrapper>
    );
  };
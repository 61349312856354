import { useState, useEffect } from "react";
import { NFT, VestingStream } from "../utils/tokens";

export type orderByType = "id" | "rarity" | "claimable" | "vesting" |  null;
export type orderType = "asc" | "desc" | null;

export const useNFTSort = (
  list: NFT[],
  order: orderType,
  orderBy: orderByType
) => {
  const [sortedList, setSortedList] = useState(list);

  useEffect(() => {
    let sorted: NFT[];

    if (order && orderBy) {
      sorted = [...list].sort((a: NFT, b: NFT) => {
        if (orderBy === "id" || orderBy === "rarity") {
          return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        } else if (orderBy === "claimable" || orderBy === "vesting") {
          const aVesting = a as VestingStream;
          const bVesting = b as VestingStream;
          return order === "asc" ? +aVesting[orderBy] - +bVesting[orderBy] : +bVesting[orderBy] - +aVesting[orderBy];
        } else {
          // Handle null case or any other custom sorting logic
          return 0;
        }
      });

      setSortedList(sorted);
    } else {
      setSortedList(list);
    }
  }, [list, order, orderBy]);

  return sortedList;
};

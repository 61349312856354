import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import {
  DropdownIconDown,
  DropdownIconUp,
} from "../../../../../components/Icons/DropdownIcon";
import { PointsIcon } from "../../../../../components/Icons/PointsIcon";
import { TokenLogo } from "../../../../../components/TokenLogo";
import { tokenLogos } from "../../../../../constants/tokenLogos";
import { Media } from "../../../../../styles";
import {
  formatDisplay,
  formatDisplayShorthand,
} from "../../../../../utils/formatDisplay";
import { TokenProps } from "../PointsTable";
import { NFTTaleDetails } from "./NFTTaleDetails";
import { PointsTaleDetails } from "./PointsTaleDetails";
import { isExternalToken } from "@/utils/tokens";
import { DexBadge } from "@/components/DexBadge/DexBadge";
import shellIcon from "@/assets/coins/SHELL.svg";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface PointsTaleProps {
  token: TokenProps;
  openDisabled: boolean
}

export const PointsTale = ({ token, openDisabled }: PointsTaleProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState<boolean>(false);
  const {
    name,
    subname,
    color,
    visible,
    balance,
    value,
    rewardAPY,
    totalPoints,
    wallet,
    type
  } = token;
  const LogoIcon = tokenLogos[name] ? tokenLogos[name] : Logo;

  const { tokenMap } = useContext(ChainContext)

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    setIsMoreDetailsOpen(false);
  };

  let protocol, protocolVersion

  if(isExternalToken(tokenMap[name])){
    // @ts-ignore
    protocol = tokenMap[name].tokenType
    // @ts-ignore
    protocolVersion = tokenMap[name].protocolVersion ?? ''
  }


  return (
    <Tale>
      <TaleHeader openDisabled={openDisabled}>
        <TokenInfoWrapper>
          <LogoIcon size={30} />
          <TokenInfo>
            {name && <TokenName title={name}>{name}</TokenName>}
            {/* {subname && (
              <TokenSubname title={subname} color={color}>
                {subname}
              </TokenSubname>
            )} */}
            <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <TokenSubname color={color}>{subname}</TokenSubname>
                {protocol && <DexBadge protocol={protocol} protocolVersion={protocolVersion}/>}
            </div>
          </TokenInfo>
        </TokenInfoWrapper>
        <TaleDetailsPreview>
          {`${formatDisplayShorthand(totalPoints)}`}
          {openDisabled ? <PointsIcon /> : <img style={{width: '20px', height: '20px', margin: '0 2px 0 6px'}} src={shellIcon}/>}
        </TaleDetailsPreview>
        {!openDisabled && <DropdownButton onClick={handleToggle}>
          {isExpanded ? <DropdownIconUp /> : <DropdownIconDown />}
        </DropdownButton>}
      </TaleHeader>
      {isExpanded && (
        <>
          <TaleData>
            <TaleDetailsItem>
              <TaleDetailsTitle>Balance</TaleDetailsTitle>
              <TaleDetailsValue>
                {`
                  ${formatDisplay(balance.balance, balance.decimals)} 
                `}
              </TaleDetailsValue>
            </TaleDetailsItem>
            <TaleDetailsItem>
              <TaleDetailsTitle>Value (USD)</TaleDetailsTitle>
              <TaleDetailsValue>
              {(name === "veSHELL")
                ? "--"
                : `$${formatDisplayShorthand(value)}`}
              </TaleDetailsValue>
            </TaleDetailsItem>
            <TaleDetailsItem>
              <TaleDetailsTitle>Reward APY</TaleDetailsTitle>
              <TaleDetailsValue 
                color={rewardAPY > 0 ? "#7ADEB9" : "white"} 
                glow={rewardAPY > 0}
              >
              {`${rewardAPY > 0 ? formatDisplay((rewardAPY * 100).toString(), 2) : '--'}%`}
              </TaleDetailsValue>
            </TaleDetailsItem>
            <TaleDetailsItem>
              <TaleDetailsTitle>Total Reward</TaleDetailsTitle>
              <TaleDetailsValue color={"#00BDFF"}>
                {`${formatDisplay(totalPoints.toString(), 0)}`}
              </TaleDetailsValue>
            </TaleDetailsItem>
          </TaleData>
          {isMoreDetailsOpen && (
            <TaleDetails>
              {token.type == 'NFT' ? <NFTTaleDetails token={token}/> : <PointsTaleDetails token={token} />}
            </TaleDetails>
          )}
          {name !== "veSHELL" && (
          <MoreDetailsButton
            onClick={() => setIsMoreDetailsOpen(!isMoreDetailsOpen)}
            visible={visible}
          >
            {isMoreDetailsOpen ? "See less" : "See more"}
            {isMoreDetailsOpen ? <DropdownIconUp /> : <DropdownIconDown />}
          </MoreDetailsButton>
          )}
        </>
      )}
    </Tale>
  );
};

const Tale = styled.div`
  width: 100%;
  max-width: 100%;
  border: 1px solid #1e2239;
  border-radius: 16px;
  background-color: #151530;

  & + & {
    margin-top: 10px;
  }
`;

const TaleHeader = styled.div<{openDisabled: boolean}>`
  --column-width: ${props => (props.openDisabled ? 0 : 8)}px;
  display: grid;
  grid-template-columns: 50% calc(50% - var(--column-width)) 20px;
  
  position: relative;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 16px 24px 16px 16px;
  overflow: hidden;

  & img {
    width: 30px;
    margin-right: 0;
  }

  ${Media.smallMobile} {
    padding: 8px 24px 8px 8px;
  }
`;

const Logo = styled(TokenLogo)`
  margin-right: 18px;
`;

const TokenInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  max-width: 100%;
`;

const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  max-width: 100%;
`;

const TokenName = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TokenSubname = styled.span<{ color?: string }>`
  font-size: 12px;
  line-height: 14px;
  color: ${({ color }) => color ?? "#A9D1E2"};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DropdownButton = styled.button`
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  width: 20px;
  height: 20px;
  padding: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TaleData = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
  padding: 8px 16px 24px;
  width: 100%;
  max-width: 100%;

  ${Media.smallMobile} {
    padding: 8px 16px 24px;
  }
`;

const TaleDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TaleDetailsTitle = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #7d7d97;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TaleDetailsValue = styled.span<{ color?: string, glow?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ color }) => color ?? "#FFFFFF"};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ glow }) => glow && css`
    -webkit-animation-name: glow;
    -moz-animation-name: glow;
    animation-name: glow;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    @keyframes glow {
        0% {
            text-shadow: 0 0 1px #00BDFF;
        }
        
        50% {
            text-shadow: 0 0 4px #00bdff;
        }

        100% {
            text-shadow: 0 0 1px #00bdff;
        }
    }
  `};

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    color: #00bdff;
  }
`;

const TaleDetailsPreview = styled(TaleDetailsValue)`
  justify-content: right;

  & svg {
    margin-left: 8px;
  }
`;

const TaleDetails = styled.div`
  padding: 20px 16px 22px;
  background: linear-gradient(180deg, #0f0f29 0%, rgba(21, 21, 48, 0) 100%);

  ${Media.smallMobile} {
    padding: 20px 16px 22px;
  }
`;

const MoreDetailsButton = styled.button<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  align-items: center;
  margin: 0px auto 18px auto;
  width: fit-content;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 100;
  color: #ffffff;

  & svg {
    margin-left: 10px;
  }
`;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { PointsIcon } from '../../../../components/Icons/PointsIcon';
import { TableFilterDropdown } from '../../../../components/Table/TableFilterDropdown';
import { tokenColors } from '../../../../constants/tokenColors';
import { isShellToken } from '../../../../utils/tokens';
import { getFormattedChartDayDate, getFormattedChartHalfDate } from '../../../../utils/getFormattedChartDate';
import { formatDisplayShorthand } from '../../../../utils/formatDisplay';
import { useWidthBreakpoint } from '../../../../hooks';
import { breakpoints, Media } from '../../../../styles';
import CustomAreaChart from "./components/CustomAreaChart";
import { dateFilters, DateFilter, CURRENT_SEASON } from "../../../../types/ChartTypes";
import { subDays, isAfter } from "date-fns";
import { SkeletonBox } from '../../../../components/Loaders/SkeletonBox';
import { ChainContext } from '@/components/Overlays/ChainProvider';
import { veSHELL } from '@/placeholders/arbitrumTokens';

interface ChartData {
  date: number;
  [key: string]: number | string | string[] | undefined;
}

interface TokenWithPoints {
  name: string;
  subname: string;
  color: string;
  balance: number;
  value: number;
  dailyPoints: number;
}

interface TokensPerDay {
  date: number;
  tokens: TokenWithPoints[];
}

interface TokenInfo {
  totalPoints: number;
  historical: {
    [timestamp: string]: number;
  };
  balance: number;
  value: number;
}

interface PointsChartProps {
  tokens: Record<string, TokenInfo> | undefined;
  selectedSeasonFilter?: string;
  selectedDateFilter?: string;
  children?: any;
  isLoading?: boolean;
}

export const stripNonAlphabeticCharacters = (s: string): string => {
  return s
    .replaceAll(/[^a-zA-Z ]/g, "")
    .replaceAll(/\s/g, "")
    .toLowerCase();
};

const tokenLookup: any = {};

export const PointsChart = (props: PointsChartProps) => {
  const { tokens, selectedSeasonFilter, selectedDateFilter, children, isLoading: loadingPoints } = props;
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const { tokenMap } = useContext(ChainContext)

  const [chartData, setChartData] = useState<ChartData[]>([]);

  const getTokensPerDay = (
    tokens: Record<string, TokenInfo>
  ): TokensPerDay[] => {
    
    if(loadingPoints) return []

    const dateTokenPoint: Record<string, TokenWithPoints[]> = {};

    const tokensSortedByInitialEarnDate = Object.keys(tokens)
      .filter((key) => key !== "compoundedPoints")
      .sort(
        (a, b) =>
          Number(Object.keys(tokens[a].historical || {})[0] ?? 0) -
          Number(Object.keys(tokens[b].historical || {})[0] ?? 0)
      );

    tokensSortedByInitialEarnDate.forEach((tokenName) => {
      const { historical = {}, balance, value } = tokens[tokenName];
      const tokenInfo = tokenName == 'veSHELL' ? veSHELL : tokenMap[tokenName];
      const color = isShellToken(tokenInfo)
        ? tokenColors["shLP"]
        : tokenColors[tokenName];
      const subname = isShellToken(tokenInfo)
        ? tokenInfo.oceanID
        : tokenInfo.name;

      tokenLookup[stripNonAlphabeticCharacters(tokenName)] = {
        name: tokenName,
        color,
      };

      for (const date in historical) {
        dateTokenPoint[date] = dateTokenPoint[date] || [];
        dateTokenPoint[date].push({
          name: tokenName,
          subname,
          color,
          balance,
          value,
          dailyPoints: historical[date],
        });
      }
    });

    return Object.keys(dateTokenPoint).map((date) => ({
      date: parseInt(date),
      tokens: dateTokenPoint[date],
    }));
  };

  const transformToChartData = (tokensPerDay: TokensPerDay[]): ChartData[] => {
    return tokensPerDay.map((day) => {
      const chartEntry: ChartData = { date: day.date };
      for (const token of day.tokens) {
        const key =
          stripNonAlphabeticCharacters(token.name) || `token${token.name}`;
        chartEntry[key] = token.dailyPoints;
      }
      return chartEntry;
    });
  };

  const filterDataByDate = (
    data: ChartData[],
    selectedDateFilter: DateFilter | undefined
  ): ChartData[] => {
    if (!selectedDateFilter) return data;

    const { days } = selectedDateFilter;
    const currentDate = new Date();
    const startDate = days
      ? subDays(currentDate, days)
      : new Date(Math.min(...data.map((entry) => entry.date)) * 1000);

    return data.filter((entry) =>
      isAfter(new Date(entry.date * 1000), startDate)
    );
  };

  const tokensPerDay = useMemo(() => {
    return getTokensPerDay(tokens ?? {});
  }, [tokens, selectedSeasonFilter, loadingPoints]);

  const tokenNameColorGroups = useMemo(() => {
    return [
      ...new Set(
        tokensPerDay
          .reverse()
          .map((day) => day.tokens)
          .flat()
          .map((token) => stripNonAlphabeticCharacters(token.name))
      ),
    ];
  }, [tokensPerDay.length, selectedSeasonFilter]);

  useEffect(() => {
    let newChartData = transformToChartData(tokensPerDay);
    if (selectedSeasonFilter === CURRENT_SEASON) {
      newChartData = filterDataByDate(newChartData, dateFilters.find((filter) => filter.label === selectedDateFilter));
    }
    setChartData(newChartData.reverse() ?? []);
  }, [chartData.length]);

  useEffect(() => {
    let newChartData = transformToChartData(tokensPerDay);
    if (selectedSeasonFilter === CURRENT_SEASON) {
      newChartData = filterDataByDate(newChartData, dateFilters.find((filter) => filter.label === selectedDateFilter));
    }
    setChartData(newChartData ?? []);
  }, [tokensPerDay, selectedSeasonFilter, selectedDateFilter]);

  if (loadingPoints) {
    return (
        <SkeletonBox
        isLoading={true}
        height="361px"
        width="100%"
        borderRadius="20px"
        style={{ marginTop: "16px" }}
    />
    )
  }

  return (
    <PointsChartWrapper>
      {children}
      {chartData.length > 0 ? (
        <StyledResponsiveContainer width="100%" height={248}>
          <StyledAreaChart
            data={chartData}
            margin={{
              top: isTablet ? 0 : 8,
              right: 0,
              left: isTablet ? 0 : 12,
              bottom: 0,
            }}
          >
            <defs>
              {tokenNameColorGroups.map((tokenGroup) => {
                const tokenName = tokenGroup;
                const tokenColor = tokenLookup[tokenGroup].color;

                return (
                  <linearGradient
                    id={tokenName}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                    key={tokenName}
                    data-defs-test={tokenName}
                  >
                    <stop
                      offset="0%"
                      stopColor={`${tokenColor}4D`}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={`${tokenColor}4D`}
                      stopOpacity={0.2}
                    />
                  </linearGradient>
                );
              })}
            </defs>
            <XAxis
              dataKey="date"
              scale="time"
              type="number"
              domain={["dataMin", "dataMax"]}
              color={"#7D7D97"}
              axisLine={false}
              tickLine={false}
              padding={{ left: 0, right: isTablet ? 0 : 12 }}
              tickMargin={isTablet ? 10 : 0}
              interval={'preserveStartEnd'}
              tickFormatter={(date) => getFormattedChartDayDate(date)}
              minTickGap={7}
            />
            <YAxis
              orientation="right"
              tickFormatter={(tick) => {
                return formatDisplayShorthand(tick, 0, 1e3);
              }}
              axisLine={false}
              tickLine={false}
              color={'#7D7D97'}
              padding={{ top: isTablet ? 0 : 4 }}
              width={40}
            />
            <Tooltip
              content={<CustomTooltip />}
            />
            {tokenNameColorGroups.map((tokenGroup) => {
              const tokenName = tokenGroup
              const tokenColor = tokenLookup[tokenGroup].color

              return <Area
                  key={tokenName}
                  type="monotone"
                  dataKey={tokenName}
                  stroke={tokenColor}
                  fillOpacity={1}
                  fill={`url(#${tokenName})`}
                  data-area-test={tokenName}
                  activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
                  stackId="1"
                />
            })}
          </StyledAreaChart>
        </StyledResponsiveContainer>
      ) : ( <PointsChartPlaceholder>No Points Earned Yet</PointsChartPlaceholder> )
      }
    </PointsChartWrapper>
  )
}

interface CustomTooltipProps {
  active?: boolean
  payload?: Array<Object>
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.some((item: any, index) => (item.name && item.payload[item.name] && item.payload[item.name] > 0))) {
    return (
      <TooltipContainer>
        <TooltipDate>
          {getFormattedChartHalfDate((payload[0] as any).payload.date)}
        </TooltipDate>
        {payload.reverse().map((item: any, index) => {
          if (item.name && item.payload[item.name] && item.payload[item.name] > 0) {
            return (
              <TooltipRow key={index} >
                <TooltipRowText>
                  <TooltipRowLabel color={item.color} capitalized={false}>{tokenLookup[item.name].name}</TooltipRowLabel>:
                  {(item.name && item.payload[item.name]) && (
                    <div style={{ display: 'inline', marginLeft: '4px' }}>
                      {formatDisplayShorthand(item.payload[item.name])}
                      <TooltipRowIcon>
                        <PointsIcon />
                      </TooltipRowIcon>
                    </div>
                  )}
                </TooltipRowText>
              </TooltipRow>
            )
          }
        })}
      </TooltipContainer >
    )
  }
  return (
    <></>
  )
}

const PointsChartWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  padding: 24px;
  border: 1px solid #171B33;
  border-radius: 20px;
  background-color: #0A0E27;

  .recharts-cartesian-axis-tick {
    text {
      fill: #7d7d97;
    }
  }

  ${Media.tablet} {
    padding: 8px;
  }
`

const PointsChartHeader = styled.div`
  display: flex;
  width: fit-content;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
`

const PointsChartPlaceholder = styled.div`
  width: 100%;
  height: 280px;
  text-align: center;
  vertical-align: middle;
  line-height: 280px;

  a {
    text-decoration: underline;
    color: #00BDFF;

    :visited {
      color: #00BDFF;
    }
  }

  ${Media.mobile}{
    line-height: 24px;
    margin-top: 50%;
    font-size: 12px;
  }
`

const TokensFilterDropdown = styled(TableFilterDropdown)`
  align-items: center;
  height: 32px;
  padding: 0 12px;
  border-radius: 10px;
`

const PointsChartDateButtons = styled.div`
  display: flex;
  width: fit-content;
  column-gap: 6px;
`

const DateButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 56px;
  height: 32px;
  border: 1px solid #292941;
  border-radius: 10px;
  background-color: #1E2239;
  padding: 0 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ active }) => active ? '#FFFFFF' : '#7D7D97'};
  transition: all 0.25s ease;
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 32px;
  z-index: 9999;
`

const StyledAreaChart = styled(CustomAreaChart)`
  .recharts-xAxis {
    transform: translateY(8px);
  }

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  ${Media.tablet} {
    .recharts-xAxis {
      transform: translateY(0px);
    }
  }
`

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  padding: 10px 12px;
  border: 1px solid #1E2239;
  border-radius: 14px;
  background-color: #171B33;
`

const TooltipDate = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #7D7D97;
`

const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
`

const TooltipRowIcon = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  color: #00BDFF;

  & > svg {
    width: 12px;
    height: 12px;
    color: #00BDFF;
  }
`

const TooltipRowText = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #FFFFFF;
  white-space: nowrap;
`

const TooltipRowLabel = styled.span<{ color?: string; capitalized?: boolean }>`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${({ color }) => color ?? 'inherit'};
  text-transform: ${({ capitalized }) => capitalized ? 'capitalize' : 'none'};
`

import React, { useEffect, useState, useMemo, useContext } from "react";
import styled, { css } from "styled-components";
import { isDefiToken, isExternalDefiToken, isLPToken, isNFTCollection, isPlaceholderToken, isShellToken, isShellV2Token, NFT, NFT1155, NFTCollection, placeholderToken, ShellToken, Token, VestingStream } from "../../../utils/tokens";
import { ModalTriggerButton } from "../../../components/Modal/ModalTriggerButton";
import { useSearch } from "../../../hooks/useSearch";
import { List, ListItem, SearchContainer, Title, TokensModal, TokensModalHeader } from "../../../components/TokensModal/TokensModal";
import { TokenButton } from "../../../components/List/TokenButton";
import { erc20ABI, useAccount, useNetwork } from "wagmi";
import { OceanABI } from "../../../constants/ABI/OceanABI";
import cross from "../../../assets/icons/cross.svg";
import { ETH_ADDRESS, OCEAN_ADDRESS, OLD_OCEAN_ADDRESS } from "../../../constants/addresses";
import { addBalance, addNFTBalance } from "../../../store/balancesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { formatDisplay } from "../../../utils/formatDisplay";
import { formatUnits, parseEther } from "@ethersproject/units";
import { ContractCallResults, ContractCallContext, Multicall } from "ethereum-multicall";
import { CloseButton } from "../../../components/Modal/Modal";
import { Collections } from "../../../components/Collections/Collections";
import { ButtonPrimary } from "../../../components/Buttons/Button";
import { SearchInput } from "../../../components/TokensModal/SearchInput";
import { ToggleSwitch } from "../../../components/ToggleSwitch/ToggleSwitch";
import { getTokenID } from "../../../utils/LiquidityGraph";
import { reduceString } from "../../../utils/reduceString";
import { WRAPPED_NFT_MAP, getAllNFTs, getNFTs } from "../../../utils/nftHelpers";
import { removeLeadingZeros } from "../../../utils/ocean/utils";
import { BigNumber } from "@ethersproject/bignumber";
import searchEyeLine from "@/assets/icons/search-eye-line.svg";
import { CustomDropdown } from "@/components/CustomDropdown/CustomDropdown";
import { Media } from "@/styles";
import shellIcon from "@/assets/icons/shell.svg";
import { POOL_API } from "@/constants/urls";
import { providers } from "ethers"
import { alchemyId } from "@/providers/WagmiProvider";
import { allChains } from "@/placeholders/chains";
import { MiniTabs } from "@/components/MiniTabs/MiniTabs";
import { ChainSelect } from "@/components/ChainSelect/ChainSelect";
import { ChainContext, changeNetwork, validChains } from "@/components/Overlays/ChainProvider";
import { addPrice } from "@/store/pricesSlice";
import { updateApys, updateTvls } from "@/store/poolsSlice";
import { Zero } from "@ethersproject/constants"
import arbitrumLogo from "@/assets/icons/arbitrum.svg";

export interface SelectTokenModalProps {
  selectedToken: Token | ShellToken | NFTCollection;
  onTokenSelect: (token: Token | ShellToken | NFTCollection) => void;
  filteredTokens: any[];
  filteredCollections: any[];
  disabledTokens: string[];
  isInputToken: boolean;
  selectedNFTs: (NFT | NFT1155 | VestingStream)[];
  updateSelectedNFTs: any;
  otherNFTs: { [collection: string]: (NFT | NFT1155)[] };
  isShrunk: boolean;
  selectionLocked?: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;
  crossChain: string;
  setCrossChain?: React.Dispatch<React.SetStateAction<string>>;
  selectedProtocols: string[],
  handleSelectProtocol: (protocolName: string) => void
}

export const SelectTokenModal = ({
  selectedToken,
  onTokenSelect,
  filteredTokens,
  filteredCollections,
  disabledTokens,
  isInputToken,
  selectedNFTs,
  updateSelectedNFTs,
  otherNFTs,
  isShrunk,
  selectionLocked,
  setIsVisible,
  isVisible,
  crossChain,
  setCrossChain,
  selectedProtocols,
  handleSelectProtocol,
}: SelectTokenModalProps) => {
  const { address: walletAddress, isConnected } = useAccount();
  const { chain: activeChain } = useNetwork();
    
  const { crossChainToggled, connectedChain, poolQuery, loadedPrices } = useContext(ChainContext)
  const validChain = activeChain?.name == crossChain ?? connectedChain.name
  type ViewsType = "Tokens" | "DeFi" | "NFTs";
  const views: ViewsType[] = ["DeFi", "Tokens", `NFTs`];
  
  const chain = crossChainToggled ? allChains[crossChain] : connectedChain

  const { nftCollections, tokenMap } = require(`../../../placeholders/${chain.name.split(' ')[0].toLowerCase()}Tokens.ts`)

  const disabledTabs = new Set(crossChainToggled ? isInputToken ? ['DeFi', 'NFTs'] : ['NFTs'] : nftCollections.length == 0 ? ['NFTs'] : [])

  const rpcURL = `https://${chain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`

  const provider = new providers.JsonRpcProvider(rpcURL)
  const multicall = new Multicall({ nodeUrl: rpcURL, tryAggregate: true, multicallCustomContractAddress: '0xcA11bde05977b3631167028862bE2a173976CA11' })
  const prices = useAppSelector(state => state.prices.prices[(crossChainToggled && isInputToken ? connectedChain : chain).name])

  const [activeView, setActiveView] = useState<ViewsType>(views[0]);
  const [nftsLoading, setNFTsLoading] = useState(false);

  const categorizedTokens = useMemo(() => {
    return {
      nonDefiTokens: isInputToken && crossChainToggled ? 
      Object.keys(allChains[crossChain!].tokens!).map((tokenID) => {
        const token = {...tokenMap[tokenID]}
        if(token.address != ETH_ADDRESS) token.address = allChains[crossChain].tokens![tokenID]
        return token
      }) : 
      filteredTokens.filter((token) => !isDefiToken(token)),
      defiTokens: filteredTokens.filter((token) => isDefiToken(token) && token.tokenType !== "Aave" && token.tokenType !== "Shell"),
      aaveTokens: filteredTokens.filter((token) => isDefiToken(token) && token.tokenType === "Aave"),
      shellDefiTokens: filteredTokens.filter((token) => isDefiToken(token) && token.tokenType === "Shell"),
    };
  }, [filteredTokens, crossChainToggled, crossChain, chain]);


  const initialTabData = {
    Tokens: { data: [...categorizedTokens.nonDefiTokens], loading: false },
    DeFi: { data: [...categorizedTokens.aaveTokens, ...categorizedTokens.defiTokens, ...categorizedTokens.shellDefiTokens], loading: false },
  };

  const [tabData, setTabData] = useState(initialTabData);
  const [protocolOptions, setProtocolOptions] = useState<string[]>([])

  const closeModal = () => {
    if(selectedToken !== placeholderToken && tokenMap[getTokenID(selectedToken)] == undefined) onTokenSelect(placeholderToken)
    setIsVisible(false);
  };

  const titleMap: { [key in ViewsType]: string } = {
    Tokens: isInputToken && crossChainToggled ? "Bridge from" : "Select Token",
    DeFi: "Select DeFi Token",
    NFTs: "Select NFT Collection",
  };

  const [tokenSearchValue, setTokenSearchValue] = useState("");
  const searchableTokenProperties = ["symbol", "name", "tokenType"];
  const [isToggleCheckedState, setIsToggleCheckedState] = useState(false);
  const tokenItemsMemoized = React.useMemo(() => tabData.Tokens.data.filter(({ wrapped }) => wrapped === isToggleCheckedState),
    [isToggleCheckedState, tabData.Tokens.data]
  );
  const searchTokens = useSearch(
    tokenItemsMemoized,
    searchableTokenProperties,
    tokenSearchValue
  );

  const POPULAR_TOKENS_ORDER = ["ETH", "SHELL", "USDC", "USDT", "ARB", "WBTC", "OP"];
  const popularTokens = React.useMemo(() => {
    return tabData.Tokens.data
      .filter((token) => POPULAR_TOKENS_ORDER.includes(token.symbol))
      .sort((a, b) => POPULAR_TOKENS_ORDER.indexOf(a.symbol) - POPULAR_TOKENS_ORDER.indexOf(b.symbol));
  }, [tabData.Tokens.data]);
  

  const POPULAR_PROTOCOLS = [
    { name: "Shell", icon: shellIcon },
    {
      name: "Aave",
      icon: "https://cdn.jsdelivr.net/gh/aave/branding-assets/Aave/AAVE%20Token%20Logo.svg",
    },
    {
      name: "Curve",
      icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/branding/logo.svg",
    },
    {
      name: "Balancer",
      icon: "https://cdn.jsdelivr.net/gh/balancer/brand-assets/logo/circle-container/logo-balancer-black-512x512.svg",
    },
    {
      name: "Pendle",
      icon: "https://legacy-v1.pendle.finance/img/brandmark.beb1d2f7.svg",
    },
    // {
    //   name: "Beefy",
    //   icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png'
    // },
    {
      name: "STIP",
      icon: arbitrumLogo
    }
  ].filter((protocol) => filteredTokens.findIndex((token) => isExternalDefiToken(token) && token.tokenType == protocol.name) != -1);

  const searchDefiTokens = useSearch(
    tabData.DeFi.data,
    searchableTokenProperties,
    tokenSearchValue
  );

  const userBalances = useAppSelector((state) => state.balances.balances[chain.name]);
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);
  const poolApys = useAppSelector((state) => state.pools.apys[chain.name])
  const poolTvls = useAppSelector((state) => state.pools.tvls[chain.name])

  const dispatch = useAppDispatch();

  const onTokenClick = (newToken: Token) => {
    onTokenSelect(newToken);
    if (isNFTCollection(selectedToken))
      updateSelectedNFTs(selectedToken.symbol, null)

    setIsVisible(false)
  };

  const openModal = async () => {
    if (selectionLocked) return

    if(crossChainToggled && isInputToken) {
      setActiveView('Tokens')
    } else if (isNFTCollection(selectedToken)) {
      setActiveView("NFTs");
    } else if (isDefiToken(selectedToken)) {
      setActiveView("DeFi");
    } else if(!isPlaceholderToken(selectedToken)){
      setActiveView('Tokens')
    }

    setIsVisible(true);
    setIsToggleCheckedState(
      selectedToken.wrapped && !isLPToken(selectedToken)
    );

  };

  const [priceDictionary, setPriceDictionary] = useState<{ [tokenName: string]: number }>({});
  const tokenOrder = useMemo(() => {
    return activeView === "Tokens" ? [...categorizedTokens.nonDefiTokens] : [...categorizedTokens.aaveTokens, ...categorizedTokens.defiTokens, ...categorizedTokens.shellDefiTokens];
  }, [activeView, categorizedTokens]);

  const fetchBalances = async (connected: any) => {
    const tokenBalancePairs: any[] = [];
    const idToToken: any = {};
    let query = false;
  
    const contractCallContext: ContractCallContext[] = [
      { reference: "Ocean v3", contractAddress: OCEAN_ADDRESS, abi: OceanABI, calls: [ { reference: "balanceOBatch", methodName: "balanceOfBatch", methodParameters: [[], []], }, ], },
      { reference: "Ocean v2", contractAddress: OLD_OCEAN_ADDRESS, abi: OceanABI, calls: [ { reference: "balanceOBatch", methodName: "balanceOfBatch", methodParameters: [[], []], }, ], },
    ];

    for (const token of tokenOrder) {
      const tokenKey = token.wrapped || isShellV2Token(token) ? token.oceanID : token.address;
      if (!connected) {
        dispatch(
          addBalance({
            chain: chain.name,
            address: tokenKey,
            amount: "0",
          })
        );
        tokenBalancePairs.push({ token: token, balance: 0 });
        continue;
      } else if (userBalances[tokenKey]) {
        tokenBalancePairs.push({
          token: token,
          balance: parseFloat(userBalances[tokenKey]),
        });
        continue;
      }

      query = true;
        
      if (token.wrapped) {
        contractCallContext[0].calls[0].methodParameters[0].push(walletAddress);
        contractCallContext[0].calls[0].methodParameters[1].push(token.oceanID);
        idToToken[tokenKey] = token;
      } else if(isShellV2Token(token)) {
        contractCallContext[1].calls[0].methodParameters[0].push(walletAddress);
        contractCallContext[1].calls[0].methodParameters[1].push(token.oceanID);
        idToToken[tokenKey] = token;
      } else if (token.address != ETH_ADDRESS) {
        contractCallContext.push({
          reference: token.symbol,
          contractAddress: token.address,
          abi: erc20ABI as any,
          calls: [
            { reference: "balanceOf", methodName: "balanceOf", methodParameters: [walletAddress], },
            { reference: "decimals", methodName: "decimals", methodParameters: [], },
          ],
        });
        idToToken[tokenKey] = token;
      } else {
        const ethBalance = await provider?.getBalance(walletAddress ?? "0xNull").then((result: any) => {
          return formatUnits(result);
        }).catch(() => {
          return "0";
        });

        dispatch(addBalance({ chain: chain.name, address: token.address, amount: ethBalance }));  
        tokenBalancePairs.push({ token: token, balance: parseFloat(ethBalance!)});
      }
    }

    if (connected && query) {
      const results: ContractCallResults = await multicall.call(
        contractCallContext
      );  

      for (const callID of Object.keys(results.results)) {
        let tokenID, newBalance;
        if (callID.includes("Ocean")) {
          results.results[callID].callsReturnContext[0].returnValues.forEach(
            (balance, index) => {
              tokenID =
                results.results[callID].callsReturnContext[0]
                  .methodParameters[1][index];
              newBalance = formatUnits(BigNumber.from(balance ?? 0));
              dispatch(addBalance({ chain: chain.name, address: tokenID, amount: newBalance }));
              tokenBalancePairs.push({
                token: idToToken[tokenID],
                balance: parseFloat(newBalance),
              });
            }
          );
        } else {
          tokenID = isInputToken && crossChainToggled ? allChains[crossChain].tokens![callID] : tokenMap[callID].address;
          newBalance = formatUnits(
            BigNumber.from(results.results[callID].callsReturnContext[0].returnValues[0]?.hex ?? 0),
            results.results[callID].callsReturnContext[1].returnValues ?? 18
          );
          dispatch(addBalance({ chain: chain.name, address: tokenID, amount: newBalance }));
          tokenBalancePairs.push({
            token: idToToken[tokenID],
            balance: parseFloat(newBalance),
          });
        }
      }
    }
    return tokenBalancePairs;
  };

  const fetchPrices = async () => {
    const priceData: { [key: string]: number | null } = {...prices};
    const tokensMissingPrice = tokenOrder.filter(token => {
        const tokenKey = isShellToken(token) ? token.name : token.symbol;
        return !priceData[tokenKey] && isDefiToken(token);
    });

    if (tokensMissingPrice.length === 0) {
        return priceData;
    }

    const callContexts: {[id: string]: ContractCallContext[]} = {}

    tokensMissingPrice.forEach(async (token) => {
        if(token.tokenType == 'STIP') return 
        if(token.tokenType == 'Aave'){
            priceData[token.symbol] = priceData[token.tokens[0]]
        } else if(token.tokenType == 'Pendle'){
            if(token.symbol.startsWith("PT-")){
                const marketData = await fetch(`https://api-v2.pendle.finance/core/v1/${chain.chainId}/markets/${token.metadata}`).then((response) => response.json())
                priceData[token.symbol] = marketData.pt.price.usd
                priceData['YT-' + token.symbol.substring(3)] = marketData.yt.price.usd
            }
        } else {
           
            callContexts[token.tokenType] = (
              callContexts[token.tokenType] ?? []
            )
              .concat(
                poolQuery
                  .buildContext(token, chain, tokenMap)
                  .filter(
                    (context) =>
                      (callContexts[token.tokenType] ?? []).findIndex(
                        (e) => e.reference == context.reference
                      ) == -1
                  )
              )
              .flat();
        }
    })

    const callResults: {[id: string]: ContractCallResults} = {}

    await Promise.all(Object.keys(callContexts).map(async (key) => {
        callResults[key] = await multicall.call(callContexts[key])
    }))

    tokensMissingPrice.forEach(async token => {
        const tokenKey = isShellToken(token) ? token.name : token.symbol;
        let tokenPrice
        if(token.tokenType != 'Aave' && token.tokenType != 'Pendle' && token.tokenType != 'STIP'){
            const results = callResults[token.tokenType]
            const childTokens = token.tokens.map((child : any) => tokenMap[child])
            const priceBalances = []

            let balances: BigNumber[] = []

            if(token.tokenType == 'Beefy'){
                const underlyingPrice: any = priceData[token.tokens[0]]
                tokenPrice = parseFloat(formatUnits(results.results[token.name].callsReturnContext[0].returnValues[0])) * underlyingPrice
            } else {
                if(token.tokenType == 'Shell'){
                    balances = results.results[token.name].callsReturnContext[0].returnValues.map((value) => BigNumber.from(value))
                    balances.push(BigNumber.from(results.results[`${token.name}-totalSupply`].callsReturnContext[0].returnValues[0]))
                } else if(token.tokenType == 'Curve'){    
                    balances = results.results[token.name].callsReturnContext.map((returnContext) => BigNumber.from(returnContext.returnValues[0]))
                } else {
                    const tokenAddresses = results.results[token.name].callsReturnContext[0].returnValues[0]
                    const tokenBalances = results.results[token.name].callsReturnContext[0].returnValues[1]
        
                    token.tokens.forEach((token: any) => {
                        const tokenAddress = tokenMap[token].address
                        const balanceIndex = tokenAddresses.indexOf(tokenAddress)
                        balances.push(BigNumber.from(tokenBalances[balanceIndex]))
                    })
        
                    balances.push(BigNumber.from(results.results[`${token.name}-totalSupply`].callsReturnContext[0].returnValues[0]))
                }

                for(let i = 0; i < childTokens.length; i++){
                    const childToken = childTokens[i]
                    const childTokenID = getTokenID(childToken)
                    const price = priceData[childTokenID]
                    priceBalances.push({
                        token: childTokenID,
                        price: price ? parseEther(price.toFixed(18)) : Zero,
                        balance: token.tokenType == 'Shell' ? balances[i] : parseEther(formatUnits(balances[i], results.results[childTokenID].callsReturnContext[0].returnValues[0]))
                    })
                }
        
                let totalValue = Zero
                priceBalances.forEach((data : any) => totalValue = totalValue.add(data.balance.mul(data.price)))
                const totalSupply = balances[balances.length - 1]
                tokenPrice = parseFloat(formatUnits(totalValue.div(totalSupply)))

            }

            priceData[tokenKey] = tokenPrice;
            dispatch(addPrice({ chain: chain.name, name: tokenKey, price: tokenPrice }));
        }
    });

    tokensMissingPrice.filter((token) => token.tokenType == 'STIP').forEach((token) => {
        priceData[token.symbol] = priceData[token.tokens[0]]
        dispatch(addPrice({ chain: chain.name, name: token.symbol, price: priceData[token.symbol] }));
    })

    return priceData;
  };

  const getTokenBalanceAndPrice = async (connected: any, tokenBalanceAndPriceRef: any) => {

    if (tokenBalanceAndPriceRef.current || !loadedPrices || !prices) return;
    tokenBalanceAndPriceRef.current = true; 
    try {
      const [priceData, tokenBalancePairs] = await Promise.all([
          fetchPrices(),
          fetchBalances(connected)
      ]);
      let newSortedTokens: Token[] = [];
      const tokenValues = tokenBalancePairs.map(pair => {
          const tokenKey = isShellToken(pair.token) ? pair.token.name : pair.token.symbol;
          const price = priceData[tokenKey];
          const balance = parseFloat(pair.balance) || 0;
          pair.usdValue = price === null ? 0 : balance * price;
          if(pair.usdValue < 0.01){
            pair.balance = 0
            pair.usdValue = 0
          }
          pair.price = price;
          return {
              pair: pair,
              value: pair.usdValue
          };
      }).sort((a, b) => b.value - a.value)
        .map(item => item.pair);

      const selectedIndex = tokenValues.findIndex(pair => pair.token.name === selectedToken.name);
      if (selectedIndex > -1) {
          const selectedToken = tokenValues[selectedIndex];
          newSortedTokens = [selectedToken.token, ...tokenValues.filter((_, index) => index !== selectedIndex).map(pair => pair.token)];
      } else {
          newSortedTokens = tokenValues.map(pair => pair.token);
      }

      setTabData(prev => ({ ...prev, [activeView]: { data: newSortedTokens, loading: false } }));
      setPriceDictionary(tokenValues.reduce((acc, pair) => {
        const tokenKey = isShellToken(pair.token) ? pair.token.name : pair.token.symbol;
        acc[tokenKey] = pair.usdValue;
        return acc;
      }, {}));
    } catch (error) {
        console.error("Error fetching token data:", error);
    } finally {
        tokenBalanceAndPriceRef.current = false;
    }
  };

  const getNFTBalances = async (connected : boolean) => {
    setNFTsLoading(true)

    let allUserNFTS = []
    let userOceanNFTs = []

    let queriedAll = false
    let queriedOcean = false

    for (const collection of filteredCollections) {
      if (!connected) {
        const emptyNFTs: { id: number; balance: number; }[] = []
        if (collection.is1155) {
          Array.from(Array(collection.totalSupply).keys()).forEach((id) => {
            emptyNFTs.push({
              id: id,
              balance: 0
            })
          })
        }
        dispatch(
          addNFTBalance({
            collection: collection.symbol,
            items: emptyNFTs,
          })
        );
        continue;
      } else if (userNFTBalances[collection.symbol]) {
        continue
      }

      if (collection.wrapped && !queriedOcean) {
        userOceanNFTs = (await getNFTs(walletAddress, OCEAN_ADDRESS))
        queriedOcean = true
      } else if (!queriedAll) {
        allUserNFTS = await getAllNFTs(walletAddress, filteredCollections.filter((collection) => !collection.wrapped))
        queriedAll = true
      }

      if (collection.is1155) {

        if (collection.wrapped) {
          const wrappedIDs = WRAPPED_NFT_MAP[collection.symbol]

          const userWrappedNFTs: any[] = []
          userOceanNFTs.forEach((userOceanNFT: any) => {
            const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
            if (wrappedIDs[oceanID]) {
              userWrappedNFTs.push({
                id: parseInt(wrappedIDs[oceanID][0]),
                balance: parseInt(userOceanNFT.balance)
              })
            }
          })

          const nonZeroBalanceIDs = new Set(userWrappedNFTs.map((nft) => nft.id))
          Array.from(Array(collection.totalSupply).keys()).forEach((id) => {
            if (!nonZeroBalanceIDs.has(id)) {
              userWrappedNFTs.push({
                id: id,
                balance: 0
              })
            }
          })

          dispatch(
            addNFTBalance({
              collection: collection.symbol,
              items: userWrappedNFTs,
            })
          );
        } else {

          const userNFTs = allUserNFTS[collection.symbol].map((nft: any) => {
            return {
              id: parseInt(nft.tokenId),
              balance: parseInt(nft.balance)
            }
          });

          const nonZeroBalanceIDs = new Set(userNFTs.map((nft: any) => nft.id))

          Array.from(Array(collection.totalSupply).keys()).forEach((id) => {
            if (!nonZeroBalanceIDs.has(id)) {
              userNFTs.push({
                id: id,
                balance: 0
              })
            }
          })

          dispatch(
            addNFTBalance({
              collection: collection.symbol,
              items: userNFTs
            })
          );
        }
      } else {
        if (collection.wrapped) {

          const wrappedIDs = WRAPPED_NFT_MAP[collection.symbol]

          const userWrappedNFTs: any[] = []
          userOceanNFTs.forEach((userOceanNFT: any) => {
            const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
            if (wrappedIDs[oceanID]) userWrappedNFTs.push(parseInt(wrappedIDs[oceanID][0]))
          })

          dispatch(
            addNFTBalance({
              collection: collection.symbol,
              items: userWrappedNFTs,
            })
          );

        } else {

          const userNFTs = allUserNFTS[collection.symbol].map((nft: any) => parseInt(nft.tokenId));
          dispatch(
            addNFTBalance({
              collection: collection.symbol,
              items: userNFTs,
            })
          );
        }
      }
    }

    setNFTsLoading(false)

  }

  const [apyLoading, setApyLoading] = useState(false);
  const apyRef = React.useRef(false);
  const tokenBalanceAndPriceRef = React.useRef(false);

  useEffect(() => {

    const updateAPYs = async () => {
      setApyLoading(true);
      let apys: any = {}
      let tvls: any = {}
      if(Object.keys(poolApys).length > 0 && Object.keys(poolTvls).length > 0){
        apys = poolApys
        tvls = poolTvls
      } else {
        const metrics = await fetch(POOL_API(chain) + 'metrics').then((response) => response.json())
        Object.keys(metrics).forEach((tokenID: string) => {
            apys[tokenID] = (metrics[tokenID].apy - 1) * 100
            tvls[tokenID] = metrics[tokenID].tvl
        })

        dispatch(updateApys({data: apys, chain: chain.name}))
        dispatch(updateTvls({data: tvls, chain: chain.name}))
      }
    //   searchDefiTokens.forEach((token) => token.apy = (apys[getTokenID(token)] - 1) * 100)
      setApyLoading(false);
      apyRef.current = true;
      return apys;
    };

    if(isVisible){
        setTabData(prev => ({ ...prev, [activeView]: { ...initialTabData[activeView as keyof typeof prev], loading: true } }));
        if (selectedProtocols.filter((protocol) => POPULAR_PROTOCOLS.find((p) => protocol == p.name)).length !== selectedProtocols.length)
          handleSelectProtocol("all");
        if(activeView == 'NFTs'){
            getNFTBalances(isConnected)
        } else {
          updateAPYs()
            getTokenBalanceAndPrice(isConnected, tokenBalanceAndPriceRef);
            
        }
    }
  }, [isVisible, activeView, walletAddress, isConnected, crossChainToggled, crossChain, loadedPrices]);

  const textRight = (token: Token) => {
    const userBalance =
      userBalances[token.wrapped || isShellV2Token(token) ? token.oceanID ?? '' : token.address];

    if (userBalance) {
      return formatDisplay(userBalance, 2);
    } else {
      return "0";
    }
  };

  const textColor = (token: Token) => {
    if (token.name === "Shell LP Token") {
      return isInputToken ? "red" : "green";
    } else if (token.wrapped) {
      return "blue";
    } else {
      return "";
    }
  };

  const poolMetric = (token: any, metric: string) => {

    try{
        return (metric == 'apy' ? poolApys : poolTvls)[getTokenID(token)] ?? 0
    } catch {
        return 0
    }
    
  }

  useEffect(() => {
    const newProtocolOptions = new Set<string>()
    filteredTokens.filter((token) => isDefiToken(token)).forEach((defiToken) => newProtocolOptions.add(defiToken.tokenType))
    setProtocolOptions([...newProtocolOptions])
  }, [filteredTokens])

  return (
    <>
      <StyledModalTriggerButton
        dataTestId={`${isInputToken ? "input" : "output"}-modal-trigger-btn`}
        onClick={selectionLocked ? () => { } : openModal}
        icon={selectedToken.icon}
        textLength={selectedToken.symbol.length}
        className={(isShrunk ? 'shrunk' : '') + ' ' + (selectionLocked ? 'locked' : '') + ' ' + (isPlaceholderToken(selectedToken) ? 'placeholder' : '')}
        isPlaceholder={isPlaceholderToken(selectedToken)}
      >
        {selectedToken.symbol}
      </StyledModalTriggerButton>
      <TokensModal
        isVisible={isVisible}
        onClose={closeModal}
        activeView={activeView}
        setActiveView={setActiveView}
        views={views}
        disabledTabs={disabledTabs}
      >
        {activeView === "NFTs" ? (
          <>
            <Collections
              onClose={closeModal}
              collectionsList={filteredCollections}
              selectedNFTs={selectedNFTs}
              updateSelectedNFTs={updateSelectedNFTs}
              otherNFTs={otherNFTs}
              isInputToken={isInputToken}
              selectedCollection={isNFTCollection(selectedToken) ? selectedToken : null}
              setSelectedCollection={onTokenClick}
              isLoading={nftsLoading}
              disabledTokens={disabledTokens}
              setShowModal={setIsVisible}
              prices={prices}
            />
          </>
        ) : (
          <>
            <TokensModalHeader>
              {/* {crossChain && setCrossChain && crossChainToggled ? (
                <TitleWrapper>
                  <Title>{titleMap[activeView]}</Title>
                  <ChainSelect onSelect={(chain) => setCrossChain(chain)} selectedChain={crossChain}/>
                </TitleWrapper>
              ) : (
                <Title>{titleMap[activeView]}</Title>
              )} */}
              <TitleWrapper>
                <Title>{titleMap[activeView]}</Title>
                {isConnected && (crossChain && setCrossChain && crossChainToggled ? (
                  <ChainSelect onSelect={(chain) => setCrossChain(chain)} selectedChain={crossChain} chainOptions={Object.values(allChains)} />
                ) : crossChainToggled || isShrunk ? <></> : (
                  <ChainSelect
                    onSelect={(chain) => changeNetwork(allChains[chain])}
                    selectedChain={connectedChain.name}
                    chainOptions={Object.values(allChains)}
                  />
                ))}
              </TitleWrapper>
              <CloseButton onClick={closeModal}>
                <img src={cross} alt="Close" />
              </CloseButton>
            </TokensModalHeader>
            {!(isInputToken && crossChainToggled) ? (
              <SearchContainer>
              <SearchInput
                value={tokenSearchValue}
                onChange={(event) => setTokenSearchValue(event.target.value)}
                placeholder={activeView === "DeFi" ? "Type token or protocol name" : "Type token name"}
              />
              {activeView == 'Tokens' && !(isInputToken && crossChainToggled) &&
                <ToggleSwitch
                  dataTestId={`wrapped-toggle-${isToggleCheckedState}`}
                  isChecked={isToggleCheckedState}
                  onChange={(event) =>
                    setIsToggleCheckedState(event.target.checked)
                  }
                >
                  Wrapped
                </ToggleSwitch>
              }
              {activeView == "DeFi" &&
                <CustomDropdown
                  allOptions={protocolOptions}
                  selectedOptions={selectedProtocols}
                  onOptionChange={handleSelectProtocol}
                />
              }
            </SearchContainer>
            ) : null}
            {activeView === "DeFi" && POPULAR_PROTOCOLS ? (
              <PopularItems>
                <PopularItemsContainer>
                  {POPULAR_PROTOCOLS.map((protocol) => (
                    <Item
                      key={protocol.name}
                      onClick={() => handleSelectProtocol(protocol.name)}
                      selected={selectedProtocols.includes(protocol.name)}
                    >
                      <TokenIcon src={protocol.icon} alt={protocol.name} />
                      <TokenSymbol>{protocol.name}</TokenSymbol>
                    </Item>
                  ))}
                </PopularItemsContainer>
              </PopularItems>
            ) : null}
            {activeView === "Tokens" && popularTokens && !(isInputToken && crossChainToggled) &&
              <PopularItems>
                <PopularItemsContainer>
                  {popularTokens.map((token) => (
                    <Item
                      key={token.symbol}
                      onClick={() => onTokenClick(token)}
                      disabled={disabledTokens.includes(getTokenID(token))}
                    >
                      <TokenIcon src={token.icon} alt={token.name} />
                      <TokenSymbol>{token.symbol}</TokenSymbol>
                    </Item>
                  ))}
                </PopularItemsContainer>
              </PopularItems>
            }
            {activeView === "Tokens" && searchTokens ? (
              <List>
              {searchTokens.map(
                (token, index) => {
                return (
                  <ListItem key={index}>
                    <TokenButton
                      dataTestId={`token-btn-${getTokenID(token)}`}
                      icon={token.icon}
                      title={getTokenID(token)}
                      subtitle={
                        activeView == "Tokens"
                          ? token.name
                          : reduceString(isShellToken(token) ? token.oceanID : token.address, 6, 4)
                      }
                      onClick={() => onTokenClick(token)}
                      textRight={textRight(token)}
                      color={textColor(token)}
                      isLoading={tabData.Tokens.loading}
                      selected={token.name === selectedToken.name}
                      disabled={disabledTokens.includes(getTokenID(token))}
                      status={token.status}
                      tokenType={token?.tokenType}
                      protocolVersion={token?.protocolVersion}
                      fee={token?.fee}
                      tokenPrice={priceDictionary[isShellToken(token) ? token.name : token.symbol]}
                    />
                  </ListItem>
                );
              })}
            </List>
            ) : searchDefiTokens.length > 0 ? (
              <List>
              {searchDefiTokens.filter(token => selectedProtocols.includes('all') || selectedProtocols.includes(token.tokenType)).map(
                (token, index) => {
                return (
                  <ListItem key={index}>
                    <TokenButton
                      dataTestId={`token-btn-${getTokenID(token)}`}
                      icon={token.icon}
                      title={getTokenID(token)}
                      subtitle={
                        activeView == "Tokens"
                          ? token.name
                          : reduceString(isShellToken(token) ? token.oceanID : token.address, 6, 4)
                      }
                      onClick={() => onTokenClick(token)}
                      textRight={textRight(token)}
                      color={textColor(token)}
                      isLoading={tabData.DeFi.loading}
                      selected={token.name === selectedToken.name}
                      disabled={disabledTokens.includes(getTokenID(token))}
                      status={token.status}
                      tokenType={(token?.tokenType == 'STIP' ? tokenMap[token.tokens[0]] : token)?.tokenType ?? ''}
                      protocolVersion={(token?.tokenType == 'STIP' ? tokenMap[token.tokens[0]] : token)?.protocolVersion ?? ''}
                      fee={token?.fee}
                      apy={poolMetric(token, 'apy')}
                      tvl={poolMetric(token, 'tvl')}
                      apyLoading={apyLoading}
                      tokenPrice={priceDictionary[isShellToken(token) ? token.name : token.symbol]}
                    />
                  </ListItem>
                );
              })}
            </List>
            ) : (
              <EmptyStateContainer>
                <EmptyIconAndTextWrapper>
                  <EmptyIconWrapper>
                    <EmptyIcon src={searchEyeLine} alt="search icon" />
                  </EmptyIconWrapper>
                  <EmptyTextWrapper>
                    <EmptyTitle>
                      No Search Results
                    </EmptyTitle>
                    <EmptySubtitle>
                      Try searching for another token.
                    </EmptySubtitle>
                  </EmptyTextWrapper>
                </EmptyIconAndTextWrapper>
                {/* <CustomPrimaryButton>Create new LP token</CustomPrimaryButton> */}
              </EmptyStateContainer>
            )}
          </>
        )}
      </TokensModal>
    </>
  );
};

const ConfirmButton = styled(ButtonPrimary)`
  position: absolute;
  bottom: 12px;
  width: calc(100% - 48px);
  height: 62px;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(42, 212, 244, 0.4);
`;

const StyledModalTriggerButton = styled(ModalTriggerButton) <{
  textLength: number | undefined;
  isPlaceholder: boolean;
}>`
  ${({ textLength }) =>
    textLength &&
    textLength > 6 &&
    css`
      font-size: 24px;
    `};

  ${({ isPlaceholder }) =>
      isPlaceholder &&
      css`
        border: 2px solid #37dcf2;
        &:hover,
        &:focus,
        &:focus-within {
          border-color: #07c0fb;
        }
      `};
`;

const EmptyStateContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
gap: 24px;
justify-content: flex-start;
align-items: center;
margin-top: 62px;
`;

const EmptyIconAndTextWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
justify-content: flex-start;
align-items: center;
`;

const EmptyTextWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: center;
`;

const EmptyTitle = styled.p`
color: #FFF;

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;
`;

const EmptySubtitle = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

const EmptyIconWrapper = styled.div`
width: 80px;
height: 80px;
display: flex;
justify-content: center;
align-items: center;
background: #171B33;
border-radius: 50%;
`;

const EmptyIcon = styled.img`
width: 40px;
height: 40px;
`;


const CustomPrimaryButton = styled(ButtonPrimary)`
width: unset;
border-radius: 16px;
padding: 18px 30px;
height: unset;
`;

const PopularItems = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 25px;
  margin-bottom: -4px;
  padding: 3px 12px 3px 3px;

  ${Media.tablet} {
    margin-top: 16px;
    margin-bottom: -17px;
  }
`;

const PopularItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Item = styled.div<{ disabled?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 0px 2px 4px 0px;
  background:#171B33;
  transition: background 0.3s ease;


  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid transparent;
      background: linear-gradient(#171B33, #171B33) padding-box, 
                  linear-gradient(to right, #37dcf2, #07c0fb) border-box;
    `};

  &:hover,
  &:focus {
    box-shadow: 0px 19px 45px #04081C;
    border-color: transparent;
    outline: 2px solid #2C5173;
    cursor: pointer;
  }

  ${Media.tablet} {
    padding: 8px 12px;
    margin: 0px 4px 6px 0px;
  }
`;

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const TokenSymbol = styled.span`
  font-size: 16px;
  color: #ffffff;

  ${Media.tablet} {
    font-size: 14px;
  }
`;

const HorizontalLine = styled.hr`
  border: 0;
  height: 1px;
  background-color: rgb(41, 41, 65);
  width: 100%;
`;

const TitleWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;
`;

const CrossChainParagraph = styled.p`
color: var(--grey-3, #7D7D97);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 70%;
margin-top: 15px;
`;
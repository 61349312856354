import { useRewardsContext } from "@/pages/Rewards/RewardsContext";
import React from "react";
import * as S from "../Staking.styled";
import { OverviewBox } from "./OverviewBox";
import { formatDisplay } from "@/utils/formatDisplay";

export const Overview = () => {
    const { stakingInfo, savedLockPeriod } = useRewardsContext();
return (
    <S.SectionContainer>
        <S.SectionTitle>Overview</S.SectionTitle>
        <S.OverviewBoxesWrapper>
            <OverviewBox label="My staked SHELL" value={formatDisplay(savedLockPeriod?.amount, 2)} withIcon />
            <OverviewBox label="My APY" value={(savedLockPeriod?.APY ?? '0') + '%'} customLabelColor="#7ADEB9" />
            <OverviewBox label="Global veSHELL" value={formatDisplay(stakingInfo.totalVeShell, 2)} withIcon icon="veSHELL"/>
            <OverviewBox label="Total staked SHELL" value={formatDisplay(stakingInfo.totalShellStaked, 2)} withIcon/>
        </S.OverviewBoxesWrapper>
    </S.SectionContainer>
)
}
import React from "react";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import infoIcon from "@/assets/icons/info-icon.svg";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import * as S from "./ConfirmationModals.styled";
import { useRewardsContext } from "../../RewardsContext";
import shellIcon from '@/assets/coins/SHELL.svg'
import { formatDisplay } from "@/utils/formatDisplay";

const InputCustomLabel = () => (
    <S.CustomInputLabelContainer>
        <S.CoinIcon src={shellIcon} alt="shell coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>SHELL</S.InputTokenName>
                <S.InputTokenSymbol>Shell</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

export const ClaimConfirmationModalBody = () => {
    const {
        myRewards,
        isClaimSuccess,
        closeClaimModal,
        claimTxSubmitted,
        handleRedirectToStaking
    } = useRewardsContext();
    return (
        <S.ConfirmationBodyContainer hasMask={isClaimSuccess}>
            {isClaimSuccess ? (
                <>
                    <S.SuccessIcon src={successIcon} alt="success icon" />
                    <S.SuccessTextWrapper>
                        <S.SuccessTitle>Claim successful!</S.SuccessTitle>
                        <S.SuccessDescription>
                        Your 
                        <span> {formatDisplay(myRewards.toString(), 2)} </span>
                        SHELL has been successfully claimed
                        </S.SuccessDescription>
                    </S.SuccessTextWrapper>
                    <Input label="Rewards" disabled value={formatDisplay(myRewards.toString())} additionalLabel={<InputCustomLabel />} />
                    <S.CustomPrimaryBtn style={{marginTop: "16px"}} onClick={closeClaimModal}>Close</S.CustomPrimaryBtn>
                </>
            ) : (
                <>
                    <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                    <S.LoaderLabelContainer>
                        <S.LoaderLabel>
                            Claiming your SHELL...
                        </S.LoaderLabel>
                        <S.LoaderSublabel>
                            You're claiming {formatDisplay(myRewards.toString(), 2)} SHELL
                        </S.LoaderSublabel>
                    </S.LoaderLabelContainer>
                    <Input label="Rewards" disabled value={formatDisplay(myRewards.toString())} additionalLabel={<InputCustomLabel />} />
                    {!claimTxSubmitted && 
                    <S.InfoLabelContainer>
                        <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                        <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                    </S.InfoLabelContainer>
                    }
                </>
            )}
        </S.ConfirmationBodyContainer >
    )
};
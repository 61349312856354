import React, { useState } from "react";
import { CloseButton } from "../Modal/Modal";
import { List, ListItem, SearchContainer, Title, TokensModalHeader } from "../TokensModal/TokensModal";
import cross from "../../assets/icons/cross.svg";
import { NFTCollection } from "../../utils/tokens";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";
import { SearchInput } from "../TokensModal/SearchInput";
import { useSearch } from "../../hooks/useSearch";
import { TokenButton } from "../List/TokenButton";
import { useAppSelector } from "../../store/hooks";
import { formatDisplay } from "../../utils/formatDisplay";

interface CollectionsListProps {
  collectionsList: NFTCollection[];
  selectedCollection: NFTCollection | null;
  title: string;
  onClose: () => void;
  onSelect: any;
  wrappedToggle: boolean;
  setWrappedToggle: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  disabledTokens: string[]
  prices: Record<string, any>
}

export const CollectionsList = ({
  collectionsList,
  selectedCollection,
  title,
  onClose,
  onSelect,
  wrappedToggle,
  setWrappedToggle,
  isLoading,
  disabledTokens,
  prices
}: CollectionsListProps) => {

  const collectionsMemoized = React.useMemo(
    () =>
      collectionsList.filter(
        ({ wrapped }) => wrapped === wrappedToggle
      ),
    [wrappedToggle]
  );

  const [searchValue, setSearchValue] = useState("");

  const filteredCollections = useSearch(collectionsMemoized, ['name', 'symbol'], searchValue);

  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);
  const sortedCollections = React.useMemo(() => {
    return filteredCollections.map(collection => {
      const userBalance = userNFTBalances[collection.symbol]?.length ?? 0;
      const collectionPrice = prices[collection.symbol] || 0;
      const product = userBalance * collectionPrice;
      const usdValue = `$${formatDisplay(product.toString(), 2)}`;
      return { ...collection, product, usdValue}; 
    })
    .sort((a, b) => b.product - a.product);
  }, [filteredCollections, userNFTBalances, prices]);

  return (
    <>
      <TokensModalHeader>
        <Title>{title}</Title>
        <CloseButton onClick={onClose}>
          <img src={cross} alt="Close" />
        </CloseButton>
      </TokensModalHeader>
      <SearchContainer>
        <SearchInput
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Type collection name"
        />
        <ToggleSwitch
            dataTestId={`wrapped-collections-toggle-${wrappedToggle}`}
            isChecked={!!wrappedToggle}
            onChange={(event) => setWrappedToggle && setWrappedToggle(event.target.checked)}
        >
            Wrapped
        </ToggleSwitch>
      </SearchContainer>
      <List>
        {sortedCollections.map((collection, index) => (
          <ListItem key={index}>
            <TokenButton
              dataTestId={`token-btn-${collection.name}`}
              icon={collection.icon}
              title={collection.name}
              subtitle={''}
              onClick={() => !isLoading && onSelect(collection)}
              textRight={collection.is1155 ? 'CollectionView' : (userNFTBalances[collection.symbol]?.length ?? 0).toString()}
              isLoading={isLoading}
              selected={selectedCollection?.symbol == collection.symbol}
              nftType={collection.is1155 ? '1155' : '721'}
              disabled={disabledTokens.includes(collection.symbol)}
              tokenPrice={collection.usdValue}
              />
          </ListItem>
        ))}
      </List>
    </>
  );
};

import { BigNumber, Contract, providers, utils } from "ethers/lib/ethers";
import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ArrowIcon } from "../../components/Icons/ArrowIcon";
import { ConfirmIcon } from "../../components/Icons/ConfirmIcon";
import { Modal } from "../../components/Modal/Modal";
import { isNFTCollection, isShellToken, isShellV2Token, maxTokenDecimals, ShellToken, Token } from "../../utils/tokens";
import { formatDisplay } from "../../utils/formatDisplay";
import { Edge, getTokenID } from "../../utils/LiquidityGraph";
import { Accountant } from "../../components/Accountant";
import { parseEther, formatUnits, parseUnits, hexZeroPad, hexlify, formatEther } from "ethers/lib/utils";
import { PoolQuery } from "../../utils/PoolQuery";
import { useAccount, useSigner, useProvider, useNetwork, erc20ABI } from "wagmi";
import { Zero, MaxUint256 } from "@ethersproject/constants";
import { buildInteractions } from "../../utils/buildInteractions";
import * as ShellV2 from "../../utils/ocean/index";
import toast from "react-hot-toast";
import { OceanABI } from "../../constants/ABI/OceanABI";
import { ETH_ADDRESS, OCEAN_ADDRESS, OLD_OCEAN_ADDRESS } from "../../constants/addresses";
import { tokenColors } from "../../constants/tokenColors";
import { wrappedTokens } from "../../constants/wrappedToken";
import { Spinner, StraightLoader } from "../../components/Loaders";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ErrorIcon } from "../../components/Icons/ErrorIcon";
import { Media } from "../../styles";
import { NFTsSwiper } from "./NFTsSwiper/NFTsSwiper";
import { calculateWrappedTokenId } from "../../utils/ocean/utils";
import { NFTWarning } from "./NFTWarning";
import * as types from "../../utils/ocean/types";
import { getPoolToken } from "../../utils/testRouter";
import { clearUserTransactions } from "@/store/transactionsSlice";
import { clearCurrentPoints } from "@/store/pointsSlice";
import { getModifiedPath } from "@/utils/sor";
import { AdapterABI } from "@/constants/ABI/AdapterABI";
import { combineMerge, combineSplit } from "@/utils/buildSwapPath";
import { allChains, bridgeOutputs } from "@/placeholders/chains";
import { alchemyId } from "@/providers/WagmiProvider";
import { AddressZero } from "@ethersproject/constants";
import arbitrumChain from "@/assets/coins/arbitrum.svg";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { Alchemy, Network } from "alchemy-sdk";

interface CrossChainConfirmationModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setTradeDisabled: (disabled: boolean) => void;
  tokensFrom: any[];
  tokensTo: any[];
  specifiedAmounts: Record<string, string>;
  splitAmounts: Record<string, BigNumber>;
  slippage: number;
  fromInputs: boolean;
  selectedNFTs: any;
  setTxSuccess: (val: boolean) => void;
  crossChain: string;
  crossChainTo: string;
  tokenDecimals: number;
}

const forwarderABI = [
  "function doOceanInteraction(address inputToken, address outputToken, bytes32 outputMetadata, address _receiver, bytes32 _minOutputAmount, address[] _primitives, uint256[] _oceanIds)",
];
export const forwarderInterface = new utils.Interface(forwarderABI);

export const CrossChainConfirmationModal = ({
  visible,
  setVisible,
  setTradeDisabled,
  tokensFrom,
  tokensTo,
  specifiedAmounts,
  splitAmounts,
  slippage,
  fromInputs,
  selectedNFTs,
  tokenDecimals,
  setTxSuccess,
  crossChain,
  crossChainTo,
}: CrossChainConfirmationModalProps) => {
  const { data: signer } = useSigner();
  const srcChainProvider = new providers.JsonRpcProvider(`https://${allChains[crossChain].rpcPrefix}.g.alchemy.com/v2/${alchemyId}`);

  const { address: walletAddress } = useAccount();
  const { liquidityGraphTo, poolQueryTo, sorTokenMapTo, tokenMapTo } = useContext(ChainContext);

  const inputNFTCollections = tokensFrom.filter((token) => isNFTCollection(token))?.map((token) => token.symbol) ?? [];
  const outputNFTCollections = tokensTo.filter((token) => isNFTCollection(token))?.map((token) => token.symbol) ?? [];
  const allNFTs = (fromInputs ? inputNFTCollections : outputNFTCollections).map((collection) => selectedNFTs[collection]).flat();

  const [amountsFrom, setAmountsFrom] = useState<Record<string, string>>({});
  const [amountsTo, setAmountsTo] = useState<Record<string, string>>({});

  const [optimalPaths, setOptimalPaths] = useState<{ [start: string]: { [end: string]: Edge[] } }>({});

  const [minAmountsTo, setMinAmountsTo] = useState<{ [end: string]: { [start: string]: BigNumber } }>({});

  const [foundNonWrap, setFoundNonWrap] = useState(false); // Flag to show slippage UI elements involved in swaps/deposits/withdraws
  const [summaryAmounts, setSummaryAmounts] = useState<{ [token: string]: string }>({});
  const [txState, setTxState] = useState("");
  const [txHash, setTxHash] = useState("");
  const [orderId, setOrderId] = useState("");
  const [linkHash, setLinkHash] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const [outputLoading, setOutputLoading] = useState(false);

  const dispatch = useAppDispatch();

  const [tokenToApprove, setTokenToApprove] = useState<Token>();
  const [approveDisabled, setApproveDisabled] = useState(false);

  const [bridgeResultAmount, setBridgeResultAmount] = useState<BigNumber>(Zero);
  const [bridgeFee, setBridgeFee] = useState(0);
  const [hasExternalCall, setHasExternalCall] = useState(false);

  const { oceanAddress } = require(`../../placeholders/${crossChainTo.split(" ")[0].toLowerCase()}Tokens.ts`);

  const networks: any = {
    "Arbitrum One": Network.ARB_MAINNET,
    Base: Network.BASE_MAINNET,
    Optimism: Network.OPT_MAINNET,
    Ethereum: Network.ETH_MAINNET,
  };
  const config = {
    apiKey: alchemyId,
    network: networks[crossChainTo],
  };
  const alchemy = new Alchemy(config);

  const wrapOrUnwrap = (path: Edge[]) => {
    const tokenOne = path[0].token;
    const tokenTwo = path[path.length - 1].token;

    return tokenOne.address == tokenTwo.address && (tokenOne.wrapped !== tokenTwo.wrapped || isNFTCollection(tokenOne) || isNFTCollection(tokenTwo));
  };

  const buildExternalCall = (
    tokenToBridge: any,
    inputTokenID: string,
    outputTokenID: string,
    path: Edge[],
    minAmountsTo: { [end: string]: { [start: string]: BigNumber } }
  ) => {
    const destOutputAddress = tokenToBridge.address == ETH_ADDRESS ? AddressZero : tokenMapTo![bridgeOutputs[inputTokenID][crossChainTo]].address;

    const outputToken = tokensTo[0];

    const outputAddress =
      isShellToken(outputToken) && outputToken.tokenType == "Shell"
        ? outputToken.protocolVersion == "V2"
          ? OLD_OCEAN_ADDRESS
          : OCEAN_ADDRESS
        : outputToken.address == ETH_ADDRESS
        ? AddressZero
        : outputToken.address;

    const outputMetadata = hexZeroPad(hexlify(outputToken.tokenType == "Shell" ? outputToken.oceanID : Zero), 32);

    const primitives = path
      .filter((step) => step.action == "Swap" || step.action == "Deposit" || step.action == "Withdraw")
      .map((step) => (getPoolToken(step.pool, tokenMapTo!) as ShellToken).pool);

    const oceanIDs = path
      .filter((step) => step.action && step.token.oceanID && !(step.action == "Unwrap" && isShellV2Token(step.token)))
      .map((step) => step.token.oceanID);

    const values = [
      destOutputAddress,
      outputAddress,
      outputMetadata,
      walletAddress!,
      hexZeroPad(hexlify(minAmountsTo[outputTokenID][inputTokenID] ?? 0), 32),
      primitives,
      oceanIDs,
    ];

    // Serialize the transaction
    const serializedTx = forwarderInterface.encodeFunctionData("doOceanInteraction", values);

    return JSON.stringify({
      version: "evm_1",
      fields: {
        to: allChains[crossChainTo].bridgeForwarder,
        data: serializedTx,
        value: tokenToBridge.address == ETH_ADDRESS ? bridgeResultAmount : 0,
      },
    });
  };

  const updateOutputValues = async (inputTokens: any[], outputTokens: any[], split: boolean) => {
    const inputTokenID = getTokenID(tokensFrom[0]);

    const newAmountsTo: Record<string, string> = { ...amountsTo };
    const newMinAmountsTo: { [end: string]: { [start: string]: BigNumber } } = { ...minAmountsTo };
    const newSummaryAmounts: { [token: string]: string } = {};
    const newOptimalPaths: { [start: string]: { [end: string]: Edge[] } } = { ...optimalPaths };

    newSummaryAmounts[inputTokenID] = specifiedAmounts[inputTokenID];

    let paths: Edge[][] = [];
    let inputNFTPaths: Edge[][] = [];

    let sharedPath: Edge[] = [];
    let pools: any;

    let inputAmounts: BigNumber[] = [];
    const outputAmounts: { [end: string]: BigNumber } = {};

    const slippageMultiplier = 10000 - slippage * 100;

    if (split) {
      [paths, inputNFTPaths] = poolQueryTo!.filterInputNFTPath(
        outputTokens
          .map((outputToken) => {
            outputAmounts[getTokenID(outputToken)] = Zero;
            newMinAmountsTo[getTokenID(outputToken)] = {};
            newOptimalPaths[getTokenID(inputTokens[0])] = {};
            return liquidityGraphTo!.findPath(inputTokens[0], outputToken);
          })
          .sort((a, b) => b.length - a.length)
      );

      const inputTokenID: string = getTokenID(inputTokens[0]);
      const totalInputAmount = specifiedAmounts[inputTokenID];

      newSummaryAmounts[inputTokenID] = totalInputAmount;

      paths = await Promise.all(
        paths.map(async (path) => {
          if (path.length == 0) return path;
          return getModifiedPath(path, totalInputAmount, slippage, tokenMapTo!, sorTokenMapTo!, allChains[crossChainTo], liquidityGraphTo!);
        })
      );

      pools = await poolQueryTo!.getPools(paths);

      const pathData = combineSplit(JSON.parse(JSON.stringify(paths)));
      sharedPath = pathData.sharedPath;

      if (sharedPath.findIndex((step) => step.action && step.action !== "Wrap" && step.action !== "Unwrap") !== -1) {
        // Shared path involves a trade
        const [sharedOutput, firstOutput] = await Promise.all([
          poolQueryTo!.query(pathData.sharedPath, parseUnits(totalInputAmount), pools, true),
          poolQueryTo!.query(pathData.sharedPath, parseUnits(totalInputAmount).div(paths.length), pools, true),
        ]);
        paths = pathData.paths;
        inputAmounts = [firstOutput.amount, sharedOutput.amount.sub(firstOutput.amount)];
        pools = poolQueryTo!.filterPools(pools, paths);
      } else {
        inputAmounts = paths.map((path) => splitAmounts[getTokenID(path[path.length - 1].token)]);
      }
    } else {
      [paths, inputNFTPaths] = poolQueryTo!.filterInputNFTPath(
        inputTokens
          .map((inputToken) => {
            newOptimalPaths[bridgeOutputs[inputTokenID][crossChainTo]] = {};
            return liquidityGraphTo!.findPath(tokenMapTo![bridgeOutputs[inputTokenID][crossChainTo]], outputTokens[0]);
          })
          .sort((a, b) => b.length - a.length)
      );
      outputAmounts[getTokenID(outputTokens[0])] = Zero;
      newMinAmountsTo[getTokenID(outputTokens[0])] = {};

      paths = await Promise.all(
        paths.map(async (path, i) => {
          if (path.length == 0) return path;
          return getModifiedPath(path, specifiedAmounts[inputTokenID], slippage, tokenMapTo!, sorTokenMapTo!, allChains[crossChainTo], liquidityGraphTo!);
        })
      );

      pools = await poolQueryTo!.getPools(paths);

      const pathData = combineMerge(JSON.parse(JSON.stringify(paths)));

      const outputTokenID = getTokenID(outputTokens[0]);

      inputAmounts = await Promise.all(
        paths.map(async (path, index) => {
          let externalCall = "";

          const tokenToBridge = tokenMapTo![inputTokenID];

          const destOutputAddress = tokenToBridge.address == ETH_ADDRESS ? AddressZero : tokenMapTo![bridgeOutputs[inputTokenID][crossChainTo]].address;
          let inputAmount = 0;

          if (path.length > 0) {
            const inputToken = getTokenID((inputNFTPaths[index].length > 0 ? inputNFTPaths[index][0] : path[0]).token);

            if (inputNFTPaths[index][inputNFTPaths[index].length - 1].action === path[0].action) {
              newOptimalPaths[inputToken][outputTokenID] = inputNFTPaths[index].concat(path.slice(1)).concat(pathData.sharedPath.slice(1));
            } else {
              newOptimalPaths[inputToken][outputTokenID] = inputNFTPaths[index].concat(path).concat(pathData.sharedPath.slice(1));
            }

            externalCall = buildExternalCall(
              tokenToBridge,
              inputTokenID,
              outputTokenID,
              newOptimalPaths[bridgeOutputs[inputTokenID][crossChainTo]][outputTokenID],
              newMinAmountsTo
            );
          }

          const params: Record<string, string> = {
            srcChainId: allChains[crossChain].chainId.toString(),
            srcChainTokenIn: allChains[crossChain].tokens![inputTokenID],
            srcChainTokenInAmount: parseUnits(specifiedAmounts[inputTokenID], tokenDecimals).toString(),
            dstChainId: allChains[crossChainTo].chainId.toString(),
            dstChainTokenOut: destOutputAddress,
            senderAddress: walletAddress!,
            srcChainOrderAuthorityAddress: walletAddress!,
            dstChainOrderAuthorityAddress: walletAddress!,
            dstChainTokenOutRecipient: allChains[crossChainTo].bridgeForwarder!,
            additionalTakerRewardBps: "1",
            externalCall: externalCall,
          };

          const queryString = new URLSearchParams(params).toString();
          const fullUrl = `https://api.dln.trade/v1.0/dln/order/create-tx?${queryString}`;

          const request = await fetch(fullUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .catch((error) => {
              // Handle errors here
              console.error("There was a problem with the fetch operation:", error);
            });

          const bridgedInputAmount = formatUnits(request.estimation.dstChainTokenOut.amount, tokenDecimals);

          setBridgeResultAmount(request.estimation.dstChainTokenOut.amount);
          setBridgeFee(parseFloat(formatEther(request.fixFee)));

          inputAmount = poolQueryTo!.adjustNFTAmount(parseFloat(bridgedInputAmount), inputNFTPaths[index]);
          return parseEther(inputAmount.toFixed(18));
        })
      );

      let totalOutputAmount = Zero;

      await Promise.all(
        pathData.paths.map(async (path: any, i) => {
          if (path.length == 0) {
            if (inputNFTPaths[i].length > 0) outputAmounts[outputTokenID] = inputAmounts[i]; // Handle one step NFT wraps
            if (!outputAmounts[outputTokenID]) outputAmounts[outputTokenID] = Zero;
            return;
          }

          const [startToken, endToken] = [getTokenID(path[0].token), getTokenID(path[path.length - 1].token)];

          if (inputAmounts[i].isZero()) {
            if (!outputAmounts[endToken]) outputAmounts[endToken] = Zero;
            return;
          }

          let resultAmount = Zero;

          try {
            if (path.length == 1) {
              resultAmount = inputAmounts[i];
            } else {
              const result = await poolQueryTo!.query(path, inputAmounts[i], pools, true);
              resultAmount = result.amount;
              poolQueryTo!.filterPools(pools, pathData.paths).sharedPools.forEach((pool: string) => {
                pools.states[pool] = {
                  xBalance: result.poolStates[pool][0],
                  yBalance: result.poolStates[pool][1],
                  totalSupply: result.poolStates[pool][2],
                  impAddress: result.poolStates[pool][3],
                };
              });
              newMinAmountsTo[outputTokenID][inputTokenID] = resultAmount.mul(slippageMultiplier).div(10000);
            }
          } catch {
            console.error("Pool Query Error");
          }

          totalOutputAmount = totalOutputAmount.add(resultAmount);

          if (wrapOrUnwrap(pathData.sharedPath)) {
            outputAmounts[outputTokenID] = outputAmounts[outputTokenID].add(resultAmount);
          }
        })
      );

      paths = wrapOrUnwrap(pathData.sharedPath) ? [] : [pathData.sharedPath];
      inputAmounts = [totalOutputAmount];
      pools = poolQueryTo!.filterPools(pools, paths);
    }

    for (let i = 0; i < paths.length; i++) {
      let path = paths[i];

      if (path.length == 0 && inputNFTPaths[i].length > 0) {
        // Handle one step NFT wrap
        const [startToken, endToken] = [getTokenID(inputNFTPaths[i][0].token), getTokenID(inputNFTPaths[i][1].token)];
        outputAmounts[endToken] = inputAmounts[i];
        newMinAmountsTo[endToken][startToken] = inputAmounts[i];
        newOptimalPaths[startToken][endToken] = inputNFTPaths[i];
        continue;
      }

      const [startToken, endToken] = [getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token), getTokenID(path[path.length - 1].token)];

      if (split) {
        if (inputNFTPaths[i][inputNFTPaths[i].length - 1].action === sharedPath[0].action) {
          newOptimalPaths[startToken][endToken] = inputNFTPaths[i].concat(sharedPath.slice(1));
        } else {
          newOptimalPaths[startToken][endToken] = inputNFTPaths[i].concat(sharedPath);
        }
        const firstSwapIndex = path.findIndex((step) => step.action && step.action !== "Wrap");
        newOptimalPaths[startToken][endToken] = newOptimalPaths[startToken][endToken].concat(firstSwapIndex !== -1 ? path.slice(firstSwapIndex) : path);
      }

      let resultAmount = Zero;

      try {
        if (path.length == 1) {
          resultAmount = inputAmounts[i];
        } else {
          const result = await poolQueryTo!.query(path, inputAmounts[i], pools, true);
          resultAmount = result.amount;
          pools.sharedPools.forEach((pool: string) => {
            pools.states[pool] = {
              xBalance: result.poolStates[pool][0],
              yBalance: result.poolStates[pool][1],
              totalSupply: result.poolStates[pool][2],
              impAddress: result.poolStates[pool][3],
            };
          });
        }
      } catch (_) {
        console.error("Proteus Query Error");
      }

      outputAmounts[endToken] = outputAmounts[endToken].add(resultAmount);

      if (split) {
        if (wrapOrUnwrap(newOptimalPaths[startToken][endToken])) {
          newMinAmountsTo[endToken][startToken] = resultAmount;
        } else {
          newMinAmountsTo[endToken][startToken] = resultAmount.mul(slippageMultiplier).div(10000);
          if (!foundNonWrap) setFoundNonWrap(true);
        }
      } else {
        if (wrapOrUnwrap(newOptimalPaths[bridgeOutputs[inputTokenID][crossChainTo]][endToken])) {
          newMinAmountsTo[endToken][inputTokenID] = resultAmount.div(inputTokens.length);
        } else {
          newMinAmountsTo[endToken][inputTokenID] = resultAmount.div(inputTokens.length).mul(slippageMultiplier).div(10000);
          if (!foundNonWrap) setFoundNonWrap(true);
        }
      }
    }

    Object.keys(outputAmounts).forEach((outputToken) => {
      const outputAmount = outputAmounts[outputToken];
      if (outputAmount.isZero()) {
        delete newAmountsTo[outputToken];
      } else {
        newAmountsTo[outputToken] = formatUnits(outputAmount);
      }
      let summaryAmount = Zero;
      Object.keys(newMinAmountsTo[outputToken]).forEach((inputToken) => (summaryAmount = summaryAmount.add(newMinAmountsTo[outputToken][inputToken])));
      newSummaryAmounts[outputToken] = formatUnits(summaryAmount);
    });

    setAmountsTo(newAmountsTo);
    setMinAmountsTo(newMinAmountsTo);
    setSummaryAmounts(newSummaryAmounts);
    setOptimalPaths(newOptimalPaths);
  };

  const updateApproveToken = async () => {
    let needsApproval = false;
    let decimals = 18;

    const inputTokenID = getTokenID(tokensFrom[0]);
    const inputToken = tokenMapTo![inputTokenID];

    if (inputToken.address != ETH_ADDRESS) {
      const tokenContract = new Contract(allChains[crossChain].tokens![inputTokenID], erc20ABI, srcChainProvider);
      const result = await tokenContract.allowance(walletAddress, allChains[crossChain].bridge);

      decimals = await tokenContract.decimals();
      const amount = parseUnits(specifiedAmounts[inputTokenID]);

      needsApproval = parseUnits(formatUnits(result, decimals)).lt(amount);
    }

    setTokenToApprove(needsApproval ? inputToken : undefined);

    return decimals;
  };

  async function getGasEstimate(from: string, to: string, data: string, value: BigNumber) {
    return (await alchemy.core.estimateGas({ from: from, to: to, data: data, value: value })).toNumber();
  }

  async function getTransaction(hash: string) {
    return await alchemy.core.getTransactionReceipt(hash);
  }

  const executeBridge = async () => {
    setConfirmDisabled(true);
    setTxState("Pending");

    const outputToken = tokensTo[0];

    const inputTokenID = getTokenID(tokensFrom[0]);
    const outputTokenID = getTokenID(outputToken);

    const tokenToBridge = tokenMapTo![inputTokenID];

    const destOutputAddress = tokenToBridge.address == ETH_ADDRESS ? AddressZero : tokenMapTo![bridgeOutputs[inputTokenID][crossChainTo]].address;

    const outputAddress =
      isShellToken(outputToken) && outputToken.tokenType == "Shell"
        ? outputToken.protocolVersion == "V2"
          ? OLD_OCEAN_ADDRESS
          : OCEAN_ADDRESS
        : outputToken.address == ETH_ADDRESS
        ? AddressZero
        : outputToken.address;

    const outputMetadata = hexZeroPad(hexlify(outputToken.tokenType == "Shell" ? outputToken.oceanID : Zero), 32);

    const primitives = optimalPaths[bridgeOutputs[inputTokenID][crossChainTo]][outputTokenID]
      .filter((step) => step.action == "Swap" || step.action == "Deposit" || step.action == "Withdraw")
      .map((step) => (getPoolToken(step.pool, tokenMapTo!) as ShellToken).pool);

    const oceanIDs = optimalPaths[bridgeOutputs[inputTokenID][crossChainTo]][outputTokenID]
      .filter((step) => step.action && step.token.oceanID && !(step.action == "Unwrap" && isShellV2Token(step.token)))
      .map((step) => step.token.oceanID);

    const values = [
      destOutputAddress,
      outputAddress,
      outputMetadata,
      walletAddress!,
      hexZeroPad(hexlify(minAmountsTo[outputTokenID][inputTokenID]), 32),
      primitives,
      oceanIDs,
    ];

    const isSingleStepBridge = primitives.length == 0 && oceanIDs.length == 1;

    setHasExternalCall(!isSingleStepBridge);

    // Serialize the transaction
    const serializedTx = forwarderInterface.encodeFunctionData("doOceanInteraction", values);

    // const gasEstimate = await getGasEstimate(
    //   walletAddress!,
    //   allChains[crossChainTo].bridgeForwarder!,
    //   serializedTx,
    //   tokenToBridge.address == ETH_ADDRESS ? bridgeResultAmount : Zero
    // );

    const params: Record<string, string> = {
      srcChainId: allChains[crossChain].chainId.toString(),
      srcChainTokenIn: allChains[crossChain].tokens![inputTokenID],
      srcChainTokenInAmount: parseUnits(amountsFrom[inputTokenID], tokenDecimals).toString(),
      dstChainId: allChains[crossChainTo].chainId.toString(),
      dstChainTokenOut: destOutputAddress,
      senderAddress: walletAddress!,
      srcChainOrderAuthorityAddress: walletAddress!,
      dstChainOrderAuthorityAddress: walletAddress!,
      dstChainTokenOutRecipient: isSingleStepBridge ? walletAddress! : allChains[crossChainTo].bridgeForwarder!,
      additionalTakerRewardBps: "1",
      referralCode: "21450",
      externalCall: isSingleStepBridge
        ? ""
        : JSON.stringify({
            version: "evm_1",
            fields: {
              to: allChains[crossChainTo].bridgeForwarder,
              data: serializedTx,
            //   gas: Math.round(gasEstimate * 1.25),
            },
          }),
    };

    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `https://api.dln.trade/v1.0/dln/order/create-tx?${queryString}`;

    // Making the GET request
    const request = await fetch(fullUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        // Handle errors here
        console.error("There was a problem with the fetch operation:", error);
      });

    signer
      ?.sendTransaction({
        to: request.tx.to,
        data: request.tx.data,
        value: request.tx.value,
      })
      .then((response: any) => {
        setTxState("Submitted");

        toast.promise(response.wait(), {
          loading: "Transaction pending on " + crossChain,
          success: (data: any) => {
            setOrderId(data.transactionHash);
            return "Transaction success on " + crossChain;
          },
          error: (data: any) => {
            setTxHash(data.transactionHash);
            setTxState("Bridge Error");
            return "Transaction error on " + crossChain;
          },
        });
      })
      .catch(() => {
        setTxState("");
        setConfirmDisabled(false);
      });
  };

  const closeModal = () => {
    setVisible(false);
    setTradeDisabled(txState === "Submitted" || txState === "Success");
    setBridgeFee(0);
    setLinkHash("");
    if (txState === "Success") {
      dispatch(clearUserTransactions({ chain: crossChainTo, user: walletAddress }));
      dispatch(clearCurrentPoints({ address: walletAddress }));
      setTxState("");
    }
  };

  useEffect(() => {
    if (visible) {
      setConfirmDisabled(true);
      setAmountsFrom(specifiedAmounts);
      setOutputLoading(true);
      const split = tokensTo.length > 1;
      const inputTokens = [...tokensFrom];
      const outputTokens = [...tokensTo];

      if (split) inputTokens.push(inputTokens[0]);
      updateOutputValues(inputTokens, outputTokens, split).then(() => {
        setOutputLoading(false);
        setConfirmDisabled(false);
      });
    }
  }, [visible]);

  useEffect(() => {
    const refreshValues = setInterval(() => {
      // Requery values every 10 seconds

      if (visible && !confirmDisabled && (foundNonWrap || outputNFTCollections.length)) {
        setConfirmDisabled(true);

        setOutputLoading(true);

        const split = tokensTo.length > 1;
        const inputTokens = [...tokensFrom];
        const outputTokens = [...tokensTo];
        if (split) inputTokens.push(inputTokens[0]);

        updateOutputValues(inputTokens, outputTokens, split).then(() => {
          setTimeout(() => {
            setOutputLoading(false);
            setConfirmDisabled(false);
          }, 500);
        });
      }
    }, 10 * 1000);

    return () => clearInterval(refreshValues);
  }, [visible, confirmDisabled]);

  useEffect(() => {
    const refreshValues = setInterval(() => {
      // Requery values every 2 seconds

      if (orderId && txState == "Submitted") {
        fetch(`https://stats-api.dln.trade/api/Orders/creationTxHash/${orderId}`)
          .then((response) => response.json())
          .then((result) => {
            const state = result.state;
            setLinkHash(result.orderId.stringValue);
            if (state == "Fulfilled" || state == "SentUnlock" || state == "ClaimedUnlock") {
              getTransaction(result.fulfilledDstEventMetadata.transactionHash.stringValue).then((transaction) => {
                if (!hasExternalCall || (transaction?.logs.filter((log) => log.address.toLowerCase() == oceanAddress.toLowerCase()) ?? []).length > 1) {
                  setTxState("Success");
                } else {
                  setTxState("Trade Error");
                }
                setTxSuccess(true);
                setTxHash(result.fulfilledDstEventMetadata.transactionHash.stringValue);
                setOrderId("");
              });
            } else if (state == "ClaimedOrderCancel") {
              setTxState("Trade Error");
              setTxHash(result.claimedOrderCancelSrcEventInfo.transactionHash.stringValue);
              setOrderId("");
            }
          });
      }
    }, 2 * 1000);

    return () => clearInterval(refreshValues);
  }, [orderId, txState]);

  useEffect(() => {
    setTxSuccess(false);
    setTxState("");
  }, [amountsFrom, amountsTo]);

  return (
    <StyledModal title={txState ? `Trade ${txState}` : "Confirm Bridge"} isVisible={visible} onClose={closeModal}>
      <ColumnWrapper>
        {tokensFrom.map((token, index) => {
          return (
            <TokenView key={getTokenID(token)} shape={index == tokensFrom.length - 1 ? "bottom" : ""}>
              <Row>
                <BalanceDisplayWrapper>
                  <span>From</span>
                  <BalanceDisplay isWrapped={token.wrapped}>{`${formatDisplay(amountsFrom[getTokenID(token)] || "0")}`}</BalanceDisplay>
                </BalanceDisplayWrapper>
                {/* <TokenDisplay>
                  <img src={token.icon} alt="logo" />
                  <span className="token-symbol-text">{token.symbol}</span>
                </TokenDisplay> */}
                <BridgeTokenDisplay>
                  <BridgeTokenLogo logo={token.icon} chainLogo={allChains[crossChain]?.icon || arbitrumChain} />
                  <TokenChainWrapper>
                    {token.symbol}
                    <span>on {crossChain || "Arbitrum"}</span>
                  </TokenChainWrapper>
                </BridgeTokenDisplay>
              </Row>
            </TokenView>
          );
        })}
        <Arrow>
          <ArrowIcon bridge />
        </Arrow>
        {tokensTo.map((token, index) => {
          return (
            <TokenView key={getTokenID(token)} shape={index == 0 ? "top" : ""}>
              <Row>
                {outputLoading ? (
                  <StraightLoader />
                ) : (
                  <BalanceDisplayWrapper>
                    <span>To</span>
                    <BalanceDisplay isWrapped={token.wrapped} awarded>
                      {`${formatDisplay(amountsTo[getTokenID(token)] || "0")}`}
                    </BalanceDisplay>
                  </BalanceDisplayWrapper>
                )}
                {/* <TokenDisplay>
                  <img src={token.icon} alt="logo" />
                  <span className="token-symbol-text">{token.symbol}</span>
                </TokenDisplay> */}
                <BridgeTokenDisplay>
                  <BridgeTokenLogo logo={token.icon} chainLogo={allChains[crossChainTo].icon!} />
                  <TokenChainWrapper>
                    {token.symbol}
                    <span>on {crossChainTo || "Arbitrum"}</span>
                  </TokenChainWrapper>
                </BridgeTokenDisplay>
              </Row>
            </TokenView>
          );
        })}
        {allNFTs.length > 0 ? (
          <>
            <Row style={{ margin: "20px 0 16px 0" }}>
              <Label>Selected NFTs</Label>
            </Row>
            <SwiperContainer>
              <NFTsSwiper isConfirmation={true} nftTokens={allNFTs} navitagionTransform={{ left: "0", right: "0" }} />
            </SwiperContainer>
          </>
        ) : (
          <Accountant double={tokensFrom.length > 1 || tokensTo.length > 1} />
        )}
        <Row style={{ marginBottom: "12px" }}>
          <Label>Slippage Tolerance</Label>
          <Value>{slippage.toFixed(2)}%</Value>
        </Row>
        <Row style={{ marginBottom: "12px" }}>
          <Label>{fromInputs ? "Minimum Received" : "Maximum Sent"}</Label>
          <Column>
            {(fromInputs ? tokensTo : tokensFrom).map((token) => {
              const tokenID = getTokenID(token);
              if (summaryAmounts[tokenID] && !outputLoading) {
                return (
                  <Value key={tokenID} style={{ textAlign: "right" }}>
                    {formatDisplay(summaryAmounts[tokenID])} {tokenID}
                  </Value>
                );
              } else {
                return <SkeletonBox key={tokenID} isLoading width="96px" height="14px" borderRadius="4px" />;
              }
            })}
          </Column>
        </Row>
        <Row style={{ marginBottom: "12px" }}>
          <Label>Bridge Fee</Label>
          {bridgeFee > 0 ? (
            <Value>{formatDisplay(bridgeFee.toString(), maxTokenDecimals("ETH"))} ETH</Value>
          ) : (
            <SkeletonBox isLoading width="96px" height="14px" borderRadius="4px" />
          )}
        </Row>
        {txState && txState != "Pending" && (
          <>
            {txState != "Submitted" && (
              <Label data-testid="success-label" style={{ margin: "0 auto 12px auto", color: "#7D7D97" }}>
                {txState == "Success" ? "Success!" : txState}
              </Label>
            )}
            <CircleContainer>{txState == "Success" ? <ConfirmIcon /> : txState.includes("Error") ? <ErrorIcon /> : <Spinner />}</CircleContainer>
            {txState == "Trade Error" && (
              <Label data-testid="waiting-label" style={{ margin: "12px auto 0px auto", color: "#00BDFF", fontWeight: 200 }}>
                {`Your bridged ${tokensFrom[0].symbol} was returned to your wallet on ${crossChainTo}`}
              </Label>
            )}
            {txState != "Submitted" && (
              <ExplorerLink
                href={(txState == "Bridge Error" ? allChains[crossChain] : allChains[crossChainTo]).explorerUrl + "tx/" + txHash}
                target="_blank"
              >{`View in Explorer \u2192`}</ExplorerLink>
            )}
          </>
        )}
        {(txState == "" || txState == "Pending") && (
          <ButtonsContainer>
            <ActionButton
              data-testid="confirm-swap-btn"
              onClick={executeBridge}
              disabled={
                //   activeChain?.id !== allChains[crossChain].chainId ||
                confirmDisabled
                //   tokenToApprove !== undefined
              }
            >
              <ConfirmIcon />
              Confirm Trade
            </ActionButton>
          </ButtonsContainer>
        )}
        {txState == "Submitted" && (
          <>
            <Label data-testid="waiting-label" style={{ margin: "16px auto 4px auto", color: "#7D7D97" }}>
              {`Waiting for ${orderId ? crossChainTo : crossChain} confirmation...`}
            </Label>
            {linkHash && (
              <ExplorerLink href={`https://app.debridge.finance/order?orderId=${linkHash}`} target="_blank">{`View on deBridge \u2192`}</ExplorerLink>
            )}
          </>
        )}
      </ColumnWrapper>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: fit-content;
  max-width: 520px;

  ${Media.mobile} {
    height: 95%;
    max-width: 100%;
    margin-top: 5.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const TokenView = styled.div<{ shape?: string }>`
  position: relative;
  border-radius: 16px;
  z-index: 1;
  max-width: 99.5%;
  padding: 14px 16px;

  & + & {
    margin-top: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #151530;
    border: 1px solid #1e2239;
    border-radius: 16px;
    z-index: -1;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnWrapper = styled(Column)`
  margin-top: 12px;

  ${Media.mobile} {
    flex-basis: 100%;
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;

  & > svg {
    width: 24px;
    height: 24px;
    color: #00bdff;
  }
`;

const SwiperContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 16px;

  ${Media.mobile} {
    margin-bottom: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BalanceDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;

  span {
    color: var(--grey-3, #7d7d97);

    /* Text 4/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 16.32px */
  }
`;

const BalanceDisplay = styled.div<{ isWrapped: boolean; awarded?: boolean }>`
  color: ${({ isWrapped, awarded }) => (isWrapped || awarded ? "#0ABCFD" : "#FFFFFF")};
  flex-grow: 1;
  text-align: left;
  color: var(--White, #fff);

  /* Title 7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
`;

const TokenDisplay = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  img {
    width: 32px;
    height: 32px;
  }

  .token-symbol-text {
    font-size: clamp(14px, 2.5vw, 16px);
    line-height: 48px;
    letter-spacing: -0.03em;
    font-weight: 500;
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%;
    flex-grow: 1;
    white-space: nowrap;
  }
`;

const BridgeTokenDisplay = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const BridgeTokenLogo = styled.div<{ logo: string; chainLogo: string }>`
  height: 32px;
  width: 32px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 14px;
    height: 14px;
    background: url(${({ chainLogo }) => chainLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    border: 2px solid #151530;
    border-radius: 50%;
  }
`;

const TokenChainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;

  color: var(--White, #fff);

  /* Text 3/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */

  span {
    color: #7d7d97;

    /* Text 4/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 16.32px */
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  text-align: left;
  color: #7d7d97;
  white-space: nowrap;
  letter-spacing: -0.02em;
`;

const Value = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
  letter-spacing: 0.02em;
`;

const Summary = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const SummaryText = styled.p`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

const SummaryColor = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`;

const CircleContainer = styled.div`
  width: 64px;
  height: 64px;
  margin: 0px auto;
  color: #00bdff;

  img {
    width: 64px;
    height: 64px;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #151530;
    color: #464659;
  }

  ${Media.tablet} {
    height: 66px;
    margin: 16px auto;
  }
`;

const ExplorerLink = styled.a`
  font-weight: 100;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #7d7d97;
  white-space: nowrap;
  letter-spacing: -0.02em;
  margin: 12px auto 2px auto;

  :hover {
    color: #00bdff;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

import React, { Fragment, useLayoutEffect, useState } from "react";
import { Column, useExpanded, useFilters, useSortBy, useTable } from "react-table";
import styled from "styled-components";
import { SortIcon } from "../../../../../components/Icons/SortIcon";
import { useWidthBreakpoint } from "../../../../../hooks";
import { breakpoints } from "../../../../../styles";
import { LoadingRows } from "@/components/Table/GenericTable";

interface SortableTableProps {
  columns: Array<Column<object>>;
  data: Array<object>;
  renderRowSubComponent?: any;
  pointsLoading: boolean;
}

export const PointsSortableTable = ({ columns: userColumns, data, renderRowSubComponent, pointsLoading }: SortableTableProps) => {
  const [expanded, setExpanded] = useState<{ [id: string]: boolean }>({});

  const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

  const tableInstance = useTable(
    {
      columns: userColumns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    useExpanded
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns, toggleRowExpanded } = tableInstance;

  useLayoutEffect(() => {
    rows.forEach((row) => toggleRowExpanded([row.id], expanded[row.id] == true));
  }, [expanded]);

  useLayoutEffect(() => {
    const newExpanded = { ...expanded };
    rows.forEach((row) => (newExpanded[row.id] = false));
    setExpanded(newExpanded);
  }, [data]);

  const onTableRowClick = (id: string) => {
    const newExpanded = { ...expanded };
    newExpanded[id] = !expanded[id];
    setExpanded(newExpanded);
    toggleRowExpanded([id]);
  };

  return (
    <Fragment>
      {pointsLoading ? (
        <LoadingRows isLoading={true} padding="50px 0 22px" rows={8} />
      ) : (
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {
                      <>
                        {index !== 0 ? column.render("Header") : null}
                        {index !== 0 ? column.canSort && <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} /> : null}
                        {column.canFilter && <div>{column.render("Filter")}</div>}
                      </>
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const tokenData: any = row.original;

              return (
                <Fragment key={row.id}>
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => {
                      if (tokenData.visible && tokenData.name !== "veSHELL") {
                        onTableRowClick(row.id);
                      }
                    }}
                    className={row.isExpanded ? "expanded" : tokenData.visible ? "hoverable" : ""}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </TableRow>
                  {row.isExpanded && tokenData.name !== "veSHELL" && (
                    <TableSubRow>
                      <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                    </TableSubRow>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  th {
    padding-bottom: 12px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7d7d97;

    & > svg {
      margin-left: 8px;
    }

    &:first-child {
      // pointer-events: none;
      // user-select: none;
      // opacity: 0;
      width: 40%;
    }

    &:last-child {
      pointer-events: none;
      user-select: none;
      opacity: 0;
      width: 5%;
    }
  }

  td {
    border-top: 1px solid #1e2239;
    border-bottom: 1px solid #1e2239;
    background: #151530;
  }

  td:first-child {
    border-left: 1px solid #1e2239;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-right: 1px solid #1e2239;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

const TableRow = styled.tr`
  position: relative;
  z-index: 1;

  &.expanded:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  td {
    padding: 26px 0 22px;
    vertical-align: middle;
    text-align: center;
  }

  td:first-child {
    padding-left: 24px;
    text-align: left;
  }

  td:last-child {
    padding-right: 24px;
    text-align: right;
  }

  &.expanded td {
    border-bottom: none;
  }

  &.expanded td:first-child {
    border-bottom-left-radius: 0;
  }

  &.expanded td:last-child {
    border-bottom-right-radius: 0;
  }

  &.hoverable:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.hoverable:hover td:first-child {
    padding-left: 23px;
  }
  &.hoverable:hover td:last-child {
    padding-right: 23px;
  }

  &.expanded:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.expanded:hover td:first-child {
    padding-left: 23px;
  }
  &.expanded:hover td:last-child {
    padding-right: 23px;
  }
`;

const TableSubRow = styled.tr`
  transform: translateY(-10px);

  td {
    background: #10102a;
  }

  & td:first-child {
    border-top-left-radius: 0;
  }

  & td:last-child {
    border-top-right-radius: 0;
  }
`;

import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import { breakpoints, Media } from '../../styles';
import { maxTokenDecimals } from '../../utils/tokens';
import { formatDisplay, formatDisplayShorthand } from '../../utils/formatDisplay';

interface BalanceInfoProps {
  tokenID: string,
  balance: string,
  showMax: boolean,
  isNFTCollection: boolean
  isShrunk: boolean,
  balanceClick: (percentage: number) => void
}

export const BalanceInfo = ({ tokenID, balance, showMax, balanceClick, isNFTCollection, isShrunk }: BalanceInfoProps) => {
  const [showOptions, setShowOptions] = useState(false)

  const percentages = [0.25, 0.5]
  const displayDecimals = maxTokenDecimals(tokenID)

  const MaxOptions = () => {
    return (
      <Row onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
        {showOptions &&
          percentages.map((percentage, index) => {
            return (
              <MaxButton key={percentage.toString()} onClick={() => balanceClick(percentage)}>
                {`${percentage * 100}%`}
              </MaxButton>
            )
          })
        }
        <MaxButton onClick={() => balanceClick(1)}>
          MAX
        </MaxButton>
      </Row>
    );
  };

  const balanceDisplay = isShrunk ? 
                        formatDisplayShorthand(parseFloat(balance), displayDecimals, 1000).replace(/,/g, '.') : 
                        formatDisplay(balance, displayDecimals)

  return (
    <Row>
      {!isShrunk && showMax && !isNFTCollection && <MaxOptions />}
      <BalanceWrapper>
        <Label className={isShrunk ? 'shrunk' : ''}>Balance</Label>
        <Row>
          <Amount data-testid={`amount-label-${tokenID}`} className={isShrunk ? 'shrunk' : ''}>
            {balanceDisplay + ' ' + tokenID}
          </Amount>
        </Row>
        {isShrunk && showMax && !isNFTCollection && <Label style={{color: '#00bdff'}} isClickable={true} isShrunk={isShrunk} onClick={() => balanceClick(1)}>{'Max'}</Label>}
      </BalanceWrapper>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  align-items: end;
  justify-content: right;
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;

  ${props => props.className?.includes('shrunk') &&
    css`
      justify-content: flex-end;
      flex-direction: column;
      gap: 4px;
      flex-wrap: wrap;
    `
  };
`;

const Label = styled.p<{ isClickable?: boolean, isShrunk?: boolean }>`
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #7D7D97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};

  ${( isShrunk ) => isShrunk && css`
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 0px;
    `
  };
`;

const Amount = styled.p`
  font-weight: 500;
  min-width: 52px;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #FFFFFF;
  white-space: nowrap;
  padding-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; 
  flex-grow: 1;
  white-space: nowrap;


  ${props =>
    props.className?.includes('shrunk') &&
    css`     
      font-size: 12px;
      line-height: 14px;
      padding-bottom: 0px;
      white-space: normal;
      word-wrap: break-word;
      max-width: 100%;
      flex-grow: 1;
    `};
`;

// const MaxButton = styled.button`
//   margin-right: 10px;
//   border: none;
//   border: solid 2px white;
//   border-radius: 6px;
//   background: rgba(255, 255, 255,0);
//   color: white;
//   font-size: 10px;
//   font-weight: 500;
//   line-height: 17px;

//   :hover {
//     background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
//     color: #000E47;
//     border: solid 2px #000E47;
//   }
// `
const MaxButton = styled.button`
  margin-right: 10px;
  background: rgba(0, 189, 255, 0.1);
  border: 1px solid #00bdff;
  border-radius: 6px;
  color: #00bdff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  :hover {
    background: #00bdff;
    border-color: transparent;
    border-radius: 6px;
    color: #0a0e27;
  }
`;

// const MaxButton = styled.button`
//   margin-right: 10px;
//   border: none;
//   border: solid 2px white;
//   border-radius: 6px;
//   background: rgba(255, 255, 255,0);
//   color: white;
//   font-size: 10px;
//   font-weight: 500;
//   line-height: 17px;

//   :hover {
//     background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
//     color: #000E47;
//     border: solid 2px #000E47;
//   }
// `

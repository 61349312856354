import React from "react";
import styled from "styled-components";
import { LPTokensBox } from "./LPTokensBox";
import { Media } from "@/styles";
import { usePools } from "../Explore/ExploreContext";

export const FeaturedLPTokens: React.FC = () => {
  const { featuredPools } = usePools();

  return (
    <FeaturedLPTokensContainer>
      <BoxesContainer>
        {featuredPools.map(
          ({ icon, title, description, color, protocol, version, info }) => (
            <LPTokensBox
              key={title}
              icon={icon}
              title={title}
              description={description}
              color={color}
              protocol={protocol}
              version={version}
              info={info}
            />
          )
        )}
      </BoxesContainer>
    </FeaturedLPTokensContainer>
  );
};

const FeaturedLPTokensContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${Media.tablet} {
    padding-inline: 8px;
  }
`;

const BoxesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  ${Media.tablet} {
    grid-template-columns: 1fr;
  }
`;

import { ButtonSecondary } from "@/components/Buttons/Button";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";
import { Media } from "@/styles";
import { timestampToDate } from "@/utils/formatDisplay";
import React from "react";
import styled from "styled-components";

export const StakingDetails = () => {
    const { savedLockPeriod, handleOpenIncreaseLockPeriodModal, getTimeUntil, getUnlockTime } = useRewardsContext();
    const { period, APY, endTime } = {...savedLockPeriod};

    const formatPeriod = () => {
        if (period) {
           const periodText = Object.entries(period).map(([unit, value]) => (
                `${value} ${unit}`
              )).join(' ');
              return periodText;
        };
    }
        
    return savedLockPeriod ? (
        <StakingDetailsContainer>
            <WhiteText>Staking details</WhiteText>
            <StakingDetailsWrapper>
                {/* <SignleDetailWrapper>
                    <GrayText>My APY</GrayText>
                    <GreenValue>{APY! / 100} %</GreenValue>
                </SignleDetailWrapper>
                <HrDiv /> */}
                {/* <SignleDetailWrapper>
                    <GrayText>Lock Period</GrayText>
                    <WhiteValue>{formatPeriod()}</WhiteValue>
                </SignleDetailWrapper> */}
                {/* <HrDiv /> */}
                <SignleDetailWrapper>
                    <GrayText>Time left to unlock</GrayText>
                    <WhiteValue>
                        {getTimeUntil(endTime!)}
                    </WhiteValue>
                </SignleDetailWrapper>
                <HrDiv />
                <SignleDetailWrapper>
                    <GrayText>Unlock time</GrayText>
                    <WhiteValue>{timestampToDate(endTime!.toString())}</WhiteValue>
                </SignleDetailWrapper>
            </StakingDetailsWrapper>
            <CustomSecondaryBtn disabled={endTime! < new Date().getTime() / 1000 || endTime! >= getUnlockTime('~2Y')} onClick={handleOpenIncreaseLockPeriodModal}>Increase lock period</CustomSecondaryBtn>
        </StakingDetailsContainer>
    ) : null
};

const StakingDetailsContainer = styled.div`
width: 100%;
padding: 24px;
border-radius: 16px;
border: 1px solid var(--dark-2, #171B33);
background: var(--dark-1, #0A0E27);
display: flex;
flex-direction: column;
gap: 24px;
justify-content: flex-start;
align-items: flex-start;

${Media.tablet} {
    padding: 16px;
  }
`;

const WhiteText = styled.p`
color: var(--grey-1, #F0F2F6);

/* Text 2/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const StakingDetailsWrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
gap: 12px;
justify-content: flex-start;
align-items: center;
`;

const HrDiv = styled.div`
height: 1px;
width: 100%;
background: #171B33;
`;

const SignleDetailWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

const GrayText = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

const WhiteValue = styled.p`
color: var(--White, #FFF);
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */

span {
    color: var(--grey-3, #7D7D97);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
`;

const GreenValue = styled.p`
color: var(--Algea, #7ADEB9);
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

const CustomSecondaryBtn = styled(ButtonSecondary)`
width: 100%;
padding-top: 17px;
padding-bottom: 17px;
height: unset;
border-radius: 16px;
border-width: 2px;

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border-color: #1E2239;
`}
`;
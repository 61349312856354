import { useRewardsContext } from "@/pages/Rewards/RewardsContext";
import React from "react";
import styled from "styled-components";
import * as S from "./MyVeShellBalance";
import veShellCoin from "@/assets/icons/shell-white-coin.svg";
import shellIcon from '@/assets/coins/SHELL.svg'
import { ButtonPrimary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import { formatDisplay } from "@/utils/formatDisplay";

export const StakingEmptyState = () => {
    const { myStaked, handleOpenStakeModal, myAvailableBalance } = useRewardsContext();
    return (
        <S.MyVeShellBalanceContainer>
            <SectionsContainer>
                <TitleAndActionWrapper>
                    <TitleWrapper>
                        <LabelAndValue>
                            <S.MyVeShellBalanceLabel>My veSHELL Balance</S.MyVeShellBalanceLabel>
                            <S.MyVeShellValueWrapper>
                                <S.ShellIcon src={veShellCoin} alt="veShell icon" />
                                <S.VeShellBalance>{formatDisplay(myStaked.toString())}</S.VeShellBalance>
                            </S.MyVeShellValueWrapper>
                        </LabelAndValue>
                        <DescriptionText>
                            Stake your SHELL to earn SHELL rewards and participate in Shell governance.
                            Voting power is determined by staked SHELL amount and duration. <a href={"https://wiki.shellprotocol.io/shell-dao/overview"} target="_blank">Explore Shell DAO</a>
                        </DescriptionText>
                    </TitleWrapper>
                    <CustomPrimaryBtn onClick={handleOpenStakeModal} disabled={parseFloat(myAvailableBalance) == 0}>
                        Stake
                    </CustomPrimaryBtn>
                </TitleAndActionWrapper>
                <S.HrDiv />
                <DetailsContainer>
                    {/* <DetailWrapper>
                        <S.MyVeShellBalanceLabel>
                            Voting Power
                        </S.MyVeShellBalanceLabel>
                        <S.RightSideWrapper>
                            <S.SmallCoinImg src={veShellCoin} alt="veShell icon" />
                            <S.SmallValue>{myStaked || "0"}</S.SmallValue>
                        </S.RightSideWrapper>
                    </DetailWrapper> */}
                    <DetailWrapper>
                        <S.MyVeShellBalanceLabel>
                        Available to stake:
                        </S.MyVeShellBalanceLabel>
                        <S.RightSideWrapper>
                            <S.SmallCoinImg src={shellIcon} alt="shell coin" />
                            <S.SmallValue>{formatDisplay(myAvailableBalance, 2)} SHELL</S.SmallValue>
                        </S.RightSideWrapper>
                    </DetailWrapper>
                </DetailsContainer>
            </SectionsContainer>
        </S.MyVeShellBalanceContainer>
    )
};


const TitleAndActionWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

${Media.tablet} {
    flex-direction: column;
    gap: 24px;
  }
`;

const SectionsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 24px;
width: 100%;
`;

const TitleWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 12px;
justify-content: flex-start;
align-items: flex-start;
width: 63%;

${Media.tablet} {
    width: 100%;
  }
`;

const LabelAndValue = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
`;

const DescriptionText = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 4/Regular */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 136%; /* 16.32px */
a {
  cursor: pointer;
  text-decoration: none;
  background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
`;

const CustomPrimaryBtn = styled(ButtonPrimary)`
padding: 17px 48px;
width: auto;
height: auto;
border-radius: 12px;

${Media.tablet} {
    width: 100%;
  }

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border: 2px solid #1E2239;
background: var(--dark-4, #151530);
`}
`;

const DetailsContainer = styled.div`
width: 100%;
display: flex;
gap: 24px;
justify-content: flex-start;
align-items: center;

${Media.tablet} {
    flex-direction: column;
  }
`;

const DetailWrapper = styled.div`
display: flex;
justify-content: flex-start;
gap: 8px;
align-items: center;

${Media.tablet} {
    justify-content: space-between;
    width: 100%;
  }
`;

import { formatUnits, parseEther } from "@ethersproject/units";
import { Zero } from "@ethersproject/constants"
import { BigNumber, Contract, providers } from "ethers";
import {
  ExternalDefiToken,
  ShellToken,
  isDefiToken,
  isExternalDefiToken,
  isExternalToken,
  isLBPToken,
  isLPToken,
  isMonoAdapter,
  isNFTCollection,
  isShellToken,
  isShellV2Token,
} from "./tokens";
import { Edge, getTokenID } from "./LiquidityGraph";
import * as ShellV2 from './ocean/index';
import { LiquidityPoolABI } from "../constants/ABI/LiquidityPoolABI";
import { OCEAN_ADDRESS, OLD_OCEAN_ADDRESS } from "../constants/addresses";
import { OceanABI } from "../constants/ABI/OceanABI";
import { addPrice } from "../store/pricesSlice";
import { OceanPoolQueryABI } from "../constants/ABI/OceanPoolQueryABI";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import { Curve2PoolABI } from "@/constants/ABI/Curve2PoolABI";
import { ERC20ABI } from "@/constants/ABI/ERC20ABI";
import * as constants from "@/utils/sor/constants";
import * as types from "@/utils/sor/types";
import { BalancerVaultABI } from "@/constants/ABI/BalancerVaultABI";
import { BalancerPoolABI } from "@/constants/ABI/BalancerPoolABI";
import { calculateWrappedTokenId } from "./ocean/utils";
import { getPoolToken } from "./testRouter";
import { alchemyId } from "@/providers/WagmiProvider";
import { Chain } from "@/placeholders/chains";
import { getSlugForStatisticsPage } from "@/pages/Pools/PoolsTable";
import { NavigateFunction } from "react-router";

export interface PoolState {
    xBalance: BigNumber
    yBalance: BigNumber
    totalSupply: BigNumber
    impAddress: string
}

export class PoolQuery {

    chain: Chain
    ocean : Contract
    oceanPoolQuery : Contract
    oldOceanPoolQuery : Contract | undefined
    tokenMap: {[id: string]: any}
    multicall: any

    balancerVaultAddress = "0xBA12222222228d8Ba445958a75a0704d566BF2C8";

    constructor(chain: Chain, poolQueryAddress: string, oldPoolQueryAddress: string, tokenMap: any) {

        this.chain = chain

        const provider = new providers.JsonRpcProvider(`https://${chain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`);
        this.multicall = new Multicall({
          nodeUrl: `https://${chain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`,
          tryAggregate: true,
        });
        this.tokenMap = tokenMap
        

        this.ocean = new Contract(
            OCEAN_ADDRESS,
            OceanABI,
            provider
        );

        this.oceanPoolQuery = new Contract(
            poolQueryAddress,
            OceanPoolQueryABI, 
            provider
        )

        if(oldPoolQueryAddress){
            this.oldOceanPoolQuery = new Contract(
                oldPoolQueryAddress,
                OceanPoolQueryABI,
                provider
            )
        }

        
    }

    getPools = async (paths: Edge[][]) => {

        const pathPools = paths.map((path) => path.map((edge) => edge.pool ?? '').filter((pool) => pool !== '' && isDefiToken(getPoolToken(pool, this.tokenMap))))
        const poolStates : { [id: string]: PoolState } = {}
        const sharedPools: string[] =[]

        const oldOceanMulticallContext: ContractCallContext[] = [
            {
                reference: 'Ocean v2',
                contractAddress: this.oldOceanPoolQuery?.address ?? '',
                abi: OceanPoolQueryABI as any,
                calls: []
            }
        ];

        const oceanMulticallContext: ContractCallContext[] = [
            {
                reference: 'Ocean v3',
                contractAddress: this.oceanPoolQuery.address,
                abi: OceanPoolQueryABI as any,
                calls: []
            }
        ];

        const promises: {[tokenID: string]: Promise<any>} = {}
    
        for(let i = 0; i < pathPools.length; i++){
    
            for(let j = 0; j < pathPools[i].length; j++){
                
                let pool = pathPools[i][j]

                const token = getPoolToken(pool, this.tokenMap)

                if(isExternalDefiToken(token)){

                    if(token.tokenType == 'Shell'){

                        oldOceanMulticallContext[0].calls.push({
                            reference: token.symbol,
                            methodName: 'getPoolState',
                            methodParameters: [token.address]
                        })
                    } else if(token.tokenType == null || token.tokenType == 'Aave' || token.tokenType == 'Pendle' || token.tokenType == 'STIP'){ 
                        poolStates[token.symbol] = {
                            xBalance: BigNumber.from(0), 
                            yBalance: BigNumber.from(0), 
                            totalSupply: BigNumber.from(0), 
                            impAddress: token.query
                        }  
                    } else if((isMonoAdapter(token) || token.tokenType == 'Beefy')){

                        let childTokenAddresses: string[]

                        if(isMonoAdapter(token)){
                            const regex = token.tokenType == 'Uniswap' ? /UNI-([\w.]+)-([\w.]+)/ : token.tokenType == 'Aerodrome' ? /AERO-([\w.]+)-([\w.]+)/ : /VELO-([\w.]+)-([\w.]+)/;
                            const match = pool.match(regex);
                            if (match) {
                                const token1 = match[1];
                                const token2 = match[2];
                                childTokenAddresses = [this.tokenMap[token1].address, this.tokenMap[token2].address];
                            } else {
                                childTokenAddresses = []; // Return null if the input string doesn't match the expected pattern
                            }
                        } else {
                            const step = paths[i].find((step) => step.pool == pool)
                            if((step!.token as ExternalDefiToken).tokenType == 'Beefy'){
                                childTokenAddresses = [this.tokenMap[token.tokens[0]].address, token.address]
                            } else {
                                childTokenAddresses = [token.address, this.tokenMap[token.tokens[0]].address]
                            }
                        }

                        poolStates[pool] = {
                            xBalance: BigNumber.from(calculateWrappedTokenId(childTokenAddresses[0], 0)), 
                            yBalance: BigNumber.from(calculateWrappedTokenId(childTokenAddresses[1], 0)), 
                            totalSupply: BigNumber.from(0), 
                            impAddress: token.query
                        }
                    } else {

                        let contractCallContext: ContractCallContext[] = [];

                        if (token.tokenType == "Curve") {
                           contractCallContext = [
                              {
                                  reference: token.symbol,
                                  contractAddress: token.address,
                                  abi: Curve2PoolABI as any,
                                  calls: [
                                  {
                                      reference: "xBalance",
                                      methodName: "balances",
                                      methodParameters: [0]
                                  },
                                  {
                                      reference: "yBalance",
                                      methodName: "balances",
                                      methodParameters: [1]
                                  },
                                  {
                                      reference: "totalSupply",
                                      methodName: "totalSupply",
                                      methodParameters: []
                                  },
                                  {
                                      reference: "decimals",
                                      methodName: "decimals",
                                      methodParameters: []
                                  },
                                  ]
                              }
                          ];

                        } else {
                          contractCallContext = [
                            {
                              reference: token.symbol,
                              contractAddress: this.balancerVaultAddress,
                              abi: BalancerVaultABI as any,
                              calls: [
                                {
                                  reference: "poolInfo",
                                  methodName: "getPoolTokens",
                                  methodParameters: [token.metadata],
                                },
                              ],
                            },
                            {
                              reference: "totalSupply",
                              contractAddress: token.address,
                              abi: BalancerPoolABI as any,
                              calls: [
                                {
                                  reference: "totalSupply",
                                  methodName: "getActualSupply",
                                  methodParameters: [],
                                },
                              ],
                            },
                          ];
                        }
                        (token.tokens as string[]).forEach((token) => {
                            const tokenData = this.tokenMap[token]
                            contractCallContext.push({
                                reference: token,
                                contractAddress: tokenData.address,
                                abi: ERC20ABI as any,
                                calls: [{
                                    reference: "decimals",
                                    methodName: "decimals",
                                    methodParameters: []
                                }]
                            })
                        })
                        promises[token.symbol] = this.multicall.call(contractCallContext)
                    }
                } else {
                    oceanMulticallContext[0].calls.push({
                        reference: token.name,
                        methodName: 'getPoolState',
                        // @ts-ignore
                        methodParameters: [token.pool]
                    })
                }
    
                for(let k = i + 1; k < pathPools.length; k++){
                    if (
                      pathPools[k].includes(pool) &&
                      !(pool in sharedPools) &&
                      isLPToken(this.tokenMap[pool]) &&
                      !isMonoAdapter(this.tokenMap[pool])
                    ) {
                      sharedPools.push(pool);
                    }
                }
            }
        }
        if(oldOceanMulticallContext[0].calls.length > 0)
            promises['Ocean v2'] = this.multicall.call(oldOceanMulticallContext)

        if(oceanMulticallContext[0].calls.length > 0)
            promises['Ocean v3'] = this.multicall.call(oceanMulticallContext)

        const responses = await Promise.all(Object.values(promises))

        Object.keys(promises).forEach((tokenID, index) => {
            const tokenResponse = responses[index]

            let state: any[] = []
            let token: any

            if(tokenID == 'Ocean v2' || tokenID == 'Ocean v3'){
                const results: ContractCallResults = tokenResponse
                results.results[tokenID].callsReturnContext.forEach((returnContext) => {
                    state = returnContext.returnValues
                    token = this.tokenMap[returnContext.reference]
                    poolStates[returnContext.reference] = {
                        xBalance: state[0], 
                        yBalance: state[1], 
                        totalSupply: state[2], 
                        impAddress: tokenID == 'Ocean v2' ? token.query : state[3]
                    }  
                })
            } else {

                token = this.tokenMap[tokenID]

                const results: ContractCallResults = tokenResponse  

                if(token.tokenType == 'Curve'){
                    results.results[token.symbol].callsReturnContext.forEach((returnContext) => {
                        if(returnContext.reference == 'xBalance'){
                            const xDecimals = results.results[token.tokens[0]].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(returnContext.returnValues[0]), xDecimals)))
                        } else if(returnContext.reference == 'yBalance'){
                            const yDecimals = results.results[token.tokens[1]].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(returnContext.returnValues[0]), yDecimals)))
                        } else if(returnContext.reference == 'totalSupply'){
                            const totalSupplyDecimals = results.results[token.symbol].callsReturnContext.at(-1)!.returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(returnContext.returnValues[0]), totalSupplyDecimals)))
                        }
                    })    
                } else {
                    const addresses = results.results[token.symbol].callsReturnContext[0].returnValues[0]
                    const balances = results.results[token.symbol].callsReturnContext[0].returnValues[1]
                    token.tokens.forEach((token: any, index: number) => {
                        const tokenAddress = this.tokenMap[token].address
                        const balanceIndex = addresses.indexOf(tokenAddress)
                        if(index === 0){
                            const xDecimals = results.results[token].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(balances[balanceIndex]), xDecimals)))
                        }
                        else {
                            const yDecimals = results.results[token].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(balances[balanceIndex]), yDecimals)))
                        }
                    })

                    state.push(BigNumber.from(results.results['totalSupply'].callsReturnContext[0].returnValues[0]))
                }
                
                poolStates[tokenID] = {
                    xBalance: state[0], 
                    yBalance: state[1], 
                    totalSupply: state[2], 
                    impAddress: token.query
                }  
            } 
        })

        return {states: poolStates, sharedPools: sharedPools}
    
    }

    filterPools = (pools: any, paths: Edge[][]) => {

        const pathPools = paths.map((path) => path.map((edge) => edge.pool ?? '').filter((pool) => pool !== '' && isDefiToken(getPoolToken(pool, this.tokenMap))))
        const poolStates : { [id: string]: PoolState } = {}
        const sharedPools: string[] =[]

        const prevSharedPools = new Set(pools.sharedPools)

        for(let i = 0; i < pathPools.length; i++){
            for(let j = 0; j < pathPools[i].length; j++){
                let pool = pathPools[i][j]
                poolStates[pool] = pools.states[pool]
                if(prevSharedPools.has(pool)) sharedPools.push(pool)
            }
        }

        return {states: poolStates, sharedPools: sharedPools}

    }

    sortPaths = (paths: Edge[][], amounts: BigNumber[]) => {

        // Create an array of indices for the paths array
        const indices = paths.map((_, index) => index);

        // Sort the indices array based on the length of subarrays in paths
        indices.sort((a, b) => paths[b].length - paths[a].length);

        // Create sorted arrays for paths and nftPaths based on the sorted indices
        const sortedPaths = indices.map(index => paths[index]);
        const sortedAmounts = indices.map(index => amounts[index]);

        return [sortedPaths, sortedAmounts]
    }

    filterInputNFTPath = (paths: Edge[][]) => {
        const inputNFTPaths: Edge[][] = []
        paths.forEach((path) => {
            const inputNFTPath : Edge[] = []

            while(path.length > 0 && isNFTCollection(path[0].token)){
                inputNFTPath.push(path.shift()!)
            }
            if(path.length) inputNFTPath.push(path[0])
            inputNFTPaths.push(inputNFTPath)
        })

        return [paths, inputNFTPaths]

    }

    filterOutputNFTPath = (paths: Edge[][]) => {
        const outputNFTPaths: Edge[][] = []
        paths.forEach((path) => {
            const outputNFTPath : Edge[] = []

            while(path.length > 0 && isNFTCollection(path[path.length - 1].token)){
                outputNFTPath.unshift(path.pop()!)
            }
            if(path.length) outputNFTPath.unshift(path[path.length - 1])
            outputNFTPaths.push(outputNFTPath)
        })
        return [paths, outputNFTPaths]

    }

    adjustNFTAmount = (amount : number, nftPath : Edge[]) => {
        const exchangeRate = 100
        nftPath.forEach((step : Edge) => {
            if(step.action == 'Fractionalize' || step.action == 'Unfractionalize') amount *= exchangeRate
        })
        return amount
    }

    getPoolQueryContract = (tokenID : any) => {
        const lpToken = getPoolToken(tokenID, this.tokenMap)
        if(isExternalDefiToken(lpToken)){
            return lpToken.tokenType == 'Shell' ? lpToken.address : lpToken.query
        } else {
            return (lpToken as ShellToken).pool
        }
    }

    getOceanID = (token: any) => {
        if(isShellV2Token(token)){
            const externalToken = Object.values(this.tokenMap).find((extToken) => !extToken.wrapped &&  token.symbol == 'sh' + extToken.symbol)!
            return externalToken.oceanID!
        } else {
            return token.oceanID!
        }
    }

    query: any = async (path: Edge[], amount: BigNumber, pools: any, specifiedInput : boolean) => {

        // @ts-ignore
        const sharedPools = pools.sharedPools.map((pool) => isShellToken(this.tokenMap[pool]) ? this.tokenMap[pool].pool : this.tokenMap[pool].query)
        const pathPools = []

        const steps = []

        if(specifiedInput){
            for (let j = 0; j < path.length - 1; j++) {
                const action = path[j+1].action
                if(action == 'Wrap' || action == 'Unwrap') continue
                steps.push({
                    token: this.getOceanID(path[action == 'Withdraw' ? j + 1 : j].token),
                    // @ts-ignore
                    pool: this.getPoolQueryContract(path[j+1].pool),
                    action: action == 'Swap' ? 0 : action == 'Deposit' ? 2 : 4
                })
                pathPools.push(path[j+1].pool)
            }
        } else {
            for (let j = path.length - 1; j >= 1; j--) {
                const action = path[j].action
                if(action == 'Wrap' || action == 'Unwrap') continue
                steps.push({
                    token: this.getOceanID(path[action == 'Deposit' ? j - 1 : j].token),
                    // @ts-ignore
                    pool: this.getPoolQueryContract(path[j].pool),
                    action: action == 'Swap' ? 1 : action == 'Deposit' ? 3 : 5
                })
                pathPools.push(path[j].pool)
            }
        }

        const poolStates = pathPools.map((pool: string) => pools.states[pool])
        if(!specifiedInput) poolStates.reverse()

        if(steps.length == 0){
            return {
                amount: amount,
                poolStates: poolStates
            }
        }
        // console.log(path, steps, amount, sharedPools, poolStates)
        const result = await this.oceanPoolQuery.query(steps, amount, sharedPools, poolStates)
        
        const newPoolStates: {[id: string]: any} = {}

        pathPools.forEach((pool, index) => {
            newPoolStates[pool] = result[1][index]
        })

        return {
            amount: result[0],
            poolStates: newPoolStates
        }
    }

    getUSDPrice = async (token : any, cachedPrices : any) => {
        const prices = cachedPrices

        const tokenID = getTokenID(token)
        if(prices[tokenID]){
            if(typeof prices[tokenID] == 'number'){
                return prices[tokenID]
            } else {
                return prices[tokenID].find((item: any) => item.id == token.id1155)?.price ?? 0
            }
        } else if(isShellToken(token)){
            const price = await this.getShellTokenPrice(token, prices)
            if(cachedPrices) cachedPrices[tokenID] = price
            return price
        } else if(isExternalDefiToken(token)){
            const price = await this.getExternalDefiTokenPrice(token, prices)
            try{ if(cachedPrices) cachedPrices[tokenID] = price } catch {}
            return price
        } else {
            return 0
        }
    }

    getShellTokenPrice = async (shellToken : any, prices : any) => {
    
        const childTokens = shellToken.tokens.map((child : any) => this.tokenMap[child])
        const priceBalances = []

        const contractCallContext: ContractCallContext[] = this.buildContext(shellToken)

        const results: ContractCallResults = await this.multicall.call(contractCallContext)

        const balances = results.results[shellToken.name].callsReturnContext[0].returnValues.map((value) => BigNumber.from(value))

        for(let i = 0; i < childTokens.length; i++){
            const childToken = childTokens[i]
            const childTokenID = getTokenID(childToken)
            const price = prices[childTokenID] ?? (isShellToken(childToken) ? await this.getShellTokenPrice(childToken, prices) : 0)
            priceBalances.push({
                token: childTokenID,
                price: parseEther(price.toFixed(18)),
                balance: balances[i]
            })
        }

        let totalValue = Zero
        priceBalances.forEach((data : any) => totalValue = totalValue.add(data.balance.mul(data.price)))
        const totalSupply = results.results[`${shellToken.name}-totalSupply`].callsReturnContext[0].returnValues[0]
        const price : BigNumber = totalValue.div(totalSupply)
        
        return parseFloat(formatUnits(price))
    }
    
    getExternalDefiTokenPrice = async (lpToken: any, prices: any) => {

        if(!lpToken.tokenType) return 0

        if(lpToken.tokenType == 'Aave') return prices[lpToken.tokens[0]]

        if(lpToken.tokenType == 'STIP'){
            const underlyingPrice: any = await this.getUSDPrice(this.tokenMap[lpToken.tokens[0]], {...prices})
            return underlyingPrice
        }

        if(lpToken.tokenType == 'Pendle') {
            const marketData = await fetch(`https://api-v2.pendle.finance/core/v1/${this.chain.chainId}/markets/${lpToken.metadata}`).then((response) => response.json())
            return lpToken.symbol.startsWith("PT-") ? marketData.pt.price.usd : marketData.yt.price.usd
        }

        const childTokens = lpToken.tokens.map((child : any) => this.tokenMap[child])
        const priceBalances = []

        let contractCallContext: ContractCallContext[] = this.buildContext(lpToken)

        let results: ContractCallResults = await this.multicall.call(contractCallContext)

        let balances: BigNumber[] = []

        if(lpToken.tokenType == 'Beefy'){
            const underlyingPrice: any = await this.getExternalDefiTokenPrice(this.tokenMap[lpToken.tokens[0]], prices)
            return parseFloat(formatUnits(results.results[lpToken.name].callsReturnContext[0].returnValues[0])) * underlyingPrice
        }

        if(lpToken.tokenType == 'Curve'){    
            balances = results.results[lpToken.name].callsReturnContext.map((returnContext) => BigNumber.from(returnContext.returnValues[0]))
        } else {
            const tokenAddresses = results.results[lpToken.name].callsReturnContext[0].returnValues[0]
            const tokenBalances = results.results[lpToken.name].callsReturnContext[0].returnValues[1]

            lpToken.tokens.forEach((token: any) => {
                const tokenAddress = this.tokenMap[token].address
                const balanceIndex = tokenAddresses.indexOf(tokenAddress)
                balances.push(BigNumber.from(tokenBalances[balanceIndex]))
            })

            balances.push(BigNumber.from(results.results[`${lpToken.name}-totalSupply`].callsReturnContext[0].returnValues[0]))
        }

        for(let i = 0; i < childTokens.length; i++){
            const childToken = childTokens[i]
            const childTokenID = getTokenID(childToken)
            const price = isExternalDefiToken(childToken) ? await this.getExternalDefiTokenPrice(childToken, prices) : prices[childTokenID]
            priceBalances.push({
                token: childTokenID,
                price: price ? parseEther(price.toFixed(18)) : Zero,
                balance: parseEther(formatUnits(balances[i], results.results[childTokenID].callsReturnContext[0].returnValues[0]))
            })
        }

        let totalValue = Zero
        priceBalances.forEach((data : any) => totalValue = totalValue.add(data.balance.mul(data.price)))
        const totalSupply = balances[balances.length - 1]
        const price : BigNumber = totalValue.div(totalSupply)
        
        return parseFloat(formatUnits(price))

    }

    buildContext = (lpToken: any, chain?: Chain, tokenMap?: any) => {

        chain = chain ?? this.chain

        tokenMap = tokenMap ?? this.tokenMap

        let context: ContractCallContext[]

        const childTokens = lpToken.tokens.map((child : any) => tokenMap[child])

        if(isShellToken(lpToken)){
            const poolContract = new Contract(
                isShellV2Token(lpToken) ? lpToken.address : lpToken.pool,
                LiquidityPoolABI,
                new providers.JsonRpcProvider(`https://${chain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`)
            );     
            const childOceanIDs = childTokens.map((childToken : any) => 
                isShellToken(childToken) ? childToken.oceanID : 
                childToken.wrapped || isShellV2Token(childToken) ? 
                childToken.oceanID : 
                ShellV2.utils.calculateWrappedTokenId(childToken.address, 0)
            )
    
            context = [
                {
                    reference: lpToken.name,
                    contractAddress: isShellV2Token(lpToken) ? OLD_OCEAN_ADDRESS : OCEAN_ADDRESS,
                    abi: OceanABI as any,
                    calls: [{
                        reference: "balanceOfBatch",
                        methodName: "balanceOfBatch",
                        methodParameters: [[poolContract.address, poolContract.address], childOceanIDs]
                    }]
                },
                {
                    reference: `${lpToken.name}-totalSupply`,
                    contractAddress: poolContract.address,
                    abi: LiquidityPoolABI as any,
                    calls: [{
                        reference: "getTokenSupply",
                        methodName: "getTokenSupply",
                        methodParameters: [lpToken.oceanID]
                    }]
                },
            ];
        } else if(lpToken.tokenType == 'Beefy'){
            context =  [
                {
                  reference: lpToken.name,
                  contractAddress: lpToken.address,
                  abi: [
                    {
                      inputs: [],
                      name: "getPricePerFullShare",
                      outputs: [
                        { internalType: "uint256", name: "", type: "uint256" },
                      ],
                      stateMutability: "view",
                      type: "function",
                    },
                  ],
                  calls: [
                    {
                      reference: "price",
                      methodName: "getPricePerFullShare",
                      methodParameters: [],
                    },
                  ],
                },
              ];
        } else {

            if(lpToken.tokenType == 'Curve'){
                context = [
                    {
                        reference: lpToken.name,
                        contractAddress: lpToken.address,
                        abi: Curve2PoolABI as any,
                        calls: [{
                            reference: "xBalance",
                            methodName: "balances",
                            methodParameters: [0]
                        },
                        {
                            reference: "yBalance",
                            methodName: "balances",
                            methodParameters: [1]
                        },
                        {
                            reference: "totalSupply",
                            methodName: "totalSupply",
                            methodParameters: []
                        }]
                    }
                ];
            } else {
                context = [
                    {
                        reference: lpToken.name,
                        contractAddress: this.balancerVaultAddress,
                        abi: BalancerVaultABI as any,
                        calls: [{
                            reference: "poolInfo",
                            methodName: "getPoolTokens",
                            methodParameters: [lpToken.metadata]
                        }]
                    },
                    {
                        reference: `${lpToken.name}-totalSupply`,
                        contractAddress: lpToken.address,
                        abi: BalancerPoolABI as any,
                        calls: [{
                            reference: "totalSupply",
                            methodName: "getActualSupply",
                            methodParameters: []
                        }]
                    }
                ];
            }

            childTokens.forEach((childToken: any) => {
                context.push({
                    reference: childToken.symbol,
                    contractAddress: childToken.address,
                    abi: ERC20ABI as any,
                    calls: [{
                        reference: "decimals",
                        methodName: "decimals",
                        methodParameters: []
                    }]
                })
            })

        }

        return context
    }

    generateBuySellTokens = (tokenData : any, buy : boolean) => {
      if(buy){
          if(isLPToken(tokenData)){
              return { initInput: tokenData.tokens.map((tokenID: string | number) => this.tokenMap[tokenID]), initOutput: [tokenData]  }
          } else {
              return { initInput: [this.tokenMap['ETH']], initOutput: [tokenData]  }
          }
      } else {
          if(isLPToken(tokenData)){
              return { initInput: [tokenData], initOutput: tokenData.tokens.map((tokenID: string | number) => this.tokenMap[tokenID]) }
          } else {
              return { initInput: [tokenData], initOutput: [this.tokenMap['ETH']]  }
          }
      }
    }

    redirectToPool = (token: any, navigate: NavigateFunction) => {
        if (isLBPToken(token)) {
            navigate(`/lbp/${token.symbol}`);
        } else if(isExternalToken(token)){
            if(isShellV2Token(token)){
                navigate(getSlugForStatisticsPage(token));
            } else {
                navigate('/trade', { state: this.generateBuySellTokens(token, true) })
            }
        } else {
            navigate(getSlugForStatisticsPage(token));
        }
    }

}
import React from "react";

export const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15495 15.8333C12.8368 15.8333 15.8216 12.8486 15.8216 9.16667C15.8216 5.48477 12.8368 2.5 9.15495 2.5C5.47305 2.5 2.48828 5.48477 2.48828 9.16667C2.48828 12.8486 5.47305 15.8333 9.15495 15.8333Z"
        stroke="url(#paint0_linear_3637_38402)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4844 17.5L13.8594 13.875"
        stroke="url(#paint1_linear_3637_38402)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3637_38402"
          x1="2.60495"
          y1="2.5"
          x2="15.9238"
          y2="2.60288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3637_38402"
          x1="13.8911"
          y1="13.875"
          x2="17.5122"
          y2="13.903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

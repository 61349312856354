import React from "react";

export const SplitActionIcon = () => {
  return (
    <svg
      width="186"
      height="26"
      viewBox="0 0 186 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="183"
        cy="23"
        r="2.5"
        transform="rotate(90 183 23)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <circle
        cx="93"
        cy="3"
        r="2.5"
        transform="rotate(90 93 3)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <circle
        cx="3"
        cy="23"
        r="2.5"
        transform="rotate(90 3 23)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <path
        d="M3 20.5V20.5C3 16.9101 5.91015 14 9.5 14H49.5H85C89.4183 14 93 10.4183 93 6V6"
        stroke="#37DCF2"
        strokeDasharray="0"
      />
      <path
        d="M183 20.5V20.5C183 16.9101 180.09 14 176.5 14H136.5H101C96.5817 14 93 10.4183 93 6V6"
        stroke="#37DCF2"
        strokeDasharray="0"
      />
    </svg>
  );
};

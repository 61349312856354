import { useWidthBreakpoint } from "@/hooks";
import { breakpoints } from "@/styles";
import React, { Fragment, useMemo, useContext } from "react";
import styled from "styled-components";

import { PointsTokenPreviewCell } from "../../Points/components/TableCells/PointsTokenPreviewCell";
import { SortIcon } from "@/components/Icons/SortIcon";
import { useTable, useFilters, useSortBy } from "react-table";
import { AirdropTale } from "./AirdropTale";
import { OtherAirdropClaimButton } from "../components/OtherAirDropClaimButton";
import { formatDisplay } from "@/utils/formatDisplay";

import { GeoContext } from "@/components/Overlays/GeoProvider";
import { LoadingRows } from "@/components/Table/GenericTable";
import { useRewardsContext } from "../../../RewardsContext";

export const AirdropTable = (props: any) => {
  const { tokens } = props;
  const {
    airdropLoading
  } = useRewardsContext();
  const { isGeoLoading } = useContext(GeoContext);

  const columns = useMemo(
    () => [
      {
        Header: "Token",
        accessor: "token",
        Cell: ({ row }: any) => <PointsTokenPreviewCell row={row} />,
        disableSortBy: true
      },
      {
        Header: "Reward",
        accessor: "reward",
        Cell: ({ value, row }: any) => 
        <BalanceWrapper>
            <Balance pending={!row.original.claimed}>{formatDisplay(value.toString(), 2)}</Balance>
            <Symbol pending={!row.original.claimed}>{row.original.name}</Symbol>
        </BalanceWrapper>,
        disableSortBy: true
      },
      {
        id: "Claim",
        accessor: "reward",
        Cell: ({ value, row }: any) => <OtherAirdropClaimButton row={row.original} />,
      },
    ],
    []
  );

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

  const data = tokens

  const tableInstance = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  return (
    <>
      <PointsTableWrapper>
        {tokens.length > 0 && !(isGeoLoading || airdropLoading) && ( 
          (isTablet ? (
            <TalesWrapper>
              {rows.map((row: any, index: number) => (
                <AirdropTale
                  key={row.id + "-AirdropTale"}
                  row={row.original}
                />
              ))}
            </TalesWrapper>
          ) : (
            <Table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {
                          <>
                            {column.render("Header")}
                            {column.canSort && (
                                  <SortIcon
                                    isSorted={column.isSorted}
                                    isSortedDesc={column.isSortedDesc}
                                  />
                                )
                            }
                            {column.canFilter && (
                              <div>{column.render("Filter")}</div>
                            )}
                          </>
                        }
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  const tokenData: any = row.original;

                  return (
                    <Fragment key={row.id}>
                      <TableRow
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          ))
        )}
        {(airdropLoading || isGeoLoading) ? (
          <LoadingRows isLoading={true} rows={1} padding="80px 0 22px" />
        ) : !tokens.length ? (
          <LoadingRows isLoading={true} rows={1} padding="80px 0 22px" />
        ) : (
        null
        )}
      </PointsTableWrapper>

    </>
  );
};

const PointsTableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border-top: 1px solid #171B33;
`;

const TalesWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  th {
    padding-bottom: 2px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7d7d97;

    & > svg {
      margin-left: 8px;
    }

    &:first-child {
      width: 60%;
      padding-left: 12px;
    }

    &:last-child {
      pointer-events: none;
      user-select: none;
      opacity: 0;
      width: 5%;
    }
  }

  td {
    border-top: 1px solid #1e2239;
    border-bottom: 1px solid #1e2239;
    background: #151530;
  }

  td:first-child {
    border-left: 1px solid #1e2239;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-right: 1px solid #1e2239;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

const TableRow = styled.tr`
  position: relative;
  z-index: 1;

  &.expanded:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  td {
    padding: 26px 0 22px;
    vertical-align: middle;
    text-align: center;
  }

  td:first-child {
    padding-left: 24px;
    text-align: left;
  }

  td:last-child {
    padding-right: 24px;
    text-align: right;
  }

  &.expanded td {
    border-bottom: none;
  }

  &.expanded td:first-child {
    border-bottom-left-radius: 0;
  }

  &.expanded td:last-child {
    border-bottom-right-radius: 0;
  }

  &.hoverable:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.hoverable:hover td:first-child {
    padding-left: 23px;
  }
  &.hoverable:hover td:last-child {
    padding-right: 23px;
  }

  &.expanded:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.expanded:hover td:first-child {
    padding-left: 23px;
  }
  &.expanded:hover td:last-child {
    padding-right: 23px;
  }
`;

const BalanceWrapper = styled.div`
display: flex;
align-items: center;
gap: 4px;
`;

const Balance = styled.p<{ pending: boolean }>`
color: ${({ pending }) => pending ? "#00BDFF" : "#FFF"};
/* Text 2/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const Symbol = styled.p<{ pending: boolean }>`
color: ${({ pending }) => pending ? "#FFF" : "#7D7D97"};
/* Text 2/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

import { Chain } from "@/placeholders/chains";

export const SHELL_API_URL = "https://points-api.shellprotocol.io/";

export const POINTS_API = (chain: Chain) => SHELL_API_URL + 'points/' + chain.api + '/';
export const POOL_API = (chain: Chain) => SHELL_API_URL + 'analytics/' + chain.api + '/';
export const LBP_API = (chain: Chain) => SHELL_API_URL + 'analytics/' + chain.api + '/lbp/';
export const HISTORY_API = (chain: Chain) => SHELL_API_URL + 'shellscan/' + chain.api + '/';
export const PRICES_API = (chain: Chain) => SHELL_API_URL + 'points/' + chain.api + '/currentprices';
export const NFT_PRICES_API = (chain: Chain) => SHELL_API_URL + 'points/' + chain.api + '/nftprices';
export const EXPLORER_API = (chain: Chain) => SHELL_API_URL + 'points/' + chain.api + '/explorer'

export const COLLAB_DROP_API = SHELL_API_URL + 'points/collab-drop/';
export const CRAB_DROP_API = SHELL_API_URL + 'points/crabs/';
export const SHELL_REWARDS_API = SHELL_API_URL + 'points/shellRewards/';
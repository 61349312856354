const BASE_URL: string = "https://api.odos.xyz";

const SOR_QUOTE_V2_ENDPOINT: string = `${BASE_URL}/sor/quote/v2`;
const INFO_TOKENS_ENDPOINT: string = `${BASE_URL}/info/tokens/`;

const ARB_SOURCES: any = {
    'Shell': ["Shell V2", "Shell V3"],
    'Curve': ["Curve Crypto Registry", "Curve Registry", "Curve Stable NG"],
    'WETH': ["Wrapped Ether"],
    'Balancer': ["Balancer V2 Stable"],
    'Uniswap': ["Uniswap V3"]
};

const BASE_SOURCES: any = {
    'WETH': ["Wrapped Ether"],
    'Balancer': ["Balancer V2 Stable"],
    'Uniswap': ["Uniswap V3"],
    'Aerodrome': ["Aerodrome Stable", "Aerodrome Volatile"]
}

const OP_SOURCES: any = {
    'WETH': ["Wrapped Ether"],
    // 'Balancer': ["Balancer V2 Stable"],
    'Uniswap': ["Uniswap V3"],
    'Velodrome': ["Velodrome Stable", "Velodrome Volatile"]
}

const MAINNET_SOURCES: any = {
    'WETH': ["Wrapped Ether"],
    'Balancer': ["Balancer V2 Stable"],
    'Uniswap': ["Uniswap V3"],
}

const SOURCES: {[id: string]: any} = {
    '42161': ARB_SOURCES,
    '8453': BASE_SOURCES,
    '10': OP_SOURCES,
    '1': MAINNET_SOURCES
}

const QUOTE_DURATION = 60000;

export {
  BASE_URL,
  SOR_QUOTE_V2_ENDPOINT,
  INFO_TOKENS_ENDPOINT,
  SOURCES,
  QUOTE_DURATION,
};

import React, { ReactNode } from "react";
import styled from "styled-components";

interface ModalNavItemProps {
    title: string;
    icon: ReactNode;
    active?: boolean;
    click: () => void;
};

export const ModalNavItem = ({ title, icon, active, click }: ModalNavItemProps) => {
    return (
        <NavItemWrapper active={active} onClick={click}>
            <Icon>{icon}</Icon>
            <Title active={active}>
                {title}
            </Title>
        </NavItemWrapper>
    )
};

const NavItemWrapper = styled.div<{ active?: boolean }>`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;
padding: 12px;
width: 100%;
border-radius: 8px;
border: ${({ active }) => active ? "1px solid #1E2239" : "1px solid transparent"};
background: ${({ active }) => active ? "var(--dark-1, #171B33)" : "none"};
position: relative;
cursor: pointer;
transition: all 0.2s ease-in-out;

&:hover {
    svg:not(:nth-child(3)) {
        path {
            fill: ${({ active }) => active ? "default" : "var(--White, #FFF)"};
        }
    }
}

&::before {
    position: absolute;
content: "";
width: ${({ active }) => active ? "4px" : "0"};
height: 44.5px;
top: 50%;
transform: translateY(-50%);
background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
right: 1px;
border-top-right-radius: 45px;
border-bottom-right-radius: 45px;
}

&:hover {
p {
    color: var(--White, #FFF);
}
}
`;

const Icon = styled.div<{ active?: boolean }>`
transition: all 0.2s ease-in-out;
svg {
    transition: all 0.2s ease-in-out;
width: 20px;
height: 20px;
}
`;

const Title = styled.p<{ active?: boolean }>`
color: ${({ active }) => active ? "var(--White, #FFF)" : "var(--dark-3, #7D7D97)"};
transition: all 0.2s ease-in-out;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
transition: color 0.3s;
`;
import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { getFormattedChartDate, getFormattedChartHalfDate } from "@/utils";
import { useWidthBreakpoint } from "@/hooks";
import { breakpoints } from "@/styles";
import { formatDisplay, formatDisplayShorthand } from "@/utils/formatDisplay";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";

interface DataPoint {
  date: number;
  tokens?: number;
  tokensUnlocked?: number;
  tokensForGradient?: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: number; date: "string"; payload: any }[];
  label?: number;
}

interface CustomYAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
}

export const VestingTimeline = () => {
  const { vestingStartTime, vestingEndTime, vestingStreams } =
    useRewardsContext();

  const totalVesting = vestingStreams
    .map((stream) => parseFloat(stream.vesting))
    .reduce((partialSum, a) => partialSum + a, 0);
  const totalVested = vestingStreams
    .map((stream) => parseFloat(stream.vested))
    .reduce((partialSum, a) => partialSum + a, 0);
  const total = parseFloat((totalVesting + totalVested).toFixed(2));

  const generateMiddleDataPoints = (
    startTime: number,
    endTime: number
  ): DataPoint[] => {
    const numberOfDays = Math.round(
      (endTime - startTime) / (60 * 60 * 24)
    );
    const tokensIncrement = total / numberOfDays;
    const middleDataPoints: DataPoint[] = [];
    const today = new Date().getTime() / 1000;

    let beforeToday = true;
    for (let i = 1; i < numberOfDays; i++) {
      const tokens = tokensIncrement * i;
      const date = new Date(startTime + i * (60 * 60 * 24)).getTime();
      if (date < today) {
        middleDataPoints.push({
          date: date,
          tokens: tokens,
          tokensForGradient: tokens,
        });
      } else {
        if (beforeToday) {
          middleDataPoints.push({
            date: date,
            tokens: tokens,
            tokensForGradient: tokens,
          });
          beforeToday = false;
        } else {
          middleDataPoints.push({
            date: date,
            tokensUnlocked: tokens,
            tokensForGradient: tokens,
          });
        }
      }
    }
    return middleDataPoints;
  };

  const data: DataPoint[] = [
    {
      date: vestingStartTime,
      tokens: 0,
      tokensForGradient: 0,
    },
    ...generateMiddleDataPoints(vestingStartTime, vestingEndTime),
    {
      date: vestingEndTime,
      tokensUnlocked: total,
      tokensForGradient: total,
    },
  ];

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    const toUnlock =
      (payload && payload[0] && payload[0].payload.tokensUnlocked > 0) || false;
    if (active && payload && payload.length > 0) {
      return (
        <TooltipContainer>
          <TooltipRow>
            <TooltipDate>{getFormattedChartHalfDate(label!)}</TooltipDate>
          </TooltipRow>
          <LabelAndValueWrapper>
            <NameLabel toUnlock={toUnlock}>Unlocked Tokens:</NameLabel>
            <TooltipValueWrapper>
              <Value>{formatDisplay(payload[0].value.toString(), 2)}</Value>
              <Symbol>SHELL</Symbol>
            </TooltipValueWrapper>
          </LabelAndValueWrapper>
        </TooltipContainer>
      );
    }

    return null;
  };

  const CustomYAxisTick: React.FC<CustomYAxisTickProps> = ({
    x,
    y,
    payload,
  }) => {
    const { value } = payload;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={36}
          y={4}
          dy={16}
          fill="#7D7D97"
          fontFamily="Inter"
          fontSize="12px"
          textAnchor="end"
        >
          {formatDisplayShorthand(value, 0, 1e4)}
        </text>
      </g>
    );
  };

  const CustomXAxisTick: React.FC<CustomYAxisTickProps> = ({
    x,
    y,
    payload,
  }) => {
    const { value } = payload;
    if (payload === data[0].date) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={24}
            y={0}
            dy={16}
            fill="#7D7D97"
            fontFamily="Inter"
            fontSize="12px"
            textAnchor="end"
          ></text>
        </g>
      );
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={24}
          y={0}
          dy={16}
          fill="#7D7D97"
          fontFamily="Inter"
          fontSize="12px"
          textAnchor="end"
        >
          {getFormattedChartDate(value)}
        </text>
      </g>
    );
  };

  const transformedData = data.map(
    ({ date, tokensUnlocked, tokens, tokensForGradient }) => ({
      date,
      tokens: tokens,
      tokensUnlocked: tokensUnlocked,
      tokensForGradient: tokensForGradient,
    })
  );

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  return (
    <VestingTimelineWrapper>
      <Title>Vesting Timeline</Title>
      <StyledResponsiveContainer width="100%" height={379}>
        <AreaChart
          data={transformedData}
          width={800}
          height={400}
          margin={{
            top: isTablet ? 4 : 8,
            right: isTablet ? -10 : -2,
            left: isTablet ? 10 : 25,
            bottom: isTablet ? 25 : 45,
          }}
        >
          <defs>
            <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5.96%" stopColor="#00BDFF" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#00BDFF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tick={<CustomXAxisTick />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            type="number"
            ticks={Array.from({ length: 5 }, (_, i) => (i / 4) * total)}
            orientation="right"
            tick={<CustomYAxisTick />}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Area
            type="linear"
            dataKey="tokens"
            stroke="#00BDFF"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            dot={false}
            connectNulls={true}
            strokeLinejoin="miter"
            strokeLinecap="butt"
            fillOpacity={0}
            fill={`transparent`}
          />
          <Area
            type="linear"
            dataKey="tokensUnlocked"
            stroke="#87C0DD"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            dot={false}
            connectNulls={true}
            strokeDasharray="5 5"
            strokeLinejoin="miter"
            strokeLinecap="butt"
            fillOpacity={0}
            fill={`transparent`}
          />
          <Area
            type="linear"
            dataKey="tokensForGradient"
            stroke="transparent"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            dot={false}
            connectNulls={true}
            strokeDasharray="5 5"
            strokeLinejoin="miter"
            strokeLinecap="butt"
            fillOpacity={1}
            fill="url(#lineGradient)"
          />
        </AreaChart>
      </StyledResponsiveContainer>
    </VestingTimelineWrapper>
  );
};

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 16px;
  border-radius: 16px;
  border: 1px solid #171b33;

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  .recharts-xAxis {
    transform: translateY(10px) translateX(-20px);
  }

  .recharts-yAxis {
    transform: translateY(-10px);
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  padding: 10px 12px;
  border: 1px solid #1e2239;
  border-radius: 14px;
  background-color: #171b33;
`;

const TooltipDate = styled.span`
  color: var(--grey-3, #7d7d97);

  /* Text 4/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 136%; /* 16.32px */
`;

const VestingTimelineWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const Title = styled.h2`
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
`;

const TooltipValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const LabelAndValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const NameLabel = styled.p<{ toUnlock: boolean }>`
  color: var(--Dolphin, #87c0dd);
  ${({ toUnlock }) =>
    toUnlock
      ? "color: var(--Dolphin, #87C0DD);"
      : "color: var(--grey-3, #00BDFF);"}

  /* Text 3/Medium */
font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
`;

const Value = styled.p`
  color: var(--White, #fff);

  /* Text 2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Symbol = styled.p`
  color: var(--grey-3, #7d7d97);

  /* Text 2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

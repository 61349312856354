
import { Spinner } from "@/components/Loaders";
import { queryInteraction } from "@/pages/Transactions/interactionHelpers";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { addInteraction } from "@/store/transactionsSlice";
import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { TransactionAction } from "./TransactionAction";
import { TransactionDetails } from "./TransactionDetails";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media, breakpoints } from "@/styles";
import { isShellToken, isShellV2Token } from "@/utils/tokens";
import { reduceString } from "@/utils/reduceString";
import { useWidthBreakpoint } from "@/hooks";
import { LinkButton } from "@/components/Buttons/LinkButton";
import { formatDisplay } from "@/utils/formatDisplay";
import { getSlugForStatisticsPage } from "@/pages/Pools/PoolsTable";
import { useLocation } from 'react-router';
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TableSubRowProps {
  row: any;
}

export const TransactionTableSubRow = ({ row }: TableSubRowProps) => {

  const { tokenMap, poolQuery, connectedChain } = useContext(ChainContext)

  const [isLoading, setIsLoading] = useState(true);

  const { original } = row;
  const transactionHash = original.transactionHash;
  const input = original.input;
  const output = original.output;

  const statTokens = input.tokens.concat(output.tokens)
                        .map((tokenID : string) => tokenMap[tokenID])
                        .filter((token : any) => isShellToken(token))

  const arrowType = original.arrowType;

  const [interaction, setInteraction] = useState({})

  const userInteractions = useAppSelector(
    (state) => state.transactions.interactions[connectedChain.name]
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMobile = useWidthBreakpoint(breakpoints.mobile)

  const getInteraction = async (
    transactionHash: string,
    input: any,
    output: any,
    arrowType: string
  ) => {
    let interaction: any;
    if (userInteractions[transactionHash]) {
      interaction = userInteractions[transactionHash];
    } else {
      interaction = await queryInteraction(
        connectedChain,
        transactionHash,
        input,
        output,
        arrowType
      );
      if(interaction.srcChain && interaction.destChain){
        interaction.preArrowPaths[0][0].chain = interaction.destChain
        const firstStep = {...interaction.preArrowPaths[0][0]}
        firstStep.chain = interaction.srcChain
        firstStep.amount = input.amounts[0]
        if(interaction.preArrowPaths[0][0].token == interaction.preArrowPaths[0][1].token){
            interaction.preArrowPaths[0].pop()
        } else {
            interaction.preArrowPathLength++
        }
        interaction.preArrowPaths[0].unshift(firstStep)
      }
      dispatch(
        addInteraction({
          chain: connectedChain.name,
          hash: transactionHash,
          data: {
            preArrowPaths: interaction.preArrowPaths,
            postArrowPaths: interaction.postArrowPaths,
            preArrowPathLength: interaction.preArrowPathLength,
            postArrowPathLength: interaction.postArrowPathLength,
            block: interaction.block,
            fee: formatDisplay(interaction.fee.toString(), 2)
          },
        })
      );
    }
    return interaction
  };

  useEffect(() => {
    getInteraction(transactionHash, input, output, arrowType).then((interaction) => {
        setInteraction(interaction)
        setIsLoading(false);
    }).catch((_) => {
        setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Interactions>
          <>
            <FlexContainer hasStatTokens={statTokens.length > 0} isLoading={isLoading}>
                <TransactionDetails row={row} interaction={interaction} isLoading={isLoading} />
                <TransactionAction row={row} interaction={interaction}  isLoading={isLoading} />
            </FlexContainer>
          </>
          {!location.pathname.includes('statistics') && statTokens.map((token : any) => 
            <TokensContainer>
                <Container>
                    <TokenInfoContainer>
                        <Image src={token.icon} />
                        <FlexColumnContainer>
                            {token.name}
                            <TokenSubtitle>LP {reduceString(token.oceanID, 6, 4)}</TokenSubtitle>
                        </FlexColumnContainer>
                    </TokenInfoContainer>
                    <LinkButton
                        to={(isShellV2Token(token) || isShellToken(token)) ? getSlugForStatisticsPage(token) : `/trade`}
                        outline={true}
                        arrow={true}
                        state={!isShellV2Token(token) ? poolQuery.generateBuySellTokens(token, true) : {}}
                        label={isMobile ? 'Details' : 'View Pool Stats'}
                    />
                </Container>
            </TokensContainer>
           )}
      </Interactions>
    </>
  );
};

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: auto;

  ${Media.tablet} {
    width: 36px;
    height: 36px;
    margin: 24px auto;

    img {
        width: 36px;
        height: 36px;
    }
  }
`

const Image = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 64px;

  ${Media.tablet} {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }
`;

const TokensContainer = styled.div`
  display: flex;
  flex-direction: column;
  //padding: 0px 32px;

  ${Media.tablet} {
    padding-inline: 0;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 24px 32px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  ${Media.tablet} {
    padding: 20px 16px;
  }
`

const TokenInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexContainer = styled.div<{ isLoading: boolean, hasStatTokens: boolean }>`
  display: flex;
  padding-block: 24px;

  ${({ hasStatTokens }) => {
    if (hasStatTokens) {
      return css`
        border-bottom: 1px solid #1e2239;
      `;
    }
  }}

  ${Media.tablet} {
    padding-top: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: left;

  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;

  span {
    color: var(--grey-3, #7d7d97);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  ${Media.mobile} {
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }
`;

const Interactions = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${Media.tablet} {
    padding-block: 0px;
  }
`;

const TokenButton = styled(ButtonSecondary)`
  margin-left: auto;
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.mobile} {
    height: 40px;
    font-size: 14px;
    border-radius: 20px;
    padding-inline: 12px 4px;
    gap: 0;
    font-weight: 500;
    margin: 0;
  }
`;

const TokenSubtitle = styled.span`
  font-size: 16px;
  line-height: 20px;

  ${Media.tablet} {
    font-size: 14px;
    line-height: 16px;
  }
`;
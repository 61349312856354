import { ProtocolTypeOption } from "@/components/DexBadge/DexBadge";

export interface Token {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  oceanID?: string;
  icon: string;
  status?: string;
}

export interface VeToken {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  icon: string;
  status?: string;
}

export const placeholderToken: Token = {
  name: "Select Token",
  symbol: "Select token",
  address: "0",
  wrapped: false,
  icon: "",
};

export interface ShellToken extends Token {
  oceanID: string;
  pool: string;
  tokens: string[];
  fee: number;
  tokenType?: ProtocolTypeOption;
  protocolVersion?: string;
}
export interface NFTCollection extends Token {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  ipfsHash: string;
  fractionalizer?: string;
  totalSupply?: number;
  is1155?: boolean;
  id1155?: number;
  isVestingStream?: boolean;
  description?: string;
  slug?: string;
}

export interface NFT {
  id: number;
  symbol: string;
  name: string;
  wrapped: boolean;
  rarity: number;
  image: string;
}

export interface VestingStream extends NFT {
  claimable: string;
  vesting: string;
  vested: string;
  claimed: string;
}

export interface LBPToken extends Token {
  description: string;
  maxSupply?: number;
  pairToken: string;
  poolName: string;
  poolAddress: string;
  poolOceanID: string;
  poolIcon: string;
}
export interface ExternalToken extends Token {
  tokenType: ProtocolTypeOption;
  protocolVersion?: string;
  metadata?: any;
}

export interface ExternalDefiToken extends ExternalToken {
  pool: string;
  query: string;
  tokens: string[];
  fee: number;
  metadata?: any;
}

export interface NFT1155 {
  id: number;
  name: string;
  image: string;
  balance: number;
  desc: string;
}

export function isShellToken(token: any): token is ShellToken {
  return token && "name" in token && "icon" in token && "oceanID" in token && "pool" in token && "tokens" in token;
}

export function isNFTCollection(token: any): token is NFTCollection {
  return token && "name" in token && "symbol" in token && "address" in token && "wrapped" in token && "ipfsHash" in token;
}

export function isLBPToken(token: any): token is LBPToken {
  return (
    token &&
    //   token.name.includes('lbp') &&
    "name" in token &&
    "description" in token &&
    "symbol" in token &&
    "address" in token &&
    "wrapped" in token &&
    "pairToken" in token
  );
}

export function isExternalToken(token: any): token is ExternalToken {
  return (
    token &&
    "name" in token &&
    "symbol" in token &&
    "address" in token &&
    "wrapped" in token &&
    "tokenType" in token &&
    !(token.tokenType == "Shell" && token.protocolVersion == "V3")
  );
}

export function isExternalDefiToken(token: any): token is ExternalDefiToken {
  return (
    token && "name" in token && "symbol" in token && "address" in token && "wrapped" in token && "pool" in token && "query" in token && "tokenType" in token
  );
}

export const isLPToken = (token: any) => {
  return (
    isShellToken(token) ||
    (isExternalDefiToken(token) && token.tokenType! && !(token.tokenType == "Aave" || token.tokenType == "Pendle" || token.tokenType == "Beefy" || token.tokenType == 'STIP'))
  );
};

export const isDefiToken = (token: any) => {
  return isShellToken(token) || isExternalDefiToken(token);
};

export function isPlaceholderToken(token: any) {
  return token.name == "Select Token" && token.address == "0";
}

export const isShellV2Token = (token: any) => {
  return isExternalToken(token) && token.tokenType == "Shell" && token.protocolVersion == "V2";
};

export const isMonoAdapter = (token: any) => {
    return isExternalDefiToken(token) && (token.tokenType == 'Uniswap' || token.tokenType == 'Aerodrome' || token.tokenType == 'Velodrome')
}

export function maxTokenDecimals(token: string) {
  if (token.includes("WBTC") && !token.includes("+")) return 5;
  else if (token.includes("ETH") && !token.includes("+")) return 3;
  else return 2;
}

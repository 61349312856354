import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ButtonSecondary } from "../../../components/Buttons/Button";
import { CopyButton } from "../../../components/Buttons/CopyButton";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { formatDisplayShorthand } from "../../../utils/formatDisplay";
import { reduceString } from "../../../utils/reduceString";
import { Composition } from "../../../components/Composition/Composition";
import { Media } from "../../../styles";
import { Link } from "react-router-dom";
import { StatusBullet } from "@/pages/LBP/StatusBullet";
import { addMetadata } from "@/store/lbpsSlice";
import { LBP_API } from "@/constants/urls";
import { extract1155Data } from "@/utils/nftHelpers";
import { CompositionSkeleton, StatsSkeleton } from "@/pages/Rewards/components/Points/components/PoolSubRow";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { Chain } from "@/placeholders/chains";

interface LBPTokenInfoProps {
  selectedToken: any;
  isShrunk: boolean;
  chain: Chain;
}

export const lbpTime = (startTime: number, endTime: number) => {

    const elapsedSeconds = endTime - startTime;
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);
    
    const formattedElapsedDays = elapsedDays.toString() + 'd' //formatTimeUnits(elapsedDays, 'day');
    const formattedElapsedHours = (elapsedHours % 24).toString() + 'h' //formatTimeUnits(elapsedHours % 24, 'hour');
    const formattedElapsedMinutes = (elapsedMinutes % 60).toString() + 'm' //formatTimeUnits(elapsedMinutes % 60, 'min');
    const formattedElapsedSeconds = (elapsedSeconds % 60).toString() + 'sec' //formatTimeUnits(elapsedSeconds % 60, 'sec');

    if(elapsedMinutes == 0 && elapsedHours == 0 && elapsedDays == 0){
        return formattedElapsedSeconds
    } else if(elapsedHours == 0 && elapsedDays == 0){
        return formattedElapsedMinutes
    } else if(elapsedDays == 0){
        return `${formattedElapsedHours} ${elapsedMinutes % 60 > 0 ? formattedElapsedMinutes : formattedElapsedSeconds}`
    } else {
        return `${formattedElapsedDays} ${elapsedHours % 24 > 0 ? formattedElapsedHours : formattedElapsedMinutes}`
    }
}

export const LBPTokenInfo = ({ selectedToken, isShrunk, chain }: LBPTokenInfoProps) => {
      
    const metadata = useAppSelector((state) => state.lbps.metadata);
    const dispatch = useAppDispatch();
    const { tokenMap } = useContext(ChainContext)
    
    const [loading, setLoading] = useState(true);
    const [lbpData, setLBPData] = useState<any>({});

    let displayName, displayIcon, displaySymbol: string, poolName: string, status
    
    if(selectedToken.is1155){
        const symbol = 'fr' + selectedToken.symbol + '-' + selectedToken.id1155
        const tokenData : any = tokenMap[symbol]
        displayName = tokenData.name
        displayIcon = extract1155Data(symbol)?.item.icon
        displaySymbol = symbol
        poolName = tokenData.poolName
        status = tokenData.status
    } else {
        displayName = selectedToken.symbol
        displayIcon = selectedToken.icon
        displaySymbol = selectedToken.symbol
        poolName = selectedToken.poolName
        status = selectedToken.status
    }
  
    const fetchLBPInfo = async () => {
      if (metadata[displaySymbol]) return metadata[displaySymbol];
  
      return fetch(LBP_API(chain) + poolName)
        .then((response) => response.json())
        .then((data) => {
          dispatch(addMetadata({ name: displaySymbol, data: data }));
          return data;
        })
        .catch(() => {});
    };

    useEffect(() => {
      // Fetch balances from contract if not already in Redux state and update store
      setLoading(true);
      fetchLBPInfo().then((data) => {
        if (data) {
          setLBPData(data);
          setLoading(false);
        }
      });
    }, [selectedToken]);

    return (
        <Wrapper className={isShrunk ? 'shrunk' : ''}>
            <TokenDiv>
                <Image className={isShrunk ? 'shrunk' : ''} src={displayIcon} alt={`${selectedToken.name} logo`} />
                <TokenSummary className={isShrunk ? 'shrunk' : ''}>
                    <TokenName>{displayName}</TokenName>
                    <TokenAddress className={isShrunk ? 'shrunk' : ''}>LBP {reduceString(selectedToken.address, 6, 4)} <CopyButton text={selectedToken.address} className='copyShellToken' /></TokenAddress>
                </TokenSummary>
            </TokenDiv>
            <Stats className={isShrunk ? 'shrunk' : ''}>
                <Portions className={isShrunk ? 'shrunk' : ''}>
                    {loading ? ( <CompositionSkeleton length={2} /> ) : (
                      Object.keys(lbpData).length > 0 && (
                        <Composition list={Object.keys(lbpData.breakdown).map((childToken) => {
                            return { symbol: childToken, percentage: `${lbpData.breakdown[childToken]}%`, }; 
                        })}/>
                      )
                    )}
                </Portions>
                <Info className={isShrunk ? 'shrunk' : ''}>
                    <StatsRow className={isShrunk ? 'shrunk' : ''}>
                    {loading ? (
                      <> 
                        <StatsSkeleton length={4} boxWidth={isShrunk ? 100 : 122} noMargin={true} /> 
                      </>
                    ) : (
                      <>
                        <InfoItem className={isShrunk ? 'shrunk' : ''}>
                            <StatusBullet status={status} small={true} />
                        </InfoItem>
                        <InfoItem className={isShrunk ? 'shrunk' : ''}>
                            <Label>Mkt Cap:</Label> <Value>${formatDisplayShorthand(lbpData.marketCap)}</Value>
                        </InfoItem>
                        {status == 'Ongoing' ? 
                        <InfoItem className={isShrunk ? 'shrunk' : ''}>
                            <Label>Ends in:</Label> <Value>{lbpTime(Math.floor(Date.now() / 1000), lbpData.endTime)}</Value>
                        </InfoItem>
                        :
                        <InfoItem className={isShrunk ? 'shrunk' : ''}>
                            <Label>Starts in:</Label> <Value>{lbpTime(Math.floor(Date.now() / 1000), lbpData.startTime)}</Value>
                        </InfoItem>
                        }
                        <InfoItem className={isShrunk ? 'shrunk' : ''}>
                            <Label>Total Vol:</Label> <Value>{`$${formatDisplayShorthand(lbpData.totalVol)}`}</Value>
                        </InfoItem>
                      </>
                    )}
                    </StatsRow>
                    <StyledLink to={{ pathname: `/lbp/${displaySymbol}` }}>
                        <ButtonS className={isShrunk ? 'shrunk' : ''}>
                           {isShrunk ? 'Details' : 'View Details'}
                            <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <g clipPath="url(#clip0_3983_174)">
                                <path
                                d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
                                fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_3983_174">
                                <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                            </svg>
                        </ButtonS>
                    </StyledLink> 
                </Info>
            </Stats>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 30px;

  width: 100%;
  padding: 24px 28px 24px 40px;
  padding-top: 24px;
  border-top: 1px solid #1e2239;
  background-image: linear-gradient(180deg, #0f0f29, transparent);

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      flex-direction: column;
      justify-content: unset;
      row-gap: 20px;
      padding: 18px 12px;
    `};
`;

const TokenDiv = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 300px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      width: 50px;
      height: 50px;
    `};
`;

const TokenSummary = styled.div`
  margin-left: 20px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      margin-left: 15px;
    `};
`;

const TokenName = styled.span`
  font-size: 20px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: -0.03em;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TokenAddress = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #a9d1e2;

  .copyShellToken {
    color: #a9d1e2;
    margin-left: 4px;
  }

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 14px;
      line-height: 16px;
    `};
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 496px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      width: 100%;
      max-width: 100%;
      align-items: unset;
    `};
`;

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  align-items: center;
  row-gap: 6px; 
  column-gap: 12px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      column-gap: 24px;
    `};
`;

const LoadWrapper = styled.div`
  margin: auto;

  > img {
    width: 48px;
    margin-left: 10px;
  }

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      margin-bottom: 16px;
      > img {
        width: 36px;
        margin-left: 0px;
      }
    `};
`;

const Portions = styled.div`
  display: flex;
  width: 100%;
  max-width: 428px;
  margin: 12px 0;
  overflow: hidden;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      max-width: 100%;
      margin: 0 0 18px;
    `};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2px;
  justify-content: space-between;
  gap: 12px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      gap: 0;
    `};
`;

const InfoItem = styled.div`
  font-size: 14px;
  line-height: 16px;
  align-items: center;

  > img {
    padding-bottom: 2px;
    margin-left: 4px;
  }

   ${props =>
    props.className?.includes('shrunk') &&
    css`
      & + & {
        margin-left: unset;
      }
      ${Media.mobile}{
        font-size: 12px;
      }
    `};
`;

const Label = styled.span`
  color: #7d7d97;
`;

const Value = styled.span<{ value?: number; color?: string }>`
  font-weight: 500;
  color: ${({ color }) => color ?? "#FFFFFF"};
`;

const StyledLink = styled(Link)`
  width: fit-content;
  margin-left: auto;
`;

const ButtonS = styled(ButtonSecondary)`
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      width: 100%;
      grid-column: 1 / 2 span;
      padding-inline: 12px;
    `};
`;

import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { tokenColors } from "../../../../constants/tokenColors";
import { isNFTCollection, isShellToken } from "../../../../utils/tokens";
import { reduceString } from "../../../../utils/reduceString";
import { PointsSortableTable } from "./components/PointsSortableTable";
import { TotalPointsCell } from "./components/TableCells/TotalPointsCell";
import { PointsTokenPreviewCell } from "./components/TableCells/PointsTokenPreviewCell";
import { breakpoints, Media } from "../../../../styles";
import { useWidthBreakpoint } from "../../../../hooks";
import { PointsTale } from "./components/PointsTale";
import { useSigner, useProvider, useAccount } from "wagmi";
import { CollectionFilter, FilterCollectionsDropdown } from "./components/FilterCollectionsDropdown";
import { LoadingRows } from "@/components/Table/GenericTable";
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const HistoricalPointsTable = (props: any) => {
  const { tokens, pointsLoading } = props;
  const [selectedFilter, setSelectedFilter] = useState<CollectionFilter>({order: null, label: "All"});

  const { tokenMap, shellTokens, nftCollections } = useContext(ChainContext)

  const tokenNames = Object.keys(tokens).filter((token) => token !== "compoundedPoints");

  const { data: signer } = useSigner();
  const { isConnected } = useAccount();

  const provider = useProvider();

  const columns = useMemo(
    () => [
      {
        Header: "Token",
        accessor: "token",
        Filter: (
          <FilterCollectionsDropdown
            onChange={(filter) => setSelectedFilter(filter)}
            list={[
              {
                order: null,
                label: "All",
              },
              {
                order: "asc",
                label: "LP Tokens",
              },
              {
                order: "asc",
                label: "NFTs",
              },
            ]}
          />
        ),
        Cell: ({ row }: any) => <PointsTokenPreviewCell row={row} />,
      },
      {
        Header: "Total Points",
        accessor: "totalPoints",
        Cell: TotalPointsCell,
      },
    ],
    []
  );

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const sortTokens = (tokenData : any[]) => {

    if(tokenData.length == 0) return []

    const nftAddresses = new Set(nftCollections.map((collection) => collection.address))

    const wrappedCollections = nftCollections.filter((collection) => collection.wrapped)
    const fungibleTokens = Object.values(tokenMap).filter((token : any) => nftAddresses.has(token.address) && !isNFTCollection(token))

    const fungibleTokenIDs = new Set(fungibleTokens.map((token) => token.symbol))
    const lpTokens = shellTokens.filter((shellToken) => fungibleTokenIDs.has(shellToken.tokens[0]) || fungibleTokenIDs.has(shellToken.tokens[1]))

    const nftOrder = lpTokens.map((lpToken, index) => {
        if(wrappedCollections[index]){
            return [lpToken.name, fungibleTokens[index].symbol, wrappedCollections[index].symbol];
        } else {
            return [lpToken.name, fungibleTokens[index].symbol]
        }
    }).reverse().flat();

    const tokenIndexes : {[id: string] : number} = {}
    tokenData.forEach((token, index) => tokenIndexes[token.name] = index)

    const sortedTokens = nftOrder.map((tokenID) => tokenData[tokenIndexes[tokenID]]).filter(value => value !== undefined)

    tokenData.forEach((token) => !(new Set(nftOrder).has(token.name)) && sortedTokens.push(token))

    return sortedTokens
  }

  const allTokens = sortTokens(tokenNames.map((token) => {
    let tokenData = tokens[token]
    let tokenObject = tokenMap[token]

    return {
      name: token,
      subname: isShellToken(tokenObject) ? reduceString(tokenObject.oceanID, 6, 4) : tokenObject?.name,
      visible: false,
      color: tokenColors[token],
      totalPoints: tokenData.totalPoints,
      wallet: signer || provider, 
      type: isNFTCollection(tokenObject) ? 'NFT' : isShellToken(tokenObject) ? 'LP Token' : "Token"
    }
  }))
  

  const filteredTokens : any = {
    All: allTokens,
    'LP Tokens': allTokens.filter((token) => token.type == 'LP Token'),
    NFTs: allTokens.filter((token) => token.type == 'NFT'),
    // tokens: allTokens,
  };

  const data = useMemo(() => {
    return filteredTokens[selectedFilter.label] || [];
  }, [selectedFilter]);

  return (
    <>
      <PointsTableWrapper>
        {data.length > 0 && (
          isTablet ? (
            <TalesWrapper>
              <FilterPointsWrapper>
                <FilterCollectionsDropdown
                  onChange={(filter) => setSelectedFilter(filter)}
                  list={[
                    {
                      order: null,
                      label: "All",
                    },
                    {
                      order: "asc",
                      label: "LP Tokens",
                    },
                    {
                      order: "asc",
                      label: "NFTs",
                    },
                  ]}
                />
                <PointsText>Total Points</PointsText>
              </FilterPointsWrapper>
              {pointsLoading ? (<LoadingRows isLoading={pointsLoading} rows={8} />
                ) : (
                  data.map((token : any, index : number) => (
                    <PointsTale
                    token={token}
                    key={`${token?.name}_${token.subname}_${index}`}
                    openDisabled={true}
                  />
                  ))
              )}  
            </TalesWrapper>
          ) : (
            <PointsSortableTable
              columns={columns}
              data={data}
              pointsLoading={pointsLoading}
            />
          )
        )}
      </PointsTableWrapper>
    </>
  );
};

const PointsTableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 40px;

  ${Media.smallDesktop} {
    margin-top: 24px;
  }
`;

const TalesWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

const FilterPointsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const PointsText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  margin-right: 44px;
`;

import React, { useContext } from "react";
import styled from "styled-components";
import { TokenLogo } from "@/components/TokenLogo";
import { tokenLogos } from "@/constants/tokenLogos";
import { maxTokenDecimals } from "@/utils/tokens";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { allChains, defaultChain } from "@/placeholders/chains";
import arbitrumChain from '@/assets/coins/arbitrum.svg';
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const TokenCell = ({ value }: any) => {
  const { tokenMap } = useContext(ChainContext)
  const [tokens, amounts, chain] = [value.tokens, value.amounts, value.chain];
  return (
    <Column>
      {tokens.map((token: any, index: number) => {
        const LogoIcon = tokenLogos[token] ? tokenLogos[token] : Logo;
        return (
          <TokenGroup key={token}>
            {chain ? <BridgeTokenLogo logo={(tokenMap[token] ?? tokenMap['ETH']).icon} chainLogo={(allChains[chain] ?? defaultChain).icon!}/> : <LogoIcon />}
            <TokenDetails>
              <TokenAmount>
                {formatDisplayShorthand(
                  amounts[index],
                  maxTokenDecimals(token),
                  1e4
                )}
              </TokenAmount>
              <TokenName>{token}</TokenName>
            </TokenDetails>
          </TokenGroup>
        );
      })}
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

const TokenGroup = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  > img {
    height: 32px;
    width: 32px;
    margin: 0;
  }

  > svg {
    margin: 8px;
    width: 70px;
    position: absolute;
    left: -30px;
    top: 8px;
  }
`;

const TokenDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
`;

const TokenName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  //width: 48px;
`;

const TokenAmount = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  //width: 48px;
`;

const Logo = styled(TokenLogo)`
  width: 30px;
`;

const BridgeTokenLogo = styled.div<{ logo: string, chainLogo: string }>`
height: 32px;
width: 32px;
position: relative;

&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;;
}

&::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 14px;
    height: 14px;
    background: url(${({ chainLogo }) => chainLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    border: 2px solid #151530;
    border-radius: 50%;
}
`;

import React, { useEffect, useState } from "react";
import * as S from "./ConfirmationModals.styled";
import mockNFT from "@/assets/icons/stream.svg";
import shellIcon from '@/assets/coins/SHELL.svg'
import { CrabInputPanel } from "./CrabInputPanel";
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";
import { parseUnits } from "ethers/lib/utils.js";
import { useRewardsContext } from "../../RewardsContext";

export const ClaimAirDropModalBody = () => {
    const { vestingEndTime, handleClaimAirdrop, approveCrab, crabApprovedAmount, crabApprovalState, setCrabApprovalState } = useRewardsContext()

    const [burnAmount, setBurnAmount] = useState(0)
    const [balanceError, setBalanceError] = useState(false)
    
    const handleInput = (value: string, balance: number) => {
        if(value){
            const numValue = parseFloat(value.replaceAll(',', ''))
            setBurnAmount(numValue)
            setBalanceError(numValue > balance)
        } else {
            setBurnAmount(0)
            setBalanceError(false)
        }
    }

    useEffect(() => {
        if(parseUnits(crabApprovedAmount).lt(parseUnits(burnAmount.toString()))){
            setCrabApprovalState('Needs')
        } else {
            setCrabApprovalState('')
        }
    }, [burnAmount, crabApprovedAmount])

    return (
      <S.ClaimAirDropBodyContainer>
        <S.TitleAndDescriptionContainer>
          <S.ModalTitle>Claim Shellstream</S.ModalTitle>
          <S.Description>
            Burn CRAB to receive a NFT representing your vesting stream of
            SHELL. Your SHELL tokens will be unlocked linearly every block and
            you will be able to claim them in your vesting panel.
            <a href={"https://wiki.shellprotocol.io/shell-dao/shell-token/sablier-vesting-stream-shell"} target="_blank"> Learn more</a>
          </S.Description>
        </S.TitleAndDescriptionContainer>
        <CrabInputPanel
          value={burnAmount > 0 ? burnAmount.toString() : ""}
          onChange={handleInput}
          label="CRAB"
          reloadBalances={false}
          error={balanceError}
          isWidget={false}
        />
        <S.ClaimAirDropDetailsBox>
          <S.ClaimAirDropDetailsBoxInfo>
            <S.ClaimAirDropDetailsNFT src={mockNFT} alt="nft image" />
            <S.ClaimAirDropDetailsInfoWrapper>
              <S.ClaimAirDropDetailsInfo>Shellstream</S.ClaimAirDropDetailsInfo>
              <S.ClaimAirDropDetailsDesc>
                NFT representing a vesting stream of your SHELL airdrop
              </S.ClaimAirDropDetailsDesc>
            </S.ClaimAirDropDetailsInfoWrapper>
          </S.ClaimAirDropDetailsBoxInfo>
          <S.HrDiv />
          <S.ClaimAirDropDetails>
            <S.DetailsOneRow>
              <S.DetailsLabel>Your stream</S.DetailsLabel>
              <S.SideWrapper>
                <S.SmallCoinIcon src={shellIcon} alt="shell coin" />
                <S.DetailsValue>
                  {formatDisplay(burnAmount.toString(), 2) ?? 0} SHELL
                </S.DetailsValue>
              </S.SideWrapper>
            </S.DetailsOneRow>
            <S.DetailsOneRow>
              <S.DetailsLabel>End Time</S.DetailsLabel>
              <S.DetailsValue>
                {timestampToDate(vestingEndTime.toString())}
              </S.DetailsValue>
            </S.DetailsOneRow>
            <S.DetailsOneRow>
              <S.DetailsLabel>Vesting type</S.DetailsLabel>
              <S.DetailsValue>Linear</S.DetailsValue>
            </S.DetailsOneRow>
          </S.ClaimAirDropDetails>
        </S.ClaimAirDropDetailsBox>
        <S.ButtonsContainer>
            {crabApprovalState.length > 0 && !balanceError &&
                <S.CustomPrimaryBtn
                    disabled={crabApprovalState == 'Pending'}
                    onClick={approveCrab}
                >
                    Approve
                </S.CustomPrimaryBtn>
            }
            <S.CustomPrimaryBtn
              disabled={burnAmount == 0 || balanceError || crabApprovalState.length > 0}
              onClick={() => handleClaimAirdrop(burnAmount)}
            >
              {balanceError ? "Insufficient CRAB" : "Claim"}
            </S.CustomPrimaryBtn>
        </S.ButtonsContainer>
      </S.ClaimAirDropBodyContainer>
    );
};
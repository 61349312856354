import React from "react";
import { useRewardsContext } from "../../RewardsContext";
import * as S from "./ConfirmationModals.styled";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import arrowDown from "@/assets/icons/arrow-down-double-fill.svg";
import shellIcon from '@/assets/coins/SHELL.svg'
import shellWhiteCoin from "@/assets/icons/shell-white-coin.svg";
import infoIcon from "@/assets/icons/info-icon.svg";
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";

type StakeConfirmationModalBodyProps = {
    isClaimVariant?: boolean;
}

const InputCustomLabelShellCoin = () => (
    <S.CustomInputLabelContainer style={{ marginRight: "45px" }}>
        <S.CoinIcon src={shellIcon} alt="shell coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>SHELL</S.InputTokenName>
                <S.InputTokenSymbol>Shell</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

const InputCustomLabelVotinPower = () => (
    <S.CustomInputLabelContainer>
        <S.CoinIcon src={shellWhiteCoin} alt="shell white coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>veSHELL</S.InputTokenName>
                <S.InputTokenSymbol>Voting Power</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

export const StakeConfirmationModalBody = ({ isClaimVariant }: StakeConfirmationModalBodyProps) => {
    const {
        amountToStake,
        isStakeConfirmed,
        handleRedirectToStaking,
        savedLockPeriod,
        handleInitIncreaseLockPeriodAfterStake,
        projectedVeShell,
        projectedEndTime,
        stakeTxSubmitted
    } = useRewardsContext();

    const unlockTime = timestampToDate(projectedEndTime.toString())
                
    return (
        <S.ConfirmationBodyContainer hasMask={isStakeConfirmed}>
            {isStakeConfirmed ? (
                <>
                    <S.SuccessIcon src={successIcon} alt="success icon" />
                    <S.SuccessTextWrapper>
                        <S.SuccessTitle>Stake successful!</S.SuccessTitle>
                        <S.SuccessDescription>
                            Your
                            <span> {formatDisplay(amountToStake.toString(), 2)} SHELL </span>
                            has been {isClaimVariant ? 'claimed and ' : ''} staked until {unlockTime}
                        </S.SuccessDescription>
                    </S.SuccessTextWrapper>
                    <S.InputsWrapper>
                        <Input label="From" disabled value={formatDisplay(amountToStake.toString())} additionalLabel={<InputCustomLabelShellCoin />} />
                        <img src={arrowDown} alt="arrow down icon" />
                        <Input label="To" disabled value={formatDisplay(projectedVeShell.toString())} additionalLabel={<InputCustomLabelVotinPower />} awardedColor />
                    </S.InputsWrapper>
                    <S.ButtonsWrapper>
                        {savedLockPeriod?.label !== "~2Y" ? (
                            <>
                                <S.CustomPrimaryBtn onClick={handleInitIncreaseLockPeriodAfterStake}>Increase lock period</S.CustomPrimaryBtn>
                                <S.CustomSecondaryBtn onClick={handleRedirectToStaking}>View my staking</S.CustomSecondaryBtn>
                            </>
                        ) : <S.CustomPrimaryBtn onClick={handleRedirectToStaking}>View my staking</S.CustomPrimaryBtn>
                        }
                    </S.ButtonsWrapper>
                </>
            ) : (
                <>
                    <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                    <S.LoaderLabelContainer>
                        <S.LoaderLabel>
                            Staking your SHELL...
                        </S.LoaderLabel>
                        <S.LoaderSublabel>
                           You're staking {formatDisplay(amountToStake.toString(), 2)} SHELL until {unlockTime}
                        </S.LoaderSublabel>
                    </S.LoaderLabelContainer>
                    <S.InputsWrapper>
                        <Input label="From" disabled value={formatDisplay(amountToStake.toString())} additionalLabel={<InputCustomLabelShellCoin />} />
                        <img src={arrowDown} alt="arrow down icon" />
                        <Input label="To" disabled value={formatDisplay(projectedVeShell.toString())} additionalLabel={<InputCustomLabelVotinPower />} awardedColor />
                    </S.InputsWrapper>
                    {!stakeTxSubmitted && 
                    <S.InfoLabelContainer>
                        <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                        <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                    </S.InfoLabelContainer>
                    }
                </>
            )}
        </S.ConfirmationBodyContainer>
    )
}
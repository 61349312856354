import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

interface MiniTabsProps {
    tabs: string[];
    onTabClick: (tab: string) => void;
}

export const MiniTabs = ({tabs, onTabClick} :MiniTabsProps) => {
    const [activeTab, setActiveTab] = useState<string>(tabs[0]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        onTabClick(tab);
    };

    const { guideAction } = useLocation().state || {};

    useEffect(() => {
        const activeTab = guideAction === 'crosschain' ? tabs[1] : tabs[0];
        handleTabClick(activeTab);
    }, [guideAction]);

    return (
        <TabsWrapper>
            <BackgroundTab left={tabs.indexOf(activeTab) * 106} />
            {tabs.map((tab) => (
                <Tab key={tab} onClick={() => handleTabClick(tab)} isActive={activeTab === tab}>{tab}</Tab>
            ))}
        </TabsWrapper>
    );
};

const TabsWrapper = styled.ul`
display: flex;
padding: 4px;
border-radius: 12px;
border: 1px solid #1E2239;
justify-content: flex-start;
align-items: center;
background: var(--dark-1, #0A0E27);
width: min-content;
position: relative;
`;

const Tab = styled.li<{isActive: boolean}>`
padding: 11px 0;
min-width: 106px;
max-width: 106px;
border-radius: 9px;
border: none;
background: none;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
z-index: 2;
color: ${({isActive}) => isActive ? '#ffffff' : '#464659'};
transition: color 0.2s;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const BackgroundTab = styled.div<{left: number}>`
min-width: 106px;
max-width: 106px;
border-radius: 9px;
border: 1px solid #1E2239;
background: var(--dark-2, #171B33);
position: absolute;
top: 50%;
transform: translateY(-50%);
height: 40px;
transition: left 0.2s;
left: calc(${({left}) => left}px + 4px);
`;
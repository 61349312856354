import React, { useContext } from "react";
import styled from "styled-components";
import { Media } from "@/styles";
import { Link, useNavigate } from "react-router-dom";
import { isNFTCollection } from "@/utils/tokens";
import { CopyButton } from "@/components/Buttons/CopyButton";
import {CopyLinkButton} from "@/components/Buttons/CopyLinkButton";
import { reduceString } from "@/utils/reduceString";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TableSubRowProps {
  row: any;
  interaction: any;
  isLoading: boolean;
}

export const TransactionDetails = ({ row, interaction, isLoading }: TableSubRowProps) => {

  const { tokenMap } = useContext(ChainContext)
  
  const { original } = row;

  const status = interaction.srcChain && interaction.destChain && interaction.preArrowPathLength == 2 ? 'Failed' : 'Success'

  const buildTokensForRepeat = () => {
    const inputTokens = original.input.tokens.map((tokenID : string, pathIndex: number) => {
        const token = tokenMap[tokenID]
        if(isNFTCollection(token) && token.is1155){
            for(let step of interaction.preArrowPaths[pathIndex]){
                if(tokenID == step.token){
                    return { ...token, symbol: `fr${tokenID}-${step.nftID[0].id}` };
                }
            }
        } else {
            return token
        }
    })

    const outputTokens = original.output.tokens.map((tokenID : string, pathIndex: number) => {
        const token = tokenMap[tokenID]
        if(isNFTCollection(token) && token.is1155){
            for(let step of interaction.preArrowPaths[pathIndex].concat(interaction.postArrowPaths[pathIndex] ?? [])){
                if(tokenID == step.token){
                    return { ...token, symbol: `fr${tokenID}-${step.nftID[0].id}` };
                }
            }
        } else {
            return token
        }
    })

    return ({initInput: inputTokens, initOutput: outputTokens})
  }

  const elapsedTime = (timestamp: number) => {
    const currentTime = new Date().getTime();
    const elapsedMilliseconds = currentTime - (timestamp * 1000);

    function formatTimeUnits(value : number, unit : string) {
        if (value === 1) {
          return `${value} ${unit}`;
        } else {
          return `${value} ${unit}s`;
        }
    }
      
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);
    
    const formattedElapsedDays = formatTimeUnits(elapsedDays, 'day');
    const formattedElapsedHours = formatTimeUnits(elapsedHours % 24, 'hour');
    const formattedElapsedMinutes = formatTimeUnits(elapsedMinutes % 60, 'min');
    const formattedElapsedSeconds = formatTimeUnits(elapsedSeconds % 60, 'sec');

    if(elapsedMinutes == 0 && elapsedHours == 0 && elapsedDays == 0){
        return formattedElapsedSeconds
    } else if(elapsedHours == 0 && elapsedDays == 0){
        return formattedElapsedMinutes
    } else if(elapsedDays == 0){
        return `${formattedElapsedHours}, ${elapsedMinutes % 60 > 0 ? formattedElapsedMinutes : formattedElapsedSeconds}`
    } else {
        return `${formattedElapsedDays}, ${elapsedHours % 24 > 0 ? formattedElapsedHours : formattedElapsedMinutes}`
    }
  }

  const navigate = useNavigate();
  const handleRepeatTransactionClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    navigate('/trade', { state: buildTokensForRepeat() });
  }

  const formatTimestamp = (timestamp : number) => {
    const formattedDate = new Date(timestamp * 1000).toLocaleString('en-US', 
        {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZoneName: 'short'
        }
    );
    return formattedDate.replace(',', '') // Removing the comma after the day
  }
  
  const baseUrl = new URL(window.location.href).origin;

  return (
    <TransactionDetailsStyled>
      <TransactionDetailsHeader>
        Transaction details
        <TransactionHashContainer>
          {reduceString(original.transactionHash, 6, 4)}
          <CopyButton
            text={original.transactionHash}
            className="transaction-hash-copy-button"
          />
        </TransactionHashContainer>
      </TransactionDetailsHeader>
      <TransactionDetailsGrid>
        {/* Status */}
        <TransactionDetailsColumn>
          {isLoading ? 
            <> 
              <SkeletonBox isLoading width="25%" height="12px" borderRadius="4px"/> 
              <SkeletonBox isLoading width="50%" height="21px" borderRadius="4px"/>
            </> : 
            <> Status <Tab type={status}>{status}</Tab> </>
          }
        </TransactionDetailsColumn>
        {/* Block */}
        <TransactionDetailsColumn>
          {isLoading ? 
            <> 
              <SkeletonBox isLoading width="50%" height="12px" borderRadius="4px"/> 
              <SkeletonBox isLoading width="100%" height="21px" borderRadius="4px"/>
            </> : 
            <> Block <span>{interaction.block}</span> </>
          }
        </TransactionDetailsColumn>
        {/* Timestamp */}
        <TransactionDetailsColumn>
          {isLoading ? 
            <> 
              <SkeletonBox isLoading width="25%" height="12px" borderRadius="4px"/> 
              <SkeletonBox isLoading width="55%" height="21px" borderRadius="4px"/>
              <SkeletonBox isLoading width="90%" height="21px" borderRadius="4px"/>
            </> : 
            <> Timestamp
              <span>
                {elapsedTime(original.timestamp)} ago <br /> <br />
                ({formatTimestamp(original.timestamp)})
              </span> 
            </>
          }
        </TransactionDetailsColumn>
        {/* Fee */}
        <TransactionDetailsColumn>
          {isLoading ? 
            <> 
              <SkeletonBox isLoading width="50%" height="12px" borderRadius="4px"/> 
              <SkeletonBox isLoading width="100%" height="21px" borderRadius="4px"/>
            </> : 
            <> Fee <span>${typeof interaction.fee === 'number' || (typeof interaction.fee === 'string' && !isNaN(Number(interaction.fee))) ? Number(interaction.fee).toFixed(2) : '0.00'}</span> </>
          }
        </TransactionDetailsColumn>
        <TransactionDetailsRow>
          <CopyLinkButton text={`${baseUrl}/tx/${original.transactionHash}`} />
          <TransactionDetailsButton 
            // to='/trade' 
            // state={!isLoading ? buildTokensForRepeat() : undefined} 
            onClick={handleRepeatTransactionClick}
            disabled={isLoading || status == 'Failed'}
          >
            Repeat Transaction
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3983_174)">
                <path
                  d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3983_174">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </TransactionDetailsButton>
        </TransactionDetailsRow>
      </TransactionDetailsGrid>
    </TransactionDetailsStyled>
  );
};

const TransactionDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: start;
  //width: 50%;
  padding-inline: 32px;
  border-right: 1px solid #1e2239;
  min-width: 410px;

  ${Media.tablet} {
    width: 100%;
    padding: 20px 16px;
    border: 1px solid rgb(30, 34, 57);
    border-width: 1px 0;
    min-width: 0px;
  }
`;

const TransactionDetailsHeader = styled.div`
  color: #fff;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: -0.6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.mobile}{
    font-size: 18px;
  }
`;

const TransactionDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 232px 1fr;
  grid-template-rows: repeat(2, auto);
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  row-gap: 20px;
  column-gap: 20px;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  ${Media.tablet} {
    grid-template-columns: 1fr;
    span {
        font-size: 12px;
    }
  }
`;

const TransactionDetailsRow = styled.div`
  display: flex;
  gap: 8px;
  grid-column: 1 / 2 span;
`;

const TransactionDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Tab = styled.div<{ type: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: ${({ type }) =>
    type === "Success" ? "#1F293D" : "rgba(238, 60, 60, 0.15)"};
  color: ${({ type }) => (type === "Success" ? "#7ADEB9" : "#ee3c3c")};
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const ArbitrumIcon = styled.a`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #37dcf2;
  border-radius: 12px;
`;

const TransactionDetailsButton = styled.button`
  color: #ffffff;
  border: 1px solid #37dcf2;
  
  width: 100%;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.tablet} {
    padding-inline: 16px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`;

const TransactionHashContainer = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #a9d1e2;
  font-weight: 400;

  ${Media.mobile}{
    font-size: 14px;
  }
`;

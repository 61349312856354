import * as constants from "./constants";
import * as types from "./types";
import { Edge, LiquidityGraph, getTokenID } from "../../utils/LiquidityGraph";
import { ExternalDefiToken, ShellToken, isExternalDefiToken, isLPToken, isMonoAdapter, isShellToken } from "@/utils/tokens";
import { Chain } from "@/placeholders/chains";

const getTokenInfoMap = async (tokenSymbols: string[], sorTokenMap: Record<string, types.TokenInfo>): Promise<types.TokenInfoMap> => {
  const tokenInfo: types.TokenInfoMap = {};

  tokenSymbols.forEach((symbol: string) => {
    for (const tokenAddress in sorTokenMap) {
      if (sorTokenMap.hasOwnProperty(tokenAddress) && sorTokenMap[tokenAddress].symbol === symbol) {
        const token = sorTokenMap[tokenAddress];
        tokenInfo[symbol] = {
          address: tokenAddress,
          decimals: token.decimals,
          protocolId: token.asset_id ?? '',
        };
        break;
      }
    }
  });
  return tokenInfo;
};

const getPathQuote = async (pathRequest: types.PathRequestV2): Promise<types.PathSteps> => {
  const response = await fetch(constants.SOR_QUOTE_V2_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(pathRequest),
  });

  if (!response.ok) {
    const responseText = await response.text();
    if (responseText.includes("No viable path found")) {
      throw new Error(`No viable path found`);
    } else {
      throw new Error(`Error`);
    }
  }
 
  const quoteResponse: types.QuoteResponse = await response.json();
  let previousLink: types.PathLink | null = null;
  const filteredPath: types.PathLink[] = 
    quoteResponse.pathViz.links.filter((link): link is types.PathLink => {
      const isDuplicate = previousLink && link.sourceToken.symbol === previousLink.sourceToken.symbol && link.targetToken.symbol === previousLink.targetToken.symbol;
      previousLink = link;
      return !isDuplicate;
    }
  );

  return {
    path: filteredPath.map((link) => ({
      fromToken: link.sourceToken,
      toToken: link.targetToken,
      amountIn: link.in_value.toString(),
      amountOut: link.out_value.toString(),
      protocol: link.label,
      action: "Swap",
    })),
    timestamp: Date.now(),
  };
};

const getBestPath = async (startToken: string, endToken: string, amountFrom: string, slippage: number, sorTokenMap: Record<string, types.TokenInfo>, externalDefiTokens: ExternalDefiToken[], chainID: number): Promise<types.PathSteps> => {
  const tokenInfoMap = await getTokenInfoMap([startToken, endToken], sorTokenMap);
  const odosSupportsTokenPair = tokenInfoMap[startToken] && tokenInfoMap[endToken];

  if (!odosSupportsTokenPair) {
    throw new Error(`Invalid token pair`);
  }

  const fromTokenInfo = tokenInfoMap[startToken];
  const toTokenInfo = tokenInfoMap[endToken];

  const rawDecimalAmount = parseFloat(amountFrom.replace(/,/g, ""));

  if (isNaN(rawDecimalAmount)) {
    throw new Error(`Invalid amount for token ${fromTokenInfo.address}`);
  }

  const scaledAmount = rawDecimalAmount * Math.pow(10, fromTokenInfo.decimals);
  const amountInSmallestUnit = scaledAmount.toLocaleString("fullwide", {
    useGrouping: false,
    maximumFractionDigits: 0,
  });

  const inputToken = {
    tokenAddress: fromTokenInfo.address,
    amount: amountInSmallestUnit,
  };

  const outputToken: types.TokenInputOutput = {
    tokenAddress: toTokenInfo.address,
    proportion: 1,
  };

  const validProtocols = new Set(
    externalDefiTokens
      .filter(
        (defiToken) =>
          (isLPToken(defiToken) || defiToken.symbol == 'ETH+WETH') && 
          (isMonoAdapter(defiToken) || 
          (defiToken.tokens.includes(startToken) ||
          defiToken.tokens.includes(endToken))
          )
      )
      .map((lpToken) => lpToken.tokenType ?? "WETH")
  );

  const res: types.PathSteps = await getPathQuote({
    chainId: chainID,
    sourceWhitelist: Array.from(validProtocols).map((protocol) => constants.SOURCES[chainID.toString()][protocol]).flat(),
    pathViz: true,
    slippageLimitPercent: slippage,
    inputTokens: [inputToken],
    outputTokens: [outputToken],
  });

  return res;
};

const getValidPaths = async (path: Edge[], sorTokenMap: Record<string, types.TokenInfo>): Promise<types.ValidSwapPath[]> => {
  const validPaths: Array<types.ValidSwapPath> = [];
  const tokenInfoMap = await getTokenInfoMap(path.map((edge) => edge.token.symbol.replace(/^sh/, "")), sorTokenMap);
  let currentSwapPath: Edge[] = [];
  for (let i = 0; i < path.length; i++) {
    const action = path[i].action;
    const tokenSymbol = path[i].token.symbol.replace(/^sh/, "");

    if (action === "Swap" && tokenInfoMap[tokenSymbol]) {
      if (currentSwapPath.length === 0) {
        const previousTokenSymbol = path[i - 1]?.token?.symbol.replace(/^sh/, "");
        if (tokenInfoMap[previousTokenSymbol]) {
          currentSwapPath.push(path[i - 1]);
        }
      }
      currentSwapPath.push(path[i]);
    } else if (currentSwapPath.length > 0) {
      addSwapPathToValidPaths(currentSwapPath, validPaths, tokenInfoMap);
      currentSwapPath = [];
    } else if(action && action !== 'Wrap'){
        break
    }
  }

  if (currentSwapPath.length > 0) {
    addSwapPathToValidPaths(currentSwapPath, validPaths, tokenInfoMap);
  }

  return validPaths;
};

function addSwapPathToValidPaths(swapPath: Edge[], validPaths: Array<types.ValidSwapPath>, tokenInfoMap: types.TokenInfoMap) {
  if (swapPath.length > 1) {
    const startToken = swapPath[0].token.symbol.replace(/^sh/, "");
    const endToken = swapPath[swapPath.length - 1].token.symbol.replace( /^sh/, "" );

    const isValid = tokenInfoMap[startToken] !== undefined && tokenInfoMap[endToken] !== undefined;
    if (isValid) {
      validPaths.push({
        tokenFrom: startToken,
        tokenTo: endToken,
        isValid,
        path: swapPath,
      });
    }
  }
}

const getBestPaths = async (validPaths: Array<types.ValidSwapPath>, amountFrom: string, slippage: number, tokenMap: any, sorTokenMap: Record<string, types.TokenInfo>, chainID: number, liquidityGraph: LiquidityGraph): Promise<{ start: string; path: Array<Edge>; }[] | undefined> => {
  const bestPaths: Array<{start: string, path: Array<Edge>}> = [];

  const externalDefiTokens: ExternalDefiToken[] = Object.values(tokenMap).filter((token) => isExternalDefiToken(token) && !token.wrapped) as ExternalDefiToken[]
  const shellTokens: ShellToken[] = Object.values(tokenMap).filter((token) => isShellToken(token) && !isExternalDefiToken(token)) as ShellToken[]

  for (const swapPath of validPaths) {
    if (swapPath.isValid) {
      try {
        const bestPath = await getBestPath(
          swapPath.tokenFrom,
          swapPath.tokenTo,
          amountFrom,
          slippage,
          sorTokenMap,
          externalDefiTokens,
          chainID
        );


        if(bestPath.path.filter((step) => step.fromToken.symbol == swapPath.tokenFrom).length > 1){
            return undefined
        } 

        const unfilteredPath: any[] = bestPath.path.map(
          (step: types.SwapStep, index) => {
            if (step.protocol.includes("Deposit")) return null;

            const pool = determinePool(step, tokenMap, externalDefiTokens, shellTokens, liquidityGraph);
            if (pool === null || pool === undefined) return null;
            return {
              token: tokenMap["sh" + step.toToken.symbol],
              pool: pool,
              action: "Swap",
            };
          }
        );

        const firstMissing = unfilteredPath.findIndex((step) => step == null)
        const stepAfterFirstMissing = unfilteredPath.findIndex((step, index) => firstMissing != -1 && index > firstMissing && step !== null)
        
        const reformattedPath: Array<Edge> = unfilteredPath.slice(0, firstMissing == -1 ? undefined : firstMissing);

        if (reformattedPath.length === 0 || stepAfterFirstMissing != -1) return undefined;

        bestPaths.push({start: swapPath.tokenFrom, path: reformattedPath});
      } catch (error) {
        console.error("Error getting best path", error);
        return undefined;
      }
    }
  }

  return bestPaths.length > 0 ? bestPaths : undefined;
};

function determinePool(step: types.SwapStep, tokenMap: {[id: string]: any}, externalDefiTokens: ExternalDefiToken[], shellTokens: ShellToken[], liquidityGraph: LiquidityGraph): string | null {
  if(step.protocol == 'Wrapped Ether'){
    return 'ETH+WETH'
  } else if(step.protocol == 'Uniswap V3' || step.protocol.includes('Aerodrome') || step.protocol.includes('Velodrome')){
    const prefix = step.protocol == 'Uniswap V3' ? 'UNI' : step.protocol.includes('Aerodrome') ? 'AERO' : 'VELO'
    if(
        tokenMap[step.fromToken.symbol] == null || 
        tokenMap[step.toToken.symbol] == null ||
        liquidityGraph.graph['sh' + step.fromToken.symbol].findIndex((neighbor) => getTokenID(neighbor.token) == 'sh' + step.toToken.symbol && neighbor.pool.includes(prefix)) == -1
    ) return null
    return `${prefix}-${step.fromToken.symbol}-${step.toToken.symbol}`
  } else if(step.protocol == 'Shell V3'){
    return (
        shellTokens.find(
          (token) =>
            token.tokens.includes(step.fromToken.symbol) &&
            token.tokens.includes(step.toToken.symbol)
        )?.name ?? null
      );
  } else {
    return (
      externalDefiTokens.find(
        (token) =>
          step.protocol.includes(token.tokenType!) &&
          token.tokens.includes(step.fromToken.symbol) &&
          token.tokens.includes(step.toToken.symbol)
      )?.symbol ?? null
    );
  }
}


const getModifiedPath = async (originalPath: Edge[], amountFrom: string, slippage: number, tokenMap: any, sorTokenMap: Record<string, types.TokenInfo>, chain: Chain, liquidityGraph: LiquidityGraph): Promise<Edge[]> => {
  const validPaths = await getValidPaths(originalPath, sorTokenMap);
  if (validPaths.length === 0) return originalPath;

  const bestPaths = await getBestPaths(validPaths.slice(0, 1), amountFrom, slippage, tokenMap, sorTokenMap, chain.chainId, liquidityGraph);
  if (!bestPaths || bestPaths.length === 0) return originalPath;
  let newPath: Edge[] = originalPath;

  for (const bestPath of bestPaths) {
    const startIndex = originalPath.findIndex((step) => step.token.symbol == 'sh' + bestPath.start) + 1;
    const endIndex = originalPath.findIndex((step) => step.token.symbol == bestPath.path[bestPath.path.length - 1].token.symbol);
    newPath = [...newPath.slice(0, startIndex), ...bestPath.path, ...newPath.slice(endIndex + 1)];
  }

  if(newPath.filter((step) => step.action == 'Wrap').length > 1) return originalPath

  return newPath;
};

export {
  getTokenInfoMap,
  getPathQuote,
  getBestPath,
  getModifiedPath,
  types,
  constants,
};

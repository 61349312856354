import React from "react";

export const MergeActionIcon = () => {
  return (
    <svg
      width="186"
      height="26"
      viewBox="0 0 186 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3"
        cy="3"
        r="2.5"
        transform="rotate(-90 3 3)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <circle
        cx="93"
        cy="23"
        r="2.5"
        transform="rotate(-90 93 23)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <circle
        cx="183"
        cy="3"
        r="2.5"
        transform="rotate(-90 183 3)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <path
        d="M183 5.5V5.5C183 9.08985 180.09 12 176.5 12H136.5H101C96.5817 12 93 15.5817 93 20V20"
        stroke="#37DCF2"
        strokeDasharray="0"
      />
      <path
        d="M3 5.5V5.5C3 9.08985 5.91015 12 9.5 12H49.5H85C89.4183 12 93 15.5817 93 20V20"
        stroke="#37DCF2"
        strokeDasharray="0"
      />
    </svg>
  );
};

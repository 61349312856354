import { allChains } from "@/placeholders/chains";
import { createSlice } from "@reduxjs/toolkit";
interface State {
  pools: Record<string, Record<string, any>>;
  charts: Record<string, Record<string, any>>;
  all: { [chain: string]: any[] };
  apys: { [chain: string]: any };
  tvls: { [chain: string]: any };
}

const initialState: State = { pools: {}, charts: {}, all: {}, apys: {}, tvls: {} };

Object.keys(allChains).forEach((chain) => {
  initialState.pools[chain] = {};
  initialState.charts[chain] = {};
  initialState.all[chain] = [];
  initialState.apys[chain] = {};
  initialState.tvls[chain] = {};
});

const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    addPool: (state, action) => {
      state.pools[action.payload.chain][action.payload.name] = action.payload.data;
    },
    addPoolChart: (state, action) => {
      state.charts[action.payload.chain][action.payload.name] = action.payload.data;
    },
    updateAllPools: (state, action) => {
      state.all[action.payload.chain] = action.payload.data;
    },
    updateApys: (state, action) => {
      state.apys[action.payload.chain] = action.payload.data;
    },
    updateTvls: (state, action) => {
      state.tvls[action.payload.chain] = action.payload.data;
    },
    clearPools: () => {
      return initialState;
    },
  },
});

export const { addPool, addPoolChart, updateAllPools, updateApys, updateTvls, clearPools } = poolsSlice.actions;

export default poolsSlice.reducer;

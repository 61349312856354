import React from 'react'
import styled from 'styled-components';
import { SwapIcon } from "../../components/Icons/SwapIcon";
import { ErrorIcon } from "../../components/Icons/ErrorIcon";
import { tokenLogos } from "../../constants/tokenLogos";
import { Token } from "../../utils/tokens";
import { Media } from '../../styles';

interface TradeButtonProps {
  disabled: boolean,
  walletConnected: boolean,
  chainConnected: boolean,
  onClick: () => void
  children: React.ReactNode
}

export const TradeButton = ({ disabled, walletConnected, chainConnected, onClick, children }: TradeButtonProps) => {

  return (
    <>
      <ExecuteButton data-testid={"execute-btn"} disabled={disabled || !chainConnected || !walletConnected} onClick={onClick}>
        <SwapIcon />
        {children}
      </ExecuteButton>
    </>
  )
}

interface ApproveButtonProps extends TradeButtonProps { tokenToApprove: Token }

export const ApproveButton = ({ disabled, walletConnected, chainConnected, onClick, children, tokenToApprove }: ApproveButtonProps) => {

//   const TokenIcon =
//     styled(tokenLogos[tokenToApprove.symbol])`
//             width: 28px;
//             height: 28px;
//             margin-right: 8px;
//             border-radius: 4px;
//         `;

    return (
        <>
            <ExecuteButton data-testid={"approve-token-btn"} disabled={disabled || !chainConnected || !walletConnected} onClick={onClick}>
                {/* <TokenIcon/> */}
                <img src={tokenToApprove.icon} style={{width: '28px', height: '28px', marginRight: '8px', borderRadius: '8px'}}/>
                {children}
            </ExecuteButton>
        </>
    )
}

interface AlertProps { children: React.ReactNode }

export const ErrorAlert = ({ children }: AlertProps) => {
  return (
    <>
      <Error>
        <ErrorIcon />
        {children}
      </Error>
    </>
  )
}

export const WarningAlert = ({children} : AlertProps) => {
    return (
        <>
            <Warning>
                <ErrorIcon/>
                {children}
            </Warning>
        </>
      )
} 

export const ExecuteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    margin-right: 8px;
    height: 28px;
    width: 28px;
  }

  &:not(:disabled) svg path {
    fill: #0a0e27;
  }

  &:disabled {
    background: #0a0e27;
    color: #464659;
  }

  ${Media.tablet} {
    height: 66px;
  }
`;

const Error = styled(ExecuteButton)`
  background: linear-gradient(90.44deg, #fb0740 0.87%, #fb0707 100%);
  pointer-events: none;
`;

const Warning = styled(ExecuteButton)`
  background: linear-gradient(90.44deg, #FFA500 0.87%, #DAA520 100%);
  pointer-events: none;
`;

const Text = styled.p`
  margin-top: 15px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #00BDFF;
`;





import React, { useEffect, useState, useContext } from "react";
import { useNetwork, erc20ABI, useAccount, erc721ABI, useSigner, useProvider } from "wagmi";
import styled, { css } from "styled-components";
import { Box } from "../../components/Layout/Box";
import { InputPanel, InputPanelsWrapper } from "./InputPanel";
import arrowsIcon from "../../assets/icons/arrows.svg";
import { Token, ShellToken, NFT, NFTCollection, isNFTCollection, isShellToken, isLBPToken, isShellV2Token, isExternalToken, VestingStream, placeholderToken, isPlaceholderToken } from "../../utils/tokens";
import { TradeButton, ApproveButton, ErrorAlert, WarningAlert } from "./TradeButton";
import { SwapInfo, SwapInfoDirection, SwapInfoText, SwapWrappedTokenInfo } from "./SwapInfo";
import { tokenColors } from "../../constants/tokenColors";
import { Content } from "../../components/Layout/Content";
import { OCEAN_ADDRESS, ETH_ADDRESS, OLD_OCEAN_ADDRESS, SHELL_ADDRESS, STREAM_ADDRESS } from "../../constants/addresses";
import { Edge, getTokenID, LiquidityGraph } from "../../utils/LiquidityGraph";
import { BigNumber, Contract } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import { formatDisplay } from "../../utils/formatDisplay";
import { formatUnits, parseUnits, parseEther } from "@ethersproject/units";
import { Spinner } from "../../components/Loaders";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { MaxUint256, Zero } from "@ethersproject/constants";
import { PoolQuery, PoolState } from "../../utils/PoolQuery";
import { DEFAULT_SLIPPAGE, SettingsModal } from "./SettingsModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { useLocation } from "react-router-dom";
import { breakpoints, Media } from "../../styles";
import { useWidthBreakpoint } from "../../hooks";
import { ImpactModal } from "./ImpactModal";
import { addPrice } from "../../store/pricesSlice";
import { NFT_PRICES_API, PRICES_API } from "../../constants/urls";
import { NFTCheckModal } from "./NFTCheckModal";
import * as allChainsImported from "wagmi/chains";
import { extract1155Data } from "../../utils/nftHelpers";
import { getModifiedPath } from "@/utils/sor";
import { OceanABI } from "@/constants/ABI/OceanABI";
import { GeoContext } from "@/components/Overlays/GeoProvider";
import { combineMerge, combineSplit } from "@/utils/buildSwapPath";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TradeWidgetProps {
    initInput: any[]
    initOutput: any[]
    lockedTokens: Set<string>
    refreshPage: boolean
    disableSwap?: boolean;
}

export const TradeWidget = ({initInput, initOutput, lockedTokens, refreshPage, disableSwap} : TradeWidgetProps) => {
  const { address: walletAddress, isConnected } = useAccount();
  const { isUS } = useContext(GeoContext);

  const provider = useProvider();
  const { data: signer } = useSigner();

  const { chain: activeChain } = useNetwork();
  const {
    tokens,
    nftCollections,
    liquidityGraph,
    poolQuery,
    tokenMap,
    sorTokenMap,
    connectedChain,
    loadedPrices,
    reloadPrices,
    setReloadPrices,
  } = useContext(ChainContext);
  const validChain = activeChain?.name == connectedChain.name

  const [slippage, setSlippage] = useState(DEFAULT_SLIPPAGE);

  const buildInitTokens = (tokens : any[], nfts : any) => {
    return tokens.map((token : any) => {
        const data1155 = extract1155Data(token.symbol)
        if(data1155){
            const collection : any = tokenMap[data1155.symbol]
            collection.id1155 = parseInt(data1155.id)
            nfts[collection.symbol] = [{
                id: parseInt(data1155.id),
                symbol: collection.symbol,
                name: data1155.item.name,
                wrapped: collection.wrapped,
                rarity: 0,
                image: data1155.item.icon,
                balance: 0,
                desc: data1155.item.desc
            }]
            return collection
        } else {
            return token
        }
    })
  }

  let initNFTsFrom : any = {}
  let initNFTsTo : any = {}

  const initTokensFrom = buildInitTokens(initInput, initNFTsFrom)
  const initTokensTo = buildInitTokens(initOutput, initNFTsTo)
  
  const [selectedNFTsFrom, setSelectedNFTsFrom] = useState<{[collection : string] : any[]}>(initNFTsFrom)
  const [selectedNFTsTo, setSelectedNFTsTo] = useState<{[collection : string] : any[]}>(initNFTsTo)

  const [tokensFrom, setTokensFrom] = useState<any[]>(initTokensFrom);
  const addTokenFrom = () => {
    const newTokenFrom = tokens.find(
      (token) =>
        token.symbol !== tokensFrom[0].symbol &&
        token.address == tokensFrom[0].address
    );
    setTokensFrom([...tokensFrom, placeholderToken]);
    setSelectedProtocolsFrom([...selectedProtocolsFrom, ['all']])
  };

  const removeTokenFrom = (inputToken: Token, index: number) => {

    setPropLockInput('')

    const newTokensFrom = [...tokensFrom];
    newTokensFrom.splice(index, 1);
    setTokensFrom(newTokensFrom);

    const newAmountsFrom: Record<string, string> = { ...amountsFrom };
    delete newAmountsFrom[getTokenID(inputToken)];
    setAmountsFrom(newAmountsFrom);

    const newSelectedProtocolsFrom = [...selectedProtocolsFrom];
    newSelectedProtocolsFrom.splice(index, 1);
    setSelectedProtocolsFrom(newSelectedProtocolsFrom);

    const newUSDValues = { ...usdValues };
    delete usdValues[getTokenID(inputToken)];
    setUSDValues(newUSDValues);
  };

  const [tokensTo, setTokensTo] = useState<any[]>(initTokensTo);
  const addTokenTo = () => {
    const newTokenTo = tokens.find(
      (token) =>
        token.symbol !== tokensTo[0].symbol &&
        token.address == tokensTo[0].address
    );
    setTokensTo([...tokensTo, placeholderToken]);
    setSelectedProtocolsTo([...selectedProtocolsTo, ['all']])
  };
  const removeTokenTo = (outputToken: Token, index: number) => {

    setPropLockOutput('')

    const newTokensTo = [...tokensTo];
    newTokensTo.splice(index, 1);
    setTokensTo(newTokensTo);

    const newSelectedProtocolsTo = [...selectedProtocolsTo];
    newSelectedProtocolsTo.splice(index, 1);
    setSelectedProtocolsTo(newSelectedProtocolsTo);

    const newAmountsTo: Record<string, string> = { ...amountsTo };
    delete newAmountsTo[getTokenID(outputToken)];
    setAmountsTo(newAmountsTo);
  };

  const [amountsFrom, setAmountsFrom] = useState<Record<string, string>>({});
  const [amountsTo, setAmountsTo] = useState<Record<string, string>>({});

  const [selectedProtocolsFrom, setSelectedProtocolsFrom] = useState<string[][]>(tokensFrom.map((_) => ['all']));
  const [selectedProtocolsTo, setSelectedProtocolsTo] = useState<string[][]>(tokensTo.map((_) => ['all']));

  const [splitAmounts, setSplitAmounts] = useState<Record<string, BigNumber>>({});

  const [usdValues, setUSDValues] = useState<Record<string, number>>({});
  const [priceImpacts, setPriceImpacts] = useState<Record<string, number>>({});

  const [fromInputs, setFromInputs] = useState(true); // Determine if user last inputted value on from side

  const [inputWarnings, setInputWarnings] = useState([false]);
  const [outputWarnings, setOutputWarnings] = useState([false]);

  const [errorText, setErrorText] = useState('');
  const [errors, setErrors] = useState<any>({})
  const [warningText, setWarningText] = useState('');
  const [warningVisible, setWarningVisible] = useState(false);

  const [impactVisible, setImpactVisible] = useState(false);
  const [nftCheckVisible, setNFTCheckVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const [propLockInput, setPropLockInput] = useState('')
  const [propLockOutput, setPropLockOutput] = useState('')

  const userBalances = useAppSelector((state) => state.balances.balances[connectedChain.name]);
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);
  const prices = useAppSelector(state => state.prices.prices[connectedChain.name])
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const externalPrimitives = new Set(Object.keys(tokenMap).filter((tokenID) => isExternalToken(tokenMap[tokenID])))

  const computeTotalOutputAmount = async (
    inputTokens: any[],
    outputTokens: any[],
    split: boolean
  ) => {
    let paths: Edge[][] = [];
    let inputNFTPaths: Edge[][] = []

    let inputAmounts: BigNumber[] = [];
    let pools: any

    const outputAmounts: { [token: string]: BigNumber } = {};

    if (split) {
      [paths, inputNFTPaths] = poolQuery.filterInputNFTPath(outputTokens.map((outputToken) =>
          liquidityGraph.findPath(inputTokens[0], outputToken)
      ).sort((a, b) => b.length - a.length));
      
      const inputTokenID = getTokenID(inputTokens[0]);
      let totalInputAmount: number;

      if(inputTokenID == 'STREAM'){
        totalInputAmount = selectedNFTsFrom[inputTokenID].map((stream: any) => parseFloat(stream.vesting)).reduce((partialSum, a) => partialSum + a, 0)
      } else {
        totalInputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsFrom[inputTokenID] ?? 0), inputNFTPaths[0]);
      }

      paths = await Promise.all(paths.map(async (path) => {
        if(path.length == 0 || totalInputAmount == 0) return path
        return getModifiedPath(path, amountsFrom[inputTokenID], slippage, tokenMap, sorTokenMap, connectedChain, liquidityGraph);
      }));

      pools = await poolQuery.getPools(paths)
      const newSplitAmounts: Record<string, BigNumber> = {};

      if (totalInputAmount > 0) {
        let numWithdrawals = 0;
        let withdrawnTokens: string[] = [];

        paths.forEach((path) => {
          // Check to see if a porportional withdrawal will happen
          if (path.length > 1) {
            const firstStep = path[1].action != 'Wrap' ? path[1] : path[2];
            if (firstStep.action == "Withdraw") {
              numWithdrawals++;

              const shellToken: any = tokenMap[firstStep.pool];
              const childTokens = shellToken.tokens;
              const nextToken = getTokenID(firstStep.token);

              if (childTokens[0] == nextToken || "sh" + childTokens[0] == nextToken) {
                withdrawnTokens.push("X");
              } else if (childTokens[1] == nextToken || "sh" + childTokens[1] == nextToken) {
                withdrawnTokens.push("Y");
              } else {
                console.error("Invalid withdrawal path");
              }
            }
          }
        });

        if (numWithdrawals == 2 && withdrawnTokens.includes("X") && withdrawnTokens.includes("Y")) {
          // Proportional withdrawal logic
          const poolData = pools.states[inputTokenID];

          if(loadedPrices){
            const lpToken: any = tokenMap[inputTokenID];
            const childTokens = lpToken.tokens
            const xValue = (await poolQuery.getUSDPrice(tokenMap[childTokens[0]], {...prices})) * parseFloat(formatUnits(poolData.xBalance))
            const yValue = (await poolQuery.getUSDPrice(tokenMap[childTokens[1]], {...prices})) * parseFloat(formatUnits(poolData.yBalance))
            const totalValue = xValue + yValue
            const xAlloc = (xValue / totalValue) * totalInputAmount
            const yAlloc = totalInputAmount - xAlloc
            for (let i = 0; i < withdrawnTokens.length; i++) {
                inputAmounts.push(withdrawnTokens[i] == 'X' ? parseUnits(xAlloc.toFixed(18)) : parseUnits(yAlloc.toFixed(18)))
                newSplitAmounts[getTokenID(paths[i][paths[i].length - 1].token)] = inputAmounts[i]
            }
          } else {
            const totalSupply = parseFloat(formatUnits(poolData.totalSupply));
            const c = 1 - (totalSupply - totalInputAmount) / totalSupply;
  
            for (let i = 0; i < withdrawnTokens.length; i++) {
              const firstIndex = paths[i][1].action != 'Wrap' ? 0 : 1;
              let split = parseUnits((c * parseFloat(formatUnits(withdrawnTokens[i] == 'X' ? poolData.xBalance : poolData.yBalance))).toFixed(18))
              try {
                inputAmounts.push(
                  i == 0 ? 
                  (await poolQuery.query([paths[i][firstIndex], paths[i][firstIndex + 1]], split, pools, false)).amount :
                  parseUnits(totalInputAmount.toFixed(18)).sub(inputAmounts[0])
                )
              } catch {
                inputAmounts.push(parseUnits((totalInputAmount / tokensTo.length).toFixed(18)));
              }
              newSplitAmounts[getTokenID(paths[i][paths[i].length - 1].token)] = inputAmounts[i]
            }
          }
        } else {
            const pathData = combineSplit(JSON.parse(JSON.stringify(paths)))

            const [sharedOutput, firstOutput] = await Promise.all([
                (poolQuery.query(pathData.sharedPath, parseUnits(totalInputAmount.toFixed(18)), pools, true)),
                (poolQuery.query(pathData.sharedPath, parseUnits((totalInputAmount / tokensTo.length).toString()), pools, true))
            ])
      
            tokensTo.forEach((_, index) => {
                inputAmounts.push(index == 0 ? firstOutput.amount : sharedOutput.amount.sub(firstOutput.amount))
                newSplitAmounts[getTokenID(paths[index][paths[index].length - 1].token)] = parseUnits((totalInputAmount / tokensTo.length).toString())
            });
      
            paths = pathData.paths

            pools = poolQuery.filterPools(pools, paths)
        }
      } else {
        tokensTo.forEach((_, index) => {
            inputAmounts.push(BigNumber.from("0"))
            newSplitAmounts[getTokenID(paths[index][paths[index].length - 1].token)] = BigNumber.from("0")
        });
      }
      setSplitAmounts(newSplitAmounts);
    } else {
      [paths, inputNFTPaths] = poolQuery.filterInputNFTPath(inputTokens.map((inputToken) =>
          liquidityGraph.findPath(inputToken, outputTokens[0])
      ).sort((a, b) => b.length - a.length));

      paths = await Promise.all(paths.map(async (path, i) => {
        if(path.length == 0 || !amountsFrom[getTokenID(paths[i][0].token)]) return path
        return getModifiedPath(path, amountsFrom[getTokenID(paths[i][0].token)], slippage, tokenMap, sorTokenMap, connectedChain, liquidityGraph);
      }));


      if(propLockInput == 'locked'){

        pools = await poolQuery.getPools(paths);

        const poolData = pools.states[getTokenID(outputTokens[0])];

        const nftPathIndex = inputNFTPaths[0].length > 0 ? 0 : 1
        const inputNFT = getTokenID(inputNFTPaths[nftPathIndex][0].token)
        const fungibleTokenID = getTokenID(inputTokens[nftPathIndex == 0 ? 1 : 0])
        const nftInputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsFrom[inputNFT] ?? 0), inputNFTPaths[nftPathIndex])

        const fungibleTokenAmount = nftInputAmount * (parseFloat(formatUnits(poolData.yBalance)) / parseFloat(formatUnits(poolData.xBalance)))

        inputAmounts = [parseEther(nftInputAmount.toFixed(18)), parseEther(fungibleTokenAmount.toFixed(18))]
        if(nftPathIndex == 1) inputAmounts.reverse()

        if(amountsFrom[fungibleTokenID] != fungibleTokenAmount.toFixed(18)){
            setAmountsFrom((prevAmountsFrom : any) => ({
                ...prevAmountsFrom,
                [fungibleTokenID]: fungibleTokenAmount.toFixed(18)
            }))
        }

      } else {

        pools = await poolQuery.getPools(paths);

        const pathData = combineMerge(JSON.parse(JSON.stringify(paths)))

        inputAmounts = paths.map((path, index) => {
            if(path.length == 0 && inputNFTPaths[index].length == 0){
                return parseEther(amountsFrom[getTokenID(inputTokens[index])])
            }
            const inputToken = getTokenID((inputNFTPaths[index].length > 0 ? inputNFTPaths[index][0] : path[0]).token)
            let inputAmount;

            if (inputToken == "STREAM") {
                inputAmount = selectedNFTsFrom[inputToken]
                  .map(
                    (stream: any) =>
                      parseFloat(stream.vesting)
                  )
                  .reduce((partialSum, a) => partialSum + a, 0);
              } else {
                inputAmount = poolQuery.adjustNFTAmount(
                  parseFloat(amountsFrom[inputToken] ?? 0),
                  inputNFTPaths[index]
                );
              }
              return parseEther(inputAmount.toFixed(18))
        })

        let totalOutputAmount = Zero

        await Promise.all(pathData.paths.map(async (path: any, i) => {
          if (path.length == 0) {
            const outputTokenID = getTokenID(split ? outputTokens[i] : outputTokens[0])
            if(inputNFTPaths[i].length > 0) outputAmounts[outputTokenID] = inputAmounts[i]; // Handle one step NFT wraps
            if (!outputAmounts[outputTokenID]) outputAmounts[outputTokenID] = Zero;
            return;
          }
          
          const [startToken, endToken] = [getTokenID(path[0].token),getTokenID(path[path.length - 1].token)];
    
          if (inputAmounts[i].isZero()) {
            if (!outputAmounts[endToken]) outputAmounts[endToken] = Zero
            return;
          }
    
          let resultAmount = Zero
          let computeError = false
    
          try {
            if(path.length == 1){
                resultAmount = inputAmounts[i]
            } else {
                const result = await poolQuery.query(path, inputAmounts[i], pools, true);
                resultAmount = result.amount;
                poolQuery.filterPools(pools, pathData.paths).sharedPools.forEach((pool: string) => {
                    pools.states[pool] = {
                        xBalance: result.poolStates[pool][0],
                        yBalance: result.poolStates[pool][1],
                        totalSupply: result.poolStates[pool][2],
                        impAddress: result.poolStates[pool][3],
                    };
                });
            }
          } catch {
            computeError = true
          }
    
          setErrors((prevState: any) => {
            const updatedState = { ...prevState };
            outputTokens.forEach((token: any) => {
                updatedState[getTokenID(token)] = {};
            });
            const inputToken = getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token)
            updatedState[inputToken] = {
              ...prevState[inputToken],
              amount: computeError
            }
            return updatedState;
          });
    
          totalOutputAmount = totalOutputAmount.add(resultAmount)
        }));

        paths = [pathData.sharedPath]
        inputAmounts = [totalOutputAmount]
        pools = poolQuery.filterPools(pools, paths)
      }
      
      setSplitAmounts({});
    }

    for(let i = 0; i < paths.length; i++) {
      const path = paths[i]
      if (path.length == 0) {
        const outputTokenID = getTokenID(split ? outputTokens[i] : outputTokens[0])
        if(inputNFTPaths[i].length > 0) outputAmounts[outputTokenID] = inputAmounts[i]; // Handle one step NFT wraps
        if (!outputAmounts[outputTokenID]) outputAmounts[outputTokenID] = Zero;
        continue;
      }

      const [startToken, endToken] = [getTokenID(path[0].token),getTokenID(path[path.length - 1].token)];

      if (inputAmounts[i].isZero()) {
        if (!outputAmounts[endToken]) outputAmounts[endToken] = Zero
        continue;
      }

      let resultAmount = Zero
      let computeError = false

      try {
        if(path.length == 1){
            resultAmount = inputAmounts[i]
        } else {
            const result = await poolQuery.query(path, inputAmounts[i], pools, true);
            resultAmount = result.amount;
            pools.sharedPools.forEach((pool: string) => {
                pools.states[pool] = {
                    xBalance: result.poolStates[pool][0],
                    yBalance: result.poolStates[pool][1],
                    totalSupply: result.poolStates[pool][2],
                    impAddress: result.poolStates[pool][3],
                };
            });
        }
      } catch {
        computeError = true
      }

      setErrors((prevState: any) => {
        const updatedState = { ...prevState };
        outputTokens.forEach((token: any) => {
            updatedState[getTokenID(token)] = {};
        });
        const inputToken = getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token)
        updatedState[inputToken] = {
          ...prevState[inputToken],
          amount: computeError
        }
        return updatedState;
      });

      outputAmounts[endToken] = outputAmounts[endToken] ? outputAmounts[endToken].add(resultAmount) : resultAmount;
    };

    return outputAmounts;
  };

  const computeTotalInputAmount = async (
    outputTokens: any[],
    inputTokens: any[],
    split: boolean
  ) => {
    let paths: Edge[][] = [];
    let outputNFTPaths: Edge[][] = []

    let outputAmounts: BigNumber[] = [];
    let pools: any

    if (split) {
      [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(inputTokens.map((inputToken) =>
          liquidityGraph.findPath(inputToken, outputTokens[0])
      ).sort((a, b) => {  
        if (a.map((step) => step.token).includes(tokenMap['ETH'])) {
            return -1;
        } else if (b.map((step) => step.token).includes(tokenMap['ETH'])) {
            return 1; 
        }
        return b.length - a.length;
      }));

      pools = await poolQuery.getPools(paths)

      const outputTokenID = getTokenID(outputTokens[0]);
      let totalOutputAmount;

      if(outputTokenID == 'STREAM'){
        totalOutputAmount = selectedNFTsTo[outputTokenID].map((stream: any) => parseFloat(stream.claimable) + parseFloat(stream.vesting)).reduce((partialSum, a) => partialSum + a, 0)
      } else {
        totalOutputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsTo[outputTokenID] ?? 0), outputNFTPaths[0].reverse())
      }

      if (totalOutputAmount > 0) {
        let numDeposits = 0;
        let depositedTokens: string[] = [];

        paths.forEach((path) => {
          // Check to see if a porportional deposit will happen
          if (path.length > 1) {
            const lastStep = path[path.length - 1];
            if (lastStep.action == "Deposit") {
              numDeposits++;

              const shellToken: any = tokenMap[lastStep.pool];
              const childTokens = shellToken.tokens;
              const lastToken = getTokenID(path[path.length - 2].token);

              if (childTokens[0] == lastToken || "sh" + childTokens[0] == lastToken) {
                depositedTokens.push("X");
              } else if (childTokens[1] == lastToken || "sh" + childTokens[1] == lastToken) {
                depositedTokens.push("Y");
              } else {
                console.error("Invalid deposit path");
              }
            }
          }
        });

        if (numDeposits == 2 && depositedTokens.includes("X") && depositedTokens.includes("Y")) {
          // Proportional deposit logic
          const poolData = pools.states[outputTokenID];

          if(loadedPrices){
            const lpToken: any = tokenMap[outputTokenID];
            const childTokens = lpToken.tokens
            const xValue = (await poolQuery.getUSDPrice(tokenMap[childTokens[0]], {...prices})) * parseFloat(formatUnits(poolData.xBalance))
            const yValue = (await poolQuery.getUSDPrice(tokenMap[childTokens[1]], {...prices})) * parseFloat(formatUnits(poolData.yBalance))
            const totalValue = xValue + yValue
            const xAlloc = (xValue / totalValue) * totalOutputAmount
            const yAlloc = totalOutputAmount - xAlloc
            for (let i = 0; i < paths.length; i++) {
                outputAmounts.push(depositedTokens[i] == 'X' ? parseUnits(xAlloc.toFixed(18)) : parseUnits(yAlloc.toFixed(18)))
            }
          } else {

            const totalSupply = parseFloat(formatUnits(poolData.totalSupply));
            const c = (totalSupply + totalOutputAmount) / totalSupply - 1;
  
            for (let i = 0; i < depositedTokens.length; i++) {
              let split = parseUnits((c * parseFloat(formatUnits(depositedTokens[i] == 'X' ? poolData.xBalance : poolData.yBalance))).toFixed(18))
              try {
                outputAmounts.push(
                    i == 0 ? 
                    (await poolQuery.query([paths[i].at(-2)!, paths[i].at(-1)!], split, pools, true)).amount :
                    parseUnits(totalOutputAmount.toFixed(18)).sub(outputAmounts[0])
                )
              } catch {
                outputAmounts.push(parseUnits((totalOutputAmount / tokensFrom.length).toFixed(18)));
              }
            }
          }
        } else {
          const equalSplit = parseUnits((totalOutputAmount / tokensFrom.length).toFixed(18));
          tokensFrom.forEach((_) => outputAmounts.push(equalSplit));
        }
      } else {
        tokensFrom.forEach((_) => outputAmounts.push(BigNumber.from("0")));
      }

      const newSplitAmounts: Record<string, BigNumber> = {};
      paths.forEach((path, index) => (newSplitAmounts[getTokenID(path[0].token)] = outputAmounts[index]));
      setSplitAmounts(newSplitAmounts);
    } else {      
      [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(outputTokens.map((outputToken) =>
          liquidityGraph.findPath(inputTokens[0], outputToken)
      ).sort((a, b) => b.length - a.length));
      pools = await poolQuery.getPools(paths);

      if(propLockOutput == 'locked'){

        const poolData = pools.states[getTokenID(inputTokens[0])];

        const nftPathIndex = outputNFTPaths[0].length > 0 ? 0 : 1
        const outputNFT = getTokenID(outputNFTPaths[nftPathIndex].at(-1)!.token)
        const fungibleTokenID = getTokenID(outputTokens[nftPathIndex == 0 ? 1 : 0])
        const nftOutputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsTo[outputNFT] ?? 0), outputNFTPaths[nftPathIndex])

        const fungibleTokenAmount = nftOutputAmount * (parseFloat(formatUnits(poolData.yBalance)) / parseFloat(formatUnits(poolData.xBalance)))

        outputAmounts = [parseEther(nftOutputAmount.toFixed(18)), parseEther(fungibleTokenAmount.toFixed(18))]
        if(nftPathIndex == 1) outputAmounts.reverse()

        if(amountsTo[fungibleTokenID] != fungibleTokenAmount.toFixed(18)){
            setAmountsTo((prevAmountsTo : any) => ({
                ...prevAmountsTo,
                [fungibleTokenID]: fungibleTokenAmount.toFixed(18)
            }))
        }

      } else {

        outputAmounts = paths.map((path, index) => {
            if(path.length == 0 && outputNFTPaths[index].length == 0){
                return parseEther(amountsTo[getTokenID(outputTokens[index])])
            }
            const outputToken = getTokenID((outputNFTPaths[index].length > 0 ? outputNFTPaths[index].slice(-1)[0] : path[path.length - 1]).token)
            let outputAmount;
            if (outputToken == "STREAM") {
              outputAmount = selectedNFTsTo[outputToken]
                .map(
                  (stream: any) =>
                    parseFloat(stream.claimable) + parseFloat(stream.vesting)
                )
                .reduce((partialSum, a) => partialSum + a, 0);
            } else {
              outputAmount = poolQuery.adjustNFTAmount(
                parseFloat(amountsTo[outputToken] ?? 0),
                [...outputNFTPaths[index]].reverse()
              );
            }
            return parseEther(outputAmount.toFixed(18))
        })
      }  
      setSplitAmounts({});
    }

    const inputAmounts: { [token: string]: BigNumber } = {};

    for (let i = 0; i < paths.length; i++) {
      if (paths[i].length == 0) {
        const inputTokenID = getTokenID(split ? inputTokens[i] : inputTokens[0])
        if (!inputAmounts[inputTokenID]) inputAmounts[inputTokenID] = Zero;
        continue;
      }

      const path = paths[i];

      const [startToken, endToken] = [getTokenID(path[0].token), getTokenID(path[path.length - 1].token)];

      if (outputAmounts[i].isZero()) {
        if (!inputAmounts[startToken]) inputAmounts[startToken] = Zero
        continue;
      }

      let resultAmount = Zero
      let computeError = false

      try {
        if(path.length == 1){
            resultAmount = outputAmounts[i]
        } else {
            const result = await poolQuery.query(path, outputAmounts[i], pools, false);
            resultAmount = result.amount;
            pools.sharedPools.forEach((pool: string) => {
                pools.states[pool] = {
                    xBalance: result.poolStates[pool][0],
                    yBalance: result.poolStates[pool][1],
                    totalSupply: result.poolStates[pool][2],
                    impAddress: result.poolStates[pool][3],
                };
            });
        }
      } catch {
        computeError = true
      }

      setErrors((prevState: any) => {
        const updatedState = { ...prevState };
        inputTokens.forEach((token: any) => {
            updatedState[getTokenID(token)] = {};
        });
        const outputToken = getTokenID((outputNFTPaths[i].length > 0 ? outputNFTPaths[i].slice(-1)[0] : path[path.length - 1]).token)
        updatedState[outputToken] = {
          ...prevState[outputToken],
          amount: computeError
        }
        return updatedState;
      });

      inputAmounts[startToken] = inputAmounts[startToken] ? inputAmounts[startToken].add(resultAmount) : resultAmount;
    }

    return inputAmounts;
  
  }

  const onTokenFromSelect = (token: Token | ShellToken | NFTCollection, index: number) => {

    const newTokensFrom = [...tokensFrom];
    newTokensFrom[index] = token;

    const newAmountsFrom: Record<string, string> = { ...amountsFrom };
    const oldAmount = amountsFrom[getTokenID(tokensFrom[index])];
    delete newAmountsFrom[getTokenID(tokensFrom[index])]; // Delete old token amount

    const newUSDValues = { ...usdValues };
    delete newUSDValues[getTokenID(tokensFrom[index])];
    setUSDValues(newUSDValues);

    const tokenID = getTokenID(token)

    if (newTokensFrom.map((token) => tokensTo.includes(token)).includes(true) || 
        oldAmount == undefined ||  
        tokensTo.filter((token) => isNFTCollection(token)).length > 0
    ) {
      delete newAmountsFrom[tokenID]
      setErrors((prevState: any) => ({
          ...prevState,
          [tokenID]: {}
      }));

    } else if (fromInputs) {
      newAmountsFrom[tokenID] = oldAmount;
    }


    if(isNFTCollection(token)){
        setSelectedNFTsFrom((prevSelectedNFTs) => {
            const newSelectedNFTs: any = { ...prevSelectedNFTs };
            delete newSelectedNFTs[token.symbol]
            return newSelectedNFTs;
        });
        setSelectedNFTsTo({})
        delete newAmountsFrom[tokenID]
    }

    setTokensFrom(newTokensFrom)
    setAmountsFrom(newAmountsFrom);

  };

  const onTokenToSelect = (token: Token | ShellToken | NFTCollection, index: number) => {

    const newTokensTo = [...tokensTo];
    newTokensTo[index] = token;

    const newAmountsTo: Record<string, string> = { ...amountsTo };
    const oldAmount = amountsTo[getTokenID(tokensTo[index])];
    delete newAmountsTo[getTokenID(tokensTo[index])]; // Delete old token amount

    const tokenID = getTokenID(token)

    if (newTokensTo.map((token) => tokensFrom.includes(token)).includes(true) || 
        oldAmount == undefined ||
        tokensFrom.filter((token) => isNFTCollection(token)).length > 0
    ) {

      delete newAmountsTo[tokenID]
      setErrors((prevState: any) => ({
          ...prevState,
          [tokenID]: {}
      }));

    } else if (!fromInputs && !isNFTCollection(token)) {
      newAmountsTo[tokenID] = oldAmount;
    }

    if(isNFTCollection(token)){
        setSelectedNFTsTo((prevSelectedNFTs) => {
            const newSelectedNFTs: any = { ...prevSelectedNFTs };
            delete newSelectedNFTs[token.symbol]
            return newSelectedNFTs;
        });

        if(tokensFrom.filter((token) => isNFTCollection(token)).length == 0) {
            setFromInputs(false)
            delete newAmountsTo[tokenID]
            setAmountsFrom({})
        }
    }

    setTokensTo(newTokensTo);
    setAmountsTo(newAmountsTo);
  };


  const debounce = (fn: Function, ms = 500) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    let previousToken: Token;
  
    return function (this: any, ...args: any[]) {
      const currentToken : Token = args[0];
      if (previousToken === currentToken) {
        clearTimeout(timeoutId);
      }
      previousToken = currentToken;
  
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };

  const onInputAmountChange = debounce((inputToken: Token, amount: string) => {

    setAmountsFrom((prevAmountsFrom) => {
        const newAmountsFrom: Record<string, string> = { ...prevAmountsFrom };
        const tokenID = getTokenID(inputToken)
        if (amount && parseFloat(amount) > 0) {
          newAmountsFrom[tokenID] = amount.replaceAll(',', '');
        } else {
          delete newAmountsFrom[tokenID];
          setErrors((prevState: any) => ({
            ...prevState,
            [tokenID]: {
              ...prevState[tokenID],
              amount: false,
            },
          }));
        }
        return newAmountsFrom;
    });
    setFromInputs(true);

  })

  const onOutputAmountChange = debounce((outputToken: Token, amount: string) => {

    setAmountsTo((prevAmountsTo) => {
      const newAmountsTo: Record<string, string> = { ...prevAmountsTo };
      const tokenID = getTokenID(outputToken)
      if (amount && parseFloat(amount) > 0) {
        newAmountsTo[tokenID] = amount.replaceAll(',', '');
      } else {
        delete newAmountsTo[tokenID];
        setErrors((prevState: any) => ({
          ...prevState,
          [tokenID]: {
            ...prevState[tokenID],
            amount: false,
          },
        }));
      }
      return newAmountsTo;
    });
    setFromInputs(false);

  })

  const handleSelectProtocol = (protocolName: string, index: number, setSelectedProtocols: (value: React.SetStateAction<string[][]>) => void) => {
    setSelectedProtocols(prevSelected => {
    
      const newSelectedProtocols = [...prevSelected]
      if (protocolName === 'all') {
        newSelectedProtocols[index] = ['all']
        return newSelectedProtocols;
      }

      let newSelection;
      if (newSelectedProtocols[index].includes(protocolName)) {
        newSelection = newSelectedProtocols[index].filter(name => name !== protocolName);
        if (newSelection.length === 0) {
          newSelection.push('all');
        }
      } else {
        newSelection = newSelectedProtocols[index].filter(name => name !== 'all').concat(protocolName);
      }

      newSelectedProtocols[index] = newSelection;

      return newSelectedProtocols
    });
  };

  const swapTokens = () => {
    const newAmounts: Record<string, string> = {};

    if(tokensFrom.concat(tokensTo).filter((token) => isNFTCollection(token)).length){
        const newSelectedNFTsFrom = {...selectedNFTsTo}
        const newSelectedNFTsTo = {...selectedNFTsFrom}

        setSelectedNFTsFrom(() => {
            Object.keys(newSelectedNFTsFrom).forEach((collectionID) => {
                const collection = tokenMap[collectionID]
                if(isNFTCollection(collection) && !collection.is1155){
                    delete newSelectedNFTsFrom[collectionID]
                }
            })
            return newSelectedNFTsFrom
        });

        setSelectedNFTsTo(() => {
            Object.keys(newSelectedNFTsTo).forEach((collectionID) => {
                const collection = tokenMap[collectionID]
                if(isNFTCollection(collection) && !collection.is1155){
                    delete newSelectedNFTsTo[collectionID]
                }
            })
            return newSelectedNFTsTo
        });

        setAmountsFrom({})
        setAmountsTo({})
    } else {
        if (tokensFrom.length == 1 && tokensTo.length == 1) {
            if (fromInputs) {
                newAmounts[getTokenID(tokensTo[0])] =
                amountsFrom[getTokenID(tokensFrom[0])];
                setAmountsTo(amountsFrom);
                setAmountsFrom({});
            } else {
                newAmounts[getTokenID(tokensFrom[0])] =
                amountsTo[getTokenID(tokensTo[0])];
                setAmountsFrom(amountsTo);
                setAmountsTo({});
            }
        } else {
            if (tokensFrom.length > 1) {
                setAmountsTo(amountsFrom);
                setAmountsFrom({});
            } else {
                setAmountsFrom(amountsTo);
                setAmountsTo({});
            }
        }
    }

    setErrors({})
    setUSDValues({})

    const newTokensFrom = [...tokensTo];
    const newTokensTo = [...tokensFrom];
    
    setTokensFrom(newTokensFrom);
    setTokensTo(newTokensTo);    

    setSelectedProtocolsFrom(newTokensFrom.map((_) => ['all']))
    setSelectedProtocolsTo(newTokensTo.map((_) => ['all']))
  };

  const labelFrom = (index: number) => {
    if (tokensTo.length > 1) return "From";

    const inputToken = tokensFrom[index];
    const outputToken = tokensTo[0];

    if (inputToken.address !== outputToken.address) return "Swap from";
    else if (!inputToken.wrapped && outputToken.wrapped) return "Wrap from";
    else if (inputToken.wrapped && !outputToken.wrapped) return "Unwrap from";
    else return "From";
  };

  const labelTo = (index: number) => {
    if (tokensTo.length == 1) return "To";

    const inputToken = tokensFrom[0];
    const outputToken = tokensTo[index];

    if (inputToken.address !== outputToken.address) return "Swap to";
    else if (!inputToken.wrapped && outputToken.wrapped) return "Wrap to";
    else if (inputToken.wrapped && !outputToken.wrapped) return "Unwrap to";
    else return "To";
  };

  const [tradeLabel, setTradeLabel] = useState("");
  const [tradeDisabled, setTradeDisabled] = useState(false);
  const [approveDisabled, setApproveDisabled] = useState(false);

  useEffect(() => {
    setTradeDisabled(
      tokensFrom.map((inputToken) => !amountsFrom[getTokenID(inputToken)] || isPlaceholderToken(inputToken)).includes(true) ||
      tokensTo.map((outputToken) => !amountsTo[getTokenID(outputToken)] || isPlaceholderToken(outputToken)).includes(true) ||
      !isConnected ||
      errorText !== "" ||
      loading
    );
  }, [tokensFrom, tokensTo, isConnected, amountsFrom, amountsTo, errorText, loading]);

  const updateTradeButton = () => {

    if (tokensFrom.length == 1 && tokensTo.length == 1) {

      const inputToken = tokensFrom[0];
      const outputToken = tokensTo[0];

      if (!inputToken.wrapped && outputToken.wrapped && inputToken.address == outputToken.address)
        setTradeLabel('Wrap Token')
      else if (inputToken.wrapped && !outputToken.wrapped && inputToken.address == outputToken.address)
        setTradeLabel('Unwrap Token')
      else
        setTradeLabel('Swap Token')
    } else {
      setTradeLabel('Trade Tokens')
    }
  }

  useEffect(() => {
    let warning = "";

    const newInputWarnings = tokensFrom.map((_) => false);
    const newOutputWarnings = tokensTo.map((_) => false);

    for (let i = tokensFrom.length - 1; i >= 0; i--) {
      const inputToken = tokensFrom[i];
      if (isPlaceholderToken(inputToken) || (isLBPToken(inputToken) && inputToken.status == 'Upcoming')) {
        newInputWarnings[i] = true;
        newOutputWarnings.forEach((e, index) => (newOutputWarnings[index] = true));
  
        warning = isPlaceholderToken(inputToken) ? 'No token selected' : 'Pool is not active'
        continue
      }
  
      const inputId = getTokenID(inputToken);
  
      for (let j = tokensTo.length - 1; j >= 0; j--) {
        const outputToken = tokensTo[j];
        if (isPlaceholderToken(outputToken) || (isLBPToken(outputToken) && outputToken.status == 'Upcoming')) {
            newOutputWarnings[j] = true;
            newInputWarnings.forEach((e, index) => (newInputWarnings[index] = true));
  
            warning = isPlaceholderToken(outputToken) ? 'No token selected' : 'Pool is not active'
            continue
        } else if(isUS && outputToken.address == SHELL_ADDRESS){
            newOutputWarnings[j] = true;
            newInputWarnings.forEach((e, index) => (newInputWarnings[index] = true));

            warning = 'Cannot buy SHELL from your region'
            continue
        }
        
        const outputId = getTokenID(outputToken);

        const path = liquidityGraph.findPath(inputToken, outputToken)
  
        if (
          inputId === outputId ||
          path.length == 0
        ) {
          newInputWarnings[i] = true;
          newOutputWarnings[j] = true;
  
          warning = `Invalid pair ${inputId}/${outputId}`
        } else {
            for (let k = path.length - 1; k >= 1; k--) {
                const action = path[k].action
                if(action != 'Wrap' && action != 'Unwrap' && externalPrimitives.has(path[k].pool)){
                    newOutputWarnings[j] = true;
                    warning = `Cannot specify ${outputId} amount`
                }
            }
        }
      }
    }

    setWarningText(warning)
    setInputWarnings(newInputWarnings)
    setOutputWarnings(newOutputWarnings)
    if (!inputWarnings.includes(true) && !outputWarnings.includes(true)) {
      updateTradeButton();
    }
  }, [tokensFrom, tokensTo, walletAddress, isUS]);

  useEffect(() => {
 
    if(tokensFrom.length > 1 && tokensFrom.filter((token) => isNFTCollection(token)).length > 0 && isShellToken(tokensTo[0])){

        if(tokensFrom.map((inputToken) => {
            if(isNFTCollection(inputToken)){
                if(inputToken.is1155){
                    return Object.values(tokenMap).filter((token) => 
                        token.address == inputToken.address && token.symbol.includes(inputToken.id1155!.toString())
                    )[0].symbol
                } else {
                    return tokens.filter((token) => token.address == inputToken.address)[0].symbol
                }
            } else {
                return inputToken.wrapped ? getTokenID(inputToken).substring(2) : getTokenID(inputToken)
            }
        }).every(inputToken => tokensTo[0].tokens.includes(inputToken))){
            setPropLockInput('visible')
        } else{
            setPropLockInput('')
        }

    } else if(tokensTo.length > 1 && tokensTo.filter((token) => isNFTCollection(token)).length > 0 && isShellToken(tokensFrom[0])){

        if(tokensTo.map((outputToken) => {
            if(isNFTCollection(outputToken)){
                if(outputToken.is1155){
                    return Object.values(tokenMap).filter((token) => 
                        token.address == outputToken.address && token.symbol.includes(outputToken.id1155!.toString())
                    )[0].symbol
                } else {
                    return tokens.filter((token) => token.address == outputToken.address)[0].symbol
                }
            } else {
                return outputToken.wrapped ? getTokenID(outputToken).substring(2) : getTokenID(outputToken)
            }
        }).every(outputToken => tokensFrom[0].tokens.includes(outputToken))){
            setPropLockOutput('visible')
        } else{
            setPropLockOutput('')
        }
    }
  }, [tokensFrom, tokensTo])

  useEffect(() => {

    const errorTokens = tokensFrom.concat(tokensTo)

    for (let i = errorTokens.length - 1; i >= 0; i--) {
        const tokenID = getTokenID(errorTokens[i])
        const tokenErrors = errors[tokenID]
        if(tokenErrors){
            if(tokenErrors.amount){
                setErrorText(`Invalid ${tokenID} amount`);
                return
            } else if(tokenErrors.balance){
                setErrorText(`Insufficient ${tokenID} balance`);
                return
            }
        } 
    }

    setErrorText('')
    
  }, [errors])

  const [tokenToApprove, setTokenToApprove] = useState<Token>();

  const approveToken = () => {
    if (tokenToApprove && signer) {
      setApproveDisabled(true);

      if(isNFTCollection(tokenToApprove) || isShellV2Token(tokenToApprove)){

        const tokenContract = new Contract(isShellV2Token(tokenToApprove) ? OLD_OCEAN_ADDRESS : tokenToApprove.address, erc721ABI, signer)
        tokenContract.isApprovedForAll(walletAddress, OCEAN_ADDRESS).then((approvalStatus: any) => {
            if(!approvalStatus){
                tokenContract.setApprovalForAll(OCEAN_ADDRESS, true).then((response: any) => {
                    toast.promise(response.wait(), {
                        loading: "Approving " + tokenToApprove.symbol,
                        success: () => {
                            setApproveDisabled(false);
                            return "Approved " + tokenToApprove.symbol;
                        },
                        error: () => {
                            setApproveDisabled(false);
                            return (
                                "Error in " + tokenToApprove.symbol + " approval"
                            );
                        },
                    }).then(() => updateApproveToken());
                }).catch(() => setApproveDisabled(false))
            }
        }).catch(() => setApproveDisabled(false));;
        
      } else {

        const tokenContract = new Contract(tokenToApprove.address, erc20ABI, signer);

        Promise.all([ tokenContract.allowance(walletAddress, OCEAN_ADDRESS), tokenContract.decimals()]).then(([result, decimals]) => {
            const approval = parseUnits(formatUnits(result, decimals));
            const amount = parseUnits(amountsFrom[getTokenID(tokenToApprove)]);
            if(approval.lt(amount)){ 
                tokenContract.approve(OCEAN_ADDRESS, MaxUint256).then((response: any) => {
                    toast.promise(response.wait(), {
                        loading: "Approving " + tokenToApprove.symbol,
                        success: () => {
                            setApproveDisabled(false);
                            return "Approved " + tokenToApprove.symbol;
                            },
                        error: () => {
                            setApproveDisabled(false);
                            return (
                                "Error in " + tokenToApprove.symbol + " approval"
                            );
                        },
                    }).then(() => updateApproveToken());
                }).catch(() => setApproveDisabled(false))
            }
        }).catch(() => setApproveDisabled(false));
      }
    }
  };

  const updateApproveToken = async () => {
    let needsApproval = false;

    for (let i = 0; i < tokensFrom.length; i++) {
      const inputToken = tokensFrom[i];

      if (!amountsFrom[getTokenID(inputToken)] ||inputToken.address == ETH_ADDRESS)
        continue;

      if (!inputToken.wrapped) {

        if(isNFTCollection(inputToken)){
            const tokenContract = new Contract(inputToken.address, erc721ABI, provider)
            needsApproval = !(await tokenContract.isApprovedForAll(walletAddress, OCEAN_ADDRESS));
        } else if(isShellV2Token(inputToken)){
            const tokenContract = new Contract(OLD_OCEAN_ADDRESS, OceanABI, provider)
            needsApproval = !(await tokenContract.isApprovedForAll(walletAddress, OCEAN_ADDRESS));
        } else {
            const tokenContract = new Contract(inputToken.address, erc20ABI, provider);
            const result = await tokenContract.allowance(walletAddress, OCEAN_ADDRESS);

            const decimals = await tokenContract.decimals();
            const amount = parseUnits(amountsFrom[getTokenID(inputToken)]);

            needsApproval = parseUnits(formatUnits(result, decimals)).lt(amount);
        }

        if (needsApproval) {
            setTokenToApprove(inputToken);
            break;
        }
      }
    }
    if (!needsApproval) {
      setTokenToApprove(undefined);
      updateTradeButton();
    }
  };

  const emptyAmounts = () => {
    return (
      Object.values(amountsFrom)
        .filter((value) => value && value.length > 0)
        .concat(
          Object.values(amountsTo).filter((value) => value && value.length > 0)
        ).length == 0
    );
  };

  const getDisabledTokens = (otherTokens : any[]) => {

    const otherNFTs = otherTokens.filter((token) => isNFTCollection(token))

    let disabledNFTs : string[] = []
    // if(tokensFrom.length > 1 || tokensTo.length > 1){
    //     disabledNFTs = nftCollections.map((collection) => collection.symbol)
    // } else if(otherNFTs.length){
    //     const pairNFTs = otherNFTs.map((collection) => collection.wrapped ? collection.symbol.substring(2) : 'sh' + collection.symbol)
    //     disabledNFTs = nftCollections.map((collection) => collection.symbol).filter((tokenID) => !pairNFTs.includes(tokenID))
    // }

    if(otherNFTs.length){
        if(tokensFrom.length > 1 || tokensTo.length > 1){
            disabledNFTs = nftCollections.map((collection) => collection.symbol)
        } else {
            const pairNFTs = otherNFTs.map((collection) => collection.wrapped ? collection.symbol.substring(2) : 'sh' + collection.symbol)
            disabledNFTs = nftCollections.map((collection) => collection.symbol).filter((tokenID) => !pairNFTs.includes(tokenID))
        }
    }

    return [... new Set(disabledNFTs.concat(otherTokens.map((token) => getTokenID(token))))]
  }

  useEffect(() => {

    if(isConnected && validChain){

      for (let i = tokensFrom.length - 1; i >= 0; i--) { // Show error if any input token amount is greater than user balance
        const inputToken = tokensFrom[i]
        if (inputWarnings[i]) continue
        const inputTokenID = getTokenID(inputToken)
        const inputAmount = amountsFrom[inputTokenID] ?? '0'

        const userBalance = isNFTCollection(inputToken) ? inputToken.is1155 && selectedNFTsFrom[inputToken.symbol].length == 1 ? 
            (selectedNFTsFrom[inputToken.symbol][0].balance ?? 0).toString() :
            (userNFTBalances[inputToken.symbol]?.length ?? 0).toString() :
            userBalances[inputToken.wrapped || isShellV2Token(inputToken) ? inputToken.oceanID ?? "" : inputToken.address] ?? "0";

            setErrors((prevState: any) => ({
            ...prevState,
            [inputTokenID]: {
              ...prevState[inputTokenID],
              balance: parseFloat(inputAmount) > parseFloat(userBalance)
            }
        }));
      }

      updateApproveToken();
    }

  }, [tokensFrom, amountsFrom, walletAddress, userBalances, userNFTBalances]);

  useEffect(() => {

    if (fromInputs && !emptyAmounts()) {
      const newAmountsTo: Record<string, string> = { ...amountsTo };
      const split = tokensTo.length > 1;
      const inputTokens = [...tokensFrom];
      const outputTokens = [...tokensTo];
      if (split) inputTokens.push(inputTokens[0]);

      setLoading(true);
      computeTotalOutputAmount(inputTokens, outputTokens, split).then(
        (outputAmounts: { [token: string]: BigNumber }) => {
          for (let token in outputAmounts) {
            const outputAmount = outputAmounts[token];
            if (outputAmount.isZero()) {
              delete newAmountsTo[token];
            } else {
              newAmountsTo[token] = formatUnits(outputAmount);
            }
          }
          setAmountsTo(newAmountsTo);
          setLoading(false);
        }
      );
    }
  }, [fromInputs, amountsFrom, tokensFrom, tokensTo, walletAddress]);

  useEffect(() => {
    if (!fromInputs && !emptyAmounts()) {
      const newAmountsFrom: Record<string, string> = { ...amountsFrom };
      const split = tokensFrom.length > 1;
      const outputTokens = [...tokensTo];
      const inputTokens = [...tokensFrom];
      if (split) outputTokens.push(outputTokens[0]);

      setLoading(true);
      computeTotalInputAmount(outputTokens, inputTokens, split).then(
        (inputAmounts: { [token: string]: BigNumber }) => {
          for (let token in inputAmounts) {
            const inputAmount = inputAmounts[token];
            if (inputAmount.isZero()) {
              delete newAmountsFrom[token];
            } else {
              newAmountsFrom[token] = formatUnits(inputAmount);
            }
          }
          setAmountsFrom(newAmountsFrom);
          setLoading(false);
        }
      );
    }
  }, [fromInputs, amountsTo, tokensTo, tokensFrom, walletAddress, selectedNFTsTo]);

  useEffect(() => {

    const newPriceImpacts : any = {}
    
    const totalInputValue = tokensFrom
      .map((token) => usdValues[getTokenID(token)])
      .reduce((partialSum, a) => partialSum + a, 0);
    const totalOutputValue = tokensTo
      .map((token) => usdValues[getTokenID(token)])
      .reduce((partialSum, a) => partialSum + a, 0);

    const impact = totalOutputValue / totalInputValue - 1;

    tokensTo.forEach((token) => {
      newPriceImpacts[getTokenID(token)] = impact * 100;
    });

    setPriceImpacts(newPriceImpacts);
  }, [usdValues]);

  const [reloadBalances, setReloadBalances] = useState(false);
  const [txSuccess, setTxSuccess] = useState(false);

  useEffect(() => {
    if (txSuccess) {
      if (!confirmVisible) {
        if(refreshPage){
            window.location.reload()
        } else {
          setReloadBalances(!reloadBalances);
          if(tokensFrom.concat(tokensTo).findIndex((token) => token.address == STREAM_ADDRESS) != -1) setReloadPrices(!reloadPrices)
          setAmountsFrom({});
          setAmountsTo({});
          setSplitAmounts({});
        }
      }
    }
  }, [txSuccess, confirmVisible]);

  const openConfirmation = () => {
    if (isConnected) {
      if(tokensFrom.filter((token) => isNFTCollection(token) && !token.is1155).length > 0 && tokensTo.filter((token) => isNFTCollection(token)).length == 0){
        setNFTCheckVisible(true);
      } else if (
        Object.values(priceImpacts).filter((impact) => impact <= -1).length > 0
      ) {
        setImpactVisible(true);
      } else {
        setConfirmVisible(true);
      }
    }
  };

  const debounceNFT = (fn: Function, ms = 1000) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };

  const debouncedSelectNFTsTo = debounceNFT((collection: string, items: NFT[]) => {
    setSelectedNFTsTo(prevSelectedNFTs => ({...prevSelectedNFTs, [collection]: items}))
  });

  return (
    <>
      <InputPanelsWrapper position="top">
      {
        tokensFrom.map((token, index) => (
        <InputPanel
          key={`${token.symbol}${index}`}
          selectedToken={token}
          onAddButtonClick={addTokenFrom}
          onRemoveButtonClick={() => removeTokenFrom(token, index)}
          onTokenSelect={(token: Token) => onTokenFromSelect(token, index)}
          value={amountsFrom[getTokenID(token)] ?? ""}
          onChange={onInputAmountChange}
          isInputToken={true}
          label={labelFrom(index)}
          inputsAmount={tokensFrom.length}
          shape="bottom"
          anotherSelected={lockedTokens.has(getTokenID(token)) || initInput.length < 2}
          otherTokens={tokensFrom.filter((_, i) => i != index).map((token) => getTokenID(token))}
          disabledTokens={getDisabledTokens(tokensTo)}
          reloadBalances={reloadBalances}
          error={Object.values(errors[getTokenID(token)] ?? {}).includes(true)}            
          warning={inputWarnings[index]}
          loading={loading && !fromInputs}
          otherNFTs={selectedNFTsTo}
          nftsOnOtherSide={tokensTo.filter((token) => isNFTCollection(token)).length > 0}
          setWarningVisible={setWarningVisible}
          setUSDValues={setUSDValues}
          priceImpact={0}
          selectedNFTs={selectedNFTsFrom[token.symbol]}
          allSelectedNFTs={selectedNFTsFrom}
          updateSelectedNFTs={(collection: string, items: NFT[]) => setSelectedNFTsFrom(prevSelectedNFTs => ({...prevSelectedNFTs, [collection]: items}))}
          propLock={propLockInput}
          setPropLock={setPropLockInput}
          isWidget={true}
          selectedProtocols={selectedProtocolsFrom[index]}
          handleSelectProtocol={(protocolName: string) => handleSelectProtocol(protocolName, index, setSelectedProtocolsFrom)}
          inputLocked={isPlaceholderToken(token)}
          selectionLocked={lockedTokens.has(getTokenID(token))}
        />
        ))
      }
      </InputPanelsWrapper>
      <SwapButtonContainer>
        {loading ? (
          <SpinContainer>
            <Spinner />
          </SpinContainer>
        ) : (
          <SwapButton data-testid="trade-screen-swap-btn" onClick={swapTokens} disabled={disableSwap ?? false}>
            <img src={arrowsIcon} alt="swap" />
          </SwapButton>
        )}
      </SwapButtonContainer>
      <InputPanelsWrapper position="bottom">
      {
        tokensTo.map((token, index) => (
          <InputPanel
              key={`${token.symbol}${index}`}
              selectedToken={token}
              onAddButtonClick={addTokenTo}
              onRemoveButtonClick={() => removeTokenTo(token, index)}
              onTokenSelect={(token: Token) => onTokenToSelect(token, index)}
              value={amountsTo[getTokenID(token)] ?? ""}
              onChange={onOutputAmountChange}
              isInputToken={false}
              label={labelTo(index)}
              inputsAmount={tokensTo.length}
              shape="top"
              anotherSelected={lockedTokens.has(getTokenID(token)) || initInput.length < 2}
              otherTokens={tokensTo.filter((_, i) => i != index).map((token) => getTokenID(token))}
              disabledTokens={getDisabledTokens(tokensFrom)}
              reloadBalances={reloadBalances}
              error={Object.values(errors[getTokenID(token)] ?? {}).includes(true)}                
              warning={outputWarnings[index]}
              loading={loading}
              otherNFTs={selectedNFTsFrom}
              nftsOnOtherSide={tokensFrom.filter((token) => isNFTCollection(token)).length > 0}
              setWarningVisible={setWarningVisible}
              setUSDValues={setUSDValues}
              priceImpact={priceImpacts[getTokenID(token)]}
              selectedNFTs={selectedNFTsTo[token.symbol]}
              allSelectedNFTs={selectedNFTsTo}
              updateSelectedNFTs={(collection: string, items: NFT[]) => setSelectedNFTsTo(prevSelectedNFTs => ({...prevSelectedNFTs, [collection]: items}))}
              nftSweepInputPrice={formatDisplay(amountsFrom[getTokenID(tokensFrom[tokensFrom.length - 1])] || '0') + ' ' + getTokenID(tokensFrom[tokensFrom.length - 1])}
              onNFTSweepSelect={debouncedSelectNFTsTo}
              propLock={propLockOutput}
              setPropLock={setPropLockOutput}
              isWidget={true}
              selectedProtocols={selectedProtocolsTo[index]}
              handleSelectProtocol={(protocolName: string) => handleSelectProtocol(protocolName, index, setSelectedProtocolsTo)}
              inputLocked={isPlaceholderToken(token)}
              selectionLocked={lockedTokens.has(getTokenID(token))}
          />
        ))
      }
      </InputPanelsWrapper>
      {tokensFrom &&
        tokensFrom.length !== 0 &&
        tokensTo &&
        tokensTo.length !== 0 && (
          <SwapInfo style={{marginTop: '12px'}}>
             <div style={{width: '28px'}}>{" "}</div>
            {/* Emtpy div for spacing purposes */}
            <SwapInfoText className="shrunk">
              Swap {tokensFrom.map((token, index) => {
                  if(!isPlaceholderToken(token)){
                    return <span data-testid={`swap-info-from-${token.symbol}-${index}`} key={index}>
                      <SwapInfoDirection color={tokenColors[token.symbol]}>
                        {`${formatDisplay(amountsFrom[getTokenID(token)] || '0')} ${token.symbol}`} {`(${token.name})`}
                      </SwapInfoDirection>
                      {index !== tokensFrom.length - 1 && !isPlaceholderToken(tokensFrom[index + 1]) && ' and '}
                    </span>
                 } else {
                  return <></>
                 }
                }
              )} {tokensTo.filter((token) => !isPlaceholderToken(token)).length == 0 ? '' : "to "}
              {tokensTo.map((token, index) => {
                if(!isPlaceholderToken(token)){
                  return <span data-testid={`swap-info-to-${token.symbol}-${index}`} key={index}>
                    <SwapInfoDirection color={tokenColors[token.symbol]}>
                      {`${formatDisplay(amountsTo[getTokenID(token)] || '0')} ${token.symbol}`} {`(${token.name})`}
                    </SwapInfoDirection>
                    {index !== tokensTo.length - 1 && !isPlaceholderToken(tokensTo[index + 1]) && ' and '}
                  </span>
                } else {
                  return <></>
                }
              })}
            </SwapInfoText>
            {/* {!isMobile && !isTablet && ( */}
              <SettingsModal
                userSlippage={slippage}
                setUserSlippage={setSlippage}
              />
            {/* )} */}
          </SwapInfo>
        )}
      <ImpactModal
        visible={impactVisible}
        setVisible={setImpactVisible}
        priceImpact={Math.min(
          ...tokensTo.map((token) => priceImpacts[getTokenID(token)])
        )}
        setTradeDisabled={setTradeDisabled}
        setConfirmVisible={setConfirmVisible}
      />
      <NFTCheckModal
        dataTestId="nft-check-modal"
        visible={nftCheckVisible}
        setVisible={setNFTCheckVisible}
        setTradeDisabled={setTradeDisabled}
        setNextVisible={Object.values(priceImpacts).filter((impact) => impact <= -1).length > 0 ? setImpactVisible : setConfirmVisible}
      />
      <ConfirmationModal
        visible={confirmVisible}
        setVisible={setConfirmVisible}
        setTradeDisabled={setTradeDisabled}
        tokensFrom={tokensFrom}
        tokensTo={tokensTo}
        specifiedAmounts={fromInputs ? amountsFrom : amountsTo}
        splitAmounts={splitAmounts}
        slippage={slippage}
        fromInputs={fromInputs}
        selectedNFTs={fromInputs ? selectedNFTsFrom : selectedNFTsTo}
        setSelectedNFTs={fromInputs ? setSelectedNFTsFrom : setSelectedNFTsTo}
        setTxSuccess={setTxSuccess}
      />
      <ButtonsContainer>
        {!errorText && !warningVisible && tokenToApprove && (
          <ApproveButton
            disabled={approveDisabled}
            walletConnected={isConnected}
            chainConnected={validChain}
            onClick={approveToken}
            tokenToApprove={tokenToApprove}
          >
            Approve {tokenToApprove.symbol}
          </ApproveButton>
        )}
        {!errorText && !warningVisible && (
          <TradeButton
            disabled={
              tradeDisabled ||
              typeof tokenToApprove != "undefined"
            }
            walletConnected={isConnected}
            chainConnected={validChain}
            onClick={openConfirmation}
          >
            {tradeLabel}
          </TradeButton>
        )}
      </ButtonsContainer>
      {errorText && <ErrorAlert>{errorText}</ErrorAlert>}
      {warningVisible && <WarningAlert>{warningText}</WarningAlert>}
      {!isConnected && <Text>Connect your wallet to execute trade.</Text>}
      {!validChain && isConnected && (
        <Text>{`Connect to ${connectedChain.name} to execute trade`}</Text>
      )}
    </>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 12px;
`;

const Text = styled.p`
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #00bdff;
`;

const SwapButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  margin: -8px auto -8px;
`;

const SpinContainer = styled.div`
  > img {
    width: 52px;
    height: 52px;
  }
`;

const SwapButton = styled.button`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #171b33;
  border: 1px solid rgba(255, 255, 255, 0.03);
  z-index: 10;

  &:hover {
    box-shadow: 0px 0px 25px rgba(43, 213, 244, 0.2);
    border: 1px solid #2c5173;
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

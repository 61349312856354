import { ButtonSecondary } from "@/components/Buttons/Button";
import { NFTCollection } from "@/utils/tokens";
import React, { useContext } from "react";
import styled from "styled-components";
import shellCoin from "@/assets/coins/shell-small.svg";
import openSeaLogo from "@/assets/icons/opensea-logo.png";
import { Link } from "react-router-dom";
import { formatDisplay } from "@/utils/formatDisplay";
import { useAppSelector } from "@/store/hooks";
import { Media } from "@/styles";
import { STREAM_ADDRESS } from "@/constants/addresses";
import { ChainContext } from "@/components/Overlays/ChainProvider";

type NFTDetailsModalBodyProps = {
    nft?: any;
}

export const NFTDetailsModalBody = ({ nft }: NFTDetailsModalBodyProps) => {

    const { tokenMap } = useContext(ChainContext)

    const collection: NFTCollection = tokenMap[nft.symbol] as NFTCollection
    const vestingEndTime = useAppSelector((state) => state.vesting.endTime);

    const isVestingStream = nft.symbol == 'STREAM' && collection.address == STREAM_ADDRESS 
    
    return nft ? (
      <ModalBodyContainer style={{padding: '16px 0 0 0'}}>
        <DetailsWrapper>
          <Image src={nft.image} alt={`${nft.name}-image`} />
          <Details>
          <DetailsSide>
            {isVestingStream ? (
              <>
                <GeneralInfoWrapper>
                  <CollectionName>{nft.name}</CollectionName>
                  <StreamId>#{nft.id}</StreamId>
                </GeneralInfoWrapper>
                <VestInfoWrapper>
                  <VestInfoItem>
                    <VestInfoLabel>Duration</VestInfoLabel>
                    <VestInfoValueWrapper>
                      <VestInfoValue>{vestingEndTime}</VestInfoValue>
                    </VestInfoValueWrapper>
                  </VestInfoItem>
                  <HrDiv />
                  <VestInfoItem>
                    <VestInfoLabel>Claimable</VestInfoLabel>
                    <VestInfoValueWrapper>
                      <img src={shellCoin} alt="shell logo" />
                      <VestInfoValue>
                        {formatDisplay(nft.claimable, 2)} SHELL
                      </VestInfoValue>
                    </VestInfoValueWrapper>
                  </VestInfoItem>
                  <HrDiv />
                  <VestInfoItem>
                    <VestInfoLabel>Vesting</VestInfoLabel>
                    <VestInfoValueWrapper>
                      <img src={shellCoin} alt="shell logo" />
                      <VestInfoValue>
                        {formatDisplay(nft.vesting, 2)} SHELL
                      </VestInfoValue>
                    </VestInfoValueWrapper>
                  </VestInfoItem>
                </VestInfoWrapper>
                <DescriptionWrapper>
                  <DescriptionSubtitle>About collection</DescriptionSubtitle>
                  <Description>
                    {collection.description}{" "}
                    <Link to="https://wiki.shellprotocol.io/shell-dao/shell-token/sablier-vesting-stream-shell" target="_blank">
                      Learn more
                    </Link>
                  </Description>
                </DescriptionWrapper>
              </>
            ) : (
              <>
                <GeneralInfoWrapper>
                  <CollectionName>{nft.name}</CollectionName>
                  <StreamId>#{nft.id}</StreamId>
                </GeneralInfoWrapper>
                <InfoWrapper>
                  {nft.metadata.map((attribute: any, index: number) => {
                    if (index % 2 == 0) {
                      const nextAttribute =
                        index + 1 < nft.metadata.length
                          ? nft.metadata[index + 1]
                          : { trait_type: "", value: "" };
                      return (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              width: "100%",
                              gap: "24px",
                            }}
                          >
                            <InfoItem>
                              <InfoLabel>{attribute["trait_type"]}</InfoLabel>
                              <InfoValueWrapper>
                                <InfoValue>{attribute.value}</InfoValue>
                              </InfoValueWrapper>
                            </InfoItem>
                            <InfoItem>
                              <InfoLabel>
                                {nextAttribute["trait_type"]}
                              </InfoLabel>
                              <InfoValueWrapper>
                                <InfoValue>{nextAttribute.value}</InfoValue>
                              </InfoValueWrapper>
                            </InfoItem>
                          </div>
                          {index + 2 < nft.metadata.length && <HrDiv />}
                        </>
                      );
                    }
                  })}
                </InfoWrapper>
                <DescriptionWrapper>
                  <DescriptionSubtitle>About collection</DescriptionSubtitle>
                  <Description>{nft.desc}</Description>
                </DescriptionWrapper>
              </>
            )}
          </DetailsSide>
          <a
                  href={`https://opensea.io/assets/arbitrum/${
                    collection.address
                  }/${nft.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={nft.id}
                  style={{ width: "100%" }}
                >
                  <ButtonOpenSea>
                    View on OpenSea
                    <img src={openSeaLogo} alt="opensea logo" />
                  </ButtonOpenSea>
                </a>
          </Details>
        </DetailsWrapper>
      </ModalBodyContainer>
    ) : null;
};

const ModalBodyContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
gap: 16px;
`;

const Title = styled.p`
color: var(--White, #FFF);

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;
`;

const DetailsWrapper = styled.div`
display: flex;
align-items: stretch;
justify-content: center;
width: 100%;
gap: 20px;

${Media.mobile}{
    flex-direction: column;
    gap: 12px;
}
`;

const Image = styled.img`
border-radius: 16px;
width: 57%;
height: auto;
${Media.mobile}{
    width: 100%;
}
`;

const Details = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
`

const DetailsSide = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;

${Media.mobile}{
    gap: 8px;
}
`;

const GeneralInfoWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
align-self: flex-start;

${Media.mobile}{
    gap: 0;
}
`;

const CollectionName = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

const StreamId = styled.p`
color: #FFF;

/* Title 5 */
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.9px;
`;

const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
gap: 8px;
`;

const InfoItem = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

const InfoLabel = styled(CollectionName)`
${Media.mobile}{
    font-size: 12px;
}
`;

const InfoValueWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 8px;

img {
    width: 16px;
    height: 16px;
}
`;

const InfoValue = styled.p`
color: var(--White, #FFF);
text-align: right;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */

${Media.mobile}{
    font-size: 12px;
}
`;

const DescriptionWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;

${Media.mobile}{
    gap: 0;
}
`;

const DescriptionSubtitle = styled(InfoValue)``;

const Description = styled(InfoLabel)`
a {
    background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
height: 136px;
overflow: auto;

${Media.mobile}{
    height: 104px;
}
`;

const ButtonOpenSea = styled(ButtonSecondary)`
width: 100%;
border-radius: 10px;
padding: 14px 16px;
height: unset;
align-self: flex-end;
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
border: 2px solid var(--gradient-2, #37DCF2);

img {
    width: 16px;
    height: 16px;
}
`;

const HrDiv = styled.div`
width: 100%;
height: 1px;
background: var(--dark-3, #1E2239);;
`;

const VestInfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
gap: 8px;
`;

const VestInfoItem = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

const VestInfoLabel = styled(CollectionName)``;

const VestInfoValueWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 8px;

img {
    width: 16px;
    height: 16px;
}
`;

const VestInfoValue = styled.p`
color: var(--White, #FFF);
text-align: right;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;




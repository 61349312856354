import React, { useRef, useState } from "react";
import styled from "styled-components";
import chevronDown from "@/assets/icons/chevron-down.svg";
import { useClickOutside } from "@/hooks/useClickOutside";
import blueCheck from "@/assets/icons/blue-check.svg";
import { Media, breakpoints } from "@/styles";
import { Chain } from "@/placeholders/chains";
import { useWidthBreakpoint } from "@/hooks";

type ChainDropdownProps = {
  allChains: Chain[];
  selectedChain: Chain;
  onChainSelect: (chain: Chain) => void;
};

export const ChainDropdown: React.FC<ChainDropdownProps> = ({ allChains, selectedChain, onChainSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const ref = useRef(null);

  useClickOutside(ref, () => setIsOpen(false));

  const handleChainSelect = (chain: Chain) => {
    onChainSelect(chain);
    setIsOpen(false);
  };

  return (
    <DropdownStyled ref={ref}>
      <SelectInput onClick={() => setIsOpen(!isOpen)}>
        {selectedChain.icon && <Icon src={selectedChain.icon} alt={`${selectedChain.name} icon`} />}
        {!isTablet && selectedChain.name.split(" ")[0]}
        <ChevronImg alt="chevron" src={chevronDown} isOpen={!isOpen} />
      </SelectInput>
      {isOpen && (
        <OptionList>
          {allChains.map((chain) => (
            <Option key={chain.name} onClick={() => handleChainSelect(chain)} selected={selectedChain.name === chain.name}>
              <Icon src={chain.icon} alt={`${chain.name} icon`} />
              <Name>{chain.name.split(" ")[0]}</Name>
            </Option>
          ))}
        </OptionList>
      )}
    </DropdownStyled>
  );
};

const DropdownStyled = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  border-radius: 16px;
  background-color: #0a0e27;
  color: #fff;
  font-family: "Inter", sans-serif;
  margin-right: 12px;

  ${Media.tablet} {
    margin-right: 8px;
  }
`;

const SelectInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  height: 60px;
  border-radius: 16px;
  background-color: #0a0e27;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    cursor: pointer;
  }

  ${Media.tablet} {
    height: 50px;
    padding: 8px 10px;
  }
`;

const OptionList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 2;
  max-width: 225px;
  min-width: 180px;
  border-radius: 16px;
  background: #0a0e27;
  box-shadow: 0px 8px 24px 0px rgba(11, 13, 34, 0.6);
  padding: 8px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: flex;
`;

const Option = styled.li<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  transition: background-color 0.2s ease;
  padding: 8px 12px;

  &:hover {
    background: #1e2239;
    cursor: pointer;
  }

  &::after {
    margin-left: 10px;
    content: ${({ selected }) => (selected ? `url(${blueCheck})` : "")};
  }
`;

const Name = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
`;

const ChevronImg = styled.img<{ isOpen: boolean }>`
  margin-left: 4px;
  width: 28px;
  height: 28px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "none")};
  transition: transform 0.15s ease-in-out;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
`;

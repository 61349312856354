import React, { ReactNode } from "react";
import { arbitrum, mainnet, optimism } from "wagmi/chains";
import { WagmiConfig, createClient, configureChains, createStorage, Chain } from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import arbLogo from '../assets/icons/arbitrum-logo.svg'

export const projectId = "1a0918d9c1353afb69eeedbbbcb3eb0a"; // for walletconnect cloud
export const infuraId = '6a2e4095949b446d9b2d93a50182519a';
export const alchemyId = "a6bQsEnqp2PtKmqtI6wTU8uKcrSFkwac";
export const openseaKey = '559f844909c04ce9a5ace8eeba9533d8'

export const base = {
    id: 8453,
    name: 'Base',
    network: 'base',
    nativeCurrency: {
      decimals: 18,
      name: 'Ether',
      symbol: 'ETH',
    },
    rpcUrls: {
      public: { http: ["https://mainnet.base.org"] },
      default: { http: ["https://mainnet.base.org"] },
    },
    blockExplorers: {
      etherscan: { name: 'Basescan', url: 'https://basescan.org' },
      default: { name: 'Basescan', url: 'https://basescan.org' },
    },
    contracts: {
      multicall3: {
        address: "0xcA11bde05977b3631167028862bE2a173976CA11",
        blockCreated: 5022
      }
    },
  } as const satisfies Chain

const chains = [arbitrum, mainnet, base, optimism];

function createLimitedStorage(storage: Storage, maxSize: number): Storage {
  return {
      get length() {
          return storage.length;
      },
      getItem: (key) => {
          return storage.getItem(key);
      },
      setItem: (key, value) => {
          if (value && value.length > maxSize) {
              throw new Error(`Storage limit exceeded! Max allowed size is ${maxSize} characters.`);
          }
          storage.setItem(key, value);
      },
      removeItem: (key) => {
          storage.removeItem(key);
      },
      clear: () => {
          storage.clear();
      },
      key: (index) => {
          return storage.key(index);
      }
  };
}

const limitedLocalStorage = createLimitedStorage(window.localStorage, 5000);
const wagmiStorage = createStorage({ storage: limitedLocalStorage });

const { provider } = configureChains(chains, [alchemyProvider({ apiKey: alchemyId }), w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider,
  storage: wagmiStorage
});

const ethereumClient = new EthereumClient(wagmiClient, chains);
interface Props {
  children: ReactNode;
}

export function WagmiProvider(props: Props) {
  return (
    <>
      <WagmiConfig client={wagmiClient}>{props.children}</WagmiConfig>
      <Web3Modal
        defaultChain={arbitrum}
        enableAccountView={true}
        chainImages={
          {
            42161: arbLogo, // arbitrum one
            421613: arbLogo, // arbitrum goerli
          }
        }
        themeVariables={{
          "--w3m-accent-color": "#07c0fb",
          "--w3m-accent-fill-color": "#0a0e27",
          "--w3m-button-border-radius": "16px",
          "--w3m-text-medium-regular-size": "16px",
          "--w3m-text-medium-regular-weight": "600",
          "--w3m-text-medium-regular-line-height": "19px",
        }}
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
}
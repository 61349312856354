import React from 'react'
import styled from 'styled-components';
import { DropdownIconDown, DropdownIconUp } from '../Icons/DropdownIcon'

interface ExpandButtonProps {
  row: any
}

export const ExpandButton = ({row}: ExpandButtonProps) => {
  const {title, style} = row.getToggleRowExpandedProps()

  return row.original.name !== "veSHELL" ? (
    <Button
      title={title}
      style={style}
      className={row.isExpanded ? 'expanded' : ''}
      visible={true}
    >
      {row.isExpanded
        ? <DropdownIconUp/>
        : <DropdownIconDown/>
      }
    </Button>
  ) : (<div></div>);
}

const Button = styled.button<{visible : boolean}>`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
`;

import { Box } from "@/components/Layout/Box";
import { Media } from "@/styles";
import styled from "styled-components";

export const StakingContainer = styled.div`
padding: 24px 0;
display: flex;
width: 100%;
flex-direction: column;
justify-content: flex-start;
align-items: center;
gap: 24px;
`;

export const SectionContainer = styled.div`
display: flex;
gap: 16px;
width: 100%;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`;

export const SectionTitle = styled.h2`
color: var(--grey-1, #F0F2F6);

/* Text 2/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const OverviewBoxesWrapper = styled.div`
display: flex;
gap: 16px;
width: 100%;
justify-content: center;
align-items: center;

${Media.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const MyStakingWrapper = styled.div<{isStretch: boolean}>`
width: 100%;
display: flex;
gap: 16px;
justify-content: center;
align-items: ${({isStretch}) => isStretch ? "stretch" : "flex-start"};

${Media.tablet} {
    flex-direction: column;
  }
`;

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 300px 0 300px 0;
`;
import React from 'react'
import styled from 'styled-components'
import { formatDisplay } from '../../../../../../utils/formatDisplay'

export const BalanceCell = ({ value, row }: any) => {
    return (
        <span>
            {`${formatDisplay(value, row.original.balance.decimals)}`}
        </span>
    )
}
import React from "react";
import styled from "styled-components";
import { NFT } from "../../utils/tokens";
import { Media } from "../../styles";
import { scrollbar } from "../../styles/scrollbar";
import { Modal } from "../../components/Modal/Modal";

interface NFTWarningProps {
  nftTokens: NFT[];
  showModal: boolean;
  canProceed: boolean;
  onClose: any;
  onProceed: any;
}
export const NFTWarning = ({
  nftTokens,
  showModal,
  canProceed,
  onClose,
  onProceed
}: NFTWarningProps) => {

  return (
    <StyledModal
        isVisible={showModal}
        onClose={onClose}
    >
        <Title>Uh oh!</Title>
        <Text>The following NFTs are no longer available</Text>
        <GalleryContainer itemCount={nftTokens.length}>
            {nftTokens && nftTokens.map((nft: NFT) => (
                <GalleryItem key={nft.id}>
                    <ImageContainer>
                        <ToucanImage src={nft.image} alt="ToucanImage" />
                    </ImageContainer>
                    <Row>
                        <TokenID>#{nft.id}</TokenID>
                    </Row>
                </GalleryItem>
            ))}
        </GalleryContainer>
        {canProceed ? 
            <>
                <Text>Do you wish to proceed without them?</Text>
                <ActionButton onClick={onClose}>No, take me back</ActionButton>
                <ModalButton onClick={onProceed}>{`Proceed anyways \u2192`}</ModalButton>
            </> : 
            <ActionButton style={{margin: '0 auto'}} onClick={onClose}>Take me back</ActionButton>
        }
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  height: 547px;
  max-width: 520px;  

  > svg {
    height: 42px;
    width: 42px;
  }

  ${Media.mobile} {
      height: 95%;
      max-width: 100%;
      margin-top: 5.5%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
  }
`;

const Title = styled.h3`
  margin-top: 12px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #7d7d97;
  text-align: center;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 264px;
  height: 76px;
  margin: 12px auto 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height; 28px;
    width: 28px;
    margin-right: 8px;
    color: #464659;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #0a0e27;
    color: #464659;
    border: solid 2px #464659;
  }
`;

const ModalButton = styled.button`
  margin-top: 8px;
  font-weight: 100;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #7D7D97;
  white-space: nowrap;
  letter-spacing: -0.02em;

  :hover {
      color: #00BDFF;
  }
`;

const Row = styled.div`
  align-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
  > div {
    align-items: center;
    display: flex;
  }
`;

const GalleryContainer = styled.div<{ itemCount: number }>`
  display: grid;
  gap: 16px 12px;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-items: start;
  align-content: flex-start;
  justify-content: start;
  grid-template-columns: repeat(4, calc(25% - calc(3 * 12px / 4)));
  align-items: center;
  ${scrollbar()}
  height: calc(100% - 240px);
  padding: 0px;

  ${Media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  ${Media.smallMobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }
`;

const GalleryItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: border-color 0.3s ease;
  background-color: #171b33;
  max-height: 100%;
  max-width: 100%;
  padding: 10px 10px 0px 10px;

  &:hover {
    border-color: #37dcf2;
  }

  ${Media.mobile} {
    max-width: 90%;
  }

  ${Media.smallMobile} {
    max-width: 90%;
  }
`;

const ImageContainer = styled.div`
  display: flex;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    width: 0px;
  }
`;

const ToucanImage = styled.img`
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const TokenID = styled.span`
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

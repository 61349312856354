import React from "react";
import * as S from "../Vesting.styled";
import { timestampToDate } from "@/utils/formatDisplay";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";

export const VestingDetails = () => {

    const { getTimeUntil, vestingStartTime, vestingEndTime } = useRewardsContext()

    return (
        <S.BoxWrapper>
            <S.BoxTitle>Vesting Details</S.BoxTitle>
            <S.BoxDetailsWrapper>
                <S.BoxDetailsRow>
                    <S.BoxDetailsLabel>Time left</S.BoxDetailsLabel>
                    <S.BoxDetailsValue>{getTimeUntil(vestingEndTime)}</S.BoxDetailsValue>
                </S.BoxDetailsRow>
                <S.CustomHrDiv />
                <S.BoxDetailsRow>
                    <S.BoxDetailsLabel>Start time</S.BoxDetailsLabel>
                    <S.BoxDetailsValue>{timestampToDate((vestingStartTime).toString())}</S.BoxDetailsValue>
                </S.BoxDetailsRow>
                <S.CustomHrDiv />
                <S.BoxDetailsRow>
                    <S.BoxDetailsLabel>End time</S.BoxDetailsLabel>
                    <S.BoxDetailsValue>{timestampToDate(vestingEndTime.toString())}</S.BoxDetailsValue>
                </S.BoxDetailsRow>
            </S.BoxDetailsWrapper>
        </S.BoxWrapper>
    )
}
import React from "react";
import styled from "styled-components";

export const CopyIcon = () => {
  return (
    <StyledCopyIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3983_240)">
        <path
          d="M5.83333 5.00008V2.50008C5.83333 2.27907 5.92113 2.06711 6.07741 1.91083C6.23369 1.75455 6.44565 1.66675 6.66667 1.66675H16.6667C16.8877 1.66675 17.0996 1.75455 17.2559 1.91083C17.4122 2.06711 17.5 2.27907 17.5 2.50008V14.1667C17.5 14.3878 17.4122 14.5997 17.2559 14.756C17.0996 14.9123 16.8877 15.0001 16.6667 15.0001H14.1667V17.5001C14.1667 17.9601 13.7917 18.3334 13.3275 18.3334H3.33917C3.22927 18.3341 3.12033 18.313 3.0186 18.2714C2.91687 18.2299 2.82436 18.1686 2.74638 18.0912C2.6684 18.0137 2.60649 17.9216 2.56421 17.8202C2.52193 17.7188 2.50011 17.61 2.5 17.5001L2.5025 5.83342C2.5025 5.37341 2.8775 5.00008 3.34167 5.00008H5.83333ZM4.16917 6.66675L4.16667 16.6667H12.5V6.66675H4.16917ZM7.5 5.00008H14.1667V13.3334H15.8333V3.33341H7.5V5.00008Z"
          fill="url(#paint0_linear_3983_240)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3983_240"
          x1="2.63125"
          y1="1.66675"
          x2="17.6151"
          y2="1.77091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
        <clipPath id="clip0_3983_240">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </StyledCopyIcon>
  );
};

const StyledCopyIcon = styled.svg`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
`;

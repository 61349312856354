import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  table-layout: fixed;

  th {
    padding-bottom: 5px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7D7D97;

    & > svg {
      margin-left: 8px;
    }
  }

  th:first-child {
    min-width: 80px;
    width: 10%;
    // text-align: center;
    padding-left: 72px;
  }

  th:nth-child(2) {
    min-width: 64px;
    width: 7%;
  }

//   th:nth-child(3) {
//     min-width: 10px;
//     width: 8%;
//   }

  th:nth-child(3) {
    min-width: 64px;
    width: 6%;
  }

  th:nth-child(4) {
    min-width: 100px;
    width: 5%;
    text-align: center;
    padding-right: 20px;
  }

  th:last-child {
    text-align: left;
    width: 2%;
  }

  td {
    border-top: 1px solid #1E2239;
    border-bottom: 1px solid #1E2239;
    background: #151530;
  }

  td:first-child {
    border-left: 1px solid #1E2239;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-right: 1px solid #1E2239;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const TableRow = styled.tr`
  /* z-index: 10;
  position: relative; */

  &.expanded:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  td {
    padding: 26px 0 22px;
    vertical-align: middle;
  }

  td:last-child {
    transition: padding-right 0.2s ease-in-out;
    padding-right: 24px;
  }

  &.expanded td {
    border-bottom: none;
  }

  &.expanded td:first-child {
    border-bottom-left-radius: 0;
  }

  &.expanded td:last-child {
    border-bottom-right-radius: 0;
  }

  &.active {
    td {
      background: #0A0E27 !important;
      border-color: #00bdff !important;
      border-left-color: #00bdff !important;
      border-right-color: #00bdff !important;
    }
  }

  &.hoverable:hover td {
    cursor: pointer;
    border-color: #2C5173;
  }

  // &.hoverable:hover td:last-child {
  //   button {
  //     transition: all 0.3s;
  //     transform: translateX(5px);
  //   }
  // }

  &.expanded:hover td {
    cursor: pointer;
    border-color: #2C5173;
  }
  &.expanded:hover td:last-child {
    padding-right: 23px;
  }
`;

export const TableSubRow = styled.tr`
  //transform: translateY(-10px);
  position: relative;
  top: -10px;

  td {
    background: #10102a;
  }

  & td:first-child {
    border-top-left-radius: 0;
  }

  & td:last-child {
    border-top-right-radius: 0;
    padding-right: 0 !important;
    text-align: left !important; 
  }
`;



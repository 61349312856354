import React, { useEffect, useState, useCallback, useContext } from "react";
import { useParams } from "react-router";
import { Box } from "@/components/Layout/Box";
import { Content } from "@/components/Layout/Content";
import { HISTORY_API } from "@/constants/urls";
import { useWidthBreakpoint } from "@/hooks";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { addTransaction, addTransactionCount } from "@/store/transactionsSlice";
import { breakpoints, Media } from "@/styles";
import styled, { css } from "styled-components";
import { TransactionTable } from "../../components/Table/TransactionTable/TransactionTable";
import { useSearch } from "@/hooks/useSearch";
import { SearchInput } from "@/components/TokensModal/SearchInput";
import { BackArrowIcon } from "@/components/Icons/BackArrowIcon";
import { TransactionTale } from "@/components/Table/TransactionTable/TransactionTale";
import { useNavigationHelpers } from "@/hooks/navigation";
import { Pagination } from "@/components/Table/TransactionTable/Pagination";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { defaultChain } from "@/placeholders/chains";

const skipInterval = 10;

export const buildTransactions = (data: any, hashes: string[]) => {
  const txs: {
    transactionHash: string;
    input: { tokens: any; amounts: any };
    output: { tokens: any; amounts: any };
    arrowType: string;
    timestamp: number;
  }[] = [];

  for (let i = 0; i < hashes.length; i++) {
    try {
      const transactionHash = hashes[i];

      let arrowType;

      if (data[transactionHash].inputTokens.length > data[transactionHash].outputTokens.length) {
        arrowType = "merge";
      } else if (data[transactionHash].inputTokens.length < data[transactionHash].outputTokens.length) {
        arrowType = "split";
      } else {
        arrowType = "single";
      }

      const transaction = {
        transactionHash: transactionHash,
        input: {
          tokens: data[transactionHash].inputTokens,
          amounts: data[transactionHash].inputAmounts,
          chain: data[transactionHash].srcChain ?? "",
        },
        output: {
          tokens: data[transactionHash].outputTokens,
          amounts: data[transactionHash].outputAmounts,
          chain: data[transactionHash].destChain ?? "",
        },
        arrowType: arrowType,
        timestamp: Number(data[transactionHash]["timestamp"]),
        clickLink: transactionHash,
        tokenIDs: data[transactionHash].inputTokens.concat(data[transactionHash].outputTokens),
      };

      txs.push(transaction);
    } catch (error) {
      continue;
    }
  }
  return txs;
};

export const TransactionHistoryScreen = () => {
  const { hash } = useParams();
  const { handleGoBack } = useNavigationHelpers();
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const { connectedChain } = useContext(ChainContext);

  const [transactions, setTransactionData] = useState<any[]>([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [transactionSkip, setTransactionSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [transactionSearchValue, setTransactionSearchValue] = useState("");

  const [version, setVersion] = useState("v3");

  const storedTxs = useAppSelector((state) => state.transactions.transactions[connectedChain.name]);
  const storedTxCounts = useAppSelector((state) => state.transactions.transactionCounts[connectedChain.name]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchTransactions = useSearch(transactions, ["transactionHash", "tokenIDs"], transactionSearchValue);

  const hashVersion = hash + version;

  const queryTransactions = async () => {
    if (storedTxs[hashVersion] && storedTxs[hashVersion][transactionSkip] && storedTxCounts[hashVersion]) {
      return {
        count: storedTxCounts[hashVersion],
        data: storedTxs[hashVersion][transactionSkip],
      };
    }

    const apiData = await fetch(`${HISTORY_API(connectedChain)}${version}/address/${hash == "all" ? "all" : hash}/${transactionSkip}`).then((response) =>
      response.json()
    );

    const transactionData: any = apiData.transactions.data;
    const transactionHashes: string[] = Object.keys(transactionData);
    //   setTransactionCount(apiData.transactions.count)

    dispatch(addTransactionCount({ chain: connectedChain.name, address: hashVersion, count: apiData.transactions.count }));

    const txs = buildTransactions(transactionData, transactionHashes);

    dispatch(addTransaction({ chain: connectedChain.name, address: hashVersion, skip: transactionSkip, data: txs }));
    //   setTransactionData(txs)

    return {
      count: apiData.transactions.count,
      data: txs,
    };
  };

  const handleSearchEnter = useCallback(() => {
    const valueToSearch = transactionSearchValue;
    if (!valueToSearch.trim() || ethers.utils.isAddress(valueToSearch) || !valueToSearch.startsWith("0x")) {
      return;
    }

    navigate(`/tx/${valueToSearch}`);
  }, [transactionSearchValue, navigate]);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  //   if (storedTxs[hashVersion] && storedTxs[hashVersion][transactionSkip] && storedTxCounts[hashVersion]) {
  //     setTransactionData(storedTxs[hashVersion][transactionSkip])
  //     setTransactionCount(storedTxCounts[hashVersion])
  //     setIsLoading(false)
  //   } else {
  //     setIsLoading(true);
  //     queryTransactions().then(() => {
  //       setIsLoading(false)
  //     }).catch((_) => {
  //       setIsLoading(false)
  //     })
  //   }
  // }, [transactionSkip, version, connectedChain])

  useEffect(() => {
    setIsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      const transactions = await queryTransactions();
      if (signal.aborted) return;

      setTransactionCount(transactions.count);
      setTransactionData(transactions.data);
      setIsLoading(false);
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [transactionSkip, version, connectedChain]);

  useEffect(() => {
    setTransactionCount(0);
    setTransactionSkip(0);
    setCurrentPage("1");
    if (version == "v2" && connectedChain.name !== defaultChain.name) setVersion("v3");
  }, [connectedChain]);

  const isSearchActive = transactionSearchValue.length > 0;
  return (
    <Content>
      <View>
        <Title onClick={() => handleGoBack()}>
          <BackArrowIcon /> {hash ? (hash === "all" ? "Latest Transactions" : "Transaction History") : null}
        </Title>
        {hash && hash !== "all" && (
          <ButtonsContainer>
            <VersionButton onClick={() => setVersion("v3")} active={version === "v3"}>
              Shell v3
            </VersionButton>
            {connectedChain.name == defaultChain.name && (
              <VersionButton onClick={() => setVersion("v2")} active={version === "v2"}>
                Shell v2
              </VersionButton>
            )}
            <VersionButton onClick={() => setVersion("bridge")} active={version === "bridge"}>
              Bridge
            </VersionButton>
          </ButtonsContainer>
        )}

        <>
          <StyledSearchContainer>
            <SearchInput
              value={transactionSearchValue}
              onChange={(e) => setTransactionSearchValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSearchEnter()}
              placeholder="Type transaction hash, token"
            />
          </StyledSearchContainer>

          <>
            {isTablet ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                {isLoading
                  ? Array.from({ length: 4 }, (_, index) => <SkeletonBox key={index} isLoading={true} height="150px" width="100%" borderRadius="16px" />)
                  : searchTransactions.map((transaction) => <TransactionTale transaction={transaction} />)}
              </div>
            ) : (
              <>
                <TransactionTable data={searchTransactions} isLoading={isLoading} txHistory={true} />
              </>
            )}
          </>
          {!isLoading && searchTransactions.length === 0 && <TransactionScreenPlaceholder>No transactions found.</TransactionScreenPlaceholder>}
          <PaginationContainer>
            <Pagination
              pagesLength={Math.ceil(transactionCount / skipInterval)}
              currentPage={parseInt(currentPage)}
              pageSize={skipInterval}
              objectsLength={isSearchActive ? searchTransactions.length : transactionCount}
              isFirstPage={transactionSkip === 0}
              isLastPage={transactionSkip + skipInterval >= transactionCount}
              handleSetNextPage={() => {
                setCurrentPage((parseInt(currentPage) + 1).toString());
                setTransactionSkip(transactionSkip + skipInterval);
              }}
              handleSetPrevPage={() => {
                setCurrentPage((parseInt(currentPage) - 1).toString());
                setTransactionSkip(transactionSkip - skipInterval);
              }}
            />
          </PaginationContainer>
        </>
      </View>
    </Content>
  );
};

const View = styled(Box)`
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;

  ${Media.tablet} {
    padding: 8px 8px 12px;
  }
`;

const TransactionScreenPlaceholder = styled.div`
  width: 100%;
  height: 280px;
  text-align: center;
  vertical-align: middle;
  line-height: 280px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;

  ${Media.tablet} {
    font-size: 20px;
    gap: 8px;
  }
`;

const StyledSearchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;

  ${Media.tablet} {
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  margin-top: 8px;
`;

const PageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const inputTextStyles = css`
  font-size: 16px;
  line-height: 19px;
`;

const placeholderStyles = css`
  ${inputTextStyles}
`;

export const PageInput = styled.input<{ count: number }>`
  color: #ffffff;
  text-align: center;
  border: none;
  background: transparent;
  outline: none;
  font-weight: 100;

  ${inputTextStyles}

  &:focus {
    border-color: #ffffff;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }

  ${({ count }) =>
    count < 10 * skipInterval &&
    css`
      width: 16px;
    `};

  ${({ count }) =>
    count >= 10 * skipInterval &&
    count < 100 * skipInterval &&
    css`
      width: 24px;
    `};
`;

const DirectionButton = styled.button`
  width: 34px;
  height: 28px;
  font-weight: 100;
  font-size: 16px;
  line-height: 22px;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #37dcf2;

  &:not(:disabled):hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    color: #0a0e27;
  }

  &:disabled {
    border: 1px solid #464659;
    color: #464659;
    cursor: auto;
  }

  ${Media.tablet} {
    width: 28px;
    border: none !important;
    &:hover {
      background: transparent !important;
      border: none !important;
      color: #464659;
    }
  }
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding-top: 300px;
  padding-bottom: 300px;
`;

const PaginationContainer = styled.div`
  margin-top: 24px;
  &:empty {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const VersionButton = styled.div<{ active?: boolean }>`
  margin-top: 20px;
  padding: 16px 24px;
  color: #7d7d97;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 16px;
  border: 2px solid #292941;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    font-weight: 600;
    background: #171b33;
    border-color: #171b33;
  }
  ${({ active }) =>
    active &&
    css`
      color: #fff;
      font-weight: 600;
      background: #171b33;
      border-color: #171b33;
    `};
`;

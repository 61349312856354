import React, { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NFT, NFT1155, isNFTCollection } from "../../../../../utils/tokens";
import { StraightLoader } from "../../../../../components/Loaders";
import { BigNumber } from "ethers";
import { OCEAN_ADDRESS } from "../../../../../constants/addresses";
import { addNFTBalance } from "../../../../../store/balancesSlice";
import { getNFTs, buildNFTDisplays, build1155Displays } from "../../../../../utils/nftHelpers";
import { removeLeadingZeros } from "../../../../../utils/ocean/utils";
import { NFTsSwiper } from "../../../../Trade/NFTsSwiper/NFTsSwiper";
import { useAccount } from "wagmi";
import { WRAPPED_NFT_MAP } from "../../../../../utils/nftHelpers";
import { TransferToken } from "./TransferToken";
import { getTokenID } from "../../../../../utils/LiquidityGraph";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TableSubRowProps {
  row: any;
  walletAddress: string;
}

export const NFTSubRow = React.memo(({ row, walletAddress}: TableSubRowProps) => {
  const { original } = row;
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { tokenMap, poolQuery } = useContext(ChainContext)

  const [nfts, setNFTs] = useState<(NFT | NFT1155)[]>([]);
  const nft: any = tokenMap[original.name];

  useEffect(() => {
    let isMounted = true;
    const fetchNFTs = async () => {
      if (walletAddress) {
        
        const userOceanNFTs = (await getNFTs(walletAddress ?? '0xNull', OCEAN_ADDRESS))

        const collection = tokenMap[original.name]

        const wrappedIDs = WRAPPED_NFT_MAP[original.name]

        const userWrappedNFTs : any[] = []

        if(isNFTCollection(collection) && collection.is1155){
            userOceanNFTs.forEach((userOceanNFT : any) => {
                const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
                if(wrappedIDs[oceanID]) {
                    userWrappedNFTs.push({
                        id: parseInt(wrappedIDs[oceanID][0]),
                        balance: parseInt(userOceanNFT.balance)
                    })
                }    
            })

            const nonZeroBalanceIDs = new Set(userWrappedNFTs.map((nft) => nft.id))
            Array.from(Array(collection.totalSupply).keys()).forEach((id) => {
                if(!nonZeroBalanceIDs.has(id)){
                    userWrappedNFTs.push({
                        id: id,
                        balance: 0
                    })
                }
            })

            dispatch(
                addNFTBalance({
                    collection: collection.symbol,
                    items: userWrappedNFTs,
                })
            );
            setNFTs(build1155Displays(collection, userWrappedNFTs).filter(item => item.balance > 0))

        } else {
            userOceanNFTs.forEach((userOceanNFT : any) => {
                const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
                if(wrappedIDs[oceanID]) userWrappedNFTs.push(parseInt(wrappedIDs[oceanID][0]))
            })

            dispatch(
                addNFTBalance({
                    collection: collection.symbol,
                    items: userWrappedNFTs,
                })
            );
            // @ts-ignore
            setNFTs(await buildNFTDisplays(collection, userWrappedNFTs))
        }
      }
    };

    setLoading(true);

    fetchNFTs().then(() => {
      if (isMounted) setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [walletAddress, nfts.length]);

  return loading ? (
    <div style={{ display: "flex", height: "48px", justifyContent: "center", alignItems: "center", }} >
      <StraightLoader />
    </div>
  ) : (
    <>
      {nfts.length > 0 && 
      <Main>
          <NFTsSwiper maxWidth={"876"} nftTokens={nfts} />
      </Main>
      }
      <Footer>
        <FooterRow>
          <ButtonRow>
            <BuyButton to="/trade" state={poolQuery.generateBuySellTokens(nft, true)} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}> Buy </BuyButton>
            <SellButton to="/trade" state={poolQuery.generateBuySellTokens(nft, false)} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}> Sell </SellButton>
            {nfts.length > 0 && ( 
              <TransferToken 
                collection={nft} 
                balance={nfts.length} 
                wallet={original.wallet} 
                nfts={nft.is1155 ? nfts : undefined} 
                isNFT={true} 
                is1155={nft.is1155}
              /> 
            )}
          </ButtonRow>
        </FooterRow>
      </Footer>
    </>
  );
});

const Main = styled.div`
  padding-top: 18px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 13px;
  width: calc(100vw - 71px);
  max-width: 876px;
`;
const TokenName = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #a9d1e2;
`;

const Footer = styled.div`
  padding: 16px 20px;
  border-top: 1px solid rgb(30, 34, 57);
`;

const FooterRow = styled.div`
  display: flex;
  align-items: row;
  justify-content: flex-end;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const TradeButton = styled(Link)`
  width: 120px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  border-radius: 16px;
  text-align: center;
`;

const BuyButton = styled(TradeButton)`
  color: #0a0e27;
  background: #37dcf2;
  //margin-left: 12px;
`;

const SellButton = styled(TradeButton)`
  color: #ffffff;
  border: 2px solid #37dcf2;
`;
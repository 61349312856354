export const tokenColors: Record<string, string> = {
  USDT: '#26A17B',
  DAI: '#F3B631',
  'USDC.e': '#3E73C4',
  WBTC: '#F09242',
  ETH: '#687ce3',
  wstETH: '#48A0F8',
  TOUCAN: '#AFF8FA',
  ARB: '#2f3740',
  MAGIC: '#CA3A31',
  SMOL: '#7752DE',
  COLLAB: '#F6C349',
  STG: '#666666',
  REUNI: '#CC8CC0',
  SHELL: '#F7C94F',
  BKB: '#EFA05B',
  ACCT: '#EFA05B',
  WETH: '#7B7B7B',
  rETH: '#F19C7A',
  USDC: '#3E73C4',
  crvUSD: '#44714D',
  MIM: '#9795F1',
  FRAX: '#FFFFFF',
  STREAM: '#C96D37',
  ezETH: '#9BC047'
};

const tokenNames = Object.keys(tokenColors)

tokenNames.forEach((name) => {
    tokenColors['sh' + name] = tokenColors[name]
})

tokenColors['TOUCOIN'] = '#D6B974'
tokenColors['SMOLCOIN'] = '#7752DE'
tokenColors['lbp'] = '#00BDFF'
tokenColors['veSHELL'] = '#FFFFFF'
tokenColors['strSHELL'] = '#FFFFFF'
tokenColors['shLP'] = '#0ABCFD'

import React from "react";

export const TwitterIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2165 5.00016C18.5749 5.29183 17.8832 5.4835 17.1665 5.57516C17.8999 5.1335 18.4665 4.4335 18.7332 3.59183C18.0415 4.0085 17.2749 4.30016 16.4665 4.46683C15.8082 3.75016 14.8832 3.3335 13.8332 3.3335C11.8749 3.3335 10.2749 4.9335 10.2749 6.9085C10.2749 7.19183 10.3082 7.46683 10.3665 7.72516C7.39987 7.57516 4.7582 6.15016 2.99987 3.99183C2.69154 4.51683 2.51654 5.1335 2.51654 5.7835C2.51654 7.02516 3.14154 8.12516 4.1082 8.75016C3.51654 8.75016 2.96654 8.5835 2.4832 8.3335V8.3585C2.4832 10.0918 3.71654 11.5418 5.34987 11.8668C4.82547 12.0103 4.27495 12.0303 3.74154 11.9252C3.96787 12.6356 4.41115 13.2572 5.00905 13.7026C5.60695 14.148 6.32941 14.3949 7.07487 14.4085C5.81122 15.4089 4.24486 15.9496 2.6332 15.9418C2.34987 15.9418 2.06654 15.9252 1.7832 15.8918C3.36654 16.9085 5.24987 17.5002 7.26653 17.5002C13.8332 17.5002 17.4415 12.0502 17.4415 7.32516C17.4415 7.16683 17.4415 7.01683 17.4332 6.8585C18.1332 6.3585 18.7332 5.72516 19.2165 5.00016Z"
        fill="url(#paint0_linear_3983_177)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3983_177"
          x1="1.93574"
          y1="3.3335"
          x2="19.3496"
          y2="3.49902"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React, { ReactNode, useEffect, useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import cross from "../../assets/icons/cross.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import { Media } from "../../styles";
import {
  TokensModalHeader,
  Title,
} from "../../components/TokensModal/TokensModal";
import { scrollbar } from "../../styles/scrollbar";

export interface BasicModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export interface ModalProps extends BasicModalProps {
  dataTestId?: string;
  children: ReactNode;
  title?: string;
  header?: ReactNode;
  className?: string;
  maxWidth?: string;
  hideCloseButton?: boolean;
  button?: ReactNode;
  withoutContentScroll?: boolean;
  isConfirmation?: boolean;
  isWrappedByCollabAirdropBanner?: boolean;
}

export const Modal = ({
  dataTestId,
  isVisible,
  onClose,
  children,
  title,
  header,
  className,
  maxWidth,
  hideCloseButton,
  button,
  withoutContentScroll,
  isConfirmation,
  isWrappedByCollabAirdropBanner,
}: ModalProps) => {
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    const focusedElementBeforeModal = document.activeElement;
    if (closeButtonRef.current) closeButtonRef.current.focus();
    return () => (focusedElementBeforeModal as HTMLElement).focus();
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    }
    
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);
  
  const modalRef = useRef<HTMLDivElement | null>(null);
  const handleFocusTrap = useCallback(
    (event: KeyboardEvent) => {
      if (isVisible && modalRef.current) {
        const focusableElements = Array.from(
          modalRef.current.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          )
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        const focusedElement = document.activeElement;

        if (event.shiftKey && focusedElement === firstElement) {
          (lastElement as HTMLElement).focus();
          event.preventDefault();
        }
        if (!event.shiftKey && focusedElement === lastElement) {
          (firstElement as HTMLElement).focus();
          event.preventDefault();
        }
      }
    },
    [isVisible, modalRef]
  );

  useEffect(() => {
    const keyListenersMap = new Map([
      ["Escape", onClose],
      ["Tab", handleFocusTrap],
    ]);

    const keyListener = (event: KeyboardEvent) => {
      const listener = keyListenersMap.get(event.key);
      return listener && listener(event);
    };

    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  }, [onClose, handleFocusTrap, isVisible]);

  if (!isVisible) return null;

  return createPortal(
    <ModalView data-testid={dataTestId}>
      <ModalOverlay
        data-testid="modal-overlay"
        isConfirmation={isConfirmation ? true : false}
        onClick={onClose}
      />
      <ModalBody data-testid="modal-body" ref={modalRef} className={className} maxWidth={maxWidth}>
      <ModalHeader data-testid="modal-header" hasBanner={isWrappedByCollabAirdropBanner}>
          {isConfirmation ? (
            <TokensModalHeader>
              <BackButton data-testid="modal-back-btn" ref={closeButtonRef} onClick={onClose}>
                <img src={arrowLeft} alt="Back button" />
              </BackButton>
              {title && <ModalTitle>{title}</ModalTitle>}
            </TokensModalHeader>
          ) : (
            title && <ModalTitle>{title}</ModalTitle>
          )}
          {!hideCloseButton && !isConfirmation && (
            <CloseButton
              data-testid="modal-close-btn"
              ref={closeButtonRef}
              onClick={onClose}
              aria-label="Close"
            >
              <img src={cross} alt="Close" />
            </CloseButton>
          )}
        </ModalHeader>
        {header}
        <ModalContent withoutContentScroll={withoutContentScroll}>
          {children}
        </ModalContent>
        {button ? button : null}
      </ModalBody>
    </ModalView>,
    document.body
  );
};

const ModalView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const ModalBody = styled.div<{ maxWidth: string | undefined }>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  ${({ maxWidth }) =>
    `
  max-width: ${maxWidth || "430"}px;
  `}

  height: 622px;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background: #0a0e27;
  border-radius: 20px;

  ${Media.mobile} {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 20px 16px 24px;
    border-radius: 0;
  }
`;

export const ModalContent = styled.div<{ withoutContentScroll?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  background: #0a0e27;

  overflow-y: ${({ withoutContentScroll }) => withoutContentScroll ? "hidden" : "auto"};
  ${scrollbar()};
`;

const ModalHeader = styled.div<{ hasBanner?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ hasBanner }) => hasBanner ? "24px" : "0"};
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const ModalOverlay = styled.div<{ isConfirmation: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background:rgba(26, 33, 73, 0.8);
  backdrop-filter: blur(17px);

  ${({ isConfirmation }) => isConfirmation && `
    opacity: 0
  `};
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  margin-left: auto;
  background: #171b33;
  border-radius: 6px;
`;

const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
`;
import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { TokenLogo } from "@/components/TokenLogo";
// import { ToucansLogo } from '../../../../components/TokenLogo';
import { tokenLogos } from "@/constants/tokenLogos";
import { extract1155Data } from "@/utils/nftHelpers";
import { itemsMap } from "@/pages/Booty/Items";
import { isExternalToken } from "@/utils/tokens";
import { tokenColors } from "@/constants/tokenColors";
import { DexBadge } from "@/components/DexBadge/DexBadge";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TokenPreviewProps {
  row: any;
}

export const PointsTokenPreviewCell = ({ row }: TokenPreviewProps) => {
  const { tokenMap } = useContext(ChainContext)
  const { original } = row;
  let { name, subname, color } = original;

  let LogoIcon = tokenLogos[name] ? tokenLogos[name] : Logo;

  const data1155 = extract1155Data(name)

  let image1155

  if(data1155){
    const item = itemsMap[parseInt(data1155.id)]
    name = item.name
    subname = tokenMap[data1155.symbol].name
    color = tokenColors[data1155.symbol]
    image1155 = `../../assets/booty/${item.image}.jpg`
  }

  let protocol, protocolVersion

  if(isExternalToken(tokenMap[name])){
    // @ts-ignore
    protocol = tokenMap[name].tokenType
    // @ts-ignore
    protocolVersion = tokenMap[name].protocolVersion ?? ''
  }

  return (
    <Row>
      {data1155 ? <NFTLogo src={image1155} nftType="1155"/> : <LogoIcon size={20} />}
      <div>
        {original.name && <TokenName textLength={name.length}>{name}</TokenName>}
        <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
            <Tag color={color}>{subname}</Tag>
            {protocol && <DexBadge protocol={protocol} protocolVersion={protocolVersion}/>}
        </div>
      </div>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  > img {
    width: 40px; 
    height: 40px;
  }
`;

const Logo = styled(TokenLogo)`
  margin-right: 18px;
`;

const TokenName = styled.p<{textLength? : number}>`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;

  ${({ textLength }) =>
    textLength &&
    textLength > 18 &&
    css`
      font-size: 18px;
    `};
`;

const Tag = styled.p<{ color?: string }>`
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => color ?? "#A9D1E2"};
  font-variant-ligatures: none;
`;

const NFTLogo = styled.img<{nftType?: string}>`
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 20px;
  overflow: hidden;

  ${({ nftType }) => nftType == '721' && css`border-radius: 0`}
  ${({ nftType }) => nftType == '1155' && css`border-radius: 8px`}

`;

import React from "react";
import styled from "styled-components";
import { AreaChart } from "recharts";
import { Media } from "../../../../../styles";

const StyledAreaChartWrapper = styled.div`
  .recharts-xAxis {
    transform: translateY(8px);
  }

  tspan {
    font-size: 12px;
    line-height: 14px;
  }

  ${Media.tablet} {
    .recharts-xAxis {
      transform: translateY(0px);
    }
  }
`;

const CustomAreaChart = (props: any) => {
  return (
    <StyledAreaChartWrapper>
      <AreaChart {...props} />
    </StyledAreaChartWrapper>
  );
};

export default CustomAreaChart;

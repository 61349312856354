import { CustomTooltip } from "@/components/CustomTooltip/CustomTooltip";
import { add, format } from "date-fns";
import React from "react";
import { useRewardsContext } from "../../RewardsContext";
import { lockPeriods } from "../../hooks/useStakingHandler";
import { LockPeriodBox } from "../Staking/components/LockPeriodBox";
import * as S from "./ConfirmationModals.styled";
import arrowRight from "@/assets/icons/arrow-right-line.svg";
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";
import darkenShellLogo from "@/assets/coins/shell-coin-darken-logo.svg";
import lighterShellLogo from "@/assets/coins/shell-coin-lighter-logo.svg";

export const IncreaseLockPeriodModalBody = () => {
    const { savedLockPeriod, handleSelectLockPeriod, currentLockPeriod, getTimeUntil, handleIncreaseLockPeriod, getUnlockTime, projectedVeShell, myStaked, projectedEndTime } = useRewardsContext();
    const { APY, label, endTime } = { ...savedLockPeriod };

    return savedLockPeriod ? (
      <S.BodyContainer>
        <S.ModalTitle>Increase lock period</S.ModalTitle>
        <S.IncreaseLockPeriodInfoBox>
          <S.LockPeriodInfoText>Time left to unlock</S.LockPeriodInfoText>
          <S.LockInfoWrapper>
            <S.LockPeriod>{getTimeUntil(endTime!)}</S.LockPeriod>
            <S.LockPeriodInfoText>{APY!} % APY</S.LockPeriodInfoText>
          </S.LockInfoWrapper>
        </S.IncreaseLockPeriodInfoBox>
        <S.LockPeriodBoxesWrapper>
          {lockPeriods.map((lockPeriod, index) => (
            <LockPeriodBox
              disabled={getUnlockTime(lockPeriod.label, savedLockPeriod?.endTime) >= getUnlockTime('~2Y')}
              key={`${lockPeriod.label}-${index}`}
              onSelect={() => handleSelectLockPeriod(lockPeriod)}
              selected={currentLockPeriod.label === lockPeriod.label}
              isDefault={false}
              lockPeriod={lockPeriod}
            />
          ))}
        </S.LockPeriodBoxesWrapper>
        <S.GeneralStakeInfoContainer>
        <S.GeneralSingleInfoWrapper style={{ paddingBottom: "12px" }}>
            <S.GeneralSingleLeftSide>
              <S.GeneralInfoLabel>Voting Power increase</S.GeneralInfoLabel>
              <CustomTooltip
                position="top"
                title="Voting Power"
                subtext="The amount of veSHELL you have represents your current voting strength in governance. Your voting power decreases linearly over time, so consider restaking periodically to maintain it."
                delay={50}
              />
            </S.GeneralSingleLeftSide>
            <S.GeneralSingleRightSide>
              <S.GeneralInfoValueWrapper>
                <S.ShellCoinImg
                  src={darkenShellLogo}
                  alt="shell coin logo"
                />
                <S.GeneralInfoGrayText>
                  {formatDisplay(myStaked.toString(), 2)}{" "}
                </S.GeneralInfoGrayText>
              </S.GeneralInfoValueWrapper>
              <S.ArrowImg src={arrowRight} alt="arrow right icon" />
              <S.GeneralInfoValueWrapper>
                <S.ShellCoinImg
                  src={lighterShellLogo}
                  alt="shell coin logo"
                />
                <S.GeneralInfoWhiteText>
                  {formatDisplay((myStaked + projectedVeShell).toString(), 2)}
                </S.GeneralInfoWhiteText>
              </S.GeneralInfoValueWrapper>
            </S.GeneralSingleRightSide>
          </S.GeneralSingleInfoWrapper>
          <S.GeneralSingleInfoWrapper style={{ paddingTop: "12px", borderBottom: 'none' }}>
            <S.GeneralSingleLeftSide>
              <S.GeneralInfoLabel>Unlock time change</S.GeneralInfoLabel>
              <CustomTooltip
                position="top"
                title="Unlock Time"
                subtext="Shows when your staked tokens will be available to claim. A longer period boosts both your voting power and rewards."
                delay={50}
              />        
            </S.GeneralSingleLeftSide>
            <S.GeneralSingleRightSide>
              <S.GeneralInfoGrayText>
                {timestampToDate(savedLockPeriod.endTime.toString())}
              </S.GeneralInfoGrayText>
              <S.ArrowImg src={arrowRight} alt="arrow right icon" />
              <S.GeneralInfoWhiteText>
                {timestampToDate(projectedEndTime.toString())}
              </S.GeneralInfoWhiteText>
            </S.GeneralSingleRightSide>
          </S.GeneralSingleInfoWrapper>
        </S.GeneralStakeInfoContainer>
        <S.CustomPrimaryBtnStake onClick={handleIncreaseLockPeriod}>
          Increase
        </S.CustomPrimaryBtnStake>
      </S.BodyContainer>
    ) : null;
}
import React from "react";
import { useRewardsContext } from "../../RewardsContext";
import * as S from "./ConfirmationModals.styled";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import mockNFT from "@/assets/icons/stream.svg";
import infoIcon from "@/assets/icons/info-icon.svg";
import shellIcon from '@/assets/coins/SHELL.svg'
import { formatDisplay } from "@/utils/formatDisplay";

export const ClaimAirDropConfirmation = () => {
    const { isAirDropClaimConfirmed, handleRedirectToVesting, airdropShellAmount, claimedStreamId, airdropTxSubmitted, handleCloseAirDropClaimConfirmationModal } = useRewardsContext();

    return (
      <S.ConfirmationBodyContainer hasMask={isAirDropClaimConfirmed}>
        {isAirDropClaimConfirmed ? (
          <>
            <S.SuccessIcon src={successIcon} alt="success icon" />
            <S.SuccessTextWrapper>
              <S.SuccessTitle>Claim successful!</S.SuccessTitle>
              <S.SuccessDescription>
                Your Shellstream is in your wallet. Check its details in your
                vesting panel.
              </S.SuccessDescription>
            </S.SuccessTextWrapper>
            <S.StreamPreview>
              <S.ClaimAirDropDetailsBoxInfo>
                <S.ImageNFT src={mockNFT} alt="nft image" />
                <S.LabelNFT style={{fontSize: '16px'}}>Shellstream #{claimedStreamId}</S.LabelNFT>
              </S.ClaimAirDropDetailsBoxInfo>
              <S.SideWrapper>
                <S.SmallCoinIcon src={shellIcon} alt="shell coin" />
                <S.DetailsValue>
                  {formatDisplay(airdropShellAmount.toString(), 2) ?? 0} SHELL
                </S.DetailsValue>
              </S.SideWrapper>
            </S.StreamPreview>
            <S.ButtonsWrapper>
              <S.CustomPrimaryBtn
                onClick={() => {
                  handleCloseAirDropClaimConfirmationModal();
                  handleRedirectToVesting();
                }}
              >
                View vesting
              </S.CustomPrimaryBtn>
            </S.ButtonsWrapper>
          </>
        ) : (
          <>
            <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
            <S.LoaderLabelContainer>
              <S.LoaderLabel>Claiming your airdrop...</S.LoaderLabel>
              <S.LoaderSublabel>
                You're claiming a Shellstream with{" "}
                {formatDisplay(airdropShellAmount.toString())} SHELL
              </S.LoaderSublabel>
            </S.LoaderLabelContainer>
            <S.StreamPreview>
              <S.ClaimAirDropDetailsBoxInfo>
                <S.ImageNFT src={mockNFT} alt="nft image" />
                <S.LabelNFT>Shellstream</S.LabelNFT>
              </S.ClaimAirDropDetailsBoxInfo>
              <S.SideWrapper>
                <S.SmallCoinIcon src={shellIcon} alt="shell coin" />
                <S.DetailsValue>
                  {formatDisplay(airdropShellAmount.toString(), 2) ?? 0} SHELL
                </S.DetailsValue>
              </S.SideWrapper>
            </S.StreamPreview>
            {!airdropTxSubmitted && (
              <S.InfoLabelContainer>
                <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                <S.InfoLabel>
                  Confirm the transaction in your wallet.
                </S.InfoLabel>
              </S.InfoLabelContainer>
            )}
          </>
        )}
      </S.ConfirmationBodyContainer>
    );
}
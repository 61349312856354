import React from "react";
import styled from "styled-components";
import getStartedImage from "@/assets/icons/shellGuide/get-started.png";
import smallCheck from "@/assets/icons/shellGuide/check.svg";
import { ButtonPrimary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import { shellStargate } from "@/assets/icons/shellGuide/selectionModal";
import { scrollbar } from "@/styles/scrollbar"; 

interface StartedSectionProps {
    handleSetStarted: () => void;
}

export const StartedSection = ({ handleSetStarted }: StartedSectionProps) => {
    return (
        <GetStartedWrapper>
            <GetStartedImage src={shellStargate} alt="get-started" />
            <TitleDescWrapper>
                <Title>
                    Welcome in Shell Protocol!
                </Title>
                <Description>
                    Get the full DeFi experience in one dapp.
                </Description>
            </TitleDescWrapper>
            <PointLinesWrapper>
                    <PointLine>
                        <Point src={smallCheck} alt="check" />
                        <PointText>
                            Bridge, swap and provide liquidity in one transaction.
                        </PointText>
                    </PointLine>
                    <PointLine>
                        <Point src={smallCheck} alt="check" />
                        <PointText>
                            Explore new protocols, compare yields, and discover the best opportunities without ever leaving the dapp.
                        </PointText>
                    </PointLine>
                    <PointLine>
                        <Point src={smallCheck} alt="check" />
                        <PointText>
                            Say goodbye to all those extra browser tabs.
                        </PointText>
                    </PointLine>
                    {/* <PointLine>
                        <Point src={smallCheck} alt="check" />
                        <PointText>
                            It’s as if DeFi Llama and 1inch had a baby!
                        </PointText>
                    </PointLine> */}
                </PointLinesWrapper>
                <ButtonContainer>
                    <ButtonPrimaryCustom onClick={() => handleSetStarted()}>
                        Let's start
                    </ButtonPrimaryCustom>
                </ButtonContainer>
        </GetStartedWrapper>
    )
};

const GetStartedWrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 24px;
min-height: 616px;
max-height: 616px;

${Media.mobile} {
    min-height: calc(100vh - 142px);
    max-height: calc(100vh - 142px);
}
`;

const TitleDescWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
padding: 0 24px;
`;

const Title = styled.h2`
color: #FFF;

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;
`;

const Description = styled.p`
color: #7D7D97;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const PointLinesWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
padding: 0 24px;

${scrollbar()}

${Media.mobile} {
    padding-bottom: calc(24px + 104px);
    overflow-y: auto;
}
`;

const PointLine = styled.div`
display: flex;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
`;

const Point = styled.img`
width: 20px;
height: 20px;
`;

const PointText = styled.p`
color: var(--Pacifiic-Harbour, #A9D1E2);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 20.3px */
`;

const GetStartedImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;

  ${Media.mobile} {
    height: auto;
    max-height: unset;
    object-fit: contain;
    object-position: top;
  }
`;

const ButtonContainer = styled.div`
padding: 0 24px 24px 24px;
width: 100%;

${Media.mobile} {
    padding: 24px;
    position: fixed;
    bottom: 0;
    background: #0A0E27;
}
`;

const ButtonPrimaryCustom = styled(ButtonPrimary)`
border-radius: 12px;
height: 48px;
padding: 14.5px 20px;
text-wrap: nowrap;
width: 100%;
`;
import React, { InputHTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";
import * as S from "./Input.styled";
import "./Input.css"; 
import { DISPLAY_DECIMALS, formatDisplay } from "@/utils/formatDisplay";
import { useWidthBreakpoint } from "@/hooks";
import { breakpoints } from "@/styles";

type TransparentInputProps = {
    label: string;
    coinLogo: ReactNode;
    value: string;
    additionalActionButton?: ReactNode;
    additionalLabel?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

export const TransparentInput = ({ label, coinLogo, additionalLabel, additionalActionButton, value, ...rest }: TransparentInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [focused, setFocused] = useState(false);
    const isTablet = useWidthBreakpoint(breakpoints.tablet)
    
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setFocused(false);
        } else {
            setFocused(true);
        }
    };
    const handleFocus = () => setFocused(true);

    const handleClickOutside = (e: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
            setFocused(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <S.TransparentInputContainer>
            <S.TransparentInputLabelWrapper>
                <S.TransparentInputLabel htmlFor="first">{label}</S.TransparentInputLabel>
                {additionalLabel && additionalLabel}
            </S.TransparentInputLabelWrapper>
            <S.TransparentInputWrapper focused={focused}>
                {coinLogo}
                <S.CleaveStyled
                  placeholder="0.0"
                  value={formatDisplay(value)}
                  {...rest}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  focused={focused}
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    numeralDecimalScale: DISPLAY_DECIMALS,
                  }}
                  onKeyDown={(event : any) =>{ 
                        ["-"].includes(event.key) && event.preventDefault()
                        if (isTablet && [",", "٫"].includes(event.key)) {
                            event.preventDefault();
                            event.target.value += '.';
                        }
                    }
                  }
                  inputMode="decimal"
                  className={isTablet ? 'shrunk' : ''}
                />
                {additionalActionButton && (
                    <S.AdditionalButtonWrapper>
                        {additionalActionButton}
                    </S.AdditionalButtonWrapper>
                )}
            </S.TransparentInputWrapper>
        </S.TransparentInputContainer>
    )
}
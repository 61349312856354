import { Media } from "@/styles";
import React from "react";
import styled from "styled-components";
import { ChartButtons } from "./ChartButtons";

interface IChartHeaderProps {
  title: string;
  amount: string;
  withButtons?: boolean
  onClickButton?: (value: string) => void;
  isActive?: string
  percent?: string;
  icon?: React.ReactNode;
}

export const ChartHeader = ({
  title,
  amount,
  percent,
  icon,
  withButtons,
  isActive,
  onClickButton
}: IChartHeaderProps) => {
  return (
    <ChartHeaderContainer>
      <TitleContainer>
        {title}
        <PriceContainer>
          {icon ? icon : null}
          {amount}
          {percent ? <PriceBullet negative={percent?.charAt(0) === '-'}>{percent}</PriceBullet> : null}
        </PriceContainer>
      </TitleContainer>
      {withButtons ? <ChartButtons onClick={onClickButton} isActive={isActive} /> : null}
    </ChartHeaderContainer>
  );
};

const ChartHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  ${Media.tablet} {
    font-size: 18px;  
  }
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: #ffffff;

  & > img,
  & > svg {
    max-width: 100%;
    max-height: 100%;
    color: rgb(0, 189, 255);
  }

  ${Media.tablet} {
    font-size: 24px;

    & > img,
    & > svg {
      max-width: 24px;
      max-height: 24px;
    }
  }
`;

const PriceBullet = styled.div<{negative?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({negative}) => negative ? 'rgba(238, 59, 59, 0.15)' : '#1b2d3d'};
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 17px;
  color: ${({negative}) => negative ? 'rgba(238, 59, 59, 1)' : '#7adeb9'};
`;

import React from "react";
import styled from "styled-components";
import { isShellToken, isNFTCollection, isShellV2Token, Token, ShellToken, NFTCollection, LBPToken, ExternalToken, ExternalDefiToken } from "../utils/tokens";
import placeholderIcon from "../assets/coins/logo-placeholder.svg";
import { allChains } from "@/placeholders/chains";
import { veSHELL } from "@/placeholders/arbitrumTokens";

interface LogoProps {
  size?: number;
  src?: string;
  alt?: string;
  className?: string;
}

const Logo = styled.img<LogoProps>`
  max-width: 100%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const OverlapTokenLogo = styled.div<{ logo: string; overlapLogo: string }>`
  height: 83px;
  width: 83px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 6px;
    right: -6px;
    width: 24px;
    height: 24px;
    background: url(${({ overlapLogo }) => overlapLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    border: 2px solid #151530;
    border-radius: 50%;
  }
`;

const createTokenLogo = (token: any, iconSize: number) => {
  const tokenID = isShellToken(token) ? token.name : token.symbol;

  const logo = ({ alt = tokenID, className = "", src = token.icon, size = iconSize }: LogoProps) => (
    <Logo src={src} alt={alt} size={size} className={className} />
  );

  //   let logo
  //   if(token.tokenType == 'Beefy'){
  //     logo = ({
  //         alt = tokenID,
  //         className = "",
  //         src = token.icon,
  //         size = iconSize,
  //       }: LogoProps) => (
  //         <OverlapTokenLogo logo={tokenMap['ezETH-wstETH-BPT'].icon} overlapLogo={'https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png'} />
  //       );

  //   } else {

  //   logo = ({
  //     alt = tokenID,
  //     className = "",
  //     src = token.icon,
  //     size = iconSize,
  //   }: LogoProps) => (
  //     <Logo src={src} alt={alt} size={size} className={className} />
  //   );
  //   }

  return styled(logo)`
    margin-right: 18px;
    border-radius: ${isNFTCollection(token) ? "4px" : "50%"};
    width: ${isShellToken(token) || isNFTCollection(token) || isShellV2Token(token) || token.name === "veSHELL" ? "50px" : "inherit"};
  `;
};

const tokenLogos: Record<string, any> = {}

const chains = Object.keys(allChains);

for (let chain of chains) {
  const { tokens, shellTokens, nftCollections, lbpTokens, externalTokens, externalDefiTokens, tokenMap } = require(`../placeholders/${chain
    .split(" ")[0]
    .toLowerCase()}Tokens.ts`);

  tokens.forEach((token: Token) => {
    if (!tokenLogos[token.symbol]) tokenLogos[token.symbol] = createTokenLogo(token, 83);
  });

  shellTokens.forEach((shellToken: ShellToken) => {
    if (!tokenLogos[shellToken.name]) tokenLogos[shellToken.name] = createTokenLogo(shellToken, 50);
  });

  nftCollections.forEach((collection: NFTCollection) => {
    if (!tokenLogos[collection.symbol]) tokenLogos[collection.symbol] = createTokenLogo(collection, 50);
  });

  lbpTokens.forEach((lbpToken: LBPToken) => {
    if (!tokenLogos[lbpToken.symbol]) tokenLogos[lbpToken.symbol] = createTokenLogo(lbpToken, 83);
    if (!tokenLogos[lbpToken.poolName]) tokenLogos[lbpToken.poolName] = createTokenLogo(tokenMap[lbpToken.poolName], 50);
  });

  externalTokens.forEach((token: ExternalToken) => {
    if (!tokenLogos[token.symbol]) tokenLogos[token.symbol] = createTokenLogo(token, 50);
  });

  externalDefiTokens.forEach((lpToken: ExternalDefiToken) => {
    if (!tokenLogos[lpToken.symbol]) tokenLogos[lpToken.symbol] = createTokenLogo(lpToken, 50);
  });
}

tokenLogos['veSHELL'] = createTokenLogo(veSHELL, 83)

export { tokenLogos, createTokenLogo };

import React, { useEffect, useState, useContext } from "react";
import { useNetwork, erc20ABI, useAccount, erc721ABI, useSigner } from "wagmi";
import styled from "styled-components";
import { Box } from "../../components/Layout/Box";
import { InputPanel, InputPanelsWrapper } from "./InputPanel";
import arrowsIcon from "../../assets/icons/arrows.svg";
import {
  Token,
  ShellToken,
  NFT,
  NFTCollection,
  isNFTCollection,
  isShellToken,
  isLBPToken,
  isShellV2Token,
  isExternalToken,
  placeholderToken,
  isPlaceholderToken,
} from "../../utils/tokens";
import { TradeButton, ApproveButton, ErrorAlert, WarningAlert, ExecuteButton } from "./TradeButton";
import { SwapInfo, SwapInfoDirection, SwapInfoText } from "./SwapInfo";
import { tokenColors } from "../../constants/tokenColors";
import { Content } from "../../components/Layout/Content";
import { OCEAN_ADDRESS, ETH_ADDRESS, OLD_OCEAN_ADDRESS, SHELL_ADDRESS, STREAM_ADDRESS } from "../../constants/addresses";
import { Edge, LiquidityGraph, getTokenID } from "../../utils/LiquidityGraph";
import { BigNumber, Contract, providers, utils } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import { formatDisplay } from "../../utils/formatDisplay";
import { formatUnits, parseUnits, parseEther } from "@ethersproject/units";
import { Spinner } from "../../components/Loaders";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { MaxUint256, Zero } from "@ethersproject/constants";
import { PoolQuery } from "../../utils/PoolQuery";
import { DEFAULT_SLIPPAGE, SettingsModal } from "./SettingsModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import { breakpoints, Media } from "../../styles";
import { useWidthBreakpoint } from "../../hooks";
import { ImpactModal } from "./ImpactModal";
import { addPrice, clearPrices } from "../../store/pricesSlice";
import { NFT_PRICES_API, PRICES_API } from "../../constants/urls";
import { NFTCheckModal } from "./NFTCheckModal";
import { getPoolToken } from "../../utils/testRouter";
import { extract1155Data } from "../../utils/nftHelpers";
import { getModifiedPath } from "@/utils/sor";
import { OceanABI } from "@/constants/ABI/OceanABI";
import { GeoContext } from "@/components/Overlays/GeoProvider";
import { combineMerge, combineSplit } from "@/utils/buildSwapPath";
import { CrossChainConfirmationModal, forwarderInterface } from "./CrossChainConfirmationModal";
import { Chain, allChains, bridgeOutputs } from "@/placeholders/chains";
import { MiniTabs } from "@/components/MiniTabs/MiniTabs";
import { ChainSelect } from "@/components/ChainSelect/ChainSelect";
import { hexZeroPad, hexlify, formatEther } from "ethers/lib/utils.js";
import { AddressZero } from "@ethersproject/constants";
import { alchemyId } from "@/providers/WagmiProvider";
import { ChainContext, changeNetwork, validChains } from "@/components/Overlays/ChainProvider";
import { clearBalances } from "@/store/balancesSlice";
import { ShellGuideModal } from "@/components/ShellGuideModal/ShellGuideModal";
import { ShellGuideModalTabs } from "@/components/ShellGuideModal/ShellGuideModalBody";

export const TradeScreen = () => {
  const isMobile = useWidthBreakpoint(breakpoints.mobile);
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const { isUS } = useContext(GeoContext);
  const {
    crossChain,
    setCrossChain,
    crossChainTo,
    setCrossChainTo,
    crossChainToggled,
    setCrossChainToggled,
    tokens,
    tokenMap,
    nftCollections,
    connectedChain,
    liquidityGraph,
    poolQuery,
    oceanAddress,
    externalDefiTokens,
    sorTokenMap,
    loadedPrices,
    reloadPrices,
    setReloadPrices,
    tokenMapTo,
    liquidityGraphTo,
    poolQueryTo,
    sorTokenMapTo
  } = useContext(ChainContext);

  const { address: walletAddress, isConnected } = useAccount();

  const { data: signer } = useSigner();

  const { chain: activeChain } = useNetwork();
  const validChain = activeChain?.name == (crossChainToggled ? crossChain : connectedChain.name);
  //   const poolQuery = new PoolQuery(tokenMap);

  const location = useLocation();
  const navigate = useNavigate();

  const [slippage, setSlippage] = useState(DEFAULT_SLIPPAGE);
  const provider = new providers.JsonRpcProvider(
    `https://${(crossChainToggled ? allChains[crossChain] : connectedChain).rpcPrefix}.g.alchemy.com/v2/${alchemyId}`
  );

  const { initInput, initOutput, guideAction } = location.state || {};
  const [guideTab, setGuideTab] = useState(ShellGuideModalTabs.GET_STARTED);

  useEffect(() => {
    if (!guideAction || !tokenMap) {
      navigate("/trade", { replace: true });
      return;
    }

    const actionTokenMap: { [key: string]: { from: string[]; to: string[]; crossChain?: string; toggleCrossChain?: boolean } } = {
      swap: { from: ["USDC"], to: ["USDT"] },
      liquidity: { from: ["USDC", "USDT"], to: ["2CRV"] },
      lending: { from: ["USDC"], to: ["aArbUSDCn"] },
      nfts: { from: ["ETH"], to: ["TOUCAN"] },
      trade: { from: ["2CRV"], to: ["aArbUSDCn"] },
      stake: { from: ["ETH"], to: ["SHELL+ETH-STIP"] },
      crosschain: {
        from: ["USDC"],
        to: ["USDT"],
        crossChain: "Base",
        toggleCrossChain: true,
      },
    };

    const actionConfig = actionTokenMap[guideAction];

    if (!actionConfig) {
      navigate("/trade", { replace: true });
    } else {
      setTokensFrom(actionConfig.from.map((token) => tokenMap[token]));
      setTokensTo(actionConfig.to.map((token) => tokenMap[token]));
      setSelectedProtocolsFrom(actionConfig.from.map(() => ["all"]));
      setSelectedProtocolsTo(actionConfig.to.map(() => ["all"]));
      if (actionConfig.crossChain) {
        setCrossChain(actionConfig.crossChain);
      }
      if (actionConfig.toggleCrossChain) {
        setCrossChainToggled(true);
      }
    }
  }, [guideAction, navigate]);

  let initTokensFrom: any[], initTokensTo: any[];
  let initNFTsFrom: any = {};
  let initNFTsTo: any = {};
  if (location.state && location.state.initInput && location.state.initOutput) {
    const findFractionalToken = (tokenID: string) => {
      return Object.values(tokenMap).filter((current) => current.symbol.includes(`fr${tokenID}`))[0].symbol;
    };

    initTokensFrom = initInput.map((token: any) => {
      const data1155 = extract1155Data(isNFTCollection(token) && token.is1155 ? findFractionalToken(token.symbol.substring(2)) : token.symbol);
      if (data1155) {
        const collection: any = { ...tokenMap[data1155.symbol] };
        collection.id1155 = parseInt(data1155.id);
        initNFTsFrom[collection.symbol] = [
          {
            id: data1155.id,
            symbol: collection.symbol,
            name: data1155.item.name,
            wrapped: collection.wrapped,
            rarity: 0,
            image: data1155.item.icon,
            balance: 0,
            desc: data1155.item.desc,
          },
        ];
        return collection;
      } else {
        return token;
      }
    });

    initTokensTo = initOutput.map((token: any) => {
      const data1155 = extract1155Data(isNFTCollection(token) && token.is1155 ? findFractionalToken(token.symbol.substring(2)) : token.symbol);
      if (data1155) {
        const collection: any = { ...tokenMap[data1155.symbol] };
        collection.id1155 = parseInt(data1155.id);
        initNFTsTo[collection.symbol] = [
          {
            id: data1155.id,
            symbol: collection.symbol,
            name: data1155.item.name,
            wrapped: collection.wrapped,
            rarity: 0,
            image: data1155.item.icon,
            balance: 0,
            desc: data1155.item.desc,
          },
        ];
        return collection;
      } else {
        return token;
      }
    });
    window.history.replaceState({}, document.title);
  } else {
    // Randomly choose stablecoin swap pair
    initTokensFrom = [tokenMap["ETH"]];
    initTokensTo = [placeholderToken];
  }
  const [selectedNFTsFrom, setSelectedNFTsFrom] = useState<{ [collection: string]: any[] }>(initNFTsFrom);
  const [selectedNFTsTo, setSelectedNFTsTo] = useState<{ [collection: string]: any[] }>(initNFTsTo);

  const [tokensFrom, setTokensFrom] = useState<any[]>(initTokensFrom);
  const addTokenFrom = () => {
    const newTokenFrom = tokens.find((token) => token.symbol !== tokensFrom[0].symbol && token.address == tokensFrom[0].address);
    setTokensFrom([...tokensFrom, placeholderToken]);
    setSelectedProtocolsFrom([...selectedProtocolsFrom, ["all"]]);
  };

  const removeTokenFrom = (inputToken: Token, index: number) => {
    setPropLockInput("");

    const newTokensFrom = [...tokensFrom];
    newTokensFrom.splice(index, 1);
    setTokensFrom(newTokensFrom);

    const newSelectedProtocolsFrom = [...selectedProtocolsFrom];
    newSelectedProtocolsFrom.splice(index, 1);
    setSelectedProtocolsFrom(newSelectedProtocolsFrom);

    const newAmountsFrom: Record<string, string> = { ...amountsFrom };
    delete newAmountsFrom[getTokenID(inputToken)];
    setAmountsFrom(newAmountsFrom);

    const newUSDValues = { ...usdValues };
    delete usdValues[getTokenID(inputToken)];
    setUSDValues(newUSDValues);
  };

  const [tokensTo, setTokensTo] = useState<any[]>(initTokensTo);
  const addTokenTo = () => {
    const newTokenTo = tokens.find((token) => token.symbol !== tokensTo[0].symbol && token.address == tokensTo[0].address);
    setTokensTo([...tokensTo, placeholderToken]);
    setSelectedProtocolsTo([...selectedProtocolsTo, ["all"]]);
  };
  const removeTokenTo = (outputToken: Token, index: number) => {
    setPropLockOutput("");

    const newTokensTo = [...tokensTo];
    newTokensTo.splice(index, 1);
    setTokensTo(newTokensTo);

    const newSelectedProtocolsTo = [...selectedProtocolsTo];
    newSelectedProtocolsTo.splice(index, 1);
    setSelectedProtocolsTo(newSelectedProtocolsTo);

    const newAmountsTo: Record<string, string> = { ...amountsTo };
    delete newAmountsTo[getTokenID(outputToken)];
    setAmountsTo(newAmountsTo);
  };

  const [amountsFrom, setAmountsFrom] = useState<Record<string, string>>({});
  const [amountsTo, setAmountsTo] = useState<Record<string, string>>({});

  const [selectedProtocolsFrom, setSelectedProtocolsFrom] = useState<string[][]>(tokensFrom.map((_) => ["all"]));
  const [selectedProtocolsTo, setSelectedProtocolsTo] = useState<string[][]>(tokensTo.map((_) => ["all"]));

  const [splitAmounts, setSplitAmounts] = useState<Record<string, BigNumber>>({});

  const [usdValues, setUSDValues] = useState<Record<string, number>>({});
  const [priceImpacts, setPriceImpacts] = useState<Record<string, number>>({});
  const [bridgeFee, setBridgeFee] = useState(0);

  const [fromInputs, setFromInputs] = useState(true); // Determine if user last inputted value on from side
  const [tokenDecimals, setTokenDecimals] = useState<Record<string, number>>({});

  const [inputWarnings, setInputWarnings] = useState([false]);
  const [outputWarnings, setOutputWarnings] = useState([false]);

  const [errorText, setErrorText] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [warningText, setWarningText] = useState("");
  const [warningVisible, setWarningVisible] = useState(false);

  const [impactVisible, setImpactVisible] = useState(false);
  const [nftCheckVisible, setNFTCheckVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [crossChainConfirmVisible, setCrossChainConfirmVisible] = useState(false);

  const [propLockInput, setPropLockInput] = useState("");
  const [propLockOutput, setPropLockOutput] = useState("");

  const userBalances = useAppSelector((state) => state.balances.balances[crossChainToggled ? crossChain : connectedChain.name]);
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);
  const prices = useAppSelector((state) => state.prices.prices[crossChainToggled ? crossChain : connectedChain.name]);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const externalPrimitives = new Set(Object.keys(tokenMap).filter((tokenID) => isExternalToken(tokenMap[tokenID])));

  const buildExternalCall = (tokenToBridge: any, inputTokenID: string, path: Edge[]) => {

    const destOutputAddress = tokenToBridge.address == ETH_ADDRESS ? AddressZero : tokenMapTo![bridgeOutputs[inputTokenID][crossChainTo]].address;

    const outputToken = tokensTo[0];

    const outputAddress =
      isShellToken(outputToken) && outputToken.tokenType == "Shell"
        ? outputToken.protocolVersion == "V2"
          ? OLD_OCEAN_ADDRESS
          : OCEAN_ADDRESS
        : outputToken.address == ETH_ADDRESS
        ? AddressZero
        : outputToken.address;

    const outputMetadata = hexZeroPad(hexlify(outputToken.tokenType == "Shell" ? outputToken.oceanID : Zero), 32);

    const primitives = path
      .filter((step) => step.action == "Swap" || step.action == "Deposit" || step.action == "Withdraw")
      .map((step) => (getPoolToken(step.pool, tokenMapTo) as ShellToken).pool);

    const oceanIDs = path
      .filter((step) => step.action && step.token.oceanID && !(step.action == "Unwrap" && isShellV2Token(step.token)))
      .map((step) => step.token.oceanID);

    const values = [destOutputAddress, outputAddress, outputMetadata, walletAddress ?? AddressZero, hexZeroPad(hexlify(0), 32), primitives, oceanIDs];

    // Serialize the transaction
    const serializedTx = forwarderInterface.encodeFunctionData("doOceanInteraction", values);

    return JSON.stringify({
      version: "evm_1",
      fields: {
        to: allChains[crossChainTo].bridgeForwarder,
        data: serializedTx,
        value: 0,
      },
    });
  };

  const computeTotalOutputAmount = async (inputTokens: any[], outputTokens: any[], split: boolean) => {
    let paths: Edge[][] = [];
    let inputNFTPaths: Edge[][] = [];

    let inputAmounts: BigNumber[] = [];
    let pools: any;

    const outputAmounts: { [token: string]: BigNumber } = {};

    const query: PoolQuery = crossChainToggled ? poolQueryTo! : poolQuery as PoolQuery
    const map: any = crossChainToggled ? tokenMapTo! : tokenMap
    const sorMap = crossChainToggled ? sorTokenMapTo! : sorTokenMap
    const chain = crossChainToggled ? allChains[crossChainTo] : connectedChain

    if (split) {
      [paths, inputNFTPaths] = query.filterInputNFTPath(
        outputTokens.map((outputToken) => liquidityGraph!.findPath(inputTokens[0], outputToken)).sort((a, b) => b.length - a.length)
      );

      const inputTokenID = getTokenID(inputTokens[0]);
      let totalInputAmount: number;

      if (inputTokenID == "STREAM") {
        totalInputAmount = selectedNFTsFrom[inputTokenID].map((stream: any) => parseFloat(stream.vesting)).reduce((partialSum, a) => partialSum + a, 0);
      } else {
        totalInputAmount = query.adjustNFTAmount(parseFloat(amountsFrom[inputTokenID] ?? 0), inputNFTPaths[0]);
      }

      paths = await Promise.all(
        paths.map(async (path) => {
          if (path.length == 0 || totalInputAmount == 0) return path;
          return getModifiedPath(path, amountsFrom[inputTokenID], slippage, map, sorMap, chain, liquidityGraph);
        })
      );

      pools = await query.getPools(paths);
      const newSplitAmounts: Record<string, BigNumber> = {};

      if (totalInputAmount > 0) {
        let numWithdrawals = 0;
        let withdrawnTokens: string[] = [];
        paths.forEach((path) => {
          // Check to see if a porportional withdrawal will happen
          if (path.length > 1) {
            const firstStep = path[1].action != "Wrap" ? path[1] : path[2];
            if (firstStep.action == "Withdraw") {
              numWithdrawals++;

              const shellToken: any = map[firstStep.pool];
              const childTokens = shellToken.tokens;
              const nextToken = getTokenID(firstStep.token);

              if (childTokens[0] == nextToken || "sh" + childTokens[0] == nextToken) {
                withdrawnTokens.push("X");
              } else if (childTokens[1] == nextToken || "sh" + childTokens[1] == nextToken) {
                withdrawnTokens.push("Y");
              } else {
                console.error("Invalid withdrawal path");
              }
            }
          }
        });

        if (numWithdrawals == 2 && withdrawnTokens.includes("X") && withdrawnTokens.includes("Y")) {
          // Proportional withdrawal logic
          const poolData = pools.states[inputTokenID];

          if (loadedPrices) {
            const lpToken: any = map[inputTokenID];
            const childTokens = lpToken.tokens;
            const xValue = (await query.getUSDPrice(map[childTokens[0]], { ...prices })) * parseFloat(formatUnits(poolData.xBalance));
            const yValue = (await query.getUSDPrice(map[childTokens[1]], { ...prices })) * parseFloat(formatUnits(poolData.yBalance));
            const totalValue = xValue + yValue;
            const xAlloc = (xValue / totalValue) * totalInputAmount;
            const yAlloc = totalInputAmount - xAlloc;
            for (let i = 0; i < withdrawnTokens.length; i++) {
              inputAmounts.push(withdrawnTokens[i] == "X" ? parseUnits(xAlloc.toFixed(18)) : parseUnits(yAlloc.toFixed(18)));
              newSplitAmounts[getTokenID(paths[i][paths[i].length - 1].token)] = inputAmounts[i];
            }
          } else {
            const totalSupply = parseFloat(formatUnits(poolData.totalSupply));
            const c = 1 - (totalSupply - totalInputAmount) / totalSupply;

            for (let i = 0; i < withdrawnTokens.length; i++) {
              const firstIndex = paths[i][1].action != "Wrap" ? 0 : 1;
              let split = parseUnits((c * parseFloat(formatUnits(withdrawnTokens[i] == "X" ? poolData.xBalance : poolData.yBalance))).toFixed(18));
              try {
                inputAmounts.push(
                  i == 0
                    ? (await query.query([paths[i][firstIndex], paths[i][firstIndex + 1]], split, pools, false)).amount
                    : parseUnits(totalInputAmount.toFixed(18)).sub(inputAmounts[0])
                );
              } catch {
                inputAmounts.push(parseUnits((totalInputAmount / tokensTo.length).toFixed(18)));
              }
              newSplitAmounts[getTokenID(paths[i][paths[i].length - 1].token)] = inputAmounts[i];
            }
          }
        } else {
          const pathData = combineSplit(JSON.parse(JSON.stringify(paths)));

          const [sharedOutput, firstOutput] = await Promise.all([
            query.query(pathData.sharedPath, parseUnits(totalInputAmount.toFixed(18)), pools, true),
            query.query(pathData.sharedPath, parseUnits((totalInputAmount / tokensTo.length).toString()), pools, true),
          ]);

          tokensTo.forEach((_, index) => {
            inputAmounts.push(index == 0 ? firstOutput.amount : sharedOutput.amount.sub(firstOutput.amount));
            newSplitAmounts[getTokenID(paths[index][paths[index].length - 1].token)] = parseUnits((totalInputAmount / tokensTo.length).toString());
          });

          paths = pathData.paths;

          pools = query.filterPools(pools, paths);
        }
      } else {
        tokensTo.forEach((_, index) => {
          inputAmounts.push(BigNumber.from("0"));
          newSplitAmounts[getTokenID(paths[index][paths[index].length - 1].token)] = BigNumber.from("0");
        });
      }
      setSplitAmounts(newSplitAmounts);
    } else {

      const graph: LiquidityGraph = crossChainToggled ? liquidityGraphTo! : liquidityGraph as LiquidityGraph

      [paths, inputNFTPaths] = query.filterInputNFTPath(
        inputTokens
          .map((inputToken) =>
            graph!.findPath(crossChainToggled ? map[bridgeOutputs[getTokenID(inputToken)][crossChainTo]] : inputToken, outputTokens[0])
          )
          .sort((a, b) => b.length - a.length)
      );


      paths = await Promise.all(
        paths.map(async (path, i) => {
          const inputAmount = amountsFrom[getTokenID(crossChainToggled ? inputTokens[i] : paths[i][0].token)];
          if (path.length == 0 || !inputAmount) return path;
          return getModifiedPath(path, inputAmount, slippage, map, sorMap, chain, graph);
        })
      );

      if (propLockInput == "locked") {
        pools = await query.getPools(paths);

        const poolData = pools.states[getTokenID(outputTokens[0])];

        const nftPathIndex = inputNFTPaths[0].length > 0 ? 0 : 1;
        const inputNFT = getTokenID(inputNFTPaths[nftPathIndex][0].token);
        const fungibleTokenID = getTokenID(inputTokens[nftPathIndex == 0 ? 1 : 0]);
        const nftInputAmount = query.adjustNFTAmount(parseFloat(amountsFrom[inputNFT] ?? 0), inputNFTPaths[nftPathIndex]);

        const fungibleTokenAmount = nftInputAmount * (parseFloat(formatUnits(poolData.yBalance)) / parseFloat(formatUnits(poolData.xBalance)));

        inputAmounts = [parseEther(nftInputAmount.toFixed(18)), parseEther(fungibleTokenAmount.toFixed(18))];
        if (nftPathIndex == 1) inputAmounts.reverse();

        if (amountsFrom[fungibleTokenID] != fungibleTokenAmount.toFixed(18)) {
          setAmountsFrom((prevAmountsFrom: any) => ({
            ...prevAmountsFrom,
            [fungibleTokenID]: fungibleTokenAmount.toFixed(18),
          }));
        }
      } else {
        pools = await query.getPools(paths);

        const pathData = combineMerge(JSON.parse(JSON.stringify(paths)));

        inputAmounts = await Promise.all(
          paths.map(async (path, index) => {
            if (crossChainToggled) {
              const inputTokenID = getTokenID(tokensFrom[0]);

              if (!amountsFrom[inputTokenID]) return parseEther("0");

              let decimals: number;

              if (tokenDecimals[inputTokenID]) {
                decimals = tokenDecimals[inputTokenID];
              } else {
                if (map[inputTokenID].address == ETH_ADDRESS) {
                  decimals = 18;
                } else {
                  const tokenContract = new Contract(allChains[crossChain].tokens![inputTokenID], erc20ABI, provider);
                  decimals = await tokenContract.decimals();
                }

                setTokenDecimals((prevDecimals: any) => ({
                  ...prevDecimals,
                  [inputTokenID]: decimals,
                }));
              }

              const tokenToBridge = map[inputTokenID];

              let externalCall = path.length > 0 ? buildExternalCall(tokenToBridge, inputTokenID, path) : "";

              const destOutputAddress = tokenToBridge.address == ETH_ADDRESS ? AddressZero : map[bridgeOutputs[inputTokenID][crossChainTo]].address;
              let inputAmount = 0;

              let fullUrl;

              if (walletAddress) {
                const params: Record<string, string> = {
                  srcChainId: allChains[crossChain].chainId.toString(),
                  srcChainTokenIn: allChains[crossChain].tokens![inputTokenID],
                  srcChainTokenInAmount: parseUnits(amountsFrom[inputTokenID], decimals).toString(),
                  dstChainId: allChains[crossChainTo].chainId.toString(),
                  dstChainTokenOut: destOutputAddress,
                  senderAddress: walletAddress!,
                  srcChainOrderAuthorityAddress: walletAddress!,
                  dstChainOrderAuthorityAddress: walletAddress!,
                  dstChainTokenOutRecipient: allChains[crossChainTo].bridgeForwarder!,
                  additionalTakerRewardBps: "1",
                  externalCall: externalCall,
                };

                const queryString = new URLSearchParams(params).toString();
                fullUrl = `https://api.dln.trade/v1.0/dln/order/create-tx?${queryString}`;
              } else {
                const params: Record<string, string> = {
                  srcChainId: allChains[crossChain].chainId.toString(),
                  srcChainTokenIn: allChains[crossChain].tokens![inputTokenID],
                  srcChainTokenInAmount: parseUnits(amountsFrom[inputTokenID], decimals).toString(),
                  dstChainId: allChains[crossChainTo].chainId.toString(),
                  dstChainTokenOut: destOutputAddress,
                  additionalTakerRewardBps: "1",
                  externalCall: externalCall,
                };

                const queryString = new URLSearchParams(params).toString();
                fullUrl = `https://api.dln.trade/v1.0/dln/order/quote?${queryString}`;
              }

              let bridgeError = false;

              try {
                const request = await fetch(fullUrl).then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.json();
                });

                const bridgedInputAmount = formatUnits(request.estimation.dstChainTokenOut.amount, decimals);

                setBridgeFee(parseFloat(formatEther(request.fixFee)));

                inputAmount = query.adjustNFTAmount(parseFloat(bridgedInputAmount), inputNFTPaths[index]);
              } catch {
                bridgeError = true;
              }
              setErrors((prevState: any) => {
                const updatedState = { ...prevState };
                outputTokens.forEach((token: any) => {
                  updatedState[getTokenID(token)] = {};
                });
                updatedState[inputTokenID] = {
                  ...prevState[inputTokenID],
                  amount: bridgeError,
                };
                return updatedState;
              });
              return parseEther(inputAmount.toFixed(18));
            } else {
              if (path.length == 0 && inputNFTPaths[index].length == 0) {
                return parseEther(amountsFrom[getTokenID(inputTokens[index])]);
              }
              const inputToken = getTokenID((inputNFTPaths[index].length > 0 ? inputNFTPaths[index][0] : path[0]).token);
              let inputAmount;

              if (inputToken == "STREAM") {
                inputAmount = selectedNFTsFrom[inputToken].map((stream: any) => parseFloat(stream.vesting)).reduce((partialSum, a) => partialSum + a, 0);
              } else {
                inputAmount = query.adjustNFTAmount(parseFloat(amountsFrom[inputToken] ?? 0), inputNFTPaths[index]);
              }
              return parseEther(inputAmount.toFixed(18));
            }
          })
        );

        let totalOutputAmount = Zero;

        await Promise.all(
          pathData.paths.map(async (path: any, i) => {
            if (path.length == 0) {
              const outputTokenID = getTokenID(split ? outputTokens[i] : outputTokens[0]);
              if (inputNFTPaths[i].length > 0) outputAmounts[outputTokenID] = inputAmounts[i]; // Handle one step NFT wraps
              if (!outputAmounts[outputTokenID]) outputAmounts[outputTokenID] = Zero;
              return;
            }

            const [startToken, endToken] = [getTokenID(path[0].token), getTokenID(path[path.length - 1].token)];

            if (inputAmounts[i].isZero()) {
              if (!outputAmounts[endToken]) outputAmounts[endToken] = Zero;
              return;
            }

            let resultAmount = Zero;
            let computeError = false;

            try {
              if (path.length == 1) {
                resultAmount = inputAmounts[i];
              } else {
                const result = await query.query(path, inputAmounts[i], pools, true);
                resultAmount = result.amount;
                query.filterPools(pools, pathData.paths).sharedPools.forEach((pool: string) => {
                  pools.states[pool] = {
                    xBalance: result.poolStates[pool][0],
                    yBalance: result.poolStates[pool][1],
                    totalSupply: result.poolStates[pool][2],
                    impAddress: result.poolStates[pool][3],
                  };
                });
              }
            } catch {
              computeError = true;
            }

            setErrors((prevState: any) => {
              const updatedState = { ...prevState };
              outputTokens.forEach((token: any) => {
                updatedState[getTokenID(token)] = {};
              });
              const inputToken = getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token);
              updatedState[inputToken] = {
                ...prevState[inputToken],
                amount: computeError,
              };
              return updatedState;
            });

            totalOutputAmount = totalOutputAmount.add(resultAmount);
          })
        );

        paths = [pathData.sharedPath];
        inputAmounts = [totalOutputAmount];
        pools = query.filterPools(pools, paths);
      }

      setSplitAmounts({});
    }

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      if (path.length == 0) {
        const outputTokenID = getTokenID(split ? outputTokens[i] : outputTokens[0]);
        if (inputNFTPaths[i].length > 0) outputAmounts[outputTokenID] = inputAmounts[i]; // Handle one step NFT wraps
        if (!outputAmounts[outputTokenID]) outputAmounts[outputTokenID] = Zero;
        continue;
      }

      const [startToken, endToken] = [getTokenID(path[0].token), getTokenID(path[path.length - 1].token)];

      if (inputAmounts[i].isZero()) {
        if (!outputAmounts[endToken]) outputAmounts[endToken] = Zero;
        continue;
      }

      let resultAmount = Zero;
      let computeError = false;

      try {
        if (path.length == 1) {
          resultAmount = inputAmounts[i];
        } else {
          const result = await query.query(path, inputAmounts[i], pools, true);
          resultAmount = result.amount;
          pools.sharedPools.forEach((pool: string) => {
            pools.states[pool] = {
              xBalance: result.poolStates[pool][0],
              yBalance: result.poolStates[pool][1],
              totalSupply: result.poolStates[pool][2],
              impAddress: result.poolStates[pool][3],
            };
          });
        }
      } catch {
        computeError = true;
      }

      setErrors((prevState: any) => {
        const updatedState = { ...prevState };
        outputTokens.forEach((token: any) => {
          updatedState[getTokenID(token)] = {};
        });
        const inputToken = getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token);
        updatedState[inputToken] = {
          ...prevState[inputToken],
          amount: computeError,
        };
        return updatedState;
      });

      outputAmounts[endToken] = outputAmounts[endToken] ? outputAmounts[endToken].add(resultAmount) : resultAmount;
    }

    return outputAmounts;
  };

  const computeTotalInputAmount = async (outputTokens: any[], inputTokens: any[], split: boolean) => {
    let paths: Edge[][] = [];
    let outputNFTPaths: Edge[][] = [];

    let outputAmounts: BigNumber[] = [];
    let pools: any;

    if (split) {
      [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(
        inputTokens
          .map((inputToken) => liquidityGraph!.findPath(inputToken, outputTokens[0]))
          .sort((a, b) => {
            if (a.map((step) => step.token).includes(tokenMap["ETH"])) {
              return -1;
            } else if (b.map((step) => step.token).includes(tokenMap["ETH"])) {
              return 1;
            }
            return b.length - a.length;
          })
      );

      pools = await poolQuery.getPools(paths);

      const outputTokenID = getTokenID(outputTokens[0]);
      let totalOutputAmount;

      if (outputTokenID == "STREAM") {
        totalOutputAmount = selectedNFTsTo[outputTokenID]
          .map((stream: any) => parseFloat(stream.claimable) + parseFloat(stream.vesting))
          .reduce((partialSum, a) => partialSum + a, 0);
      } else {
        totalOutputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsTo[outputTokenID] ?? 0), outputNFTPaths[0].reverse());
      }

      if (totalOutputAmount > 0) {
        let numDeposits = 0;
        let depositedTokens: string[] = [];

        paths.forEach((path) => {
          // Check to see if a porportional deposit will happen
          if (path.length > 1) {
            const lastStep = path[path.length - 1];
            if (lastStep.action == "Deposit") {
              numDeposits++;

              const shellToken: any = tokenMap[lastStep.pool];
              const childTokens = shellToken.tokens;
              const lastToken = getTokenID(path[path.length - 2].token);

              if (childTokens[0] == lastToken || "sh" + childTokens[0] == lastToken) {
                depositedTokens.push("X");
              } else if (childTokens[1] == lastToken || "sh" + childTokens[1] == lastToken) {
                depositedTokens.push("Y");
              } else {
                console.error("Invalid deposit path");
              }
            }
          }
        });

        if (numDeposits == 2 && depositedTokens.includes("X") && depositedTokens.includes("Y")) {
          // Proportional deposit logic
          const poolData = pools.states[outputTokenID];

          if (loadedPrices) {
            const lpToken: any = tokenMap[outputTokenID];
            const childTokens = lpToken.tokens;
            const xValue = (await poolQuery.getUSDPrice(tokenMap[childTokens[0]], { ...prices })) * parseFloat(formatUnits(poolData.xBalance));
            const yValue = (await poolQuery.getUSDPrice(tokenMap[childTokens[1]], { ...prices })) * parseFloat(formatUnits(poolData.yBalance));
            const totalValue = xValue + yValue;
            const xAlloc = (xValue / totalValue) * totalOutputAmount;
            const yAlloc = totalOutputAmount - xAlloc;
            for (let i = 0; i < paths.length; i++) {
              outputAmounts.push(depositedTokens[i] == "X" ? parseUnits(xAlloc.toFixed(18)) : parseUnits(yAlloc.toFixed(18)));
            }
          } else {
            const totalSupply = parseFloat(formatUnits(poolData.totalSupply));
            const c = (totalSupply + totalOutputAmount) / totalSupply - 1;

            for (let i = 0; i < depositedTokens.length; i++) {
              let split = parseUnits((c * parseFloat(formatUnits(depositedTokens[i] == "X" ? poolData.xBalance : poolData.yBalance))).toFixed(18));
              try {
                outputAmounts.push(
                  i == 0
                    ? (await poolQuery.query([paths[i].at(-2)!, paths[i].at(-1)!], split, pools, true)).amount
                    : parseUnits(totalOutputAmount.toFixed(18)).sub(outputAmounts[0])
                );
              } catch {
                outputAmounts.push(parseUnits((totalOutputAmount / tokensFrom.length).toFixed(18)));
              }
            }
          }
        } else {
          const equalSplit = parseUnits((totalOutputAmount / tokensFrom.length).toFixed(18));
          tokensFrom.forEach((_) => outputAmounts.push(equalSplit));
        }
      } else {
        tokensFrom.forEach((_) => outputAmounts.push(BigNumber.from("0")));
      }
      const newSplitAmounts: Record<string, BigNumber> = {};
      paths.forEach((path, index) => (newSplitAmounts[getTokenID(path[0].token)] = outputAmounts[index]));
      setSplitAmounts(newSplitAmounts);
    } else {
      [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(
        outputTokens.map((outputToken) => liquidityGraph!.findPath(inputTokens[0], outputToken)).sort((a, b) => b.length - a.length)
      );
      pools = await poolQuery.getPools(paths);

      if (propLockOutput == "locked") {
        const poolData = pools.states[getTokenID(inputTokens[0])];

        const nftPathIndex = outputNFTPaths[0].length > 0 ? 0 : 1;
        const outputNFT = getTokenID(outputNFTPaths[nftPathIndex].at(-1)!.token);
        const fungibleTokenID = getTokenID(outputTokens[nftPathIndex == 0 ? 1 : 0]);
        const nftOutputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsTo[outputNFT] ?? 0), outputNFTPaths[nftPathIndex]);

        const fungibleTokenAmount = nftOutputAmount * (parseFloat(formatUnits(poolData.yBalance)) / parseFloat(formatUnits(poolData.xBalance)));

        outputAmounts = [parseEther(nftOutputAmount.toFixed(18)), parseEther(fungibleTokenAmount.toFixed(18))];
        if (nftPathIndex == 1) outputAmounts.reverse();

        if (amountsTo[fungibleTokenID] != fungibleTokenAmount.toFixed(18)) {
          setAmountsTo((prevAmountsTo: any) => ({
            ...prevAmountsTo,
            [fungibleTokenID]: fungibleTokenAmount.toFixed(18),
          }));
        }
      } else {
        outputAmounts = paths.map((path, index) => {
          if (path.length == 0 && outputNFTPaths[index].length == 0) {
            return parseEther(amountsTo[getTokenID(outputTokens[index])]);
          }
          const outputToken = getTokenID((outputNFTPaths[index].length > 0 ? outputNFTPaths[index].slice(-1)[0] : path[path.length - 1]).token);
          let outputAmount;
          if (outputToken == "STREAM") {
            outputAmount = selectedNFTsTo[outputToken]
              .map((stream: any) => parseFloat(stream.claimable) + parseFloat(stream.vesting))
              .reduce((partialSum, a) => partialSum + a, 0);
          } else {
            outputAmount = poolQuery.adjustNFTAmount(parseFloat(amountsTo[outputToken] ?? 0), [...outputNFTPaths[index]].reverse());
          }
          return parseEther(outputAmount.toFixed(18));
        });
      }
      setSplitAmounts({});
    }

    const inputAmounts: { [token: string]: BigNumber } = {};

    for (let i = 0; i < paths.length; i++) {
      if (paths[i].length == 0) {
        const inputTokenID = getTokenID(split ? inputTokens[i] : inputTokens[0]);
        if (!inputAmounts[inputTokenID]) inputAmounts[inputTokenID] = Zero;
        continue;
      }

      const path = paths[i];

      const [startToken, endToken] = [getTokenID(path[0].token), getTokenID(path[path.length - 1].token)];

      if (outputAmounts[i].isZero()) {
        if (!inputAmounts[startToken]) inputAmounts[startToken] = Zero;
        continue;
      }

      let resultAmount = Zero;
      let computeError = false;

      try {
        if (path.length == 1) {
          resultAmount = outputAmounts[i];
        } else {
          const result = await poolQuery.query(path, outputAmounts[i], pools, false);
          resultAmount = result.amount;
          pools.sharedPools.forEach((pool: string) => {
            pools.states[pool] = {
              xBalance: result.poolStates[pool][0],
              yBalance: result.poolStates[pool][1],
              totalSupply: result.poolStates[pool][2],
              impAddress: result.poolStates[pool][3],
            };
          });
        }
      } catch {
        computeError = true;
      }

      setErrors((prevState: any) => {
        const updatedState = { ...prevState };
        inputTokens.forEach((token: any) => {
          updatedState[getTokenID(token)] = {};
        });
        const outputToken = getTokenID((outputNFTPaths[i].length > 0 ? outputNFTPaths[i].slice(-1)[0] : path[path.length - 1]).token);
        updatedState[outputToken] = {
          ...prevState[outputToken],
          amount: computeError,
        };
        return updatedState;
      });

      inputAmounts[startToken] = inputAmounts[startToken] ? inputAmounts[startToken].add(resultAmount) : resultAmount;
    }

    return inputAmounts;
  };

  const onTokenFromSelect = (token: Token | ShellToken | NFTCollection, index: number) => {
    const newTokensFrom = [...tokensFrom];
    newTokensFrom[index] = token;

    const newAmountsFrom: Record<string, string> = { ...amountsFrom };
    const oldAmount = amountsFrom[getTokenID(tokensFrom[index])];
    delete newAmountsFrom[getTokenID(tokensFrom[index])]; // Delete old token amount

    const newUSDValues = { ...usdValues };
    delete newUSDValues[getTokenID(tokensFrom[index])];
    setUSDValues(newUSDValues);

    const tokenID = getTokenID(token);

    if (
      newTokensFrom.map((token) => tokensTo.includes(token)).includes(true) ||
      oldAmount == undefined ||
      tokensTo.filter((token) => isNFTCollection(token)).length > 0
    ) {
      delete newAmountsFrom[tokenID];
      setErrors((prevState: any) => ({
        ...prevState,
        [tokenID]: {},
      }));
    } else if (fromInputs) {
      newAmountsFrom[tokenID] = oldAmount;
    }

    if (isNFTCollection(token)) {
      setSelectedNFTsFrom((prevSelectedNFTs) => {
        const newSelectedNFTs: any = { ...prevSelectedNFTs };
        delete newSelectedNFTs[token.symbol];
        return newSelectedNFTs;
      });
      setSelectedNFTsTo({});
      delete newAmountsFrom[tokenID];
    }

    setTokensFrom(newTokensFrom);
    setAmountsFrom(newAmountsFrom);
  };

  const onTokenToSelect = (token: Token | ShellToken | NFTCollection, index: number) => {
    const newTokensTo = [...tokensTo];
    newTokensTo[index] = token;

    const newAmountsTo: Record<string, string> = { ...amountsTo };
    const oldAmount = amountsTo[getTokenID(tokensTo[index])];
    delete newAmountsTo[getTokenID(tokensTo[index])]; // Delete old token amount

    const tokenID = getTokenID(token);

    if (
      newTokensTo.map((token) => tokensFrom.includes(token)).includes(true) ||
      oldAmount == undefined ||
      tokensFrom.filter((token) => isNFTCollection(token)).length > 0
    ) {
      delete newAmountsTo[tokenID];
      setErrors((prevState: any) => ({
        ...prevState,
        [tokenID]: {},
      }));
    } else if (!fromInputs && !isNFTCollection(token)) {
      newAmountsTo[tokenID] = oldAmount;
    }

    if (isNFTCollection(token)) {
      setSelectedNFTsTo((prevSelectedNFTs) => {
        const newSelectedNFTs: any = { ...prevSelectedNFTs };
        delete newSelectedNFTs[token.symbol];
        return newSelectedNFTs;
      });

      if (tokensFrom.filter((token) => isNFTCollection(token)).length == 0) {
        setFromInputs(false);
        delete newAmountsTo[tokenID];
        setAmountsFrom({});
      }
    }

    setTokensTo(newTokensTo);
    setAmountsTo(newAmountsTo);
  };

  const debounce = (fn: Function, ms = 500) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    let previousToken: Token;

    return function (this: any, ...args: any[]) {
      const currentToken: Token = args[0];
      if (previousToken === currentToken) {
        clearTimeout(timeoutId);
      }
      previousToken = currentToken;

      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };

  const onInputAmountChange = debounce((inputToken: Token, amount: string) => {
    setAmountsFrom((prevAmountsFrom) => {
      const newAmountsFrom: Record<string, string> = { ...prevAmountsFrom };
      const tokenID = getTokenID(inputToken);
      if (amount && parseFloat(amount) > 0) {
        newAmountsFrom[tokenID] = amount.replaceAll(",", "");
      } else {
        delete newAmountsFrom[tokenID];
        setErrors((prevState: any) => ({
          ...prevState,
          [tokenID]: {
            ...prevState[tokenID],
            amount: false,
          },
        }));
      }
      return newAmountsFrom;
    });
    setFromInputs(true);
  });

  const onOutputAmountChange = debounce((outputToken: Token, amount: string) => {
    setAmountsTo((prevAmountsTo) => {
      const newAmountsTo: Record<string, string> = { ...prevAmountsTo };
      const tokenID = getTokenID(outputToken);
      if (amount && parseFloat(amount) > 0) {
        newAmountsTo[tokenID] = amount.replaceAll(",", "");
      } else {
        delete newAmountsTo[tokenID];
        setErrors((prevState: any) => ({
          ...prevState,
          [tokenID]: {
            ...prevState[tokenID],
            amount: false,
          },
        }));
      }
      return newAmountsTo;
    });
    setFromInputs(false);
  });

  const handleSelectProtocol = (protocolName: string, index: number, setSelectedProtocols: (value: React.SetStateAction<string[][]>) => void) => {
    setSelectedProtocols((prevSelected) => {
      const newSelectedProtocols = [...prevSelected];
      if (protocolName === "all") {
        newSelectedProtocols[index] = ["all"];
        return newSelectedProtocols;
      }

      let newSelection;
      if (newSelectedProtocols[index].includes(protocolName)) {
        newSelection = newSelectedProtocols[index].filter((name) => name !== protocolName);
        if (newSelection.length === 0) {
          newSelection.push("all");
        }
      } else {
        newSelection = newSelectedProtocols[index].filter((name) => name !== "all").concat(protocolName);
      }

      newSelectedProtocols[index] = newSelection;

      return newSelectedProtocols;
    });
  };

  const swapTokens = () => {
    const newAmounts: Record<string, string> = {};

    // setCrossChain(crossChainTo);
    // setCrossChainTo(crossChain);

    if (tokensFrom.concat(tokensTo).filter((token) => isNFTCollection(token)).length) {
      const newSelectedNFTsFrom = { ...selectedNFTsTo };
      const newSelectedNFTsTo = { ...selectedNFTsFrom };

      setSelectedNFTsFrom(() => {
        Object.keys(newSelectedNFTsFrom).forEach((collectionID) => {
          const collection = tokenMap[collectionID];
          if (isNFTCollection(collection) && !collection.is1155) {
            delete newSelectedNFTsFrom[collectionID];
          }
        });
        return newSelectedNFTsFrom;
      });

      setSelectedNFTsTo(() => {
        Object.keys(newSelectedNFTsTo).forEach((collectionID) => {
          const collection = tokenMap[collectionID];
          if (isNFTCollection(collection) && !collection.is1155) {
            delete newSelectedNFTsTo[collectionID];
          }
        });
        return newSelectedNFTsTo;
      });

      setAmountsFrom({});
      setAmountsTo({});
    } else {
      if (tokensFrom.length == 1 && tokensTo.length == 1) {
        if (fromInputs) {
          newAmounts[getTokenID(tokensTo[0])] = amountsFrom[getTokenID(tokensFrom[0])];
          setAmountsTo(amountsFrom);
          setAmountsFrom({});
        } else {
          newAmounts[getTokenID(tokensFrom[0])] = amountsTo[getTokenID(tokensTo[0])];
          setAmountsFrom(amountsTo);
          setAmountsTo({});
        }
      } else {
        if (tokensFrom.length > 1) {
          setAmountsTo(amountsFrom);
          setAmountsFrom({});
        } else {
          setAmountsFrom(amountsTo);
          setAmountsTo({});
        }
      }
    }

    setErrors({});
    setUSDValues({});

    const newTokensFrom = [...tokensTo];
    const newTokensTo = [...tokensFrom];

    setTokensFrom(newTokensFrom);
    setTokensTo(newTokensTo);

    setSelectedProtocolsFrom(newTokensFrom.map((_) => ["all"]));
    setSelectedProtocolsTo(newTokensTo.map((_) => ["all"]));
  };

  const labelFrom = (index: number) => {
    if (tokensTo.length > 1) return "From";

    const inputToken = tokensFrom[index];
    const outputToken = tokensTo[0];

    if (inputToken.address !== outputToken.address && !crossChainToggled) return "Swap from";
    else if (!inputToken.wrapped && outputToken.wrapped) return "Wrap from";
    else if (inputToken.wrapped && !outputToken.wrapped) return "Unwrap from";
    else if (crossChainToggled) return "From";
    else return "From";
  };

  const CrossChainSelectorFrom = () =>
    crossChainToggled ? (
      <ChainSelect
        onSelect={(chain) => {
          setCrossChain(chain);
        }}
        selectedChain={crossChain}
        chainOptions={Object.values(allChains)}
      />
    ) : null;

  const CrossChainSelectorTo = () =>
    crossChainToggled ? (
      <ChainSelect
        onSelect={(chain) => {
          setCrossChainTo(chain);
        }}
        selectedChain={crossChainTo}
        chainOptions={Object.values(allChains)}
      />
    ) : null;

  const renderCrossChainSelector = (isFrom: boolean) => {
    if (isFrom) return <CrossChainSelectorFrom/>;
    else return <CrossChainSelectorTo/>;
  };

  const labelTo = (index: number) => {
    if (tokensTo.length == 1) return "To";

    const inputToken = tokensFrom[0];
    const outputToken = tokensTo[index];

    if (inputToken.address !== outputToken.address) return "Swap to";
    else if (!inputToken.wrapped && outputToken.wrapped) return "Wrap to";
    else if (inputToken.wrapped && !outputToken.wrapped) return "Unwrap to";
    else if (crossChainToggled) return "To";
    else return "To";
  };

  const [tradeLabel, setTradeLabel] = useState("");
  const [tradeDisabled, setTradeDisabled] = useState(false);
  const [approveDisabled, setApproveDisabled] = useState(false);

  useEffect(() => {
    setTradeDisabled(
      tokensFrom.map((inputToken) => !amountsFrom[getTokenID(inputToken)] || isPlaceholderToken(inputToken)).includes(true) ||
        tokensTo.map((outputToken) => !amountsTo[getTokenID(outputToken)] || isPlaceholderToken(outputToken)).includes(true) ||
        !isConnected ||
        errorText !== "" ||
        loading
    );
  }, [tokensFrom, tokensTo, isConnected, amountsFrom, amountsTo, errorText, loading]);

  const updateTradeButton = () => {
    if (tokensFrom.length == 1 && tokensTo.length == 1) {
      const inputToken = tokensFrom[0];
      const outputToken = tokensTo[0];

      if (crossChainToggled) {
        setTradeLabel("Bridge Tokens");
      } else if (!inputToken.wrapped && outputToken.wrapped && inputToken.address == outputToken.address) setTradeLabel("Wrap Token");
      else if (inputToken.wrapped && !outputToken.wrapped && inputToken.address == outputToken.address) setTradeLabel("Unwrap Token");
      else setTradeLabel("Swap Token");
    } else {
      setTradeLabel("Trade Tokens");
    }
  };

  useEffect(() => {
    let warning = "";

    const newInputWarnings = tokensFrom.map((_) => false);
    const newOutputWarnings = tokensTo.map((_) => false);

    for (let i = tokensFrom.length - 1; i >= 0; i--) {
      const inputToken = tokensFrom[i];
      if (isPlaceholderToken(inputToken) || (isLBPToken(inputToken) && inputToken.status == "Upcoming")) {
        newInputWarnings[i] = true;
        newOutputWarnings.forEach((e, index) => (newOutputWarnings[index] = true));

        warning = isPlaceholderToken(inputToken) ? "No token selected" : "Pool is not active";
        continue;
      }

      const inputId = getTokenID(inputToken);

      for (let j = tokensTo.length - 1; j >= 0; j--) {
        const outputToken = tokensTo[j];
        const outputId = getTokenID(outputToken);
        if (isPlaceholderToken(outputToken) || (isLBPToken(outputToken) && outputToken.status == "Upcoming")) {
          newOutputWarnings[j] = true;
          newInputWarnings.forEach((e, index) => (newInputWarnings[index] = true));

          warning = isPlaceholderToken(outputToken) ? "No token selected" : "Pool is not active";
          continue;
        } else if (isUS && outputToken.address == SHELL_ADDRESS) {
          newOutputWarnings[j] = true;
          newInputWarnings.forEach((e, index) => (newInputWarnings[index] = true));

          warning = "Cannot buy SHELL from your region";
          continue;
        } else if (crossChainToggled) {
          newOutputWarnings[j] = true;
          warning = `Cannot specify ${outputId} amount`;
          continue;
        }

        const path = (crossChainToggled ? liquidityGraphTo! : liquidityGraph).findPath(inputToken, outputToken);

        if (inputId === outputId || path.length == 0) {
          newInputWarnings[i] = true;
          newOutputWarnings[j] = true;

          warning = `Invalid pair ${inputId}/${outputId}`;
        } else {
          for (let k = path.length - 1; k >= 1; k--) {
            const action = path[k].action;
            if (action != "Wrap" && action != "Unwrap" && externalPrimitives.has(path[k].pool)) {
              newOutputWarnings[j] = true;
              warning = `Cannot specify ${outputId} amount`;
            }
          }
        }
      }
    }

    setWarningText(warning);
    setInputWarnings(newInputWarnings);
    setOutputWarnings(newOutputWarnings);
    updateTradeButton();
  }, [tokensFrom, tokensTo, walletAddress, isUS, crossChainToggled, liquidityGraph]);

  useEffect(() => {
    if (tokensFrom.length > 1 && tokensFrom.filter((token) => isNFTCollection(token)).length > 0 && isShellToken(tokensTo[0])) {
      if (
        tokensFrom
          .map((inputToken) => {
            if (isNFTCollection(inputToken)) {
              if (inputToken.is1155) {
                return Object.values(tokenMap).filter((token) => token.address == inputToken.address && token.symbol.includes(inputToken.id1155!.toString()))[0]
                  .symbol;
              } else {
                return tokens.filter((token) => token.address == inputToken.address)[0].symbol;
              }
            } else {
              return inputToken.wrapped ? getTokenID(inputToken).substring(2) : getTokenID(inputToken);
            }
          })
          .every((inputToken) => tokensTo[0].tokens.includes(inputToken))
      ) {
        setPropLockInput("visible");
      } else {
        setPropLockInput("");
      }
    } else if (tokensTo.length > 1 && tokensTo.filter((token) => isNFTCollection(token)).length > 0 && isShellToken(tokensFrom[0])) {
      if (
        tokensTo
          .map((outputToken) => {
            if (isNFTCollection(outputToken)) {
              if (outputToken.is1155) {
                return Object.values(tokenMap).filter(
                  (token) => token.address == outputToken.address && token.symbol.includes(outputToken.id1155!.toString())
                )[0].symbol;
              } else {
                return tokens.filter((token) => token.address == outputToken.address)[0].symbol;
              }
            } else {
              return outputToken.wrapped ? getTokenID(outputToken).substring(2) : getTokenID(outputToken);
            }
          })
          .every((outputToken) => tokensFrom[0].tokens.includes(outputToken))
      ) {
        setPropLockOutput("visible");
      } else {
        setPropLockOutput("");
      }
    }
  }, [tokensFrom, tokensTo]);

  useEffect(() => {
    const errorTokens = tokensFrom.concat(tokensTo);

    for (let i = errorTokens.length - 1; i >= 0; i--) {
      const tokenID = getTokenID(errorTokens[i]);
      const tokenErrors = errors[tokenID];
      if (tokenErrors) {
        if (tokenErrors.amount) {
          setErrorText(`Invalid ${tokenID} amount`);
          return;
        } else if (tokenErrors.balance) {
          setErrorText(`Insufficient ${tokenID} balance`);
          return;
        }
      }
    }

    setErrorText("");
  }, [errors]);

  const [tokenToApprove, setTokenToApprove] = useState<Token>();

  const approveToken = () => {
    if (tokenToApprove && signer) {
      setApproveDisabled(true);

      if (isNFTCollection(tokenToApprove) || isShellV2Token(tokenToApprove)) {
        const tokenContract = new Contract(isShellV2Token(tokenToApprove) ? OLD_OCEAN_ADDRESS : tokenToApprove.address, erc721ABI, signer);
        tokenContract
          .isApprovedForAll(walletAddress, oceanAddress)
          .then((approvalStatus: any) => {
            if (!approvalStatus) {
              tokenContract
                .setApprovalForAll(oceanAddress, true)
                .then((response: any) => {
                  toast
                    .promise(response.wait(), {
                      loading: "Approving " + tokenToApprove.symbol,
                      success: () => {
                        setApproveDisabled(false);
                        return "Approved " + tokenToApprove.symbol;
                      },
                      error: () => {
                        setApproveDisabled(false);
                        return "Error in " + tokenToApprove.symbol + " approval";
                      },
                    })
                    .then(() => updateApproveToken());
                })
                .catch(() => setApproveDisabled(false));
            }
          })
          .catch(() => setApproveDisabled(false));
      } else {
        const tokenContract = new Contract(
          crossChainToggled ? allChains[crossChain].tokens![getTokenID(tokenToApprove)] : tokenToApprove.address,
          erc20ABI,
          signer
        );

        const approvalOperator = crossChainToggled ? allChains[crossChain].bridge : oceanAddress;

        Promise.all([tokenContract.allowance(walletAddress, approvalOperator), tokenContract.decimals()])
          .then(([result, decimals]) => {
            const approval = parseUnits(formatUnits(result, decimals));
            const amount = parseUnits(amountsFrom[getTokenID(tokenToApprove)]);
            if (approval.lt(amount)) {
              tokenContract
                .approve(approvalOperator, MaxUint256)
                .then((response: any) => {
                  toast
                    .promise(response.wait(), {
                      loading: "Approving " + tokenToApprove.symbol,
                      success: () => {
                        setApproveDisabled(false);
                        return "Approved " + tokenToApprove.symbol;
                      },
                      error: () => {
                        setApproveDisabled(false);
                        return "Error in " + tokenToApprove.symbol + " approval";
                      },
                    })
                    .then(() => updateApproveToken());
                })
                .catch(() => setApproveDisabled(false));
            }
          })
          .catch(() => setApproveDisabled(false));
      }
    }
  };

  const updateApproveToken = async () => {
    let needsApproval = false;

    for (let i = 0; i < tokensFrom.length; i++) {
      const inputToken = tokensFrom[i];

      const inputTokenID = getTokenID(inputToken);

      if (!amountsFrom[inputTokenID] || inputToken.address == ETH_ADDRESS) continue;

      if (!inputToken.wrapped) {
        if (isNFTCollection(inputToken)) {
          const tokenContract = new Contract(inputToken.address, erc721ABI, provider);
          needsApproval = !(await tokenContract.isApprovedForAll(walletAddress, oceanAddress));
        } else if (isShellV2Token(inputToken)) {
          const tokenContract = new Contract(OLD_OCEAN_ADDRESS, OceanABI, provider);
          needsApproval = !(await tokenContract.isApprovedForAll(walletAddress, oceanAddress));
        } else {
          const tokenContract = new Contract(crossChainToggled ? allChains[crossChain].tokens![inputTokenID] : inputToken.address, erc20ABI, provider);
          const result = await tokenContract.allowance(walletAddress, crossChainToggled ? allChains[crossChain].bridge : oceanAddress);
          const decimals = tokenDecimals[inputTokenID] ?? (await tokenContract.decimals());
          const amount = parseUnits(amountsFrom[inputTokenID]);

          needsApproval = parseUnits(formatUnits(result, decimals)).lt(amount);
        }

        if (needsApproval) {
          setTokenToApprove(inputToken);
          break;
        }
      }
    }
    if (!needsApproval) {
      setTokenToApprove(undefined);
      updateTradeButton();
    }
  };

  const emptyAmounts = () => {
    return (
      Object.values(amountsFrom)
        .filter((value) => value && value.length > 0)
        .concat(Object.values(amountsTo).filter((value) => value && value.length > 0)).length == 0
    );
  };

  const getDisabledTokens = (otherTokens: any[]) => {
    const otherNFTs = otherTokens.filter((token) => isNFTCollection(token));

    let disabledNFTs: string[] = [];
    // if(tokensFrom.length > 1 || tokensTo.length > 1){
    //     disabledNFTs = nftCollections.map((collection) => collection.symbol)
    // } else if(otherNFTs.length){
    //     const pairNFTs = otherNFTs.map((collection) => collection.wrapped ? collection.symbol.substring(2) : 'sh' + collection.symbol)
    //     disabledNFTs = nftCollections.map((collection) => collection.symbol).filter((tokenID) => !pairNFTs.includes(tokenID))
    // }

    if (otherNFTs.length) {
      if (tokensFrom.length > 1 || tokensTo.length > 1) {
        disabledNFTs = nftCollections.map((collection) => collection.symbol);
      } else {
        const pairNFTs = otherNFTs.map((collection) => (collection.wrapped ? collection.symbol.substring(2) : "sh" + collection.symbol));
        disabledNFTs = nftCollections.map((collection) => collection.symbol).filter((tokenID) => !pairNFTs.includes(tokenID));
      }
    }

    return [...new Set(disabledNFTs.concat(crossChainToggled ? [] : otherTokens.map((token) => getTokenID(token))))];
  };

  useEffect(() => {
    if (crossChainToggled) {
      const tokenIDsTo = new Set(tokensTo.map((token) => getTokenID(token)));
      if (tokensTo.length > 1) {
        tokenIDsTo.delete(getTokenID(tokensTo[tokensTo.length - 1]));
        removeTokenTo(tokensTo[tokensTo.length - 1], tokensTo.length - 1);
      }
      if (tokensFrom.length > 1 || Object.keys(allChains[crossChain].tokens!).findIndex((token) => token == getTokenID(tokensFrom[0])) == -1) {
        Object.keys(allChains[crossChain].tokens!).forEach((token, index) => {
          if (!tokenIDsTo.has(token) || index == Object.keys(allChains[crossChain].tokens!).length - 1) {
            setTokensFrom([tokenMap[token]]);
            return;
          }
        });
      }
    }
    
  }, [crossChain, crossChainToggled]);

    useEffect(() => {
      setAmountsFrom({});
      setAmountsTo({});
      setErrors({});
      setTokensFrom([tokenMap['ETH']])
      setTokensTo([placeholderToken])
    }, [crossChainToggled])

  useEffect(() => {
    if (isConnected && validChain) {
      for (let i = tokensFrom.length - 1; i >= 0; i--) {
        // Show error if any input token amount is greater than user balance
        const inputToken = tokensFrom[i];
        if (inputWarnings[i]) continue;
        const inputTokenID = getTokenID(inputToken);
        const inputAmount = amountsFrom[inputTokenID] ?? "0";

        const userBalance = isNFTCollection(inputToken)
          ? inputToken.is1155 && selectedNFTsFrom[inputToken.symbol].length == 1
            ? (selectedNFTsFrom[inputToken.symbol][0].balance ?? 0).toString()
            : (userNFTBalances[inputToken.symbol]?.length ?? 0).toString()
          : userBalances[inputToken.wrapped || isShellV2Token(inputToken) ? inputToken.oceanID ?? "" : inputToken.address] ?? "0";

        setErrors((prevState: any) => ({
          ...prevState,
          [inputTokenID]: {
            ...prevState[inputTokenID],
            balance: parseFloat(inputAmount) > parseFloat(userBalance),
          },
        }));
      }

      updateApproveToken();
    }
  }, [tokensFrom, amountsFrom, walletAddress, userBalances, userNFTBalances]);

  useEffect(() => {
    if (fromInputs && !emptyAmounts()) {
      const newAmountsTo: Record<string, string> = { ...amountsTo };
      const split = tokensTo.length > 1;
      const inputTokens = [...tokensFrom];
      const outputTokens = [...tokensTo];
      if (split) inputTokens.push(inputTokens[0]);

      setLoading(true);
      computeTotalOutputAmount(inputTokens, outputTokens, split).then((outputAmounts: { [token: string]: BigNumber }) => {
        for (let token in outputAmounts) {
          const outputAmount = outputAmounts[token];
          if (outputAmount.isZero()) {
            delete newAmountsTo[token];
          } else {
            newAmountsTo[token] = formatUnits(outputAmount);
          }
        }
        setAmountsTo(newAmountsTo);
        setLoading(false);
      });
    }
  }, [fromInputs, amountsFrom, tokensFrom, tokensTo, walletAddress]);

  useEffect(() => {
    if (!fromInputs && !emptyAmounts()) {
      const newAmountsFrom: Record<string, string> = { ...amountsFrom };
      const split = tokensFrom.length > 1;
      const outputTokens = [...tokensTo];
      const inputTokens = [...tokensFrom];
      if (split) outputTokens.push(outputTokens[0]);

      setLoading(true);
      computeTotalInputAmount(outputTokens, inputTokens, split).then((inputAmounts: { [token: string]: BigNumber }) => {
        for (let token in inputAmounts) {
          const inputAmount = inputAmounts[token];
          if (inputAmount.isZero()) {
            delete newAmountsFrom[token];
          } else {
            newAmountsFrom[token] = formatUnits(inputAmount);
          }
        }
        setAmountsFrom(newAmountsFrom);
        setLoading(false);
      });
    }
  }, [fromInputs, amountsTo, tokensTo, tokensFrom, walletAddress, selectedNFTsTo]);

  useEffect(() => {
    const newPriceImpacts: any = {};

    const totalInputValue = tokensFrom.map((token) => usdValues[getTokenID(token)]).reduce((partialSum, a) => partialSum + a, 0);
    const totalOutputValue = tokensTo.map((token) => usdValues[getTokenID(token)]).reduce((partialSum, a) => partialSum + a, 0);

    const impact = totalOutputValue / totalInputValue - 1;

    tokensTo.forEach((token) => {
      newPriceImpacts[getTokenID(token)] = impact * 100;
    });

    setPriceImpacts(newPriceImpacts);
  }, [usdValues]);

  const [reloadBalances, setReloadBalances] = useState(false);
  const [txSuccess, setTxSuccess] = useState(false);

  useEffect(() => {
    if (txSuccess) {
      if ((crossChainToggled && !crossChainConfirmVisible) || (!crossChainToggled && !confirmVisible)) {
        dispatch(clearBalances());
        setReloadBalances(!reloadBalances);
        if (tokensFrom.concat(tokensTo).findIndex((token) => token.address == STREAM_ADDRESS) != -1) setReloadPrices(!reloadPrices);
        setAmountsFrom({});
        setAmountsTo({});
        setSplitAmounts({});
      }
    }
  }, [txSuccess, confirmVisible, crossChainConfirmVisible]);

  const openConfirmation = () => {
    if (isConnected) {
      if (tokensFrom.filter((token) => isNFTCollection(token) && !token.is1155).length > 0 && tokensTo.filter((token) => isNFTCollection(token)).length == 0) {
        setNFTCheckVisible(true);
      } else if (Object.values(priceImpacts).filter((impact) => impact <= -1).length > 0) {
        setImpactVisible(true);
      } else if (crossChainToggled) {
        setCrossChainConfirmVisible(true);
      } else {
        setConfirmVisible(true);
      }
    }
  };

  const debounceNFT = (fn: Function, ms = 1000) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };

  const debouncedSelectNFTsTo = debounceNFT((collection: string, items: NFT[]) => {
    setSelectedNFTsTo((prevSelectedNFTs) => ({ ...prevSelectedNFTs, [collection]: items }));
  });

  return (
    <Content>
      <Toaster />
      <View>
        <ViewHeader>
          <MiniTabs
            tabs={["Swap", "Bridge"]}
            onTabClick={(tab) => {
              if (tab == "Bridge") {
                setCrossChainToggled(true);
                setSlippage(DEFAULT_SLIPPAGE * 2);
              } else {
                setCrossChainToggled(false);
                setBridgeFee(0);
                setSlippage(DEFAULT_SLIPPAGE);
                navigate("/trade", { replace: true }); // replace the current entry in the history stack
              }
            }}
          />
          <SettingsModal userSlippage={slippage} setUserSlippage={setSlippage} />
        </ViewHeader>
        <InputPanelsWrapper position="top" style={{ marginTop: "12px" }}>
          {tokensFrom.map((token, index) => (
            <InputPanel
              key={`${token.symbol}${index}`}
              selectedToken={token}
              onAddButtonClick={addTokenFrom}
              onRemoveButtonClick={() => removeTokenFrom(token, index)}
              onTokenSelect={(token: Token) => onTokenFromSelect(token, index)}
              value={amountsFrom[getTokenID(token)] ?? ""}
              onChange={onInputAmountChange}
              isInputToken={true}
              label={labelFrom(index)}
              inputsAmount={tokensFrom.length}
              shape="bottom"
              anotherSelected={tokensTo.length > 1 || crossChainToggled}
              otherTokens={tokensFrom.filter((_, i) => i != index).map((token) => getTokenID(token))}
              disabledTokens={getDisabledTokens(tokensTo)}
              reloadBalances={reloadBalances}
              error={Object.values(errors[getTokenID(token)] ?? {}).includes(true)}
              warning={inputWarnings[index]}
              loading={loading && !fromInputs}
              otherNFTs={selectedNFTsTo}
              nftsOnOtherSide={tokensTo.filter((token) => isNFTCollection(token)).length > 0}
              setWarningVisible={setWarningVisible}
              setUSDValues={setUSDValues}
              priceImpact={0}
              selectedNFTs={selectedNFTsFrom[token.symbol]}
              allSelectedNFTs={selectedNFTsFrom}
              updateSelectedNFTs={(collection: string, items: NFT[]) =>
                setSelectedNFTsFrom((prevSelectedNFTs) => ({ ...prevSelectedNFTs, [collection]: items }))
              }
              propLock={propLockInput}
              setPropLock={setPropLockInput}
              isWidget={false}
              inputLocked={isPlaceholderToken(token)}
              crossChainToggled={crossChainToggled}
              crossChain={crossChain}
              CrossChainSelector={() => renderCrossChainSelector(true)}
              setCrossChain={setCrossChain}
              bridgeFee={bridgeFee}
              selectedProtocols={selectedProtocolsFrom[index]}
              handleSelectProtocol={(protocolName: string) => handleSelectProtocol(protocolName, index, setSelectedProtocolsFrom)}
            />
          ))}
        </InputPanelsWrapper>
        <SwapButtonContainer>
          {loading ? (
            <SpinContainer>
              <Spinner />
            </SpinContainer>
          ) : (
            <SwapButton data-testid="trade-screen-swap-btn" onClick={swapTokens}>
              <img src={arrowsIcon} alt="swap" />
            </SwapButton>
          )}
        </SwapButtonContainer>
        <InputPanelsWrapper position="bottom">
          {tokensTo.map((token, index) => (
            <InputPanel
              key={`${token.symbol}${index}`}
              selectedToken={token}
              onAddButtonClick={addTokenTo}
              onRemoveButtonClick={() => removeTokenTo(token, index)}
              onTokenSelect={(token: Token) => onTokenToSelect(token, index)}
              value={amountsTo[getTokenID(token)] ?? ""}
              onChange={onOutputAmountChange}
              isInputToken={false}
              label={labelTo(index)}
              CrossChainSelector={() => renderCrossChainSelector(false)}
              crossChainToggled={crossChainToggled}
              crossChain={crossChainTo}
              inputsAmount={tokensTo.length}
              shape="top"
              anotherSelected={tokensFrom.length > 1 || crossChainToggled}
              otherTokens={tokensTo.filter((_, i) => i != index).map((token) => getTokenID(token))}
              disabledTokens={getDisabledTokens(tokensFrom)}
              reloadBalances={reloadBalances}
              error={Object.values(errors[getTokenID(token)] ?? {}).includes(true)}
              warning={outputWarnings[index]}
              loading={loading}
              otherNFTs={selectedNFTsFrom}
              nftsOnOtherSide={tokensFrom.filter((token) => isNFTCollection(token)).length > 0}
              setWarningVisible={setWarningVisible}
              setUSDValues={setUSDValues}
              priceImpact={priceImpacts[getTokenID(token)]}
              selectedNFTs={selectedNFTsTo[token.symbol]}
              allSelectedNFTs={selectedNFTsTo}
              updateSelectedNFTs={(collection: string, items: NFT[]) => setSelectedNFTsTo((prevSelectedNFTs) => ({ ...prevSelectedNFTs, [collection]: items }))}
              nftSweepInputPrice={
                formatDisplay(amountsFrom[getTokenID(tokensFrom[tokensFrom.length - 1])] || "0") + " " + getTokenID(tokensFrom[tokensFrom.length - 1])
              }
              onNFTSweepSelect={debouncedSelectNFTsTo}
              propLock={propLockOutput}
              setPropLock={setPropLockOutput}
              isWidget={false}
              inputLocked={isPlaceholderToken(token)}
              selectedProtocols={selectedProtocolsTo[index]}
              handleSelectProtocol={(protocolName: string) => handleSelectProtocol(protocolName, index, setSelectedProtocolsTo)}
            />
          ))}
        </InputPanelsWrapper>
        {tokensFrom && tokensFrom.length !== 0 && tokensTo && tokensTo.length !== 0 && (
          <SwapInfo justifyContent="center">
            {!isMobile && !isTablet && <div></div>} {/* Emtpy div for spacing purposes */}
            <SwapInfoText className={isTablet ? "shrunk" : ""}>
              {crossChainToggled ? <span className="prefix">Bridge</span> : "Swap"}{" "}
              {tokensFrom
                .filter((token) => !isPlaceholderToken(token))
                .map((token, index) => (
                  <span data-testid={`swap-info-from-${token.symbol}-${index}`} key={index}>
                    <SwapInfoDirection color={tokenColors[token.symbol]}>
                      {`${formatDisplay(amountsFrom[getTokenID(token)] || "0")} ${token.symbol}`}
                    </SwapInfoDirection>
                    <SwapInfoDirection color={crossChainToggled ? allChains[crossChain].color : tokenColors[token.symbol]}>
                      {` (${crossChainToggled ? "on " : ""}${crossChainToggled ? (crossChain ? crossChain : "Arbitrum") : token.name})`}
                    </SwapInfoDirection>
                    {index !== tokensFrom.length - 1 && !isPlaceholderToken(tokensFrom[index + 1]) && " and "}
                  </span>
                ))}{" "}
              {tokensTo.filter((token) => !isPlaceholderToken(token)).length == 0 ? "" : crossChainToggled ? <span className="prefix">to</span> : "to"}{" "}
              {tokensTo
                .filter((token) => !isPlaceholderToken(token))
                .map((token, index) => (
                  <span data-testid={`swap-info-to-${token.symbol}-${index}`} key={index}>
                    <SwapInfoDirection color={tokenColors[token.symbol]}>
                      {`${formatDisplay(amountsTo[getTokenID(token)] || "0")} ${token.symbol}`}
                    </SwapInfoDirection>
                    <SwapInfoDirection color={crossChainToggled ? allChains[crossChainTo].color : tokenColors[token.symbol]}>
                      {` (${crossChainToggled ? "on " : ""}${crossChainToggled ? (crossChainTo ? crossChainTo : "Arbitrum") : token.name})`}
                    </SwapInfoDirection>
                    {index !== tokensTo.length - 1 && !isPlaceholderToken(tokensTo[index + 1]) && " and "}
                  </span>
                ))}
            </SwapInfoText>
          </SwapInfo>
        )}
        <ImpactModal
          visible={impactVisible}
          setVisible={setImpactVisible}
          priceImpact={Math.min(...tokensTo.map((token) => priceImpacts[getTokenID(token)]))}
          setTradeDisabled={setTradeDisabled}
          setConfirmVisible={crossChainToggled ? setCrossChainConfirmVisible : setConfirmVisible}
        />
        <NFTCheckModal
          dataTestId="nft-check-modal"
          visible={nftCheckVisible}
          setVisible={setNFTCheckVisible}
          setTradeDisabled={setTradeDisabled}
          setNextVisible={Object.values(priceImpacts).filter((impact) => impact <= -1).length > 0 ? setImpactVisible : setConfirmVisible}
        />
        <ConfirmationModal
          visible={confirmVisible}
          setVisible={setConfirmVisible}
          setTradeDisabled={setTradeDisabled}
          tokensFrom={tokensFrom}
          tokensTo={tokensTo}
          specifiedAmounts={fromInputs ? amountsFrom : amountsTo}
          splitAmounts={splitAmounts}
          slippage={slippage}
          fromInputs={fromInputs}
          selectedNFTs={fromInputs ? selectedNFTsFrom : selectedNFTsTo}
          setSelectedNFTs={fromInputs ? setSelectedNFTsFrom : setSelectedNFTsTo}
          setTxSuccess={setTxSuccess}
        />
        {crossChain && (
          <CrossChainConfirmationModal
            visible={crossChainConfirmVisible}
            setVisible={setCrossChainConfirmVisible}
            setTradeDisabled={setTradeDisabled}
            tokensFrom={tokensFrom}
            tokensTo={tokensTo}
            specifiedAmounts={fromInputs ? amountsFrom : amountsTo}
            splitAmounts={splitAmounts}
            slippage={slippage}
            fromInputs={fromInputs}
            selectedNFTs={fromInputs ? selectedNFTsFrom : selectedNFTsTo}
            tokenDecimals={tokenDecimals[getTokenID(tokensFrom[0])]}
            setTxSuccess={setTxSuccess}
            crossChain={crossChain}
            crossChainTo={crossChainTo}
          />
        )}
      </View>
      <ButtonsContainer>
        {!errorText && !warningVisible && isConnected ? (
          (crossChainToggled && activeChain?.id !== allChains[crossChain].chainId) || (!crossChainToggled && activeChain?.name !== connectedChain.name) ? (
            <ExecuteButton onClick={() => changeNetwork(crossChainToggled ? allChains[crossChain] : connectedChain)}>
              <img
                src={(crossChainToggled ? allChains[crossChain] : connectedChain).icon}
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: "8px",
                  borderRadius: "4px",
                }}
              />
              Switch to {crossChainToggled ? crossChain : connectedChain.name.split(" ")[0]}
            </ExecuteButton>
          ) : tokenToApprove ? (
            <ApproveButton
              disabled={approveDisabled}
              walletConnected={isConnected}
              chainConnected={validChain}
              onClick={approveToken}
              tokenToApprove={tokenToApprove}
            >
              Approve {tokenToApprove.symbol}
            </ApproveButton>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {!errorText && !warningVisible && (
          <TradeButton
            disabled={tradeDisabled || typeof tokenToApprove != "undefined"}
            walletConnected={isConnected}
            chainConnected={validChain}
            onClick={openConfirmation}
          >
            {tradeLabel}
          </TradeButton>
        )}
      </ButtonsContainer>
      {errorText && <ErrorAlert>{errorText}</ErrorAlert>}
      {warningVisible && <WarningAlert>{warningText}</WarningAlert>}
      {!isConnected && <Text>Connect your wallet to execute trade.</Text>}
      <ShellGuideModal guideTab={guideTab} setGuideTab={setGuideTab} />
    </Content>
  );
};

export const View = styled(Box)<{ isTokensSwaping?: boolean }>`
  padding: 20px;

  ${Media.tablet} {
    padding: 12px 12px 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 24px;
`;

const Text = styled.p`
  margin-top: 15px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #00bdff;
`;

const ViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px 2px;
  margin-bottom: 8px;
`;

const SwapButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  margin: 2px auto 0px;

  ${Media.tablet} {
    margin: -8px auto -8px;
  }
`;

const SpinContainer = styled.div`
  > img {
    width: 52px;
    height: 52px;
  }
`;

const SwapButton = styled.button`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #171b33;
  border: 1px solid rgba(255, 255, 255, 0.03);
  z-index: 10;

  &:hover {
    box-shadow: 0px 0px 25px rgba(43, 213, 244, 0.2);
    border: 1px solid #2c5173;
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

import React, { useContext } from "react";
import * as S from "../Vesting.styled";
import shellIcon from "@/assets/coins/SHELL.svg";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";
import { formatDisplay } from "@/utils/formatDisplay";
import { parseEther } from "ethers/lib/utils";
import { GeoContext } from "@/components/Overlays/GeoProvider";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";

export const VestingBox = () => {
  const {
    claimAllStreams,
    handleOpenClaimStakeModal,
    vestingStreams,
    vestingEndTime,
    withdrawableAmount,
  } = useRewardsContext();

  const totalAvailable = vestingStreams
    .map((stream) => parseFloat(stream.claimable))
    .reduce((partialSum, a) => partialSum + a, 0);
  const totalVesting = vestingStreams
    .map((stream) => parseFloat(stream.vesting))
    .reduce((partialSum, a) => partialSum + a, 0);

  const nextDailyClaim =
    (24 * 60 * 60 * totalVesting) / (vestingEndTime - Date.now() / 1000);

  const { vestingLoading } = useRewardsContext();
  const { isGeoLoading } = useContext(GeoContext);

  return (
    <S.VestingDetailsContainer>
      <S.HeaderRowWrapper>
        <S.LabelAndValueWrapper>
          <S.LabelWrapper>
            <S.Label>Available to claim</S.Label>
            {/* <CustomTooltip position="top" title="vesting" subtext="Vesting tooltip" delay={50}>
                            <S.TooltipImage>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_8144_606)">
                                        <path d="M6.99972 12.8337C10.2214 12.8337 12.833 10.222 12.833 7.00033C12.833 3.77866 10.2214 1.16699 6.99972 1.16699C3.77805 1.16699 1.16638 3.77866 1.16638 7.00033C1.16638 10.222 3.77805 12.8337 6.99972 12.8337Z" stroke="#7D7D97" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7 9.33333V7" stroke="#7D7D97" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7 4.66699H7.00667" stroke="#7D7D97" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_8144_606">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </S.TooltipImage>
                        </CustomTooltip> */}
          </S.LabelWrapper>
          <S.VestingValueWrapper>
            <S.ShellImage src={shellIcon} alt="shell coin" />
            {vestingLoading || isGeoLoading ? (
              <SkeletonBox
                isLoading={true}
                width="100px"
                height="30px"
                borderRadius="8px"
              />
            ) : (
              <S.Value>{formatDisplay(totalAvailable.toString(), 2)}</S.Value>
            )}
          </S.VestingValueWrapper>
        </S.LabelAndValueWrapper>
        <S.ActionsWrapper>
          <S.CustomPrimaryBtn
            onClick={() => handleOpenClaimStakeModal(0, totalAvailable)}
            disabled={parseFloat(withdrawableAmount) > 0 || totalAvailable == 0}
          >
            Stake
          </S.CustomPrimaryBtn>
          <S.CustomSecondaryBtn
            disabled={totalAvailable == 0}
            onClick={() =>
              claimAllStreams(
                vestingStreams.map((stream) => stream.id),
                vestingStreams.map((stream) => parseEther(stream.claimable)),
                totalAvailable
              )
            }
          >
            Claim
          </S.CustomSecondaryBtn>
        </S.ActionsWrapper>
      </S.HeaderRowWrapper>
      <S.HrDiv />
      <S.DistributionTimeWrapper>
        <S.DistributionLabel>Next 24h unlock:</S.DistributionLabel>
        <S.DistributionTime>
          {formatDisplay(nextDailyClaim.toString(), 2)} SHELL
        </S.DistributionTime>
      </S.DistributionTimeWrapper>
    </S.VestingDetailsContainer>
  );
};

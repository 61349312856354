import React, { useEffect, useMemo, useState } from "react";
import * as S from "./ConfirmationModals.styled";
import { TransparentInput } from "@/components/Input/TransparentInput";
import { CustomTooltip } from "@/components/CustomTooltip/CustomTooltip";
import darkenShellLogo from "@/assets/coins/shell-coin-darken-logo.svg";
import lighterShellLogo from "@/assets/coins/shell-coin-lighter-logo.svg";
import arrowRight from "@/assets/icons/arrow-right-line.svg";
import { lockPeriods } from "@/pages/Rewards/hooks/useStakingHandler";
import { useRewardsContext } from "@/pages/Rewards";
import { LockPeriodBox } from "../Staking/components/LockPeriodBox";
import shellIcon from '@/assets/coins/SHELL.svg'
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";

type StakeModaBodyProps = {
    isClaimVariant?: boolean;
}

export const StakeModalBody = ({
    isClaimVariant
}: StakeModaBodyProps) => {
    const {
        myStaked,
        currentLockPeriod,
        savedLockPeriod,
        myRewards,
        handleSelectLockPeriod,
        handleInitStake,
        amountToStake,
        onChangeStakeAmount,
        myAvailableBalance,
        handleStakeMax,
        getUnlockTime,
        projectedVeShell,
        stakeApprovalState,
        stakeBalanceError,
        approveStake,
        handleClaimAndStake,
        streamToClaim
    } = useRewardsContext();
    const { endTime } = {...savedLockPeriod};

    const AvailableBalance = () => (
        <S.AvailableBalanceWrapper>
            <S.ClaimAirDropDetailsDesc>Available:</S.ClaimAirDropDetailsDesc>
            <S.GeneralInfoWhiteText>{formatDisplay(myAvailableBalance, 2)} SHELL</S.GeneralInfoWhiteText>
        </S.AvailableBalanceWrapper>
    );

    const MaxButton = () => <S.MaxBtn onClick={handleStakeMax}>MAX</S.MaxBtn>;

    return (
      <S.BodyContainer>
        <S.ModalTitle>
          {isClaimVariant ? "Claim & Stake your SHELL" : "Stake your SHELL"}
        </S.ModalTitle>
        <TransparentInput
          label="Amount"
          coinLogo={<S.CoinIcon src={shellIcon} alt="shell coin" />}
          value={
            amountToStake > 0 ? amountToStake.toString() : ''
          }
          onChange={(e) => onChangeStakeAmount(e)}
          disabled={isClaimVariant}
          additionalLabel={!isClaimVariant && <AvailableBalance />}
          additionalActionButton={!isClaimVariant && <MaxButton />}
        />
        {!savedLockPeriod && (
          <S.LockPeriodsWrapper>
            <S.LockPeriodTitleWrapper>
              <S.LockPeriodTitle>Lock period</S.LockPeriodTitle>
              <CustomTooltip
                position="right"
                title="Select Lock Period"
                subtext="Choose your lock duration. Remember, you can extend it anytime for a higher APY. Longer locks lead to better returns."
                delay={50}
              />
            </S.LockPeriodTitleWrapper>
            <S.LockPeriodBoxesWrapper>
              {lockPeriods.map((lockPeriod, index) => (
                <LockPeriodBox
                  key={`${lockPeriod.label}-${index}`}
                  onSelect={() => handleSelectLockPeriod(lockPeriod)}
                  selected={currentLockPeriod.label === lockPeriod.label}
                  isDefault={lockPeriod?.isDefault}
                  lockPeriod={lockPeriod}
                />
              ))}
            </S.LockPeriodBoxesWrapper>
            <S.LockPeriodDescription>
              You will not be able to redeem your staked SHELL until the end of the lock period. 
              You can stake more SHELL until the end of the lock period or increase the lock period at any time.
            </S.LockPeriodDescription>
          </S.LockPeriodsWrapper>
        )}
        <S.GeneralStakeInfoContainer>
          <S.GeneralSingleInfoWrapper style={{ paddingBottom: "12px" }}>
            <S.GeneralSingleLeftSide>
              {!savedLockPeriod ? (
                <S.GeneralInfoLabel>You will receive</S.GeneralInfoLabel>
              ) : (
                <>
                  <S.GeneralInfoLabel>Voting Power increase</S.GeneralInfoLabel>
                  <CustomTooltip
                    position={"top"}
                    title="Voting Power"
                    subtext="The amount of veSHELL you have represents your current voting strength in governance. Your voting power decreases linearly over time, so consider restaking periodically to maintain it."
                    delay={50}
                  />                    
                </>
              )}
            </S.GeneralSingleLeftSide>
            <S.GeneralSingleRightSide>
              {amountToStake ? (
                !savedLockPeriod ? (
                  <>
                    <S.ValueWrapper>
                      <S.ShellCoinImg
                        src={lighterShellLogo}
                        alt="shell coin logo"
                      />
                      <S.GeneralInfoWhiteText>
                        {formatDisplay(projectedVeShell.toString())} veSHELL
                      </S.GeneralInfoWhiteText>
                    </S.ValueWrapper>
                    <S.GeneralInfoGrayText>
                      (Voting Power)
                    </S.GeneralInfoGrayText>
                  </>
                ) : (
                  <>
                    <S.GeneralInfoValueWrapper>
                      <S.ShellCoinImg
                        src={darkenShellLogo}
                        alt="shell coin logo"
                      />
                      <S.GeneralInfoGrayText>
                        {formatDisplay(myStaked.toString(), 2)}{" "}
                      </S.GeneralInfoGrayText>
                    </S.GeneralInfoValueWrapper>
                    <S.ArrowImg src={arrowRight} alt="arrow right icon" />
                    <S.GeneralInfoValueWrapper>
                      <S.ShellCoinImg
                        src={lighterShellLogo}
                        alt="shell coin logo"
                      />
                      <S.GeneralInfoWhiteText>
                        {formatDisplay((myStaked + projectedVeShell).toString(), 2)}
                      </S.GeneralInfoWhiteText>
                    </S.GeneralInfoValueWrapper>
                  </>
                )
              ) : (
                <S.GeneralInfoGrayText>-</S.GeneralInfoGrayText>
              )}
            </S.GeneralSingleRightSide>
          </S.GeneralSingleInfoWrapper>
          <S.GeneralSingleInfoWrapper
            style={{ padding: "12px 0 0 0", border: "none" }}
          >
            <S.GeneralSingleLeftSide>
              {!savedLockPeriod ? (
                <S.GeneralInfoLabel>
                  Tokens will be locked until
                </S.GeneralInfoLabel>
              ) : (
                <>
                  <S.GeneralInfoLabel>Unlock time</S.GeneralInfoLabel>
                  <CustomTooltip
                    position="top"
                    title="Unlock Time"
                    subtext="Shows when your staked tokens will be available to claim. A longer period boosts both your voting power and rewards."
                    delay={50}
                  />                    
                </>
              )}
            </S.GeneralSingleLeftSide>
            <S.GeneralSingleRightSide>
              <S.GeneralInfoWhiteText>
                {/* {format(add(new Date(), { ...currentLockPeriod.period }), "dd/MM/yyyy, hh:mm:ss")} */}
                {timestampToDate((endTime ? endTime : getUnlockTime(currentLockPeriod.label)).toString())}
              </S.GeneralInfoWhiteText>
            </S.GeneralSingleRightSide>
          </S.GeneralSingleInfoWrapper>
        </S.GeneralStakeInfoContainer>
        <S.ButtonsContainer>
          {stakeApprovalState.length > 0 && !(!isClaimVariant && stakeBalanceError) && (
            <S.CustomPrimaryBtnStake
              disabled={stakeApprovalState == "Pending"}
              onClick={approveStake}
            >
              Approve
            </S.CustomPrimaryBtnStake>
          )}
          <S.CustomPrimaryBtnStake
            onClick={isClaimVariant ? handleClaimAndStake : handleInitStake}
            disabled={
              amountToStake == 0 || (!isClaimVariant && stakeBalanceError) || stakeApprovalState.length > 0
            }
          >
            {isClaimVariant
              ? "Claim & Stake"
              : stakeBalanceError
              ? "Insufficient SHELL"
              : "Stake"}
          </S.CustomPrimaryBtnStake>
        </S.ButtonsContainer>
      </S.BodyContainer>
    );
};

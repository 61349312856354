import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import { HISTORY_API } from "@/constants/urls";

import { SearchNotFoundMessage } from "@/components/Messages/SearchNotFoundMessage";
import { LogoLoader } from "../../components/Loaders";
import { Box } from "../../components/Layout/Box";
import { Content } from "@/components/Layout/Content";
import { Media, breakpoints } from "@/styles";
import { useWidthBreakpoint } from "@/hooks";
import { useAppDispatch } from "@/store/hooks";
import { addInteraction } from "@/store/transactionsSlice";
import { BackArrowIcon } from "@/components/Icons/BackArrowIcon";
import { queryInteraction } from "./interactionHelpers";
import { TransactionTable, Transaction } from "../../components/Table/TransactionTable/TransactionTable";
import { TransactionTale } from "@/components/Table/TransactionTable/TransactionTale";
import { useNavigationHelpers } from "@/hooks/navigation";
import { ChainContext } from "@/components/Overlays/ChainProvider";

type Interaction = {
  id: string;
  inputToken: string;
  inputAmount: string;
  outputToken: string;
  outputAmount: string;
  dust?: string;
  externalContract: string;
  type: "ERC20Wrap" | "ComputeOutputAmount" | "EtherUnwrap";
  feeCharged?: string;
};

export const TransactionSearchScreen = () => {
  const { hash } = useParams() as { hash: string };
  const { handleGoBack } = useNavigationHelpers();

  const isTablet = useWidthBreakpoint(breakpoints.tablet)

  const [data, setData] = useState<Transaction[] | null>(null)
  const [interaction, setInteraction] = useState<Interaction | null>(null)
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const { connectedChain } = useContext(ChainContext)

  const determineArrowType = ( inputLength: number, outputLength: number ): "merge" | "split" | "single" => {
    if (inputLength > outputLength) return "merge";
    if (inputLength < outputLength) return "split";
    return "single";
  };

  const getTokens = (interactions: Interaction[], type: "input" | "output") => {
    const primaryTokenSet = new Set(interactions.map((i) => i[`${type}Token`]));
    const opposingType = type === "input" ? "output" : "input";
    interactions.forEach((interaction) =>
      primaryTokenSet.delete(interaction[`${opposingType}Token`])
    );

    const tokens = [...primaryTokenSet];
    const amounts = tokens.map((token) => {
      const foundInteraction = interactions.find(
        (i) => i[`${type}Token`] === token
      );
      return foundInteraction ? foundInteraction[`${type}Amount`] : "0";
    });

    return { tokens, amounts };
  };

  const getTransactionData = async () => {

    const apiData = await fetch(HISTORY_API(connectedChain) + 'tx/' + hash).then((response) => response.json())
    const { transactionHash, interactions, timestamp } = apiData
    if(interactions.length == 0) return

    const { tokens: inputTokens, amounts: inputAmounts } = getTokens(interactions, "input");
    const { tokens: outputTokens, amounts: outputAmounts } = getTokens(interactions, "output");

    const arrowType = determineArrowType(inputTokens.length, outputTokens.length)

    const transaction: Transaction = {
      transactionHash,
      input: {
        tokens: inputTokens,
        amounts: inputAmounts,
      },
      output: {
        tokens: outputTokens,
        amounts: outputAmounts,
      },
      arrowType: arrowType,
      clickLink: transactionHash,
      tokenIDs: inputTokens.concat(outputTokens),
      expanded: true,
      timestamp: timestamp
    }

    setData([transaction])

    const interactionData = await queryInteraction(connectedChain, transactionHash, transaction.input, transaction.output, arrowType, apiData)

    // setInteraction(interactionData)

    dispatch(
        addInteraction({
          chain: connectedChain.name, 
          hash: transactionHash,
          data: {
            preArrowPaths: interactionData.preArrowPaths,
            postArrowPaths: interactionData.postArrowPaths,
            preArrowPathLength: interactionData.preArrowPathLength,
            postArrowPathLength: interactionData.postArrowPathLength,
            block: interactionData.block,
            fee: interactionData.fee
          },
        })
    );
  }


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    getTransactionData().then(() => {
        setIsLoading(false)
    }).catch(() => {
        setIsLoading(false)
    })

  }, []);

  return (
    <Content>
      {
        isLoading ?
          <LoadingWrapper>
            <LogoLoader />
          </LoadingWrapper>
          :
          <View>
            <>
              <Title onClick={() => handleGoBack()}>
                <BackArrowIcon/> {data && data.length > 0 && `Transaction Details`}
              </Title>
              {
                data && data.length > 0 ? (
                  <>
                    {isTablet ? (
                        <TransactionTale transaction={data[0]}/>
                    ) : (
                        <TransactionTable data={data} />
                    )}
                  </>
                ) : (
                    <SearchNotFoundMessage
                      searchValue={hash}
                      navPath="/explore"
                      navLabel="Back to Explore"
                    />
                )
              }
            </>
          </View>
      }
    </Content>
  )
};

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding-top: 300px;
  padding-bottom: 300px;
`;

const View = styled(Box)`
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;  

  ${Media.tablet} {
      padding: 8px 8px 12px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;

  ${Media.tablet} {
      font-size: 20px;
      gap: 8px;
      margin-bottom: 8px;
  }
`;

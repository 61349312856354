import { Media } from "@/styles";
import React from "react";
import styled, { css } from "styled-components";

interface IChartButtonsProps {
  isActive?: string;
  onClick?: (value: string) => void;
}

export const ChartButtons = ({ isActive, onClick }: IChartButtonsProps) => {
  const handleClick = (value: string) => {
    onClick && onClick(value);
  };
  return (
    <ChartButtonsContainer>
      <ChartButton
        isActive={isActive === "chart"}
        onClick={() => handleClick("chart")}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.91614 15.4085L7.91614 10.4001L11.2495 13.7335L18.3328 5.7668L17.1578 4.5918L11.2495 11.2335L7.91614 7.90013L1.66614 14.1585L2.91614 15.4085Z"
            fill="#7D7D97"
          />
        </svg>
      </ChartButton>
      <ChartButton
        isActive={isActive === "gallery"}
        onClick={() => handleClick("gallery")}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM11.7833 9.88333L9.28333 13.1083L7.5 10.95L5 14.1667H15L11.7833 9.88333Z"
            fill="#7D7D97"
          />
        </svg>
      </ChartButton>
    </ChartButtonsContainer>
  );
};

const ChartButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ChartButton = styled.div<{ isActive: boolean }>`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  background: #151530;
  cursor: pointer;

  svg {
    display: block;
    transition: all 0.3s ease;
  }

  &:hover {
    background: transparent;
    border-color: #2C5173;
    svg {
      path {
        fill: #2C5173;
      }
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: transparent;
      border-color: #00bdff;
      svg {
        path {
          fill: #00bdff;
        }
      }
    `};
    ${Media.tablet} {
      padding: 4px;
    }
`;
{
  /* <stop stopColor="#37DCF2" /> */
}
{
  /* <stop offset="1" stopColor="#07C0FB" /> */
}

import { Edge } from "./LiquidityGraph";
import { ETH_ADDRESS } from "@/constants/addresses";

export const combineSplit = (paths: Edge[][]) => {

    paths = paths.sort((a, b) => b.length - a.length)
    if(paths[1].at(-1)?.action == 'Wrap' && paths[1].at(-1)?.token.address == ETH_ADDRESS){
        paths.reverse()
    }

    const sharedPath = []
    while(paths[0].length && paths[1].length){
        const first = paths[0][0]
        const second = paths[1][0]
        
        if(first.action == second.action && first.token.symbol == second.token.symbol && first.pool == second.pool){
            paths[0].shift()
            paths[1].shift()
            sharedPath.push(first)
        } else {
            break
        }
    }

    if(paths[0].length > 0) paths[0].unshift({token: sharedPath.at(-1)!.token, action: '', pool: ''})
    if(paths[1].length > 0) paths[1].unshift({token: sharedPath.at(-1)!.token, action: '', pool: ''})

    return {sharedPath: sharedPath, paths: paths}

}

export const combineMerge = (paths: Edge[][]) => {
    if(paths.length == 1) return {sharedPath: [{token: paths[0].at(-1)!.token, action: '', pool: ''}], paths: paths}

    const sharedPath = []

    while(paths[0].length && paths[1].length){
        const first = paths[0].at(-1)!
        const second = paths[1].at(-1)!

        if(first.action == second.action && first.token.symbol == second.token.symbol && first.pool == second.pool){
            if(paths[0].at(-2)?.token.symbol !== paths[1].at(-2)?.token.symbol) break
            paths[0].pop()
            paths[1].pop()
            sharedPath.unshift(first)
        } else {
            sharedPath.unshift({token: paths[0].at(-1)!.token, action: '', pool: ''})
            break
        }
    }

    return {sharedPath: sharedPath, paths: paths}
}






import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperClass } from "swiper";
import styled from "styled-components";
import { NFT, VestingStream } from "../../../utils/tokens";
import { Media, breakpoints } from "../../../styles";
import selectItem from "../../../assets/icons/select-item.svg";
import { useAppSelector } from "../../../store/hooks";
import NFTSelectionalModal from "./NFTSelectionModal";
import { useWidthBreakpoint } from "@/hooks";
import addIcon from "@/assets/icons/add-line.svg";
import blueEye from "@/assets/icons/blue-eye.svg";
import { InsideVestingStreams } from "@/components/Collections/InsideVestingStreams";
import { NFTDetailsModalBody } from "./NFTDetailsModalBody";
import { Modal } from "@/components/Modal/Modal";

interface INFTsSwiperProps {
  allSelectedNFTs: { [collection: string]: NFT[] };
  nftTokens: NFT[];
  isInputToken: boolean;
  navitagionTransform?: { left: string; right: string };
  maxWidth?: string;
  updateSelectedNFTs?: any;
  selectedCollection: any;
  nftSweepInputPrice?: null | string;
  onNFTSweepSelect?: (collection: string, items: NFT[]) => void;
  onClose?: () => void;
  isVestingStreamVariant?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const NFTsSwiperSelect = ({
  allSelectedNFTs,
  nftTokens,
  isInputToken,
  navitagionTransform,
  maxWidth,
  updateSelectedNFTs,
  selectedCollection,
  nftSweepInputPrice,
  onNFTSweepSelect,
  isVestingStreamVariant,
  setIsVisible
}: INFTsSwiperProps) => {
  const [canSwipe, setCanSwipe] = useState<{ isBeginning: boolean; isEnd: boolean; }>({ isBeginning: true, isEnd: false });
  const navigationPrevRef = React.useRef<HTMLDivElement>(null);
  const swiperRef = React.useRef<{ swiper: SwiperClass }>(null);
  const navigationNextRef = React.useRef<HTMLDivElement>(null);

  const [isSelectNFTsButtonDisabled, setIsSelectNFTsButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNFTDetailsModalOpen, setIsNFTDetailsModalOpen] = useState(false);
  const [selectedTokenForDetails, setSelectedTokenForDetails] = useState<undefined | NFT | VestingStream>(undefined);
  const [isMaskVisible, setIsMaskVisible] = useState<{ visibility: boolean, id: null | number | string }>({ visibility: false, id: null });

  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);

  const isMobile = useWidthBreakpoint(breakpoints.mobile);

  const handleTokenDetailsClick = (token: NFT | VestingStream) => {
    setSelectedTokenForDetails(token);
    setIsNFTDetailsModalOpen(true);
  };

  const nextSlide = () => {
    swiperRef.current?.swiper?.slideNext();
    setCanSwipe({ ...canSwipe, isBeginning: false, isEnd: swiperRef.current?.swiper.isEnd! });
  };

  const prevSlide = () => {
    swiperRef.current?.swiper?.slidePrev();
    setCanSwipe({ ...canSwipe, isBeginning: swiperRef.current?.swiper.isBeginning!, isEnd: false });
  };

  const openModal = () => {
    if (setIsVisible) setIsVisible(true);
  }

  useEffect(() => {
    if (isInputToken) {
      setIsSelectNFTsButtonDisabled(!(userNFTBalances?.[selectedCollection.symbol]?.length > 0));
    } else {
      setIsSelectNFTsButtonDisabled(false);
    }
  }, [userNFTBalances, selectedCollection, isInputToken]);

  return isVestingStreamVariant ? (
    <>
      <TokensContainer
        navitagionTransform={navitagionTransform}
        maxWidth={maxWidth}
      >
        <div
          className={`button-prev ${canSwipe.isBeginning ? "disabled" : ""}`}
          ref={navigationPrevRef}
          onClick={prevSlide}
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M3.33268 8H12.666" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div
          className={`button-next ${canSwipe.isEnd ? "disabled" : ""}`}
          ref={navigationNextRef}
          onClick={nextSlide}
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M3.33268 8H12.666" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <Swiper
          ref={swiperRef}
          slidesPerView={'auto'}
          slidesPerGroup={isMobile ? 4 : 7}
          className="swiper-container"
          onUpdate={(swiper) => setCanSwipe({ ...canSwipe, isBeginning: swiper.isBeginning, isEnd: swiper.isEnd, })}
          onReachBeginning={() => {
            setCanSwipe({ ...canSwipe, isBeginning: true, isEnd: false });
          }}
          onReachEnd={() => {
            setCanSwipe({ ...canSwipe, isBeginning: false, isEnd: true });
          }}
        >
          <SwiperSlide>
            <TokenSummary>
              <ViewAllButton data-testid="select-nfts-btn" onClick={() => setShowModal(!showModal)} disabled={isSelectNFTsButtonDisabled}>
                <img style={{ marginTop: '3px' }} src={selectItem} alt="select" />
              </ViewAllButton>
              <TokenID>Select...</TokenID>
            </TokenSummary>
          </SwiperSlide>
          {nftTokens && nftTokens.map((token) => (
            <SwiperSlide key={token.id}>
              <InfoModalTrigger 
              onMouseEnter={() => setIsMaskVisible({ visibility: true, id: token.id })} 
              onMouseLeave={() => setIsMaskVisible({ visibility: false, id: null })}
              >
                <TokenSummary onClick={() => handleTokenDetailsClick(token as VestingStream)}>
                  <Image src={token.image} />
                  <TokenID>#{token.id}</TokenID>
                  <InfoMask isVisible={isMaskVisible.visibility && isMaskVisible.id === token.id}>
                    <img src={blueEye} alt="eye icon" width={24} height={24} />
                  </InfoMask>
                </TokenSummary>
              </InfoModalTrigger>
            </SwiperSlide>
          ))}
        </Swiper>
        <StyledModal title="NFT Details" isVisible={isNFTDetailsModalOpen} onClose={() => setIsNFTDetailsModalOpen(false)}>
          <NFTDetailsModalBody nft={selectedTokenForDetails as VestingStream} />
        </StyledModal>
        <InsideVestingStreams 
          title="Select Shellstream"
          onClose={() => setShowModal(false)}
          showModal={showModal}
          setShowModal={setShowModal}
          onConfirm={(streams: VestingStream[]) => updateSelectedNFTs(selectedCollection.symbol, streams)}
          selectedCollection={selectedCollection}
          isInputToken={isInputToken}
        />
      </TokensContainer>
    </>
  ) : (
    <TokensContainer
      navitagionTransform={navitagionTransform}
      maxWidth={maxWidth}
    >
      <div
        className={`button-prev ${canSwipe.isBeginning ? "disabled" : ""}`}
        ref={navigationPrevRef}
        onClick={prevSlide}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M3.33268 8H12.666" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <div
        className={`button-next ${canSwipe.isEnd ? "disabled" : ""}`}
        ref={navigationNextRef}
        onClick={nextSlide}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M3.33268 8H12.666" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <Swiper
        ref={swiperRef}
        slidesPerView={'auto'}
        slidesPerGroup={isMobile ? 4 : 7}
        className="swiper-container"
        onUpdate={(swiper) => { setCanSwipe({ ...canSwipe, isBeginning: swiper.isBeginning, isEnd: swiper.isEnd, }); }}
        onReachBeginning={() => {
          setCanSwipe({ ...canSwipe, isBeginning: true, isEnd: false });
        }}
        onReachEnd={() => {
          setCanSwipe({ ...canSwipe, isBeginning: false, isEnd: true });
        }}
      >
        <SwiperSlide>
          <TokenSummary>
            <ViewAllButton data-testid="select-nfts-btn" onClick={() => setShowModal(!showModal)} disabled={isSelectNFTsButtonDisabled}>
              <img style={{ marginTop: '3px' }} src={selectItem} alt="select" />
            </ViewAllButton>
            <TokenID>Select...</TokenID>
          </TokenSummary>
        </SwiperSlide>
        {nftTokens && nftTokens.map((token) => (          
          <SwiperSlide key={token.id}>
          <InfoModalTrigger 
            onMouseEnter={() => setIsMaskVisible({ visibility: true, id: token.id })} 
            onMouseLeave={() => setIsMaskVisible({ visibility: false, id: null })}
          >
            <TokenSummary onClick={() => handleTokenDetailsClick(token as NFT)}>
              <Image src={token.image} />
              <TokenID>#{token.id}</TokenID>
              <InfoMask isVisible={isMaskVisible.visibility && isMaskVisible.id === token.id}>
                <img src={blueEye} alt="eye icon" width={24} height={24} />
              </InfoMask>
            </TokenSummary>
          </InfoModalTrigger>
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledModal title="NFT Details" isVisible={isNFTDetailsModalOpen} onClose={() => setIsNFTDetailsModalOpen(false)}>
          <NFTDetailsModalBody nft={selectedTokenForDetails as NFT} />
      </StyledModal>
      <NFTSelectionalModal
        title={`${isInputToken ? "Your" : "Available"} NFTs`}
        allSelectedNFTs={allSelectedNFTs}
        nftTokens={nftTokens}
        isInputToken={isInputToken}
        selectedCollection={selectedCollection}
        updateSelectedNFTs={updateSelectedNFTs}
        nftSweepInputPrice={nftSweepInputPrice}
        showModal={showModal}
        setShowModal={setShowModal}
        onNFTSweepSelect={onNFTSweepSelect}
        onClose={() => setShowModal(false)}
      />
    </TokensContainer>
  );
};

const StyledModal = styled(Modal)`
  height: fit-content;
  max-width: 896px;

  ${Media.mobile} {
    height: 95%;
    max-width: 100%;
    margin-top: 5.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-bottom: 0;
  }
`

const TokensContainer = styled.div<{
  navitagionTransform?: { left: string; right: string };
  maxWidth?: string;
  isConfirmation?: boolean;
}>`
    max-width: ${({ maxWidth, isConfirmation }) =>
    maxWidth ? maxWidth + "px" : isConfirmation ? "100%" : "586px"};
    display: flex;
    position: relative;
  
    ${Media.tablet} {
      max-width: ${({ maxWidth, isConfirmation }) =>
    maxWidth
      ? maxWidth + "px"
      : isConfirmation
        ? "100%"
        : "calc(100vw - 80px)"};
    }
  
    ${Media.mobile} {
      max-width: ${({ maxWidth, isConfirmation }) =>
    isConfirmation || maxWidth ? "100%" : "calc(100vw - 80px)"};
      padding: ${({ isConfirmation }) => (isConfirmation ? "5px" : "none")};
  
      .button-prev {
        transform: translate(-24px, -100%) !important;
      }
      .button-next {
        transform: translate(24px, -100%) !important;
      }
    }
  
    .swiper {
      margin-inline: unset;
    }
  
    .swiper-slide {
      width: 68px !important;
      &:not(:last-child) {
        margin-right: 16px;
      }
      ${Media.tablet}{
        &:not(:last-child) {
          margin-right: 18px;
        }
      }
      ${Media.smallMobile}{
        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
    .button-prev,
    .button-next {
      position: absolute;
      top: 50%;
      background-color: #171b33;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      z-index: 9999;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
      }
      &:hover {
        background-color: #1e2239;
      }
    }
    .button-prev {
      left: 0;
      transform: ${({ navitagionTransform }) =>
    `translate(${navitagionTransform?.left ? navitagionTransform.left : "-36"
    }px, -100%)`};
      right: auto;
      svg {
        transform: rotate(180deg);
      }
    }
    .button-next {
      right: 0;
      transform: ${({ navitagionTransform }) =>
    `translate(${navitagionTransform?.right ? navitagionTransform.right : "24"
    }px, -100%)`};
      left: auto;
    }
`;

const Link = styled.a`
  color: white;
`;

const Image = styled.img`
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 12px;
  object-fit: cover;
`;

const TokenSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TokenID = styled.span`
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const ViewAllButton = styled.button`
  display: block;
  width: 64px;
  height: 64px;
  color: #000e47;
  border-radius: 12px;
  border: 1px solid #37dcf2;
  object-fit: cover;

  > img {
    width: 32px;
    height: 32px;
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

const AddMoreStreamsButton = styled.button`
height: 100%;
padding: 12px 20px;
border-radius: 12px;
border: 2px solid var(--gradient-2, #37DCF2);
`;

const InfoModalTrigger = styled.div`
cursor: pointer;
`;

const InfoMask = styled.div<{ isVisible?: boolean }>`
background: #12122CCC;
opacity: ${({ isVisible }) => isVisible ? "1" : "0"};
width: 100%;
height: 64px;
position: absolute;
top: 0;
border-radius: 12px;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.35s ease-in-out;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;
import React from "react";
import { useNavigate } from "react-router";
import { breakpoints } from "../../styles";
import { useWidthBreakpoint } from "@/hooks";
import styled from "styled-components";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import { useTransactions } from "./ExploreContext";
import { TransactionTable } from "@/components/Table/TransactionTable/TransactionTable";
import { TransactionTale } from "@/components/Table/TransactionTable/TransactionTale";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import rightNavArrowIcon from "../../assets/icons/right-nav-arrow.svg";

export const Transactions = () => {
  const navigate = useNavigate();
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const isMobile = useWidthBreakpoint(breakpoints.mobile);

  const { transactions, transactionsLoading } = useTransactions();

  const renderTabletView = () => (
    <FlexContainer>
      {transactionsLoading
        ? Array.from({ length: 4 }, (_, index) => (
            <SkeletonBox
              key={index}
              isLoading={true}
              height="150px"
              width="100%"
              borderRadius="16px"
            />
          ))
        : transactions.map((transaction) => (
            <TransactionTale
              key={transaction.transactionHash}
              transaction={transaction}
            />
          ))}
    </FlexContainer>
  );

  return (
    <TableContainer>
      <TableHeader>
        <TableTitle>Latest Transactions</TableTitle>
        <TableButton onClick={() => navigate({ pathname: `/history/all` })}>
          {(isMobile ? "" : "View ") + "More"}
          <img src={rightNavArrowIcon} alt="Right Nav Arrow" />
        </TableButton>
      </TableHeader>
      <Divider />
      {isTablet ? renderTabletView() : <TransactionTable />}
    </TableContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;

  ${Media.tablet} {
    margin-top: 16px;
    padding: 0px;
    border: none;
    margin-top: 16px;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.tablet} {
    align-items: center;
    gap: 16px;
  }
`;

const TableTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;
  ${Media.tablet} {
    font-size: 20px;
    padding-left: 8px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #171b33;
  margin-block: 16px;

  ${Media.tablet} {
    margin-block: 12px 16px;
  }
`;

const TableButton = styled(ButtonSecondary)`
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.tablet} {
    width: 30%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    padding-inline: 8px 0px;
    gap: 0px;
  }
`;

import React, { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { CustomModal } from "../CustomModal/CustomModal";
import shellGuideIcon from "@/assets/icons/shellGuide.svg";
import { ShellGuideModalBody } from "./ShellGuideModalBody";
import { useAccount } from "wagmi";

export const ShellGuideModal = ({ guideTab, setGuideTab }: any) => {
  const [isOpenGuideModal, setIsOpenGuideModal] = useState(false);
  const { isConnected } = useAccount();

  useEffect(() => {
    if (!document.cookie.includes("shellGuideModalViewed") && isConnected) {
      setIsOpenGuideModal(true);
    }
  }, [isConnected]);

  const handleCloseShellGuideModal = (tab: string) => {
    setIsOpenGuideModal(false);
    setGuideTab(tab);
    document.cookie = "shellGuideModalViewed=true";
  };

  const modalMobileStyles = {
    height: "100dvh",
    minHeight: "100dvh",
    maxHeight: "100dvh",
    borderRadius: "0",
    top: "0",
  };

  const modalMobileBgStyles = {
    top: "0",
    bottom: "0",
  };

  return (
    <>
      <ButtonContainer>
        <ShellGuideButton onClick={() => setIsOpenGuideModal(true)}>
          <ButtonIcon src={shellGuideIcon} alt="Shell Guide Icon" />
          <span>Shell Guide</span>
        </ShellGuideButton>
      </ButtonContainer>
      <CustomModal
        isOpen={isOpenGuideModal}
        onClose={() => handleCloseShellGuideModal(guideTab)}
        withOutsideClick
        withBackdropBlur
        withoutCloseIcon
        width="918px"
        mobileStyle={modalMobileStyles}
        mobileBgStyles={modalMobileBgStyles}
      >
        <ShellGuideModalBody onClose={handleCloseShellGuideModal} guideTab={guideTab} />
      </CustomModal>
    </>
  );
};

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
`;

const ShellGuideButton = styled.button`
  border-radius: 12px;
  border: 1px solid #1e2239;
  background: var(--dark-1, #0a0e27);
  backdrop-filter: blur(27px);
  display: flex;
  gap: 8px;
  padding: 16px;
  align-items: center;
  color: var(--White, #fff);
  cursor: pointer;

  /* Text 2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background: var(--dark-2, #171b33);
  }
`;

const ButtonIcon = styled.img`
  height: 24px;
  width: 24px;
`;

import React, { CSSProperties, useEffect, useState, useRef, HTMLAttributes } from "react";
import { useClickOutside } from "@/hooks/useClickOutside";
import { createPortal } from "react-dom";
import * as S from "./CustomModal.styled";
import closeButton from "@/assets/icons/close-button.png";
import { useDelayUnmount } from "@/hooks";

export type ModalProps = {
    title?: string;
    isOpen: boolean;
    withOutsideClick?: boolean;
    onClose: () => void;
    withoutCloseIcon?: boolean;
    withBackdropBlur?: boolean;
    icon?: string;
    message?: boolean;
    width?: string;
    style?: CSSProperties | undefined;
    mobileStyle?: CSSProperties | undefined;
    mobileBgStyles?: CSSProperties | undefined;
} & HTMLAttributes<HTMLDivElement>;

export const CustomModal = ({
    children,
    isOpen,
    onClose,
    title,
    icon,
    message,
    withOutsideClick,
    withoutCloseIcon,
    withBackdropBlur,
    width,
    style,
    mobileStyle,
    mobileBgStyles,
    ...rest
}: ModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null);
    let el = document.getElementById("modal");

    if (!el) {
        el = document.createElement("div");
        el.setAttribute("id", "modal");
        document.body.appendChild(el);
    };

    const handleOutsideClick = () => {
        if (isOpen && onClose && withOutsideClick) {
            onClose();
        }
    };

    const handleClose = () => {
        onClose();
    };

    useClickOutside(modalRef, handleOutsideClick);

    const [isFullHeight, setIsFullHeight] = useState(false);

    const checkFullHeight = () => {
        if (mobileStyle && modalRef.current) {
            const modalHeight = modalRef.current.getBoundingClientRect().height;
            const windowHeight = window.innerHeight;
            setIsFullHeight(modalHeight >= windowHeight);
        }
    };

    useEffect(() => {
        checkFullHeight();
        window.addEventListener('resize', checkFullHeight);
        return () => window.removeEventListener('resize', checkFullHeight);
    }, [isOpen, mobileStyle]);

    const shouldRenderChild = useDelayUnmount(isOpen, 220);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [isOpen])

    return createPortal(
        <>
            {shouldRenderChild && (
                <S.Background
                    withBackdropBlur={withBackdropBlur && withBackdropBlur}
                    isOpen={isOpen}
                    mobileStyle={mobileBgStyles}
                >
                    <S.Modal 
                    ref={modalRef} 
                    message={message} 
                    isOpen={isOpen} 
                    width={width} 
                    style={style}
                    mobileStyle={mobileStyle}
                    isFullHeight={isFullHeight}
                    {...rest} 
                    >
                        {!withoutCloseIcon && (
                            <S.CloseBtn src={closeButton} alt="close button icon" onClick={() => handleClose()} />
                        )}
                        {title || icon ? (
                            <S.ModalHeader>
                                <S.Title>
                                    {icon ? <img src={icon} alt="modal icon" /> : null}
                                    {title || ""}
                                </S.Title>
                            </S.ModalHeader>
                        ) : null}
                        {children}
                    </S.Modal>
                </S.Background>
            )}
        </>,
        el
    );
};
import { ExternalDefiToken, ExternalToken, LBPToken, NFTCollection, ShellToken, Token } from "@/utils/tokens";
import React, { createContext, useState, ReactNode, SetStateAction, Dispatch, useEffect, useRef } from "react";
import { useAccount, useConnect, useNetwork, useProvider, useSigner, useChainId } from "wagmi";
import {
  oceanAddress as arbitrumOcean,
  tokens as arbitrumTokens,
  shellTokens as arbitrumShellTokens,
  nftCollections as arbitrumNFTCollections,
  lbpTokens as arbitrumLBPTokens,
  externalTokens as arbitrumExternalTokens,
  externalDefiTokens as arbitrumExternalDefiTokens,
  tokenMap as arbitrumTokenMap,
} from "@/placeholders/arbitrumTokens";
import { PoolQuery } from "@/utils/PoolQuery";
import { LiquidityGraph } from "@/utils/LiquidityGraph";
import { Chain, allChains, defaultChain } from "@/placeholders/chains";
import { constants, types } from "@/utils/sor";
import { PRICES_API, NFT_PRICES_API } from "@/constants/urls";

export const changeNetwork = (chain: Chain) => {
  window.ethereum
    ?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + chain.chainId.toString(16) }],
    })
    .catch((switchError: any) => {
      if (switchError.code === 4902) {
        window.ethereum?.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x" + chain.chainId.toString(16),
              chainName: chain.name,
              nativeCurrency: {
                name: "ETH",
                symbol: "ETH",
                decimals: 18,
              },
              rpcUrls: [chain.rpcUrl],
              blockExplorerUrls: [chain.explorerUrl],
            },
          ],
        });
      }
    });
};

interface ChainContextType {
  connectedChain: Chain;
  crossChain: string;
  setCrossChain: Dispatch<SetStateAction<string>>;
  crossChainToggled: boolean;
  setCrossChainToggled: Dispatch<SetStateAction<boolean>>;
  crossChainTo: string;
  setCrossChainTo: Dispatch<SetStateAction<string>>;
  tokens: Token[];
  shellTokens: ShellToken[];
  nftCollections: NFTCollection[];
  lbpTokens: LBPToken[];
  externalTokens: ExternalToken[];
  externalDefiTokens: ExternalDefiToken[];
  tokenMap: { [id: string]: any };
  poolQuery: PoolQuery;
  liquidityGraph: LiquidityGraph;
  oceanAddress: string;
  sorTokenMap: Record<string, types.TokenInfo>;
  changeChain: (arg0: Chain) => void;
  allPrices: { [id: string]: any };
  loadedPrices: boolean;
  reloadPrices: boolean;
  setReloadPrices: Dispatch<SetStateAction<boolean>>;
  stipTokens: ExternalDefiToken[];
  poolQueryTo?: PoolQuery;
  liquidityGraphTo?: LiquidityGraph;
  tokenMapTo?: { [id: string]: any };
  sorTokenMapTo?: Record<string, types.TokenInfo>;
}

const defaultContext: ChainContextType = {
  connectedChain: defaultChain,
  crossChain: "",
  setCrossChain: () => {},
  crossChainToggled: false,
  setCrossChainToggled: () => {},
  crossChainTo: "",
  setCrossChainTo: () => {},
  tokens: arbitrumTokens,
  shellTokens: arbitrumShellTokens,
  nftCollections: arbitrumNFTCollections,
  lbpTokens: arbitrumLBPTokens,
  externalTokens: arbitrumExternalTokens,
  externalDefiTokens: arbitrumExternalDefiTokens,
  tokenMap: arbitrumTokenMap,
  poolQuery: new PoolQuery(defaultChain, "0x17ab4f67c362C88001Ab5A7A757e6ED4717384E4", "0xe116eD9406CCD00e87E75f7EF88D32AbB8b40088", arbitrumTokenMap),
  liquidityGraph: new LiquidityGraph(
    defaultChain,
    arbitrumTokens,
    arbitrumShellTokens,
    [...arbitrumNFTCollections],
    arbitrumLBPTokens,
    arbitrumExternalTokens,
    arbitrumExternalDefiTokens,
    arbitrumTokenMap
  ),
  oceanAddress: arbitrumOcean,
  sorTokenMap: {},
  changeChain: () => {},
  allPrices: {},
  loadedPrices: false,
  reloadPrices: false,
  setReloadPrices: () => {},
  stipTokens: [],
};

export const ChainContext = createContext(defaultContext);

export const validChains = new Set(["Arbitrum One", "Base", "Optimism", "Ethereum"]);

interface ChainProviderProps {
  children: ReactNode;
}

export const ChainProvider: React.FC<ChainProviderProps> = ({ children }) => {
  const [crossChainToggled, setCrossChainToggled] = useState(false);
  const [crossChain, setCrossChain] = useState("");
  const [crossChainTo, setCrossChainTo] = useState("");

  const { chain: activeChain } = useNetwork();
  const { isConnected } = useAccount();

  const [connectedChain, setConnectedChain] = useState(defaultContext.connectedChain);

  const [tokens, setTokens] = useState<Token[]>(defaultContext.tokens);
  const [shellTokens, setShellTokens] = useState<ShellToken[]>(defaultContext.shellTokens);
  const [nftCollections, setNFTCollections] = useState<NFTCollection[]>(defaultContext.nftCollections);
  const [lbpTokens, setLBPTokens] = useState<LBPToken[]>(defaultContext.lbpTokens);
  const [externalTokens, setExternalTokens] = useState<ExternalToken[]>(defaultContext.externalTokens);
  const [externalDefiTokens, setExternalDefiTokens] = useState<ExternalDefiToken[]>(defaultContext.externalDefiTokens);
  const [tokenMap, setTokenMap] = useState<any>(defaultContext.tokenMap);
  const [poolQuery, setPoolQuery] = useState<PoolQuery>(defaultContext.poolQuery);
  const [liquidityGraph, setLiquidityGraph] = useState<LiquidityGraph>(defaultContext.liquidityGraph);
  const [oceanAddress, setOceanAddress] = useState<string>(defaultContext.oceanAddress);
  const [sorTokenMap, setSorTokenMap] = useState<Record<string, types.TokenInfo>>(defaultContext.sorTokenMap);
  const [stipTokens, setStipTokens] = useState<ExternalDefiToken[]>([]);

  const [allPrices, setAllPrices] = useState({});
  const [loadedPrices, setLoadedPrices] = useState(false);
  const [reloadPrices, setReloadPrices] = useState(true);

  const queryAddresses: { [id: string]: any } = {
    "Arbitrum One": { current: "0x17ab4f67c362C88001Ab5A7A757e6ED4717384E4", old: "0xe116eD9406CCD00e87E75f7EF88D32AbB8b40088" },
    Base: { current: "0x0c8fAeDC4Ab8d7Ba6DD84809e7E00cf892ccD59A", old: "" },
    Optimism: { current: "0xC32eB36f886F638fffD836DF44C124074cFe3584", old: "" },
    Ethereum: { current: "0x0c8fAeDC4Ab8d7Ba6DD84809e7E00cf892ccD59A", old: "" },
    
  };
  const [tokenMapTo, setTokenMapTo] = useState<any>({});
  const [poolQueryTo, setPoolQueryTo] = useState<PoolQuery>();
  const [liquidityGraphTo, setLiquidityGraphTo] = useState<LiquidityGraph>();
  const [sorTokenMapTo, setSorTokenMapTo] = useState<Record<string, types.TokenInfo>>();

  const initializeSorTokenMap = async (chain: Chain) => {
    try {
      const response = await fetch(constants.INFO_TOKENS_ENDPOINT + chain.chainId, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }
      const tokenData = await response.json();
      setSorTokenMap(tokenData.tokenMap);
    } catch (error) {
      console.error("Error fetching token map:", error);
    }
  };

  const changeChain = async (chain: Chain) => {
    const {
      oceanAddress,
      tokens,
      shellTokens,
      nftCollections,
      lbpTokens,
      externalTokens,
      externalDefiTokens,
      tokenMap,
    } = require(`../../placeholders/${chain.name.split(" ")[0].toLowerCase()}Tokens.ts`);

    setConnectedChain(chain);
    setTokens(tokens);
    setShellTokens(shellTokens);
    setNFTCollections(nftCollections);
    setLBPTokens(lbpTokens);
    setExternalTokens(externalTokens);
    setExternalDefiTokens(externalDefiTokens);
    setTokenMap(tokenMap);
    setPoolQuery(new PoolQuery(chain, queryAddresses[chain.name].current, queryAddresses[chain.name].old, tokenMap));
    setOceanAddress(oceanAddress);
    initializeSorTokenMap(chain);

    const graph = new LiquidityGraph(chain, tokens, shellTokens, [...nftCollections], lbpTokens, externalTokens, externalDefiTokens, tokenMap);

    if (chain.name == defaultChain.name) {
      const stakedTokens = await graph.initializeSTIPTokens(externalDefiTokens, shellTokens, tokenMap);
      setStipTokens(stakedTokens);
    }

    setLiquidityGraph(graph);
  };

  const initCrossChainTo = async (chain: Chain) => {
    const {
      tokens,
      shellTokens,
      nftCollections,
      lbpTokens,
      externalTokens,
      externalDefiTokens,
      tokenMap,
    } = require(`../../placeholders/${chain.name.split(" ")[0].toLowerCase()}Tokens.ts`);

    setTokenMapTo(tokenMap);

    setPoolQueryTo(new PoolQuery(chain, queryAddresses[chain.name].current, queryAddresses[chain.name].old, tokenMap));

    const graph = new LiquidityGraph(chain, tokens, shellTokens, [...nftCollections], lbpTokens, externalTokens, externalDefiTokens, tokenMap);

    if (chain.name == defaultChain.name) {
      const stakedTokens = await graph.initializeSTIPTokens(externalDefiTokens, shellTokens, tokenMap);
      setStipTokens(stakedTokens);
    }

    setLiquidityGraphTo(graph);

    try {
      const response = await fetch(constants.INFO_TOKENS_ENDPOINT + chain.chainId, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }
      const tokenData = await response.json();
      setSorTokenMapTo(tokenData.tokenMap);
    } catch (error) {
      console.error("Error fetching token map:", error);
    }

    setLoadedPrices(false);

    updatePrices(chain).then((result) => {
      setAllPrices((prevPrices: any) => ({
        ...prevPrices,
        [chain.name]: result,
      }));
      setTimeout(() => {
        setLoadedPrices(true);
      }, 250);
    });
  };

  const updatePrices = async (chain: Chain) => {
    const newPrices: any = {};

    let priceData: any;
    let nftPriceData: any;
    try {
      priceData = await fetch(PRICES_API(chain)).then((response) => response.json());
      nftPriceData = await fetch(NFT_PRICES_API(chain)).then((response) => response.json());
    } catch {
      try {
        priceData = await fetch(`https://staging-points-api.shellprotocol.io/points/${chain.api}/currentprices`).then((response) => response.json());
        nftPriceData = await fetch(`https://staging-points-api.shellprotocol.io/points/${chain.api}/nftprices`).then((response) => response.json());
      } catch {
        console.error("Server not responding properly");
        return {};
      }
    }

    Object.keys(priceData).forEach((tokenID) => {
      newPrices[tokenID] = priceData[tokenID];
      newPrices["sh" + tokenID] = priceData[tokenID];
    });

    Object.keys(nftPriceData).forEach((tokenID) => {
      newPrices[tokenID] = nftPriceData[tokenID];
      newPrices["sh" + tokenID] = nftPriceData[tokenID];
    });

    return newPrices;
  };

  useEffect(() => {
    if (activeChain && validChains.has(activeChain.name!)) {
      changeChain(allChains[activeChain.name]);
      setCrossChain(activeChain.name);
      if(!crossChainTo) setCrossChainTo(activeChain.name == defaultChain.name ? "Base" : defaultChain.name);
    }
  }, [activeChain]);

  useEffect(() => {
    if (!isConnected) {
      liquidityGraph.initializeSTIPTokens(externalDefiTokens, shellTokens, tokenMap).then((stakedTokens) => setStipTokens(stakedTokens));
      setCrossChain(defaultChain.name);
      if(!crossChainTo) setCrossChainTo(Object.keys(allChains).find((chain) => chain !== defaultChain.name) ?? defaultChain.name)
    }
  }, []);

  useEffect(() => {
    if(crossChainTo){
        initCrossChainTo(allChains[crossChainTo]);
        if(crossChainTo == crossChain){
            setCrossChain(Object.keys(allChains).find((chain) => chain !== crossChainTo) ?? defaultChain.name)
        }
    }
  }, [crossChainTo]);

  useEffect(() => {
    if(crossChain && crossChain == crossChainTo){
        setCrossChainTo(Object.keys(allChains).find((chain) => chain !== crossChainTo) ?? defaultChain.name)
    }
  }, [crossChain])

  useEffect(() => {
    setLoadedPrices(false);

    updatePrices(connectedChain).then((result) => {
      setAllPrices((prevPrices: any) => ({
        ...prevPrices,
        [connectedChain.name]: result,
      }));
      setTimeout(() => {
        setLoadedPrices(true);
      }, 250);
    });
  }, [reloadPrices, connectedChain]);

  return (
    <ChainContext.Provider
      value={{
        connectedChain,
        crossChainToggled,
        setCrossChainToggled,
        crossChain,
        setCrossChain,
        crossChainTo,
        setCrossChainTo,
        tokens,
        shellTokens,
        nftCollections,
        lbpTokens,
        externalTokens,
        externalDefiTokens,
        tokenMap,
        poolQuery,
        liquidityGraph,
        oceanAddress,
        sorTokenMap,
        changeChain,
        allPrices,
        loadedPrices,
        reloadPrices,
        setReloadPrices,
        stipTokens,
        tokenMapTo,
        poolQueryTo,
        liquidityGraphTo,
        sorTokenMapTo,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

import { ETH_ADDRESS, STREAM_ADDRESS } from "../constants/addresses";

import ethIcon from "../assets/coins/ETH.svg";
import aWethIcon from '../assets/coins/aWETH.png'
import ausdbcIcon from "../assets/coins/aUSDbC.png";
import awstEthIcon from "../assets/coins/awstETH.png";
import weEthIcon from "../assets/coins/weETH.svg"
import weethWethBPTIcon from "../assets/coins/weETH-WETH-BPT.png";
import rethWethBPTIcon from "../assets/coins/rETH-WETH-BPT.png";
import cbEthWethBPTIcon from "../assets/coins/cbETH-WETH-BPT.png";

import { ProtocolTypeOption, generateColorHex } from "@/components/DexBadge/DexBadge";
import { tokenColors } from "@/constants/tokenColors";
import { Token, ShellToken, NFTCollection, LBPToken, ExternalToken, ExternalDefiToken } from "../utils/tokens";
import { openseaKey } from "@/providers/WagmiProvider";
import { NFT_RARITY, WRAPPED_NFT_MAP } from "@/utils/nftHelpers";
import { calculateWrappedTokenId } from "@/utils/ocean/utils";
import { createTokenLogo, tokenLogos } from "@/constants/tokenLogos";

export const oceanAddress = "0x86C45705d3ED8Db4D43328327CF992ff43f3bdb9";

export const tokens: Token[] = [
  {
    name: "Ether",
    symbol: "ETH",
    address: ETH_ADDRESS,
    wrapped: false,
    icon: ethIcon,
  },
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0x4200000000000000000000000000000000000006",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  {
    name: "Dai",
    symbol: "DAI",
    address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  },
  {
    name: "Tether",
    symbol: "USDT",
    address: "0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2",
    wrapped: false,
    icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9.png",
  },
  {
    name: "USD Base Coin",
    symbol: "USDbC",
    address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    wrapped: false,
    icon: "https://app.aave.com/icons/tokens/usdbc.svg",
  },
  {
    name: "Lido Staked ETH",
    symbol: "wstETH",
    address: "0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452",
    wrapped: false,
    icon: "https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/0x5979d7b546e38e414f7e9822514be443a4800529.png",
  },
  {
    name: "Rocket Pool ETH",
    symbol: "rETH",
    address: "0xB6fe221Fe9EeF5aBa221c348bA20A1Bf5e73624c",
    wrapped: false,
    icon: "https://raw.githubusercontent.com/balancer/tokenlists/main/src/assets/images/tokens/0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8.png",
  },
  {
    name: "Ether.fi Staked ETH",
    symbol: "weETH",
    address: "0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A",
    wrapped: false,
    icon: weEthIcon,
  },
  {
    name: "Coinbase Staked ETH",
    symbol: "cbETH",
    address: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/21535.png",
  },
  {
    name: "Degen",
    symbol: "DEGEN",
    address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/30096.png",
  },
  {
    name: "Aerodrome",
    symbol: "AERO",
    address: "0x940181a94A35A4569E4529A3CDfB74e38FD98631",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/29270.png",
  },
];

export const shellTokens: ShellToken[] = [];

export const nftCollections: NFTCollection[] = [];

export const lbpTokens: LBPToken[] = [];

export const externalTokens: ExternalToken[] = [];

export const externalDefiTokens: ExternalDefiToken[] = [
  {
    name: "ETH+WETH",
    symbol: "ETH+WETH",
    address: "0x4200000000000000000000000000000000000006",
    wrapped: false,
    icon: "",
    pool: "0xC32eB36f886F638fffD836DF44C124074cFe3584",
    query: "0xC32eB36f886F638fffD836DF44C124074cFe3584",
    tokens: ["ETH", "WETH"],
    tokenType: undefined,
    fee: 0,
  },
  {
    name: "Aave Base USDbC",
    symbol: "aBasUSDbC",
    address: "0x0a1d576f3eFeF75b330424287a95A366e8281D54",
    wrapped: false,
    icon: ausdbcIcon,
    pool: "0x156be1fd089e63c1bcf2a56d63d34419323e30f6",
    query: "0x156be1fd089e63c1bcf2a56d63d34419323e30f6",
    tokens: ["USDbC"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Base USDC",
    symbol: "aBasUSDC",
    address: "0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB",
    wrapped: false,
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5760.png",
    pool: "0x5be1836b4573f56a0cb3278b038fcec476fe6b37",
    query: "0x5be1836b4573f56a0cb3278b038fcec476fe6b37",
    tokens: ["USDC"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Base WETH",
    symbol: "aBasWETH",
    address: "0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7",
    wrapped: false,
    icon: aWethIcon,
    pool: "0xc3f758c7a171ef657b96d33314fa70c27ef435f9",
    query: "0xc3f758c7a171ef657b96d33314fa70c27ef435f9",
    tokens: ["WETH"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aave Base wstETH",
    symbol: "aBaswstETH",
    address: "0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452",
    wrapped: false,
    icon: awstEthIcon,
    pool: "0x51264939334d3e2c11d5898886cbc720885ce326",
    query: "0x51264939334d3e2c11d5898886cbc720885ce326",
    tokens: ["wstETH"],
    tokenType: "Aave",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "weETH-WETH Stable Pool",
    symbol: "weETH-WETH-BPT",
    address: "0xab99a3e856deb448ed99713dfce62f937e2d4d74",
    wrapped: false,
    icon: weethWethBPTIcon,
    pool: "0x1c6732141bbfbc91c5a393f08a77651e24bf4f49",
    query: "0xefe2764bc7a1e7769a8d92478b0677597da159fc",
    tokens: ["weETH", "WETH"],
    tokenType: "Balancer",
    protocolVersion: "V2",
    fee: 0.05,
    metadata: "0xab99a3e856deb448ed99713dfce62f937e2d4d74000000000000000000000118", // Balancer Pool ID
  },
  {
    name: "rETH-WETH Stable Pool",
    symbol: "rETH-WETH-BPT",
    address: "0xC771c1a5905420DAEc317b154EB13e4198BA97D0",
    wrapped: false,
    icon: rethWethBPTIcon,
    pool: "0x73daf5640292f1fd8f11aaf072008bf71e0f7d20",
    query: "0x6bEE9f554245edb1c6597778888FFE8494Ec428a",
    tokens: ["WETH", "rETH"],
    tokenType: "Balancer",
    protocolVersion: "V2",
    fee: 0.04,
    metadata: "0xc771c1a5905420daec317b154eb13e4198ba97d0000000000000000000000023", // Balancer Pool ID
  },
  {
    name: "cbETH-WETH Stable Pool",
    symbol: "cbETH-WETH-BPT",
    address: "0xFb4C2E6E6e27B5b4a07a36360C89EDE29bB3c9B6",
    wrapped: false,
    icon: cbEthWethBPTIcon,
    pool: "0x739ccd419cb60bb40ab926b4d5fdcf26de308556",
    query: "0x828441589496a28ee2aeaebe0f01b4c89da70f7e",
    tokens: ["cbETH", "WETH"],
    tokenType: "Balancer",
    protocolVersion: "V2",
    fee: 0.008,
    metadata: "0xfb4c2e6e6e27b5b4a07a36360c89ede29bb3c9b6000000000000000000000026", // Balancer Pool ID
  },
  {
    name: "Uniswap",
    symbol: "UNI",
    address: "",
    wrapped: false,
    icon: "",
    pool: "0xddad89685f9dba6c035d7639f3f71fa762b583c2",
    query: "0x5d2fab79a78213ead922e036e3a3e4320390330a",
    tokens: tokens.filter((token) => !token.wrapped && token.address !== ETH_ADDRESS).map((token) => token.symbol),
    tokenType: "Uniswap",
    protocolVersion: "V3",
    fee: 0,
  },
  {
    name: "Aerodrome",
    symbol: "Aero",
    address: "",
    wrapped: false,
    icon: "",
    pool: "0x33ABF362424eAAaC950449Fe680Ed7C5F562b50B",
    query: "0xde88E80589447a4df42D7CFc247865e2c24dB2CE",
    tokens: tokens.filter((token) => !token.wrapped && token.address !== ETH_ADDRESS).map((token) => token.symbol),
    tokenType: "Aerodrome",
    protocolVersion: "V2",
    fee: 0,
  },
];

export const tokenMap: {
  [id: string]: Token | ShellToken | NFTCollection | LBPToken;
} = {};

tokens.forEach((token) => {
  if (token.symbol !== "shLP") {
    tokenMap[token.symbol] = token;
    // tokenLogos[token.symbol] = createTokenLogo(token, 83);
  }
});

shellTokens.forEach((token) => {
  tokenMap[token.name] = token;
//   tokenLogos[token.name] = createTokenLogo(token, 50);
});

externalTokens.forEach((token) => {
  tokenMap[token.symbol] = token;
  if (!tokenColors[token.symbol]) tokenColors[token.symbol] = generateColorHex(token.tokenType!);
//   tokenLogos[token.symbol] = createTokenLogo(token, 50);
});

externalDefiTokens.forEach((token) => {
  tokenMap[token.symbol] = token;
  tokenColors[token.symbol] = generateColorHex(token.tokenType!);
//   tokenLogos[token.symbol] = createTokenLogo(token, 50);
});

nftCollections.forEach((collection) => {
  tokenMap[collection.symbol] = collection;

//   tokenLogos[collection.symbol] = createTokenLogo(collection, 50);
  if (!collection.wrapped) {
    const options = { method: "GET", headers: { accept: "application/json", "X-API-KEY": openseaKey } };

    const rarityTable =
      collection.is1155 || collection.address == STREAM_ADDRESS ? [] : require(`../constants/nftRarities/${collection.symbol.toLowerCase()}-rarity.json`);
    NFT_RARITY[collection.address] = {};

    if (collection.is1155) {
      const idMap: any = {};
      for (let i = 0; i < collection.totalSupply!; i++) {
        idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol];
      }
      WRAPPED_NFT_MAP["sh" + collection.symbol] = idMap;
    } else {
      fetch(`https://api.opensea.io/api/v2/collections/${collection.slug}`, options)
        .then((response) => response.json())
        .then((response) => {
          const wrappedCollection: NFTCollection = tokenMap["sh" + collection.symbol] as NFTCollection;
          if (!collection.description) {
            collection.description = response.description;
            wrappedCollection.description = response.description;
          }
          if (!collection.totalSupply) {
            collection.totalSupply = response["total_supply"];
            wrappedCollection.totalSupply = response["total_supply"];
          }
          const idMap: any = {};
          for (let i = 0; i <= collection.totalSupply!; i++) {
            idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol];
            NFT_RARITY[collection.address][i.toString()] = rarityTable[i] ?? null;
          }
          WRAPPED_NFT_MAP["sh" + collection.symbol] = idMap;
        })
        .catch((err) => console.error(err));
    }
  }
});

lbpTokens.forEach((lbpToken) => {
  tokenMap[lbpToken.symbol] = lbpToken;
  tokenMap[lbpToken.poolName] = {
    name: lbpToken.poolName,
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: lbpToken.poolIcon,
    oceanID: lbpToken.poolOceanID,
    pool: lbpToken.poolAddress,
    tokens: [lbpToken.symbol, lbpToken.pairToken],
    fee: 0,
    tokenType: "Shell",
    protocolVersion: "V3",
  };
//   tokenLogos[lbpToken.symbol] = createTokenLogo(lbpToken, 83);
//   tokenLogos[lbpToken.poolName] = createTokenLogo(tokenMap[lbpToken.poolName], 50);
});

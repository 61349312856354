import React from "react";
import { useRewardsContext } from "../../RewardsContext";
import * as S from "./ConfirmationModals.styled";
import infoIcon from "@/assets/icons/info-icon.svg";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import arrowDown from "@/assets/icons/arrow-down-double-fill.svg";
import { timestampToDate } from "@/utils/formatDisplay";

export const ChangeLockPeriodConfirmationModalBody = () => {
    const { isIncreaseLockPeriodSuccess, savedLockPeriod, currentLockPeriod, handleRedirectToStaking, stakeTxSubmitted, projectedEndTime } = useRewardsContext();
                
    const unlockTime = timestampToDate(projectedEndTime.toString())

    return savedLockPeriod && currentLockPeriod ? (
        <S.ConfirmationBodyContainer hasMask={isIncreaseLockPeriodSuccess}>
            {isIncreaseLockPeriodSuccess ? (
                <>
                    <S.SuccessIcon src={successIcon} alt="success icon" />
                    <S.SuccessTextWrapper>
                        <S.SuccessTitle>Increase successful!</S.SuccessTitle>
                        <S.SuccessDescription>
                        Your new unlock time is {unlockTime}
                        </S.SuccessDescription>
                    </S.SuccessTextWrapper>
                    <Input label="Increased by" disabled value={currentLockPeriod.label} additionalLabel={{label: `${currentLockPeriod?.APY}% APY`, weight: 400}} />
                    <S.CustomPrimaryBtn style={{ marginTop: "16px" }} onClick={handleRedirectToStaking}>View my staking</S.CustomPrimaryBtn>
                </>
            ) : (
                <>
                    <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                    <S.LoaderLabelContainer>
                        <S.LoaderLabel>
                            Increasing staking period...
                        </S.LoaderLabel>
                        <S.LoaderSublabel>
                           Your new unlock time will be {unlockTime}
                        </S.LoaderSublabel>
                    </S.LoaderLabelContainer>
                    <Input label="Increase by" disabled value={currentLockPeriod.label} additionalLabel={{label: `${currentLockPeriod?.APY}% APY`, weight: 400}} />
                    {!stakeTxSubmitted && 
                    <S.InfoLabelContainer>
                        <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                        <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                    </S.InfoLabelContainer>
                    }
                </>
            )}
        </S.ConfirmationBodyContainer >
    ) : null
}
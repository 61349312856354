import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ButtonSecondary } from "../../../components/Buttons/Button";
import { CopyButton } from "../../../components/Buttons/CopyButton";
import { isExternalToken, isShellV2Token } from "../../../utils/tokens";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addPool } from "../../../store/poolsSlice";
import { formatDisplay, formatDisplayShorthand } from "../../../utils/formatDisplay";
import { reduceString } from "../../../utils/reduceString";
import { POOL_API } from "../../../constants/urls";
import { Composition } from "../../../components/Composition/Composition";
import { breakpoints, Media } from "../../../styles";
import { useWidthBreakpoint } from "../../../hooks";
import { CompositionSkeleton, StatsSkeleton } from "@/pages/Rewards/components/Points/components/PoolSubRow";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSlugForStatisticsPage } from "@/pages/Pools/PoolsTable";
import { DexBadge } from "@/components/DexBadge/DexBadge";
import { getTokenID } from "@/utils/LiquidityGraph";
import { CustomTooltip } from "@/components/CustomTooltip/CustomTooltip";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { STIPFooter, rewardTokenID } from "./STIPFooter";
import { Chain } from "@/placeholders/chains";

interface ShellTokenInfoProps {
  selectedToken: any;
  isShrunk: boolean;
  chain: Chain;
}

export const ShellTokenInfo = ({ selectedToken, isShrunk, chain }: ShellTokenInfoProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { tokenMap } = require(`../../../placeholders/${chain.name.split(' ')[0].toLowerCase()}Tokens.ts`)
  const { poolQuery, poolQueryTo, connectedChain } = useContext(ChainContext);
  const query = chain.name == connectedChain.name ? poolQuery : poolQueryTo!

  const poolStats = useAppSelector((state) => state.pools.pools[chain.name]);
  const prices = useAppSelector((state) => state.prices.prices[chain.name]);

  const isMobile = useWidthBreakpoint(breakpoints.mobile);

  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState<any>({});

  const [stipLoading, setStipLoading] = useState(true);
  const [stipData, setStipData] = useState<any>({});

  const detailsDisabled = isExternalToken(selectedToken) && !isShellV2Token(selectedToken);

  const stipRewardToken = tokenMap[rewardTokenID];

  const fetchPoolInfo = async (pool: any) => {
    const poolName = pool.tokenType == "STIP" ? pool.tokens[0] : getTokenID(pool);
    const version = isExternalToken(pool) && isShellV2Token(pool) ? "v2" : "v3";
    if (poolStats[poolName]) return poolStats[poolName];

    return fetch(POOL_API(chain) + version + "/pools/" + poolName.replace(/\//g, "-"))
      .then((response) => response.json())
      .then((data) => {
        dispatch(addPool({ name: poolName, data: data, chain: chain.name }));
        return data;
      })
      .catch(() => {});
  };

  const fetchStipData = async (pool: any) => {
    const version = isExternalToken(pool) && isShellV2Token(pool) ? "v2" : "v3";

    const rewardData = await fetch(POOL_API(chain) + version + "/pools/" + pool.symbol.replace(/\//g, "-"))
      .then((response) => response.json())
      .catch(() => {
        return { totalSupply: 0, totalReward: 0, rewardAPY: 0 };
      });

    const baseTokenPrice = await query.getUSDPrice(selectedToken, { ...prices });
    const rewardTokenPrice = await query.getUSDPrice(stipRewardToken, { ...prices });

    const totalStaked = rewardData.totalSupply;
    const dailyReward = rewardData.totalReward;

    return {
      totalStaked: { amount: totalStaked, value: totalStaked * baseTokenPrice },
      rewardPerDay: { amount: dailyReward, value: dailyReward * rewardTokenPrice },
      rewardAPY: (rewardData.rewardAPY - 1) * 100,
    };
  };

  useEffect(() => {
    // Fetch balances from contract if not already in Redux state and update store
    setLoading(true);
    fetchPoolInfo(selectedToken).then((data) => {
      if (data) {
        setAnalytics(data);
        setLoading(false);
      }
    });

    if (selectedToken.tokenType == "STIP") {
      setStipLoading(true);
      fetchStipData(selectedToken).then((data: any) => {
        if (data) {
          setStipData(data);
          setStipLoading(false);
        }
      });
    }
  }, [selectedToken, chain]);

  if (location.pathname.includes("/rewards/stip") && selectedToken.tokenType == "STIP" && selectedToken.metadata == "active")
    return <STIPFooter token={tokenMap[selectedToken.tokens[0]]} rewardToken={stipRewardToken} data={stipData} loading={stipLoading} isShrunk={isShrunk} />;

  return (
    <>
      <Wrapper className={isShrunk ? "shrunk" : ""}>
        <TokenDiv>
          <Image className={isShrunk ? "shrunk" : ""} src={selectedToken.icon} alt={`${selectedToken.name} logo`} />
          <TokenSummary className={isShrunk ? "shrunk" : ""}>
            <TokenName>{selectedToken.name}</TokenName>
            <div style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
              <TokenAddress className={isShrunk ? "shrunk" : ""}>
                {reduceString(selectedToken.oceanID ?? selectedToken.address, 6, 4)}{" "}
                <CopyButton text={selectedToken.oceanID ?? selectedToken.address} className="copyShellToken" />
              </TokenAddress>
              {selectedToken.tokenType && selectedToken.tokenType !== "STIP" && (
                <DexBadge protocol={selectedToken.tokenType} protocolVersion={selectedToken.protocolVersion ?? ""} />
              )}
            </div>
            {/* <TokenAddress className={isShrunk ? 'shrunk' : ''}>LP {reduceString(selectedToken.oceanID ?? selectedToken.address, 6, 4)} <CopyButton text={selectedToken.oceanID ?? selectedToken.address} className='copyShellToken' /></TokenAddress> */}
          </TokenSummary>
        </TokenDiv>
        <Stats className={isShrunk ? "shrunk" : ""}>
          {
            <>
              <Portions className={isShrunk ? "shrunk" : ""}>
                {loading ? (
                  <CompositionSkeleton length={2} />
                ) : (
                  Object.keys(analytics).length > 0 && (
                    <Composition
                      list={Object.keys(analytics.breakdown).map((childToken) => {
                        return { symbol: childToken, percentage: `${analytics.breakdown[childToken]}%` };
                      })}
                    />
                  )
                )}
              </Portions>
              <Info className={isShrunk ? "shrunk" : ""}>
                <StatsRow className={isShrunk ? "shrunk" : ""}>
                  {loading ? (
                    <>
                      <StatsSkeleton length={4} boxWidth={isShrunk ? 90 : 110} noMargin={true} />
                    </>
                  ) : (
                    <>
                      <InfoItem className={isShrunk ? "shrunk" : ""} style={{ display: "flex", gap: "4px" }}>
                        <Label>APY:</Label>{" "}
                        <Value color={"#7ADEB9"} style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                          {`${analytics.apy > 1 ? formatDisplay(((analytics.apy - 1 + analytics.rewardAPY) * 100).toString(), 2) : "--"}%`}
                          {analytics.apy > 1 && analytics.rewardAPY > 0 && (
                            <CustomTooltip
                              position={"right"}
                              title="APY Breakdown"
                              subtext={
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                                  <p>{`Trading Fees: ${formatDisplay(((analytics.apy - 1) * 100).toString(), 2)}%`}</p>
                                  <p>{`SHELL Rewards: ${formatDisplay((analytics.rewardAPY * 100).toString(), 2)}%`}</p>
                                </div>
                              }
                              delay={50}
                            />
                          )}
                        </Value>
                      </InfoItem>
                      <InfoItem className={isShrunk ? "shrunk" : ""}>
                        <Label>Fee Tier:</Label> <Value color={"#00BDFF"}>{`${selectedToken.fee}%`}</Value>
                      </InfoItem>
                      <InfoItem className={isShrunk ? "shrunk" : ""}>
                        <Label>TVL:</Label> <Value>{`$${formatDisplayShorthand(analytics.totalValueLocked)}`}</Value>
                      </InfoItem>
                      <InfoItem className={isShrunk ? "shrunk" : ""}>
                        <Label>24h Vol:</Label> <Value>{`$${formatDisplayShorthand(analytics["24HrVolume"])}`}</Value>
                      </InfoItem>
                    </>
                  )}
                </StatsRow>
                <StyledLink to={{ pathname: getSlugForStatisticsPage(selectedToken) }} disabled={detailsDisabled}>
                  <ButtonS className={isShrunk ? "shrunk" : ""}>
                    {isShrunk ? "Details" : "View Pool Stats"}
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3983_174)">
                        <path
                          d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
                          fill={detailsDisabled ? "grey" : "white"}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3983_174">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </ButtonS>
                </StyledLink>
              </Info>
            </>
          }
        </Stats>
      </Wrapper>
      {selectedToken.tokenType == "STIP" && selectedToken.metadata == "active" && (
        <STIPFooter token={tokenMap[selectedToken.tokens[0]]} rewardToken={stipRewardToken} data={stipData} loading={stipLoading} isShrunk={isShrunk} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  //   column-gap: 30px;

  width: 100%;
  padding: 24px 28px 24px 28px;
  padding-top: 24px;
  border-top: 1px solid #1e2239;
  background-image: linear-gradient(180deg, #0f0f29, transparent);

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      flex-direction: column;
      justify-content: unset;
      row-gap: 20px;
      padding: 18px 12px;
    `};
`;

const TokenDiv = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 342px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 42px;
  overflow: hidden;
  object-fit: cover;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      width: 50px;
      height: 50px;
    `};
`;

const TokenSummary = styled.div`
  margin-left: 20px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      margin-left: 15px;
    `};
`;

const TokenName = styled.span`
  font-size: clamp(16px, 2vw, 20px);
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.03em;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TokenAddress = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #a9d1e2;

  .copyShellToken {
    color: #a9d1e2;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      font-size: 14px;
      line-height: 16px;
    `};
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 496px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      width: 100%;
      max-width: 100%;
      align-items: unset;
    `};
`;

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  align-items: center;
  row-gap: 6px;
  column-gap: 12px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      justify-items: space-around;
      column-gap: 6px;
    `};
`;

const LoadWrapper = styled.div`
  margin: auto;

  > img {
    width: 48px;
    margin-left: 10px;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      margin-bottom: 16px;
      > img {
        width: 36px;
        margin-left: 0px;
      }
    `};
`;

const Portions = styled.div`
  display: flex;
  width: 100%;
  max-width: 428px;
  margin: 18px 0;
  overflow: hidden;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      max-width: 100%;
      margin: 0 0 12px;
    `};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2px;
  justify-content: space-between;
  gap: 16px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      gap: 0;
    `};
`;

const InfoItem = styled.p`
  font-size: 14px;
  line-height: 16px;
  align-items: center;

  > img {
    padding-bottom: 2px;
    margin-left: 4px;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      & + & {
        margin-left: unset;
      }
      ${Media.mobile} {
        font-size: 12px;
      }
    `};
`;

const Label = styled.span`
  color: #7d7d97;
`;

const Value = styled.span<{ value?: number; color?: string }>`
  font-weight: 500;
  color: ${({ color }) => color ?? "#FFFFFF"};
`;

const StyledLink = styled(Link)<{ disabled: boolean }>`
  width: fit-content;
  margin-left: auto;

  ${({ disabled }) =>
    disabled &&
    `

  & ${ButtonS} {
  color: var(--grey-4, #464659);
  border-color: #1E2239;
  background: #151530;
  }
  pointer-events: none;
  `}
`;

const ButtonS = styled(ButtonSecondary)`
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      width: 100%;
      grid-column: 1 / 2 span;
      padding-inline: 12px;
    `};
`;

import React from "react";
import styled, { css } from "styled-components";
import { formatDisplay, formatDisplayShorthand } from "../../../utils/formatDisplay";
import { Media } from "../../../styles";
import { USDValue } from "../InputPanel";
import { StatsSkeleton } from "@/pages/Rewards/components/Points/components/PoolSubRow";

interface STIPFooterProps {
  token: any;
  rewardToken: any;
  data: any;
  loading: boolean;
  isShrunk: boolean;
}

export const rewardTokenID = 'ARB'

export const STIPFooter = ({ token, rewardToken, data, loading, isShrunk }: STIPFooterProps) => {
  return (
    <Footer>
      <FooterRow className={isShrunk ? "shrunk" : ""}>
        {loading ? (
          <>
            <StatsSkeleton length={4} boxWidth={isShrunk ? 156 : 122} noMargin={true} />
          </>
        ) : (
          <>
            <InfoItem className={isShrunk ? "shrunk" : ""}>
              <Label>APY:</Label> <Value color={"#7ADEB9"}>{`${formatDisplay(data.rewardAPY, 2)}%`}</Value>
            </InfoItem>
            <InfoItem className={isShrunk ? "shrunk" : ""}>
              <Label>24h Reward:</Label> <Value>{formatDisplayShorthand(data.rewardPerDay.amount)}</Value> <img src={rewardToken.icon} />{" "}
              <USDValue className={isShrunk ? "shrunk" : ""}>{`($${formatDisplayShorthand(data.rewardPerDay.value, 2, 1e3)})`}</USDValue>
            </InfoItem>
            {isShrunk && <div></div>}
            <InfoItem className={isShrunk ? "shrunk" : ""} style={{ display: "flex" }}>
              <Label>Total Staked:</Label> <Value>{formatDisplayShorthand(data.totalStaked.amount)}</Value> <img src={token.icon} />{" "}
              <USDValue className={isShrunk ? "shrunk" : ""}>{`($${formatDisplayShorthand(data.totalStaked.value, 2, 1e3)})`}</USDValue>
            </InfoItem>
          </>
        )}
      </FooterRow>
    </Footer>
  );
};

const InfoItem = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  gap: 4px;

  > img {
    width: 20px;
    height: 20px;
  }

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      & + & {
        margin-left: unset;
      }
      ${Media.mobile} {
        font-size: 12px;
      }
    `};
`;

const Label = styled.span`
  color: #7d7d97;
`;

const Value = styled.span<{ value?: number; color?: string }>`
  font-weight: 500;
  color: ${({ color }) => color ?? "#FFFFFF"};
`;

const Footer = styled.div`
  border-top: 1px solid #1e2239;
  padding: 16px 20px;
`;

const FooterRow = styled.div`
  display: flex;
  align-items: row;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;

  ${(props) =>
    props.className?.includes("shrunk") &&
    css`
      display: grid;
      grid-template-columns: 1fr 2.5fr;
      grid-template-rows: 1fr 1fr;
      justify-items: start;
      align-items: center;
      row-gap: 6px;
      column-gap: 12px;
    `};
`;

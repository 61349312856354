import React, { ReactNode } from 'react';
import styled from 'styled-components';
interface IGeoRestrictedOverlayProps {
  children: ReactNode;
  isUS: boolean;
}

const GeoRestrictedOverlay: React.FC<IGeoRestrictedOverlayProps> = ({ children, isUS }) => {
  if (!isUS) { return <>{children}</>; }
  return (
    <Container>
      <BlurredContent>{children}</BlurredContent>
      <Overlay>
        <OverlayMessage>
          This feature is currently restricted in your region.
        </OverlayMessage>
      </Overlay>
    </Container>
  );
};

export default GeoRestrictedOverlay;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const BlurredContent = styled.div`
  filter: blur(5px);
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const OverlayMessage = styled.div`
  color: rgb(255, 255, 255);
  background-color: rgb(21 21 48);
  padding: 20px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  text-align: center;
`;

import React from "react";

export const PoolsIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18.6665C12 20.8758 15.5813 22.6665 20 22.6665C24.4187 22.6665 28 20.8758 28 18.6665C28 16.4572 24.4187 14.6665 20 14.6665C15.5813 14.6665 12 16.4572 12 18.6665Z"
        stroke="#464659"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.6667V24C12 26.208 15.5813 28 20 28C24.4187 28 28 26.208 28 24V18.6667M4 8C4 9.42933 5.52533 10.7493 8 11.464C10.4747 12.1787 13.5253 12.1787 16 11.464C18.4747 10.7493 20 9.42933 20 8C20 6.57067 18.4747 5.25067 16 4.536C13.5253 3.82133 10.4747 3.82133 8 4.536C5.52533 5.25067 4 6.57067 4 8Z"
        stroke="#464659"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8V21.3333C4 22.5173 5.02933 23.2667 6.66667 24"
        stroke="#464659"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14.6665C4 15.8505 5.02933 16.5998 6.66667 17.3332"
        stroke="#464659"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
import { format, isAfter } from "date-fns";

export const getFormattedChartDate = (dateAsUnixTime: number): string => {
    const date = new Date(dateAsUnixTime * 1000);
  
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
  
    return `${month} '${year}`;
};  

export const getFormattedChartDayDate = (dateAsUnixTime: number): string => {
    const date = new Date(dateAsUnixTime * 1000);
  
    return format(date, "MM/dd");
};

export const getFormattedChartHalfDate = (dateAsUnixTime: number): string => {
  const date = new Date(dateAsUnixTime * 1000);

  return format(date, "MM/dd, hh:mm a");
};

export const getFormattedChartFullDate = (dateAsUnixTime: number): string => {
  const date = new Date(dateAsUnixTime * 1000);

  return format(date, "MM/dd/yyyy");
};

export const isDateAfterCurrent = (dateAsUnixTime: number): boolean => {
  const currentDate = new Date();
  const date = new Date(dateAsUnixTime * 1000);

  return isAfter(date, currentDate);
};
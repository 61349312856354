import { allChains } from '@/placeholders/chains'
import { createSlice } from '@reduxjs/toolkit'
interface State {
    prices: Record<string, Record<string, any>>
}

const initialPrices: Record<string, Record<string, any>> = {}

Object.keys(allChains).forEach((chain) => {
    initialPrices[chain] = {}
})

const initialState: State = { prices: initialPrices }

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    addPrice: (state, action) => {
        const { chain, name, price } = action.payload;
        state.prices[chain][name] = price;
    },
    clearPrices: (state, action) => {
        const { chain } = action.payload;
        state.prices[chain] = {};
    },
    clearAllPrices: () => {
        return initialState;
    }
  }
})

export const { addPrice, clearPrices, clearAllPrices } = pricesSlice.actions

export default pricesSlice.reducer
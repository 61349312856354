import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { Spinner } from "@/components/Loaders";
import { Modal } from "@/components/Modal/Modal";
import { SearchInput } from "@/components/TokensModal/SearchInput";
import { SearchContainer } from "@/components/TokensModal/TokensModal";
import { useSearch } from "@/hooks/useSearch";
import { queryInteraction } from "@/pages/Transactions/interactionHelpers";
import { NFT } from "@/utils/tokens";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { addInteraction } from "@/store/transactionsSlice";
import { Media } from "@/styles";
import { scrollbar } from "@/styles/scrollbar-horizontal";
import { buildNFTDisplays, build1155Displays } from "@/utils/nftHelpers";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TableSubRowProps {
  transaction: any;
  nftShown: string;
  pathIndex: number;
  handleCloseModal: () => void;
}

export const TransactionNFTDetails = ({
  transaction,
  nftShown,
  pathIndex,
  handleCloseModal,
}: TableSubRowProps) => {

  const { tokenMap, connectedChain } = useContext(ChainContext)

  const transactionHash = transaction.transactionHash
  const input = transaction.input;
  const output = transaction.output;
  const arrowType = transaction.arrowType

  const [searchValue, setSearchValue] = useState("");
  const [nfts, setNFTs] = useState<NFT[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const userInteractions = useAppSelector(state => state.transactions.interactions[connectedChain.name])
  const dispatch = useAppDispatch()

  const getInteraction = async (transactionHash: string, input: any, output: any, arrowType: string) => {

    let interaction : any

    if(userInteractions[transactionHash]){
        interaction = userInteractions[transactionHash]
    } else {

        interaction = await queryInteraction(connectedChain, transactionHash, input, output, arrowType)

        dispatch(addInteraction(
            {
                chain: connectedChain.name,
                hash: transactionHash, 
                data: {
                    preArrowPaths: interaction.preArrowPaths, 
                    postArrowPaths: interaction.postArrowPaths,
                    preArrowPathLength: interaction.preArrowPathLength,
                    postArrowPathLength: interaction.postArrowPathLength,
                    block: interaction.block,
                    fee: interaction.fee
                }
            }
        ))
    }

    for (let i = 0; i < interaction.preArrowPaths[pathIndex]?.length; i++) {
        const step = interaction.preArrowPaths[pathIndex][i];
        if (step.token == nftShown) {
          if(step.nftID.every((item: any) => typeof item === 'number')){
            setNFTs(
                (
                    // @ts-ignore
                    await buildNFTDisplays(tokenMap[step.token], step.nftID)
                ).sort((nftA: { id: number; }, nftB: { id: number; }) => nftA.id - nftB.id)
              );
          } else {
            // @ts-ignore
            setNFTs(build1155Displays(tokenMap[step.token], step.nftID))
          }
          return;
        }
    }

    for (let i = 0; i < interaction.postArrowPaths[pathIndex].length; i++) {
        const step = interaction.postArrowPaths[pathIndex][i];
        if (step.token == nftShown) {
          if(step.nftID.every((item: any) => typeof item === 'number')){
            setNFTs(
                (
                    // @ts-ignore
                    await buildNFTDisplays(tokenMap[step.token], step.nftID)
                ).sort((nftA: { id: number; }, nftB: { id: number; }) => nftA.id - nftB.id)
              );
          } else {
            // @ts-ignore
            setNFTs(build1155Displays(tokenMap[step.token], step.nftID))
          }
          return;
        }
    }

  }

  useEffect(() => {
    if(nftShown && nfts.length == 0){
      setIsLoading(true)
      getInteraction(transactionHash, input, output, arrowType).then(() => {
        setIsLoading(false)
      }).catch((_) => {
        setIsLoading(false)
      })
    }
  }, [nftShown])

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const filteredCollectionItems = useSearch(nfts as any, ["id"], searchValue);

  return (
    <StyledModal
      title={`${nfts.length > 1 ? nfts.length : ''} ${tokenMap[nftShown]?.name}`}
      isVisible={nftShown !== ''}
      onClose={() => {
        setNFTs([])
        handleCloseModal()
      }}
    >
      <SearchContainer>
        <SearchInput
            type="text"
            placeholder="Type NFT #..."
            value={searchValue}
            onChange={handleSearchInputChange}
        />
      </SearchContainer>
      {isLoading ? 
      <CircleContainer>
        <Spinner/>
      </CircleContainer>
      : 
      <>
        <GalleryContainer itemCount={input.tokens.length}>
            {filteredCollectionItems &&
            filteredCollectionItems.map((nft: NFT, index: number) => (
              <Link
                href={`https://opensea.io/assets/arbitrum/${tokenMap[nft.symbol].address}/${nft.id}`}
                target="_blank"
                rel="noopener noreferrer"
                key={nft.id}
              >
                <GalleryItem key={"item" + index}>
                    <ImageContainer>
                        <Logo src={nft.image} alt="Logo" />
                    </ImageContainer>
                    <Row key={"item-info" + index}>
                        <Token>{'balance' in nft ? nft.name + ' x' + nft.balance : '#' + nft.id}</Token>
                        {/* <SingleArrowContainer>
                            <SingleArrowIcon />
                        </SingleArrowContainer>
                        <Token wrapped={!nft.wrapped}>{"#" + nft.id}</Token> */}
                    </Row>
                </GalleryItem>
              </Link>
            ))}
        </GalleryContainer>
      </>
    }
    </StyledModal>
  );
};

const Link = styled.a`
  color: white;
`;

const StyledModal = styled(Modal)`
  max-height: 580px;
  max-width: 620px;

  ${Media.mobile} {
    max-height: 90%;
    max-width: 100%;
    margin-top: 10.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: auto;
`;

const GalleryContainer = styled.div<{ itemCount: number }>`
  display: grid;
  gap: 16px 12px;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-items: start;
  align-content: flex-start;
  justify-content: start;
  grid-template-columns: repeat(4, calc(25% - calc(3 * 12px / 4)));
  align-items: center;
  ${scrollbar()}
  max-height: calc(100% - 100px);
  height: 100%;
  padding: 0px;

  ${Media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  ${Media.smallMobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  ${({ itemCount }) =>
    itemCount <= 1 &&
    css`
      align-items: center;
    //   align-content: center;
      justify-items: center;
    `};
`;

const GalleryItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: border-color 0.3s ease;
  background-color: #171b33;
  max-height: 100%;
  max-width: 100%;
  padding: 10px 10px 0px 10px;

  &:hover {
    border-color: #37dcf2;
  }

  ${Media.mobile} {
    max-width: 90%;
  }

  ${Media.smallMobile} {
    max-width: 90%;
  }
`;

const ImageContainer = styled.div`
  display: flex;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    width: 0px;
  }
`;

const Logo = styled.img`
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const Token = styled.div<{ wrapped?: boolean }>`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;

  ${({ wrapped }) =>
    wrapped &&
    css`
      color: #37dcf2;
    `};
`;

const SingleArrowContainer = styled.div`
  color: #7d7d97;
  margin: 6px;
  max-width: 60px;
  width: 10px;

  > div {
    display: block;
  }
`;

const Row = styled.div`
  align-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 12px;
  > div {
    align-items: center;
    display: flex;
  }
`;

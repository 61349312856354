import { NFTCollection } from "./tokens";
import { Alchemy, BigNumber, Network, NftTokenType } from "alchemy-sdk";
import { Multicall } from "ethereum-multicall";
import { alchemyId} from "../providers/WagmiProvider";
import { itemsMap } from "../pages/Booty/Items";
import { defaultChain } from "@/placeholders/chains";

const config = {
    apiKey: alchemyId,
    network: Network.ARB_MAINNET,
};
const alchemy = new Alchemy(config);
export const multicall = new Multicall({
//   nodeUrl: `https://arbitrum-mainnet.infura.io/v3/${infuraId}`,
  nodeUrl: `https://${defaultChain.rpcPrefix}.g.alchemy.com/v2/${alchemyId}`,
  tryAggregate: true,
});

export const WRAPPED_NFT_MAP : any = {}
export const NFT_RARITY : {[address: string] : {[id: string] : number}} = {}

export async function getAllNFTs (userAddress: string | undefined, collections : NFTCollection[]) {
    if(!userAddress) return {}

    const nfts : any = {}
    let pageKey = ''
    do {
        const result = await alchemy.nft.getNftsForOwner(userAddress, {pageKey: pageKey});
        collections.forEach((collection) => {

            const collectionItems : any[] = []
            const uniqueIdsSet = new Set<string>();

            result.ownedNfts.forEach((nft) => {
                if(nft.contract.address.toLowerCase() == collection.address.toLowerCase() && !uniqueIdsSet.has(nft.tokenId)){
                    uniqueIdsSet.add(nft.tokenId);
                    collectionItems.push(nft)
                } 
            })

            nfts[collection.symbol] = collectionItems.concat(nfts[collection.symbol] ?? [])
        })
        pageKey = result.pageKey ?? ''
    } while (pageKey)

    return nfts
}

export async function getNFTs (userAddress : string | undefined, contractAddress : string) {
    if(!userAddress) return []

    const uniqueIdsSet = new Set<string>();

    const nfts : any[] = []
    let pageKey = ''
    do {
        const result = await alchemy.nft.getNftsForOwner(userAddress, {pageKey: pageKey});
        result.ownedNfts.forEach((nft) => {
            if(nft.contract.address.toLowerCase() == contractAddress.toLowerCase() && !uniqueIdsSet.has(nft.tokenId)){
                uniqueIdsSet.add(nft.tokenId);
                nfts.push(nft)
            } 
        })
        pageKey = result.pageKey ?? ''
    } while (pageKey)

    return nfts
}

export async function getNFTsData (contractAddress : string, nftIDs : number[]) {
    const result = await alchemy.nft.getNftMetadataBatch(nftIDs.map((nftID) => {
        return {
            contractAddress: contractAddress,
            tokenId: nftID,
            tokenType: NftTokenType.ERC721
        }
    }))
    return result.nfts
}

export async function getNFTTotalSupply (contractAddress: string) {
    const result = await alchemy.nft.getContractMetadata(contractAddress)
    return parseInt(result.totalSupply ?? '0')
}

export async function buildNFTDisplays (collection : NFTCollection, nftIDs : number[]) {


    let images: any[] = []
    let metadata: any[] = []

    // @ts-ignore
    const ipfsHash = collection.ipfsHash

    if(ipfsHash !== ''){

        images = nftIDs.map((nftID) => `https://alchemy.mypinata.cloud/ipfs/${ipfsHash}/${nftID}.jpg`)

    } else { // On chain data

        // const contractCallContext: ContractCallContext[] = [
        //     {
        //         reference: collection.symbol,
        //         contractAddress: collection.address,
        //         abi: erc721ABI as any,
        //         calls: nftIDs.map((nftID) => {
        //             return {
        //                 reference: "tokenURI",
        //                 methodName: "tokenURI",
        //                 methodParameters: [nftID],
        //             }
        //         })
        //     },
        // ];

        // const results: ContractCallResults = await multicall.call(contractCallContext)
        // images = results.results[collection.symbol].callsReturnContext.map((returnContext) => returnContext.returnValues.map((data) => {
        //     const metadata = JSON.parse(Buffer.from(data.split(",")[1], "base64").toString('utf-8'))
        //     const svg = Buffer.from(metadata.image.split(",")[1], "base64").toString('utf-8')
        //     return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`
        // }))

        const nftsData = (await getNFTsData(collection.address, nftIDs))
        images = nftsData.map((nft :any) => nft.image.cachedUrl)
        metadata = nftsData.map((nft :any) => nft.raw.metadata.attributes)
    }

    return(
        nftIDs.map((nftID, index) => {
            return ({
                id: nftID,
                symbol: collection.symbol,
                name: collection.name,
                wrapped: collection.wrapped,
                rarity: NFT_RARITY[collection.address][nftID] ?? '???',
                image: images[index],
                metadata: metadata[index],
                desc: collection.description
            })
        })
    )
}

export function build1155Displays (collection : NFTCollection, nfts: any[]) {

    const allItems = itemsMap.map((item: any, index: number) => {
        return {
            id: index,
            name: item.name,
            desc: item.desc,
            image: item.image
        }
    })

    return(
        nfts.map((nft) => {
            return ({
                id: nft.id,
                symbol: collection.symbol,
                name: (collection.wrapped ? 'Wrapped ' : '') + allItems[nft.id].name,
                wrapped: collection.wrapped,
                balance: nft.balance,
                rarity: 0,
                desc: allItems[nft.id].desc,
                image: `../../assets/booty/${allItems[nft.id].image}.jpg`
            })
        })
    )

}

export function get1155Item (id : string) {
    const item = itemsMap[parseInt(id)]

    return {
        id: id,
        name: item.name,
        desc: item.desc,
        icon: `../../assets/booty/${item.image}.jpg`
    }
}

export function extract1155Data(tokenID : string) {
    const pattern = /^fr([A-Za-z]+)-(\d+)$/;
    const match = tokenID.match(pattern);
    if (match) {
      const [, symbol, id] = match;
      const item = get1155Item(id)
      return { symbol, id, item };
    }
    return null; // Return null if the input string doesn't match the pattern
}

import { ExpandButton } from "@/components/Table/ExpandButton";
import { GenericTable } from "@/components/Table/GenericTable";
import { Pagination } from "@/components/Table/TransactionTable/Pagination";
import { usePaginate, useWidthBreakpoint } from "@/hooks";
import { breakpoints } from "@/styles";
import { reduceString } from "@/utils/reduceString";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled, { css } from "styled-components";
import { LeaderboardTableMobile } from "./LeaderboardTableMobile";
import { PointsIcon } from "@/components/Icons/PointsIcon";
import shellIcon from "@/assets/coins/SHELL.svg";
import { useLeaderboard } from "@/pages/Explore/ExploreContext";

export const LeaderboardTable = ({ transactions: leaderboardTransactions, isSearchActive, isShell }: any) => {
  const { leaderboard } = useLeaderboard();
  const transactions =  leaderboardTransactions || leaderboard.slice(0, 3);

  const { hash } = useParams();
  const [activeUser, setActiveUser] = useState<string>("");
  const navigate = useNavigate();
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const columns = useMemo(
    () => [
      {
        Header: "Rank",
        accessor: "rank",
        Cell: ({ row }: any) => <Cell>#{row.original.id}</Cell>,
      },
      {
        Header: "Wallet",
        accessor: "wallet",
        Cell: ({ row }: any) => {
          return <Cell>{reduceString(row.original.address, 7, 6)}</Cell>;
        },
      },
      {
        Header: `${isShell ? "SHELL" : "Shell Points"}`,
        accessor: "shellPoints",
        Cell: ({ row }: any) => (
          <Cell>
            {isShell ? (
              <img
                style={{ width: "24px", height: "24px", marginRight: "4px" }}
                src={shellIcon}
              />
            ) : (
              <PointsIcon />
            )}
            {row.original.points}
          </Cell>
        ),
      },
      {
        Header: "",
        id: "expander",
        Cell: ({ row }: any) => (
          <Cell withArrow={true}>
            <ExpandButton row={row} />
          </Cell>
        ),
        disableSortBy: true,
        width: 50,
      },
    ],
    [isShell]
  );

  const handleRowClick = useCallback(
    (address: any) => {
      const entry: any = transactions.find(
        (entry: any) => entry.address === address
      );
      const leaderboardRank = entry ? entry.id : null;
      if (leaderboardRank) {
        navigate(`/wallet/${address}`, { state: { rank: leaderboardRank } });
      } else {
        navigate(`/wallet/${address}`);
      }
    },
    [transactions.length]
  );

  const pageSize = 10;
  const { handleSetCurrentPage, ...pagination } = usePaginate<object>(
    transactions,
    {
      pageSize,
    }
  );

  useEffect(() => {
    if (hash !== undefined) {
      const index = transactions.findIndex((item: any) => item.address == hash);
      const transaction = transactions.find(
        (item: any) => item.address == hash
      );
      if (index !== -1) {
        handleSetCurrentPage(Math.floor(index / pageSize) + 1);
        setActiveUser(transaction.id);
      }
    }
  }, [transactions, hash]);

  useEffect(() => {
    if (isSearchActive) {
      handleSetCurrentPage(1);
      if (activeUser) {
        setActiveUser("");
      }
    }
  }, [isSearchActive, activeUser]);

  return (
    <Container>
      {isTablet ? (
        <LeaderboardTableMobile
          data={pagination.pageObjects}
          onRowClick={handleRowClick}
          isShell={isShell}
        />
      ) : (
        <GenericTable
          columns={columns}
          data={pagination.pageObjects}
          onRowClick={handleRowClick}
          id={activeUser}
        />
      )}
      <PaginationContainer>
        <Pagination {...pagination} />
      </PaginationContainer>
    </Container>
  );
};

const Container = styled.div`
  th:first-child {
    min-width: 40px;
    width: 13%;
    padding-left: 20px;
  }

  th:nth-child(2) {
    min-width: 120px;
    width: 54%;
  }

  th:nth-child(3) {
    min-width: 120px;
    width: 20%;
  }

  th:last-child {
    text-align: left;
    min-width: unset;
    width: 6%;
  }

  td:last-child {
    padding-right: 20px;
    margin-left: 20px;
    text-align: right;
    & > * {
      margin-left: 20px;
    }
  }
`;

const PaginationContainer = styled.div`
  margin-top: 24px;
  &:empty {
    display: none;
  }
`;

const Cell = styled.div<{ withArrow?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  color: white;

  & > svg {
    width: 16px;
    height: 16px;
    color: #00bdff;
    // margin-left: 8px;
  }

  ${({ withArrow }) =>
    withArrow &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `};

  ${({ withArrow }) =>
    !withArrow &&
    css`
      svg {
        min-width: 24px;
        min-height: 24px;
      }
    `};
`;

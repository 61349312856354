import React from "react";
import styled from "styled-components";
import { Media } from "../../styles";
import smallShellIcon from "@/assets/coins/shell-small.svg";
import { formatDisplay } from "@/utils/formatDisplay";

interface ItemProps {
  dataTestId?: string;
  icon: string;
  iconRight?: string;
  title?: string;
  subtitle?: string;
  allocation?: string;
  tokensToClaim?: string;
  aboveTitle?: string;
  selected?: boolean
  onClick: () => void;
  vestingStreamVariant?: boolean;
}

export const CollectionButton = ({
  dataTestId,
  icon,
  iconRight,
  title,
  subtitle,
  allocation,
  tokensToClaim,
  aboveTitle,
  selected,
  onClick,
  vestingStreamVariant,
}: ItemProps) => {
  return vestingStreamVariant ? (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick}>
      <VestingStreamContainer>
      <VestingStreamLeftSideWrapper>
        <Logo src={icon} alt="Logo" vestingVariant />
        <Content>
          <AboveTitle>{aboveTitle}</AboveTitle>
          {title && <Title>{title}</Title>}
        </Content>
      </VestingStreamLeftSideWrapper>
      <VestingStreamRighSideContainer>
      <VestingStreamRightSideWrapper>
        <RightSideLabel>
        Claimable:
        </RightSideLabel>
        <RightSideValue>
          {formatDisplay(tokensToClaim, 2)}
          <img src={smallShellIcon} alt="small shell icon" />
        </RightSideValue>
      </VestingStreamRightSideWrapper>
      <VestingStreamRightSideWrapper>
        <RightSideLabel>
        Vesting:
        </RightSideLabel>
        <RightSideValue>
          {formatDisplay(allocation, 2)}
          <img src={smallShellIcon} alt="small shell icon" />
        </RightSideValue>
      </VestingStreamRightSideWrapper>
      </VestingStreamRighSideContainer>
      </VestingStreamContainer>
    </Button>
  ) : (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick}>
      <Logo src={icon} alt="Logo" />
      <Content>
        <AboveTitle>{aboveTitle}</AboveTitle>
        {title && <Title>{title}</Title>}
        <SubTitle>{subtitle}</SubTitle>
      </Content>
      {iconRight && (
        <IconRight>
          <img src={iconRight} alt="" />
        </IconRight>
      )}
    </Button>
  )
};

const Button = styled.button<{ selected: boolean | undefined }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 24px 13px 20px;
  text-align: left;
  background: ${props => props.selected ? 'linear-gradient(#171B33, #171B33) padding-box, linear-gradient(to right, #37dcf2, #07c0fb) border-box' : '#171B33'};
  border: 1px solid transparent;
  border-radius: 16px;

  &:hover {
    box-shadow: 0px 19px 45px #04081C;
    border-color: transparent;
    outline: ${props => props.selected ? 'none' : '2px solid #2C5173'};
  }
  ${Media.mobile} {
    padding-block: 8px;
  }
`;

const Logo = styled.img<{vestingVariant?: boolean}>`
  width: 60px;
  height: 60px;
  margin-right: 12px;
  border-radius: ${({vestingVariant}) => vestingVariant ? "12px" : "50%"};
  overflow: hidden;
`;

const VestingStreamContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

const VestingStreamLeftSideWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
`;

const VestingStreamRighSideContainer = styled.div`
display: flex;
gap: 30px;
justify-content: flex-end;
align-items: center;
`;

const VestingStreamRightSideWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
gap: 4px;
`;

const RightSideLabel = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */

${Media.smallMobile}{
    font-size: 11px;
}
`;

const RightSideValue = styled.p`
color: var(--White, #FFF);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
display: flex;
justify-content: flex-start;
align-items: center;
gap: 6px;

img {
  width: 16px;
  height: 16px;
}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px 0;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const AboveTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;

  ${Media.smallMobile}{
    font-size: 11px;
    line-height: 14px;
  }
`;

const SubTitle = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;

const IconRight = styled.div`
  margin-left: auto;
`;

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "../../components/Layout/Box";
import { Content } from "../../components/Layout/Content";
import { NFT_PRICES_API, POINTS_API, PRICES_API } from "../../constants/urls";
import { useNetwork, useProvider } from "wagmi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  isExternalToken,
  isLPToken,
  isNFTCollection,
} from "../../utils/tokens";
import { LogoLoader, Spinner } from "../../components/Loaders";
import { formatUnits } from "@ethersproject/units";
import { BigNumber, Contract } from "ethers";
import { OceanABI } from "../../constants/ABI/OceanABI";
import { OCEAN_ADDRESS, OLD_OCEAN_ADDRESS, SHELL_ADDRESS, STAKING_ADDRESS } from "../../constants/addresses";
import { addBalance, addNFTBalance } from "../../store/balancesSlice";
import { PoolQuery } from "../../utils/PoolQuery";
import { artifacts } from "../Booty/Items";
import { addPrice } from "../../store/pricesSlice";
import { WRAPPED_NFT_MAP, getNFTs } from "../../utils/nftHelpers";
import { removeLeadingZeros } from "../../utils/ocean/utils";
import { Media, breakpoints } from "@/styles";
import { BoxButton } from "@/components/Buttons/Button";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import arbitrumLogo from "@/assets/icons/arbitrum-logo.svg";
import { LeaderboardIcon } from "@/components/Icons/LeaderboardIcon";
import { useNavigate, useParams, useLocation } from "react-router";
import { SeasonFilter } from "../Rewards/components/Points/components/FilterSeasonDropdown";
import { PointsChart } from "../Rewards/components/Points/PointsChart";
import { PointsTable } from "../Rewards/components/Points/PointsTable";
import { HistoricalPointsTable } from "../Rewards/components/Points/HistoricalPointsTable";
import { PointsDetails } from "../Rewards/components/Points/PointsDetails";
import { reduceString } from "@/utils/reduceString";
import { CrabABI } from "@/constants/ABI/CrabABI";
import { formatDisplay } from "@/utils/formatDisplay";
import { BackArrowIcon } from "@/components/Icons/BackArrowIcon";
import { HistoryIcon } from "@/components/Icons/HistoryIcon";
import { useWidthBreakpoint } from "@/hooks";
import { updateBoosts, updateCurrentPoints, updateHistoricalPoints } from "@/store/pointsSlice";
import { dateFilters, seasonFilters, CURRENT_SEASON, convertToSlug } from "../../types/ChartTypes";
import { ShellWalletIcon } from "@/components/Icons/ShellWalletIcon";
import { queryLeaderboard, LeaderboardItem } from "../Leaderboard/queryLeaderboard";
import { useNavigationHelpers } from "@/hooks/navigation";
import { CopyButton } from "@/components/Buttons/CopyButton";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { defaultChain } from "@/placeholders/chains";
import { StakingABI } from "@/constants/ABI/StakingABI";

export const WalletScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleGoBack } = useNavigationHelpers();

  const provider = useProvider();

  const { chain: activeChain } = useNetwork();
  const validChain = activeChain?.name == defaultChain.name;

//   const poolQuery = new PoolQuery(tokenMap);
  const { tokens, nftCollections, shellTokens, externalTokens, externalDefiTokens, tokenMap, poolQuery } = useContext(ChainContext)

  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const location = useLocation();

  const [pointData, setPointData] = useState({
    totalShellPoints: 0,
    forecastedPointsGain: 0,
    compoundedPoints: 0,
    boost: {},
    tokens: {},
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isRankLoading, setIsRankLoading] = useState(true);

  const [totalValue, setTotalValue] = useState(0);
  const [shellBalance, setShellBalance] = useState("");
  const [rank, setRank] = useState<number | undefined>(location.state?.rank);

  const oldOcean = new Contract(OLD_OCEAN_ADDRESS, OceanABI, provider);
  const ocean = new Contract(OCEAN_ADDRESS, OceanABI, provider!);
  const staking = new Contract(STAKING_ADDRESS, StakingABI, provider);

  const userBoosts = useAppSelector((state) => state.points.boosts)[id!] ?? {};
  const userCurrentPoints = useAppSelector((state) => state.points.currentSeason);
  const userHistoricalPoints = useAppSelector((state) => state.points.historicalSeason);
  const cachedLeaderboards = useAppSelector((state) => state.leaderboards.leaderboards);
  const dispatch = useAppDispatch();

  const [seasonFilter, setSeasonFilter] = useState<SeasonFilter>({
    label: CURRENT_SEASON,
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState("All");

  const getPrices = async () => {
    const priceData: { [id: string]: any } = {};

    const [pricesResponse, nftPricesResponse] = await Promise.all([
      fetch(PRICES_API(defaultChain)).then((response) => response.json()),
      fetch(NFT_PRICES_API(defaultChain)).then((response) => response.json()),
    ]);

    if (pricesResponse) {
      Object.keys(pricesResponse).forEach((tokenID) => {
        const tokenPrice = pricesResponse[tokenID];
        dispatch(addPrice({ chain: defaultChain.name, name: tokenID, price: tokenPrice }));
        dispatch(addPrice({ chain: defaultChain.name, name: "sh" + tokenID, price: tokenPrice }));
        priceData[tokenID] = tokenPrice;
        priceData["sh" + tokenID] = tokenPrice;
      });
    } else {
      console.error("Server not responding properly");
      return {};
    }

    if (nftPricesResponse) {
      Object.keys(nftPricesResponse).forEach((tokenID) => {
        const tokenPrice = nftPricesResponse[tokenID];
        dispatch(addPrice({ chain: defaultChain.name, name: tokenID, price: tokenPrice }));
        dispatch(addPrice({ chain: defaultChain.name, name: "sh" + tokenID, price: tokenPrice }));
        priceData[tokenID] = tokenPrice;
        priceData["sh" + tokenID] = tokenPrice;
      });
    }

    const lpTokenPrices = await Promise.all(
      shellTokens
        .map((shellToken) => poolQuery.getUSDPrice(shellToken, priceData))
        .concat(externalDefiTokens.filter((defiToken) => defiToken.tokenType == "Shell").map((lpToken) => poolQuery.getUSDPrice(lpToken, priceData)))
    );

    if (lpTokenPrices) {
      shellTokens.forEach((shellToken, i) => {
        const tokenPrice = lpTokenPrices[i];
        dispatch(addPrice({ chain: defaultChain.name, name: shellToken.name, price: tokenPrice }));
        priceData[shellToken.name] = tokenPrice;
      });

      externalDefiTokens
        .filter((defiToken) => defiToken.tokenType == "Shell")
        .forEach((shellToken, i) => {
          const tokenPrice = lpTokenPrices[i + shellTokens.length];
          dispatch(addPrice({  chain: defaultChain.name, name: shellToken.symbol, price: tokenPrice }));
          priceData[shellToken.symbol] = tokenPrice;
        });
    }

    return priceData;
  };

  const queryPoints = async () => {
    if (seasonFilter.label !== CURRENT_SEASON) {
      let pointData;
      const slug = convertToSlug(seasonFilter.label);
      if (userHistoricalPoints[id!] && userHistoricalPoints[id!][slug]) {
        const cachedData = userHistoricalPoints[id!][slug];
        pointData = JSON.parse(cachedData);
      } else {
        const data = await fetch(POINTS_API(defaultChain) + id + "/" + slug).then(
          (response) => response.json()
        );
        pointData = {
          totalShellPoints: data.totalShellPoints ?? 0,
          forecastedPointsGain: 0,
          compoundedPoints: data.tokens.compoundedPoints?.totalPoints ?? 0,
          boost: 0,
          tokens: data.tokens,
        };
        dispatch(
          updateHistoricalPoints({
            address: id,
            season: slug,
            value: JSON.stringify(pointData),
          })
        );
      }
      setPointData(pointData);
      setTotalValue(0);
      setShellBalance("0");
      return;
    }

    const currentTime = Math.floor(Date.now() / 1000);

    if (userCurrentPoints[id!]) {
      const cachedData = userCurrentPoints[id!];
      const elapsedTimeRatio = (currentTime - cachedData.time) / (60 * 60 * 24);
      const pointData = JSON.parse(cachedData.pointData);
      pointData.totalShellPoints += pointData.forecastedPointsGain * elapsedTimeRatio;

      const boostData = pointData.boost;

      if (userBoosts["Active Boost"]) {
        boostData.currentBoost = userBoosts["Active Boost"];
      }

      artifacts.forEach((artifact: any, index) => {
        if (artifact.effect?.type == "Booster") {
          if (boostData.activatedArtifacts[index.toString()]) {
            let boost = boostData.activatedArtifacts[index.toString()];
            boostData.activatedArtifacts[index.toString()].timeRemaining = Math.max(parseFloat(boost.timeRemaining), userBoosts[artifact.name] ?? 0);
            dispatch(updateBoosts({ address: id, name: boost.name, value: parseFloat(boost.timeRemaining) }));
          } else if (userBoosts[artifact.name]) {
            boostData.activatedArtifacts[index.toString()] = { name: artifact.name, timeRemaining: userBoosts[artifact.name] };
          }
        }
      });

      setPointData(pointData);
      setTotalValue(cachedData.totalValue);
      setShellBalance(cachedData.shellBalance);
      return;
    }

    const tokenIDs = shellTokens
      .map((shellToken) => shellToken.name)
      .concat(tokens.filter((token) => token.wrapped).map((token) => token.symbol))
      .concat(externalDefiTokens.filter((token) => token.tokenType == "Shell").map((token) => token.symbol))
      .concat(externalTokens.filter((token) => token.tokenType == "Shell").map((token) => token.symbol))
      .concat(nftCollections.filter((collection) => collection.wrapped).map((collection) => collection.symbol));

    const fungibleTokenIDs = tokenIDs.filter((tokenID) => !isNFTCollection(tokenMap[tokenID]));

    const oceanFungibles = fungibleTokenIDs.filter((tokenID) => !isExternalToken(tokenMap[tokenID]))
    const oldOceanFungibles = fungibleTokenIDs.filter((tokenID) => isExternalToken(tokenMap[tokenID]))

    const [data, oldBalanceResponse, balanceResponse, veShellBalance, userOceanNFTs, tokenPrices] =
      await Promise.all([
        fetch(POINTS_API(defaultChain) + id + "/now").then((response) => response.json()),
        oldOcean.balanceOfBatch(
            oldOceanFungibles.map((_) => id),
            oldOceanFungibles.map((tokenID) => tokenMap[tokenID].oceanID)
        ),
        ocean.balanceOfBatch(
          oceanFungibles.map((_) => id),
          oceanFungibles.map((tokenID) => tokenMap[tokenID].oceanID)
        ),
        staking.balanceOf(id),
        getNFTs(id ?? "0xNull", OCEAN_ADDRESS),
        getPrices(),
      ]);

    const tokenData = data.tokens;
    const fungibleBalances: { [id: string]: number } = {};

    oldBalanceResponse.forEach((balance: any, index: number) => (fungibleBalances[oldOceanFungibles[index]] = balance));

    balanceResponse.forEach((balance: any, index: number) => (fungibleBalances[oceanFungibles[index]] = balance));

    let priceError = Object.keys(tokenPrices).length == 0;

    const diffs = [];
    let newTotalValue = 0;

    tokenIDs.push("veSHELL");

    for (let i = 0; i < tokenIDs.length; i++) {
      const tokenID = tokenIDs[i];

      if (!tokenData[tokenID]) continue;

      if (tokenID == "veSHELL") {
        tokenData[tokenID].balance = formatUnits(veShellBalance);
        continue;
      }

      const token = tokenMap[tokenID];

      if (isNFTCollection(token)) {
        const collectionID = token.symbol;

        const wrappedIDs = WRAPPED_NFT_MAP[collectionID];

        const userWrappedNFTs: any[] = [];
        userOceanNFTs.forEach((userOceanNFT: any) => {
          const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString());
          if (wrappedIDs[oceanID]) {
            if (token.is1155) {
              userWrappedNFTs.push({ id: parseInt(wrappedIDs[oceanID][0]), balance: userOceanNFT.balance });
            } else {
              userWrappedNFTs.push(parseInt(wrappedIDs[oceanID][0]));
            }
          }
        });

        // Update Redux store with balances

        const chainBalance = userWrappedNFTs.length;

        dispatch(addNFTBalance({ collection: collectionID, items: userWrappedNFTs }));

        diffs.push(Math.abs(chainBalance - tokenData[collectionID].balance));

        let tokenValue = 0;

        if (token.is1155) {
          for (let item of userWrappedNFTs) {
            tokenValue += tokenPrices[tokenID].find((e: any) => e.id == item.id.toString()).price * item.balance * 100;
          }
        } else {
          tokenValue = tokenPrices[tokenID] * chainBalance;
        }

        // const tokenValue = tokenPrices[tokenID] * chainBalance;
        newTotalValue += tokenValue;

        // Add new fields to tokenData
        tokenData[collectionID].balance = chainBalance;
        tokenData[collectionID].value = priceError ? 0 : tokenValue;
      } else {
        // Update Redux store with balances

        const chainBalance = formatUnits(fungibleBalances[tokenID]);
        dispatch(addBalance({ chain: defaultChain.name, address: token.oceanID, amount: chainBalance }));

        diffs.push(Math.abs(parseFloat(chainBalance) - tokenData[tokenID].balance));

        const tokenValue = tokenPrices[tokenID] * parseFloat(chainBalance);
        newTotalValue += tokenValue;

        // Add new fields to tokenData
        tokenData[tokenID].balance = parseFloat(chainBalance);
        tokenData[tokenID].value = priceError ? 0 : tokenValue;
      }
    }

    setTotalValue(newTotalValue);
    const balanceError = diffs.filter((diff) => diff >= 0.001).length > 0;

    const boostData = data.boost;

    if (userBoosts["Active Boost"]) {
      boostData.currentBoost = userBoosts["Active Boost"];
    } else {
      dispatch(
        updateBoosts({
          address: id,
          name: "Active Boost",
          value: parseFloat(boostData.currentBoost),
        })
      );
    }

    artifacts.forEach((artifact: any, index) => {
      if (artifact.effect?.type == "Booster") {
        if (boostData.activatedArtifacts[index.toString()]) {
          let boost = boostData.activatedArtifacts[index.toString()];
          boostData.activatedArtifacts[index.toString()].timeRemaining = Math.max(parseFloat(boost.timeRemaining), userBoosts[artifact.name] ?? 0);
          dispatch(
            updateBoosts({
              address: id,
              name: boost.name,
              value: parseFloat(boost.timeRemaining),
            })
          );
        } else if (userBoosts[artifact.name]) {
          boostData.activatedArtifacts[index.toString()] = {
            name: artifact.name,
            timeRemaining: userBoosts[artifact.name],
          };
        }
      }
    });

    const pointData = {
      totalShellPoints: data.totalShellPoints ?? 0,
      forecastedPointsGain: balanceError ? -1 : data.next24hGain ?? 0,
      compoundedPoints: seasonFilter.label == "Season One" ? tokenData.compoundedPoints.totalPoints : 0,
      boost: data.boost,
      tokens: tokenData,
    };

    let shellBalance = "0";

    try {
      const shell = new Contract(SHELL_ADDRESS, CrabABI, provider);
      shellBalance = formatDisplay(formatUnits(await shell.balanceOf(id)), 2);
    } catch {}

    tokenData["SHELL"] = {
      // add SHELL token to the data
      quota: -1,
      balance: +shellBalance,
      rewardAPY: 0,
      value: tokenPrices["SHELL"],
      totalPoints: 0,
      historical: {},
    };

    setPointData(pointData);
    setShellBalance(shellBalance);

    dispatch(
      updateCurrentPoints({
        address: id,
        value: {
          time: currentTime,
          pointData: JSON.stringify(pointData),
          totalValue: newTotalValue,
          shellBalance: shellBalance,
        },
      })
    );

    return balanceError || priceError;
  };

  const timeout = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const intervalQuery = async (numIntervals: number) => {
    for (let i = 0; i < numIntervals; i++) {
      const error = await queryPoints();
      if (error) {
        // Query Shell API at staged intervals to allow time to sync
        await timeout(20 * 1000);
      } else {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (Object.keys(pointData.tokens).length === 0) {
      setIsLoading(true);
    }

    queryPoints()
      .then(() => {
        if (Object.keys(pointData.tokens).length > 0 && isLoading) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        intervalQuery(5);
      });
  }, [id && seasonFilter.label, Object.keys(pointData.tokens).length > 0]);

  useEffect(() => {
    setIsRankLoading(true);

    queryLeaderboard(dispatch, cachedLeaderboards).then(() => {
      const slug = convertToSlug(CURRENT_SEASON);
      if (cachedLeaderboards[slug]) {
        const matchedRank = cachedLeaderboards[slug].find((entry: LeaderboardItem) => entry.address === id);
        if (matchedRank) {
          setRank(matchedRank.id);
        }
      }
      setIsRankLoading(false);
    });
  }, [rank, id, cachedLeaderboards, dispatch]);

  return (
    <Content>
      <View>
        <Header>
          <Title onClick={() => handleGoBack()}>
            <BackArrowIcon />
            Wallet View
          </Title>
        </Header>
        <ContentWrapper>
          <WalletInfoContainer>
            <ShellWalletIcon />
            <span>
              <WalletAddressContainer>
                {isTablet ? reduceString(id!, 8, 6) : id}
                <CopyButton text={id || ""} showText={!isTablet} />
              </WalletAddressContainer>
            </span>
            <>
              {isRankLoading ? (
                <SkeletonBox isLoading={isRankLoading} width="auto" height="2rem" borderRadius="16px" padding="0 12px" animationDuration="2s" />
              ) : (
                <RankComponent rank={rank} />
              )}
            </>
          </WalletInfoContainer>
          <ButtonsContainer>
            <BoxButtonStyled
              onClick={() => {
                navigate({
                  pathname: `/leaderboard/` + id,
                });
              }}
            >
              <LeaderboardIcon />
              Leaderboard
            </BoxButtonStyled>
            <BoxButtonStyled
              onClick={() => {
                navigate({
                  pathname: `/history/` + id,
                });
              }}
            >
              <HistoryIcon />
              History
            </BoxButtonStyled>
            <BoxButtonStyled
              onClick={() => {
                window.location.href =
                  defaultChain.explorerUrl + "/address/" + id;
              }}
            >
              <Icon src={arbitrumLogo} />
              View on Arbiscan
            </BoxButtonStyled>
          </ButtonsContainer>
        </ContentWrapper>
        {isLoading ? (
          <LoadingWrapper>
            <CircleContainer>
              <Spinner />
            </CircleContainer>
          </LoadingWrapper>
        ) : (
          <>
            <PointsDetails
              isLoading={isLoading}
              totalShellPoints={pointData.totalShellPoints}
              forecastedPointsGain={pointData.forecastedPointsGain}
              compoundedPoints={pointData.compoundedPoints}
              boost={pointData.boost}
              totalValue={totalValue}
              seasonFilter={seasonFilter}
              shellBalance={shellBalance}
              isOwnWallet={false}
              seasonFilters={seasonFilters}
              setSeasonFilter={setSeasonFilter}
            />
            <PointsChart tokens={pointData.tokens} selectedSeasonFilter={seasonFilter.label} selectedDateFilter={selectedDateFilter}>
              <ChartHeader>
                {seasonFilter.label === CURRENT_SEASON && (
                  <DateFiltersContainer>
                    {dateFilters.map((item, index) => (
                      <DateFilterButton key={index} onClick={() => setSelectedDateFilter(item.label)} active={item.label === selectedDateFilter}>
                        {item.label}
                      </DateFilterButton>
                    ))}
                  </DateFiltersContainer>
                )}
              </ChartHeader>
            </PointsChart>
            {seasonFilter.label == CURRENT_SEASON ? (
              <PointsTable tokens={pointData.tokens} walletAddress={id} />
            ) : (
              <HistoricalPointsTable tokens={pointData.tokens} />
            )}
          </>
        )}
      </View>
    </Content>
  );
};

interface RankProps {
  rank: number | undefined;
  width?: string;
  height?: string;
  fontSize?: string;
  padding?: string;
}

export const RankComponent: React.FC<RankProps> = ({ rank, width, height, fontSize, padding }) => (
  <RankBadge width={width} height={height} padding={padding}>
    <RankText fontSize={fontSize}>{rank ? `#${rank}` : "No Rank"}</RankText>
  </RankBadge>
);

const View = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 40px;

  ${Media.tablet} {
    padding: 16px 8px 40px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;

  ${Media.tablet} {
    font-size: 20px;
    gap: 8px;
  }
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 245px 0 245px 0;
`;

const BoostBoxes = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  ${Media.tablet} {
    flex-direction: column;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  ${Media.tablet} {
    flex-direction: column;
  }
`;

const BoxButtonStyled = styled(BoxButton)`
  flex-grow: 1;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #171b33;
`;

const WalletInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2em;

  span {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 18px;
    gap: 8px;
    cursor: pointer;
  }

  ${Media.mobile} {
    font-size: 20px;
    gap: 8px;
    flex-direction: column;
  }
`;

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: auto;
`;

const DateFilterButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 32px;
  background: #1e2239;
  border: 1px solid #292941;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ active }) => (active ? "#FFFFFF" : "#7D7D97")};

  &:hover {
    color: #ffffff;
  }

  ${Media.tablet} {
    width: 42px;
    height: 28px;
  }
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  ${Media.tablet} {
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    padding: 0px;
  }
`;

const SeasonFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateFiltersContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const RankBadge = styled.div<{
  width?: string;
  height?: string;
  padding?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: rgba(0, 189, 255, 0.1);
  border: 1.5px solid rgb(0, 189, 255);
  height: ${({ height }) => height || "2rem"};
  width: ${({ width }) => width || "auto"};
  padding: ${({ padding }) => padding || "0 12px"};
  flex-shrink: 1;
`;

const RankText = styled.div<{ fontSize?: string }>`
  font-family: Arial, sans-serif;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  background: linear-gradient(to right, #37dcf2, #07c0fb);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-anchor: middle;
`;

const WalletAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import * as S from "./Input.styled";
import "./Input.css"; 

type AdditionalLabelType = {
    label: string;
    weight: number;
}

type InputProps = {
    label?: string;
    additionalLabel?: AdditionalLabelType | React.ReactNode;
    awardedColor?: boolean;
    isBig?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;


export const Input = ({ label, additionalLabel, awardedColor, isBig, ...rest }: InputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { value } = rest;
    const [focused, setFocused] = useState(false);
    const handleFocus = () => setFocused(true);
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setFocused(false);
        } else {
            setFocused(true);
        };

        const handleClickOutside = (e: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
                setFocused(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);
    };
    return (
        <S.InputContainer focused={focused} isBig={isBig}>
            <S.InputWrapper>
                {label && (
                    <S.InputLabel htmlFor="first" focused={focused} value={!!value || value === 0}>
                        {label}
                    </ S.InputLabel>
                )}
                <S.Input
                    ref={inputRef}
                    awardedColor={awardedColor}
                    isBig={isBig}
                    {...rest}
                    id="first"
                    type="text"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </S.InputWrapper>
            {additionalLabel && (
                <S.AdditionalLabel labelWeight={typeof additionalLabel === 'object' && additionalLabel !== null && 'label' in additionalLabel && 'weight' in additionalLabel
                    ? additionalLabel.weight
                    : undefined}>
                    {typeof additionalLabel === 'object' && additionalLabel !== null && 'label' in additionalLabel && 'weight' in additionalLabel
                        ? additionalLabel.label
                        : additionalLabel}
                </S.AdditionalLabel>
            )}
        </S.InputContainer>
    )
};
import React from 'react';
import { Provider } from 'react-redux'
import { store } from "./store/store"
import { Routes, Route, Navigate } from 'react-router-dom';
import { TradeScreen } from './pages/Trade/TradeScreen';
import { Providers } from './providers/Providers';
import { Header } from './components/Header/Header';
import { CollabAirdropBanner } from './components/CollabAirdropBanner';
import { Tabs } from './components/Tabs';
// import { ExploreScreen } from './pages/Explore/ExploreScreen';
import { RewardsScreen } from './pages/Rewards/RewardsScreen';
import { PointsScreen } from './pages/Rewards/components/Points/PointsScreen';
import { AirDrop } from "./pages/Rewards/components/AirDrop/AirDrop";
import { Staking } from "./pages/Rewards/components/Staking/Staking";
import { Vesting } from "./pages/Rewards/components/Vesting/Vesting";
import { ExploreScreen } from './pages/Explore/ExploreScreen';
import { BootyScreen } from './pages/Booty/BootyScreen';
import { LBPScreen } from './pages/LBP/LBPScreen';
import { LeaderboardScreen } from './pages/Leaderboard/LeaderboardScreen';
import { TransactionSearchScreen } from './pages/Transactions/TransactionSearch';
import { WalletScreen } from './pages/Wallet/Wallet';
import { TransactionHistoryScreen } from './pages/Transactions/TransactionHistory';
import { StatisticsScreen } from './pages/Statistics/Statistics';
import { PoolsScreen } from './pages/Pools/PoolsScreen';
import { ShellGuideModal } from './components/ShellGuideModal/ShellGuideModal';
import { STIP } from './pages/Rewards/components/STIP/STIP';

export const App = () => (
  <Providers>
    <Provider store={store}>
      <Header />
      {/* <CollabAirdropBanner /> */}
      <Tabs />
      <Routes>
        <Route path='/trade' element={<TradeScreen />} />
        {/* <Route path='/wallet' element={<SimpleWalletScreen />} /> */}
        {/* <Route path='/explore' element={<ExploreScreen />} /> */}
        <Route path='/rewards' element={<RewardsScreen />}>
          <Route index element={<Navigate replace to="airdrop" />} />
          <Route index path='shell' element={<PointsScreen />} />
          <Route path='airdrop' element={<AirDrop />} />
          <Route path='staking' element={<Staking />} />
          <Route path='vesting' element={<Vesting />} />
          <Route path='stip' element={<STIP />} />
        </Route>
        {/* <Route path='/booty' element={<BootyScreen />} /> */}
        <Route path='/explore' element={<ExploreScreen />} />
        <Route path='/lbp/:tokenName' element={<LBPScreen />} />
        <Route path='/history/:hash' element={<TransactionHistoryScreen />} /> 
        <Route path='/leaderboard/:hash?' element={<LeaderboardScreen />} /> 
        <Route path='/tx/:hash' element={<TransactionSearchScreen />} /> 
        <Route path='/pools' element={<PoolsScreen />} />
        <Route path='/statistics/:tokenName' element={<StatisticsScreen />} /> 
        <Route path='/wallet/:id' element={<WalletScreen />} /> 
        <Route path='/' element={<Navigate replace to='/trade' />} />
      </Routes>
    </Provider>
  </Providers>
);

import { addLeaderboard } from "@/store/leaderboardsSlice";
import { SHELL_API_URL } from "@/constants/urls";
import { formatDisplay } from "@/utils/formatDisplay";
import { convertToSlug } from "@/types/ChartTypes";

export interface LeaderboardItem {
  id: number;
  address: string;
  points: string;
  clickLink: string;
}

interface LeaderboardResult {
  leaderboard: Record<string, any>;
  time: string
}

type DispatchFunction = (action: any) => void;

export const queryLeaderboard = async (
  dispatch: DispatchFunction,
  cachedLeaderboards: Record<string, LeaderboardItem[]>,
  signal?: AbortSignal | undefined
): Promise<LeaderboardItem[]> => {
  const slug = convertToSlug('Season Five');
  if (cachedLeaderboards[slug]) {
    return cachedLeaderboards[slug];
  } else {
    try {
    const response = await fetch(`${SHELL_API_URL}points/leaderboard/${slug}`, { signal });
    const result: LeaderboardResult = await response.json();

    const leaderboard: LeaderboardItem[] = Object.keys(result.leaderboard).map(
      (wallet, index) => ({
        id: index + 1,
        address: wallet,
        points: formatDisplay(result.leaderboard[wallet], 0),
        clickLink: wallet,
      })
    );

    dispatch(addLeaderboard({ id: slug, value: leaderboard, date: result.time }));
    return leaderboard;
  } catch (error: any) {
    if (error.name === 'AbortError') {
      //console.log('Fetch aborted - leaderboard');
    }
  }
  }
  return [];
};

import React from "react";
import styled from "styled-components";
import { Media } from "@/styles";
import { useNavigate } from "react-router";

interface SearchNotFoundMessageProps {
  searchValue: string;
  navPath: string;
  navLabel: string;
}

export const SearchNotFoundMessage: React.FC<SearchNotFoundMessageProps> = ({
    searchValue,
    navPath,
    navLabel
  }) => {
    const navigate = useNavigate();

  return (
    <>
      <SearchContainer>
        <IconContainer>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3099 31.6667C25.6737 31.6667 31.6432 25.6971 31.6432 18.3333C31.6432 10.9695 25.6737 5 18.3099 5C10.9461 5 4.97656 10.9695 4.97656 18.3333C4.97656 25.6971 10.9461 31.6667 18.3099 31.6667Z"
              stroke="#00BDFF"
              strokeWidth="3.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34.9688 35L27.7188 27.75"
              stroke="#00BDFF"
              strokeWidth="3.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconContainer>
      </SearchContainer>
      <TextContainer>
        <TextPrimary>Search not found</TextPrimary>
        <TextSecondary>
          We found nothing for the search: <br /> <span>{searchValue}</span>
        </TextSecondary>
      </TextContainer>
      <Button onClick={() => navigate(navPath)}>
        {navLabel}
        <svg
          width="19"
          height="15"
          viewBox="0 0 19 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3361 0.813969C11.9486 0.420457 11.3154 0.415596 10.9219 0.80311C10.5284 1.19062 10.5235 1.82377 10.911 2.21728L15.1469 6.51873L1.07962 6.51874C0.527335 6.51874 0.0796204 6.96645 0.0796204 7.51874C0.0796204 8.07102 0.527336 8.51874 1.07962 8.51874L15.1103 8.51873L10.911 12.783C10.5235 13.1765 10.5284 13.8097 10.9219 14.1972C11.3154 14.5847 11.9486 14.5798 12.3361 14.1863L18.2294 8.2018L18.9204 7.50015L18.2294 6.79849L12.3361 0.813969Z"
            fill="#0B1639"
          />
        </svg>
      </Button>
    </>
  );
};

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  background: #171b33;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 16px 0 18px 0;
`;

const TextPrimary = styled.div`
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2em;
  color: #ffffff;
`;

const TextSecondary = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45em;
  color: #7d7d97;
  text-align: center;

  span {
    font-weight: 500;
  }
`;

const Button = styled.div`
  padding: 17px 30px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2em;
  color: #0a0e27;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
  margin-inline: auto;

  ${Media.tablet} {
    width: 100%;
    margin-left: 0;
  }
`;

import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import { Box } from "@/components/Layout/Box";
import { Media } from "@/styles";
import styled from "styled-components";

export const VestingContainer = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
justify-content: flex-start;
align-items: center;
width: 100%;
margin-top: 24px;
`;

export const VestingDetailsContainer =  styled.div`
display: flex;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 24px;
border-radius: 16px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
width: 100%;

${Media.tablet} {
    padding: 16px;
}
`;

export const HeaderRowWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

${Media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const LabelAndValueWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 8px;
`;

export const LabelWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 4px;
`;

export const Label = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const VestingValueWrapper = styled.div`
display: flex;
gap: 8px;
justify-content: flex-start;
align-items: center;
`;

export const ShellImage = styled.img`
width: 32px;
height: 32px;
`;

export const Value = styled.p`
color: var(--White, #FFF);

/* Title 6 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`;

export const ActionsWrapper = styled.div`
display: flex;
gap: 16px;
justify-content: flex-end;
align-items: center;

${Media.tablet} {
    width: 100%;
  }
`;

export const HrDiv = styled.div`
height: 1px;
width: 100%;
background: #1E2239;
`;

export const CustomHrDiv = styled.div`
height: 1px;
width: 100%;
background: #171B33;
`;

export const DistributionTimeWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;
`;

export const DistributionLabel = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const DistributionTime = styled.p`
color: var(--White, #FFF);
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

export const CustomPrimaryBtn = styled(ButtonPrimary)`
width: 140px;
border-radius: 12px;
height: unset;
padding: 16px 20px;

${Media.tablet} {
    width: 100%;
  }
  
${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border: 2px solid #1E2239;
background: var(--dark-4, #151530);
`}
`;

export const CustomSecondaryBtn = styled(ButtonSecondary)`
width: 140px;
border-radius: 12px;
height: unset;
padding: 16px 20px;
border-width: 2px;

${Media.tablet} {
    width: 100%;
  }

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border-color: #1E2239;
`}
`;

export const DetailsAndProgressWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 20px;
width: 100%;

${Media.tablet} {
    flex-direction: column;
  }
`;

export const BoxWrapper = styled.div`
display: flex;
width: 100%;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 24px;
border-radius: 16px;
border: 1px solid var(--dark-2, #171B33);
background: var(--dark-1, #0A0E27);

${Media.tablet} {
    padding: 16px;
}
`;

export const BoxTitle = styled.p`
color: var(--grey-1, #F0F2F6);

/* Text 2/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const BoxDetailsWrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 12px;
`;

export const BoxDetailsRow = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
`;

export const BoxDetailsLabel = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

export const BoxDetailsValue = styled.p`
color: var(--White, #FFF);
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
span {
    color: #7D7D97;
}
`;

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 300px 0 300px 0;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 72px;
  text-align: center;
  vertical-align: middle;
  line-height: 72px;

  a {
    text-decoration: underline;
    color: #00BDFF;

    :visited {
      color: #00BDFF;
    }
  }

  ${Media.mobile}{
    line-height: 24px;
    margin-top: 50%;
    font-size: 12px;
  }
`
import React, { ReactNode, useContext } from "react";
import styled from "styled-components";
import { Bullet, LiveText, StatusBullet, StatusContainer } from "../LBP/StatusBullet";
import { Media } from "@/styles";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { isLPToken } from "@/utils/tokens";
import { useNavigate } from "react-router";
import { extract1155Data } from "@/utils/nftHelpers";
import { itemsMap } from "../Booty/Items";
import { DexBadge } from "@/components/DexBadge/DexBadge";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface PoolsTableRowMobileProps {
  icon: string;
  name: string;
  subname: string;
  status?: string;
  info?: { title: string; value: string | ReactNode; color?: string }[];
}

export const PoolsTableRowMobile = ({ icon, name, subname, status, info }: PoolsTableRowMobileProps) => {
  const navigate = useNavigate();
  const { poolQuery, tokenMap } = useContext(ChainContext)
  const handleRowClick = (token: any) => poolQuery.redirectToPool(token, navigate);

  const tokenID = name

  const data1155 = extract1155Data(name)
          
  if(data1155){
    const item = itemsMap[parseInt(data1155.id)]
    name = item.name
    subname = tokenMap[data1155.symbol].name
    icon = `../../assets/booty/${item.image}.jpg`
  }

  let protocol, protocolVersion

  if(isLPToken(tokenMap[name])){
    // @ts-ignore
    protocol = tokenMap[name].tokenType
    // @ts-ignore
    protocolVersion = tokenMap[name].protocolVersion ?? ''
  }

  return (
    <PoolsTableRowMobileContainer onClick={() => {handleRowClick(tokenMap[tokenID])}}>
      <PoolsTableRowMobileHeader>
        <Icon src={icon} />
        <FlexContainer>
          <Title>
            {name}{" "}
          </Title>
          {/* <Description>{subname}</Description> */}
          <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
            <Description>{subname}</Description>
            {protocol && <DexBadge protocol={protocol} protocolVersion={protocolVersion}/>}
          </div>
        </FlexContainer>
      </PoolsTableRowMobileHeader>
      {info?.length ? (
        <InfoContainer>
          {info?.map(({ title, value, color }) => (
            <InfoBox key={title + value}>
              {title !== 'Status' && <InfoTitle>{title}</InfoTitle>}
              {title == 'Status' ?  <StatusBullet status={value as string} /> : <InfoValue color={color}>{value}</InfoValue>}
            </InfoBox>
          ))}
        </InfoContainer>
      ) : null}
      {/* <TableButton>
        View more
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3983_174)">
            <path
              d="M13.1727 11.9997L8.22266 7.04974L9.63666 5.63574L16.0007 11.9997L9.63666 18.3637L8.22266 16.9497L13.1727 11.9997Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_3983_174">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </TableButton> */}
    </PoolsTableRowMobileContainer>
  );
};

const TableButton = styled(ButtonSecondary)`
  width: 100%;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  backdrop-filter: blur(25px);
  z-index: 1;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.tablet} {
    width: 100%;
  }
`;

const Blur = styled.div<{ color?: string }>`
  width: 578px;
  height: 578px;
  position: absolute;
  bottom: -578px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 366px;
  opacity: 0.2;
  background: ${({ color }) => color || "#72CCD7"};
  filter: blur(75px);
  z-index: 0;
  transition: all 0.3s ease;
`;

const PoolsTableRowMobileContainer = styled.div`
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--dark-3, #1e2239);
  background: var(--dark-4, #151530);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: #2c5173;
    ${Blur} {
      border-radius: 578px;
      bottom: -480px;
    }
  }

  ${Media.tablet} {
    padding: 16px;
  }
`;

const PoolsTableRowMobileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--white, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
`;
const Description = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const InfoTitle = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoValue = styled.div<{ color?: string }>`
  color: ${({ color }) => color || "#fff"};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StatusBulletContainer = styled.div`
  ${StatusContainer} {
    padding: 3px 8px;
  }
  ${LiveText} {
    font-size: 12px;
    line-height: normal;
  }
  ${Bullet} {
    width: 8px;
    height: 8px;
    &::after {
      width: 4px;
      height: 4px;
    }
  }
`;

import React, { createContext, useState, useEffect, ReactNode } from "react";
import { IS_DEV_MODE } from "@/constants/environment";

export interface GeoData {
  isBlocked?: boolean;
  country?: string;
}

interface GeoContextType {
  geoData: GeoData | null;
  isGeoLoading: boolean;
  geoError: Error | null;
  isUS: boolean;
}

const defaultGeoContextValue: GeoContextType = {
  geoData: null,
  isGeoLoading: true,
  geoError: null,
  isUS: false,
};

interface GeoProviderProps {
  children: ReactNode;
}

export const GeoContext = createContext(defaultGeoContextValue);

export const GeoProvider: React.FC<GeoProviderProps> = ({ children }) => {
  const [geoData, setGeoData] = useState<GeoData | null>(null);
  const [geoError, setGeoError] = useState<Error | null>(null);
  const [isGeoLoading, setIsGeoLoading] = useState(true);
  const [isUS, setIsUS] = useState(false);

  const fetchGeoLocation = async () => {
    if (!IS_DEV_MODE) { // prevent API call in development mode
      try {
        const response = await fetch("/api/geolocation");
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const data: GeoData = await response.json();
        setGeoData(data);
        setIsUS(data.isBlocked!);
      } catch (error) {
        setGeoError(error instanceof Error ? error : new Error("Error in fetching geolocation"));
      } finally {
        setIsGeoLoading(false);
      }
    } else {
      console.log("GeoLocation API call skipped in development mode");
      setGeoData({ country: "JPN", isBlocked: false });
      setIsUS(false);
      setIsGeoLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchGeoLocation();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <GeoContext.Provider value={{ geoData, isGeoLoading, geoError, isUS }}>
      {children}
    </GeoContext.Provider>
  );
};

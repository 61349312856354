import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { tokenColors } from "../../../../constants/tokenColors";
import { isNFTCollection, isShellToken, maxTokenDecimals} from "../../../../utils/tokens";
import { reduceString } from "../../../../utils/reduceString";
import { PointsSortableTable } from "./components/PointsSortableTable";
import { BalanceCell } from "./components/TableCells/BalanceCell";
import { MultiplierCell } from "./components/TableCells/MultiplierCell";
import { PointsTokenPreviewCell } from "./components/TableCells/PointsTokenPreviewCell";
import { ValueCell } from "./components/TableCells/ValueCell";
import { PoolSubRow } from "./components/PoolSubRow";
import { TokenSubRow } from "./components/TokenSubRow";
import { ExpandButton } from "../../../../components/Table/ExpandButton";
import { breakpoints, Media } from "../../../../styles";
import { useWidthBreakpoint } from "../../../../hooks";
import { PointsTale } from "./components/PointsTale";
import { useSigner, useProvider, useAccount } from "wagmi";
import { CollectionFilter, FilterCollectionsDropdown } from "./components/FilterCollectionsDropdown";
import { NFTSubRow } from "./components/NFTSubRow";
import { TotalRewardsCell } from "./components/TableCells/TotalRewardsCell";
import { LoadingRows } from "@/components/Table/GenericTable";
import { ChainContext } from "@/components/Overlays/ChainProvider";
import { veSHELL } from "@/placeholders/arbitrumTokens";

export interface TokenProps {
  name: string;
  subname: string;
  visible: boolean;
  color: string;
  balance: {
    balance: any;
    decimals: number;
  };
  value: any;
  rewardAPY: any;
  totalPoints: any;
  wallet: any;
  type: string;
}

export const PointsTable = (props: any) => {
  const { tokens, walletAddress, pointsLoading } = props;
  const [selectedFilter, setSelectedFilter] = useState<CollectionFilter>({order: null, label: "All"});
  const tokenNames = Object.keys(tokens).filter(
    (token) => token !== "compoundedPoints"
  );
  
  const { isConnected } = useAccount();
  const { data: signer } = useSigner();
  const provider = useProvider();

  const { tokenMap, shellTokens, nftCollections } = useContext(ChainContext)

  const columns = useMemo(
    () => [
      {
        Header: "Token",
        accessor: "token",
        Filter: (
          <FilterCollectionsDropdown
            onChange={(filter) => setSelectedFilter(filter)}
            list={[
              {
                order: null,
                label: "All",
              },
              {
                order: "asc",
                label: "LP Tokens",
              }
            ]}
          />
        ),
        Cell: ({ row }: any) => <PointsTokenPreviewCell row={row} />,
      },
      {
        Header: "Balance",
        accessor: "balance.balance",
        Cell: ({ value, row }: any) => <BalanceCell value={value} row={row} />,
      },
      {
        Header: "Value (USD)",
        accessor: "value",
        Cell: ({ value, row }: any) => <ValueCell value={value} row={row} />,
      },
      {
        Header: "Reward APY",
        accessor: "rewardAPY",
        Cell: MultiplierCell,
      },
      {
        Header: "Total Reward",
        accessor: "totalPoints",
        Cell: TotalRewardsCell,
      },
      {
        id: "expander",
        accessor: "token",
        Cell: ({ row }: any) => <ExpandButton row={row} />,
      },
    ],
    []
  );

  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const sortTokens = (tokenData : any[]) => {
    if(tokenData.length == 0) return []

    const nftAddresses = new Set(nftCollections.map((collection) => collection.address))

    const wrappedCollections = nftCollections.filter((collection) => collection.wrapped)
    const fungibleTokens = Object.values(tokenMap).filter((token : any) => nftAddresses.has(token.address) && !isNFTCollection(token))

    const fungibleTokenIDs = new Set(fungibleTokens.map((token) => token.symbol))
    const lpTokens = shellTokens.filter((shellToken) => fungibleTokenIDs.has(shellToken.tokens[0]) || fungibleTokenIDs.has(shellToken.tokens[1]))

    const nftOrder = lpTokens.map((lpToken, index) => {
        if(wrappedCollections[index]){
            if(wrappedCollections[index].is1155){
                const collectionID = wrappedCollections[index].symbol.substring(2)
                const tokenIDs = [lpToken.name]
                for(let j = lpTokens.length - 1; j > index; j--){
                    const childTokens = lpTokens[j].tokens
                    if(childTokens[0].includes(`fr${collectionID}`) || childTokens[1].includes(`fr${collectionID}`)){
                        tokenIDs.push(lpTokens[j].name)
                        lpTokens.splice(j, 1)
                    }
                }
                return tokenIDs.concat(wrappedCollections[index].symbol)
            } else {
                return [lpToken.name, fungibleTokens[index].symbol, wrappedCollections[index].symbol];
            }
        } else {
            return [lpToken.name, fungibleTokens[index].symbol]
        }
    }).sort((a, b) => {
        if(a[0] == 'Smolpool'){
            return 1
        } else {
            return -1
        }
    }).flat();

    const tokenIndexes : {[id: string] : number} = {}
    tokenData.forEach((token, index) => tokenIndexes[token.name] = index)

    const sortedTokens = nftOrder.map((tokenID) => tokenData[tokenIndexes[tokenID]]).filter((data) => data != undefined)

    tokenData.forEach((token) => !(new Set(nftOrder).has(token.name)) && sortedTokens.push(token))

    // Update to move the element with name 'veSHELL' & 'SHELL' to the top
    const veSHELLIndex = sortedTokens.findIndex((token) => token.name === 'veSHELL');
    const SHELLIndex = sortedTokens.findIndex(token => token.name === 'SHELL');
    if (SHELLIndex !== -1 && veSHELLIndex !== -1) {
      const SHELLToken = sortedTokens.splice(SHELLIndex, 1)[0];
      const adjustedVeSHELLIndex = veSHELLIndex > SHELLIndex ? veSHELLIndex - 1 : veSHELLIndex;
      const veSHELLToken = sortedTokens.splice(adjustedVeSHELLIndex, 1)[0];
      sortedTokens.unshift(veSHELLToken, SHELLToken);
    }

    return sortedTokens
  }

  const allTokens = sortTokens(tokenNames.map((token) => {
    let tokenData = tokens[token]
    let tokenObject = token == 'veSHELL' ? veSHELL : tokenMap[token]

    const roundLowBalance = (balance : number) => {
        if(balance > 0 && balance < 1e-6){
            return  1e-6
        } else {
            return balance
        }
    }

    return {
      name: token,
      subname: isShellToken(tokenObject) ? reduceString(tokenObject.oceanID, 6, 4) : tokenObject?.name,
      visible: true,
      color: tokenColors[token],
      balance: {
        balance: roundLowBalance(tokenData.balance ?? 0),
        decimals: maxTokenDecimals(token)
      },
      value: tokenData.value ?? 0,
      rewardAPY: tokenData.rewardAPY,
      totalPoints: tokenData.totalPoints,
      wallet: signer ?? provider, 
      type: isNFTCollection(tokenObject) ? 'NFT' : isShellToken(tokenObject) ? 'LP Token' : "Token"
    }
  }))

  const filteredTokens : any = {
    All: allTokens,
    'LP Tokens': allTokens.filter((token) => token.type == 'LP Token'),
    NFTs: allTokens.filter((token) => token.type == 'NFT'),
    //tokens: allTokens,
  };

  const data = useMemo(() => {
    return filteredTokens[selectedFilter.label] || [];
  }, [filteredTokens, selectedFilter.label, isConnected]);

  const renderRowSubComponent = useCallback(
    ({ row }: { row: any }) => {
      let subComponent = null;
  
      if (row.original.type === 'NFT') {
        subComponent = <NFTSubRow row={row} walletAddress={walletAddress}/>;
      } else if (row.original.type === 'Token') {
        subComponent = <TokenSubRow row={row} />;
      } else {
        subComponent = <PoolSubRow row={row} />;
      }
  
      return subComponent;
    },
    []
  );

  return (
    <>
      <PointsTableWrapper>
        {
          isTablet ? (
            <TalesWrapper>
              <FilterPointsWrapper>
                <FilterCollectionsDropdown
                  onChange={(filter) => setSelectedFilter(filter)}
                  list={[
                    {
                      order: null,
                      label: "All",
                    },
                    {
                      order: "asc",
                      label: "LP Tokens",
                    },
                    {
                      order: "asc",
                      label: "NFTs",
                    },
                  ]}
                />
                <PointsText>Total Reward</PointsText>
              </FilterPointsWrapper>
              {pointsLoading ? (
                 <LoadingRows isLoading={true} rows={8} />
                ) : (
                  data.map((token : any, index : number) => (
                    <PointsTale
                      token={token}
                      key={`${token?.name}_${token.subname}_${index}`}
                      openDisabled={false}
                    />
                  ))
              )}  
            </TalesWrapper>
          ) : (
            <PointsSortableTable
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              pointsLoading={pointsLoading}
            />
          )
        }
      </PointsTableWrapper>
    </>
  );
};

const PointsTableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 40px;

  ${Media.smallDesktop} {
    margin-top: 24px;
  }
`;

const TalesWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

const FilterPointsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const PointsText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  margin-right: 44px;
`;

import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { maxTokenDecimals } from "../../utils/tokens";
import { Media } from "@/styles";
import { formatDisplay, timestampToDate } from "@/utils/formatDisplay";
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const Statistics = ({token, lbpData, data1155}: any) => {

  const { tokenMap } = useContext(ChainContext)

  const displayIcon = data1155 ? data1155.item.icon : token.icon;

  const formatTimestamp = (timestamp : number) => {
    return timestampToDate(timestamp.toString()).split(',').map((part, index) => (
        <span key={index}>
            {part}
            {index < 1 && <br />} {/* Add <br> after the first part */}
        </span>
    ))
  }
  return (
    <StyledStatistics>
      <StatisticsContainer>
        <Statistic>
          <Key>Start date</Key>
          <Value>
           {formatTimestamp(lbpData.startTime)}
          </Value>
        </Statistic>
        <Statistic>
          <Key>End date</Key>
          <Value>
            {formatTimestamp(lbpData.endTime)}
          </Value>
        </Statistic>
        <Statistic>
          <Key>
            Start Balances
          </Key>
          <Value>
            <FlexContainer bullet={true}>
              {formatDisplay(lbpData.startBalances[0], maxTokenDecimals(token.symbol))} <img src={displayIcon} />
            </FlexContainer>
            <FlexContainer bullet={true}>
              {formatDisplay(lbpData.startBalances[1], maxTokenDecimals(token.pairToken))} <img src={tokenMap[token.pairToken].icon} />
            </FlexContainer>
          </Value>
        </Statistic>
        <Statistic>
          <Key>
            {token.status == 'Ended' ? 'Ending' : 'Current'} Balances
          </Key>
          <Value>
            <FlexContainer bullet={true}>
              {formatDisplay(lbpData.currentBalances[0], maxTokenDecimals(token.symbol))} <img src={displayIcon} />
            </FlexContainer>
            <FlexContainer bullet={true}>
              {formatDisplay(lbpData.currentBalances[1], maxTokenDecimals(token.pairToken))} <img src={tokenMap[token.pairToken].icon} />
            </FlexContainer>
          </Value>
        </Statistic>
        <Statistic>
          <Key>
            Tokens Released
          </Key>
          <Value>
            <FlexContainer bullet={true}>
              {formatDisplay((lbpData.startBalances[0] - lbpData.currentBalances[0]).toString(), maxTokenDecimals(token.symbol))} <img src={displayIcon} />
            </FlexContainer>
          </Value>
        </Statistic>
        <Statistic>
          <Key>
            Tokens Accrued
          </Key>
          <Value>
            <FlexContainer bullet={true}>
              {formatDisplay((lbpData.currentBalances[1] - lbpData.startBalances[1]).toString(), maxTokenDecimals(token.pairToken))} <img src={tokenMap[token.pairToken].icon} />
            </FlexContainer>
          </Value>
        </Statistic>
        {/* <Statistic>
          <Key>Start Weights</Key>
          <Value>
            <FlexContainer bullet={true}>
              95% <img src={token.icon} />
            </FlexContainer>
            <FlexContainer bullet={true}>
              +5% <img src={tokenMap[token.pairToken].icon} />
            </FlexContainer>
          </Value>
        </Statistic>
        <Statistic>
          <Key>End Weights</Key>
          <Value>
            <FlexContainer bullet={true}>
              50% <img src={token.icon} />
            </FlexContainer>
            <FlexContainer bullet={true}>
              50% <img src={tokenMap[token.pairToken].icon} />
            </FlexContainer>
          </Value>
        </Statistic> */}
      </StatisticsContainer>
    </StyledStatistics>
  );
};

const StyledStatistics = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  gap: 10px;
  min-width: 295px;
  // background: #151530;
  border: 1px solid #1e2239;
  border-radius: 16px;
  ${Media.tablet} {
    padding-inline: 14px;
  }
`;

const StatisticsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
`;

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Key = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #7d7d97;
`;

const Value = styled.div<{ column?: boolean; gap?: string }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  align-items: ${({ column }) => (column ? "end" : "center")};
  gap: ${({ gap }) => gap || "8px"};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  img {
    width: 24px;
    height: 24px;
  }

  ${Media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
  }
`;

const Title = styled.div`
  padding-inline: 20px;
  margin-inline: -20px;
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
  border-bottom: 1px solid #1e2239;
`;

const FlexContainer = styled.div<{ bullet?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ bullet }) =>
    bullet &&
    css`
      width: fit-content;
      padding: 8px 10px;
      border-radius: 12px;
      border: 1px solid var(--dark-3, #1e2239);
      background: var(--dark-4, #151530);
    `}

    > img {
        border-radius: 4px;
    }
`;

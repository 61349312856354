import { VestingStream } from "@/pages/Rewards/hooks/useVestingHandler";
import React from "react";
import styled from "styled-components";
import externalLinkIcon from "@/assets/icons/external-link.svg";
import { ButtonPrimary, ButtonSecondary } from "@/components/Buttons/Button";
import { breakpoints, Media } from '@/styles';
import { useWidthBreakpoint } from '@/hooks';
import { formatDisplay } from "@/utils/formatDisplay";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";

export const VestingStreamItem = ({ id, address, imgSrc, claimable, vesting }: VestingStream) => {

    const { claimStream, handleOpenClaimStakeModal, withdrawableAmount } = useRewardsContext()

    const canClaim = parseFloat(claimable) > 0
    const canStake = canClaim && parseFloat(withdrawableAmount) == 0
    const isTablet = useWidthBreakpoint(breakpoints.tablet)

    return (
      <VestingStreamContainer>
        <LeftSideContainer>
          <ImageAndNameWrapper>
            <NFTimg src={imgSrc} alt="nft image" />
            <NameWrapper>
              <Name>Shellstream #{id}</Name>
              <AddressWrapper>
                <Address>{address}</Address>
                <a>
                  <LinkImg
                    src={externalLinkIcon}
                    alt="external link icon image"
                  />
                </a>
              </AddressWrapper>
            </NameWrapper>
          </ImageAndNameWrapper>
          {isTablet ? 
            <BalanceRowWrapper>
              <MobileBalanceContainer>
                <MobileLabel>Vesting</MobileLabel>
                <Balance>{formatDisplay(vesting, 2) + " SHELL"}</Balance>
              </MobileBalanceContainer>
              <MobileBalanceContainer>
                <MobileLabel>Claimable</MobileLabel>
                <Balance>{formatDisplay(claimable, 2) + " SHELL"}</Balance>
              </MobileBalanceContainer>
            </BalanceRowWrapper> :
            <>
              <MobileBalanceContainer>
                <MobileLabel>Vesting</MobileLabel>
                <Balance>{formatDisplay(vesting, 2) + " SHELL"}</Balance>
              </MobileBalanceContainer>
              <MobileBalanceContainer>
                <MobileLabel>Claimable</MobileLabel>
                <Balance>{formatDisplay(claimable, 2) + " SHELL"}</Balance>
              </MobileBalanceContainer>
            </>
          }
        </LeftSideContainer>
        <ActionContainer>
          <CustomPrimaryButton
            disabled={!canStake}
            onClick={() => handleOpenClaimStakeModal(id, parseFloat(claimable))}
          >
            Stake
          </CustomPrimaryButton>
          <CustomSecondaryButton
            disabled={!canClaim}
            onClick={() => claimStream(id, parseFloat(claimable))}
          >
            Claim
          </CustomSecondaryButton>
        </ActionContainer>
      </VestingStreamContainer>
    );
};

const VestingStreamContainer = styled.li`
padding: 20px;
border-radius: 16px;
border: 1px solid var(--dark-3, #1E2239);
background: var(--dark-4, #151530);
display: flex;
justify-content: flex-start;
gap: 64px;
align-items: center;
width: 100%;

${Media.tablet} {
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
`;

const LeftSideContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

${Media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
`;

const BalanceRowWrapper = styled.div`

${Media.tablet} {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  }
`;

const InfoContainer = styled.div`
display: flex;
justify-content: space-between;
`

const MobileBalanceContainer = styled.div`
display: flex;
flex-direction: column;
gap: 6px;
justify-content: flex-start;
align-items: flex-start;

${Media.tablet} {
    width: 100%;
  }
`;

const MobileLabel = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
display: none;

${Media.tablet} {
    display: block;
  }
`;

const BalanceWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 4px;
`;

const ActionContainer = styled.div`
width: auto;
display: flex;
justify-content: flex-end;
align-items: center;
gap: 16px;

${Media.tablet} {
    width: 100%;
  }

  ${Media.smallMobile} {
    flex-direction: row;
  }

`;

const Balance = styled.p`
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;



const ImageAndNameWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 16px;
`;

const NFTimg = styled.img`
width: 64px;
height: 64px;
border-radius: 8px;
`;

const NameWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 6px;
justify-content: flex-start;
align-items: flex-start;
`;

const Name = styled.p`
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const AddressWrapper = styled.div`
display: flex;
justify-content: flex-start;
gap: 2px;
align-items: center;
`;

const LinkImg = styled.img`
width: 16px;
height: 16px;
`;

const Address = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const CustomPrimaryButton = styled(ButtonPrimary)`
padding: 17px 24px;
height: unset;
width: auto;
border-width: 2px;
border-radius: 12px;

${Media.tablet} {
    width: 100%;
  }

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border: 2px solid #1E2239;
background: var(--dark-4, #151530);
`}
`;

const CustomSecondaryButton = styled(ButtonSecondary)`
padding: 17px 24px;
height: unset;
width: auto;
border-width: 2px;
border-radius: 12px;

${Media.tablet} {
    width: 100%;
  }

${({ disabled }) => disabled && `
color: var(--grey-4, #464659);
pointer-events: none;
border: 2px solid #1E2239;
background: var(--dark-4, #151530);
`}
`;
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TransferToken } from "./TransferToken";
import { useAppSelector } from "../../../../../store/hooks";
import { useAccount } from 'wagmi';
import { formatDisplay } from '../../../../../utils/formatDisplay';
import { defaultChain } from "@/placeholders/chains";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TableSubRowProps {
  row: any;
}

export const TokenSubRow = React.memo(({ row }: TableSubRowProps) => {
  const { original } = row;
  const userBalances = useAppSelector((state) => state.balances.balances[defaultChain.name]);
  const userCurrentPoints = useAppSelector(state => state.points.currentSeason)
  const { address: walletAddress } = useAccount();

  const { tokenMap, poolQuery } = useContext(ChainContext)

  const token: any = tokenMap[original.name];
  const [userBalance, setUserBalance] = useState(0);

  useEffect(() => {
    if(original.name === "SHELL" && walletAddress) {
      const shellBalance = userCurrentPoints[walletAddress].shellBalance;
      setUserBalance(shellBalance);
    } else {
      const userBalance = userBalances[token.oceanID];
      const userBalanceFormatted = formatDisplay(userBalance, original.balance.decimals);
      setUserBalance(userBalanceFormatted.includes("<") ? 0 : parseFloat(userBalanceFormatted)); 
    }   
  }, [userBalances[token.oceanID], token.oceanID]);
  
  return (
    <Footer>
      <FooterRow>
        <ButtonRow>
          <BuyButton to="/trade" state={poolQuery.generateBuySellTokens(token, true)} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) } > Buy </BuyButton>
          <SellButton to={"/trade"} state={poolQuery.generateBuySellTokens(token, false)} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) } > Sell </SellButton>
          <TransferToken token={token} balance={userBalance} wallet={original.wallet} />
        </ButtonRow>
      </FooterRow>
    </Footer>
  );
});

const Footer = styled.div`
  padding: 16px 20px;
`;

const FooterRow = styled.div`
  display: flex;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 8px;
`;

const TradeButton = styled(Link)`
  width: 120px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  border-radius: 16px;
  text-align: center;
`;

const BuyButton = styled(TradeButton)`
  color: #0a0e27;
  background: #37dcf2;
  //margin-left: 12px;
`;

const SellButton = styled(TradeButton)`
  color: #ffffff;
  border: 2px solid #37dcf2;
`;

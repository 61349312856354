import { LockPeriodType } from "@/pages/Rewards/hooks/useStakingHandler";
import { Media } from "@/styles";
import React from "react";
import styled from "styled-components";

type LockPeriodBoxProps = {
    lockPeriod: LockPeriodType;
    isDefault?: boolean;
    onSelect: () => void;
    selected: boolean;
    disabled?: boolean;
}

export const LockPeriodBox = ({lockPeriod, isDefault, selected, disabled, onSelect} :LockPeriodBoxProps) => {

    const {label, APY} = lockPeriod;
    return (
        <LockPeriodBoxWrapper selected={selected} onClick={onSelect} disabled={disabled}>
            <LockPeriodDurationWrapper>
                <LockPeriodDuration disabled={disabled}>{label}</LockPeriodDuration>
                {isDefault && <DefaulMark>default</DefaulMark>}
            </LockPeriodDurationWrapper>
            <LockPeriodAPY disabled={disabled}>{APY}% APY</LockPeriodAPY>
        </LockPeriodBoxWrapper>
    )
};

const LockPeriodBoxWrapper = styled.div<{selected: boolean, disabled?: boolean}>`
padding: 20px 16px;
border-radius: 12px;
outline: ${({selected, disabled}) => disabled ? "1px solid #171B33" : selected ? "2px solid var(--gradient-2, #37DCF2)" : "1px solid var(--dark-3, #1E2239)"};
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
cursor: pointer;
pointer-events: ${({disabled}) => disabled ? "none" : "all"};
transition: all .35s ease-in-out;

&:hover {
    outline: ${({selected, disabled}) => selected && !disabled ? "2px solid var(--gradient-2, #37DCF2)" : "2px solid var(--dark-3, #1E2239)"};
}

${Media.tablet} {
    padding: 16px 12px;
    width: calc(50% - 4px);
}
`;

const LockPeriodDurationWrapper = styled.div`
display: flex;
justify-content: flex-start;
gap: 4px;
align-items: center;
`;

const LockPeriodDuration = styled.p<{disabled?: boolean}>`
color: ${({disabled}) => disabled ? "#464659" : "#FFF"};
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;

${Media.tablet} {
    font-size: 14px;
}
`;

const LockPeriodAPY = styled.p<{disabled?: boolean}>`
color: ${({disabled}) => disabled ? "#464659" : "#7D7D97"};
text-align: left;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;

${Media.tablet} {
    font-size: 12px;
}
`;

const DefaulMark = styled.p`
color: var(--dark-1, #0A0E27);
text-align: center;
font-family: Inter;
font-size: 9px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
padding: 3px 5px;
border-radius: 100px;
background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
`;
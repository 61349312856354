import React, { useContext } from "react";
import * as S from "./AirDrop.styled";
import shellIcon from "@/assets/coins/SHELL.svg";
import crabIcon from "@/assets/seasons/CRAB.svg";
import leftArrow from "@/assets/icons/arrow-left.svg";
import { useRewardsContext } from "../../RewardsContext";
import { CustomModal } from "@/components/CustomModal/CustomModal";
import { ClaimAirDropModalBody } from "../modals/ClaimAirDropModalBody";
import { ClaimAirDropConfirmation } from "../modals/ClaimAirDropConfirmation";
import { ClaimCrab } from "./components/ClaimCrab";
import { AirdropTable } from "./components/AirdropTable";
import GeoRestrictedOverlay from "@/components/Overlays/GeoRestrictedOverlay";
import { formatDisplay } from "@/utils/formatDisplay";
import { useAccount } from "wagmi";
import { useWidthBreakpoint } from "@/hooks";
import { breakpoints } from "@/styles";
import { ClaimOther } from "./components/ClaimOther";
import { GeoContext } from "@/components/Overlays/GeoProvider";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { LoadingRows } from "@/components/Table/GenericTable";

export const AirDrop = () => {
  const {
    userSeasons,
    airDropBalance,
    unclaimedCrab,
    handleCloseAirDropClaimModal,
    isAirDropClaimModalOpen,
    airDropClaim,
    isAirDropConfirmationModalOpen,
    handleCloseAirDropClaimConfirmationModal,
    handleClaimCrab,
    airdropLoading,
    otherAirdrops,
  } = useRewardsContext();
  const { isGeoLoading, isUS } = useContext(GeoContext);
  const { isConnected } = useAccount();

  const isMobile = useWidthBreakpoint(breakpoints.mobile);

  return (
    <>
      <S.AirDropContainer isConnected={isConnected}>
        <GeoRestrictedOverlay isUS={isUS}>
          <S.ShellAirDropContainer>
            <S.CongratsTitle>
              {userSeasons.some((season) => season.status) ? (
                <>
                  <span>Congrats!</span> You're eligible for{" "}
                  {isMobile ? "the SHELL airdrop" : "the SHELL airdrop"}
                </>
              ) : (
                <>You're not eligible for the SHELL airdrop</> // TO DO: Add a call to action to stake SHELL
              )}
            </S.CongratsTitle>
            <S.AirdropSeasonsSummaryWrapper>
              {isConnected && <ClaimOther />}
              {isConnected && <ClaimCrab />}
              <S.AirdropClaimWrapper>
                <S.AirdropClaimInner>
                  <S.AirdropCrabToShellWrapper>
                    <S.AirdropCrabIcon src={crabIcon} alt="CRAB" />
                    <S.AirdropRightArrowIcon src={leftArrow} alt="->" />
                    <S.AirdropShellIcon src={shellIcon} alt="SHELL" />
                  </S.AirdropCrabToShellWrapper>

                  <S.AirdropColumn>
                    {[
                      {
                        title: "Unclaimed CRAB",
                        value: unclaimedCrab,
                        unit: "CRAB",
                      },
                      {
                        title: "Claimable airdrop",
                        value: airDropBalance,
                        unit: "SHELL",
                      },
                    ].map((item, index) => (
                      <S.AirdropBalanceItem key={index}>
                        <S.AirdropBalanceLabel>
                          {item.title}
                        </S.AirdropBalanceLabel>
                        <S.AirdropCoinValueWrapper>
                          <S.Coin
                            src={item.unit === "CRAB" ? crabIcon : shellIcon}
                            alt={item.unit.toLowerCase()}
                          />
                          <S.AirdropValue>
                            {isGeoLoading || airdropLoading ? (
                              <SkeletonBox
                                isLoading={true}
                                width="72px"
                                height="24px"
                                borderRadius="6px"
                              />
                            ) : (
                              <>{formatDisplay(item.value.toString(), 2)} <span>{item.unit}</span> </>
                            )}
                          </S.AirdropValue>
                        </S.AirdropCoinValueWrapper>
                      </S.AirdropBalanceItem>
                    ))}
                    <S.ButtonsContainer>
                      {unclaimedCrab > 0 && (
                        <S.CustomSecondaryBtn onClick={handleClaimCrab}>
                          Claim CRAB
                        </S.CustomSecondaryBtn>
                      )}
                      <S.CustomPrimaryBtn
                        onClick={airDropClaim}
                        disabled={airDropBalance == 0}
                      >
                        Claim SHELL
                      </S.CustomPrimaryBtn>
                    </S.ButtonsContainer>
                    <S.SeasonsDescription>
                      The amount of SHELL you can claim is based on your current
                      CRAB balance.
                    </S.SeasonsDescription>
                  </S.AirdropColumn>
                </S.AirdropClaimInner>
              </S.AirdropClaimWrapper>
            </S.AirdropSeasonsSummaryWrapper>
          </S.ShellAirDropContainer>
        </GeoRestrictedOverlay>
        <S.OtherAirDropsContainer>
          <S.Title>Other airdrops</S.Title>
            <AirdropTable tokens={otherAirdrops} />
        </S.OtherAirDropsContainer>
      </S.AirDropContainer>

      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isAirDropClaimModalOpen}
        onClose={() => handleCloseAirDropClaimModal()}
        withBackdropBlur
        withOutsideClick
        width="654px"
      >
        <ClaimAirDropModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isAirDropConfirmationModalOpen}
        onClose={() => handleCloseAirDropClaimConfirmationModal()}
        withBackdropBlur
        withOutsideClick
      >
        <ClaimAirDropConfirmation />
      </CustomModal>
    </>
  );
};

import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './GlobalStyle';
import { WagmiProvider } from './WagmiProvider';
import { GeoProvider } from '@/components/Overlays/GeoProvider';
import Hotjar from '@hotjar/browser';
import { ChainProvider } from '@/components/Overlays/ChainProvider';

interface Props {
  children: ReactNode;
}

export function Providers(props: Props) {

  // Hotjar tracking setup
  const siteId = 4991746;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  return (
    <WagmiProvider>
      <GeoProvider>
        <ChainProvider>
          <BrowserRouter>
            <GlobalStyle />
            {props.children}
          </BrowserRouter>
        </ChainProvider>
      </GeoProvider>
    </WagmiProvider>
  );
}
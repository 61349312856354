import { allChains } from '@/placeholders/chains'
import { createSlice } from '@reduxjs/toolkit'
interface BalancesState {
    balances: Record<string, Record<string, string>>
    nftBalances: Record<string, any[]>
}

const initialBalances: Record<string, Record<string, string>> = {}

Object.keys(allChains).forEach((chain) => {
    initialBalances[chain] = {}
})

const initialState : BalancesState = {balances: initialBalances, nftBalances: {}}

const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
      addBalance: (state, action) => {
          state.balances[action.payload.chain][action.payload.address] = action.payload.amount
      },
      addNFTBalance: (state, action) => {
        let newNFTBalances = {...state.nftBalances}
        newNFTBalances[action.payload.collection] = action.payload.items
        return {
            ...state,
            nftBalances: newNFTBalances
        }
      },
      clearBalances: () => {
          return initialState
      }
  }
})

export const { addBalance, addNFTBalance, clearBalances } = balancesSlice.actions

export default balancesSlice.reducer
import React from 'react'
import styled from 'styled-components'
import { PointsIcon } from '../../../../../../components/Icons/PointsIcon'
import { formatDisplayShorthand } from '../../../../../../utils/formatDisplay'

export const TotalPointsCell = ({ value }: any) => 
    <CellRow>
        <span>{formatDisplayShorthand(value)}</span>
        <PointsIcon />
    </CellRow>

const CellRow = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 12px;

  & > svg {
    width: 16px;
    height: 16px;
    color: #00BDFF;
    margin-left: 8px;
  }
`

import React from "react";
import { useRewardsContext } from "../../RewardsContext";
import * as S from "./ConfirmationModals.styled";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import arrowDown from "@/assets/icons/arrow-down-double-fill.svg";
import shellIcon from '@/assets/coins/SHELL.svg'
import shellWhiteCoin from "@/assets/icons/shell-white-coin.svg";
import infoIcon from "@/assets/icons/info-icon.svg";
import { formatDisplay } from "@/utils/formatDisplay";

const InputCustomLabelShellCoin = () => (
    <S.CustomInputLabelContainer style={{ marginRight: "45px" }}>
        <S.CoinIcon src={shellIcon} alt="shell coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>SHELL</S.InputTokenName>
                <S.InputTokenSymbol>Shell</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

const InputCustomLabelVotinPower = () => (
    <S.CustomInputLabelContainer>
        <S.CoinIcon src={shellWhiteCoin} alt="shell white coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>veSHELL</S.InputTokenName>
                <S.InputTokenSymbol>Voting Power</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

export const UnstakeConfirmationModalBody = () => {
    const {
        withdrawableAmount,
        isUnstakeConfirmed,
        handleRedirectToStaking,
        handleCloseUnstakeConfirmationModal,
        stakeTxSubmitted
    } = useRewardsContext();

    return (
        <S.ConfirmationBodyContainer hasMask={isUnstakeConfirmed}>
            {isUnstakeConfirmed ? (
                <>
                    <S.SuccessIcon src={successIcon} alt="success icon" />
                    <S.SuccessTextWrapper>
                        <S.SuccessTitle>Unstake successful!</S.SuccessTitle>
                        <S.SuccessDescription>
                            Your SHELL tokens are back in your wallet.
                        </S.SuccessDescription>
                    </S.SuccessTextWrapper>
                    <S.InputsWrapper>
                        <Input label="You received" disabled value={formatDisplay(withdrawableAmount)} additionalLabel={<InputCustomLabelShellCoin />} awardedColor />
                    </S.InputsWrapper>
                    <S.ButtonsWrapper style={{marginTop: "16px"}}>
                        <S.CustomSecondaryBtn onClick={handleCloseUnstakeConfirmationModal}>Close</S.CustomSecondaryBtn>
                    </S.ButtonsWrapper>
                </>
            ) : (
                <>
                    <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                    <S.LoaderLabelContainer>
                        <S.LoaderLabel>
                            Unstaking your SHELL...
                        </S.LoaderLabel>
                        <S.LoaderSublabel>
                            You're unstaking {formatDisplay(withdrawableAmount)} SHELL
                        </S.LoaderSublabel>
                    </S.LoaderLabelContainer>
                    <S.InputsWrapper>
                        <Input label="You receive" disabled value={formatDisplay(withdrawableAmount)} additionalLabel={<InputCustomLabelShellCoin />} awardedColor />
                    </S.InputsWrapper>
                    {!stakeTxSubmitted && 
                    <S.InfoLabelContainer>
                        <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                        <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                    </S.InfoLabelContainer>
                    }
                </>
            )}
        </S.ConfirmationBodyContainer>
    )
}
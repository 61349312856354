import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

const pathHistory: string[] = [];

export const useNavigationHelpers = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  useEffect(() => {
    if (pathHistory[pathHistory.length - 1] !== currentLocation.pathname) {
      pathHistory.push(currentLocation.pathname);
    }
  }, [currentLocation, navigate]);

  const handleGoBack = (defaultPath = "/explore") => {
    const historyLength = pathHistory.length;

    if (
      historyLength >= 2 &&
      pathHistory[historyLength - 1] === pathHistory[historyLength - 2]
    ) {
      pathHistory.pop();
      navigate(defaultPath);
    } else if (window.history.length > 1) {
      pathHistory.pop();
      navigate(-1);
    } else {
      navigate(defaultPath);
    }
  };

  const clearPathHistory = () => {
    pathHistory.length = 0;
  };

  return { handleGoBack, clearPathHistory };
};

import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import { useToggler } from "../../../../../hooks/useToggler";
import {
  Dropdown,
  DropdownButton,
  DropdownContent,
  DropdownToggle,
} from "../../../../../components/Dropdown";
import {
  DropdownIconDown,
  DropdownIconUp,
} from "../../../../../components/Icons/DropdownIcon";
import checked from "@/assets/icons/dropdown-checked.svg";
import { Media } from "../../../../../styles";

export interface SeasonFilter {
  label: string;
  value?: any;
}

interface CollectionFilterDropdownProps {
  onChange: (value: SeasonFilter) => void;
  list: SeasonFilter[];
  className?: string;
  currentFilter: SeasonFilter;
}

export const FilterSeasonDropdown = ({
  onChange,
  list,
  className,
  currentFilter,
}: CollectionFilterDropdownProps) => {
  const { visible, toggle } = useToggler();
  const ref = useRef(null);
  useClickOutside(ref, () => toggle(false));

  const [selectedFilter, setSelectedFilter] = useState(currentFilter);

  const handleFilterChange = (filter: SeasonFilter) => {
    setSelectedFilter(filter);
    onChange(filter);
  };

  return (
    <StyledDropdown ref={ref} className={visible ? "expanded" : ""}>
      <StyledDropdownToggle onClick={() => toggle()} className={className}>
        {selectedFilter.label}
        {visible ? <DropdownIconUp /> : <DropdownIconDown />}
      </StyledDropdownToggle>
      {visible && (
        <StyledDropdownContent>
          {list.map((item) => (
            <li key={item.label}>
              <StyledDropdownButton
                onClick={() => {
                  handleFilterChange(item);
                  toggle(false);
                }}
                className={item.label === selectedFilter.label ? "active" : ""}
              >
                {item.label}
              </StyledDropdownButton>
            </li>
          ))}
        </StyledDropdownContent>
      )}
    </StyledDropdown>
  );
};

const StyledDropdownToggle = styled(DropdownToggle)`
  border: none;
  border-radius: 16px;
  font-size: 14px;
  line-height: 17px;

  border: 1px solid #292941;
  border-radius: 10px;
  height: 32px;
  gap: 16px;
  padding: 8px 12px;

  & svg {
    position: relative !important;
    right: 0 !important;
  }

  ${Media.tablet} {
    //width: 100px;
    width: auto;
    height: 29px;
    font-size: 11px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 138px;
  width: max-content;

  ${Media.tablet} {
    min-width: unset;
    //width: 100px;
    width: -webkit-fill-available;
    height: 28px;
  }
  ${Media.mobile} {
    margin-right: 0px;
  }
  &.expanded ${StyledDropdownToggle} {
    border-color: #292941;
  }
`;

const StyledDropdownContent = styled(DropdownContent)`
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 8px 24px rgba(11, 13, 34, 0.6);
  z-index: 99999;
`;

const StyledDropdownButton = styled(DropdownButton)`
  position: relative;
  padding: 9px 6px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  &:after {
    display: none;
    position: absolute;
    content: url(${checked});
    right: 0px;
    top: 10px;
  }

  &:hover {
    background-color: #1e2239;
    borde-radius: 6px;
  }

  &.active:after {
    display: block;
  }

  ${Media.tablet} {
    font-size: 11px;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { CustomModal } from "../CustomModal/CustomModal";
import { Chain, defaultChain } from "@/placeholders/chains";
import arbitrumChain from '@/assets/coins/arbitrum.svg';
import arrowDown from '@/assets/icons/arrow-down.svg';
import { Media } from "@/styles";

interface ChainSelectProps {
    onSelect: (chain: string) => void;
    selectedChain: string;
    additionalOnClickAction?: () => void;
    chainOptions: Chain[]
}

export const ChainSelect = ({ onSelect, selectedChain, additionalOnClickAction, chainOptions  }: ChainSelectProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        additionalOnClickAction && additionalOnClickAction();
        setIsModalOpen(true);
    };

    const handleSelectChain = (chain: string) => {
        onSelect(chain);
        handleCloseModal();
    };

    return (
        <>
            <ChainSelectWrapper onClick={chainOptions.length > 1 ? handleOpenModal : () => {}} disabled={chainOptions.length <= 1}>
                <Icon src={(chainOptions.find((chain) => chain.name === selectedChain) ?? defaultChain).icon} alt={`${selectedChain}-icon`} />
                <ChainNameLabel>{selectedChain.split(' ')[0]}</ChainNameLabel>
                {chainOptions.length > 1 && <Arrow src={arrowDown} alt="arrow-down" />}
            </ChainSelectWrapper>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal} withBackdropBlur withOutsideClick width="470px">
                <BodyContainer>
                    <ModalTitle>
                        Select network
                    </ModalTitle>
                    <ChainList>
                        {/* <ChainItemWrapper selected={selectedChain === ""} onClick={() => handleSelectChain("")}>
                            <ModalItemIcon src={arbitrumChain} alt="arbitrum-icon" />
                            <ModalItemLabel>Arbitrum</ModalItemLabel>
                        </ChainItemWrapper> */}
                        {chainOptions.map((chain) => (
                            <ChainItemWrapper key={chain.name} onClick={() => handleSelectChain(chain.name)}>
                                <ModalItemIcon src={chain.icon} alt={`${name}-icon`} />
                                <ModalItemLabel>{chain.name.split(' ')[0]}</ModalItemLabel>
                            </ChainItemWrapper>
                        ))}
                    </ChainList>
                </BodyContainer>
            </CustomModal>
        </>
    )
};

const ChainSelectWrapper = styled.div<{ disabled: boolean }>`
  padding: 5px;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--grey-5, #292941);
  background: var(--dark-3, #1e2239);
  cursor: pointer;

  &:not(:disabled):hover {
    border-color: rgb(44, 81, 115);
    cursor: pointer;
  }

  &:disabled {
    cursor: none;
  }
`;

const Icon = styled.img`
height: 16px;
width: 16px;
`

const ChainNameLabel = styled.p`
color: var(--White, #FFF);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.14px;
`;

const Arrow = styled.img`
width: 12px;
height: 12px;
`;

const BodyContainer = styled.div<{ hasMask?: boolean }>`
padding: 24px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 24px;
transition: all .5s ease-in-out;

${Media.tablet} {
  padding: 16px 20px 32px;
  gap: 20px;
}
`;

const ModalTitle = styled.h2`
color: var(--White, #FFF);

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;

${Media.mobile} {
  font-size: 18px;
}
`;

const ChainList = styled.ul`
display: flex;
flex-direction: column;
gap: 8px;
width: 100%;
`;

const ChainItemWrapper = styled.li<{selected?: boolean}>`
padding: 20px;
border-radius: 16px;
border: ${({selected}) => selected ? '1px solid var(--gradient-1, #37DCF2)' : '1px solid var(--gradient-2, #171B33)'};
background: var(--dark-2, #171B33);
display: flex;
width: 100%;
justify-content: flex-start;
align-items: center;
gap: 12px;

&:hover {
    border-color: var(--gradient-1, #37DCF2);
    cursor: pointer;
}
`;

const ModalItemIcon = styled.img`
width: 40px;
height: 40px;
`;

const ModalItemLabel = styled.p`
color: var(--White, #FFF);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.18px;
`;


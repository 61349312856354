import React from "react";
import styled from "styled-components";
import { LeaderboardTableRowMobile } from "./LeaderboardTableRowMobile";
import { useLeaderboard } from "@/pages/Explore/ExploreContext";
import { LoadingRows } from "@/components/Table/GenericTable";

interface LeaderboardTableMobileProps {
  data: Array<object>;
  onRowClick?: (address: string) => void;
  isShell: boolean
}

export const LeaderboardTableMobile = ({
  data,
  onRowClick,
  isShell
}: LeaderboardTableMobileProps) => {
  const { leaderboardLoading } = useLeaderboard();

  return (
    <>
      <LeaderboardTableContainerStyled>
        {data.map((el: any) => (
          <LeaderboardTableRowMobile el={el} onRowClick={onRowClick} isShell={isShell}/>
        ))}
      </LeaderboardTableContainerStyled>
      {leaderboardLoading && (
        <LoadingRows isLoading={true} padding="100px 0 22px" rows={3} />
      )}
    </>
  );
};

const LeaderboardTableContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

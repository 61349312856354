import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperClass } from "swiper";
import { ChartButtons } from "./ChartButtons";
import { Media } from "@/styles";

interface IGalleryProps {
  images: string[];
  isActive: string;
  onClickButton: (value: string) => void;
}

export const Gallery = ({ images, isActive, onClickButton }: IGalleryProps) => {
  const [activeImage, setActiveImage] = useState<string>(images[0]);
  const [canSwipe, setCanSwipe] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({
    isBeginning: true,
    isEnd: false,
  });
  const navigationPrevRef = useRef(null);
  const swiperRef = useRef<{ swiper: SwiperClass }>(null);
  const navigationNextRef = useRef(null);

  const nextSlide = () => {
    swiperRef.current?.swiper?.slideNext();
  };

  const prevSlide = () => {
    swiperRef.current?.swiper?.slidePrev();
  };

  return (
    <GalleryContainer>
      <GalleryHeader>
        Collection gallery{" "}
        <ChartButtons isActive={isActive} onClick={onClickButton} />
      </GalleryHeader>
      <GalleryMainImage
        src={`../../assets/lbp/${activeImage}.png`}
      ></GalleryMainImage>
      <SwiperWrapper>
        <div
          className={`button-prev ${canSwipe.isBeginning ? "disabled" : ""}`}
          ref={navigationPrevRef}
          onClick={prevSlide}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33268 8H12.666"
              stroke="white"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301"
              stroke="white"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className={`button-next ${canSwipe.isEnd ? "disabled" : ""}`}
          ref={navigationNextRef}
          onClick={nextSlide}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33268 8H12.666"
              stroke="white"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301"
              stroke="white"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <Swiper
          ref={swiperRef}
          slidesPerView={"auto"}
          slidesPerGroup={1}
          className="swiper-container"
          onUpdate={(swiper) => {
            setCanSwipe({
              ...canSwipe,
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}
          onReachBeginning={() => {
            setCanSwipe({ ...canSwipe, isBeginning: true, isEnd: false });
          }}
          onReachEnd={() => {
            setCanSwipe({ ...canSwipe, isBeginning: false, isEnd: true });
          }}
        >
          {images.map((image) => (
            <SwiperSlide key={image}>
              <ImageSlide
                isActive={image === activeImage}
                src={`../../assets/lbp/${image}.png`}
                onClick={() => setActiveImage(image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
    </GalleryContainer>
  );
};

const GalleryContainer = styled.div`
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--dark-2, #171b33);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;

  .swiper-slide {
    width: 72px;
    height: 72px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  ${Media.tablet} {
    padding-block: 16px;
  }
`;

const GalleryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;

  ${Media.tablet} {
    padding-inline: 16px;
  }
`;

const GalleryMainImage = styled.img`
  width: 100%;
  height: auto;
`;

const ImageSlide = styled.img<{ isActive: boolean }>`
  width: 72px;
  height: 72px;
  border-radius: 8px;
  padding: 4px;
  border: 2px solid #171b33;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    border-color: #2C5173;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #00bdff;
    `}
`;

const SwiperWrapper = styled.div`
  margin-top: 12px;
  padding-inline: 20px;
  position: relative;

  .button-prev,
  .button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    z-index: 999;
    background: #151530;
    border-radius: 50%;
    border: 1px solid #1e2239;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &.disabled {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  .button-prev {
    left: 5px;
    svg {
      transform: rotate(180deg);
    }
  }

  .button-next {
    right: 5px;
  }

  ${Media.tablet} {
    padding-inline: 16px;
  }
`;

import { createSlice } from '@reduxjs/toolkit'
import { allChains } from '@/placeholders/chains'

interface TransactionsState {
    transactions: Record<string, Record<string, any>>,
    transactionCounts: Record<string, Record<string, number>>,
    interactions: Record<string, Record<string, any>>,
}

const initialTransactions: Record<string, Record<string, any>> = {}
const initialTransactionCounts: Record<string, Record<string, number>> = {}
const initialInteractions: Record<string, Record<string, any>> = {}

Object.keys(allChains).forEach((chain) => {
    initialTransactions[chain] = {}
    initialTransactionCounts[chain] = {}
    initialInteractions[chain] = {}
})

const initialState: TransactionsState = {
    transactions: initialTransactions,
    transactionCounts: initialTransactionCounts,
    interactions: initialInteractions
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransaction: (state, action) => {
        const { chain, address, skip, data } = action.payload;
        if (!state.transactions[chain]) {
            state.transactions[chain] = {};
        }
        if (!state.transactions[chain][address]) {
            state.transactions[chain][address] = {};
        }
        state.transactions[chain][address][skip] = data;
    },
    clearUserTransactions: (state, action) => {
        const { chain, user } = action.payload;
        if (state.transactions[chain]) {
            state.transactions[chain][user] = {};
        }
    },
    addTransactionCount: (state, action) => {
        const { chain, address, count } = action.payload;
        if (!state.transactionCounts[chain]) {
            state.transactionCounts[chain] = {};
        }
        state.transactionCounts[chain][address] = count;
    },
    addInteraction: (state, action) => {
        const { chain, hash, data } = action.payload;
        if (!state.interactions[chain]) {
            state.interactions[chain] = {};
        }
        state.interactions[chain][hash] = data;
    },
    clearTransactions: () => {
        return initialState;
    },
  }
})

export const { addTransaction, clearUserTransactions, addTransactionCount, addInteraction, clearTransactions } = transactionsSlice.actions

export default transactionsSlice.reducer
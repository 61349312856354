import React from "react";
import styled from "styled-components";
import CopyLinkIcon from "../Icons/CopyLinkIcon";
import { copyTextToClipboard } from "../../utils/copy";

interface CopyLinkButtonProps {
  text: string;
  className?: string;
  children?: React.ReactNode;
  cIcon?: JSX.Element;
}

export const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({
  text,
  className = "",
  children,
  cIcon,
}) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <StyledButton
      className={className}
      onClick={() => {
        copyTextToClipboard(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }}
    >
      <Text>{children}</Text>
      {copied ? (
        <CopiedContainer>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6663 5L7.49967 14.1667L3.33301 10"
              stroke="url(#paint0_linear_3858_25284)"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3858_25284"
                x1="3.44967"
                y1="5"
                x2="16.7676"
                y2="5.14962"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#37DCF2" />
                <stop offset="1" stopColor="#07C0FB" />
              </linearGradient>
            </defs>
          </svg>
        </CopiedContainer>
      ) : (
        cIcon || <CopyLinkIcon color="white" size={20} />
      )}
    </StyledButton>
  );
};

export default CopyLinkButton;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
`;

const CopiedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Text = styled.div`
  word-break: break-all;
  text-align: start;
`;

const StyledButton = styled.button`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #37dcf2;
  border-radius: 12px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`;
import React, { useContext } from "react";
import styled from "styled-components";
import statusClaimed from "@/assets/icons/status-success.svg";
import statusDidntClaim from "@/assets/icons/status-error.svg";
import statusPending from "@/assets/icons/warning-icon-modal.svg";
import crabIcon from "@/assets/seasons/CRAB.svg";
import { Media } from "@/styles";
import { formatDisplay } from "@/utils/formatDisplay";
import { useRewardsContext } from "../../../RewardsContext";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { GeoContext } from "@/components/Overlays/GeoProvider";

type SeasonBoxProps = {
  status: string;
  seasonName: string;
  crabsValue: number;
};

export const SeasonBox = ({
  status,
  seasonName,
  crabsValue = 0,
}: SeasonBoxProps) => {
  const getStatusIcon = () => {
    if (status == "Pending") {
      return statusPending;
    } else if (status == "Claimed") {
      return statusClaimed;
    } else {
      return statusDidntClaim;
    }
  };

  const { airdropLoading } = useRewardsContext();
  const { isGeoLoading } = useContext(GeoContext);
  return (
    <SeasonBoxWrapper>
      <SeasonNameWrapper>
        <StatusIcon src={getStatusIcon()} alt={status} />
        <SeasonName>{seasonName}</SeasonName>
      </SeasonNameWrapper>
      <HrDiv />
      <CrabsWrapper>
        <CrabsLabel>CRAB Earned</CrabsLabel>
        <CrabsValueWrapper>
          <CrabsCoin src={crabIcon} alt="crabs coin" />
          <CrabsValue>
            {airdropLoading || isGeoLoading ? (
              <SkeletonBox
                isLoading={true}
                width="64px"
                height="20px"
                borderRadius="6px"
              />
            ) : (
              <> {formatDisplay(crabsValue.toString(), 2)} <span> CRAB</span> </>
            )}
          </CrabsValue>
        </CrabsValueWrapper>
      </CrabsWrapper>
    </SeasonBoxWrapper>
  );
};

const SeasonBoxWrapper = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--dark-3, #1e2239);
  width: 100%;
  background-image: linear-gradient(
    145deg,
    rgb(21 21 48) 0%,
    rgb(10 13 39) 100%
  );
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  ${Media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  ${Media.mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
`;

export const HrDiv = styled.div`
  display: none;

  ${Media.mobile} {
    display: block;
    height: 1px;
    width: 100%;
    background: #171b33;
  }
`;

const SeasonNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SeasonName = styled.p`
  color: var(--White, #fff);
  font-family: Inter;
  font-size: clamp(15px, 3vw, 18px);
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: -0.6px;
  overflow: hidden;
`;

const StatusIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CrabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  justify-content: flex-start;

  ${Media.mobile} {
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const CrabsLabel = styled.p`
  color: var(--grey-3, #7d7d97);
  width: 100%;
  white-space: nowrap;
  text-align: right;

  /* Text 3/Regular */
  font-family: Inter;
  font-size: clamp(12px, 2vw, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  ${Media.mobile} {
    text-align: center;
    width: 100%;
  }
`;

const CrabsValueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const CrabsCoin = styled.img`
  width: 21px;
  height: 21px;
`;

const CrabsValue = styled.p`
  color: var(--White, #fff);
  display: flex;
  /* Text 2/Medium */
  font-family: Inter;
  font-size: clamp(12px, 1.5vw, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  span {
    color: #7d7d97;
    margin-left: 8px;
  }

  ${Media.mobile} {
    font-size: clamp(10px, 4vw, 14px);
  }
`;

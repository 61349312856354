import React, { useContext } from "react";
import styled from "styled-components";
import {
  Bullet,
  LiveText,
  StatusBullet,
  StatusContainer,
} from "../LBP/StatusBullet";
import { Media } from "@/styles";
import { useNavigate } from "react-router";
import { DexBadge, ProtocolTypeOption } from "@/components/DexBadge/DexBadge";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { usePools } from "../Explore/ExploreContext";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface LPTokensBoxProps {
  icon: string;
  title: string;
  description: string;
  status?: string;
  color?: string;
  info: { title: string; value: string; color?: string }[];
  protocol: ProtocolTypeOption;
  version: string;
}

export const LPTokensBox = ({
  icon,
  title,
  description,
  status,
  info,
  color,
  protocol,
  version,
}: LPTokensBoxProps) => {
  const navigate = useNavigate();
  const { featuredPoolsLoading } = usePools();
  const { tokenMap, poolQuery } = useContext(ChainContext)

  return (
    <LPTokensBoxContainer
      onClick={() => {
        poolQuery.redirectToPool(tokenMap[title], navigate);
      }}
    >
      <LPTokensBoxHeader>
        <Icon src={icon} />
        <FlexContainer>
          <Title>
            {title}{" "}
            {status && (
              <StatusBulletContainer>
                <StatusBullet status={status} />
              </StatusBulletContainer>
            )}
          </Title>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Description>{description}</Description>
            <DexBadge protocol={protocol} protocolVersion={version} />
          </div>
        </FlexContainer>
      </LPTokensBoxHeader>
      <Divider />
      <InfoContainer>
        {info.map(({ title, value, color }) => (
          <InfoBox key={title + value}>
            <InfoTitle>{title}</InfoTitle>
            <InfoValue color={color}>
              {featuredPoolsLoading ? (
                <SkeletonBox
                  isLoading={true}
                  width={"50px"}
                  height="20px"
                  borderRadius="6px"
                />
              ) : (
                value
              )}
            </InfoValue>
          </InfoBox>
        ))}
      </InfoContainer>
      <Blur color={color} />
    </LPTokensBoxContainer>
  );
};

const Blur = styled.div<{ color?: string }>`
  width: 578px;
  height: 578px;
  position: absolute;
  bottom: -578px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 366px;
  opacity: 0.2;
  background: ${({ color }) => color || "#72CCD7"};
  filter: blur(75px);
  z-index: 0;
  transition: all 0.3s ease;
`;

const LPTokensBoxContainer = styled.div`
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--dark-3, #1e2239);
  background: var(--dark-4, #151530);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: #2c5173;
    ${Blur} {
      border-radius: 578px;
      bottom: -480px;
    }
  }

  ${Media.tablet} {
    padding: 16px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #1e2239;
`;

const LPTokensBoxHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--white, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
`;
const Description = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const InfoTitle = styled.div`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoValue = styled.div<{ color?: string }>`
  color: ${({ color }) => color || "#fff"};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StatusBulletContainer = styled.div`
  ${StatusContainer} {
    padding: 3px 8px;
  }
  ${LiveText} {
    font-size: 12px;
    line-height: normal;
  }
  ${Bullet} {
    width: 8px;
    height: 8px;
    &::after {
      width: 4px;
      height: 4px;
    }
  }
`;

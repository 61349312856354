import React from "react";

export const SingleHorizontalActionIcon = () => {
  return (
    <svg
      width="41"
      height="6"
      viewBox="0 0 41 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3"
        cy="3"
        r="2.5"
        transform="rotate(-180 3 3)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <circle
        cx="38"
        cy="3"
        r="2.5"
        transform="rotate(-180 38 3)"
        fill="#0A0E27"
        stroke="#37DCF2"
      />
      <path d="M6 3L35 3" stroke="#37DCF2" strokeDasharray="0" />
    </svg>
  );
};

import { StraightLoader } from "@/components/Loaders";
import { useWidthBreakpoint } from "@/hooks";
import Cleave from "cleave.js/react";
import { BalanceInfo } from "@/pages/Trade/BalanceInfo";
import { breakpoints } from "@/styles";
import { DISPLAY_DECIMALS, formatDisplay } from "@/utils/formatDisplay";
import React from "react";
import styled, { css } from "styled-components";
import crabIcon from '@/assets/seasons/CRAB.svg'
import { useRewardsContext } from "../../RewardsContext";


interface InputPanelProps {
  value: string;
  onChange: (value: string, balance: number) => void;
  label: string;
  reloadBalances: boolean;
  error: boolean;
  isWidget: boolean;
}

export const CrabInputPanel = ({
  value,
  onChange,
  label,
  reloadBalances,
  error,
  isWidget,
}: InputPanelProps) => {

  const { crabBalance } = useRewardsContext()

  const isTablet = useWidthBreakpoint(breakpoints.tablet)

  const onBalanceClick = (percentage: number) => {

    const truncateDecimals = (balance : number) => {
        const multiplier = Math.pow(10, DISPLAY_DECIMALS),
            adjustedNum = balance * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
        return (truncatedNum / multiplier).toString();
    }

    const balanceNum = parseFloat(crabBalance);
  
    onChange(truncateDecimals(balanceNum * percentage), parseFloat(crabBalance));
  };

  return (
    <>
      <View
        errorBorder={error}
        shake={false}
      >
        <ViewRow isShrunk={isTablet || isWidget}>
          <Label className={isTablet || isWidget ? 'shrunk' : ''}>
            <CleaveStyled
              placeholder="0.0"
              value={value}
              options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
                numeralDecimalScale: DISPLAY_DECIMALS,
              }}
              onChange={(event : any) => onChange(event.target.value, parseFloat(crabBalance))}
              wrapped={'false'}
              onKeyDown={(event : any) =>{ 
                    ["-"].includes(event.key) && event.preventDefault()
                    if (isTablet && [",", "٫"].includes(event.key)) {
                        event.preventDefault();
                        event.target.value += '.';
                    }
                }
              }
              inputMode="decimal"
              className={isTablet || isWidget ? 'shrunk' : ''}
            />
          </Label>
          <Row>
            <BalanceInfo
                tokenID={'CRAB'}
                balance={crabBalance}
                showMax={true}
                balanceClick={onBalanceClick}
                isNFTCollection={false}
                isShrunk={isTablet || isWidget}
            />
            <img src={crabIcon} style={{height: '40px', width: '40px'}}/>
          </Row>
        </ViewRow>
      </View>
    </>
  );
};

const Button = styled.button<{ shellTokenSelected: boolean, isShrunk: boolean }>`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: #151530;
  border: 2px solid #2c5173;
  box-shadow: 0px 6px 7px #04081c;
  border-radius: 50%;

  ${({ shellTokenSelected, isShrunk }) =>
    shellTokenSelected &&
    css`
      margin-top: ${isShrunk ? '-64' : '-16'}px;
  `};
`;

const View = styled.div<
 {
    errorBorder: boolean;
    shake?: boolean;
  }
>`
  position: relative;
  border-radius: 16px;
  z-index: 1;

  width: 100%;
  max-width: 100%;

  &:hover {
    filter: drop-shadow(0px 0px 2px #2c5173);
  }

  ${Button} {
    display: block;
  }

  & + & {
    margin-top: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #151530;
    border: 1px solid #1e2239;
    border-radius: 16px;
    z-index: -1;
  }

  ${({ errorBorder }) =>
    errorBorder &&
    css`
      filter: drop-shadow(0px 0px 2px #cc0000) !important;
    `};

  ${({ shake }) =>
    shake &&
    css`
      animation: shake 0.25s infinite;
    `};

  @keyframes shake {
    0% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(0deg);
    }
    30% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    40% {
      transform: translate(1px, 0px) rotate(0deg);
    }
    50% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    60% {
      transform: translate(-3px, 0px) rotate(0deg);
    }
    70% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    80% {
      transform: translate(-1px, 0px) rotate(0deg);
    }
    90% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    100% {
      transform: translate(1px, 0px) rotate(0deg);
    }
  }

`;

const ViewRow = styled.div<{ isShrunk: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 18px 24px 24px;
  overflow: hidden;

  ${({ isShrunk }) =>
    isShrunk &&
    css`
      padding: 20px 12px 16px 24px;
    `};
`;


const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a9d1e2;
  
  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 12px;
      line-height: 14px;
    `};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  row-gap: 10px;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      column-gap: 8px;
    `};

`;

const StyledStraightLoader = styled(StraightLoader)`
  margin-left: 10px;
`;

const USDValue = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  padding-left: 3px;
`;

const PriceImpact = styled(USDValue)<{ value: number }>`
  font-weight: 1;

  ${({ value }) =>
    value > 0.001 &&
    css`
      color: #7adeb9;
    `};
  ${({ value }) =>
    value <= -5 &&
    css`
      color: #ff5349;
    `};
  ${({ value }) =>
    value > -5 &&
    value <= -1 &&
    css`
      color: #daa520;
    `};
`;

const inputTextStyles = css`
  font-weight: 500;
  font-size: 46px;
  line-height: 56px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const CleaveStyled = styled(Cleave)<{ wrapped?: string }>`
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 180px;
  padding-right: 8px;
  margin-top: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  ${inputTextStyles}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &::-moz-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &:-ms-input-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }
  &:-moz-placeholder {
    ${inputTextStyles}
    ${props =>
        props.className?.includes('shrunk') &&
        css`
        font-size: 30px;
        line-height: 36px;
    `};
  }

  ${({ wrapped }) =>
    wrapped === "true" &&
    css`
      color: #37dcf2;

      &::-webkit-input-placeholder {
        color: #37dcf2;
      }
      &::-moz-placeholder {
        color: #37dcf2;
      }
      &:-ms-input-placeholder {
        color: #37dcf2;
      }
      &:-moz-placeholder {
        color: #37dcf2;
      }
    `};

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 30px;
      line-height: 36px;
      margin-top: 8px;
      min-width: 100px;
      max-width: 144px;
    `};
`;

export const InputPanelsWrapper = styled.div<{ position?: "top" | "bottom" }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ position }) =>
    position === "top"
      ? css`
          ${View} {
            &:not(:last-child) {
              &:after {
                display: none;
              }
              &:before {
                clip-path: unset;
              }
            }
          }
        `
      : css`
          ${View} {
            &:not(:first-child) {
              &:after {
                display: none;
              }
              &:before {
                clip-path: unset;
              }
            }
          }
        `};
`;

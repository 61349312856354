import { getFormattedChartHalfDate, isDateAfterCurrent } from "@/utils";
import React from "react";
import styled from "styled-components";

interface TooltipProps {
  active?: boolean;
  payload?: Array<Object>;
  type: string;
}

export function roundToFirstTwoNonZeroDecimals(num: number): number {
  let count = 0;
  let tempNum = num;

  // Count the number of zeros after the decimal point before a non-zero digit
  while (count < 2 && tempNum % 1 !== 0) {
    tempNum *= 10;
    if (Math.floor(tempNum) === 0) {
      count++;
    }
  }

  // Calculate the multiplier for rounding
  const multiplier = Math.pow(10, count + 2);

  // Round the number to the first two non-zero decimal values
  return Math.round(num * multiplier) / multiplier;
}

export const StatsTooltip = ({ active, payload, type }: TooltipProps) => {

  if (
    active &&
    payload &&
    payload.some(
      (item: any) =>
        item.name && item.payload[item.name] && item.payload[item.name] > 0
    )
  ) {
    const [item]: any = payload;

    const prefix = type == 'price' ? '$' : ''
    const suffix = type == 'APY' ? '%' : ''
    
    return (
      <TooltipContainer>
        <TooltipDate>
          {getFormattedChartHalfDate((payload[0] as any).payload.date)}
        </TooltipDate>
        <TooltipRow>
          <TooltipRowText>
            <TooltipRowLabel color={item.color} capitalized={false}>
              {isDateAfterCurrent((payload[0] as any).payload.date)
                ? `Predicted ${type}`
                : type.charAt(0).toUpperCase() + type.slice(1)}
              :
            </TooltipRowLabel>
            {item.name && item.payload[item.name] && (
              <div style={{ display: "inline", marginLeft: "4px" }}>
                {prefix}{roundToFirstTwoNonZeroDecimals(item.value)}{suffix}
              </div>
            )}
          </TooltipRowText>
        </TooltipRow>
      </TooltipContainer>
    );
  }
  return <></>;
};

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 220px;
  max-width: 100%;
  padding: 10px 12px;
  border: 1px solid #1e2239;
  border-radius: 14px;
  background-color: #171b33;
`;

const TooltipDate = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #7d7d97;
`;

const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 4px;
  white-space: nowrap;
`;

const TooltipRowText = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
`;

const TooltipRowLabel = styled.span<{ color?: string; capitalized?: boolean }>`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${({ color }) => color ?? "inherit"};
  text-transform: ${({ capitalized }) => (capitalized ? "capitalize" : "none")};
`;

import React, { useState } from "react";
// import { TokenCell } from "./TableCells/TokenCell";
// import { TimestampCell } from "./TableCells/TimestampCell";
// import { UsdValue } from "./TableCells/UsdValueCell";
// import { TypeCell } from "./TableCells/TypeCell";
import styled from "styled-components";
// import { LeaderboardTableSubRow } from "./LeaderboardTableSubRow";
import { Media } from "@/styles";
import { reduceString } from "@/utils/reduceString";
import { useNavigate } from "react-router";
import { PointsIcon } from "@/components/Icons/PointsIcon";
import shellIcon from "@/assets/coins/SHELL.svg";

interface LeaderboardTableRowMobileProps {
  el: {
    address: string;
    clickLink: string;
    id: number;
    points: string;
    visible: boolean;
  };
  onRowClick?: (address: string) => void;
  isShell: boolean;
}

export const LeaderboardTableRowMobile = ({ el, onRowClick, isShell }: LeaderboardTableRowMobileProps) => {
  const navigate = useNavigate();
  return (
    <DataContainer>
      <FlexContainer>
        Rank
        <span>#{el.id}</span>
      </FlexContainer>
      <FlexContainer>
       {`${isShell ? "SHELL" : "Shell Points"}`}
        <span>
          {isShell ? (
              <img
                style={{ width: "24px", height: "24px", marginRight: "4px" }}
                src={shellIcon}
              />
            ) : (
              <PointsIcon />
            )}
          {el.points}
        </span>
      </FlexContainer>
      <FlexContainer>
        Wallet
        <span>{reduceString(el.address, 7, 6)}</span>
      </FlexContainer>
      <ButtonContainer>
        <Button onClick={() => onRowClick && onRowClick(el.address)}>
          View wallet
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 15L13 10L8 5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </Button>
      </ButtonContainer>
    </DataContainer>
  );
};

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px 12px;
  background: #151530;
  border: 1px solid #1e2239;
  border-radius: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--white, #fff);

    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const Button = styled.div`
  padding-top: 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-inline: -12px;
  grid-column: 1 / 2 span;
  border-top: 1px solid #1e2239;
`;

import React from "react";

export const WebsiteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6332 11.667C13.6998 11.117 13.7498 10.567 13.7498 10.0003C13.7498 9.43366 13.6998 8.88366 13.6332 8.33366H16.4498C16.5832 8.86699 16.6665 9.42533 16.6665 10.0003C16.6665 10.5753 16.5832 11.1337 16.4498 11.667M12.1582 16.3003C12.6582 15.3753 13.0415 14.3753 13.3082 13.3337H15.7665C14.9591 14.7239 13.6783 15.777 12.1582 16.3003ZM11.9498 11.667H8.04983C7.9665 11.117 7.9165 10.567 7.9165 10.0003C7.9165 9.43366 7.9665 8.87533 8.04983 8.33366H11.9498C12.0248 8.87533 12.0832 9.43366 12.0832 10.0003C12.0832 10.567 12.0248 11.117 11.9498 11.667ZM9.99983 16.6337C9.30817 15.6337 8.74983 14.5253 8.40817 13.3337H11.5915C11.2498 14.5253 10.6915 15.6337 9.99983 16.6337ZM6.6665 6.66699H4.23317C5.03221 5.273 6.31216 4.21823 7.83317 3.70033C7.33317 4.62533 6.95817 5.62533 6.6665 6.66699ZM4.23317 13.3337H6.6665C6.95817 14.3753 7.33317 15.3753 7.83317 16.3003C6.31535 15.7767 5.03721 14.7234 4.23317 13.3337ZM3.54984 11.667C3.4165 11.1337 3.33317 10.5753 3.33317 10.0003C3.33317 9.42533 3.4165 8.86699 3.54984 8.33366H6.3665C6.29983 8.88366 6.24983 9.43366 6.24983 10.0003C6.24983 10.567 6.29983 11.117 6.3665 11.667M9.99983 3.35866C10.6915 4.35866 11.2498 5.47533 11.5915 6.66699H8.40817C8.74983 5.47533 9.30817 4.35866 9.99983 3.35866ZM15.7665 6.66699H13.3082C13.0473 5.63487 12.6611 4.63857 12.1582 3.70033C13.6915 4.22533 14.9665 5.28366 15.7665 6.66699ZM9.99983 1.66699C5.3915 1.66699 1.6665 5.41699 1.6665 10.0003C1.6665 12.2105 2.54448 14.3301 4.10728 15.8929C4.8811 16.6667 5.79976 17.2805 6.8108 17.6993C7.82185 18.1181 8.90548 18.3337 9.99983 18.3337C12.21 18.3337 14.3296 17.4557 15.8924 15.8929C17.4552 14.3301 18.3332 12.2105 18.3332 10.0003C18.3332 8.90598 18.1176 7.82234 17.6988 6.8113C17.28 5.80025 16.6662 4.88159 15.8924 4.10777C15.1186 3.33395 14.1999 2.72012 13.1889 2.30133C12.1778 1.88254 11.0942 1.66699 9.99983 1.66699Z"
        fill="url(#paint0_linear_3765_24735)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3765_24735"
          x1="1.81234"
          y1="1.667"
          x2="18.4609"
          y2="1.79559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { MergeActionIcon } from "@/components/Icons/MergeActionIcon";
import { SingleHorizontalActionIcon } from "@/components/Icons/SingleHorizontalActionIcon";
import { SplitActionIcon } from "@/components/Icons/SplitActionIcon";
import { TokenLogo } from "@/components/TokenLogo";
import { tokenLogos } from "@/constants/tokenLogos";
import { isNFTCollection, maxTokenDecimals } from "@/utils/tokens";
import { Media } from "@/styles";
import { scrollbar } from "@/styles/scrollbar";
import { formatDisplayShorthand } from "@/utils/formatDisplay";
import { get1155Item } from "@/utils/nftHelpers";
import React, { useState, useEffect, useRef, useContext } from "react";
import styled, { css } from "styled-components";
import { TransactionNFTDetails } from "./TransactionNFTDetails";
import { Spinner } from "@/components/Loaders";
import { allChains } from "@/placeholders/chains";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface TableSubRowProps {
  row: any;
  interaction: any;
  isLoading: boolean;
}

export const TransactionAction = ({ row, interaction, isLoading }: TableSubRowProps) => {

  const { tokenMap } = useContext(ChainContext)

  const { original } = row;

  const arrowType = original.arrowType;

  const type = original.type;

  const isWrapping = type === "Wrap";

  const [nftShown, setNFTShown] = useState("");
  const [nftPathIndex, setNFTPathIndex] = useState(0);

  const [preArrowPaths, setPreArrowPaths]: any[] = useState([]);
  const [postArrowPaths, setPostArrowPaths]: any[] = useState([]);

  let [preArrowPathLength, setPreArrowPathLength] = useState(0);
  let [postArrowPathLength, setPostArrowPathLength] = useState(0);

  const handleClick = (tokenID: string, pathIndex: number) => {
    setNFTShown(tokenID);
    setNFTPathIndex(pathIndex);
  };

  useEffect(() => {
    if(interaction){
        setPreArrowPaths(interaction.preArrowPaths);
        setPostArrowPaths(interaction.postArrowPaths);
        setPreArrowPathLength(interaction.preArrowPathLength);
        setPostArrowPathLength(interaction.postArrowPathLength);
    }
  }, [interaction]);

  return (
    <Interactions>
      <ActionHeader>Transaction action</ActionHeader>
      {isLoading ? (
        <CircleContainer>
          <Spinner />
        </CircleContainer>
      ) : isWrapping ? (
        <TransactionActionWrappingContainer>
          {[...Array(preArrowPathLength)].map((_, stepIndex) => (
            <FlexContainer key={stepIndex}>
              <ActionItem>
                <Column>
                  <Logo />
                </Column>
                <ActionColumn>
                  <Column>#1142</Column>
                  <Column color="#7D7D97">$68.72</Column>
                </ActionColumn>
              </ActionItem>
              <SingleArrowContainer>
                <SingleHorizontalActionIcon />
              </SingleArrowContainer>
              <ActionItem>
                <Column>
                  <Logo />
                </Column>
                <ActionColumn>
                  <Column>#1142</Column>
                  <Column color="#7D7D97">$68.72</Column>
                </ActionColumn>
              </ActionItem>
            </FlexContainer>
          ))}
        </TransactionActionWrappingContainer>
      ) : (
        <>
            {[...Array(preArrowPathLength)].map((_, stepIndex: any) => {
            return (
                <TokenWithArrowWrapper key={'pre' + stepIndex} alignWay="before">
                    {[...Array(preArrowPaths.length)].map((_, pathIndex) => {
                        const step = preArrowPaths[pathIndex][stepIndex];
                        const tokenData = tokenMap[step.token]
                        const LogoIcon = tokenLogos[step.token] ?? Logo;
                        if(step.token !== ''){
                            return (
                                <TokenLabelWrapper
                                    key={'pre' + stepIndex + pathIndex} 
                                    afterAction={false} 
                                    arrowType={arrowType} 
                                    arrow={(stepIndex == 0 || preArrowPaths[pathIndex][stepIndex - 1].token === '') ? 'none' : 'before'}
                                >
                                    <Column>
                                        {step.token !== "" ?
                                        isNFTCollection(tokenData) ?
                                        <NFTDetails key={step.token + pathIndex} onClick={() => handleClick(step.token, pathIndex)}>
                                            {tokenData.is1155 ? 
                                            <img style={{borderRadius: '4px'}} src={get1155Item(step.nftID[0].id).icon} alt="logo" /> : 
                                            <LogoIcon />
                                            }
                                        </NFTDetails>
                                        : step.chain ? 
                                        <BridgeTokenLogo 
                                          key={step.token + pathIndex} 
                                          logo={(tokenMap[step.token] ?? tokenMap['ETH']).icon} 
                                          chainLogo={allChains[step.chain].icon!}
                                        /> 
                                        : 
                                        <LogoIcon key={step.token + pathIndex} />
                                        :
                                        <Blank key={step.token + pathIndex} />
                                        }
                                    </Column>
                                    <ActionColumn>
                                        <Column color="#7D7D97">
                                            {step.token !== "" ?  
                                            <TokenAmount key={step.token + pathIndex}>
                                                {formatDisplayShorthand(step.amount, maxTokenDecimals(step.token), 1e4)}
                                            </TokenAmount>
                                            :
                                            <Blank key={step.token + pathIndex} />
                                            }
                                        </Column>
                                        <Column>
                                            {step.token !== "" ?  
                                            <TokenInfo key={step.token + pathIndex}>
                                                {step.token}
                                            </TokenInfo>
                                            :
                                            <Blank key={step.token + pathIndex} />
                                            }
                                        </Column>
                                    </ActionColumn>
                                </TokenLabelWrapper>
                            )
                        } else {
                            return <div key={'pre' + stepIndex + pathIndex}></div>
                        }
                    })}
                </TokenWithArrowWrapper>
            );
            })}
            {arrowType !== "single" && (
            <MultiArrowContainer>
                {arrowType == "merge" ? (
                <MergeActionIcon />
                ) : (
                <SplitActionIcon />
                )}
            </MultiArrowContainer>
            )}
            {[...Array(postArrowPathLength)].map((_, stepIndex: any) => {
            return (
                <TokenWithArrowWrapper key={'post' + stepIndex} alignWay="after">
                    {[...Array(postArrowPaths.length)].map((_, pathIndex) => {
                        const step = postArrowPaths[pathIndex][stepIndex];
                        const tokenData = tokenMap[step.token]
                        const LogoIcon = tokenLogos[step.token] ?? Logo;
                        if(step.token !== ''){
                            return (
                                <TokenLabelWrapper
                                    key={'post' + stepIndex + pathIndex} 
                                    afterAction={true} 
                                    arrowType={arrowType} 
                                    arrow={(stepIndex == postArrowPaths[pathIndex].length - 1 || postArrowPaths[pathIndex][stepIndex + 1].token === '') ? 'none' : 'after'}
                                >
                                    <Column>
                                        {step.token !== "" ?
                                        isNFTCollection(tokenData) ?
                                        <NFTDetails key={step.token + pathIndex} onClick={() => handleClick(step.token, pathIndex)}>
                                            {tokenData.is1155 ? 
                                            <img style={{borderRadius: '4px'}} src={get1155Item(step.nftID[0].id).icon} alt="logo" /> : 
                                            <LogoIcon />
                                            }
                                        </NFTDetails>
                                        :
                                        <LogoIcon key={step.token + pathIndex} />
                                        :
                                        <Blank key={step.token + pathIndex} />
                                        }
                                    </Column>
                                    <ActionColumn>
                                        <Column color="#7D7D97">
                                            {step.token !== "" ?  
                                            <TokenAmount key={step.token + pathIndex}>
                                                {formatDisplayShorthand(step.amount, maxTokenDecimals(step.token), 1e4)}
                                            </TokenAmount>
                                            :
                                            <Blank key={step.token + pathIndex} />
                                            }
                                        </Column>
                                        <Column>
                                            {step.token !== "" ?  
                                            <TokenInfo key={step.token + pathIndex}>
                                                {step.token}
                                            </TokenInfo>
                                            :
                                            <Blank key={step.token + pathIndex} />
                                            }
                                        </Column>
                                    </ActionColumn>
                                </TokenLabelWrapper>
                            )
                        } else {
                            return <div key={'post' + stepIndex + pathIndex}></div>
                        }
                    })}
                </TokenWithArrowWrapper>
            );
            })}
            <TransactionNFTDetails
              transaction={original}
              nftShown={nftShown}
              pathIndex={nftPathIndex}
              handleCloseModal={() => setNFTShown("")}
            />
        </>
      )}
    </Interactions>
  );
};

const ActionHeader = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  align-self: flex-start;
  font-family: Inter;
  letter-spacing: -0.6px;

  ${Media.mobile}{
    font-size: 18px;
  }
`;

const Interactions = styled.div`
  width: 50%;
  max-height: 239px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 32px;
  box-sizing: border-box;

  ${scrollbar()}

  > svg {
    margin: 8px;
  }

  ${Media.tablet} {
    width: 100%;
    max-height: 246px;
    padding-inline: 0;
    border-radius: 0;
    padding: 0 16px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${Media.tablet}{
    gap: 12px;
    padding-left: 16px;
    padding-right: 7px;
  }
`;

const TransactionActionWrappingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;

  ${Media.tablet}{
    padding-left: 0;
  }
`;

const Blank = styled.div`
  height: 24px;
  &:empty {
    display: none;
  }
`;

const SingleArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NFTDetails = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const MultiArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${Media.tablet} {
    align-self: center;
  }
`;

const Column = styled.div<{color?: string}>`
  display: flex;
  flex-direction: column;
  align-self: start;
  row-gap: 10px;

  color: ${({color}) => color ? color : '#fff'};

  > div > img {
    height: 32px;
    width: 32px;
    margin: 0;
  }

  > img {
    height: 32px;
    width: 32px;
    margin: 0;
  }

  > svg {
    margin: 8px;
    width: 32px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  color: #ffffff;
  column-gap: 4px;
`;

const ActionItem = styled(Row)`
  padding: 10px 16px;
  border-radius: 12px;
  border: 1px solid var(--dark-3, #1e2239);
  background: var(--dark-4, #151530);
  gap: 8px;

  ${Media.tablet} {
    padding: 10px 8px;
    font-size: 14px;
  }
`;

const ActionColumn = styled(Column)`
  gap: 4px;
`;

const TokenAmount = styled.p`
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  color: #ffffff;
`;

const TokenInfo = styled.p`
  color: var(--grey-3, #7d7d97);
  font-size: 14px;
  font-family: Inter;

  ${Media.mobile}{
    font-size: 12px;
  }
`;

const Logo = styled(TokenLogo)`
  width: 10px;
`;

const TokenLabelWrapper = styled.div<{ afterAction: boolean; arrowType: 'split' | 'merge' | 'single', arrow: 'before' | 'after' | 'none' }>`
  display: flex;
  position: relative;
  align-items: center;
  width: fit-content;
  padding: 10px 16px;
  border: 1px solid var(--dark-3, #1e2239);
  border-radius: 12px;
  background: var(--dark-4, #151530);
  margin: 0 auto;
  justify-content: center;
  gap: 8px;

  ${({ arrowType, afterAction }) => {
    switch (arrowType) {
      case 'merge':
        return css`
          grid-column: ${afterAction && '1 / -1'};
        `
      case 'split':
        return css`
          grid-column: ${!afterAction && '1 / -1'};
        `
      case 'single':
        return css`
            grid-column: ${!afterAction && '1 / -1'};
        `
    }
  }}

  & img {
    display: block;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  &:before,
  &:after {
    width: 6px;
    height: 21px;
    position: absolute;
    left: 50%;
    background-size: 6px 21px;
    z-index: 1;
  }

  ${({ arrow }) => {
    switch (arrow) {
      case 'before':
        return css`
          margin-top: 18px;
          top: 0;
          &:before {
            content: '';
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAABUCAMAAACvMwfdAAAAtFBMVEUAAAAA//9V//8u0ehA1eo32+092/Mz4PU72PU52fY32/Y13PY43+832/M12/M03PM33PM23PM13fQ23fI42/I42/I32/I43fM32/M32/M23PE43PE43PE33fE33fE22/E33PM33PI33PI23PI43fI33PI43fI33PI33PI23PI33PM33PI33PI33PI32/I33PI33PI33PI33PI33PI33fI23PI33PI33PI33PI33PI33PL///9p6VaVAAAAOnRSTlMAAgMLDA4VGRobHB0gKissQUJDTE1OT2lqa2xtbm9wcZGZmpucoqq1tre40tTW19jZ8fLz9Pj5+/z9NEcKggAAAAFiS0dEOzkO9GwAAAEKSURBVEjH7ZRNU8JAEEQbiGKMgIhoXIKKXwhBYwgu7Pv/P8xDoJJovOktfew3NTNduzVSrsAsk80mWZhAZfmzLXu5l7PCv8ooaT0++FNHRS7a1zuAOOp1Ov1pDODGkuRnwGfYyqtaoQXSE0lPgD0vBg4t8CAFO+C6vKIBtr4MELfKoL0CQi2AqBJKt8BcH0CvCvpAogzwqsADsjpwBKR1rQZA8vtwA8TtmnWDHXBTBpM8oB4BOyz8CwvMJPlrwJp9t/bEAmlXksYOYHXb97zB3QrAjfKq6PtDTQ5tL9dlPx0VA4/vi8/w3K2EOg1f37PsbR76+iEA1akBDWjA/4PALAGa297c9j+57V/Tr5H58TAs7gAAAABJRU5ErkJggg==');
            transform: translate(-50%, -36px);
          }
        `
      case 'after':
        return css`
          margin-bottom: 18px;
          bottom: 0;
          &:after {
            content: '';
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAABUCAMAAACvMwfdAAAAtFBMVEUAAAAA//9V//8u0ehA1eo32+092/Mz4PU72PU52fY32/Y13PY43+832/M12/M03PM33PM23PM13fQ23fI42/I42/I32/I43fM32/M32/M23PE43PE43PE33fE33fE22/E33PM33PI33PI23PI43fI33PI43fI33PI33PI23PI33PM33PI33PI33PI32/I33PI33PI33PI33PI33PI33fI23PI33PI33PI33PI33PI33PL///9p6VaVAAAAOnRSTlMAAgMLDA4VGRobHB0gKissQUJDTE1OT2lqa2xtbm9wcZGZmpucoqq1tre40tTW19jZ8fLz9Pj5+/z9NEcKggAAAAFiS0dEOzkO9GwAAAEKSURBVEjH7ZRNU8JAEEQbiGKMgIhoXIKKXwhBYwgu7Pv/P8xDoJJovOktfew3NTNduzVSrsAsk80mWZhAZfmzLXu5l7PCv8ooaT0++FNHRS7a1zuAOOp1Ov1pDODGkuRnwGfYyqtaoQXSE0lPgD0vBg4t8CAFO+C6vKIBtr4MELfKoL0CQi2AqBJKt8BcH0CvCvpAogzwqsADsjpwBKR1rQZA8vtwA8TtmnWDHXBTBpM8oB4BOyz8CwvMJPlrwJp9t/bEAmlXksYOYHXb97zB3QrAjfKq6PtDTQ5tL9dlPx0VA4/vi8/w3K2EOg1f37PsbR76+iEA1akBDWjA/4PALAGa297c9j+57V/Tr5H58TAs7gAAAABJRU5ErkJggg==');
            transform: translate(-50%, 36px);
          }
        `
    }
  }}
`

const TokenWithArrowWrapper = styled.div<{ alignWay?: 'before' | 'after' }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: ${({ alignWay }) => alignWay === 'before' ? 'none' : 'start'};
  gap: 8px;
`
const CircleContainer = styled.div`
  width: 56px;
  height: 56px;
  margin: auto;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    width: 56px;
    height: 56px;
  }

  ${Media.tablet} {
    width: 36px;
    height: 36px;
    margin: 24px auto;

    img {
        width: 36px;
        height: 36px;
    }
  }
`

const BridgeTokenLogo = styled.div<{ logo: string, chainLogo: string }>`
height: 32px;
width: 32px;
position: relative;

&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;;
}

&::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 14px;
    height: 14px;
    background: url(${({ chainLogo }) => chainLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    border: 2px solid #151530;
    border-radius: 50%;
}
`;
import React from "react";
import styled from "styled-components";
import { Media } from "../../styles";
import { formatDisplay } from "@/utils/formatDisplay";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { useMetrics } from "./ExploreContext";
import { VolumeChart } from "./VolumeChart";

export const Metrics = () => {
  const { metrics, metricsLoading } = useMetrics();

  const renderMetricBox = (metricKey: string, title: string) =>
    metrics && (
      <SingleBox>
        <div>{title}</div>
        {metricsLoading ? (
          <SkeletonBox
            isLoading={true}
            width="200px"
            height="32px"
            borderRadius="8px"
          />
        ) : (
          <>{metricKey == 'volume' ? '$' : ''}{formatDisplay(metrics[metricKey]?.value.toString(), 0)}</>
        )}
        <div>
          Last 24h:{" "}
          <span>
            {metricsLoading ? (
              <SkeletonBox
                isLoading={true}
                width="64px"
                height="20px"
                borderRadius="6px"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              />
            ) : (
              <>
                {metricKey == 'volume' ? '$' : ''}{formatDisplay(metrics[metricKey]?.dailyChange.toString(), 0)}
              </>
            )}
          </span>
        </div>
      </SingleBox>
    );

  return (
    <>
      <Boxes>
        {renderMetricBox("volume", "Lifetime Volume")}
        {renderMetricBox("users", "Users")}
      </Boxes>
      <VolumeChart
        title="Volume"
        volumeData={metrics?.volumeChart}
        loading={metricsLoading}
      />
    </>
  );
};

const Boxes = styled.div`
  display: flex;
  gap: 12px;

  ${Media.tablet} {
    padding-inline: 8px;
    flex-direction: column;
  }
`;

const SingleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 20px;
  border-radius: 16px;
  background: #151530;
  border: 1px solid #1e2239;
  font-size: 30px;
  line-height: 0.85em;
  font-weight: 500;
  flex: 1;

  div {
    color: #7d7d97;
    font-weight: 400;
    font-size: 16px;
  }

  span {
    color: #ffffff;
    font-weight: 500;
  }
  ${Media.tablet} {
    font-size: 24px;

    div {
      font-size: 14px;
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { useRewardsContext } from "../../../RewardsContext";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";

interface GaugeClaimButtonProps {
  row: any;
  isActive: boolean;
}

export const GaugeClaimButton = ({ row, isActive }: GaugeClaimButtonProps) => {
  const { claimFromGauge } = useRewardsContext();
  const reward = row.reward;
  const gaugeAddress = row.gauge;

  return (
    <CustomSecondaryButton onClick={() => claimFromGauge(gaugeAddress, reward)} disabled={reward === 0}>
      Claim {isActive ? "Rewards" : ""}
    </CustomSecondaryButton>
  );
};

const CustomSecondaryButton = styled(ButtonSecondary)`
  padding: 17px 24px;
  height: unset;
  width: auto;
  border-width: 2px;
  border-radius: 16px;

  &:hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    border: solid 2px #000e47;
    color: #000e47;
  }

  ${({ disabled }) =>
    disabled &&
    `
        color: var(--grey-4, #464659);
        pointer-events: none;
        border-color: #1E2239;
        background: #151530;
    `}

  ${Media.tablet} {
    padding: 12px 16px;

    border-radius: 12px;
    font-size: 14px;
  }
`;

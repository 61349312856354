import React from "react";
import * as S from "../Vesting.styled";
import { formatDisplay } from "@/utils/formatDisplay";
import { useRewardsContext } from "@/pages/Rewards/RewardsContext";

export const VestingProgress = () => {

    const { vestingStreams } = useRewardsContext()

    const totalVesting = vestingStreams.map((stream) => parseFloat(stream.vesting)).reduce((partialSum, a) => partialSum + a, 0)
    const totalVested = vestingStreams.map((stream) => parseFloat(stream.vested)).reduce((partialSum, a) => partialSum + a, 0)
    const totalClaimed = vestingStreams.map((stream) => parseFloat(stream.claimed)).reduce((partialSum, a) => partialSum + a, 0)

    return (
        <S.BoxWrapper>
            <S.BoxTitle>Vesting Progress</S.BoxTitle>
            <S.BoxDetailsWrapper>
                <S.BoxDetailsRow>
                    <S.BoxDetailsLabel>Tokens vesting</S.BoxDetailsLabel>
                    <S.BoxDetailsValue>{formatDisplay(totalVesting.toString(), 2)} SHELL</S.BoxDetailsValue>
                </S.BoxDetailsRow>
                <S.CustomHrDiv />
                <S.BoxDetailsRow>
                    <S.BoxDetailsLabel>Tokens vested</S.BoxDetailsLabel>
                    <S.BoxDetailsValue>{formatDisplay(totalVested.toString(), 2)} SHELL</S.BoxDetailsValue>
                </S.BoxDetailsRow>
                <S.CustomHrDiv />
                <S.BoxDetailsRow>
                    <S.BoxDetailsLabel>Tokens claimed</S.BoxDetailsLabel>
                    <S.BoxDetailsValue>{formatDisplay(totalClaimed.toString(), 2)} SHELL</S.BoxDetailsValue>
                </S.BoxDetailsRow>
            </S.BoxDetailsWrapper>
        </S.BoxWrapper>
    )
}
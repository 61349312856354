import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box } from '../../../../components/Layout/Box';
import { PointsChart } from './PointsChart';
import { PointsDetails } from './PointsDetails';
import { PointsTable } from './PointsTable';

import { NFT_PRICES_API, POINTS_API, PRICES_API } from '../../../../constants/urls';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isExternalToken, isNFTCollection } from '../../../../utils/tokens';
import { LogoLoader } from '../../../../components/Loaders';
import { formatUnits } from '@ethersproject/units';
import { BigNumber, Contract } from 'ethers';
import { OceanABI } from '../../../../constants/ABI/OceanABI';
import { OCEAN_ADDRESS, OLD_OCEAN_ADDRESS, SHELL_ADDRESS, STAKING_ADDRESS } from '../../../../constants/addresses';
import { addBalance, addNFTBalance } from '../../../../store/balancesSlice';
import { PoolQuery } from '../../../../utils/PoolQuery';
import { artifacts } from '../../../Booty/Items';
import { addPrice } from '../../../../store/pricesSlice';
import { getNFTs } from '../../../../utils/nftHelpers';
import { removeLeadingZeros } from '../../../../utils/ocean/utils';
import { WRAPPED_NFT_MAP } from '../../../../utils/nftHelpers';
import { SeasonFilter } from './components/FilterSeasonDropdown';
import { HistoricalPointsTable } from './HistoricalPointsTable';
import { EarnShellPointsBoxes } from './components/EarnShellPointsBoxes/EarnShellPointsBoxes';
import { Media } from '@/styles';
import { BoxButton } from '@/components/Buttons/Button';
import shellEthIcon from "@/assets/points/shell-eth-icon.png"
import arbEthIcon from "@/assets/points/ARB+ETH-icon.png";
import { LeaderboardIcon } from '@/components/Icons/LeaderboardIcon';
import { useNavigate } from 'react-router';
import { BoostBox } from './components/BoostBox';
import { CrabABI } from '../../../../constants/ABI/CrabABI';
import { formatDisplay } from '../../../../utils/formatDisplay';
import { HistoryIcon } from '@/components/Icons/HistoryIcon';
import { updateBoosts, updateCurrentPoints, updateHistoricalPoints } from '@/store/pointsSlice';
import { dateFilters, seasonFilters, CURRENT_SEASON, convertToSlug } from "../../../../types/ChartTypes";
import { ClaimHeader } from './ClaimHeader';
import { StakingABI } from '@/constants/ABI/StakingABI';
import { SkeletonBox } from '@/components/Loaders/SkeletonBox';
import { ChainContext } from '@/components/Overlays/ChainProvider';
import { defaultChain } from '@/placeholders/chains';
import { useRewardsContext } from '../../RewardsContext';

export const PointsScreen = () => {

  const { tokenMap, tokens, shellTokens, nftCollections, externalTokens, externalDefiTokens, poolQuery } = useContext(ChainContext)

  const navigate = useNavigate()
  const boxes = [
    {
      image: arbEthIcon,
      headerText: "STIP Rewards",
      descriptionText: `Deposit into pools to earn ARB`,
      onClickButton: () => navigate('/rewards/stip')
    },
    {
      image: shellEthIcon,
      headerText: 'SHELL is here!',
      descriptionText: 'Try the new SHELL+ETH pool',
      // TODO - Add correct navigate link
      onClickButton: () => poolQuery.redirectToPool(tokenMap['SHELL+ETH'], navigate)
    }
  ]
  const { address: walletAddress, isConnected } = useAccount();
  const provider = useProvider();
  const { chain: activeChain } = useNetwork();
  const validChain = activeChain?.name == defaultChain.name

  const {
    userEpochs
  } = useRewardsContext();

  const [pointData, setPointData] = useState({
    totalShellPoints: 0,
    forecastedPointsGain: 0,
    compoundedPoints: 0,
    boost: {},
    tokens: {}
  })

  const [isLoading, setIsLoading] = useState(true);
  const [totalValue, setTotalValue] = useState(0)
  const [shellBalance, setShellBalance] = useState('0')

  const oldOcean = new Contract(OLD_OCEAN_ADDRESS, OceanABI, provider)
  const ocean = new Contract(OCEAN_ADDRESS, OceanABI, provider)
  const staking = new Contract(STAKING_ADDRESS, StakingABI, provider)

  const userBoosts = useAppSelector(state => state.points.boosts)[walletAddress!] ?? {}
  const userCurrentPoints = useAppSelector(state => state.points.currentSeason)
  const userHistoricalPoints = useAppSelector(state => state.points.historicalSeason)
  const dispatch = useAppDispatch()

  const [seasonFilter, setSeasonFilter] = useState<SeasonFilter>({ label: CURRENT_SEASON });
  const [selectedDateFilter, setSelectedDateFilter] = useState("All");

  const getPrices = async () => {
    const priceData: { [id: string]: any } = {};

    const [pricesResponse, nftPricesResponse] = await Promise.all([
      fetch(PRICES_API(defaultChain)).then((response) => response.json()),
      fetch(NFT_PRICES_API(defaultChain)).then((response) => response.json()),
    ]);

    if (pricesResponse) {
      Object.keys(pricesResponse).forEach((tokenID) => {
        const tokenPrice = pricesResponse[tokenID];
        dispatch(addPrice({ chain: defaultChain.name, name: tokenID, price: tokenPrice }));
        dispatch(addPrice({ chain: defaultChain.name, name: "sh" + tokenID, price: tokenPrice }));
        priceData[tokenID] = tokenPrice;
        priceData["sh" + tokenID] = tokenPrice;
      });
    } else {
      console.error("Server not responding properly");
      return {};
    }

    if (nftPricesResponse) {
      Object.keys(nftPricesResponse).forEach((tokenID) => {
        const tokenPrice = nftPricesResponse[tokenID];
        dispatch(addPrice({ chain: defaultChain.name, name: tokenID, price: tokenPrice }));
        dispatch(addPrice({ chain: defaultChain.name, name: "sh" + tokenID, price: tokenPrice }));
        priceData[tokenID] = tokenPrice;
        priceData["sh" + tokenID] = tokenPrice;
      });
    }

    const lpTokenPrices = await Promise.all(
      shellTokens.map((shellToken) =>
        poolQuery.getUSDPrice(shellToken, priceData)
      ).concat(externalDefiTokens.filter((defiToken) => defiToken.tokenType == 'Shell').map((lpToken) =>
        poolQuery.getUSDPrice(lpToken, priceData)
      ))
    );

    if (lpTokenPrices) {
      shellTokens.forEach((shellToken, i) => {
        const tokenPrice = lpTokenPrices[i];
        dispatch(addPrice({ chain: defaultChain.name, name: shellToken.name, price: tokenPrice }));
        priceData[shellToken.name] = tokenPrice;
      });

      externalDefiTokens.filter((defiToken) => defiToken.tokenType == 'Shell').forEach((shellToken, i) => {
        const tokenPrice = lpTokenPrices[i + shellTokens.length];
        dispatch(addPrice({ chain: defaultChain.name, name: shellToken.symbol, price: tokenPrice }));
        priceData[shellToken.symbol] = tokenPrice;
      })

    }

    return priceData;
  };

  const queryPoints = async () => {
    if (!isConnected || !validChain) {
      const data = await fetch(POINTS_API(defaultChain) + '0xNull/now').then((response) => response.json())
      data.tokens['SHELL'] = { // add SHELL token to the data
        "quota": -1,
        "balance": 0,
        "rewardAPY": 0,
        "value": 0,
        "totalPoints": 0,
        "historical": {}
      };
    
      setPointData({
        totalShellPoints: 0,
        forecastedPointsGain: 0,
        compoundedPoints: 0,
        boost: 0,
        tokens: data.tokens
      })
      setTotalValue(0)
      setShellBalance('0')
      return false
    } else if (seasonFilter.label !== CURRENT_SEASON) {
      let pointData
      const slug = convertToSlug(seasonFilter.label)
      if (userHistoricalPoints[walletAddress!] && userHistoricalPoints[walletAddress!][slug]) {
        const cachedData = userHistoricalPoints[walletAddress!][slug]
        pointData = JSON.parse(cachedData)
      } else {
        const data = await fetch(POINTS_API(defaultChain) + walletAddress + '/' + slug).then((response) => response.json())
        pointData = {
          totalShellPoints: data.totalShellPoints ?? 0,
          forecastedPointsGain: 0,
          compoundedPoints: data.tokens.compoundedPoints?.totalPoints ?? 0,
          boost: 0,
          tokens: data.tokens
        }
        dispatch(updateHistoricalPoints({ address: walletAddress, season: slug, value: JSON.stringify(pointData) }))
      }
      setPointData(pointData)
      setTotalValue(0)
      setShellBalance('0')
      return false
    }

    const currentTime = Math.floor(Date.now() / 1000)

    if (userCurrentPoints[walletAddress!]) {
      const cachedData = userCurrentPoints[walletAddress!]
      const pointData = JSON.parse(cachedData.pointData)

      const elapsedTimeRatio = (currentTime - cachedData.time) / (60 * 60 * 24)
      pointData.totalShellPoints += (pointData.forecastedPointsGain * elapsedTimeRatio)

      const boostData = pointData.boost

      if (userBoosts['Active Boost']) {
        boostData.currentBoost = userBoosts['Active Boost']
      }

      artifacts.forEach((artifact: any, index) => {
        if (artifact.effect?.type == 'Booster') {
          if (boostData.activatedArtifacts[index.toString()]) {
            let boost = boostData.activatedArtifacts[index.toString()]
            boostData.activatedArtifacts[index.toString()].timeRemaining = Math.max(parseFloat(boost.timeRemaining), userBoosts[artifact.name] ?? 0)
            dispatch(updateBoosts({ address: walletAddress, name: boost.name, value: parseFloat(boost.timeRemaining) }))
          } else if (userBoosts[artifact.name]) {
            boostData.activatedArtifacts[index.toString()] = { name: artifact.name, timeRemaining: userBoosts[artifact.name] }
          }
        }
      })

      setPointData(pointData)
      setTotalValue(cachedData.totalValue)
      setShellBalance(cachedData.shellBalance)
      return
    }

    const tokenIDs = shellTokens
      .map((shellToken) => shellToken.name)
      .concat(
        tokens.filter((token) => token.wrapped).map((token) => token.symbol)
      )
      .concat(
        externalDefiTokens
          .filter((token) => token.tokenType == "Shell")
          .map((token) => token.symbol)
      )
      .concat(
        externalTokens
          .filter((token) => token.tokenType == "Shell")
          .map((token) => token.symbol)
      )
      .concat(
        nftCollections
          .filter((collection) => collection.wrapped)
          .map((collection) => collection.symbol)
      );

    const fungibleTokenIDs = tokenIDs.filter(
      (tokenID) => !isNFTCollection(tokenMap[tokenID])
    );

    const oceanFungibles = fungibleTokenIDs.filter((tokenID) => !isExternalToken(tokenMap[tokenID]))
    const oldOceanFungibles = fungibleTokenIDs.filter((tokenID) => isExternalToken(tokenMap[tokenID]))

      const responses = await Promise.allSettled([
        fetch(POINTS_API(defaultChain) + walletAddress + "/now").then((response) => response.json()),
        oldOcean.balanceOfBatch(
            oldOceanFungibles.map((_) => walletAddress),
            oldOceanFungibles.map((tokenID) => tokenMap[tokenID].oceanID)
        ),
        ocean.balanceOfBatch(
          oceanFungibles.map((_) => walletAddress),
          oceanFungibles.map((tokenID) => tokenMap[tokenID].oceanID)
        ),
        staking.balanceOf(walletAddress),
        getNFTs(walletAddress ?? "0xNull", OCEAN_ADDRESS),
        getPrices(),
      ]);
    
      const [data, oldBalanceResponse, balanceResponse, veShellBalance, userOceanNFTs, tokenPrices] = responses.map((result) =>
        result.status === "fulfilled" ? result.value : null // default to null if promise was rejected
      ); 

    const tokenData = data.tokens;
    const fungibleBalances: { [id: string]: number } = {};

    oldBalanceResponse.forEach(
        (balance: any, index: number) =>
          (fungibleBalances[oldOceanFungibles[index]] = balance)
    );

    balanceResponse.forEach(
      (balance: any, index: number) =>
        (fungibleBalances[oceanFungibles[index]] = balance)
    );

    let priceError = Object.keys(tokenPrices).length == 0;

    const diffs = [];
    let newTotalValue = 0;

    tokenIDs.push('veSHELL')
    
    for (let i = 0; i < tokenIDs.length; i++) {
      const tokenID = tokenIDs[i];

      if(!tokenData[tokenID]) continue
      
      if(tokenID == 'veSHELL'){
        tokenData[tokenID].balance = formatUnits(veShellBalance)
        continue
      }

      const token = tokenMap[tokenID];

      if (isNFTCollection(token)) {
        const collectionID = token.symbol;

        const wrappedIDs = WRAPPED_NFT_MAP[collectionID];

        const userWrappedNFTs: any[] = [];
        userOceanNFTs.forEach((userOceanNFT: any) => {
          const oceanID = removeLeadingZeros(
            BigNumber.from(userOceanNFT.tokenId).toHexString()
          );
          if (wrappedIDs[oceanID]){
            if(token.is1155){
                userWrappedNFTs.push({id: parseInt(wrappedIDs[oceanID][0]), balance: userOceanNFT.balance});
            } else {
                userWrappedNFTs.push(parseInt(wrappedIDs[oceanID][0]));
            }
          }
        });

        // Update Redux store with balances

        const chainBalance = userWrappedNFTs.length;

        dispatch(
          addNFTBalance({ collection: collectionID, items: userWrappedNFTs })
        );

        diffs.push(Math.abs(chainBalance - tokenData[collectionID].balance));

        let tokenValue = 0;

        if (token.is1155) {
          for (let item of userWrappedNFTs) {
            tokenValue +=
              tokenPrices[tokenID].find((e: any) => e.id == item.id.toString())
                .price *
              item.balance *
              100;
          }
        } else {
          tokenValue = tokenPrices[tokenID] * chainBalance;
        }

        // const tokenValue = tokenPrices[tokenID] * chainBalance;
        newTotalValue += tokenValue;

        // Add new fields to tokenData
        tokenData[collectionID].balance = chainBalance;
        tokenData[collectionID].value = priceError ? 0 : tokenValue;
      } else {
        // Update Redux store with balances

        const chainBalance = formatUnits(fungibleBalances[tokenID]);
        dispatch(addBalance({ chain: defaultChain.name, address: token.oceanID, amount: chainBalance }));
        
        diffs.push(
          Math.abs(parseFloat(chainBalance) - tokenData[tokenID].balance)
        );

        const tokenValue = tokenPrices[tokenID] * parseFloat(chainBalance);
        newTotalValue += tokenValue;

        // Add new fields to tokenData
        tokenData[tokenID].balance = parseFloat(chainBalance);
        tokenData[tokenID].value = priceError ? 0 : tokenValue;
      }
    }

    setTotalValue(newTotalValue)
    const balanceError = false //diffs.filter((diff) => diff >= 0.001).length > 0

    const boostData = data.boost

    if (userBoosts['Active Boost']) {
      boostData.currentBoost = userBoosts['Active Boost']
    } else {
      dispatch(updateBoosts({ address: walletAddress, name: 'Active Boost', value: parseFloat(boostData.currentBoost) }))
    }

    artifacts.forEach((artifact: any, index) => {
      if (artifact.effect?.type == 'Booster') {

        if (boostData.activatedArtifacts[index.toString()]) {
          let boost = boostData.activatedArtifacts[index.toString()]
          boostData.activatedArtifacts[index.toString()].timeRemaining = Math.max(parseFloat(boost.timeRemaining), userBoosts[artifact.name] ?? 0)
          dispatch(updateBoosts({ address: walletAddress, name: boost.name, value: parseFloat(boost.timeRemaining) }))
        } else if (userBoosts[artifact.name]) {
          boostData.activatedArtifacts[index.toString()] = { name: artifact.name, timeRemaining: userBoosts[artifact.name] }
        }

      }
    })

    const pointData = {
      totalShellPoints: data.totalShellPoints ?? 0,
      forecastedPointsGain: data.next24hGain ?? 0,
      compoundedPoints: seasonFilter.label == 'Season One' ? tokenData.compoundedPoints.totalPoints : 0,
      boost: data.boost,
      tokens: tokenData
    }
    
    let shellBalance = '0'

    try {
        const shell = new Contract(SHELL_ADDRESS, CrabABI, provider)
        shellBalance = formatDisplay(formatUnits(await shell.balanceOf(walletAddress)), 2)
    } catch {}

    tokenData['SHELL'] = { // add SHELL token to the data
      "quota": -1,
      "balance": +shellBalance,
      "rewardAPY": 0,
      "value": tokenPrices['SHELL'],
      "totalPoints": 0,
      "historical": {}
    };

    setPointData(pointData)
    setShellBalance(shellBalance)

    dispatch(updateCurrentPoints({
      address: walletAddress,
      value: {
        time: currentTime,
        pointData: JSON.stringify(pointData),
        totalValue: newTotalValue,
        shellBalance: shellBalance
      }
    }))

    return balanceError || priceError
  }

  const cancellableTimeout = (ms: number): { promise: Promise<void>; cancel: () => void } => {
    let timeoutId: NodeJS.Timeout;
    const promise = new Promise<void>((resolve) => {
      timeoutId = setTimeout(resolve, ms);
    });
    return { promise, cancel: () => clearTimeout(timeoutId) };
  };

  const intervalQuery = async (numIntervals: number, onCancel: { cancelled: boolean, cancel: () => void }): Promise<boolean> => {
    for (let i = 0; i < numIntervals; i++) {
      if (onCancel.cancelled) return false;
      const error = await queryPoints();
      if (error) {
        const { promise, cancel } = cancellableTimeout(20 * 1000);
        onCancel.cancel = cancel;
        await promise;
        if (onCancel.cancelled) return false;
      } else {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const onCancel = { cancelled: false, cancel: () => console.log('Cancellation called') };
  
    const callQueryPoints = async () => {
      setIsLoading(true);
  
      try {
        const error = await queryPoints();
        if (error) {
          await intervalQuery(5, onCancel);
        }
      } catch (error) {
        console.error('Error fetching points:', error);
      } finally {
        if (!onCancel.cancelled) {
          setIsLoading(false);
        }
      }
    };
  
    callQueryPoints();
  
    return () => {
      onCancel.cancelled = true;
      onCancel.cancel();
    };
  }, [walletAddress, isConnected, activeChain, seasonFilter.label]);
  return (
    <>
      <ClaimHeader/>
      <PointsDetails
        totalShellPoints={pointData.totalShellPoints}
        isLoading={isLoading}
        forecastedPointsGain={pointData.forecastedPointsGain}
        compoundedPoints={pointData.compoundedPoints}
        boost={pointData.boost}
        totalValue={totalValue}
        seasonFilter={seasonFilter}
        shellBalance={shellBalance}
        isOwnWallet={true}
        seasonFilters={seasonFilters}
        setSeasonFilter={setSeasonFilter}
        userEpochs={userEpochs}
      />
      <ButtonsContainer>
        <BoxButtonStyled
          onClick={() => {
            navigate({ pathname: walletAddress ? `/leaderboard/${walletAddress}` : '/leaderboard' });
          }}
        >
          <LeaderboardIcon />
          Leaderboard
        </BoxButtonStyled>
        <BoxButtonStyled
          onClick={() => {
            navigate({ pathname: `/history/${walletAddress || 'all'}` });
          }}
        >
          <HistoryIcon />
          History
        </BoxButtonStyled>
      </ButtonsContainer>
      { isLoading || (isConnected && pointData.totalShellPoints > 0) ? (
      <PointsChart
        tokens={validChain ? pointData.tokens : undefined}
        selectedSeasonFilter={seasonFilter.label}
        selectedDateFilter={selectedDateFilter}
        isLoading={isLoading}
      >
        <ChartHeader>
          {seasonFilter.label === CURRENT_SEASON && (
            <DateFiltersContainer>
              {dateFilters.map((item, index) => (
                <DateFilterButton
                  key={index}
                  onClick={() => setSelectedDateFilter(item.label)}
                  active={item.label === selectedDateFilter}
                >
                  {item.label}
                </DateFilterButton>
              ))}
            </DateFiltersContainer>
          )}
        </ChartHeader>
      </PointsChart>
      ) : <EarnShellPointsBoxes />}
      {/* {(!isLoading && pointData.totalShellPoints === 0) || !isConnected ? (  ) : null} */}
      <BoostBoxes>
        {boxes.map((box) => <BoostBox key={box.headerText} {...box} />)}
      </BoostBoxes>
      {seasonFilter.label == CURRENT_SEASON ? (
        <PointsTable tokens={pointData.tokens} walletAddress={walletAddress} pointsLoading={isLoading} />
      ) : (
        <HistoricalPointsTable tokens={pointData.tokens} pointsLoading={isLoading} />
      )}
    </>
  )
};

const View = styled(Box)`
  padding: 20px 20px 40px;

  ${Media.tablet} {
    padding: 16px 8px 16px;
  }
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  padding: 300px 0 300px 0;
`

const BoostBoxes = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 20px;
    ${Media.tablet} {
        flex-direction: column;
      }
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 8px;
    ${Media.tablet} {
        flex-direction: column;
      }
`;

const BoxButtonStyled = styled(BoxButton)`
  flex-grow: 1;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const DateFilterButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 32px;
  background: #1e2239;
  border: 1px solid #292941;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ active }) => (active ? "#FFFFFF" : "#7D7D97")};

  &:hover {
    color: #ffffff;
  }

  ${Media.tablet} {
    width: 42px;
    height: 28px;
  }
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  ${Media.tablet} {
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    padding: 0px;
  }
`;

const SeasonFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateFiltersContainer = styled.div`
  display: flex;
  gap: 6px;
`;

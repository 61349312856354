import React, { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
import styled from "styled-components";
import { reduceString } from "../../utils/reduceString";
import power from "../../assets/icons/power.svg";
import { useAppDispatch } from "../../store/hooks";
import { clearBalances } from "../../store/balancesSlice";
import { clearPoints } from "@/store/pointsSlice";
import { ChooseWalletModal, ConnectionFailedModal } from "./ConnectionModals";
import { Media, breakpoints } from "../../styles";
import { useWidthBreakpoint } from "../../hooks/useWidthBreakpoint";
import { useLocation } from "react-router-dom";
import { useSwitchNetwork, useNetwork } from "wagmi";
import { arbitrum } from "wagmi/chains";

enum ConnectingModals {
  chooseWallet,
  connectionPending,
  connectionFailed,
}

export const Connection = () => {
  const [modal, setModal] = useState<ConnectingModals | null>(null);
  const location = useLocation()
  const appUrl = 'app.shellprotocol.io'
  const isMobile = useWidthBreakpoint(breakpoints.mobile)

  const closeModal = () => setModal(null);

  const { connect, connectors } = useConnect();
  const { address: walletAddress, isConnected, isDisconnected, isConnecting} = useAccount();
  const { data: ensName } = useEnsName({ address: walletAddress, chainId: 1 });
  const { disconnect } = useDisconnect();
  const { chain: activeChain } = useNetwork();

  const metamaskConnector = connectors.find((conn) => conn.name === "MetaMask")!;
  const rabbyConnector = connectors.find((conn) => conn.name === "Rabby")!;

  const dispatch = useAppDispatch();

  const handleMetamaskConnect = async () => {
    setModal(ConnectingModals.connectionPending);
    if (window.ethereum?.isMetaMask) {
        try {
          connect({ connector: metamaskConnector });
        } catch {
          setTroubleshootVisible(true)
        }
    } else {
      window.location.href =
        "https://metamask.app.link/" + appUrl + location.pathname;
    }
    closeModal();
  };

  const handleRabbyConnect = async () => {
    setModal(ConnectingModals.connectionPending);
    if (window.ethereum?.isRabby) {
        try {
          connect({ connector: rabbyConnector });
        } catch {
          setTroubleshootVisible(true)
        }
    } 
    closeModal();
  };

  const handleDisconnect = () => {
    disconnect();
  };
  
  const [connectedWallet, setConnectedWallet] = useState(walletAddress);

  const [troubleshootVisible, setTroubleshootVisible] = useState(false);

  const { switchNetwork } = useSwitchNetwork();

  useEffect(() => {
    const whichChain =
      activeChain?.id === arbitrum.id ? arbitrum.id : activeChain?.id === 8453 ? 8453 : activeChain?.id === 10 ? 10 : activeChain?.id === 1 ? 1 : arbitrum.id;
    if(connectedWallet === undefined) { switchNetwork?.( whichChain ) }
  }, [isConnecting, connectedWallet]);

  useEffect(() => {
    if (isDisconnected) { setConnectedWallet(undefined); }
  }, [isDisconnected]);

  useEffect(() => {
    if (walletAddress !== connectedWallet) {
      dispatch(clearBalances());
      dispatch(clearPoints());
      setConnectedWallet(walletAddress);
    }
  }, [walletAddress]);

  if (troubleshootVisible) {
    return (
      <ConnectionFailedModal
        isVisible={true}
        onClose={closeModal}
        onRecconectClick={() => handleMetamaskConnect()}
        onTryAnotherWalletClick={() => {
            setTroubleshootVisible(false)
            setModal(ConnectingModals.chooseWallet)
        }}
      />
    );
  }

//   if (isConnecting && modal != null) {
//     return <ConnectionPendingModal isVisible={true} onClose={closeModal} />;
//   }

  if (!isConnected) {
    return (
      <>
        <ConnectButton data-testid="connect-wallet-btn" onClick={() => setModal(ConnectingModals.chooseWallet)} >
          Connect Wallet
        </ConnectButton>
        <ChooseWalletModal
          data-testid="choose-wallet-modal"
          isVisible={modal === ConnectingModals.chooseWallet}
          onClose={closeModal}
          onMetamaskClick={() => handleMetamaskConnect()}
          onRabbyClick={() => handleRabbyConnect()}
          onWalletConnectClick={() => setModal(ConnectingModals.connectionPending)}
        />
      </>
    );
  } else {
    let displayValue;

    if (walletAddress !== undefined) {
      displayValue = ensName ? `${ensName}` : isMobile ? reduceString(walletAddress, 4, 0) : reduceString(walletAddress, 6, 4);
    }

    return (
      <DisconnectButton data-testid="disconnect-btn" onClick={() => handleDisconnect()}>
        {displayValue}
        <PowerImage src={power} alt="Disconnect" />
      </DisconnectButton>
    );
  }
};

const Button = styled.button`
  height: 60px;

  ${Media.tablet} {
    height: 50px;
  }
`;

const ConnectButton = styled(Button)`
  padding: 18px 28px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0a0e27;

  ${Media.tablet} {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const DisconnectButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px 28px;
  background: transparent;
  border: 1px solid #37dcf2;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-variant-ligatures: none;

  ${Media.tablet} {
    padding: 8px 20px;
  }
`;

const PowerImage = styled.img`
  margin-left: 12px;
`;

import { Spinner } from "@/components/Loaders";
import { TransactionTable } from "@/components/Table/TransactionTable/TransactionTable";
import { TransactionTale } from "@/components/Table/TransactionTable/TransactionTale";
import { HISTORY_API } from "@/constants/urls";
import { useWidthBreakpoint } from "@/hooks";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import { addTransactionCount, addTransaction } from "@/store/transactionsSlice";
import { breakpoints, Media } from "@/styles";
import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { buildTransactions } from "../Transactions/TransactionHistory";
import { ChainContext } from "@/components/Overlays/ChainProvider";

export const skipInterval = 10; // Number of transactions fetched at a time
export const pageSize = 5; // Number of transactions displayed at a time

export const PoolHistory = ({poolName} : any) => {
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const { connectedChain } = useContext(ChainContext)

  const [transactionCount, setTransactionCount] = useState(0) 
  const [transactionSkip, setTransactionSkip] = useState(0) 
  const [transactions, setTransactions] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState('1') 
  
  const storedTxs = useAppSelector(state => state.transactions.transactions[connectedChain.name])
  const storedTxCounts = useAppSelector(state => state.transactions.transactionCounts[connectedChain.name])
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);

  const queryTransactions = async () => {
    const apiData = await fetch(`${HISTORY_API(connectedChain)}pool/${poolName}/${transactionSkip}`).then((response) => response.json());      
    const transactionData: any = apiData.transactions.data;
    const transactionHashes: string[] = Object.keys(transactionData);
    const newTransactions = buildTransactions(transactionData, transactionHashes);

    dispatch(addTransactionCount({ chain: connectedChain.name, address: poolName, count: apiData.transactions.count }));
    dispatch(addTransaction({ chain: connectedChain.name, address: poolName, skip: transactionSkip, data: newTransactions }));

    setTransactions(newTransactions.slice(0, pageSize));
    setTransactionCount(apiData.transactions.count);
  }

  const handlePageNavigation = (increment: boolean = true) => {
    let newPage = parseInt(currentPage) + (increment ? 1 : -1);
    newPage = Math.max(1, Math.min(newPage, Math.ceil(transactionCount / pageSize)));
    setCurrentPage(newPage.toString());
  
    const reduxSkipIndex = Math.floor(((newPage - 1) * pageSize) / 10);
    const withinBatchIndex = ((newPage - 1) * pageSize) % 10;

    if (storedTxs[poolName] && storedTxs[poolName][reduxSkipIndex]) {
      const transactionsToDisplay = storedTxs[poolName][reduxSkipIndex].slice(withinBatchIndex, withinBatchIndex + pageSize);
      setTransactions(transactionsToDisplay);
    } else {
      setTransactionSkip(reduxSkipIndex);
    }
  };
  
  useEffect(() => {
    if (storedTxs[poolName!] && storedTxs[poolName!][transactionSkip] && storedTxCounts[poolName!]) {
      setTransactions((storedTxs[poolName!][transactionSkip] as any[]).slice(transactions.length ? -pageSize : 0, pageSize));
      setTransactionCount(storedTxCounts[poolName!])
      setLoading(false)
    } else {
      setLoading(true);
      queryTransactions().then(() => {
        setLoading(false)
      }).catch((_) => {
        setLoading(false)
      })
    }
  }, [transactionSkip])

  const handlePageInputFocus = () => {
    setCurrentPage('');
  }
  
  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(e.target.value);
  }
  
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };
  
  const handlePageInputBlur = () => {
    let newPageNum = parseInt(currentPage);
    if (!newPageNum || isNaN(newPageNum)) {
      newPageNum = 1;
    } else {
      newPageNum = Math.max(1, Math.min(newPageNum, Math.ceil(transactionCount / pageSize)));
    }
    setCurrentPage(newPageNum.toString());
    const reduxSkipIndex = Math.floor(((newPageNum - 1) * pageSize) / skipInterval);
    if (storedTxs[poolName] && storedTxs[poolName][reduxSkipIndex]) {
      const withinBatchIndex = ((newPageNum - 1) * pageSize) % skipInterval;
      const transactionsToDisplay = storedTxs[poolName][reduxSkipIndex].slice(withinBatchIndex, withinBatchIndex + pageSize);
      setTransactions(transactionsToDisplay);
    } else {
      setTransactionSkip(reduxSkipIndex);
    }
  }
  
  return (
    (!loading ? transactions.length > 0 ?
        <TableContainer>
          {isTablet ? 
              <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                  {transactions.map((transaction) => <TransactionTale transaction={transaction} />)}
              </div>
              :
              <TransactionTable
                  data={transactions}
              />
          }
          <ButtonRow>
            <DirectionButton 
              disabled={currentPage === '0'} 
              onClick={() => handlePageNavigation(false)}
            >{'\u2190'}</DirectionButton>
            <PageRow>
                {!isTablet && 
                    <PageInput
                        value={currentPage}
                        onFocus={handlePageInputFocus}
                        onChange={handlePageInputChange}
                        onBlur={handlePageInputBlur}
                        onKeyDown={handleKeyPress}
                        count={transactionCount}
                    />
                }
                <p>{`${isTablet ? currentPage : ''} of ${Math.ceil(Math.max(transactionCount, pageSize) / pageSize)}`}</p>
            </PageRow>
            <DirectionButton 
              disabled={currentPage === Math.ceil(Math.max(transactionCount, pageSize) / pageSize).toString()} 
              onClick={() => handlePageNavigation()}
            >{'\u2192'}
            </DirectionButton>
          </ButtonRow>
        </TableContainer> : <></>
        : 
        <CircleContainer>
          <Spinner/>
        </CircleContainer>
      )
  );
};

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid #171b33;
  border-radius: 20px;
  background-color: #0a0e27;

  ${Media.tablet} {
    padding: 16px;
  }
`;

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: 156px auto;
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  margin-top: 8px;
`;

const PageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const inputTextStyles = css`
  font-size: 16px;
  line-height: 19px;
`

const placeholderStyles = css`
  ${inputTextStyles}
`

export const PageInput = styled.input<{ count: number }>`
  color: #FFFFFF;
  text-align: center;
  border: none;
  background: transparent;
  outline: none;
  font-weight: 100;
  width: 36px;

  ${inputTextStyles}

  &:focus {
    border-color: #FFFFFF;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }

  ${({ count }) => count < 10 * pageSize && css`
    width: 16px;
  `};

  ${({ count }) => count >= 10 * pageSize && count < 100 * pageSize && css`
    width: 24px;
  `};
`;

const DirectionButton = styled.button`
  width: 34px;
  height: 28px;
  font-weight: 100;
  font-size: 16px;
  line-height: 22px;
  border-radius: 20px;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #37dcf2;

  &:not(:disabled):hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    color: #0a0e27;
  }

  &:disabled {
    border: 1px solid #464659;
    color: #464659;
    cursor: auto;
  }

  ${Media.tablet} {
    opacity: 1;
    border: none !important;
    background: transparent !important;

    &:not(:disabled):hover {
      color: #FFFFFF;
    }
  }
`;
import { Content } from "@/components/Layout/Content"
import React, { useEffect } from "react"
import { useLocation } from "react-router";
import { Outlet, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { RewardsContextProvider } from "./RewardsContext";
import * as S from "./RewardsScreenStyled";

export const RewardsScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const REWARDS_SUBPAGES = [
        {
            to: "/rewards/shell",
            label: "SHELL",
        },
        {
            to: "/rewards/airdrop",
            label: "Airdrop",
        },
        {
            to: "/rewards/vesting",
            label: "Vesting",
        },
        {
            to: "/rewards/staking",
            label: "Staking",
        },
        {
            to: "/rewards/stip",
            label: "STIP",
        }
    ];

    useEffect(() => {
        if (location.pathname === "/rewards") {
            navigate("shell");
        }
    }, [])

    return (
        <RewardsContextProvider>
            <Content>
                <S.View>
                    <S.HeadWrapper>
                        <S.TitleWrapper>
                            <S.Title>Rewards</S.Title>
                            <S.Description>Earn SHELL, claim your airdrops, and stake to earn yield</S.Description>
                        </S.TitleWrapper>
                        <S.NavWrapper>
                            {REWARDS_SUBPAGES.map(({ to, label }) => (
                                <S.NavItem isActiveTab={location.pathname.includes(label.toLowerCase())}>
                                    <Link to={to}>
                                        {label}
                                    </Link>
                                </S.NavItem>
                            ))}
                        </S.NavWrapper>
                    </S.HeadWrapper>
                    <Outlet />
                </S.View>
            </Content>
        </RewardsContextProvider>
    )
};
import React from 'react';
import styled from 'styled-components';
import activeDot from "@/assets/icons/shellGuide/steper-active-ball.svg";
import dot from "@/assets/icons/shellGuide/steper-ball.svg";
import arrow from "@/assets/icons/shellGuide/steper-arrow.svg";
import { Media } from '@/styles';

interface SteperProps {
    totalSteps: number;
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};

export const Steper = ({ totalSteps, currentStep, setCurrentStep }: SteperProps) => {

    return (
        <SteperContainer>
            {Array.from({ length: totalSteps }, (_, i) => (
                <>
                    {i !== 0 && (
                        <StepArrow src={arrow} alt="arrow" />
                    )}
                    {i === currentStep ? (
                        <StepActiveDot src={activeDot} alt="dot-active" />
                    ) : (
                        <StepDotWrapper onClick={() => setCurrentStep(i)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" stroke="#171B33" />
                                <circle cx="6" cy="6" r="4" fill="#171B33" />
                            </svg>
                        </StepDotWrapper>
                    )}
                </>
            ))}
        </SteperContainer>
    )
};

const SteperContainer = styled.div`
border-radius: 10px;
border: 1px solid var(--dark-2, #171B33);
background: var(--dark-1, #0A0E27);
padding: 12px 16px;
min-height: 51px;
display: flex;
gap: 12px;
justify-content: flex-start;
align-items: center;

${Media.mobile} {
    padding: 6px 8px;
    min-height: unset;
}
`;

const StepDot = styled.img`
width: 12px;
height: 12px;
`;

const StepDotWrapper = styled.div`
display: flex;
padding: 4px;
margin-left: -4px;
margin-right: -4px;
justify-content: center;
align-items: center;
cursor: pointer;

&:hover {
    svg {
        rect {
            stroke: #7D7D97;
        }
        circle {
            fill: #7D7D97;
        
        }
    }
}
`;

const StepActiveDot = styled.img`
width: 12px;
height: 12px;
`;

const StepArrow = styled.img`
width: 12px;
height: 12px;
`;
import { AddressZero } from '@ethersproject/constants';
import ethereumChain from '../assets/coins/ethereum.svg';
import optimismChain from '../assets/coins/optimism.svg';
import baseChain from '../assets/coins/base.svg';
import polygonChain from '../assets/coins/polygon.svg';
import arbitrumChain from '../assets/icons/arbitrum.svg';

export interface Chain {
    name: string;
    rpcPrefix: string;
    chainId: number;
    api: string;
    tokens?: {[id: string]: string}
    rpcUrl?: string;
    explorerUrl?: string; 
    bridge?: string;
    bridgeForwarder?: string;
    icon?: string;
    color: string;
}

export const allChains: {[name: string] : Chain} = {
    'Arbitrum One': {
        name: 'Arbitrum One',
        rpcPrefix: 'arb-mainnet',
        chainId: 42161,
        api: 'arbitrum-one',
        tokens: {
            'ETH': AddressZero,
            'USDC': '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        },
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        explorerUrl: 'https://arbiscan.io/',
        bridge: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
        bridgeForwarder: '0x653652D7060c1A878035e5f24E77644ccF09bf04',
        icon: arbitrumChain,
        color: '#4EA7F8',
    },
    'Base': {
        name: 'Base',
        rpcPrefix: 'base-mainnet',
        chainId: 8453,
        api: 'base',
        tokens: {
            'ETH': AddressZero,
            'USDC': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        },
        rpcUrl: 'https://mainnet.base.org',
        explorerUrl: 'https://basescan.org/',
        bridge: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
        bridgeForwarder: '0xd5A7eDB913eae552e341b3bA0704CB2F6971BF7b',
        icon: baseChain,
        color: '#1B3AF5'
    },
    'Optimism': {
        name: 'Optimism',
        rpcPrefix: 'opt-mainnet',
        chainId: 10,
        api: 'optimism',
        tokens: {
            'ETH': AddressZero,
            'USDC': '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        },
        rpcUrl: 'https://mainnet.optimism.io/',
        explorerUrl: 'https://optimistic.etherscan.io/',
        bridge: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
        bridgeForwarder: '0xB1C6626857F4D4aC6b030FB779EE89bAb3A377AC',
        icon: optimismChain,
        color: '#EA3431'
    },
    'Ethereum': {
        name: 'Ethereum',
        rpcPrefix: 'eth-mainnet',
        chainId: 1,
        api: 'ethereum',
        tokens: {
            'ETH': AddressZero,
            'USDC': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
        },
        rpcUrl: 'https://mainnet.infura.io/v3/',
        explorerUrl: 'https://etherscan.io/',
        bridge: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
        bridgeForwarder: '0xe38f74d8e81b5a6b61ef4120db6a48956329e702',
        icon: ethereumChain,
        color: '#627EEA'
    },
    // 'Polygon': {
    //     rpcPrefix: 'polygon-mainnet',
    //     chainId: 137,
    //     tokens: {
    //         'ETH': {from: AddressZero, to: 'ETH'},
    //         'USDC': {from: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', to: 'USDC.e'},
    //     },
    //     rpcUrl: 'https://polygon-rpc.com/',
    //     explorerUrl: 'https://polygonscan.io/',
    //     bridge: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
    //     icon: 'https://alchemy.mypinata.cloud/ipfs/QmRNqgazYuxUa5WdddFPftTWiP3KwzBMgV9Z19QWnLMETc'
    // }
}

export const defaultChain: Chain = Object.values(allChains)[0]

export const bridgeOutputs: {[name: string]: {[name: string] : string}} = {
    'ETH': {
        'Arbitrum One': 'ETH',
        'Base': 'ETH',
        'Optimism': 'ETH',
        'Ethereum': 'ETH'
    },
    'USDC': {
        'Arbitrum One': 'USDC.e',
        'Base': 'USDC',
        'Optimism': 'USDC.e',
        'Ethereum': 'USDC'
    }
}
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Area, ReferenceDot } from "recharts";
import { addDays, addMonths, addWeeks, differenceInMonths } from "date-fns";
import { AreaChartWithPredicted } from "../../components/Charts/AreaChartWithPredicted";
import { tokenColors } from "@/constants/tokenColors";
import { formatDisplay } from "@/utils/formatDisplay";
import { ChartHeader } from "../LBP/ChartHeader";
import { Media } from "@/styles";
import { dateFilters } from "@/types/ChartTypes";
import { getFormattedChartDate, getFormattedChartDayDate } from "@/utils";
import { StatsTooltip } from "./StatsTooltip";

export const StatsChart = ({ title, token, data, type }: any) => {

  const tokenColor = type == 'price' ? tokenColors[token.symbol] : '#7adeb9';
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>("All");
  const [chartData, setChartData] = useState(data);

  const prefix = type == "price" ? "$" : "";
  const suffix = type == "APY" ? "%" : "";

  const addPeriod = (
    date: Date,
    period: "day" | "week" | "month" | "all",
    totalDuration: number
  ): Date => {
    switch (period) {
      case "day":
        return addDays(date, 1);
      case "week":
        return addWeeks(date, 1);
      case "month":
        return addMonths(date, 1);
      case "all":
        if(totalDuration > 2){
            return addMonths(date, totalDuration > 6 ? 2 : 1)
        } else {
            return addWeeks(date, 1);
        }
      default:
        return date;
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    let startDate: Date;

    const selectedDate = dateFilters.find(
      (filter) => filter.label === selectedDateFilter
    )!;

    if (selectedDate.getStart) {
      startDate = selectedDate.getStart(currentDate);
    } else {
      startDate = new Date(
        Math.min(...data.map((entry: any) => entry.date * 1000))
      );
    }

    const totalDuration = differenceInMonths(currentDate, startDate)

    const filteredChartData = data.filter((entry: any, index: number) => {
      const entryDate = new Date(entry.date * 1000);
      if (
        entryDate.getTime() >= startDate.getTime() ||
        index == data.length - 1
      ) {
        startDate = addPeriod(startDate, selectedDate.period, totalDuration);
        return true;
      } else {
        return false;
      }
    });

    setChartData(filteredChartData);
  }, [selectedDateFilter]);

  const todayData = chartData.at(-1);

  return (
    <AreaChartWithPredicted
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ChartHeader
            title={title}
            amount={`${prefix}${formatDisplay(todayData.value, 2)}${suffix}`}
          />
          <FiltersContainer>
            <DateFiltersContainer>
              {dateFilters.map((item, index) => (
                <DateFilterButton
                  key={index}
                  onClick={() => setSelectedDateFilter(item.label)}
                  active={item.label === selectedDateFilter}
                >
                  {item.label}
                </DateFilterButton>
              ))}
            </DateFiltersContainer>
          </FiltersContainer>
        </div>
      }
      tooltip={<StatsTooltip type={type}/>}
      allowDuplicatedCategory={false}
      todayData={todayData}
      tokenColor={tokenColor}
      prefixTick={prefix}
      suffixTick={suffix}
      withoutLegend={true}
      xAxisFormat={
        selectedDateFilter == "All"
          ? getFormattedChartDate
          : getFormattedChartDayDate
      }
      areas={
        <>
          <Area
            key="pastData"
            type="monotone"
            data={chartData}
            dataKey="value"
            stroke={tokenColor}
            fillOpacity={0}
            fill={`transparent`}
            data-area-test={"pastData"}
            activeDot={{ stroke: tokenColor, fill: "#0A0E27" }}
            stackId="1"
            strokeWidth={2}
          />
          <ReferenceDot
            x={todayData.date}
            y={todayData.value}
            fill={tokenColor}
            stroke={tokenColor}
            r={3}
          />
          <Area
            key="data"
            type="monotone"
            data={chartData}
            dataKey="value"
            stroke="transparent"
            fillOpacity={1}
            fill={`url(#data)`}
            data-area-test="data"
            stackId="1"
          />
        </>
      }
    />
  );
};

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;

  ${Media.tablet} {
    align-items: start;
    gap: 8px;
    margin-right: 12px;
  }
`;

const DateFiltersContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const DateFilterButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 32px;
  background: #1e2239;
  border: 1px solid #292941;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ active }) => (active ? "#FFFFFF" : "#7D7D97")};

  &:hover {
    color: #ffffff;
  }

  ${Media.tablet} {
    width: 42px;
    height: 28px;
  }
`;

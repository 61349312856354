import React from "react";
import confirmationLoader from "@/assets/icons/confirmation-loader.svg";
import { Input } from "@/components/Input/Input";
import infoIcon from "@/assets/icons/info-icon.svg";
import successIcon from "@/assets/icons/success-modal-icon.svg";
import * as S from "./ConfirmationModals.styled";
import { useRewardsContext } from "../../RewardsContext";
import shellIcon from '@/assets/coins/SHELL.svg'
import { formatDisplay } from "@/utils/formatDisplay";

const InputCustomLabel = ({token}: {token: {icon: string, symbol: string, name: string}}) => (
    <S.CustomInputLabelContainer>
        <S.CoinIcon src={token.icon} alt="shell coin" />
        <S.CustomInputLabelTokenName>
            <S.CustomInputLabelTokenName>
                <S.InputTokenName>{token.symbol}</S.InputTokenName>
                <S.InputTokenSymbol>{token.name}</S.InputTokenSymbol>
            </S.CustomInputLabelTokenName>
        </S.CustomInputLabelTokenName>
    </S.CustomInputLabelContainer>
);

export const GaugeClaimConfirmationModalBody = () => {
    const {
        gaugeClaimAmount,
        isGaugeClaimSuccess,
        gaugeClaimTxSubmitted,
        closeGaugeClaimModal,
        stipRewardToken
    } = useRewardsContext();
    return (
        <S.ConfirmationBodyContainer hasMask={isGaugeClaimSuccess}>
            {isGaugeClaimSuccess ? (
                <>
                    <S.SuccessIcon src={successIcon} alt="success icon" />
                    <S.SuccessTextWrapper>
                        <S.SuccessTitle>Claim successful!</S.SuccessTitle>
                        <S.SuccessDescription>
                        Your 
                        <span> {formatDisplay(gaugeClaimAmount.toString(), 2)} </span>
                        {stipRewardToken.symbol} has been successfully claimed
                        </S.SuccessDescription>
                    </S.SuccessTextWrapper>
                    <Input label="Rewards" disabled value={formatDisplay(gaugeClaimAmount.toString())} additionalLabel={<InputCustomLabel token={stipRewardToken} />} />
                    <S.CustomPrimaryBtn style={{marginTop: "16px"}} onClick={closeGaugeClaimModal}>Close</S.CustomPrimaryBtn>
                </>
            ) : (
                <>
                    <S.ConfirmationLoader src={confirmationLoader} alt="loader" />
                    <S.LoaderLabelContainer>
                        <S.LoaderLabel>
                            Claiming your {stipRewardToken.symbol}...
                        </S.LoaderLabel>
                        <S.LoaderSublabel>
                            You're claiming {formatDisplay(gaugeClaimAmount.toString(), 2)} {stipRewardToken.symbol}
                        </S.LoaderSublabel>
                    </S.LoaderLabelContainer>
                    <Input label="Rewards" disabled value={formatDisplay(gaugeClaimAmount.toString())} additionalLabel={<InputCustomLabel token={stipRewardToken} />} />
                    {!gaugeClaimTxSubmitted && 
                    <S.InfoLabelContainer>
                        <S.InfoLabelIcon src={infoIcon} alt="info icon" />
                        <S.InfoLabel>Confirm the transaction in your wallet.</S.InfoLabel>
                    </S.InfoLabelContainer>
                    }
                </>
            )}
        </S.ConfirmationBodyContainer >
    )
};
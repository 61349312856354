import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { CloseButton, Modal } from "../Modal/Modal";
import { List, ListItem, SearchContainer, Title, TokensModalHeader } from "../TokensModal/TokensModal";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import cross from "../../assets/icons/cross.svg";
import { NFT, VestingStream } from "../../utils/tokens";
import { ButtonPrimary, ButtonSecondary } from "../Buttons/Button";
import arrowUpRight from "@/assets/icons/arrow-up-right.svg";
import list from "@/assets/icons/list.svg";
import grid from "@/assets/icons/grid.svg";
import { SearchInput } from "../TokensModal/SearchInput";
import { FiltersContainer } from "@/pages/Trade/NFTsSwiper/NFTSelectionModal";
import { FilterDropdown, NFTFilter } from "./FilterDropdown";
import { Spinner, StraightLoader } from "../Loaders";
import { useSearch } from "@/hooks/useSearch";
import { useNFTSort } from "@/hooks/useNFTSort";
import { CollectionButton } from "./CollectionButton";
import { scrollbar } from "@/styles/scrollbar";
import { CollectionGridItem } from "./CollectionGridItem";
import { Media, breakpoints } from "@/styles";
import { WRAPPED_NFT_MAP, getNFTs, getNFTsData, multicall } from "@/utils/nftHelpers";
import { StreamABI } from "@/constants/ABI/StreamABI";
import { VestingABI } from "@/constants/ABI/VestingABI";
import { OCEAN_ADDRESS, STREAM_ADDRESS, VESTING_ADDRESS } from "@/constants/addresses";
import { ContractCallContext, ContractCallResults } from "ethereum-multicall";
import { BigNumber, Contract } from "ethers";
import { formatEther } from "ethers/lib/utils.js";
import { useAccount, useProvider } from "wagmi";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { updateEndTime } from "@/store/vestingSlice";
import { OceanABI } from "@/constants/ABI/OceanABI";
import { removeLeadingZeros } from "@/utils/ocean/utils";
import { useWidthBreakpoint } from "@/hooks";

interface InsideVestingStreamsProps {
  selectedCollection: any
  title: string;
  onClose: () => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onConfirm: (streams :VestingStream[]) => void;
  isInputToken: boolean;
}

export const InsideVestingStreams = ({
  selectedCollection,
  title,
  onClose,
  showModal,
  setShowModal,
  onConfirm,
  isInputToken
}: InsideVestingStreamsProps) => {

  const { address: walletAddress, isConnected } = useAccount();

  const isMobile = useWidthBreakpoint(breakpoints.mobile)

  const provider = useProvider();
  const wrappedIDs = WRAPPED_NFT_MAP["sh" + selectedCollection.symbol];
  const lowLiquidity = true;

  const [searchValue, setSearchValue] = useState("");
  const [viewType, setViewType] = useState<"list" | "grid">("list");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nfts, setNFTs] = useState<VestingStream[]>([]);

  const [bottomLoading, setBottomLoading] = useState(false);
  const [hasMoreBottom, setHasMoreBottom] = useState(true);
  const [selectedItems, setSelectedItems] = useState<NFT[]>([]);

  const [showSlider, setShowSlider] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);

  const vestingEndTime = useAppSelector((state) => state.vesting.endTime);
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);
  const dispatch = useAppDispatch();

  const [selectedFilter, setSelectedFilter] = useState<NFTFilter>({
    order: null,
    orderBy: null,
    label: "Sort by",
  });

  const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array];
  
    for (let i = shuffledArray?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
  
    return shuffledArray;
  }
  
  const generateChunks = (nftIDs: string[]) => {
    const numChunks = 50;
    const sublistSize = Math.ceil(nftIDs?.length / numChunks);
  
    const chunks = Array(numChunks)
      .fill([])
      .map((_, i) => nftIDs.slice(i * sublistSize, (i + 1) * sublistSize));
  
    return chunks;
  };

  const initChunks = shuffleArray(generateChunks(Object.keys(wrappedIDs)));
  const [idChunks, setIdChunks] = useState<any[]>(initChunks);

  const filteredCollectionItems = useSearch(nfts, ["id"], searchValue);

  const sortedNFTs = useNFTSort(
    filteredCollectionItems,
    selectedFilter.order,
    selectedFilter.orderBy
  ) as VestingStream[];

  const isItemSelected = (itemId: number) =>
    selectedItems && selectedItems.map(({ id }) => id).includes(itemId);

  const onClickItem = (item: VestingStream) => {
    const newSelectedItems = isItemSelected(item.id)
      ? selectedItems.filter(({ id }) => id !== item.id)
      : [...selectedItems, item];
    const newSliderValue = showSlider
      ? isItemSelected(item.id)
        ? sliderValue - 1
        : sliderValue + 1
      : sliderValue;
    setSelectedItems(newSelectedItems);
    setSliderValue(newSliderValue);
    // if (showSlider && selectedCollection && onNFTSweepSelect) {
    //   onNFTSweepSelect(selectedCollection.symbol, newSelectedItems);
    // }
  };

  const handleFilterChange = (filter: any) => {
    setSelectedFilter(filter);
    setSelectedItems([]);

    if (!isInputToken && !lowLiquidity) {

      let newChunks: any;  
      if (filter.orderBy == "id") {
          newChunks = generateChunks(
          filter.order == "asc"
              ? Object.keys(wrappedIDs)
              : Object.keys(wrappedIDs).reverse()
          );
      }  
      setIdChunks(newChunks);
      setIsLoading(true);
      getFractionalizedNFTs(newChunks).then((newFractionalizedNFTS) => {
          setNFTs(newFractionalizedNFTS);
          setIsLoading(false);
      });
      
      setSliderValue(1);
      setShowSlider(false);

    }
  };

  const toggleModal = () => {
    setShowModal(false);
    setSearchValue("");
  };

  const handleScroll = (e: any) => {
    if (Math.floor(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight) {
      if (!isInputToken && hasMoreBottom) loadMoreBottom();
    }
  };

  const loadMoreBottom = () => {
    if (!bottomLoading) {
      setBottomLoading(true);
      getFractionalizedNFTs(idChunks).then((newFractionalizedNFTS: VestingStream[]) => {
        setNFTs(nfts.concat(newFractionalizedNFTS));
        setBottomLoading(false);
      });
    }
  };

  const getTimeUntilEnd = (startTime: number) => {

        function getTimestampTwoYearsLater(timestampInSeconds: number) {
          const milliseconds = timestampInSeconds * 1000;
          const date = new Date(milliseconds);
      
          date.setFullYear(date.getFullYear() + 2);
      
          return date.getTime() / 1000;
        }
    
        const endTime = getTimestampTwoYearsLater(startTime)
    
        const diffInSecs = endTime - new Date().getTime() / 1000;

        if(diffInSecs <= 0) return '0Y 0M 0D'
    
        // Calculate seconds in a day, month, and year
        const secsInDay = 24 * 60 * 60;
        const secsInMonth = secsInDay * 30.436875; // Average days in a month
        const secsInYear = secsInDay * 365.2425; // Average days in a year
    
        // Calculate the difference in years
        const years = Math.floor(diffInSecs / secsInYear);
        let remainingSecs = diffInSecs - years * secsInYear;
    
        // Calculate the difference in months
        const months = Math.floor(remainingSecs / secsInMonth);
        remainingSecs -= months * secsInMonth;
    
        // Calculate the difference in days
        const days = Math.floor(remainingSecs / secsInDay);

        if(diffInSecs > 0 && years == 0 && months == 0 && days == 0){
            return `<${years}Y ${months}M 1D`
        }
    
        const formattedDuration = `${years}Y ${months}M ${days}D`;
        return formattedDuration;
  }

  const getFractionalizedNFTs = async (chunks: string[][]) => {

    let oceanAddress
    let fractionalizer: string

    oceanAddress = OCEAN_ADDRESS
    fractionalizer = selectedCollection.fractionalizer

    const ocean = new Contract(oceanAddress, OceanABI, provider);

    let newFractionalizedNFTs: any[] = [];

    if (lowLiquidity) {
      const oceanNFTs = (
        await getNFTs(fractionalizer, oceanAddress)
      ).filter((token) => token.balance == 1);
      const streams = await getNFTs(oceanAddress, STREAM_ADDRESS)
      oceanNFTs.forEach((oceanNFT: any) => {
        const oceanID = removeLeadingZeros(
          BigNumber.from(oceanNFT.tokenId).toHexString()
        );
        if (wrappedIDs[oceanID]){
            const stream = streams.find((stream) => parseInt(stream.tokenId) == wrappedIDs[oceanID][0])
            newFractionalizedNFTs.push(stream);
        }
      });
      setHasMoreBottom(false);
    } else {
      const nftIDs: number[] = []
      while (chunks?.length) {
        const chunk = chunks.shift()!;
        const balances = await ocean.balanceOfBatch(
          chunk.map(() => fractionalizer),
          chunk
        );
        for (let i = 0; i < chunk?.length; i++) {
          if (!balances[i].isZero()) {
            nftIDs.push(wrappedIDs[chunk[i]][0]);
          }
        }

        if (nftIDs?.length > 20) {
          break;
        }
      }

      newFractionalizedNFTs = await getNFTsData(STREAM_ADDRESS, nftIDs)

      setIdChunks(chunks);
      setHasMoreBottom(chunks?.length > 0);
    }
    return newFractionalizedNFTs;
  };

  const fetchStreams = async () => {
         
    const nfts = isInputToken ? await getNFTs(walletAddress, STREAM_ADDRESS) : await getFractionalizedNFTs(idChunks);
    const streamIds = nfts.map((token) => token.tokenId);
    const images = nfts.map((token) => token.image.originalUrl);

    const contractCallContext: ContractCallContext[] = [
      {
        reference: "Vesting",
        contractAddress: VESTING_ADDRESS,
        abi: VestingABI as any,
        calls: [
          {
            reference: "vestingStartTime",
            methodName: "vestingStartTime",
            methodParameters: [],
          },
          {
            reference: "admin",
            methodName: "admin",
            methodParameters: [],
          },
        ],
      },
      {
        reference: "Claimable",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "withdrawableAmountOf",
            methodName: "withdrawableAmountOf",
            methodParameters: [id],
          };
        }),
      },
      {
        reference: "Deposited",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "getDepositedAmount",
            methodName: "getDepositedAmount",
            methodParameters: [id],
          };
        }),
      },
      {
        reference: "Vested",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "streamedAmountOf",
            methodName: "streamedAmountOf",
            methodParameters: [id],
          };
        }),
      },
      {
        reference: "Claimed",
        contractAddress: STREAM_ADDRESS,
        abi: StreamABI as any,
        calls: streamIds.map((id) => {
          return {
            reference: "getWithdrawnAmount",
            methodName: "getWithdrawnAmount",
            methodParameters: [id],
          };
        }),
      },
    ];

    const results: ContractCallResults = await multicall.call(
      contractCallContext
    );

    dispatch(updateEndTime({
        data: getTimeUntilEnd(BigNumber.from(
            results.results["Vesting"].callsReturnContext[0].returnValues[0]
            ).toNumber()
        )
    }))
    const admin = results.results['Vesting'].callsReturnContext[1].returnValues[0].toLowerCase()

    const streams: VestingStream[] = []

    nfts.forEach((nft, index) => {
        if(nft.raw.metadata.attributes[1].value.toLowerCase() == admin){
            streams.push({
              id: parseInt(streamIds[index]),
              name: 'Shellstream',
              symbol: 'STREAM',
              wrapped: false,
              rarity: 0,
              image: images[index],
              claimable: formatEther(
                BigNumber.from(
                  results.results["Claimable"].callsReturnContext[index]
                    .returnValues[0]
                ).toBigInt()
              ),
              vesting: formatEther(
                BigNumber.from(
                  results.results["Deposited"].callsReturnContext[index]
                    .returnValues[0]
                ).toBigInt() -
                  BigNumber.from(
                    results.results["Vested"].callsReturnContext[index]
                      .returnValues[0]
                  ).toBigInt()
              ),
              vested: formatEther(
                BigNumber.from(
                  results.results["Vested"].callsReturnContext[index].returnValues[0]
                ).toBigInt()
              ),
              claimed: formatEther(
                BigNumber.from(
                  results.results["Claimed"].callsReturnContext[index].returnValues[0]
                ).toBigInt()
              )
            })
        }
    })

    return streams
  };

  useEffect(() => {
    setIsAllSelected(
      selectedItems?.length > 0 && selectedItems?.length == sortedNFTs?.length
    );
  }, [selectedItems, nfts]);

  useEffect(() => {
    let _isMounted = true;
    if (_isMounted) {
      setIdChunks(initChunks);
      setSelectedItems([])
      setIsLoading(true);
    }
    fetchStreams()
      .then((streams) => {
        if (_isMounted) {
          setNFTs(streams);
          setIsLoading(false);
        }
      })
      .catch((_) => {
        if (_isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      _isMounted = false;
    };
  }, [walletAddress, isInputToken, userNFTBalances[selectedCollection.symbol]]);

  return (
    <StyledModal
      isVisible={showModal}
      onClose={() => { onClose && onClose(); toggleModal(); }}
      header={
          <HeaderWrapper>
            <Description>
              {selectedCollection.description}
              <a href={"https://wiki.shellprotocol.io/shell-dao/shell-token/sablier-vesting-stream-shell"} target="_blank"> Learn more</a>
            </Description>
          </HeaderWrapper>
      }
      title={selectedItems.length > 0 ? `${selectedItems.length} Stream${selectedItems.length > 1 ? "s" : ""} selected` : title}
    >
      <SearchContainer style={{display: 'grid', gridTemplateColumns: isMobile ? '1fr 1fr': '2fr 1fr'}}>
        <SearchInput
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Shellstream #"
          style={{width: isMobile ? '100%' : 'auto'}}
        />
        {!isLoading && 
        <DurationWrapper>
          <DurationLabel>Duration:</DurationLabel>
          <DurationValue>{vestingEndTime}</DurationValue>
        </DurationWrapper>
        }
      </SearchContainer>
      <FiltersContainer>
        <FilterDropdown
          onChange={(filter) => handleFilterChange(filter)}
          list={[
            { orderBy: "id", order: "asc", label: "ID: Low to High" },
            { orderBy: "id", order: "desc", label: "ID: High to Low" },
            { orderBy: "vesting", order: "asc", label: "Vesting: Low to High" },
            { orderBy: "vesting", order: "desc", label: "Vesting: High to Low" },
            { orderBy: "claimable", order: "asc", label: "Claimable: Low to High" },
            { orderBy: "claimable", order: "desc", label: "Claimable: High to Low" },
          ]}
          value={selectedFilter}
        />
        {isInputToken ? (
          <MaxButton
            onClick={() => {
              setSelectedItems(isAllSelected ? [] : sortedNFTs);
            }}
          >
            {isAllSelected ? "Unselect All" : "Select All"}
          </MaxButton>
        ) : (
          <></>
        )}
        <FiltersButtons>
          <FilterButton
            data-testid="list-view-btn"
            active={viewType === "list"}
            onClick={() => setViewType("list")}
          >
            <img src={list} alt="List" />
          </FilterButton>
          <FilterButton
            data-testid="grid-view-btn"
            active={viewType === "grid"}
            onClick={() => setViewType("grid")}
          >
            <img src={grid} alt="Grid" />
          </FilterButton>
        </FiltersButtons>
      </FiltersContainer>
      {isLoading ? (
        <CircleContainer>
          <Spinner />
        </CircleContainer>
      ) : sortedNFTs?.length == 0 ? (
        <Title style={{ margin: "auto" }}>No  Shellstreams Found</Title>
      ) : viewType === "list" ? (
        <CollectionList
          onScroll={handleScroll}
          confirmVisible={selectedItems?.length > 0}
        >
          {sortedNFTs.map((item, index) => (
            <ListItem key={index}>
              <CollectionButton
                dataTestId={`nft-list-item-${index}`}
                icon={item.image}
                // iconRight={isItemSelected(item.id) ? checked : ""}
                iconRight={""}
                title={"#" + item.id}
                aboveTitle={item.name}
                subtitle={`Rarity: ${item.rarity}`}
                selected={isItemSelected(item.id)}
                onClick={() => onClickItem(item)}
                vestingStreamVariant
                allocation={item?.vesting}
                tokensToClaim={item?.claimable}
              />
            </ListItem>
          ))}
          {bottomLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <StraightLoader />
            </div>
          )}
        </CollectionList>
      ) : (
        (
          <>
            <Grid
              onScroll={handleScroll}
              confirmVisible={selectedItems?.length > 0}
            >
              {" "}
              {sortedNFTs.map((item, index) => (
                <CollectionGridItem
                  dataTestId={`nft-item-${index}`}
                  key={index}
                  icon={item.image}
                  title={"#" + item.id}
                  aboveTitle={item.name}
                  allocation={item?.vesting}
                  isVestingVariant
                  selected={isItemSelected(item.id)}
                  tokensToClaim={item?.claimable}
                  onClick={() => onClickItem(item)}
                />
              ))}
            </Grid>
            {bottomLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <StraightLoader />
              </div>
            )}

          </>
        )
      )
      }
      {
        selectedItems.length > 0 && (
        <ConfirmButton onClick={() => {
          onConfirm(selectedItems as VestingStream[]);
          toggleModal();
        }}>Confirm</ConfirmButton>
        )
      }
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: 680px;
  max-width: 620px;

  ${Media.mobile} {
    height: 90%;
    max-width: 100%;
    margin-top: 9.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
`;

const HeaderWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 12px;
width: 100%;
justify-content: flex-start;
align-items: flex-start;
`;

const TitleWrapper = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`;

const Description = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */

a {
    background: var(--gradient-2, linear-gradient(90deg, #37DCF2 0.87%, #07C0FB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
`;

const LeftSideTitleWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 12px;
`;

const RightSideTitleWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 12px;
`;

const DurationWrapper = styled.div`
border-radius: 8px;
border: 1px solid var(--dark-3, #1E2239);
padding: 8px 12px;
display: flex;
gap: 4px;
justify-content: center;
align-items: center;
`;

const DurationLabel = styled.p`
color: var(--grey-3, #7D7D97);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */

${Media.smallMobile}{
    font-size: 12px;
}
`;

const DurationValue = styled.p`
color: var(--White, #FFF);

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */

${Media.smallMobile}{
    font-size: 12px;
}
`;

const LearnMoreBtn = styled(ButtonSecondary)`
border-radius: 10px;
border: 1px solid var(--gradient-2, #37DCF2);
padding: 8px 12px;
box-shadow: 0px 24px 84px 0px #08081B;
width: auto;
height: unset;
color: var(--White, #FFF);
/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
`;

const ButtonContent = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
gap: 4px;
`;

const FiltersButtons = styled.div`
  display: flex;
  gap: 0 11px;
  margin-left: auto;
`;

const FilterButton = styled(Button) <{ active?: boolean | undefined }>`
  opacity: ${(props) => (props.active ? "1" : "0.2")};

  &:hover {
    opacity: ${(props) => (props.active ? "1" : "0.4")};
  }
`;

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: auto;
`;

const CollectionList = styled(List) <{ confirmVisible: boolean }>`
  margin-top: 16px;
  padding: 3px 12px 2px 3px;

  ${({ confirmVisible }) =>
    confirmVisible &&
    css`
      padding-bottom: 80px;
    `};
`;

const Grid = styled.div<{ confirmVisible: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - calc(3 * 12px / 4)));
  gap: 16px 12px;
  margin-top: 16px;
  padding: 3px 12px 12px 3px;
  overflow-y: auto;
  ${scrollbar()}

  ${Media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    margin: 16px auto 0;
  }

  ${({ confirmVisible }) =>
    confirmVisible &&
    css`
      padding-bottom: 80px;
    `};
`;

const SubmitButtonContainer = styled.div`
position: absolute;
bottom: 0;
width: 100%;
left: 0;
background: #0A0E27;
padding: 12px 24px 0 24px;
`;

const SubmitButton = styled(ButtonPrimary)`
padding: 20px 30px;
height: unset;
width: 100%;
border-radius: 16px;
`;

const ModalContent = styled.div``;

const ConfirmButton = styled(ButtonPrimary)`
  position: absolute;
  bottom: 12px;
  width: 100%;
  height: 62px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  //box-shadow: 0px 4px 24px rgba(42, 212, 244, 0.4);
`;

const MaxButton = styled.button`
  margin-left: 12px;
  border: none;
  border: solid 1px #7d7d97;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0);
  color: #7d7d97;
  font-size: 10px;
  font-weight: 250;
  line-height: 17px;

  :hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    color: #000e47;
    border: solid 1px #000e47;
  }
`;

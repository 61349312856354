import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useAccount, useNetwork } from "wagmi";
import { Toaster } from "react-hot-toast";
import arbitrumLogo from "../../assets/icons/arbitrum.svg";
import warningIcon from "../../assets/icons/warning.svg";
import { useWidthBreakpoint } from "../../hooks";
import { breakpoints, Media } from "../../styles";
import { ButtonPrimary } from "../Buttons/Button";
import { ChainContext, changeNetwork, validChains } from "../Overlays/ChainProvider";
import { defaultChain, Chain, allChains } from "@/placeholders/chains";
import { useLocation } from "react-router";
import { ChainDropdown } from "./ChainDropdown";

export const Network = () => {
  const { chain: activeChain } = useNetwork();
  const [warning, setWarning] = useState(false);
  const { isConnected } = useAccount();
  const { changeChain, connectedChain } = useContext(ChainContext);
  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("rewards")) {
      changeChain(defaultChain);
      setWarning(isConnected && activeChain!.name != defaultChain.name);
    } else {
      if (activeChain && activeChain?.name !== connectedChain.name) {
        if (validChains.has(activeChain.name!)) changeChain(allChains[activeChain?.name!]);
        else setWarning(true);
      } else {
        setWarning(false);
      }
      if (location.pathname.includes("trade")) setWarning(false);
    }
  }, [location.pathname, isConnected, activeChain, connectedChain]);

  const handleSelectChain = (chain: Chain) => {
    if (isConnected) {
      changeNetwork(chain);
    } else {
      changeChain(chain);
    }
  };

  return (
    <>
      {" "}
      {warning ? (
        <NetworkIndicator warning={warning}>
          <Toaster />
          <ArbitrumLogo warning={warning} src={warningIcon} alt={"Error"} />
          {!isTablet && `Wrong Chain`}
        </NetworkIndicator>
      ) : (
        <ChainDropdown
          allChains={Array.from(validChains).map((chainName) => allChains[chainName])}
          selectedChain={connectedChain}
          onChainSelect={handleSelectChain}
        />
      )}
      {warning ? (
        <WarningInfoBanner>
          <img src={warningIcon} alt={"Error"} style={{ width: `32px`, height: "32px" }} />
          <TextInfo>
            Please switch your wallet's network to <span>{connectedChain.name}</span>
          </TextInfo>
          <ConnectButton onClick={() => changeNetwork(connectedChain)}>Switch Network</ConnectButton>
        </WarningInfoBanner>
      ) : null}
    </>
  );
};

const WarningInfoBanner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 12px));
  width: 370px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: linear-gradient(#0a0e27, #0a0e27) padding-box, linear-gradient(to right, #37dcf2, #07c0fb) border-box;
  border: 1px solid transparent;
  border-radius: 12px;
  z-index: 9999;

  ${Media.tablet} {
    width: 343px;
  }
`;

const TextInfo = styled.div`
  max-width: 271px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.36em;
  color: #ffffff;
  text-align: center;
  span {
    font-weight: 600;
    background: linear-gradient(#37dcf2 100%, #07c0fb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const ConnectButton = styled(ButtonPrimary)`
  width: 100%;
  height: 51px;
  border-radius: 12px;
`;

const NetworkIndicator = styled.div<{ warning: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 60px;
  margin-right: 10px;
  padding: 0 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 16px;
  background-color: #0a0e27;
  color: #ffffff;

  ${({ warning }) =>
    warning &&
    css`
      background: rgba(238, 60, 60, 0.15);
      backdrop-filter: blur(27px);
      color: #ee3c3c;
    `};

  ${Media.tablet} {
    justify-content: center;
    height: 50px;
    width: 50px;
    padding: 10px;
  }
`;

const ArbitrumLogo = styled.img<{ warning: boolean }>`
  width: 28px;
  height: 28px;
  margin-right: 10px;
  border-radius: ${({ warning }) => (warning ? "0px" : "14px")};
  object-fit: cover;
  overflow: hidden;

  ${Media.tablet} {
    margin-right: 0;
  }
`;

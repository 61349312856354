import { useEffect, useState } from "react";
import collabCoin from "@/assets/coins/collab-coin.svg";
import { BigNumber, Contract } from "ethers";
import { formatEther, formatUnits, parseUnits } from "ethers/lib/utils";
import { CrabABI } from "@/constants/ABI/CrabABI";
import { CRAB_ADDRESS, VESTING_ADDRESS } from "@/constants/addresses";
import { useAccount, useNetwork } from "wagmi";
import toast from "react-hot-toast";
import { MaxUint256 } from "@ethersproject/constants";
import { ERC20ABI } from "@/constants/ABI/ERC20ABI";
import { ContractCallContext, ContractCallResults } from "ethereum-multicall";
import { multicall } from "@/utils/nftHelpers";
import { CRAB_DROP_API, COLLAB_DROP_API } from "@/constants/urls";
import { VestingABI } from "@/constants/ABI/VestingABI";
import { CollabAirdropABI } from "@/constants/ABI/CollabAirdropABI";
import { useProvider, useSigner } from "wagmi";
import { addNFTBalance } from "@/store/balancesSlice";
import { useAppDispatch } from "@/store/hooks";
import { defaultChain } from "@/placeholders/chains";

type SeasonType = {
    name: string;
    value: number;
    status: string;
};

export type AirDropType = {
    name: string;
    subname: string;
    reward: number;
    claimed: boolean;
    icon: string;
}

const otherAirDropsInit :AirDropType[] = [
    {
        name: 'COLLAB',
        subname: 'Collab.Land',
        reward: 0,
        claimed: false,
        icon: collabCoin,
    }
]

export type ClaimData = {
    index: number[];
    amount: string[];
    proof: string[][];
};

type ClaimDataCollab = {
  index: number;
  amount: string;
  proof: string[];
  distribution: number[];
};

export const useAirDropHandler = () => {
    const [userSeasons, setUserSeasons] = useState<SeasonType[]>([]);
    const [airDropBalance, setAirDropBalance] = useState(0);
    const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
    const [isClaimConfirmationModalOpen, setIsClaimConfirmationModalOpen] = useState(false);
    const [isAirDropClaimConfirmed, setIsAirDropClaimConfirmed] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [actionTimeoutId, setActionTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [redirectTimerId, setRedirectTimerId] = useState<NodeJS.Timeout | null>(null);
    const [isCrabClaimModalOpen, setIsCrabClaimModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [claimData, setClaimData] = useState<ClaimData>({index: [], amount: [], proof: []});
    const [unclaimedCrab, setUnclaimedCrab] = useState(0);
    const [crabClaimTxSubmitted, setCrabClaimTxSubmitted] = useState(false)
    const [crabClaimSuccess, setCrabClaimSuccess] = useState(false)

    const [crabBalance, setCrabBalance] = useState('0')
    const [crabApprovalState, setCrabApprovalState] = useState('')
    const [crabApprovedAmount, setCrabApprovedAmount] = useState('0')
    const [airdropLoading, setAirdropLoading] = useState(false)

    const [claimedStreamId, setClaimedStreamId] = useState(0)
    const [airdropTxSubmitted, setAirdropTxSubmitted] = useState(false)
    const [airdropShellAmount, setAirdropShellAmount] = useState(0)

    const { data: signer } = useSigner();
    const provider = useProvider();
    const { address : walletAddress, isConnected } = useAccount();
    const { chain: activeChain } = useNetwork();

    const vesting = new Contract(VESTING_ADDRESS, VestingABI, signer || provider)
    const crabs = new Contract(CRAB_ADDRESS, CrabABI, signer || provider)
    const collab = new Contract("0x5A5B14594f1BF93656a2B705ECdb873373A4eFe9", CollabAirdropABI, signer || provider);

    const [otherAirdrops, setOtherAirdrops] = useState<AirDropType[] | null>(otherAirDropsInit);
    
    const [isOtherAirDropClaimModalOpen, setIsOtherAirDropClaimModalOpen] = useState(false)
    const [otherClaimTxSubmitted, setOtherClaimTxSubmitted] = useState(false)
    const [otherClaimSuccess, setOtherClaimSuccess] = useState(false)
    const [claimOtherData, setClaimOtherData] = useState<ClaimDataCollab | null>(null);
    const [currentAirdrop, setCurrentAirdrop] = useState<AirDropType | null>(null);

    const dispatch = useAppDispatch()
    const validChain = activeChain?.name == defaultChain.name

    // const claimedWalletAddress = '0x72daf58e621002843A24E9D2285475e83D9Bc928';
    // const unclaimedWalletAddress = '0x9F5A95e48d010beED3b578dC378b27d8A49FE491';

    const fetchAirdropsClaimData = async () => {
    
      if(!validChain || isConnected){
        setClaimOtherData(null)
        setOtherAirdrops(otherAirDropsInit)
        return
      }

      try {
        const response = await fetch(COLLAB_DROP_API + walletAddress);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
    
        if (data && 'index' in data && 'amount' in data) {
          setClaimOtherData(data);
    
          const reward = parseFloat(formatUnits(data.amount));
          const isClaimed = await checkUserClaimStatus(data.index);
    
          setOtherAirdrops(prevAirdrops => prevAirdrops ? prevAirdrops.map(ad => 
            ad.name === 'COLLAB' ? { 
              ...ad, 
              reward: reward,
              claimed: isClaimed
            } : ad
          ) : []);
        }
      } catch (error) {
        console.error("Error -", error);
      }
    };
    
    const checkUserClaimStatus = async (index: number): Promise<boolean> => {
      if (!collab || index === undefined) return false;
      try {
        const indexToPass = BigNumber.from(index);
        const isClaimed = await collab.isClaimed(indexToPass);
        return isClaimed;
      } catch (error) {
        console.error("Error checking claim status:", error);
        return false;
      }
    };
      
    useEffect(() => {
      let isMounted = true;
      if (walletAddress && isMounted) {
          fetchAirdropsClaimData();
      }
      return () => {
          isMounted = false;
      };
    }, [walletAddress, validChain]);

    const handleOtherAirdropClaim = (airdrop: AirDropType) => {
      if (!otherAirdrops) { return; }

      const airdropIndex = otherAirdrops.findIndex(ad => ad.name === airdrop.name);

      if (!collab || !claimOtherData || airdropIndex === -1) { return; }

      setCurrentAirdrop(airdrop);
      setIsOtherAirDropClaimModalOpen(true);
      try {
        const indexToPass = BigNumber.from(claimOtherData.index);
        const amountToPass = BigNumber.from(claimOtherData.amount);
        const formattedProof = claimOtherData.proof.map(proofElement => `0x${proofElement.toString().replace(/^0x/, "")}`);

        collab.claim(
          indexToPass, 
          amountToPass, 
          formattedProof as ReadonlyArray<`0x${string}`>
        ).then((response: any) => {
            setCrabClaimTxSubmitted(true)
            response.wait().then((result: any) => {
              if (result.status == 1) { //Success
                setOtherClaimSuccess(true);
                setOtherClaimTxSubmitted(false);
              } else {
                setOtherClaimSuccess(true);
              }
            });
          })
          .catch((error: any) => {
            console.error("Claim Error", error);
            setIsOtherAirDropClaimModalOpen(false);
          });
      } catch (error) {
        console.error("Error claiming collabland airdrop tokens:", error);
      }
    };

    const handleOtherAirdropClose = () => {
      setIsOtherAirDropClaimModalOpen(false);
      if(otherClaimSuccess) setRefresh(!refresh)
    }

    const handleOpenCrabClaimModal = () => {
        setIsCrabClaimModalOpen(true);
    };

    const handleCloseCrabClaimModal = () => {
        setIsCrabClaimModalOpen(false);
        if(crabClaimSuccess) setRefresh(!refresh)
    };

    const handleCloseClaimModal = () => {
        setIsClaimModalOpen(false);
    };

    const handleSortAirDropRewards =  () => {

    }

    const handleSortAirDropToken = () => {

    }

    const handleCloseClaimConfirmationModal = () => {
        setIsClaimConfirmationModalOpen(false);
        if(isAirDropClaimConfirmed) setRefresh(!refresh)
    };

    const handleInitClaim = () => {
        setIsClaimModalOpen(true);
    };

    const fetchBalances = async () => {
      const contractCallContext: ContractCallContext[] = [
        {
          reference: "CRAB",
          contractAddress: CRAB_ADDRESS,
          abi: ERC20ABI as any,
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [walletAddress],
            },
            {
              reference: "allowance",
              methodName: "allowance",
              methodParameters: [walletAddress, VESTING_ADDRESS],
            },
          ],
        },
      ];

      const results: ContractCallResults = await multicall.call(
        contractCallContext
      );

      return {
        crab: formatEther(
          results.results["CRAB"].callsReturnContext[0].returnValues[0]
        ),
        approvedAmount: formatEther(
          results.results["CRAB"].callsReturnContext[1].returnValues[0]
        ),
      };
    };

    const crabSeasons = ['Season Zero', 'Season One', 'Season Two', 'Season Three']

    const fetchClaimData = async () => {

      const claims = await crabs.viewClaims(walletAddress);
      const data = await fetch(CRAB_DROP_API + walletAddress).then((response) => response.json());

      const newClaimData: ClaimData = { index: [], amount: [], proof: [] };
      const newUserSeasons: SeasonType[] = []
      let unclaimedCrabAmount = 0

      for (let index = 0; index < claims.length; index++) {
        const unclaimed = !claims[index] && data[crabSeasons[index]] && Object.keys(data[crabSeasons[index]]).length > 0;
        const seasonClaimData = data[crabSeasons[index]];
        if (seasonClaimData && Object.keys(seasonClaimData).length > 0) {
          newClaimData.index.push(seasonClaimData.index);
          newClaimData.amount.push(seasonClaimData.amount);
          newClaimData.proof.push(
            unclaimed
              ? seasonClaimData.proof.map(
                  (proofElement: string) =>
                    `0x${proofElement.replace(/^0x/, "")}` as `0x${string}`
                )
              : []
          );
          newUserSeasons.push({
            name: crabSeasons[index],
            status: unclaimed ? 'Pending' : 'Claimed',
            value: parseFloat(formatUnits(seasonClaimData.amount))
          })
          unclaimedCrabAmount += (unclaimed ? parseFloat(formatUnits(seasonClaimData.amount)) : 0)
        } else {
          newClaimData.index.push(0);
          newClaimData.amount.push("0");
          newClaimData.proof.push([]);
          newUserSeasons.push({
            name: crabSeasons[index],
            status: '',
            value: 0
          })
        }
      }

      return {
        claimData: newClaimData,
        userSeasons: newUserSeasons,
        unclaimedCrab: unclaimedCrabAmount
      }
    };

    const handleClaimCrab = async () => {

        const modalDelayTimer = setTimeout(() => {
            setIsCrabClaimModalOpen(true);
        crabs
          .claim(
            claimData.index.map((index) => BigNumber.from(index)),
            claimData.amount.map((amount) => BigNumber.from(amount)),
            claimData.proof
          )
          .then((response: any) => {
            setCrabClaimTxSubmitted(true)
            response.wait().then((result: any) => {
              if (result.status == 1) { //Success
                setCrabClaimSuccess(true);
                setCrabClaimTxSubmitted(false)
              } else {
                setCrabClaimSuccess(false);
              }
            });
          })
          .catch(() => {
            setIsCrabClaimModalOpen(false);
        });
        }, 230);
        setActionTimeoutId(modalDelayTimer);

    };

    const approveCrab = () => {
      setCrabApprovalState("Pending");
      crabs
        .approve(VESTING_ADDRESS, MaxUint256)
        .then((response: any) => {
          toast.promise(response.wait(), {
            loading: "Approving CRAB",
            success: () => {
              setCrabApprovalState("");
              fetchBalances().then((balanceResult) => { setCrabApprovedAmount(balanceResult.approvedAmount)})
              return "Approved CRAB";
            },
            error: () => {
              setCrabApprovalState("");
              return "Error in CRAB approval";
            },
          });
        })
        .catch(() => {});
    };

    const handleClaimAirdrop = (amount: number) => {
        setIsClaimModalOpen(false)
        setIsClaimConfirmationModalOpen(true)
        setAirdropShellAmount(amount)
        vesting.claimStream(parseUnits(amount.toString())).then((response: any) => {
            setAirdropTxSubmitted(true)
            response.wait().then((result: any) => {
                if (result.status == 1) { //Success
                    setClaimedStreamId(
                      BigNumber.from(
                        result.events.find(
                          (event: any) => event.event == "Claimed"
                        ).args[0]
                      ).toNumber()
                    );
                    setIsAirDropClaimConfirmed(true);
                    setAirdropTxSubmitted(false)
                    dispatch(
                        addNFTBalance({
                            collection: 'STREAM',
                            items: null,
                        })
                    );
                }
            })
        }).catch(() => {
            setIsClaimConfirmationModalOpen(false)
            setIsClaimModalOpen(true)
            setAirdropShellAmount(0)
        })
    };

    useEffect(() => {
      let _isMounted = true;
      if (_isMounted) {
        setAirdropLoading(true);
      }
      setIsAirDropClaimConfirmed(false)
      setCrabClaimSuccess(false)
      if(!validChain){
        setCrabBalance('0')
        setAirDropBalance(0)
        setCrabApprovedAmount('0');
        setClaimData({index: [], amount: [], proof: []})
        setUserSeasons([])
        setUnclaimedCrab(0)
        setAirdropLoading(false);
        return
      }
      Promise.all([fetchBalances(), fetchClaimData()])
        .then(([balanceResult, claimResult]) => {
          if (_isMounted) {
            setCrabBalance(parseFloat(balanceResult.crab) > 1e-6 ? balanceResult.crab : '0')
            setAirDropBalance(parseFloat(balanceResult.crab) > 1e-6 ? parseFloat(balanceResult.crab) : 0)
            setCrabApprovedAmount(balanceResult.approvedAmount);
            setClaimData(claimResult.claimData)
            setUserSeasons(claimResult.userSeasons)
            setUnclaimedCrab(claimResult.unclaimedCrab)
            setAirdropLoading(false);
          }
        })
        .catch((_) => {
          if (_isMounted) {
            setAirdropLoading(false);
          }
        });

      return () => {
        _isMounted = false;
      };
    }, [walletAddress, refresh, validChain]);

    useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
            if (redirectTimerId) clearTimeout(redirectTimerId);
            if (actionTimeoutId) clearTimeout(actionTimeoutId);
        }
    }, [crabClaimSuccess]);

    return {
        handleInitClaim,
        userSeasons,
        airDropBalance,
        unclaimedCrab,
        isClaimModalOpen,
        setIsClaimModalOpen,
        handleCloseClaimModal,
        isClaimConfirmationModalOpen,
        setIsClaimConfirmationModalOpen,
        handleCloseClaimConfirmationModal,
        isAirDropClaimConfirmed,
        setIsAirDropClaimConfirmed,
        handleSortAirDropRewards,
        handleSortAirDropToken,
        otherAirdrops,
        isCrabClaimModalOpen,
        handleOpenCrabClaimModal,
        handleCloseCrabClaimModal,
        crabBalance,
        crabApprovalState,
        setCrabApprovalState,
        crabApprovedAmount,
        airdropLoading,
        approveCrab,
        handleClaimCrab,
        crabClaimTxSubmitted,
        crabClaimSuccess,
        claimedStreamId,
        airdropTxSubmitted,
        airdropShellAmount,
        handleClaimAirdrop,

        // Other Airdrops
        handleOtherAirdropClaim,
        handleOtherAirdropClose,
        isOtherAirDropClaimModalOpen,
        otherClaimTxSubmitted,
        otherClaimSuccess,
        currentAirdrop
    }
}
import { CustomModal } from "@/components/CustomModal/CustomModal";
import React from "react";
import { useRewardsContext } from "../../RewardsContext";
import { AlertModalBody } from "../modals/AlertModalBody";
import { ChangeLockPeriodConfirmationModalBody } from "../modals/ChangeLockPeriodConfirmationModalBody";
import { IncreaseLockPeriodModalBody } from "../modals/IncreaseLockPeriodModalBody";
import { StakeConfirmationModalBody } from "../modals/StakeConfirmationModalBody";
import { StakeModalBody } from "../modals/StakeModalBody";
import { MyVeShellBalance } from "./components/MyVeShellBalance";
import { Overview } from "./components/Overview";
import { StakingDetails } from "./components/StakingDetails";
import { StakingEmptyState } from "./components/StakingEmptyState";
import * as S from "./Staking.styled";
import { UnstakeConfirmationModalBody } from "../modals/UstakeConfirmationModalBody";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { useAccount } from "wagmi";

export const Staking = () => {
  const {
    savedLockPeriod,
    isStakeModalOpen,
    handleCloseStakeModal,
    isOpenStakeConfirmationModal,
    handleCloseStakeConfirmationModal,
    isOpenAlertModal,
    handleCloseAlertModal,
    isIncreaseLockPeriodModalOpen,
    handleCloseIncreaseLockPeriodModal,
    isOpenChangeLockPeriodConfirmationModal,
    handleCloseChangeLockPeriodConfirmationModal,
    isOpenUnstakeConfirmationModal,
    handleCloseUnstakeConfirmationModal,
    stakingLoading,
  } = useRewardsContext();
  const { isConnected } = useAccount();
  return (
    <>
      <S.StakingContainer>
        <>
          <Overview />
          <S.SectionContainer>
            <S.SectionTitle>My staking</S.SectionTitle>
            <S.MyStakingWrapper isStretch={!!savedLockPeriod}>
                {stakingLoading ? (
                    <>
                    <SkeletonBox isLoading={true} height={"200px"} width="100%" borderRadius="16px" />
                    {/* { isConnected && <SkeletonBox isLoading={true} height={"200px"} width="100%" borderRadius="16px" /> } */}
                    </>
                ) : savedLockPeriod ? (
                <>
                  <MyVeShellBalance />
                  <StakingDetails />
                </>
                ) : (
                    <StakingEmptyState />
                )}
            </S.MyStakingWrapper>
          </S.SectionContainer>
        </>
      </S.StakingContainer>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isStakeModalOpen}
        onClose={handleCloseStakeModal}
        withBackdropBlur
        withOutsideClick
        width="654px"
      >
        <StakeModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenStakeConfirmationModal}
        onClose={() => handleCloseStakeConfirmationModal()}
        withOutsideClick
        withBackdropBlur
      >
        <StakeConfirmationModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenAlertModal}
        onClose={() => handleCloseAlertModal()}
        withOutsideClick
        withBackdropBlur
      >
        <AlertModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isIncreaseLockPeriodModalOpen}
        onClose={() => handleCloseIncreaseLockPeriodModal()}
        withOutsideClick
        withBackdropBlur
        width="654px"
      >
        <IncreaseLockPeriodModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenChangeLockPeriodConfirmationModal}
        onClose={() => handleCloseChangeLockPeriodConfirmationModal()}
        withOutsideClick
        withBackdropBlur
      >
        <ChangeLockPeriodConfirmationModalBody />
      </CustomModal>
      <CustomModal
        mobileStyle={{ minHeight: "80%", maxHeight: "100%" }}
        isOpen={isOpenUnstakeConfirmationModal}
        onClose={() => handleCloseUnstakeConfirmationModal()}
        withBackdropBlur
        withOutsideClick
      >
        <UnstakeConfirmationModalBody />
      </CustomModal>
    </>
  );
};

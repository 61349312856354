import React, { ReactNode, useEffect, useState, useCallback, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styled from "styled-components";
import { CompassIcon } from "./Icons/CompassIcon";
import { SwapIcon } from "./Icons/SwapIcon";
import { PoolsIcon } from "./Icons/PoolsIcon";
import { Box } from "./Layout/Box";
import { Content } from "./Layout/Content";
import { RewardsIcon } from "./Icons/RewardsIcon";
import { Media } from "../styles";
import { ChainContext } from "./Overlays/ChainProvider";

const MAIN_TABS = [
  { to: "/trade", icon: <SwapIcon />, label: "Trade" },
  { to: "/rewards", icon: <RewardsIcon />, label: "Rewards", subpages: ["shell", "airdrop", "staking", "vesting", "booty", "lbp", "leaderboard", "stip"] },
  { to: "/pools", icon: <PoolsIcon />, stroke: 'true', label: "Pools", subpages: ["statistics"] },
  { to: "/explore", icon: <CompassIcon />, label: "Explore", subpages: ["wallet", "history", "explore", "tx"] }
];

export const Tabs = () => {
  const location = useLocation();
  const mainTabs = new Set(MAIN_TABS.map((tab) => tab.to));
  const { setCrossChainToggled } = useContext(ChainContext)

  const [highlightedTab, setHighlightedTab] = useState(location.pathname);

  const findMainTabForSubTab = useCallback((subTab: string) => {
    for (const tab of MAIN_TABS) {
      if (tab.subpages && tab.subpages.includes(subTab)) {
        return tab.to;
      }
    }
    return null;
  }, []);
  
  useEffect(() => {
    if (mainTabs.has(location.pathname) || findMainTabForSubTab(location.pathname.split("/")[2])) {
      if (location.pathname !== highlightedTab) {
        setHighlightedTab(location.pathname.split("/")[2] || location.pathname);
      }
      return;
    }

    let savedTab = null;
    try {
      savedTab = sessionStorage.getItem("lastMainTab");
    } catch (error) {
      // ignore
    }
    const mainTab = findMainTabForSubTab(location.pathname.split("/")[1] || "");
    const newHighlightedTab = savedTab && mainTabs.has(savedTab) ? savedTab : mainTab || "/explore";
    
    if (newHighlightedTab !== highlightedTab) {
      setHighlightedTab(newHighlightedTab);
    }   
  }, [location.pathname]);

  useEffect(() => {
    if (mainTabs.has(highlightedTab)) {
      try {
        sessionStorage.setItem("lastMainTab", highlightedTab);
      } catch (error) {
        // ignore
      }
    }

    if(highlightedTab !== 'Trade') setCrossChainToggled(false)
  }, [highlightedTab]);

  return (
    <Content>
      <View>
        <List>
          {MAIN_TABS.map((tab) => (
            <Tab key={tab.to} to={tab.to} stroke={tab.stroke} highlightedTab={highlightedTab}>
              {tab.icon}
              <TabText>{tab.label}</TabText>
            </Tab>
          ))}
        </List>
      </View>
    </Content>
  );
};

const Tab: React.FC<{
  to: string;
  children: ReactNode;
  stroke?: string;
  highlightedTab?: string;
}> = ({ to, children, stroke, highlightedTab }) => {
  const location = useLocation(); 

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (location.pathname === '/rewards/airdrop' && to.includes('/rewards')) { // prevents navigation to rewards tab when airdrop (default subpage) is active
      event.preventDefault();
    }
  };

  return (
    <Item>
      <TabButton
        to={to}
        stroke={stroke}
        className={highlightedTab === to ? "active" : ""}
        onClick={handleClick}
      >
        {children}
      </TabButton>
    </Item>
  );
};

const View = styled(Box)`
  height: 70px;
  padding: 7px;
  margin-bottom: 24px;

  ${Media.tablet} {
    height: 50px;
    padding: 4px;
    margin-bottom: 16px;
  }
`;

const List = styled.ul`
  display: flex;
  height: 100%;
`;

const Item = styled.li`
  width: 100%;

  & + & {
    margin-left: 16px;

    ${Media.tablet} {
      margin-left: 0;
    }
  }
`;

const TabText = styled.span`
  ${Media.tablet} {
    display: none;
  }
`

const Explanation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none;
  border-radius: 14px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`;

const HelpInfo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none;
  border-radius: 14px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  background: #171b33;
  border: 1px solid #1e2239;
`;

const TabButton = styled(NavLink)<{ stroke?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none;
  border-radius: 14px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #464659;

  svg {
    margin-right: 10px;

    ${Media.tablet} {
      max-width: 22px;
      max-height: 22px;
      margin-right: 0;
    }
  }

  &.active {
    color: #ffffff;
    background: #171b33;
    border: 1px solid #1e2239;
  }

  &.active svg path {
    ${({ stroke }) => (stroke == 'true' ? "stroke: #37dcf2;" : "fill: #37dcf2;")}
  }

  ${Media.tablet} {
    border-radius: 16px;
  }

  ${Media.tablet} {
    border-radius: 16px;
  }
`;

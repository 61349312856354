import { BigNumber, Contract } from "ethers/lib/ethers";
import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ArrowIcon } from "../../components/Icons/ArrowIcon";
import { ConfirmIcon } from "../../components/Icons/ConfirmIcon";
import { Modal } from "../../components/Modal/Modal";
import { isNFTCollection, isShellV2Token, NFT } from "../../utils/tokens";
import { formatDisplay } from "../../utils/formatDisplay";
import { Edge, getTokenID } from "../../utils/LiquidityGraph";
import { Accountant } from "../../components/Accountant";
import { parseEther, formatUnits, parseUnits } from "ethers/lib/utils";
import { PoolQuery } from "../../utils/PoolQuery";
import { useAccount, useSigner, useProvider } from "wagmi";
import { Zero } from "@ethersproject/constants";
import { buildInteractions } from "../../utils/buildInteractions";
import * as ShellV2 from "../../utils/ocean/index";
import toast from "react-hot-toast";
import { OceanABI } from "../../constants/ABI/OceanABI";
import { OCEAN_ADDRESS, OLD_OCEAN_ADDRESS } from "../../constants/addresses";
import { tokenColors } from "../../constants/tokenColors";
import { wrappedTokens } from "../../constants/wrappedToken";
import { Spinner, StraightLoader } from "../../components/Loaders";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ErrorIcon } from "../../components/Icons/ErrorIcon";
import { Media } from "../../styles";
import { NFTsSwiper } from "./NFTsSwiper/NFTsSwiper";
import { calculateWrappedTokenId } from "../../utils/ocean/utils";
import { NFTWarning } from "./NFTWarning";
import * as types from "../../utils/ocean/types";
import { buildNFTDisplays } from "../../utils/nftHelpers";
import { clearUserTransactions } from "@/store/transactionsSlice";
import { clearCurrentPoints } from "@/store/pointsSlice";
import { getModifiedPath } from "@/utils/sor";
import { AdapterABI } from "@/constants/ABI/AdapterABI";
import { combineMerge, combineSplit } from "@/utils/buildSwapPath";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { ChainContext } from "@/components/Overlays/ChainProvider";

interface ConfirmationModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setTradeDisabled: (disabled: boolean) => void;
  tokensFrom: any[];
  tokensTo: any[];
  specifiedAmounts: Record<string, string>;
  splitAmounts: Record<string, BigNumber>;
  slippage: number;
  fromInputs: boolean;
  selectedNFTs: any;
  setSelectedNFTs: any;
  setTxSuccess: (val: boolean) => void;
}

export const ConfirmationModal = ({
  visible,
  setVisible,
  setTradeDisabled,
  tokensFrom,
  tokensTo,
  specifiedAmounts,
  splitAmounts,
  slippage,
  fromInputs,
  selectedNFTs,
  setSelectedNFTs,
  setTxSuccess,
}: ConfirmationModalProps) => {
  const { data: signer } = useSigner();
  const provider = useProvider();

  const { oceanAddress, tokens, externalTokens, tokenMap, poolQuery, liquidityGraph, connectedChain, externalDefiTokens, sorTokenMap } =
    useContext(ChainContext);

  const { address: walletAddress } = useAccount();
  // const liquidityGraph = new LiquidityGraph();
  // const poolQuery = new PoolQuery(tokenMap);

  const inputNFTCollections = tokensFrom.filter((token) => isNFTCollection(token))?.map((token) => token.symbol) ?? [];
  const outputNFTCollections = tokensTo.filter((token) => isNFTCollection(token))?.map((token) => token.symbol) ?? [];
  const allNFTs = (fromInputs ? inputNFTCollections : outputNFTCollections).map((collection) => selectedNFTs[collection]).flat();

  const [amountsFrom, setAmountsFrom] = useState<Record<string, string>>({});
  const [amountsTo, setAmountsTo] = useState<Record<string, string>>({});

  const [optimalPaths, setOptimalPaths] = useState<{ [start: string]: { [end: string]: Edge[] } }>({});

  const [maxAmountsFrom, setMaxAmountsFrom] = useState<{ [start: string]: { [end: string]: BigNumber } }>({});
  const [minAmountsTo, setMinAmountsTo] = useState<{ [end: string]: { [start: string]: BigNumber } }>({});

  const [foundNonWrap, setFoundNonWrap] = useState(false); // Flag to show slippage UI elements involved in swaps/deposits/withdraws
  const [summaryAmounts, setSummaryAmounts] = useState<{ [token: string]: string }>({});
  const [txState, setTxState] = useState("");
  const [txHash, setTxHash] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const [inputLoading, setInputLoading] = useState(false);
  const [outputLoading, setOutputLoading] = useState(false);

  const [unavailableNFTs, setUnavailableNFTs] = useState<{ [collection: string]: NFT[] }>({});

  const dispatch = useAppDispatch();

  const ocean = new Contract(oceanAddress, OceanABI, signer || provider);

  const wrapOrUnwrap = (path: Edge[]) => {
    const tokenOne = path[0].token;
    const tokenTwo = path[path.length - 1].token;

    return tokenOne.address == tokenTwo.address && (tokenOne.wrapped !== tokenTwo.wrapped || isNFTCollection(tokenOne) || isNFTCollection(tokenTwo));
  };

  const updateOutputValues = async (inputTokens: any[], outputTokens: any[], split: boolean) => {
    setAmountsFrom(specifiedAmounts);

    const newAmountsTo: Record<string, string> = { ...amountsTo };
    const newMinAmountsTo: { [end: string]: { [start: string]: BigNumber } } = { ...minAmountsTo };
    const newSummaryAmounts: { [token: string]: string } = {};
    const newOptimalPaths: { [start: string]: { [end: string]: Edge[] } } = { ...optimalPaths };

    let paths: Edge[][] = [];
    let inputNFTPaths: Edge[][] = [];

    let sharedPath: Edge[] = [];
    let pools: any;

    let inputAmounts: BigNumber[] = [];
    const outputAmounts: { [end: string]: BigNumber } = {};

    const slippageMultiplier = 10000 - slippage * 100;

    if (split) {
      [paths, inputNFTPaths] = poolQuery.filterInputNFTPath(
        outputTokens
          .map((outputToken) => {
            outputAmounts[getTokenID(outputToken)] = Zero;
            newMinAmountsTo[getTokenID(outputToken)] = {};
            newOptimalPaths[getTokenID(inputTokens[0])] = {};
            return liquidityGraph.findPath(inputTokens[0], outputToken);
          })
          .sort((a, b) => b.length - a.length)
      );

      const inputTokenID: string = getTokenID(inputTokens[0]);
      const totalInputAmount = specifiedAmounts[inputTokenID];

      newSummaryAmounts[inputTokenID] = totalInputAmount;

      paths = await Promise.all(
        paths.map(async (path) => {
          if (path.length == 0) return path;
          return getModifiedPath(path, totalInputAmount, slippage, tokenMap, sorTokenMap, connectedChain, liquidityGraph);
        })
      );

      pools = await poolQuery.getPools(paths);

      const pathData = combineSplit(JSON.parse(JSON.stringify(paths)));
      sharedPath = pathData.sharedPath;

      if (sharedPath.findIndex((step) => step.action && step.action !== "Wrap" && step.action !== "Unwrap") !== -1) {
        // Shared path involves a trade
        const [sharedOutput, firstOutput] = await Promise.all([
          poolQuery.query(pathData.sharedPath, parseUnits(totalInputAmount), pools, true),
          poolQuery.query(pathData.sharedPath, parseUnits(totalInputAmount).div(paths.length), pools, true),
        ]);
        paths = pathData.paths;
        inputAmounts = [firstOutput.amount, sharedOutput.amount.sub(firstOutput.amount)];
        pools = poolQuery.filterPools(pools, paths);
      } else {
        inputAmounts = paths.map((path) => splitAmounts[getTokenID(path[path.length - 1].token)]);
      }
    } else {
      [paths, inputNFTPaths] = poolQuery.filterInputNFTPath(
        inputTokens
          .map((inputToken) => {
            newOptimalPaths[getTokenID(inputToken)] = {};
            return liquidityGraph.findPath(inputToken, outputTokens[0]);
          })
          .sort((a, b) => b.length - a.length)
      );
      outputAmounts[getTokenID(outputTokens[0])] = Zero;
      newMinAmountsTo[getTokenID(outputTokens[0])] = {};

      // paths = await Promise.all(paths.map(async (path, i) => {
      //   if(path.length == 0) return path
      //   return getModifiedPath(path, specifiedAmounts[getTokenID(paths[i][0].token)], slippage);
      // }));

      pools = await poolQuery.getPools(paths);

      const pathData = combineMerge(JSON.parse(JSON.stringify(paths)));

      inputAmounts = paths.map((path, index) => {
        if (path.length == 0 && inputNFTPaths[index].length == 0) {
          return parseEther(amountsFrom[getTokenID(inputTokens[index])]);
        }
        const inputToken = getTokenID((inputNFTPaths[index].length > 0 ? inputNFTPaths[index][0] : path[0]).token);
        let inputAmount;

        if (inputToken == "STREAM") {
          inputAmount = selectedNFTs[inputToken].map((stream: any) => parseFloat(stream.vesting)).reduce((partialSum: number, a: number) => partialSum + a, 0);
        } else {
          inputAmount = poolQuery.adjustNFTAmount(parseFloat(specifiedAmounts[inputToken] ?? 0), inputNFTPaths[index]);
        }
        newSummaryAmounts[inputToken] = inputAmount.toFixed(18);
        return parseEther(inputAmount.toFixed(18));
      });

      let totalOutputAmount = Zero;

      await Promise.all(
        pathData.paths.map(async (path: any, i) => {
          const outputTokenID = getTokenID(outputTokens[0]);
          if (path.length == 0) {
            if (inputNFTPaths[i].length > 0) outputAmounts[outputTokenID] = inputAmounts[i]; // Handle one step NFT wraps
            if (!outputAmounts[outputTokenID]) outputAmounts[outputTokenID] = Zero;
            return;
          }

          const [startToken, endToken] = [getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token), getTokenID(path[path.length - 1].token)];

          if (inputAmounts[i].isZero()) {
            if (!outputAmounts[endToken]) outputAmounts[endToken] = Zero;
            return;
          }

          let resultAmount = Zero;

          try {
            if (path.length == 1) {
              resultAmount = inputAmounts[i];
            } else {
              const result = await poolQuery.query(path, inputAmounts[i], pools, true);
              resultAmount = result.amount;
              poolQuery.filterPools(pools, pathData.paths).sharedPools.forEach((pool: string) => {
                pools.states[pool] = {
                  xBalance: result.poolStates[pool][0],
                  yBalance: result.poolStates[pool][1],
                  totalSupply: result.poolStates[pool][2],
                  impAddress: result.poolStates[pool][3],
                };
              });
              newMinAmountsTo[getTokenID(outputTokens[0])][startToken] = resultAmount.mul(slippageMultiplier).div(10000);
              if (!foundNonWrap) setFoundNonWrap(true);
            }
          } catch {
            console.error("Pool Query Error");
          }

          totalOutputAmount = totalOutputAmount.add(resultAmount);

          if (inputNFTPaths[i][inputNFTPaths[i].length - 1].action === path[0].action) {
            newOptimalPaths[startToken][outputTokenID] = inputNFTPaths[i].concat(path.slice(1)).concat(pathData.sharedPath.slice(1));
          } else {
            newOptimalPaths[startToken][outputTokenID] = inputNFTPaths[i].concat(path).concat(pathData.sharedPath.slice(1));
          }

          if (wrapOrUnwrap(pathData.sharedPath)) {
            outputAmounts[getTokenID(outputTokens[0])] = outputAmounts[getTokenID(outputTokens[0])].add(resultAmount);
          }
        })
      );

      paths = wrapOrUnwrap(pathData.sharedPath) ? [] : [pathData.sharedPath];
      inputAmounts = [totalOutputAmount];
      pools = poolQuery.filterPools(pools, paths);
    }

    for (let i = 0; i < paths.length; i++) {
      let path = paths[i];

      if (path.length == 0 && inputNFTPaths[i].length > 0) {
        // Handle one step NFT wrap
        const [startToken, endToken] = [getTokenID(inputNFTPaths[i][0].token), getTokenID(inputNFTPaths[i][1].token)];
        outputAmounts[endToken] = inputAmounts[i];
        newMinAmountsTo[endToken][startToken] = inputAmounts[i];
        newOptimalPaths[startToken][endToken] = inputNFTPaths[i];
        continue;
      }

      const [startToken, endToken] = [getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token), getTokenID(path[path.length - 1].token)];

      if (split) {
        if (inputNFTPaths[i][inputNFTPaths[i].length - 1].action === sharedPath[0].action) {
          newOptimalPaths[startToken][endToken] = inputNFTPaths[i].concat(sharedPath.slice(1));
        } else {
          newOptimalPaths[startToken][endToken] = inputNFTPaths[i].concat(sharedPath);
        }
        const firstSwapIndex = path.findIndex((step) => step.action && step.action !== "Wrap");
        newOptimalPaths[startToken][endToken] = newOptimalPaths[startToken][endToken].concat(firstSwapIndex !== -1 ? path.slice(firstSwapIndex) : path);
      }

      let resultAmount = Zero;

      try {
        if (path.length == 1) {
          resultAmount = inputAmounts[i];
        } else {
          const result = await poolQuery.query(path, inputAmounts[i], pools, true);
          resultAmount = result.amount;
          pools.sharedPools.forEach((pool: string) => {
            pools.states[pool] = {
              xBalance: result.poolStates[pool][0],
              yBalance: result.poolStates[pool][1],
              totalSupply: result.poolStates[pool][2],
              impAddress: result.poolStates[pool][3],
            };
          });
        }
      } catch (_) {
        console.error("Proteus Query Error");
      }

      outputAmounts[endToken] = outputAmounts[endToken].add(resultAmount);

      if (split) {
        if (wrapOrUnwrap(newOptimalPaths[startToken][endToken])) {
          newMinAmountsTo[endToken][startToken] = resultAmount;
        } else {
          newMinAmountsTo[endToken][startToken] = resultAmount.mul(slippageMultiplier).div(10000);
          if (!foundNonWrap) setFoundNonWrap(true);
        }
      } else {
        for (const inputToken of inputTokens) {
          const inputTokenID = getTokenID(inputToken);
          if (wrapOrUnwrap(newOptimalPaths[inputTokenID][endToken])) {
            newMinAmountsTo[endToken][inputTokenID] = resultAmount.div(inputTokens.length);
          } else {
            newMinAmountsTo[endToken][inputTokenID] = resultAmount.div(inputTokens.length).mul(slippageMultiplier).div(10000);
            if (!foundNonWrap) setFoundNonWrap(true);
          }
        }
      }
    }

    Object.keys(outputAmounts).forEach((outputToken) => {
      const outputAmount = outputAmounts[outputToken];
      if (outputAmount.isZero()) {
        delete newAmountsTo[outputToken];
      } else {
        newAmountsTo[outputToken] = formatUnits(outputAmount);
      }
      let summaryAmount = Zero;
      Object.keys(newMinAmountsTo[outputToken]).forEach((inputToken) => (summaryAmount = summaryAmount.add(newMinAmountsTo[outputToken][inputToken])));
      newSummaryAmounts[outputToken] = formatUnits(summaryAmount);
    });

    setAmountsTo(newAmountsTo);
    setMinAmountsTo(newMinAmountsTo);
    setSummaryAmounts(newSummaryAmounts);
    setOptimalPaths(newOptimalPaths);
  };

  const updateInputValues = async (outputTokens: any[], inputTokens: any[], split: boolean) => {
    setAmountsTo(specifiedAmounts);

    const newAmountsFrom: Record<string, string> = { ...amountsFrom };
    const newMaxAmountsFrom: { [start: string]: { [end: string]: BigNumber } } = { ...maxAmountsFrom };
    const newSummaryAmounts: { [token: string]: string } = {};
    const newOptimalPaths: { [start: string]: { [end: string]: Edge[] } } = { ...optimalPaths };

    let paths: Edge[][] = [];
    let outputNFTPaths: Edge[][] = [];
    const inputAmounts: { [start: string]: BigNumber } = {};

    if (split) {
      [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(
        inputTokens
          .map((inputToken) => {
            inputAmounts[getTokenID(inputToken)] = Zero;
            newMaxAmountsFrom[getTokenID(inputToken)] = {};
            newOptimalPaths[getTokenID(inputToken)] = {};
            return liquidityGraph.findPath(inputToken, outputTokens[0]);
          })
          .sort((a, b) => {
            if (a.map((step) => step.token).includes(tokenMap["ETH"])) {
              return -1;
            } else if (b.map((step) => step.token).includes(tokenMap["ETH"])) {
              return 1;
            }
            return b.length - a.length;
          })
      );
    } else {
      [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(
        outputTokens.map((outputToken) => liquidityGraph.findPath(inputTokens[0], outputToken)).sort((a, b) => b.length - a.length)
      );
      inputAmounts[getTokenID(inputTokens[0])] = Zero;
      newMaxAmountsFrom[getTokenID(inputTokens[0])] = {};
      newOptimalPaths[getTokenID(inputTokens[0])] = {};
    }

    const outputAmounts = paths.map((path, index) => {
      const tokenID = getTokenID((outputNFTPaths[index].length > 0 ? outputNFTPaths[index].slice(-1)[0] : path[path.length - 1]).token);
      const outputAmount = specifiedAmounts[tokenID] || "0";
      newSummaryAmounts[tokenID] = outputAmount;
      return split
        ? splitAmounts[getTokenID(path[0].token)]
        : parseEther(
            tokenID == "STREAM"
              ? selectedNFTs[tokenID]
                  .map((stream: any) => parseFloat(stream.claimable) + parseFloat(stream.vesting))
                  .reduce((partialSum: any, a: any) => partialSum + a, 0)
                  .toFixed(18)
              : poolQuery.adjustNFTAmount(parseFloat(outputAmount), [...outputNFTPaths[index]].reverse()).toFixed(18)
          );
    });

    const pools = await poolQuery.getPools(paths);
    const slippageMultiplier = 10000 + slippage * 100;

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      const [startToken, endToken] = [
        getTokenID(path[0].token),
        getTokenID((outputNFTPaths[i].length > 0 ? outputNFTPaths[i].slice(-1)[0] : path[path.length - 1]).token),
      ];

      if (path[path.length - 1].action === outputNFTPaths[i][0].action) {
        newOptimalPaths[startToken][endToken] = path.concat(outputNFTPaths[i].slice(1));
      } else {
        newOptimalPaths[startToken][endToken] = path.concat(outputNFTPaths[i]);
      }

      let resultAmount = Zero;

      try {
        if (path.length == 1) {
          resultAmount = outputAmounts[i];
        } else {
          const result = await poolQuery.query(path, outputAmounts[i], pools, false);
          resultAmount = result.amount;
          pools.sharedPools.forEach((pool: string) => {
            pools.states[pool] = {
              xBalance: result.poolStates[pool][0],
              yBalance: result.poolStates[pool][1],
              totalSupply: result.poolStates[pool][2],
              impAddress: result.poolStates[pool][3],
            };
          });
        }
      } catch (_) {
        console.error("Proteus Query Error");
      }

      inputAmounts[startToken] = inputAmounts[startToken].add(resultAmount);

      if (wrapOrUnwrap(path)) {
        newMaxAmountsFrom[startToken][endToken] = resultAmount;
      } else {
        newMaxAmountsFrom[startToken][endToken] = resultAmount.mul(slippageMultiplier).div(10000);
        if (!foundNonWrap) setFoundNonWrap(true);
      }
    }

    Object.keys(inputAmounts).forEach((inputToken) => {
      const inputAmount = inputAmounts[inputToken];
      if (inputAmount.isZero()) {
        delete newAmountsFrom[inputToken];
      } else {
        newAmountsFrom[inputToken] = formatUnits(inputAmount);
      }
      let summaryAmount = Zero;
      Object.keys(newMaxAmountsFrom[inputToken]).forEach((outputToken) => (summaryAmount = summaryAmount.add(newMaxAmountsFrom[inputToken][outputToken])));
      newSummaryAmounts[inputToken] = formatUnits(summaryAmount);
    });

    setAmountsFrom(newAmountsFrom);
    setMaxAmountsFrom(newMaxAmountsFrom);
    setSummaryAmounts(newSummaryAmounts);
    setOptimalPaths(newOptimalPaths);
  };

  const checkForNFTs = async () => {
    const newUnavailableNFTs: any = {};

    for (let i = 0; i < tokensTo.length; i++) {
      const outputToken = tokensTo[i];
      if (selectedNFTs[outputToken.symbol]) {
        const fungibleToken = tokens.concat(externalTokens).filter((token) => token.address == outputToken.address && token.symbol !== outputToken.symbol)[0];

        let ocean;
        let fractionalizer: string;

        if (isShellV2Token(fungibleToken)) {
          ocean = OLD_OCEAN_ADDRESS;
          const adapter = new Contract(outputToken.fractionalizer, AdapterABI, provider);
          fractionalizer = await adapter.primitive();
        } else {
          ocean = oceanAddress;
          fractionalizer = outputToken.fractionalizer;
        }

        const fractionalizerOcean = new Contract(ocean, OceanABI, provider);

        const nftIDs = selectedNFTs[outputToken.symbol].map((nft: NFT) => calculateWrappedTokenId(outputToken.address, nft.id));
        const balances = await fractionalizerOcean?.balanceOfBatch(
          nftIDs.map(() => fractionalizer),
          nftIDs
        );
        const unavailableIDs = [];
        for (let i = 0; i < balances.length; i++) {
          if (balances[i].isZero()) {
            const nftID = selectedNFTs[outputToken.symbol][i].id;
            unavailableIDs.push(nftID);
          }
        }

        if (unavailableIDs.length) newUnavailableNFTs[outputToken.symbol] = await buildNFTDisplays(outputToken, unavailableIDs);
      }
    }
    setUnavailableNFTs(newUnavailableNFTs);
  };

  const executeTransaction = () => {
    if (signer) {
      setConfirmDisabled(true);
      setTxState("Pending");

      const expectedAmounts = fromInputs ? minAmountsTo : maxAmountsFrom;

      const interactions = buildInteractions(
        tokensFrom,
        tokensTo,
        { ...specifiedAmounts },
        expectedAmounts,
        fromInputs,
        { ...splitAmounts },
        { ...selectedNFTs },
        optimalPaths,
        liquidityGraph,
        tokenMap
      );

      ShellV2.executeInteractions(ocean as types.Ocean, signer, interactions)
        .then((response: any) => {
          setTxState("Submitted");

          toast.promise(response.wait(), {
            loading: "Transaction pending",
            success: (data: any) => {
              setTxHash(data.transactionHash);
              setTxState("Success");
              setTxSuccess(true);
              return "Transaction success";
            },
            error: (data: any) => {
              setTxHash(data.transactionHash);
              setTxState("Error");
              return "Transaction error";
            },
          });
        })
        .catch(() => {
          setTxState("");
          setConfirmDisabled(false);
        });
    }
  };

  const closeModal = () => {
    setVisible(false);
    setTradeDisabled(txState === "Submitted" || txState === "Success");
    if (txState === "Success") {
      dispatch(clearUserTransactions({ chain: connectedChain.name, user: walletAddress }));
      dispatch(clearCurrentPoints({ address: walletAddress }));
      setTxState("");
    }
  };

  useEffect(() => {
    if (visible) {
      setConfirmDisabled(true);

      if (fromInputs) {
        setOutputLoading(true);

        const split = tokensTo.length > 1;
        const inputTokens = [...tokensFrom];
        const outputTokens = [...tokensTo];
        if (split) inputTokens.push(inputTokens[0]);

        updateOutputValues(inputTokens, outputTokens, split).then(() => {
          setOutputLoading(false);
          setConfirmDisabled(false);
        });
      } else {
        setInputLoading(true);

        const split = tokensFrom.length > 1;
        const outputTokens = [...tokensTo];
        const inputTokens = [...tokensFrom];
        if (split) outputTokens.push(outputTokens[0]);

        checkForNFTs();

        updateInputValues(outputTokens, inputTokens, split).then(() => {
          setInputLoading(false);
          setConfirmDisabled(false);
        });
      }
    }
  }, [visible]);

  useEffect(() => {
    const refreshValues = setInterval(() => {
      // Requery values every 10 seconds

      if (visible && !confirmDisabled && (foundNonWrap || outputNFTCollections.length) && Object.keys(unavailableNFTs).length == 0) {
        setConfirmDisabled(true);

        if (fromInputs) {
          setOutputLoading(true);

          const split = tokensTo.length > 1;
          const inputTokens = [...tokensFrom];
          const outputTokens = [...tokensTo];
          if (split) inputTokens.push(inputTokens[0]);

          updateOutputValues(inputTokens, outputTokens, split).then(() => {
            setTimeout(() => {
              setOutputLoading(false);
              setConfirmDisabled(false);
            }, 500);
          });
        } else {
          setInputLoading(true);

          const split = tokensFrom.length > 1;
          const outputTokens = [...tokensTo];
          const inputTokens = [...tokensFrom];
          if (split) outputTokens.push(outputTokens[0]);

          checkForNFTs();

          updateInputValues(outputTokens, inputTokens, split).then(() => {
            setTimeout(() => {
              setInputLoading(false);
              setConfirmDisabled(false);
            }, 500);
          });
        }
      }
    }, 10 * 1000);

    return () => clearInterval(refreshValues);
  }, [visible, confirmDisabled, unavailableNFTs]);

  useEffect(() => {
    setTxSuccess(false);
    setTxState("");
  }, [amountsFrom, amountsTo]);

  return (
    <StyledModal title={txState ? `Trade ${txState}` : "Confirm Trade"} isVisible={visible} onClose={closeModal}>
      <ColumnWrapper>
        {tokensFrom.map((token, index) => {
          return (
            <TokenView key={getTokenID(token)} shape={index == tokensFrom.length - 1 ? "bottom" : ""}>
              <Row>
                {inputLoading ? (
                  <StraightLoader />
                ) : (
                  <BalanceDisplayWrapper>
                    <span>From</span>
                    <BalanceDisplay isWrapped={token.wrapped}>{`${formatDisplay(amountsFrom[getTokenID(token)] || "0")}`}</BalanceDisplay>
                  </BalanceDisplayWrapper>
                )}
                <TokenDisplay>
                  <img src={token.icon} alt="logo" />
                  <span className="token-symbol-text">{token.symbol}</span>
                </TokenDisplay>
              </Row>
            </TokenView>
          );
        })}
        <Arrow>
          <ArrowIcon bridge />
        </Arrow>
        {tokensTo.map((token, index) => {
          return (
            <TokenView key={getTokenID(token)} shape={index == 0 ? "top" : ""}>
              <Row>
                {outputLoading ? (
                  <StraightLoader />
                ) : (
                  <BalanceDisplayWrapper>
                    <span>To</span>
                    <BalanceDisplay isWrapped={token.wrapped} awarded>
                      {`${formatDisplay(amountsTo[getTokenID(token)] || "0")}`}
                    </BalanceDisplay>
                  </BalanceDisplayWrapper>
                )}
                <TokenDisplay>
                  <img src={token.icon} alt="logo" />
                  <span className="token-symbol-text">{token.symbol}</span>
                </TokenDisplay>
              </Row>
            </TokenView>
          );
        })}
        {allNFTs.length > 0 ? (
          <>
            <Row style={{ margin: "20px 0 16px 0" }}>
              <Label>Selected NFTs</Label>
            </Row>
            <SwiperContainer>
              <NFTsSwiper isConfirmation={true} nftTokens={allNFTs} navitagionTransform={{ left: "0", right: "0" }} />
            </SwiperContainer>
          </>
        ) : (
          <Accountant double={tokensFrom.length > 1 || tokensTo.length > 1} />
        )}
        <Row style={{ marginBottom: "12px" }}>
          <Label>Slippage Tolerance</Label>
          <Value>{foundNonWrap ? slippage.toFixed(2) + "%" : "N/A"}</Value>
        </Row>
        <Row style={{ marginBottom: "12px" }}>
          <Label>{fromInputs ? "Minimum Received" : "Maximum Sent"}</Label>
          <Column style={{ rowGap: "6px" }}>
            {(fromInputs ? tokensTo : tokensFrom).map((token, index) => {
              const tokenID = getTokenID(token);
              const loading = fromInputs ? outputLoading : inputLoading;
              if (summaryAmounts[tokenID] && !loading) {
                return (
                  <Value key={tokenID} style={{ textAlign: "right" }}>
                    {formatDisplay(summaryAmounts[tokenID])} {tokenID}
                  </Value>
                );
              } else {
                return <SkeletonBox key={tokenID} isLoading width="96px" height="14px" borderRadius="4px" />;
              }
            })}
          </Column>
        </Row>
        {/* {
                    (!txState || txState == 'Pending') &&
                    <Summary>
                        <SummaryText>
                            Trade {!fromInputs && foundNonWrap && <span style={{ textDecoration: 'underline' }}>at most</span>} {tokensFrom.map((token, index) => {
                                if (token.wrapped) {
                                    wrappedTokens.push(token.wrapped)
                                }
                                const tokenID = getTokenID(token)
                                return (
                                    <span key={index}>
                                        <SummaryColor data-testid={`summary-from-${tokenID}-${index}`} color={tokenColors[token.symbol]}>
                                            {`${formatDisplay(summaryAmounts[tokenID] || '0')} ${tokenID}`}
                                        </SummaryColor>
                                        {index !== tokensFrom.length - 1 && ' and '}
                                    </span>
                                )
                            })} for {fromInputs && foundNonWrap && <span style={{ textDecoration: 'underline' }}>at least</span>} {tokensTo.map((token, index) => {
                                if (token.wrapped) {
                                    wrappedTokens.push(token.wrapped)
                                }
                                const tokenID = getTokenID(token)
                                return (
                                    <span key={index}>
                                        <SummaryColor data-testid={`summary-to-${tokenID}-${index}`} color={tokenColors[token.symbol]}>
                                            {`${formatDisplay(summaryAmounts[tokenID] || '0')} ${tokenID}`}
                                        </SummaryColor>
                                        {index !== tokensTo.length - 1 && ' and '}
                                    </span>
                                )
                            })}
                        </SummaryText>
                    </Summary>
                } */}
        {txState && txState != "Pending" && (
          <>
            {txState != "Submitted" && (
              <Label data-testid="success-label" style={{ margin: "0 auto 12px auto", color: "#7D7D97" }}>
                {txState == "Success" ? "Success!" : "Error"}
              </Label>
            )}
            <CircleContainer>{txState == "Success" ? <ConfirmIcon /> : txState == "Error" ? <ErrorIcon /> : <Spinner />}</CircleContainer>
            {txState != "Submitted" && (
              <ExplorerLink href={connectedChain.explorerUrl + "tx/" + txHash} target="_blank">{`View in Explorer \u2192`}</ExplorerLink>
            )}
          </>
        )}
        {(txState == "" || txState == "Pending") && (
          <ActionButton data-testid="confirm-swap-btn" onClick={executeTransaction} disabled={confirmDisabled}>
            <ConfirmIcon />
            Confirm Trade
          </ActionButton>
        )}
        {txState == "Submitted" && (
          <Label data-testid="waiting-label" style={{ margin: "16px auto 4px auto", color: "#7D7D97" }}>
            Waiting for blockchain confirmation...
          </Label>
        )}
      </ColumnWrapper>
      <NFTWarning
        nftTokens={Object.values(unavailableNFTs).flat()}
        showModal={Object.keys(unavailableNFTs).length > 0}
        canProceed={outputNFTCollections.filter((collection) => selectedNFTs[collection]?.length == unavailableNFTs[collection]?.length).length == 0}
        onClose={() => {
          closeModal();
          const newSelectedNFTs = { ...selectedNFTs };
          Object.keys(unavailableNFTs).forEach((collection) => {
            newSelectedNFTs[collection] = selectedNFTs[collection].filter((nft: any) => unavailableNFTs[collection].map((nft) => nft.id).indexOf(nft.id) == -1);
          });
          setSelectedNFTs(newSelectedNFTs);
          setUnavailableNFTs({});
        }}
        onProceed={() => {
          const newSelectedNFTs = { ...selectedNFTs };
          for (let i = 0; i < outputNFTCollections.length; i++) {
            const collection = outputNFTCollections[i];
            const unavailableIDs = unavailableNFTs[collection]?.map((nft) => nft.id) ?? [];
            const newCollectionNFTs = selectedNFTs[collection].filter((nft: NFT) => !unavailableIDs.includes(nft.id));
            selectedNFTs[collection] = newCollectionNFTs;
            newSelectedNFTs[collection] = newCollectionNFTs;
            specifiedAmounts[collection] = newCollectionNFTs.length.toString();
          }

          setSelectedNFTs(newSelectedNFTs);
          setUnavailableNFTs({});

          updateInputValues([...tokensTo], [...tokensFrom], false).then(() => {
            setTimeout(() => {
              setInputLoading(false);
              setConfirmDisabled(false);
            }, 500);
          });
        }}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: fit-content;
  max-width: 520px;

  ${Media.mobile} {
    height: 95%;
    max-width: 100%;
    margin-top: 5.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const TokenView = styled.div<{ shape?: string }>`
  position: relative;
  border-radius: 16px;
  z-index: 1;
  max-width: 99.5%;
  padding: 12px 16px;

  & + & {
    margin-top: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #151530;
    border: 1px solid #1e2239;
    border-radius: 16px;
    z-index: -1;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnWrapper = styled(Column)`
  margin-top: 12px;

  ${Media.mobile} {
    flex-basis: 100%;
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;

  & > svg {
    width: 24px;
    height: 24px;
    color: #00bdff;
  }
`;

const SwiperContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 16px;

  ${Media.mobile} {
    margin-bottom: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BalanceDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;

  span {
    color: var(--grey-3, #7d7d97);

    /* Text 4/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 16.32px */
  }
`;

const BalanceDisplay = styled.div<{ isWrapped: boolean; awarded?: boolean }>`
  color: ${({ isWrapped, awarded }) => (isWrapped || awarded ? "#0ABCFD" : "#FFFFFF")};
  flex-grow: 1;
  text-align: left;
  color: var(--White, #fff);

  /* Title 7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
`;

const TokenDisplay = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  img {
    width: 32px;
    height: 32px;
  }

  .token-symbol-text {
    font-size: clamp(14px, 2.5vw, 16px);
    line-height: 48px;
    letter-spacing: -0.03em;
    font-weight: 500;
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%;
    flex-grow: 1;
    white-space: nowrap;
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  text-align: left;
  color: #7d7d97;
  white-space: nowrap;
  letter-spacing: -0.02em;
`;

const Value = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
  letter-spacing: 0.02em;
`;

const Summary = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const SummaryText = styled.p`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

const SummaryColor = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`;

const CircleContainer = styled.div`
  width: 64px;
  height: 64px;
  margin: 0px auto;
  color: #00bdff;

  img {
    width: 64px;
    height: 64px;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #151530;
    color: #464659;
  }

  ${Media.tablet} {
    height: 66px;
    margin: 16px auto;
  }
`;

const ExplorerLink = styled.a`
  font-weight: 100;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #7d7d97;
  white-space: nowrap;
  letter-spacing: -0.02em;
  margin: 12px auto 2px auto;

  :hover {
    color: #00bdff;
  }
`;

import React from "react";

export const BackArrowIcon = () => {
  return (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.3334 16H6.66675"
            stroke="white"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.0001 25.3334L6.66675 16.0001L16.0001 6.66675"
            stroke="white"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
  );
};
